import React from 'react'
import DashboardNav from "./DashboardNav"
import SideBar from "./SideBar"
import ContentWrapper from "./ContentWrapper"
import {Outlet} from 'react-router-dom'
import "../../../assets/css/dashboard.scss"
import {App} from "../../../assets/js/MainApp"
import {DatatableColumnVisibility} from '../../../assets/js/DatabaseTableColvis'
import {DashboardNotifyProvider} from './DashboardProvider'
import {DashboardLayoutProvider} from './DashboardLayoutProvider'

const DashboardLayout = () => {

    React.useEffect(() => {
        App.initCore();
        App.initAfterLoad();
        DatatableColumnVisibility.init();
    }, [])

    return (
        <DashboardLayoutProvider>
            <DashboardNotifyProvider>
                <div className="dashboard-style">
                    <DashboardNav/>
                    <div className="page-content custom-scrollbars">
                        <SideBar/>
                        <ContentWrapper>
                            <Outlet/>
                        </ContentWrapper>
                    </div>
                </div>
            </DashboardNotifyProvider>
        </DashboardLayoutProvider>
    )
}

export default DashboardLayout
