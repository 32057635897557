import React from 'react'
import GDPharmacyHeader from './components/GDPharmacyHeader'

function GDPharmacy() {
    return (
        <React.Fragment>
            <GDPharmacyHeader/>
        </React.Fragment>
    )
}

export default GDPharmacy
