import {
    Alert,
    AlertTitle,
    Backdrop,
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Grid,
    Stack,
    Typography
} from '@mui/material'
import {bgcolor} from '@mui/system'
import React, {useContext, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import ProfileCard from './ProfileCard'
import axios from '../../../../api'
import {useQuery} from '@tanstack/react-query'
import {LoginStateContext} from '../../../../../contexts/LoginStateContext'
import PackageAlert from '../../packages/PackageAlert'
import PackageStatus from '../../packageStatuses/PackageStatus'

function DashboardOverlay({blurDiv}) {
    const navigate = useNavigate()
    const loginContext = useContext(LoginStateContext)
    const {data, isLoading, isError} = useQuery(
        ['package', loginContext.state.data.id],
        async () => (
            axios.get('/api/admin/userpackage', {headers: {'Authorization': `Bearer ${loginContext.state.token}`}}).then(res => res.data)
        )
    )
    // console.log()

    useEffect(() => {
        let content = document.getElementsByClassName('content')[0]
        if (!isLoading) {
            if (data && data.package === null || data.package?.package_status == 'Not Booked') {
                content.style = "overflow-y:hidden"
                blurDiv.current.style = 'filter: blur(5px)'
            }
        }
        return (() => {
            content.style = "overflow-y:visible"
        })
    }, [isLoading])
    if (isLoading) {
        return (
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={isLoading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
        )
    }
    if (data.package !== null && data.package.package_status !== "Not Booked") {
        return
    }
    return (
        <Box width='100%' height='100%' position='absolute' top={0} left={0}>
            <Box width="100%" height='100%' sx={{opacity: 0.7}} position='absolute' top={0} left={0} zIndex={2}
                 bgcolor='black'>
            </Box>
            <Box width="100%" height='100%' position='relative' zIndex={3} p={1}>
                <Box width={'100%'} maxWidth={700} margin='auto'>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            {/* <Alert severity='error' sx={{ '&>div:last-child': { width: '100%' } }}>
                                <AlertTitle>Buy Package</AlertTitle>
                                <Stack direction='row' justifyContent='space-between'>
                                    <Typography variant='subtitle1'>Please Buy The Package before accessing the dashboard</Typography>
                                    <Button variant='contained' onClick={() => navigate('/packages')}>Buy Package</Button>
                                </Stack>
                            </Alert> */}
                            <PackageStatus data={data.package}/>
                        </Grid>
                        <Grid item xs={12}>
                            <ProfileCard/>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    )
}

export default DashboardOverlay
