import {Rating} from "@mui/material";
import "../../components/stores/ProductReview.css";
import {useState, useEffect, useContext} from "react";
import profilePic from "../../images/blankProfile.webp";
import ReviewListItem from "./ReviewListItem";
import RatingDatas from "./RatingDatas";
import axios from "../api.js";
import swal from 'sweetalert';
import {RatingContext} from "../../contexts/RatingContext"
import {ProductContext} from "../pages/stores/ProductPage/ProductDetPage";
import {useQuery} from "@tanstack/react-query";

const chkProductOrder = (product) => {

}

const ProductReview = () => {
    const productCont = useContext(ProductContext)
    const [reviewData, setReviewData] = useState({
        product_id: productCont.product.id,
        rating: 1,
        comment: ''
    })
    const {data, isLoading, refetch} = useQuery(
        ['product_review', productCont.product.id],
        async () => {
            return axios
                .get("api/admin/get_review?product_id=" + productCont.product.id)
                .then(res => res.data).catch(err => err)
        }
    )
    const [ratingVal, setRatingVal] = useState(1);
    const [reviewAllow, setReviewAllow] = useState(false)

    const token = localStorage.getItem('token');


    const tok =
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiZTMyNWYzM2JlNzMyZDVkYzFiN2ZjODQ3OTAxYjRiMDJlYTk2YWFkN2E5NGQ4OTk2NjNkNmZkNTZhODFiYzIyYjZjZTQ3ODFmYWY3MGNmZWYiLCJpYXQiOjE2NjIwOTQ2NDMuMjA5ODg1LCJuYmYiOjE2NjIwOTQ2NDMuMjA5ODkyLCJleHAiOjE2OTM2MzA2NDMuMjAxNjI1LCJzdWIiOiIzIiwic2NvcGVzIjpbXX0.XtflKDGEZnK1WYCWKHXc-wdEQR6DY9OthWp09sa9OiF7e9R4-QRiRnT2nyzOzStXNuifmYWvkYwVqJzB1NzKW896zjNZhR7HEXZ0OqXEXaAI8K0_rBbRkJbSZo79_9Pa1ZXAGQpxJPDoornew03LF4Y6G8lN0WqADgcoqGXRcuo_3FFyFU8evo7TNJrhluyVRimQYsCXK-hHSEJXKRKAb8d5g_3zLYGnCMPgdhm8P9DX3L6R8lpo9qydDh5vTq85fVakv_KqFzjiWvpFLCj_92znYRAAZoJYOWYQnyrRE3EsCzHwEvwm_mEYUyBQSfM_0CqZHhMJiphDTX_ynixqixS3hLYeAK3R3K4s8w8k4ETDewd4DswxFpIOkcwD954NxbA1Zv2001Ze1GaU9a6p_pFWI9fTUVuC-L2CQW9pW90RHc7iL-G2LsKJQmrP_JQrxHgXF9kv_gM0nPkn7sbMIf7BRSlP6dPWbNWhk2Pn5j0h9IOn71ChEsiQB0yQZu8qGYn0RC8osfUPSDq_HJf_eT_-odECJM3mkth-NjYrgu3QQK0LMhFMaV1izJyhBAzpvivLeqXiwm9anFx2Qdq_xggcPcRaeTFSPhzkZKSXhOW5_XMbHesqjKaHaE3fGPS7FH8idotWtSfRL1j77_MYla4bPJ03gljbmPvrQXc_nR8";

    // useEffect(() => {
    //   const getParam = new URLSearchParams([['product_id', props.product.id]])
    //   axios
    //     .get("api/admin/get_review", { params: getParam })
    //     .then((res) => {
    //       // let review = res.data.filter(element => element.product_id==props.product.id)
    //       console.log()
    //       setReviews(res.data)
    //     });
    // }, [trackUpdate]);

    useEffect(() => {
        const token = localStorage.getItem('token')
        if (token !== null) {
            axios.get("api/admin/order", {
                headers: {Authorization: `Bearer ${token}`},
            })
                .then(res => {
                    let orders = res.data
                    let prodFound = []

                    for (let i = 0; i < orders.length; i++) {
                        for (let j = 0; j < orders[i].products.length; j++) {
                            if (orders[i].products[j].id == productCont.product.id) {
                                setReviewAllow(true)
                                break
                            }
                        }
                    }
                })
        }
    }, [])


    const renderPostReview = () => {

        if (!reviewAllow) {
            return
        }
        return (
            <div className="add-review">
                <h5>POST REVIEW</h5>
                <hr/>
                <div id="input-rating">
                    <Rating
                        name="simple-controlled"
                        value={reviewData.rating}
                        onChange={(event, newValue) => {
                            setRatingValue(newValue);
                        }}
                        size="large"
                    />
                </div>
                <div className="form-outline mb-2">
          <textarea
              className="form-control"
              id="form4Example3"
              rows="3"
              placeholder="Write a Review For this Product"
              name="comment"
              value={reviewData.comment}
              onChange={handleChange}
          ></textarea>
                </div>
                <div className="review-btns d-flex justify-content-end">
                    <button id="review-btn-post-review" onClick={postReview}>POST REVIEW</button>
                </div>
            </div>
        )
    }

    const handleChange = (e) => {
        e.preventDefault()
        setReviewData({...reviewData, [e.target.name]: e.target.value})
    }

    const setRatingValue = (newValue) => {
        setRatingVal(newValue);
        setReviewData({...reviewData, rating: newValue})
    };

    const postReview = (e) => {
        e.preventDefault()
        console.log()
        axios.post("api/admin/product_review", reviewData, {headers: {Authorization: `Bearer ${token}`}})
            .then((res) => {
                swal("Success", "Review Added Successfully", "success")
                refetch()
                productCont.prodRefetch()
                setReviewData({...reviewData, rating: 1, comment: ''})
            })
            .catch(err => console.log())
    }

    if (isLoading) {
        return
    }

    return (
        <div className="prod-review-tab">
            <div className="row g-5">
                <div className="col-12 col-md-5">
                    {<RatingDatas/>}
                    <hr/>

                    {renderPostReview()}

                </div>

                <div className="col-12 col-md-7">
                    <div className="prod-reviews-container mt-5">
                        <div className="prod-review-ribbon w-100 d-flex justify-content-between align-items-center">
                            <h5>PRODUCT REVIEWS</h5>
                            <div className="ribbon-sort-by d-flex">
                                <label className="mx-4">Sort By:</label>
                                <div className="select-div">
                                    <select>
                                        <option value="">Rating: High To Low</option>
                                        <option value="">Rating: Low To High</option>
                                        <option value="">Relevence</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="prod-review-list">
                            {data.map((review) => {
                                return <ReviewListItem review={review}/>;
                            })}
                            {
                                (data.length == 0) &&
                                <h4 className="text-center mt-4">No Reviews Yet</h4>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

function useProductReview(product) {
    const [data, setData] = useState([])
    const [isLoading, setLoading] = useState(true)
    useEffect(() => {
        if (product.length === 0) {
            setLoading(false)
            return
        }
        axios
            .get("api/admin/get_review?product_id=" + product.id)
            .then((res) => {
                setData(res.data)
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
            })
    }, [product])
    return {data, isLoading}
}

export default ProductReview;
