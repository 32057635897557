import {AddBusinessOutlined, KeyboardArrowDownOutlined} from '@mui/icons-material'
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material'
import axios from '../../../../api'
import React, {useContext} from 'react'
import {useEffect} from 'react'
import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {LoginStateContext} from '../../../../../contexts/LoginStateContext'
import AuthButton from '../../../../custom_componets/AuthButton'
import {GDVendorContext} from '../GDVendorContext'
import GDVendorNavigation from './GDVendorNavigation'

function GDVendorHeader({children, headerRef}) {
    const {vendor, refetchVendor} = useContext(GDVendorContext)
    const {following, isLoading, followVendor} = useVendorFollowing(vendor.id, refetchVendor)
    const matches = useMediaQuery('(max-width:1000px)');
    const display = {
        display: matches ? 'none' : 'block'
    }
    return (
        <Box width={'100%'} bgcolor={'#47B1DB'} textAlign='left' ref={headerRef}>
            <Box className='container-lg' sx={{position: 'relative', display: 'flex', alignItems: 'end'}}
                 pt={matches ? 1.5 : 4} pb={0.3}>
                <Box width='100%'>
                    <Card sx={{width: '100%', pb: 0}}>
                        <CardContent sx={{paddingBottom: '0px !important'}}>
                            <Grid container>
                                <Grid item md={1} xs={2}>
                                    <Avatar
                                        src={vendor.image_path}
                                        sx={{
                                            width: '80%',
                                            height: 'auto',
                                            aspectRatio: '1/1',
                                            '& img': {width: '100%', aspectRatio: '1/1'}
                                        }}
                                    />
                                </Grid>
                                <Grid item md={3} xs={5}>
                                    <Stack direction='row'>
                                        <Typography variant='h6' lineHeight={1}>{vendor.store_name}</Typography>
                                        {
                                            vendor.is_exculsive === 2 &&
                                            <Box sx={{height: '30px', '& img': {height: '100%', aspectRatio: '3/1'}}}>
                                                <img
                                                    src="http://provenseed.ca/wp-content/uploads/2017/01/CertifiedSeed-300x155.png"
                                                    alt=""/>
                                            </Box>

                                        }
                                    </Stack>

                                    <Typography variant='subtitle1' lineHeight={1}>
                                        {
                                            vendor.vendor_type === 1 && "Healthy Food Store"
                                        }
                                        {
                                            vendor.vendor_type === 2 && "Pharmacy"
                                        }
                                        {
                                            vendor.vendor_type === 3 && "Fitness Center"
                                        }
                                    </Typography>
                                    {
                                        !matches &&
                                        <Typography variant='subtitle1'
                                                    color="text.secondary">{vendor.follower_count} Followers</Typography>

                                    }

                                </Grid>
                                <Grid item md={3} sx={display}>
                                    <Card sx={{
                                        height: '100%',
                                        borderLeft: 1,
                                        borderRight: 1,
                                        borderColor: 'divider',
                                        px: 2
                                    }} elevation="0">
                                        <Typography variant='subtitle1' color="text.secondary">Customer
                                            Reviews</Typography>
                                        <Typography variant='h6'>90% Positive Reviews</Typography>
                                    </Card>
                                </Grid>
                                <Grid item md={2.5} sx={display}>
                                    <Card sx={{
                                        height: '100%',
                                        borderLeft: 1,
                                        borderRight: 1,
                                        borderColor: 'divider',
                                        px: 2
                                    }} elevation="0">
                                        <Typography variant='subtitle1' color="text.secondary">Joined</Typography>
                                        <Typography variant='h6'>4+ Years</Typography>
                                    </Card>
                                </Grid>
                                <Grid item md={2.5} xs={5}
                                      sx={{display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
                                    <Card sx={{
                                        height: '100%',
                                        display: 'inline-block',
                                        borderRight: 1,
                                        borderColor: 'divider',
                                        px: 2
                                    }} elevation="0">
                                        {/* <Button startIcon={<AddBusinessOutlined />} variant='outlined'>

                                            </Button> */}
                                        <AuthButton loading={isLoading} callbackFn={followVendor}
                                                    loaderColor={'primary'} variant='outlined'
                                                    startIcon={<AddBusinessOutlined/>}>
                                            {
                                                following ? 'Following' : 'Follow'
                                            }
                                        </AuthButton>
                                        {
                                            matches &&
                                            <Typography variant='subtitle1' fontWeight={550}
                                                        color="text.secondary">{vendor.follower_count} Followers</Typography>

                                        }

                                    </Card>
                                </Grid>
                            </Grid>
                            {children}
                        </CardContent>
                    </Card>
                </Box>
            </Box>
        </Box>
    )
}

function useVendorFollowing(vendor_id, refetchVendor) {
    const [following, setFollowing] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const loginContext = useContext(LoginStateContext)
    const navigate = useNavigate()

    function refetch() {
        setIsLoading(true)
        setFollowing(false)
        fetchVendorFollowingStatus()
    }

    async function fetchVendorFollowingStatus() {
        let res;
        if (loginContext.state.loggedIn) {
            try {
                res = await axios.get(`/api/isFollowed?vendor_id=${vendor_id}`, {headers: {'Authorization': `Bearer ${loginContext.state.token}`}})
                if (res.data.isfollowed == 1) {
                    setFollowing(true)
                } else {
                    setFollowing(false)
                }
            } catch (e) {
                console.log()
            }
        }
        setIsLoading(false)
    }

    async function followVendor() {
        if (!loginContext.state.loggedIn) {
            return navigate("/login")
        }
        const data = {
            vendor_id: vendor_id
        }
        try {
            await axios.post('/api/vendor-follow', data, {headers: {'Authorization': `Bearer ${loginContext.state.token}`}})
            refetch()
            refetchVendor()
        } catch (e) {
            console.log()
        }
    }

    useEffect(() => {
        if (!loginContext.state.loading) {
            fetchVendorFollowingStatus()
        }
    }, [loginContext.state.loading])

    return {
        following,
        isLoading,
        followVendor
    }
}

export default GDVendorHeader
