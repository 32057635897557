import React, {useState} from 'react'
import ChatOpen from './ChatOpen'
import ChatFloatingBtn from './ChatFloatingBtn'
import {Box} from '@mui/material'
import ChatModal from './ChatModal'

function GDChatbot() {
    const [chatOpen, setChatOpen] = useState(false)
    const [openModal, setOpenModal] = useState(false)

    const [modalTitle, setModalTitle] = useState()
    const [modalDesc, setModalDesc] = useState()
    const [handleConfirm, setHandleConfirm] = useState(() => {
    })
    const [modalLoading, setModalLoading] = useState(null)

    function openChatModal(obj) {
        setOpenModal(true)
        setModalTitle(obj.title)
        setModalDesc(obj.description)
        setHandleConfirm(() => obj.handleConfirm)
        setModalLoading(obj.isLoading)
    }

    function closeChatModal() {
        setOpenModal(false)
    }

    function closeChat() {
        setChatOpen(false)
    }

    return (
        <>
            <Box
                sx={{
                    position: 'fixed',
                    bottom: 20,
                    right: 20,
                    cursor: 'pointer',
                    zIndex: 99999
                }}
            >
                {
                    !chatOpen ?
                        <ChatFloatingBtn openWindow={() => setChatOpen(true)}/>
                        :
                        <ChatOpen closeWindow={() => setChatOpen(false)} openChatModal={openChatModal}/>
                }
            </Box>
            <ChatModal
                title={modalTitle}
                description={modalDesc}
                handleConfirm={handleConfirm}
                isOpen={openModal}
                onClose={closeChatModal}
                closeChat={closeChat}
                isLoading={modalLoading}
            />
        </>
    )
}

export default GDChatbot
