import {Box, Card, CardHeader, Grid} from '@mui/material'
import React, {useContext} from 'react'
import LabTestItem from './LabTestItem'
import {LabTestContext} from './LabTestProvider'

function LabTests({data}) {
    const {department} = useContext(LabTestContext)
    if (data.length === 0) {
        return
    }
    return (
        <Box my={2}>
            <Card variant='outlined'>
                <CardHeader
                    title={`${department.department} Lab Tests`}
                    subheader='15 lab tests found in Biochemistry'
                    titleTypographyProps={{
                        fontWeight: '550'
                    }}
                />
            </Card>

            <Grid container spacing={1} my={1}>
                {
                    data.map((item, idx) => (
                        <Grid item xs={4} key={idx}>
                            <LabTestItem data={item}/>
                        </Grid>
                    ))
                }
            </Grid>
        </Box>
    )
}

export default LabTests
