import {grey} from "@mui/material/colors";
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import InputFieldLabel from "../../../../form/InputFieldLabel";
import InputField from "../../../../form/InputField";
import {CHEQUE, COLLECTED, DEFAULT_VALUE_CHEQUE, DEPOSITED} from "../../../../../utils/constant/payment";
import DatePickerReactHookForm from "../../../../date-picker/date-picker-react-hook-form";
import {FIELD_REQUIRED} from "../../../../../utils/constant/common";
import DeleteRow from "../../../../form/DeleteRow";
import React, {useState} from "react";

const PayChequeItem = (props) => {
    const {form, index, append, remove, fieldsLength } = props
    const {register, formState: {errors}, setValue} = form

    const handleChange = (value, name) => {
        setStatus(value)
        setValue(name, value)
    };

    const handleAppend = () => append(DEFAULT_VALUE_CHEQUE)

    const handleRemove = (index) => remove(index)

    const [chequeStatus, setStatus] = useState(COLLECTED)



    return (
        <div className="p-4 d-grid gap-2" style={{background: grey["300"], borderRadius: 8}}>
            <div className="row">
                <div className="col-3">
                    <FormControl variant="standard" fullWidth color="primary">
                        <InputFieldLabel required={true}>
                            Cheque Number
                        </InputFieldLabel>
                        <InputField
                            {...register(
                                `${CHEQUE}.${index}.cheque_number`
                            )}
                            error={Boolean(errors && errors[CHEQUE] && errors[CHEQUE][index]?.cheque_number?.message)}
                            helperText={errors && errors[CHEQUE] && errors[CHEQUE][index]?.cheque_number?.message}
                        />
                    </FormControl>
                </div>
                <div className="col-3">
                    <FormControl variant="standard" fullWidth color="primary">
                        <InputFieldLabel required={true}>
                            Cheque Issuing Bank
                        </InputFieldLabel>
                        <InputField
                            {...register(
                                `${CHEQUE}.${index}.cheque_issue_bank`
                            )}
                            error={Boolean(errors && errors[CHEQUE] && errors[CHEQUE][index]?.cheque_issue_bank?.message)}
                            helperText={errors && errors[CHEQUE] && errors[CHEQUE][index]?.cheque_issue_bank?.message}
                        />
                    </FormControl>
                </div>
                <div className="col-3">
                    <DatePickerReactHookForm label="Cheque Issued Date"
                                             required={true}
                                             control={form.control}
                                             name={`${CHEQUE}.${index}.cheque_issue_date`}
                                             error={errors[CHEQUE] && errors[CHEQUE][index]?.cheque_issue_date?.message}
                    />
                </div>
                <div className="col-3">
                    <FormControl variant="standard" fullWidth color="primary">
                        <InputFieldLabel required={true}>
                            Cheque Amount
                        </InputFieldLabel>
                        <InputField
                            {...register(
                                `${CHEQUE}.${index}.cheque_amount`
                            )}
                            error={Boolean(errors && errors[CHEQUE] && errors[CHEQUE][index]?.cheque_amount?.message)}
                            helperText={errors && errors[CHEQUE] && errors[CHEQUE][index]?.cheque_amount?.message}
                        />
                    </FormControl>
                </div>

                <div className="d-grid pt-4 pb-3">
                    <InputFieldLabel classes="mb-0" required={true}>
                        Cheque Status
                    </InputFieldLabel>
                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name={`${CHEQUE}.${index}.cheque_status`}
                            onChange={e => handleChange(e.target.value, `${CHEQUE}.${index}.cheque_status`)}
                        >
                            <FormControlLabel checked={chequeStatus === COLLECTED} value={COLLECTED}
                                              control={<Radio/>} label="Collection"/>
                            <FormControlLabel checked={chequeStatus === DEPOSITED} value={DEPOSITED}
                                              control={<Radio/>}
                                              label="Deposit"/>
                        </RadioGroup>
                    </FormControl>
                    {Boolean(errors[CHEQUE] && errors[CHEQUE][index]?.cheque_status?.message) ? <span
                        className="text-danger">{errors[CHEQUE] && errors[CHEQUE][index]?.cheque_status?.message}</span> : ""}
                </div>

                {
                    chequeStatus === COLLECTED &&
                        <div className="col-4">
                            <DatePickerReactHookForm label="Collect On"
                                                     required={true}
                                                     control={form.control}
                                                     name={`${CHEQUE}.${index}.collect_on`}
                                                     error={errors && errors[CHEQUE] && errors?.[CHEQUE]?.[index]?.collect_on?.message}
                            />
                        </div>
                }

                {
                    chequeStatus === DEPOSITED &&
                    <>
                        <div className="col-4">
                            <FormControl variant="standard" fullWidth color="primary">
                                <InputFieldLabel required={true}>
                                    Deposited By
                                </InputFieldLabel>
                                <InputField
                                    {...register(
                                        `${CHEQUE}.${index}.depositor_name`
                                    )}
                                    error={Boolean(errors?.[CHEQUE]?.[index]?.depositor_name?.message)}
                                    helperText={errors?.[CHEQUE]?.[index]?.depositor_name?.message}
                                />
                            </FormControl>
                        </div>
                        <div className="col-4">
                            <DatePickerReactHookForm label="Deposit Date"
                                                     required={true}
                                                     control={form.control}
                                                     name={`${CHEQUE}.${index}.deposited_date`}
                                                     error={errors && errors[CHEQUE] && errors?.[CHEQUE]?.[index]?.deposited_date?.message}
                            />
                        </div>
                        <div className="col-4">
                            <InputFieldLabel classes="mb-0" required={true}>
                                Deposit Voucher
                            </InputFieldLabel>
                            <input type="file"
                                   multiple={false}
                                   onChange={(e) => {
                                       const file = e.target.files[0];
                                       form.setValue(`${CHEQUE}.${index}.deposited_voucher_scan`, file);
                                   }}
                                   accept="image/jpeg, image/jpg, image/png"
                            />
                            {
                                Boolean(errors && errors[CHEQUE] && errors[CHEQUE][index]?.deposited_voucher_scan?.message) &&
                                <span className="text-danger">{FIELD_REQUIRED}</span>
                            }
                        </div>
                    </>
                }
            </div>
            <DeleteRow index={index}
                       fieldsLength={fieldsLength}
                       handleRemove={handleRemove}
                       handleAppend={handleAppend}
                       classes="pt-3 justify-content-end"/>
        </div>
    )
}

export default PayChequeItem
