import {Avatar, Box, Button, Rating, Stack, TextField, Typography} from '@mui/material'
import React from 'react'
import {useContext} from 'react'
import {GDVendorContext} from '../GDVendorContext'
import AuthButton from '../../../../custom_componets/AuthButton'
import {useState} from 'react'
import axios from '../../../../api'
import {LoginStateContext} from '../../../../../contexts/LoginStateContext'

async function postReview(data, authToken) {
    try {
        await axios.post('/api/vendor-review', data, {headers: {'Authorization': `Bearer ${authToken}`}})
        alert('success')
        return true
    } catch (err) {
        alert('failure')
        return false
    }
}

function GDVendorPostReview({refetchReview}) {
    const loginContext = useContext(LoginStateContext)
    const {vendor} = useContext(GDVendorContext)
    const [reviewPosting, setReviewPosting] = useState(false)
    const [reviewData, setReviewData] = useState({
        rating: 3,
        comment: '',
        vendor_id: vendor.id
    })

    async function handleSubmit() {
        const res = await postReview(reviewData, loginContext.state.token)
        if (res) refetchReview()
    }

    if (loginContext.state.loading) {
        return
    }
    if (!loginContext.state.loggedIn) {
        return
    }
    const userDetails = loginContext.state.data.member
    return (
        <Box mt={2} pr={2}>
            <Stack direction={'row'} alignItems='center'>
                <Avatar
                    src="https://st2.depositphotos.com/1006318/5909/v/950/depositphotos_59095529-stock-illustration-profile-icon-male-avatar.jpg"
                    sx={{width: '70px', height: '70px', mr: 1}}
                />
                <Box>
                    <Typography variant="subtitle1" fontWeight={450}>
                        {userDetails.name}
                    </Typography>
                    <Typography lineHeight={1.1} variant="subtitle1" color="text.secondary">
                        Reviews are public and are editable
                    </Typography>
                </Box>
            </Stack>
            <Box my={1}>
                <Rating size='large'
                        sx={{width: '100%', justifyContent: 'space-between', '& > label': {fontSize: '35px'}}}/>
            </Box>
            <Box my={1}>
                <TextField
                    id="outlined-textarea"
                    label="Your Experience"
                    placeholder="Your Experience with the store"
                    fullWidth
                    multiline
                    onChange={(e) => setReviewData(prev => ({...prev, comment: e.target.value}))}
                    // disabled
                />
            </Box>
            <AuthButton label='Post Review' callbackFn={handleSubmit}/>
        </Box>
    )
}

export default GDVendorPostReview
