import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Grid,
    Typography,
} from "@mui/material";
import axios from "../../../api";
import React, {useState} from "react";
import {useContext} from "react";
import {LoginStateContext} from "../../../../contexts/LoginStateContext";
import {
    RadioInput,
    RadioInputContain,
} from "../../../custom_componets/FormComponents";

function CalorieIntake() {
    const loginContext = useContext(LoginStateContext);
    const [inputs, setInputs] = useState({
        gender: loginContext.state.data.gender,
        age: loginContext.state.data.dob
            ? new Date().getFullYear() - loginContext.state.data.dob.slice(0, 4)
            : "",
        height: loginContext.state.data.height,
        weight: loginContext.state.data.weight,
        activity: "",
    });
    // const [showCal, setShowCal] = useState(false)
    const [calorie, setCalorie] = useState(null);

    function handleChange(e) {
        setInputs({...inputs, [e.target.name]: e.target.value});
    }

    function handleSubmit(e) {
        e.preventDefault();
        setCalorie(null);
        axios
            .post("/api/admin/calorieCalculate", inputs)
            .then((res) => {
                // console.log('Calorie data', res.data)
                let numData = parseFloat(res.data);
                setCalorie(numData.toFixed());
            })
            .catch((err) => console.log());
    }

    return (
        <Box>
            <Card>
                <CardContent>
                    <Box component="form" onSubmit={handleSubmit}>
                        <Grid container gap={1}>
                            <Grid xs={5}>
                                <Grid container gap={2} sx={{alignItems: "center"}}>
                                    <Grid item xs={3}>
                                        Gender
                                    </Grid>
                                    <Grid item xs={8}>
                                        <select
                                            className="form-select"
                                            name="gender"
                                            aria-label="Default select example"
                                            value={inputs.gender}
                                            onChange={handleChange}
                                        >
                                            <option disabled>Open this select menu</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Others">Others</option>
                                        </select>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid xs={5}>
                                <Grid container gap={2} sx={{alignItems: "center"}}>
                                    <Grid item xs={3}>
                                        Age
                                    </Grid>
                                    <Grid item xs={8}>
                                        <div className="input-group mb-3">
                                            <input
                                                type="number"
                                                class="form-control"
                                                name="age"
                                                value={inputs.age}
                                                onChange={handleChange}
                                            />
                                            <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon2">
                          years
                        </span>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid xs={5}>
                                <Grid container gap={2} sx={{alignItems: "center"}}>
                                    <Grid item xs={3}>
                                        Weight
                                    </Grid>
                                    <Grid item xs={8}>
                                        <div className="input-group mb-3">
                                            <input
                                                type="number"
                                                class="form-control"
                                                name="weight"
                                                value={inputs.weight}
                                                onChange={handleChange}
                                            />
                                            <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon2">
                          kg
                        </span>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid xs={5}>
                                <Grid container gap={2} sx={{alignItems: "center"}}>
                                    <Grid item xs={3}>
                                        Height
                                    </Grid>
                                    <Grid item xs={8}>
                                        <div className="input-group mb-3">
                                            <input
                                                type="number"
                                                name="height"
                                                class="form-control"
                                                value={parseFloat(inputs.height).toFixed(2)}
                                                onChange={handleChange}
                                            />
                                            <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon2">
                          meter
                        </span>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Box mt={4}>
                            {/* <Typography sx={{ fontWeight: "600" }}>Activity Factor</Typography> */}
                            <Grid container gap={2}>
                                <Grid xs={5}>
                                    <Box mt={1}>
                                        <RadioInputContain
                                            name="activity"
                                            label="Activity Factor"
                                            direction="column"
                                        >
                                            <RadioInput
                                                id="radio1"
                                                type={"radio"}
                                                value={"Sedentary"}
                                                label={"Sedentary (Little or no exercise)"}
                                                onChange={handleChange}
                                            />
                                            <RadioInput
                                                id="radio2"
                                                type={"radio"}
                                                value={"Lightly active"}
                                                label={
                                                    "Lightly active (Light excercise 1-2 times a week)"
                                                }
                                                onChange={handleChange}
                                            />
                                            <RadioInput
                                                id="radio3"
                                                type={"radio"}
                                                value={"Moderately active"}
                                                label={
                                                    "Moderately active (Moderate exercise 2-3 times/week)"
                                                }
                                                onChange={handleChange}
                                            />
                                            <RadioInput
                                                id="radio4"
                                                type={"radio"}
                                                value={"Very active"}
                                                label={"Very active (Hard exercise 3-5 times/week)"}
                                                onChange={handleChange}
                                            />
                                            <RadioInput
                                                id="radio5"
                                                type={"radio"}
                                                value={"Extra active"}
                                                label={
                                                    "Extra active (Physical job or hard exercise 6-7 times/week)"
                                                }
                                                onChange={handleChange}
                                            />
                                            <RadioInput
                                                id="radio6"
                                                type={"radio"}
                                                value={"Professional athlete"}
                                                label={"Professional athlete"}
                                                onChange={handleChange}
                                            />
                                        </RadioInputContain>
                                    </Box>
                                </Grid>
                                {calorie !== null && (
                                    <Grid xs={6}>
                                        <Card variant="outlined">
                                            <CardContent>
                                                <h3>Calorie Intake to maintain current weight</h3>
                                                <h4 className="text-success">{calorie} kcal/day</h4>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                        <button type="submit" className="btn btn-primary">
                            Calculate Calorie
                        </button>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
}

export default CalorieIntake;
