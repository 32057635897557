import React, {useContext, useEffect, useState} from 'react'
import {toast, Toaster} from 'react-hot-toast'
import {LoginStateContext} from '../contexts/LoginStateContext'
import {onMessageListener, requestForToken} from '../firebase'

function GDNotification({setFCM, setFCMLoading}) {
    const loginContext = useContext(LoginStateContext)
    const [notification, setNotification] = useState({title: '', body: ''})
    const notify = () => toast(<ToastDisplay/>)

    function ToastDisplay() {
        return (
            <div>
                <p><b>{notification?.title}</b></p>
                <p>{notification?.body}</p>
            </div>
        )
    }

    useEffect(() => {
        if (notification?.title) {
            notify()
        }
    }, [notification])

    useEffect(() => {
        if (!loginContext.state.loading) {
            // console.log()
            requestForToken(setFCM, setFCMLoading, loginContext.state.token)
        }
    }, [loginContext.state.loading])

    // if(!loginContext.state.loading && loginContext.state.loggedIn) {
    //     requestForToken(loginContext.state.token)
    // }

    onMessageListener().then((payload) => {
        setNotification({title: payload?.notification?.title, body: payload?.notification?.body})
    })
        .catch(err => console.log())

    return (
        <Toaster/>
    )
}

export default GDNotification
