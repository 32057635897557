import {ShoppingCartCheckout} from '@mui/icons-material';
import {Avatar, Box, Card, CardContent, CardHeader, Divider, Grid, Stack, Typography} from '@mui/material';
import {useQuery} from '@tanstack/react-query';
import React from 'react'
import {useContext} from 'react';
import {useState} from 'react';
import {Link} from 'react-router-dom';
import {LoginStateContext} from '../../../contexts/LoginStateContext';
import {useCart} from '../../../contexts/provider/GDCartProvider';
import axios from '../../api'
import OrderItemList from './OrderItemList';

function OrderSummary() {
    const loginContext = useContext(LoginStateContext)
    const [orderData, setOrderData] = useState(
        {
            sub_total: 0,
            total_amount: 0,
            shipping_id: null
        }
    )
    const [charges, setCharges] = useState({
        subTotal: 0,
        totalDiscount: 0,
        shipping_charge: 0,
        total_cost: 0
    })

    const shippingDetails = useQuery(
        ['shippingDetails'],
        async () => {
            return axios.get("api/admin/order/shipping-details", {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                .then(res => res.data).catch(err => {
                })
        }
    )

    const products = useCart().cart

    React.useEffect(() => {
        const {discount, total} = calcAmount(products)
        let shipping = getShipping(shippingDetails.data, total)
        let shipping_charge = shipping === undefined ? 0 : shipping.price
        setCharges((prev) => ({
            ...prev,
            subTotal: Number(total),
            totalDiscount: Number(discount),
            shipping_charge: shipping === undefined ? 0 : shipping.price,
            total_cost: Number(total) + Number(shipping_charge) - Number(discount)
        }))
        setOrderData((prev) => ({
            ...prev,
            sub_total: Number(total),
            total_amount: Number(total) + Number(shipping_charge) - Number(discount),
            shipping_id: shipping === undefined ? null : shipping.id,
        }))
    }, [shippingDetails.isLoading, shippingDetails.data]);

    return (
        <Card variant='outlined'>
            <CardHeader title={<><ShoppingCartCheckout/> Cart Summary <Typography variant='caption'><Link
                to="/gd-store/cart">(Edit)</Link></Typography></>}
                        subheader="The total consist of additional shipping charge with discount amount subtracted."/>
            <CardContent sx={{py: 0, pb: '0px !important'}}>
                {/* <Grid container>
                    <Grid item xs={12} py={1} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Stack direction='row' alignItems='center'>
                            <Box width='10%' minWidth='60px' sx={{ '& img': { width: '100%', aspectRatio: '1/1', objectFit: 'cover' } }}>
                                <img src="https://static-01.daraz.com.np/p/0df7d2b9d089b763e7153211710ec643.jpg" alt="" />
                            </Box>
                            <Stack direction='row' alignItems='center' justifyContent={'space-between'} flexGrow={1} ml={1}>
                                <Box>
                                    <Typography variant='subtitle1' fontWeight={550}>Vitamin Supplements 10mg</Typography>
                                    <Typography variant='subtitle1'>2 X NPR 200</Typography>
                                </Box>
                                <Typography variant='subtitle1' fontWeight={550}>NPR 400</Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} py={1} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Stack direction='row' alignItems='center'>
                            <Box width='10%' minWidth='60px' sx={{ '& img': { width: '100%', aspectRatio: '1/1', objectFit: 'cover' } }}>
                                <img src="https://static-01.daraz.com.np/p/0df7d2b9d089b763e7153211710ec643.jpg" alt="" />
                            </Box>
                            <Stack direction='row' alignItems='center' justifyContent={'space-between'} flexGrow={1} ml={1}>
                                <Box>
                                    <Typography variant='subtitle1' fontWeight={550}>Vitamin Supplements 10mg</Typography>
                                    <Typography variant='subtitle1'>2 X NPR 200</Typography>
                                </Box>
                                <Typography variant='subtitle1' fontWeight={550}>NPR 400</Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} py={1} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Stack direction='row' alignItems='center'>
                            <Box width='10%' minWidth='60px' sx={{ '& img': { width: '100%', aspectRatio: '1/1', objectFit: 'cover' } }}>
                                <img src="https://static-01.daraz.com.np/p/0df7d2b9d089b763e7153211710ec643.jpg" alt="" />
                            </Box>
                            <Stack direction='row' alignItems='center' justifyContent={'space-between'} flexGrow={1} ml={1}>
                                <Box>
                                    <Typography variant='subtitle1' fontWeight={550}>Vitamin Supplements 10mg</Typography>
                                    <Typography variant='subtitle1'>2 X NPR 200</Typography>
                                </Box>
                                <Typography variant='subtitle1' fontWeight={550}>NPR 400</Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid> */}
                <OrderItemList/>
                <Box py={2}>
                    <Stack direction={'row'} justifyContent='space-between'>
                        <Typography variant='subtitle1'>
                            Sub Total
                        </Typography>
                        <Typography variant='subtitle1' color="primary" fontWeight={600}>
                            NPR. {charges.subTotal}
                        </Typography>
                    </Stack>
                    <Stack direction={'row'} justifyContent='space-between'>
                        <Typography variant='subtitle1'>
                            Shipping Charge
                        </Typography>
                        <Typography variant='subtitle1' color="primary" fontWeight={600}>
                            NPR. {charges.shipping_charge}
                        </Typography>
                    </Stack>
                    <Stack direction={'row'} justifyContent='space-between'>
                        <Typography variant='subtitle1'>
                            Total Discount
                        </Typography>
                        <Typography variant='subtitle1' color="primary" fontWeight={600}>
                            NPR. {charges.totalDiscount}
                        </Typography>
                    </Stack>
                </Box>
                <Stack direction={'row'} justifyContent='space-between' sx={{borderTop: 1, borderColor: 'divider'}}
                       py={2}>
                    <Typography variant='h6'>
                        Total Payable
                    </Typography>
                    <Typography variant='h6' color="primary" fontWeight={600}>
                        NPR. {charges.total_cost}
                    </Typography>
                </Stack>
            </CardContent>
        </Card>
    )
}


function calcAmount(products) {
    let discount = 0;
    let total = 0;
    products.forEach((i) => {
        total += i.cartCount * i.sale_price;
        discount += Math.round(
            (i.discountPercent * i.sale_price * i.cartCount) / 100
        );
    });
    return {discount, total}
}

function getShipping(shippingDetails, total) {
    if (shippingDetails === undefined || shippingDetails === null) return
    let shippingDetail = null
    shippingDetail = shippingDetails.filter((shippingDetail) => (total > shippingDetail.minimum && total < shippingDetail.maximum))
    shippingDetail = (shippingDetail === null && shippingDetails.length > 0) ? shippingDetails[shippingDetails.length - 1] : shippingDetail[0]
    return shippingDetail
}

export default OrderSummary
