import React from 'react'
import { useRefreshToken } from '../components/auth/refreshToken/useRefreshToken'
import { LoginStateContext } from '../contexts/LoginStateContext'
import { useContext } from 'react'
import { useEffect } from 'react'

function TokenRefresh() {
    const loginContext = useContext(LoginStateContext)
    const { refreshed, refreshing, token } = useRefreshToken(loginContext) || {}
    useEffect(()=> {
        loginContext.setNewToken(token)
    }, [refreshed])
}

export default TokenRefresh
