import {useQuery} from '@tanstack/react-query'
import axios from '../../../api'
import React from 'react'
import {useEffect} from 'react'
import {useState} from 'react'
import {useContext} from 'react'
import {createContext} from 'react'
import {LoginStateContext} from '../../../../contexts/LoginStateContext'
import UserKyc from './UserKyc'
import Loader from '../../Loader'
import {Alert, AlertTitle} from '@mui/material'

const GlobalFormContext = createContext(null)

function GlobalFormProvider({children}) {
    const [kycId, setKycId] = useState(null)
    const loginContext = useContext(LoginStateContext)
    const [globalFormInputs, setGlobalFormInputs] = useState({})
    const globalFormData = useQuery(
        ['global-form'],
        async () => (
            axios.get('api/view-global-form', {headers: {"Authorization": `Bearer ${loginContext.state.token}`}}).then(res => res.data).catch(err => err)
        )
    )
    const packageData = useQuery(
        ['package'],
        async () => (
            axios.get('/api/admin/userpackage', {headers: {'Authorization': `Bearer ${loginContext.state.token}`}})
                .then(res => res.data)
        )
    )
    if (localStorage.getItem('globalForm') === null) {
        localStorage.setItem('globalForm', JSON.stringify({}))
    }
    // useEffect(()=>{
    //   setGlobalFormInputs(JSON.parse(localStorage.getItem('globalForm')))
    // },[])


    if (globalFormData.isLoading || packageData.isLoading) {
        return <div className='d-flex justify-content-center'><Loader/></div>
    }
    console.log(packageData.data)
    if (packageData.data.package !== null && (packageData.data?.package?.package_status == 'Pending' || packageData.data?.package?.package_status == 'Activated')) {
        return (
            <GlobalFormContext.Provider
                value={{globalFormInputs, setGlobalFormInputs, kycId, setKycId, globalForm: globalFormData}}>
                {/* {children} */}
                <UserKyc/>
            </GlobalFormContext.Provider>
        )
    } else {
        return (
            <Alert severity='warning'>
                <AlertTitle>
                    You need to buy and pay for your package before filling global form
                </AlertTitle>
            </Alert>
        )
    }

}

function useGlobalForm() {
    const {globalFormInputs, setGlobalFormInputs} = useContext(GlobalFormContext)

    return {
        globalFormInputs,
        setGlobalFormInputs
    }
}

export function useKycId() {
    const {kycId, setKycId} = useContext(GlobalFormContext)

    return {kycId, setKycId}
}

export function useGlobalFormData() {
    const {globalForm} = useContext(GlobalFormContext)

    return {globalForm: globalForm.data, refetchGlobalForm: globalForm.refetch}
}

export {GlobalFormProvider, useGlobalForm}
