import axios from "../../api.js";
import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import WishListItem from "../../stores/WishListItem";
import swal from "sweetalert";
import "../stores/WishList.css";
import loaderRolling from "../Image/loader-rolling (1).gif";
import EmptyState from "../../EmptyState";
import {StoreContext} from "../../../contexts/StoreContext"

const WishList = () => {
    const [wishlist, setWishlist] = useState([]);
    const [loadingWish, setLoadingWish] = useState(true);
    const [updated, setUpdated] = useState(false);
    let token = localStorage.getItem("token");
    const navigate = useNavigate();

    const storeContext = React.useContext(StoreContext)

    // useEffect(()=>{
    //   storeContext.setBreadCrumbs([
    //     ['/', "Home"], ["/store", "Store"], ['current_page', "Your Wishlist"]
    //   ])
    // }, [])

    useEffect(() => {
        axios
            .get("api/admin/wishlist", {
                headers: {Authorization: `Bearer ${token}`},
            })
            .then((res) => {
                setWishlist(res.data);
                // storeContext.setWishlistCount(res.data.length)
                setLoadingWish(false);
            });
    }, [updated]);

    const removeAllWish = () => {
        swal({
            title: "Are you sure?",
            text: "This will remove all the product from your wishlist",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                axios
                    .delete("api/admin/wishlist/allWishlistDelete", {
                        headers: {Authorization: `Bearer ${token}`},
                    })
                    .then((res) => {
                        swal("Products Removed From WishList", {
                            icon: "success",
                        });
                        setUpdated(!updated);
                    });
            }
        });
    };

    const renderWishList = () => {
        if (loadingWish == true) {
            return (
                <div className="loading-screen text-center">
                    <img src={loaderRolling} alt=""/>
                </div>
            );
        }
        return wishlist.map((item) => {
            return (
                <WishListItem prod={item} setUpdated={setUpdated} updated={updated}/>
            );
        });
    };

    const navigateStore = () => {
        navigate("/store");
    };

    if (!loadingWish) {
        if (wishlist.length == 0) {
            return (
                <>
                    <div className="container">
                        <EmptyState redirectLink="/store"/>
                    </div>
                </>
            );
        }
    }
    return (
        <>
            <div className="container-lg mb-5 pt-4">
                <div className="mb-4"></div>
                <h2>Your Wishlist</h2>

                <div className="wishlist-container mt-4">{renderWishList()}</div>

                <div className="d-flex justify-content-end">
                    <div className="cart-buttons mt-3">
                        <button className="m-1 clear-all" onClick={removeAllWish}>
                            Remove All
                        </button>
                        <button className="m-1 continue" onClick={navigateStore}>
                            Continue Shopping
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WishList;
