import {Box, Card} from '@mui/material'
import {useQuery} from '@tanstack/react-query'
import React, {useContext, useEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import axios from '../../../api'
import {LabTestContext} from './LabTestProvider'

function LabDepartmentItem({children, active, bgColor = '#0D59A8', color = '#fff', data}) {
    const navigate = useNavigate()

    function handleClick(e) {
        navigate(`/lab-tests/${data.id}`)
    }

    return (
        <Box bgcolor={active ? "#52C8F4" : bgColor} borderTop={1} borderColor='#fff' p={2} color={color}
             sx={{cursor: 'pointer'}} onClick={handleClick}>
            {data.department}
        </Box>
    )
}

function LabTestSideBar() {
    const {department, setDepartment,} = useContext(LabTestContext)
    const {data, isLoading, isError} = useQuery(
        ['labTestDepartments'],
        async () => (
            axios.get('/api/lab-test/department').then(res => res.data).catch(err => err)
        )
    )
    const params = useParams()
    const idDepartment = params.department || null

    useEffect(() => {
        if (!isLoading) {
            const selectedDepartment = data.filter((item) => item.id == idDepartment) || []
            setDepartment(selectedDepartment[0] || {})
        }
    }, [params, isLoading])

    if (isLoading || isError) {
        return
    }
    if (data.length === 0) {
        return
    }
    return (
        <Box width='100%'>
            <Card>
                {
                    data.map((department, idx) => (
                        <LabDepartmentItem key={idx} data={department} active={idDepartment == department.id}/>
                    ))
                }
            </Card>
        </Box>
    )
}

export default LabTestSideBar
