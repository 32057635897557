import React from 'react'
import '../components/Packages.css'
// import Check from '../images/check.png'
import {Link} from "react-router-dom";
import {useState, useEffect} from 'react'
import axios from "./api.js"
import Package from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import parse from 'html-react-parser'
import DOMPurify from 'dompurify'

const Packages = () => {
    const [packages, setPackages] = useState([])

    useEffect(() => {
        axios.get('api/package').then((res) => {
            setPackages(res.data)
        })
            .catch((err) => {
                console.log()
            })
    }, [])

    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };
    //function to generate Dynamic packages.

    var stringToHTML = function (str) {
        if (str == "" || str == null) {
            return ""
        }
        return parse(DOMPurify.sanitize(str))
    };

    const getPackages = () => {
        return packages.map((pg) => {

            //splitting the descritpion into an array

            let desc = pg.description.replace(/(<([^>]+)>)/ig, ' ')
            desc = desc.split("\r\n")
            desc.shift();
            desc.pop();

            // let desc = stringToHTML(pg.description)
            // console.log()
            // console.log()


            //dynamically setting the classname by storing the classname in the varaible color
            let color = ""
            if (pg.id === 1) {
                color = "customized"
            } else if (pg.id === 2) {
                color = "silver"
            } else if (pg.id === 3) {
                color = "golden"
            } else if (pg.id === 4) {
                color = "platinum"
            } else if (pg.id === 5) {
                color = "vip"
            } else if (pg.id === 6) {
                color = "corporate"
            }

            return (
                <div key={pg.id} className={"col-lg-3 col-md-6 name-wrapper px-1 " + color}>
                    <div className="name text-center pt-3 pb-1">
                        <h3>{pg.package_type}</h3>
                    </div>
                    <div className="triangle"></div>
                    <div className="card h-100 pb-4">
                        <div className="card-header border-none">
                            {/* <h2 className="mb-0">{"Rs. " + pg.price}<span className="price-span">per family</span></h2>
                            <h4 className="my-0">/month</h4> */}
                        </div>
                        <div className="card-body" style={{position: 'relative'}}>
                            {/* {desc.map((d) => {
                                return (
                                    <>
                                        <div className='d-flex align-items-start mb-2 '>
                                            <img src={Check} alt="" className="mt-1" />
                                            <p className='mb-0 ms-1'>{d}</p>
                                        </div>
                                    </>
                                )
                            })} */}
                            <div className="pg-description">
                                <p dangerouslySetInnerHTML={{__html: `${pg.description}`}} className="mb-0"></p>
                            </div>
                            <div style={{position: 'absolute', bottom: '0', right: '0', left: '0'}}>
                                <div className='d-grid mb-2 mx-3'>
                                    <Link to="/single-package" state={{pkg: pg}} className='btn btn-primary'>Start
                                        Plan</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            )
        })
    }

    return (
        <div className="packages">
            <div className='container py-5'>
                <div className='text-white text-center heading'>
                    <h1>Packages</h1>
                    <p className='text-white'>Choose the best Package that is suitable for you and your family</p>
                </div>
                <Package {...settings} className="custom-slick">
                    {getPackages()}
                </Package>
                {/* <div className="col-lg-3 col-md-6 name-wrapper silver">
                        <div className="name text-center pt-3 pb-1">
                            <h3>Silver Membership</h3>
                        </div>
                        <div className="triangle"></div>
                        <div className="card">
                            <div className="card-header border-none">
                                <h2 className='mb-0'>Rs 6000<span className="price-span">per family</span></h2>
                                <h4 className='my-0'>/month</h4>
                            </div>
                            <div className="card-body">
                                <div className='d-flex align-items-center mb-2'>
                                    <img src={Check} alt="" />
                                    <p className='mb-0 ms-1'>Family visit once every 3 months</p>
                                </div>
                                <div className='d-flex align-items-center mb-2'>
                                    <img src={Check} alt="" />
                                    <p className='mb-0 ms-1'>Family visit once every 3 months</p>
                                </div>
                                <div className='d-flex align-items-center mb-2'>
                                    <img src={Check} alt="" />
                                    <p className='mb-0 ms-1'>Family visit once every 3 months</p>
                                </div>
                                <div className='mt-4 d-grid'>
                                    <a href="/" className='btn btn-primary'>Start Plan</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 name-wrapper golden">
                        <div className="name text-center pt-3 pb-1">
                            <h3>Golden Membership</h3>
                        </div>
                        <div className="triangle"></div>
                        <div className="card">
                            <div className="card-header border-none">
                                <h2 className='mb-0'>Rs 6000<span className="price-span">per family</span></h2>
                                <h4 className='my-0'>/month</h4>
                            </div>
                            <div className="card-body">
                                <div className='d-flex align-items-center mb-2'>
                                    <img src={Check} alt="" />
                                    <p className='mb-0 ms-1'>Family visit once every 3 months</p>
                                </div>
                                <div className='d-flex align-items-center mb-2'>
                                    <img src={Check} alt="" />
                                    <p className='mb-0 ms-1'>Family visit once every 3 months</p>
                                </div>
                                <div className='d-flex align-items-center mb-2'>
                                    <img src={Check} alt="" />
                                    <p className='mb-0 ms-1'>Family visit once every 3 months</p>
                                </div>
                                <div className='mt-4 d-grid'>
                                    <a href="/" className='btn btn-primary'>Start Plan</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 name-wrapper platinum">
                        <div className="name text-center pt-3 pb-1">
                            <h3>Platinum Membership</h3>
                        </div>
                        <div className="triangle"></div>
                        <div className="card">
                            <div className="card-header border-none">
                                <h2 className='mb-0'>Rs 6000<span className="price-span">per family</span></h2>
                                <h4 className='my-0'>/month</h4>
                            </div>
                            <div className="card-body">
                                <div className='d-flex align-items-center mb-2'>
                                    <img src={Check} alt="" />
                                    <p className='mb-0 ms-1'>Family visit once every 3 months</p>
                                </div>
                                <div className='d-flex align-items-center mb-2'>
                                    <img src={Check} alt="" />
                                    <p className='mb-0 ms-1'>Family visit once every 3 months</p>
                                </div>
                                <div className='d-flex align-items-center mb-2'>
                                    <img src={Check} alt="" />
                                    <p className='mb-0 ms-1'>Family visit once every 3 months</p>
                                </div>
                                <div className='mt-4 d-grid'>
                                    <a href="/" className='btn btn-primary'>Start Plan</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 name-wrapper customized">
                        <div className="name text-center pt-3 pb-1">
                            <h3>Customized Membership</h3>
                        </div>
                        <div className="triangle"></div>
                        <div className="card">
                            <div className="card-header border-none">
                                <h2 className='mb-0'>Rs 6000<span className="price-span">per family</span></h2>
                                <h4 className='my-0'>/month</h4>
                            </div>
                            <div className="card-body">
                                <div className='d-flex align-items-center mb-2'>
                                    <img src={Check} alt="" />
                                    <p className='mb-0 ms-1'>Family visit once every 3 months</p>
                                </div>
                                <div className='d-flex align-items-center mb-2'>
                                    <img src={Check} alt="" />
                                    <p className='mb-0 ms-1'>Family visit once every 3 months</p>
                                </div>
                                <div className='d-flex align-items-center mb-2'>
                                    <img src={Check} alt="" />
                                    <p className='mb-0 ms-1'>Family visit once every 3 months</p>
                                </div>
                                <div className='mt-4 d-grid'>
                                    <a href="/" className='btn btn-primary'>Start Plan</a>
                                </div>
                            </div>
                        </div>
                    </div> */}
            </div>
        </div>
    )
}

export default Packages
