import {faPeopleGroup} from '@fortawesome/free-solid-svg-icons'
import React, {useContext, useEffect, useRef} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import axios from "../../api"
import PackageItem from './PackageItem'
import "./PackageDetails.scss"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import swal from 'sweetalert'
import Loader from '../Loader'
import AuthButton from '../../custom_componets/AuthButton'
import {LoginStateContext} from '../../../contexts/LoginStateContext'
import useFamily from './useFamily'
import {chkConditions} from './chkConditions'
import {formatNumber} from '../../../helperFunctions/formatNumber'
import {useChkLogin} from '../../../middlewares/LoginMiddleware'

const initialCalc = {
    registration: 0,
    monthly: 0,
    totalMonth: 0,
    discountTotal: 0,
    discount: null,
    discountAmt: 0,
    result: 0
}


function PackageDetails() {
    const loginObj = useChkLogin()
    const [mainPackage, setMainPackage] = React.useState(null)
    const [loading, setLoading] = React.useState(true)
    const [calcRes, setCalcRes] = React.useState(initialCalc)
    const familySize = useRef(null)
    const paymentInterval = useRef(null)
    const params = useParams()
    const navigate = useNavigate()

    const  memberType = 'New Member'
    const year = "2"


    const loginContext = useContext(LoginStateContext)

    const familyDetails = useFamily(mainPackage)

    const fetchFamilySize = () => {
        if (familySize.current && !familyDetails.isLoading) {
            console.log()
            const family = familyDetails?.data;
            if (family !== null && family?.message === "success") {
                familySize.current.value = family.packagefee.family_size
            }
        }
    }

    useEffect(() => {
        fetchFamilySize()
    }, [familyDetails.isLoading])

    React.useEffect(() => {
        axios.get("api/package", {params: {slug: params.slug}})
            .then(res => {
                setMainPackage(res.data);
                setLoading(false);
            })
    }, [params.slug])


    const checkKyc = async (token) => {
        try {
            const res = await axios.get('/api/kycstatus', {headers: {"Authorization": `Bearer ${token}`}})
            if (res.data.message != "kyc verified.") {
                swal({
                    title: "Global Form Not Verified",
                    text: "Update Your Global Form.",
                    icon: "warning",
                }).then((result) => {
                    if (result) {
                        navigate("/user/kyc")
                    }
                })
                return false
            }
            return true
        } catch (err) {

        }

    }

    const checkFamilySize = async (token) => {
        try {
            const res = await axios.get(`api/admin/userpackage/calculate-amount/${mainPackage.id}`, {headers: {'Authorization': `Bearer ${token}`}})
            return res.data.packagefee.family_size;
        } catch (err) {
            return false
        }
    }

    const checkExistingPackage = async (token) => {
        try {
            const res = await axios.get('api/admin/userpackage', {headers: {'Authorization': `Bearer ${token}`}})
            console.log()
            if (res.data.package === null) {
                return 0
            }
            return res.data.package.year
        } catch (err) {
            return false
        }
    }

    const discardPackage = async (token) => {
        try {
            const res = await axios.delete(`api/admin/userpackage/discard`, {headers: {"Authorization": `Bearer ${token}`}})
            console.log()
            return true
        } catch (err) {
            console.log()
            swal("Error", "Server Error", 'error')
            return false;
        }
    }

    const showDialog = async (data, token) => {
        const res = await swal({
            title: data.title,
            text: data.message,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: 'Confirm',
            closeOnConfirm: false
        })
        if (res && (data.status === 0 || data.status === 6 || data.status == 15 || data.status == 17)) {
            navigate(data.navigate, {state: {redirect_to: window.location.pathname}})
        }
        if (res && data.status === 3) {
            const res = await discardPackage(token)
            return mainPackage.type === 4 ? navigate(`/school-package`) : res
        }
        return false
    }

    const onClick = async (token) => {
        if (mainPackage.type === 4 && loginObj.data.school_profile?.types != 'school') {
            if (!loginObj.isLoggedIn) {
                return navigate('/school-profile/' + mainPackage.slug)
            }
        }

        let chkUser = await chkConditions(token, mainPackage)

        if (!chkUser.isChk) {
            let res = await showDialog(chkUser, token)
            if (!res) {
                return
            }
        }
        let errorList = []

        let family = await checkFamilySize(token)

        if (family && (family) != familySize.current.value) {
            errorList.push(`Your Family Size is ${family} but you selected ${familySize.current.value} members`)
        }

        if (errorList.length > 0) {
            swal(
                {
                    title: "Selection Error",
                    text: errorList.join('. '),
                    icon: "warning",
                }
            )
            fetchFamilySize()
            setCalcRes(initialCalc)
        } else {
            if (mainPackage.type === 3 && chkUser.profile?.school_profile?.status === 'pending') {
                swal({
                    title: 'Info',
                    text: 'Your previous submission is pending.',
                    icon: 'info',
                    closeOnClickOutside: false
                })
            } else {
                // alert('Booked Package')
                navigate(`/${mainPackage.slug}/book-package`, {state: {payment_interval: paymentInterval.current.value}})
            }

        }
    }

    const calculateFee = () => {
        const feeInterval = {
            Yearly: {
                month: 12,
                discount: 0.05
            },
            Half_Yearly: {
                month: 6,
                discount: 0.02
            },
            Quarterly: {
                month: 3,
                discount: 0.01
            },
            Monthly: {
                month: 1,
                discount: 0
            }
        }

        let fee = mainPackage?.fee?.length && mainPackage.fees.filter((fee) => {
            return fee.family_size == familySize.current.value
        })[0]

       if(fee) {
           let totalmonth = 0;
           let discounttotal = 0;
           let res = 0;
           let discountAmt = 0;
           if (memberType === 'New Member') {
               totalmonth = familySize.current.value * fee.one_monthly_fee * feeInterval[paymentInterval.current.value].month;
               discountAmt = totalmonth * feeInterval[paymentInterval.current.value].discount
               discounttotal = totalmonth - discountAmt;
               res = fee.one_registration_fee + discounttotal;
               setCalcRes({
                   registration: fee.one_registration_fee,
                   monthly: fee.one_monthly_fee,
                   totalMonth: totalmonth,
                   discountTotal: discounttotal,
                   discount: feeInterval[paymentInterval.current.value].discount * 100,
                   discountAmt: discountAmt,
                   result: res
               })

           } else if (memberType === 'Existing Member') {
               if (year === '2') {
                   totalmonth = familySize.current.value * fee.two_monthly_fee * feeInterval[paymentInterval.current.value].month;
                   discountAmt = totalmonth * feeInterval[paymentInterval.current.value].discount
                   discounttotal = totalmonth - totalmonth * feeInterval[paymentInterval.current.value].discount;
                   res = fee.two_continuation_fee + discounttotal;
                   setCalcRes({
                       registration: fee.two_continuation_fee,
                       monthly: fee.two_monthly_fee,
                       totalMonth: totalmonth,
                       discountTotal: discounttotal,
                       discount: feeInterval[paymentInterval.current.value].discount * 100,
                       discountAmt: discountAmt,
                       result: res
                   })
               } else if (year === '3') {
                   totalmonth = familySize.current.value * fee.three_monthly_fee * feeInterval[paymentInterval.current.value].month;
                   discountAmt = totalmonth * feeInterval[paymentInterval.current.value].discount
                   discounttotal = totalmonth - totalmonth * feeInterval[paymentInterval.current.value].discount;
                   res = fee.three_continuation_fee + discounttotal;
                   setCalcRes({
                       registration: fee.three_continuation_fee,
                       monthly: fee.three_monthly_fee,
                       totalMonth: totalmonth,
                       discountTotal: discounttotal,
                       discount: feeInterval[paymentInterval.current.value].discount * 100,
                       discountAmt: discountAmt,
                       result: res
                   })
               }
           }
       }
    }

    return (
        <>
            {loading || loginObj.isLoading ?
                <div className="text-center">
                    <Loader/>
                </div>
                :
                <div className="package-details-page">
                    <div className="package-details py-5">
                        <div className="container">
                            <div className="row">
                                <div className="section-sub-title text-center">
                                    <h4 className='text-muted mb-0'>Pricing Plan</h4>
                                </div>
                                <div className="section-title text-center">
                                    <h1>{mainPackage.package_type}</h1>
                                </div>

                                <div className="package-content mt-4">
                                    <div className="row">
                                        <div className="col-lg-6 package-detail">
                                            <PackageItem data={mainPackage} hasBtn={false} key={mainPackage.id}/>
                                        </div>

                                        <div className="col-lg-6 package-fee-calculator-outer">
                                            <div className="package-fee-calculator-inner w-100">
                                                <div className="package-fee-calculator w-100">
                                                    <div className="pg-title pg-head-txt">
                                                        <span>PACKAGE FEE CALCULATOR</span>
                                                    </div>
                                                    <div className="calculator-input">
                                                        <div className="pg-label">
                                                            <span>Please Select The Size Of Your {mainPackage.type === 3 ? 'Employees' : mainPackage.type === 4 ? 'Students' : 'Family'}</span>
                                                        </div>
                                                        <select name="familysize" id="familysize"
                                                                className="form-select my-2 pg-label" ref={familySize}>
                                                            <FontAwesomeIcon icon={faPeopleGroup}/>
                                                            {mainPackage.fees != null &&
                                                                mainPackage.fees.map((fee) =>
                                                                    <option value={fee.family_size}
                                                                            key={fee.id}>{fee.family_size} Members</option>
                                                                )
                                                            }
                                                        </select>
                                                    </div>

                                                    <div className="calculator-input">
                                                        <div className="pg-label">
                                                            <span>Please Select Payment Interval</span>
                                                        </div>
                                                        <select name="paymentinterval" id="paymentinterval"
                                                                className='form-select my-2 pg-label'
                                                                ref={paymentInterval}>
                                                            <option value="Yearly">Yearly</option>
                                                            <option value="Half_Yearly">Half Yearly</option>
                                                            <option value="Quarterly">Quarterly</option>
                                                            <option value="Monthly">Monthly</option>
                                                        </select>
                                                    </div>

                                                    <div className="calculator-input">
                                                        <div className="btn btn-secondary" onClick={calculateFee}>
                                                            Calculate
                                                        </div>
                                                    </div>

                                                    <div className="calculator-results-wrapper"
                                                         style={{paddingLeft: '10px', paddingRight: '10px'}}>
                                                        <div className="table-responsive">
                                                            <table className='table text-left'>
                                                                <thead>
                                                                <tr>
                                                                    <th scope='col'>
                                                                        Fee Type
                                                                    </th>
                                                                    <th scope='col'>
                                                                        Amount
                                                                    </th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                <tr>
                                                                    <td>
                                                                        {memberType === 'New Member' &&
                                                                            'Total GD Enrollment Fee'
                                                                        }
                                                                        {memberType === 'Existing Member' &&
                                                                            'Total GD Continuation Fee'
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        <b className='text-info'>NPR. {formatNumber(calcRes.registration)}</b>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td>
                                                                        Package Subscription Fee per Person (Monthly)
                                                                    </td>
                                                                    <td>
                                                                        <b className='text-info'>NPR. {formatNumber(calcRes.monthly)}</b>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td>
                                                                        Total Package Subscription Fee
                                                                        ({paymentInterval.current != null && paymentInterval.current.value})
                                                                    </td>
                                                                    <td>
                                                                        <b className='text-info'>NPR. {formatNumber(calcRes.totalMonth)}</b>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td>
                                                                        Discount on total package subscription fee
                                                                        ({paymentInterval.current != null && paymentInterval.current.value}) {calcRes.discount}%
                                                                    </td>
                                                                    <td>
                                                                        <b className='text-danger'>-
                                                                            NPR. {formatNumber(calcRes.discountAmt)}</b>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td>
                                                                        <b>
                                                                            Total Payable Fee
                                                                        </b>
                                                                    </td>
                                                                    <td>
                                                                        <b className='text-success'>NPR. {formatNumber(calcRes.result)}</b>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="buy-btn px-4 py-3">
                                                    <AuthButton
                                                        callbackFn={onClick}
                                                        authVerfify={loginContext.verifyingLogin}
                                                        label='Buy Package'
                                                        component='div'
                                                        className='btn btn-primary'
                                                        auth={mainPackage.type !== 4 || loginObj.isLoggedIn}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default PackageDetails
