import React, {useEffect, useState} from "react";
// import Doctors from "../images/services/doctor.png";
import Slider from "react-slick";
import "./TopDoctors.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "./api.js";
import {Link} from "react-router-dom"
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle} from "@fortawesome/free-solid-svg-icons";

const TopDoctors = () => {

    // const token = localStorage.getItem('token');
    let navigate = useNavigate();
    const consultHandler = (id, slug) => {
        navigate(`/doctor/${slug}`, {state: {id: id}});
    }
    const [topDoctors, setTopDoctors] = useState([]);
    useEffect(() => {
        axios.get("/api/top-doctors")
            .then((res) => {
                setTopDoctors(res.data);
            })
            .catch((err) => {
                console.log();
            });
    }, []);
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: false,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    function chkAvailableSlots(bookings) {
        let res = false;
        bookings.forEach((booking, index) => {
            if (booking.slots.length > 0) {
                res = true;
                return;
            }
        })
        return res;
    }

    return (
        <div className="container my-5">
            <div className="mb-5">
                <div className="row">
                    <div className="col-md-8">
                        <h5 className="dr-appointment">Appointments With Top Doctors</h5>
                        <p className="para-text ">
                            Talk to Doctors regarding your health issue
                        </p>
                    </div>
                    <div className="col-md-4 text-end">
                        <Link
                            to="/booking/doctor"
                            className="btn view-services btn-outline-dark p-0 px-3 py-2"
                        >
                            View all Doctors
                        </Link>
                    </div>
                </div>
            </div>
            {/* <div className="row"> */}
            <Slider {...settings} className="top-doctor-slider custom-slick">
                {topDoctors.map((topDoctor) =>
                    <div className="col-md-3" key={topDoctor.id}>
                        <div className="card dr-card">
                            <img src={topDoctor.image_path} className="card-img-top doctor-img" alt=""/>
                            <div className="card-body doctors-container">
                                <h4 className="text-heading mb-0">{topDoctor.salutation} {topDoctor.user.name}</h4>
                                <h5 className="dr-position mb-0">
                                    <span>{topDoctor.specialization}</span>
                                </h5>
                                <div className="mt-1 d-flex align-items-center justify-content-between">
                                    <button className="btn doctor-consult px-4 "
                                            onClick={() => consultHandler(topDoctor.id, topDoctor.slug)}>
                                        Consult Now
                                    </button>
                                </div>
                            </div>
                            <div className="slot-status">
                                {
                                    chkAvailableSlots(topDoctor.bookings) &&
                                    <>
                                        <div className="outer-dot border border-success">
                                        </div>
                                        <div className="blink-dot inner-dot bg-success mb-0"
                                             style={{fontSize: '14px'}}></div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                )}


                {/* <div className="col-md-3">
          <div className="card dr-card" >
            <img src={Doctors} className="card-img-top doctor-img" alt="" />
            <div className="card-body doctors-container">
              <h4 className="text-heading mb-0">Dr.XYZ</h4>
              <h5 className="dr-position mb-0">
                <span>Dentist</span>
              </h5>
              <p className="card-text">
                Next available time:
                <span className="available-time"> Aug 12,12:22pm</span>
              </p>
              <div className="d-grid ">
              <a href="/" class="btn doctor-consult text-white ">
               Consult Now
              </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card dr-card" >
            <img src={Doctors} className="card-img-top doctor-img" alt="" />
            <div className="card-body doctors-container">
              <h4 className="text-heading mb-0">Dr.XYZ</h4>
              <h5 className="dr-position mb-0">
                <span>Dentist</span>
              </h5>
              <p className="card-text">
                Next available time:
                <span className="available-time"> Aug 12,12:22pm</span>
              </p>
              <div className="d-grid ">
              <a href="/" class="btn doctor-consult text-white ">
               Consult Now
              </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card dr-card" >
            <img src={Doctors} className="card-img-top doctor-img" alt="" />
            <div className="card-body doctors-container">
              <h4 className="text-heading mb-0">Dr.XYZ</h4>
              <h5 className="dr-position mb-0">
                <span>Dentist</span>
              </h5>
              <p className="card-text">
                Next available time:
                <span className="available-time"> Aug 12,12:22pm</span>
              </p>
              <div className="d-grid ">
              <a href="/" class="btn doctor-consult text-white ">
               Consult Now
              </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card dr-card" >
            <img src={Doctors} className="card-img-top doctor-img" alt="" />
            <div className="card-body doctors-container">
              <h4 className="text-heading mb-0">Dr.XYZ</h4>
              <h5 className="dr-position mb-0">
                <span>Dentist</span>
              </h5>
              <p className="card-text">
                Next available time:
                <span className="available-time"> Aug 12,12:22pm</span>
              </p>
              <div className="d-grid ">
              <a href="/" class="btn doctor-consult text-white ">
               Consult Now
              </a>
              </div>
            </div>
          </div>
        </div> */}
            </Slider>
            {/* </div> */}
        </div>

    );
};

export default TopDoctors;
