import {Alert, AlertTitle, Box, Button, CircularProgress, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react'
import Shield from '../../../images/shield.jpg'
import {PhoneVerifyInput} from '../../custom_componets/PhoneVerifyInput'
import {LoginStateContext} from '../../../contexts/LoginStateContext'
import {useContext} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import axios from '../../api'
import {setCookie} from '../../../helperFunctions/cookiesHelper'
import {toast} from 'react-hot-toast'

function TwoFactorAuth() {
    const loginContext = useContext(LoginStateContext)
    const location = useLocation()
    const [otp, setOtp] = useState()
    const token = location.state
    const [verifying, setVerifying] = useState(false)
    const [canSubmit, setCanSubmit] = useState(false)
    const [error, setError] = useState(false)
    const navigate = useNavigate()

    function handleOTP() {
        setVerifying(true)
        axios.post(`api/verify-login-otp`, {otp: otp}, {headers: {'Authorization': `Bearer ${token}`}})
            .then(res => {
                setCookie('token', res.data.message.token, false)
                toast.success("OTP logged in successfully.")
                navigate('/')
            })
            .catch(err => {
                setError(true)
            })
            .finally(() => {
                setVerifying(false)
            })
    }

    function getInput(value, lastInput) {
        setOtp(value)
        setError(false)
        // console.log()
        setCanSubmit(lastInput)
    }

    useEffect(() => {
        if (canSubmit) {
            handleOTP()
        }
    }, [canSubmit])

    return (
        <Box
            minHeight='100vH'
            width='100%'
            position='relative'
        >
            <Box
                position='absolute'
                top='50%'
                left='50%'
                background='#fff'
                width='100%'
                maxWidth='600px'
                px={{md: 5, xs: 2}}
                border={1}
                borderColor='divider'
                overflow='hidden'
                display='flex'
                flexDirection='column'
                alignItems='center'
                boxShadow='rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;'
                sx={{
                    transform: 'translate(-50%, -50%)'
                }}
            >
                <Box pt={4} pb={2} width='100%' display='flex' justifyContent='center'
                     sx={{'& img': {width: '15%', margin: 'auto'}}}>
                    <img src={Shield} alt=""/>
                </Box>
                <Box py={2}>
                    <Typography variant='h4' fontWeight={600} textAlign='center'>Authenticate Your Account</Typography>
                </Box>
                <Box py={2}>
                    <Typography variant='h6' textAlign='center'>
                        Protecting your account is our top priority. Please confirm your account by entering the
                        authorization code sent to your email.
                    </Typography>
                </Box>
                <Box py={2}>
                    <PhoneVerifyInput length={6} onChange={getInput}/>
                </Box>
                {
                    error && (
                        <Box>
                            <Typography variant='subtitle1' color='error.main'>
                                Please enter correct OTP.
                            </Typography>
                        </Box>
                    )
                }

                <Box pt={6} pb={2} display='flex' width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography variant='subtitle1'>
                        It may take a minute to recieve your code. <br/>
                        Haven't received it? <a href="/">Resend a new code.</a>
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default TwoFactorAuth
