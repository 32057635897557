import {faCaretRight, faCircleCheck} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react'
import {useState} from 'react';
import {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import axios from "../../api.js";
import Loader from '../Loader.js';
import NurseShift from './NurseShift.js';

const NurseProfile = () => {
    const token = localStorage.getItem('token');
    const [nurse, setNurse] = useState({});
    const [loading, setLoading] = useState(true);
    const params = useParams();

    useEffect(() => {
        axios
            .get('/api/nurse', {params: {slug: params.slug}})
            .then((res) => {
                setNurse(res.data.data[0]);
                setLoading(false);
            })
            .catch((err) => {
                console.log();
            });
    }, [params.slug])

    return (
        <>
            {loading ?
                <div className="text-center">
                    <Loader/>
                </div>
                :
                <div className="doctor-prof-page">
                    <div className="container py-4">
                        <div className="row pt-4">
                            <div className="col-12 col-lg-7">
                                <div className="profile-head d-flex align-items-center">
                                    <div className="profile-image">
                                        <img src={nurse.image_path} alt=""/>
                                    </div>
                                    <div className="profile-desc ms-3">
                                        <div className="profile-desc-name">
                                            <h2>{nurse.user.name + " (" + nurse.qualification + ")"}</h2>
                                        </div>
                                        <div className="profile-desc-department label-txt d-flex align-items-center">
                                            <FontAwesomeIcon icon={faCaretRight} size="lg" className='me-2'/>
                                            <span>Nurse Type: {nurse.nurse_type}</span>
                                        </div>
                                        <div className="profile-desc-experience label-txt d-flex align-items-center">
                                            <FontAwesomeIcon icon={faCaretRight} size="lg" className='me-2'/>
                                            <span>Experience: {nurse.year_practiced} years</span>
                                        </div>
                                        <div className="profile-desc-experience label-txt d-flex align-items-center">
                                            <FontAwesomeIcon icon={faCaretRight} size="lg" className='me-2'/>
                                            <span>Booking Fee: Rs. {nurse.fee}</span>
                                        </div>
                                        <div className="profile-desc-verified d-flex align-items-center">
                                            <FontAwesomeIcon icon={faCircleCheck} size="sm" className='me-2'/>
                                            <span className="label-txt">GD Verified</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="profile-nav d-flex align-items-center mt-3">
                                    <div className="profile-nav-item me-3 label-txt">
                                        <span>About</span>
                                    </div>
                                    <div className="profile-nav-item mx-3 label-txt">
                                        <span>Reviews</span>
                                    </div>
                                    <div className="profile-nav-item mx-3 label-txt">
                                        <span>Location</span>
                                    </div>
                                    <div className="profile-nav-item mx-3 label-txt">
                                        <span>FAQs</span>
                                    </div>
                                </div>

                                <div className="about-section py-4 mb-4" id='review-part'>
                                    <div className="about-section-infos row g-4">

                                        <div className="about-info col-6">
                                            <div className="about-info-title label-txt">
                                                <span>Qualifications</span>
                                            </div>
                                            <div className="about-info-detail">
                                                <div>{nurse.qualification}</div>
                                            </div>
                                        </div>

                                        <div className="about-info col-6">
                                            <div className="about-info-title label-txt">
                                                <span>Medical Registry</span>
                                            </div>
                                            <div className="about-info-detail">
                                                <div>Nepal Nursing Council: {nurse.nnc_no}</div>
                                                {/* <div>National Specialist Register: 35131</div> */}
                                            </div>
                                        </div>

                                        <div className="about-info col-6">
                                            <div className="about-info-title label-txt">
                                                <span>NNC Number</span>
                                            </div>
                                            <div className="about-info-detail">
                                                <div>{nurse.nnc_no}</div>
                                            </div>
                                        </div>

                                        <div className="about-info col-6">
                                            <div className="about-info-title label-txt">
                                                <span>Gender</span>
                                            </div>
                                            <div className="about-info-detail">
                                                <div>{nurse.gender}</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-5">
                                <div className="card border-0 h-100">
                                    <div className="card-header">
                                        <h4 className='card-title mb-0'>Available Shifts</h4>
                                    </div>
                                    <div className="card-body single-doctor-booking px-0">
                                        <NurseShift token={token} nurse={nurse}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default NurseProfile
