import axios from "../api.js";
import React, {useCallback, useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import "../../components/auth/SignUp.css";
import 'react-phone-number-input/style.css'
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input'
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import Logo from '../../images/white-logo.png';
import Background from '../../images/slider.png';
import {FileUploader} from "react-drag-drop-files";
import swal from "sweetalert";
import ReCAPTCHA from "react-google-recaptcha";
import {useRef} from "react";
import useCaptchaVerify from "./captchaVerify.js";
import captchaVerify from "./captchaVerify.js";
import AuthButton from "../custom_componets/AuthButton.jsx";
import {GoogleReCaptcha} from "react-google-recaptcha-v3";
import {Box, IconButton, InputBase} from "@mui/material";
import {VisibilityOutlined} from "@mui/icons-material";
import FormInputBase, {FormInputPassword} from "./FormInputBase.jsx";

const imageTypes = ["JPG", "PNG", "GIF"];
const fileTypes = ["PDF"];
const SignUp = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const currentParams = Object.fromEntries(searchParams)
    const [termsAgree, setTermsAgree] = useState(false)
    const [validateError, setValidateError] = useState({})
    const [submitting, setSubmitting] = useState(false)
    const [focused, setFocused] = useState()

    if (!currentParams?.type) {
        console.log()
        currentParams.type = "none"
    }
    // if (Object.keys(currentParams).length == 0) {

    // }
    console.log('Hello world')
    const {
        register,
        handleSubmit,
        watch,
        setError,
        clearErrors,
        formState: {errors},
        control
    } = useForm({
        defaultValues: {
            email: currentParams?.email ? currentParams.email : '',
            name: currentParams?.full_name ? currentParams.full_name : '',
        }
    });
    const password = watch('password')
    const {
        register: register2,
        handleSubmit: handleSubmit2,
        watch: watch2,
        formState: {errors: errors2},
        control: control2
    } = useForm();
    const {
        register: register3,
        handleSubmit: handleSubmit3,
        watch: watch3,
        formState: {errors: errors3},
        control: control3
    } = useForm();
    const {
        register: register4,
        handleSubmit: handleSubmit4,
        watch: watch4,
        formState: {errors: errors4},
        control: control4
    } = useForm();
    let navigate = useNavigate();
    // const password = useRef({});
    // password.current = watch("password", "");
    const [image, setImage] = useState(null);
    const [file, setFile] = useState();
    const [image2, setImage2] = useState();
    const [file2, setFile2] = useState();
    const [image3, setImage3] = useState();
    const [file3, setFile3] = useState();
    const [captchErr, setCaptchaErr] = useState(null);
    const [errorList, setErrorList] = useState([]);
    const [errorList2, setErrorList2] = useState([]);
    const [errorList3, setErrorList3] = useState([]);
    const [errorList4, setErrorList4] = useState([]);
    const [captchaToken, setCaptchaToken] = useState()
    const [refreshCaptcha, setRefreshCaptcha] = useState(false)
    const [emailExist, setEmailExist] = useState(false)
    const [pwdMismatch, setPwdMismatch] = useState(false)

    const captchaRef = useRef(null)

    const captchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY

    async function validateHuman(token) {
        const secret = process.env.REACT_APP_RECAPTCHA_SECRET_KEY
        // return captchaVerify(secret, token)
        return true
    }

    const onVerify = useCallback((token) => {
        setCaptchaToken(token)
    }, [refreshCaptcha]);

    const onSubmit = async (data) => {
        console.log();
        setSubmitting(true)
        const fd = new FormData();
        for (var key in data) {
            fd.append(key, data[key]);
        }
        fd.append('image', image);
        fd.append('file', file);
        fd.append('image', image2);
        fd.append('file', file2);
        fd.append('image', image3);
        fd.append('file', file3);

        const secret = process.env.REACT_APP_RECAPTCHA_SECRET_KEY
        // const captchaToken = await captchaRef.current.getValue()
        const response = await captchaVerify(secret, captchaToken)
        setRefreshCaptcha(prev => !prev)

        if (true) {
            axios.post('/api/register', fd)
                .then(res => {
                    // captchaRef.current.reset()
                    setSubmitting(false)
                    if (res.data.status === 'true') {
                        swal({
                            title: "User Registered successfully!",
                            text: "Please visit your email and verify your account",
                            icon: "success",
                            closeOnClickOutside: false
                        }).then((value) => {
                            if (value) {
                                navigate("/login?verify=verify_email", {replace: true});
                            }
                        });
                    }
                })
                .catch((err) => {
                    console.log()
                    setSubmitting(false)
                    setValidateError(err.response.data.message)
                    // captchaRef.current.reset()
                    if (fd.get('role') === '6') {
                        setErrorList(err.response.data.message);
                    }
                    if (fd.get('role') === '4') {
                        setErrorList2(err.response.data.message);
                    }
                    if (fd.get('role') === '5') {
                        setErrorList3(err.response.data.message);
                    }
                    if (fd.get('role') === '7') {
                        setErrorList4(err.response.data.message);
                    }

                });
        } else {
            setCaptchaErr("Please Verify The Captcha")
        }

    };
    const [departments, setDepartment] = useState([]);
    const [vendortypes, setVendorType] = useState([]);

    useEffect(() => {
        const handleFocusOut = (e) => {
            console.log('focused out', e.target)
            if (e.target.classList.contains('is-invalid')) {
                return
            }
            if (e.target.hasAttribute('required') && !e.target.value) {
                e.target.classList.add('is-invalid')
                e.target.classList.add('border-danger')
            } else {
                e.target.classList.remove('is-invalid')
                e.target.classList.remove('border-danger')
            }
        }

        const handleFocus = (e) => {
            e.target.classList.remove('is-invalid')
            e.target.classList.remove('border-danger')
        }

        const inputs = document.getElementsByTagName('input')
        for (let i = 0; i < inputs.length; i++) {
            inputs[i].addEventListener('focusout', handleFocusOut)
            inputs[i].addEventListener('focus', handleFocus)
        }

        return () => {
            for (let i = 0; i < inputs.length; i++) {
                inputs[i].removeEventListener('focusout', handleFocusOut)
                inputs[i].removeEventListener('focus', handleFocus)
            }
        }
    }, [])

    useEffect(() => {
        axios
            .get('/api/admin/department')
            .then((res) => {
                setDepartment(res.data);
            })
            .catch((err) => {
                console.log();
            });
    }, []);
    useEffect(() => {
        axios
            .get('/api/vendor-type')
            .then((res) => {
                setVendorType(res.data);
            })
            .catch((err) => {
                console.log();
            });
    }, []);

    //function to view image before uploading
    const renderImage = (img) => {
        if (img == null || img.length == 0) {
            return
        }

        console.log()
        return (
            <div className="imgUpload">
                <img src={img} alt="upload img"/>
            </div>

        )
    }

    //function to view file before uploading
    const renderFile = (file) => {
        if (file == null || file.length == 0) {
            return
        }
        return (
            <div className="viewFileUpload">
                <iframe src={file} width="100%"/>
                Review PDF <a href={file} target="_blank">Click here</a>
            </div>

        )
    }

    const verifyDuplicateEmail = () => {
        return async (e) => {
            console.log(e.target.value)
            return axios
                .get(`api/check-existing-email?email=${e.target.value}`)
                .then((res) => {
                    if (res.data.message == "Email Already Existed.") {
                        setEmailExist(true)
                        return true
                    } else {
                        setEmailExist(false)
                        return false
                    }
                })
                .catch((err) => console.log(err))
        }

    }

    return (
        <div className="container-fluid auth register">
            <div className="row">
                <div className="col-lg-6 image-side px-0">
                    <img src={Background} alt="" className='bg-image'/>
                    <div className="image-overlay"></div>
                    <div className="text-center bg-logo mx-auto">
                        <img src={Logo} alt=""/>
                    </div>
                </div>
                <div className="col-lg-6 offset-lg-6 login-form">
                    <div className="card border-0">
                        <div>
                            <h3 className="card-title">Sign up</h3>
                            <p>Sign up for free to access any of our services </p>
                        </div>
                        <div className="tab-content mt-3" id="pills-tabContent">
                            {
                                (currentParams.type == "none" || currentParams.type == "user") &&
                                <div className={"tab-pane fade show active"} id="pills-user" role="tabpanel"
                                     aria-labelledby="pills-user-tab">
                                    <form onSubmit={handleSubmit(onSubmit)} className="form">
                                        <div className="row">
                                            <input type="hidden" {...register("role")} value="6"/>
                                            <input type="hidden" {...register("is_verified")} value="0"/>
                                            <div className="col-md-4">
                                                <label className="form-label">First Name <small
                                                    className="text-danger">*</small></label>
                                                <input
                                                    onChange={() => console.log('first name changing...')}
                                                    type="text"
                                                    className="form-control input-field"
                                                    placeholder="Enter your first name"
                                                    required
                                                    {...register("first_name")}
                                                />
                                                {errors.name?.type === "required" && (
                                                    <p>Please enter your name.</p>
                                                )}
                                            </div>
                                            <div className="col-md-4">
                                                <label className="form-label">Middle Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control input-field"
                                                    placeholder="Enter your middle name"
                                                    {...register("middle_name")}
                                                />
                                                {errors.name?.type === "required" && (
                                                    <p>Please enter your name.</p>
                                                )}
                                            </div>
                                            <div className="col-md-4">
                                                <label className="form-label">Last Name <small
                                                    className="text-danger">*</small></label>
                                                <input
                                                    type="text"
                                                    className="form-control input-field"
                                                    placeholder="Enter your last name"
                                                    required
                                                    {...register("last_name")}
                                                />
                                                {errors.name?.type === "required" && (
                                                    <p>Please enter your name.</p>
                                                )}
                                            </div>
                                            <div className="col-md-12">
                                                <label className="form-label">Email <small
                                                    className="text-danger">*</small></label>
                                                <input
                                                    type="email"
                                                    placeholder="Enter Your Email"
                                                    className="form-control input-field"
                                                    required
                                                    {
                                                        ...(() => {
                                                            const registerObj = register("email", {
                                                                pattern: {
                                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                    message: "Please enter a valid email.",
                                                                },
                                                            })

                                                            return {
                                                                ...registerObj, onChange: (e) => {
                                                                    const elem = e.target;
                                                                    registerObj.onChange(e)
                                                                    verifyDuplicateEmail()(e).then(res => {
                                                                        if (res) {
                                                                            e.target.classList.add('is-invalid')
                                                                            e.target.classList.add('border-danger')
                                                                        } else {
                                                                            e.target.classList.remove('is-invalid')
                                                                            e.target.classList.remove('border-danger')
                                                                        }
                                                                    })
                                                                }
                                                            }
                                                        })()
                                                    }
                                                />
                                                {(errors.email || emailExist) && (
                                                    <p>{emailExist ? 'Email Already Exists' : errors.email.message}</p>
                                                )}
                                                {errorList.email && <p>{errorList.email}</p>}
                                            </div>
                                            <div className="col-md-12">
                                                <label className="form-label">Phone <small
                                                    className="text-danger">*</small></label>
                                                <Controller
                                                    name="phone"
                                                    control={control}
                                                    defaultValue={currentParams?.phone ? `+${currentParams.phone}` : ''}
                                                    required
                                                    rules={{
                                                        required: "Please enter a valid phone.",
                                                        validate: (value) => isValidPhoneNumber(value)
                                                    }}
                                                    render={({field: {onChange, value}}) => (
                                                        (
                                                            <PhoneInput
                                                                value={value}
                                                                onChange={onChange}
                                                                required
                                                                placeholder="Enter Your Phone Number"
                                                                defaultCountry="NP"
                                                                id="phone-input1"
                                                                international
                                                                countryCallingCodeEditable={false}
                                                                addInternationalOption={false}
                                                            />
                                                        )
                                                    )
                                                    }
                                                />
                                                {errors.phone && (
                                                    <p>Please enter a valid phone.</p>
                                                )}
                                                {errorList.phone && <p>{errorList.phone}</p>}
                                            </div>
                                            <div className="col-md-12 mb-2">
                                                <label className="form-label">Password <small
                                                    className="text-danger">*</small></label>
                                                <FormInputPassword>
                                                    {
                                                        (ref, show) => (
                                                            <input
                                                                type={show ? 'text' : 'password'}
                                                                ref={ref}
                                                                placeholder="Enter A Password"
                                                                required
                                                                className="form-control input-field"
                                                                {...register("password", {
                                                                    minLength: {
                                                                        value: 8,
                                                                        message: "Password must have at least 8 characters",
                                                                    },
                                                                })}
                                                            />

                                                        )
                                                    }

                                                </FormInputPassword>
                                                <small className="text-info fw-bold">*Required:Uppercase and lowercase
                                                    letter(A,z). Numeric character(0-9). Special Character(!, %, @, #,
                                                    etc.)</small>
                                                {errors.password && <p>{errors.password.message}</p>}
                                                {validateError.password && <p>{validateError.password}</p>}
                                            </div>
                                            <div className="col-md-12">
                                                <label className="form-label">Confirm Password <small
                                                    className="text-danger">*</small></label>
                                                <FormInputPassword>
                                                    {
                                                        (ref, show) => (
                                                            <input
                                                                type={show ? 'text' : 'password'}
                                                                placeholder="Re-enter Your Password"
                                                                ref={ref}
                                                                className='form-control input-field'
                                                                required
                                                                {
                                                                    ...(() => {
                                                                        const registerObj = register("password_confirmation", {
                                                                            validate: {
                                                                                validate: (value) => {
                                                                                    if (watch('password') !== value) {
                                                                                        return "The password confirmation does not match."
                                                                                    }
                                                                                }
                                                                            },
                                                                        })
                                                                        return {
                                                                            ...registerObj, onChange: (e) => {
                                                                                registerObj.onChange(e)
                                                                                if (e.target.value !== password) {
                                                                                    e.target.classList.add('is-invalid')
                                                                                    e.target.classList.add('border-danger')
                                                                                    setError('password_confirmation', {
                                                                                        type: 'manual',
                                                                                        message: 'The password confirmation does not match.'
                                                                                    })
                                                                                } else {
                                                                                    e.target.classList.remove('is-invalid')
                                                                                    e.target.classList.remove('border-danger')
                                                                                    clearErrors('password_confirmation')
                                                                                }
                                                                            }
                                                                        }
                                                                    })()
                                                                }

                                                                // {...register("password_confirmation", {
                                                                //     validate: {
                                                                //         validate: (value) => {
                                                                //             if (watch('password') !== value) {
                                                                //                 return "The password confirmation does not match."
                                                                //             }
                                                                //         }
                                                                //     },
                                                                // })}
                                                            />

                                                        )
                                                    }

                                                </FormInputPassword>
                                                {errors.password_confirmation && (
                                                    <p>{errors.password_confirmation.message}</p>
                                                )}
                                            </div>
                                            <div className="col-md-12 mt-3">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value=""
                                                           id="userChecked" onChange={(e) => {
                                                        setTermsAgree(e.target.checked)
                                                    }}/>
                                                    <label className="form-check-label" htmlFor="userChecked">
                                                        Agree to our Terms of use and Privacy Policy
                                                    </label>
                                                </div>
                                            </div>
                                            <h6 className='my-4'>By continuing, you agree to the <a
                                                href="/termscondition"> Terms of use</a> and <a href="/termscondition">Privacy
                                                Policy</a>. </h6>
                                            <div className="col-md-12 mt-3">
                                                <GoogleReCaptcha
                                                    // ref={captchaRef}
                                                    onVerify={onVerify}
                                                    refreshReCaptcha={refreshCaptcha}
                                                />
                                                {
                                                    captchErr !== null
                                                    &&
                                                    <div className="alert alert-danger">
                                                        {captchErr}
                                                    </div>
                                                }

                                            </div>
                                            <div className="mt-4 d-grid">
                                                <AuthButton
                                                    type='submit'
                                                    component='button'
                                                    className='btn px-5 btn-primary'
                                                    label='Sign Up'
                                                    loading={submitting}
                                                    disabled={!termsAgree}
                                                />
                                            </div>
                                            <div className="text-center mt-3">
                                                <h6>Or</h6>
                                                <h6>Already have an account? <Link to="/login"
                                                                                   className='signup-btn'>Login</Link>
                                                </h6>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUp;
