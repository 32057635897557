import {Backdrop, Box, Button, CircularProgress} from '@mui/material'
import axios from '../../../../api'
import React, {useContext, useEffect, useRef, useState} from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {
    InputField,
    NepaliDateInput,
    PhoneNumberInput,
    SelectInput,
    SelectOption
} from '../../../../custom_componets/FormComponents'
import generateInitialState from '../helpers/generateInitialState'
import {LoginStateContext} from '../../../../../contexts/LoginStateContext'
import {useGlobalForm, useGlobalFormData, useKycId} from '../GlobalFormProvider'

// function base64ToData() {
//     let data = 'https://demo.ghargharmadoctor.com/storage/images/fruits_1669964069.jpg'
//     data
// }

function Personal({handleBack, handleNext, allFormData, setAllFormData, errors, setErrors}) {
    const {globalFormInputs, setGlobalFormInputs} = useGlobalForm()
    const {setKycId} = useKycId()
    const {globalForm, refetchGlobalForm} = useGlobalFormData()
    console.log()
    const [inputs, setInputs] = useState(generateInitialState([
        "first_name",
        "last_name",
        "middle_name",
        "gender",
        "nationality",
        "birth_date",
        "salutation",
        "account_branch",
        "currency",
        "mobile_number",
        "email",
        "country",
    ], globalForm))
    const country = useCountry()
    const countryData = country.data
    // console.log()
    // console.log()
    // console.log()
    const token = useContext(LoginStateContext).state.token
    const user = useContext(LoginStateContext).state.data

    const formRef = useRef(null)

    const [open, setOpen] = useState(false)

    useEffect(() => {
        if (Object.keys(globalForm).length > 0) {

            return
        }
        if (Object.keys(allFormData).length <= 0) {
            let member = user.member
            let nameArr = member.name.split(" ") || []
            let firstName = nameArr.shift() || ''
            let lastName = nameArr.pop() || ''
            let middleName = nameArr.join(' ') || ''
            setInputs(prev => ({
                ...prev,
                first_name: firstName,
                middle_name: middleName,
                last_name: lastName,
                birth_date: user.dob || '',
                mobile_number: member.phone || '',
                gender: user?.gender?.toLowerCase() || '',
                email: member.email,
                country: 'Nepal',
                currency: 'NPR',
                nationality: 'Nepalese'
            }))
        }

    }, [])


    function handleChange(e) {
        setInputs({...inputs, [e.target.name]: e.target.value})
    }

    async function handleSubmit(e) {
        e.preventDefault()
        setOpen(true)
        setErrors({})
        // console.log()
        let age = Math.floor((new Date().getTime() - new Date(inputs.birth_date).getTime()) / 31536000000).toFixed()
        setOpen(false)
        if (false) {
            console.log()
            alert('false')
            setErrors((prev) => ({...prev, mobile_number: {message: "Invalid Mobile Number"}}))
            return;
        }
        setAllFormData({...allFormData, ...inputs})
        axios.post('api/kyc/personal-information', inputs, {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
            console.log()
            setKycId(res.data.kyc_id)
            handleNext()
            refetchGlobalForm()
        }).catch(err => console.log())
    }

    return (
        <React.Fragment>
            <form className='form-horizontal' onSubmit={handleSubmit}>
                <div className="card">
                    <div className="card-header">
                        <h3 className="m-0 p-0">Personal Information</h3>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-4">
                                <InputField
                                    label="First Name"
                                    name="first_name"
                                    value={inputs.first_name}
                                    error={errors?.first_name}
                                    errorMsg={errors?.first_name ? errors.first_name.message : null}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField
                                    label="Middle Name"
                                    name="middle_name"
                                    value={inputs.middle_name}
                                    error={errors?.middle_name}
                                    errorMsg={errors?.middle_name ? errors.middle_name.message : null}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField
                                    label="Last Name"
                                    name="last_name"
                                    value={inputs.last_name}
                                    error={errors?.last_name}
                                    errorMsg={errors?.last_name ? errors.last_name.message : null}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-md-2">
                                <SelectInput
                                    label="Salutation"
                                    name="salutation"
                                    value={inputs.salutation}
                                    error={errors?.salutation}
                                    errorMsg={errors?.salutation ? errors.salutation.message : null}
                                    onChange={handleChange}
                                    required
                                >
                                    <SelectOption value={"Mr."}>Mr.</SelectOption>
                                    <SelectOption value={"Mrs."}>Mrs.</SelectOption>
                                    <SelectOption value={"Miss."}>Miss</SelectOption>
                                    <SelectOption value={"Minor"}>Minor</SelectOption>
                                </SelectInput>
                            </div>
                            <div className="col-md-2">
                                <SelectInput
                                    label="Gender"
                                    name="gender"
                                    value={inputs.gender}
                                    error={errors?.gender}
                                    errorMsg={errors?.gender ? errors.gender.message : null}
                                    onChange={handleChange}
                                    required
                                >
                                    <SelectOption value={"male"}>Male</SelectOption>
                                    <SelectOption value={"female"}>Female</SelectOption>
                                    <SelectOption value={"others"}>Others</SelectOption>
                                </SelectInput>
                            </div>
                            <div className="col-md-4">
                                <SelectInput
                                    label="Nationality"
                                    name="nationality"
                                    value={inputs.nationality}
                                    error={errors?.nationality}
                                    errorMsg={errors?.nationality ? errors.nationality.message : null}
                                    onChange={handleChange}
                                    required
                                >
                                    {
                                        !country.isLoading
                                        &&
                                        countryData.map((c) => (
                                            c.demonyms && c.demonyms.eng && c.demonyms.eng.f &&
                                            <SelectOption value={c.demonyms.eng.f}>{c.demonyms.eng.f}</SelectOption>
                                        ))
                                    }
                                </SelectInput>
                            </div>
                            <div className="col-md-4">
                                <NepaliDateInput
                                    label="Date of Birth"
                                    name="birth_date"
                                    value={inputs.birth_date}
                                    error={errors?.birth_date}
                                    errorMsg={errors?.birth_date ? errors.birth_date.message : null}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField
                                    label="Account Branch"
                                    name="account_branch"
                                    value={inputs.account_branch}
                                    error={errors?.account_branch}
                                    errorMsg={errors?.account_branch ? errors.account_branch.message : null}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-md-4">
                                <SelectInput
                                    label="Currency"
                                    name="currency"
                                    value={inputs.currency}
                                    error={errors?.currency}
                                    errorMsg={errors?.currency ? errors.currency.message : null}
                                    onChange={handleChange}
                                    required
                                >
                                    {
                                        !country.isLoading &&

                                        // <SelectOption value={Object.keys(countryData[0].currencies)[0]}>{Object.keys(countryData[0].currencies)[0]}</SelectOption>
                                        countryData.map((c) => (
                                            c.currencies &&
                                            <SelectOption
                                                value={Object.keys(c.currencies)[0]}>{Object.keys(c.currencies)[0]}</SelectOption>
                                        ))


                                    }
                                    {/* <SelectOption value={"np"}>Nepal</SelectOption>
                                    <SelectOption value={"au"}>Australia</SelectOption>
                                    <SelectOption value={"us"}>USA</SelectOption>
                                    <SelectOption value={"uk"}>UK</SelectOption>
                                    <SelectOption value={"in"}>India</SelectOption>
                                    <SelectOption value={"jp"}>Japan</SelectOption>
                                    <SelectOption value={"sk"}>South Korea</SelectOption> */}
                                </SelectInput>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className='form-label'>Mobile Number</label>
                                    <PhoneInput
                                        country={'np'}
                                        onlyCountries={['np']}
                                        disableDropdown
                                        countryCodeEditable={false}
                                        inputProps={{
                                            name: 'phone',
                                            required: true,
                                        }}
                                        value={inputs.mobile_number}
                                        onChange={phone => setInputs({...inputs, mobile_number: phone})}
                                        inputStyle={{paddingLeft: '48px'}}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <InputField
                                    label="Email"
                                    type='email'
                                    name="email"
                                    value={inputs.email}
                                    error={errors?.email}
                                    errorMsg={errors?.email ? errors.email.message : null}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-md-4">
                                <SelectInput
                                    label="Country"
                                    name="country"
                                    value={inputs.country}
                                    error={errors?.country}
                                    errorMsg={errors?.country ? errors.country.message : null}
                                    onChange={handleChange}
                                    required
                                >
                                    {
                                        country.isLoading ?
                                            <SelectOption>Loading....</SelectOption>
                                            :
                                            countryData.map((c) => (
                                                <SelectOption value={c.name.common}>{c.name.common}</SelectOption>
                                            ))
                                    }
                                    {/* <SelectOption value={"np"}>Nepal</SelectOption>
                                    <SelectOption value={"au"}>Australia</SelectOption>
                                    <SelectOption value={"us"}>USA</SelectOption>
                                    <SelectOption value={"uk"}>UK</SelectOption>
                                    <SelectOption value={"in"}>India</SelectOption>
                                    <SelectOption value={"jp"}>Japan</SelectOption>
                                    <SelectOption value={"sk"}>South Korea</SelectOption> */}
                                </SelectInput>
                            </div>
                        </div>
                    </div>
                </div>

                <React.Fragment>
                    <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                        <Box sx={{flex: '1 1 auto'}}/>
                        <button type="submit" className="btn btn-primary" onClick={(e) => {
                            // console.log()
                            // handleNext()
                        }}>
                            Next
                        </button>
                    </Box>
                </React.Fragment>
            </form>

            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={open}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
        </React.Fragment>
    )
}

function useCountry() {
    const [data, setData] = useState()
    const [isLoading, setLoading] = useState(true)
    const [isError, setError] = useState(false)

    useEffect(() => {
        axios.get('https://restcountries.com/v3.1/all')
            .then(res => {
                setData(res.data)
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                setError(true)
            })
    }, [])

    return {data, isLoading, isError}
}

export default Personal
