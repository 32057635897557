import {Grid} from '@mui/material'
import {useQuery} from '@tanstack/react-query'
import React from 'react'
import FitnessContainer from '../../../fitnessCenter/components/FitnessContainer'
import LabTestItem from '../../components/LabTestItem'
import CatalogSlider from './CatalogSlider'
import axios from '../../../../api'

function LabTestCatalog() {
    const {data, isLoading, isError} = useQuery(
        ['labServices'],
        async () => (
            axios.get('/api/health-service').then(res => {
                return res.data.slice(0, 4)
            })
        )
    )


    if (isLoading || isError) {
        return
    }
    return (
        <FitnessContainer title={"Lab Test Services"}>
            <Grid container>
                {
                    data.map((lab, idx) => (
                        <Grid item lg={3} px={1} key={idx}>
                            <LabTestItem data={lab} bgcolor={(idx % 2 != 0) ? "#FDF7E8" : "#F1FAFE"}/>
                        </Grid>
                    ))
                }
            </Grid>
        </FitnessContainer>
    )
}

export default LabTestCatalog
