import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import {grey} from '@mui/material/colors';
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import {yupResolver} from "@hookform/resolvers/yup";
import {PaymentValidationSchema} from "./partials/paymentValidationSchema";
import {
    CHEQUE,
    DEFAULT_VALUE_CHEQUE,
    DEFAULT_VALUE_ONLINE_TRANSFER,
    ONLINE_TRANSFER
} from "../../../../utils/constant/payment";
import OnlineTransfer from "./partials/OnlineTransfer";
import PayCheque from "./partials/PayCheque";
import {useMutation} from "@tanstack/react-query";
import SchoolPaymentVerificationService from "../../../../services/school-payment-verification.service";
import InputFieldLabel from "../../../form/InputFieldLabel";
import PaymentInformation from "./partials/PaymentInformation";
import FormAction from "../../../form/FormAction";
import PaymentHistory from "./partials/PaymentHistory";
import {filterParams} from "../../../../utils/common/common.utils";
import swal from "sweetalert";
import {toast} from "react-hot-toast";


const defaultValues = {
    payment_type: "online_transfer",
    online_transfer: [DEFAULT_VALUE_ONLINE_TRANSFER],
    cheques: [DEFAULT_VALUE_CHEQUE],
};

const PaymentForm = () => {
    const [paymentType, setPaymentType] = useState(ONLINE_TRANSFER)
    const form = useForm({resolver: yupResolver(PaymentValidationSchema), defaultValues});
    const {handleSubmit, reset, setValue, formState} = form

    /*
    * REACT QUERY
    * */
    const { mutate} = useMutation({
        mutationFn: SchoolPaymentVerificationService.submitPayment,
        onSuccess: () => {
            toast.success("Payment successful.", {position:'top-right',background: '#333', color: '#fff',})
        }
    })

    const onSubmit = (data) => {
        console.log(data)

        if (paymentType === ONLINE_TRANSFER) {
            data[ONLINE_TRANSFER] = data?.online_transfer?.map(filterParams)
            delete data[CHEQUE]
        } else {
            data[CHEQUE] = data?.cheques?.map(filterParams)
            delete data[ONLINE_TRANSFER]
        }


        const payload = {
            number_of_students: 68,
            total_payable_amount: 300000,
            package_id: 10,
            school_profile_id: 1,
            academic_year: "2082/2083",
            ...data
        }

        mutate(payload)
    }

    const handleChange = (value) => {
        setPaymentType(value)
        setValue('payment_type', value)
    };

    if (formState?.errors) {
        console.log(formState.errors)
    }

    return (
        <>
            <PaymentHistory/>
            <div className="d-grid gap-3 rounded p-4 mb-4" style={{background: grey["200"]}}>
                <div className="d-flex justify-content-between">
                    <h4 className="fs-4 fw-bolder lh-sm">
                        Payment Form
                    </h4>
                    <PaymentInformation/>
                </div>
                <FormControl>
                    <InputFieldLabel>Payment Method</InputFieldLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="payment_type"
                        onChange={e => handleChange(e.target.value)}
                    >
                        <FormControlLabel checked={paymentType === ONLINE_TRANSFER} value={ONLINE_TRANSFER}
                                          control={<Radio/>} label="Online Transfer"/>
                        <FormControlLabel checked={paymentType === "cheque"} value={"cheque"} control={<Radio/>}
                                          label="Cheque"/>
                    </RadioGroup>
                </FormControl>
                <form className="d-grid" style={{gap: 32}} onSubmit={handleSubmit(onSubmit)}>
                    {paymentType === ONLINE_TRANSFER ? <OnlineTransfer form={form}/> : <PayCheque form={form}/>}
                    <FormAction spinnerColor="white" isLoading={false}/>
                </form>
            </div>
        </>
    )
}

export default PaymentForm;
