import {EventAvailableOutlined} from '@mui/icons-material'
import {Box, Button, Grid, Stack, Typography} from '@mui/material'
import React from 'react'
import SliderOne from '../../slider-carousels/SliderOne'
import "../../TopDoctors.css";
import {Link, useNavigate} from 'react-router-dom'
import {useEffect} from 'react';
import {useState} from 'react';
import axios from '../../api';
import NoData from '../../custom_componets/NoData';

var settings = {
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: false,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

function DoctorHome() {
    const [topDoctors, setTopDoctors] = useState([]);
    const [isLoading, setLoading] = useState(true)
    useEffect(() => {
        axios.get("/api/top-doctors")
            .then((res) => {
                setTopDoctors(res.data);
            })
            .catch((err) => {
                console.log();
            }).finally(() => {
            setLoading(false)
        });
    }, []);
    if (isLoading) {
        return
    }

    return (
        <Box className='container my-5'>
            <div className="row">
                <div className="col-md-8 heading">
                    <p>Book an appointment for an in-person or video consultation</p>
                    <p>Choose accurate doctor as per the specialization</p>
                </div>
                <div className="col-md-4 text-end">
                    <Link
                        to="/booking/doctor"
                        className="btn view-services btn-outline-dark p-0 px-3 py-2"
                    >
                        View all Doctors
                    </Link>
                </div>
            </div>
            {
                topDoctors.length > 0 ?
                    <SliderOne numItems={4} py='10px' settingOverride={settings}>
                        {
                            topDoctors.map((item, idx) => (
                                <Box px={2} py={2}>
                                    <DoctorCard doctor={item}/>
                                </Box>
                            ))
                        }
                    </SliderOne>
                    :
                    <NoData title='No Doctors' description='There are currently no doctors available.'/>
            }
        </Box>
    )
}

function DoctorCard({doctor}) {
    // console.log()
    let navigate = useNavigate();
    const consultHandler = (id, slug) => {
        navigate(`/doctor/${slug}`, {state: {id: id}});
    }

    function chkAvailableSlots(bookings) {
        let res = false;
        bookings.forEach((booking, index) => {
            if (booking.slots.length > 0) {
                res = true;
                return;
            }
        })
        return res;
    }

    return (
        <Box position='relative' borderRadius={3} py={4} px={1} textAlign='center' border={1} borderColor='divider'
             lineHeight={1}
             boxShadow={'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;'}>
            <Stack gap={1} alignItems='center' justifyContent='center'>
                <Box
                    width='55%'
                    margin='auto'
                    borderRadius='50%'
                    overflow='hidden'
                    border={4}
                    borderColor='#52C8F4'
                    sx={{
                        '& img': {
                            width: '100%',
                            aspectRatio: '1/1',
                            objectFit: 'cover'
                        }
                    }}
                >
                    <img src={doctor.image_path} alt=""/>
                </Box>
                <Box>
                    <Typography variant='h6' fontWeight={600} lineHeight={1.2}>
                        {doctor.salutation} {doctor.user.name}
                    </Typography>
                </Box>
                <Box>
                    <Typography variant='subtitle1' color='text.disabled' lineHeight={1.2}>
                        {doctor.address}
                    </Typography>
                </Box>
                <Box width='fit-content' m='auto' borderRadius={10}>
                    <Typography variant='subtitle1' color='#52C8F4' fontWeight={600}
                                lineHeight={1.2}>{doctor.specialization}</Typography>
                </Box>
                <Box
                    display='flex'
                    justifyContent={'center'}
                    width={'100%'}
                    mt={2}
                >
                    <Button variant='outlined' fullWidth endIcon={<EventAvailableOutlined/>}
                            onClick={() => consultHandler(doctor.id, doctor.slug)}>
                        Consult Now
                    </Button>
                </Box>
            </Stack>
            <div className={`slot-status ${chkAvailableSlots(doctor.bookings) && 'active'}`}>
                <div className={`outer-dot ${chkAvailableSlots(doctor.bookings) && 'border border-success'}`}>
                </div>
                <div
                    className={`blink-dot inner-dot ${chkAvailableSlots(doctor.bookings) ? 'bg-success mb-0' : 'bg-secondary mb-0'}`}
                    style={{fontSize: '14px'}}/>
            </div>
        </Box>
    )
}

export default DoctorHome
