import "./AdComponent.scss"

const AdComponent = ({adGif, className}) => {
    return (
        <div className={`ad-container my-4 ${className}`}>
            <div className="ad-gif-container">
                <img src={adGif} alt="Advertisements"/>
            </div>
        </div>
    )
}

export default AdComponent
