import {Box} from '@mui/material'
import React, {useState} from 'react'
import {useChkLogin} from '../../../../middlewares/LoginMiddleware'
import {useQuery} from '@tanstack/react-query'
import axios from '../../../api'
import Loader from '../../Loader'
import swal from 'sweetalert'
import {useNavigate} from 'react-router-dom'
import {useContext} from 'react'
import FormModalContext from '../../../custom_componets/globalFormModal/FormModalContext'

function DeactivateStudents() {
    const navigate = useNavigate()
    const {openFormModal} = useContext(FormModalContext)
    const {token, data: userProfile} = useChkLogin()
    const {data: deactivated, isLoading: stdLoading, refetch} = useQuery(
        ['deactivated-student'],
        async () => axios.get('api/company-school-profile/deactivated-student-details', {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => res.data).catch(err => console.log(err))
    )
    if (stdLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center gap-4">
                <Loader/>
            </div>
        )
    }
    console.log('Deactivate student list', deactivated)

    function reactivateStds(e, id, reason, handleClose) {
        e.preventDefault()
        axios.post('api/company-school-profile/reactivate-users', {
            profile_id: userProfile.school_profile.id,
            deactivate_student_id: id,
            activate_reason: reason
        }, {headers: {'Authorization': `Bearer ${token}`}})
            .then(res => {
                swal({
                    title: "Success!",
                    text: res.data.message,
                    icon: "success",
                });
                refetch()
                handleClose()
            }).catch(err => {
            console.log(err)
        })
    }

    function handleAdditionalPayment(id) {
        // let selected = unpaidStd.map((item) => item.id)
        navigate('/user/family/payment', {
            state: {
                selected: [id],
                type: 1,
                redirectLink: '/school/deactivate/students'
            }
        })
    }

    return (
        <div>
            <div className="card">
                <div className="card-header">
                    <span className="fs-5">
                        Deactivated Students
                    </span>
                    {/* <br />
                    <span className="fw-light fs-6">
                        Students deactivated list.
                    </span> */}
                </div>
                <div className="card-body">
                    <table className='table border-left border-right border-top'>
                        <thead>
                        <Box component='tr' bgcolor={'#'}>
                            <th><span className="text-muted">S.N.</span></th>
                            <th><span className="text-muted">Student Name</span></th>
                            <th><span className="text-muted">Username</span></th>
                            <th><span className="text-muted">School Details</span></th>
                            <th><span className="text-muted">Reactivate</span></th>
                            <th><span className="text-muted">Reject Reason</span></th>
                        </Box>
                        </thead>
                        <tbody>
                        {
                            deactivated.map((item, idx) => {
                                let activate = item.member.user.deactivate.activated
                                return (
                                    <tr key={item.id}>
                                        <td>
                                            <span>{idx + 1}</span>
                                        </td>
                                        <td>
                                            <span>{item.member.user.name}</span>
                                        </td>
                                        <td>
                                            <span>{item.member.user.user_name}</span>
                                        </td>
                                        <td>
                                            <span className='me-1'>{item.year}</span>
                                            <span className='me-1'>{item.class}</span>
                                            <span className='me-1'>{item.section}</span>
                                        </td>
                                        <td>
                                            {
                                                (activate === null || activate?.status === 2) ? (
                                                    <button className="btn btn-primary" onClick={e => openFormModal(
                                                        <ReactivateReason reactivateStds={reactivateStds}
                                                                          student={item}/>
                                                    )}>
                                                        Reactivate
                                                    </button>
                                                ) : (
                                                    activate?.status === 1 ? (
                                                        <button className="btn btn-success"
                                                                onClick={e => handleAdditionalPayment(item.member.user.id)}>
                                                            Pay
                                                        </button>
                                                    ) : (
                                                        <span className='badge badge-info'>Reactivating</span>
                                                    )
                                                )
                                            }

                                        </td>
                                        <td>
                                            {
                                                (activate != null && activate?.status === 2) && (
                                                    <span className='text-danger'>{activate.reject_reason}</span>
                                                )
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

function ReactivateReason({handleClose, reactivateStds, student}) {
    const [reason, setReason] = useState(null)
    return (
        <Box
            width='100%'
            maxWidth='400px'
            position='absolute'
            top='50%'
            left='50%'
            sx={{transform: 'translate(-50%, -50%)'}}
        >
            <div className="card">
                <div className="card-body">
                    <div className='mb-2'>
                        <label htmlFor="">Reactivate Reason</label>
                        <br/>
                        <span className="fw-light">
                            Specify your reason for reactivating this student.
                        </span>
                    </div>
                    <div className='mb-2'>
                        <textarea rows="5" className='form-control' onChange={e => setReason(e.target.value)}
                                  value={reason}/>
                    </div>
                    <div className="mt-2">
                        <button className="btn btn-primary"
                                onClick={e => reactivateStds(e, student.member.user.deactivate.id, reason, handleClose)}
                                disabled={reason === null || reason?.length === 0}>
                            Reactivate
                        </button>
                    </div>
                </div>
            </div>
        </Box>
    )
}

export default DeactivateStudents
