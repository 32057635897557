import {Box} from '@mui/system'
import React from 'react'

function SliderItem({bgimage, content, link}) {
    return (
        <Box sx={{width: '100%', aspectRatio: '2/0.5'}} bgcolor='divider'>
            <img src={bgimage} alt="banner content" style={{width: '100%', height: '100%', objectFit: 'cover'}}/>
        </Box>
    )
}

export default SliderItem
