import {Alert, AlertTitle, Button, Stack, Typography} from '@mui/material'
import {useQuery} from '@tanstack/react-query'
import React, {useContext, useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {LoginStateContext} from '../../../../contexts/LoginStateContext'
import useKycStatus from '../../../../customHooks/useKycStatus'
import axios from "../../../api"

function PackageStatus({data, type = "pkgStatus", disclaimer = null}) {
    const loginContext = useContext(LoginStateContext)
    const location = useLocation()
    const user = loginContext.state.data
    const status = data?.package_status
    const globalFormStatus = useKycStatus()


    const familyQuery = useQuery(
        ["getfamilydata", user.id],
        async () => {
            return axios.get('/api/admin/family', {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                .then(res => res.data)
        }
    );

    useEffect(() => {
        familyQuery.refetch()
    }, [location])

    console.log()
    if (familyQuery.isLoading) {
        return
    }
    const family = familyQuery.data

    if (data === null && type === 'pkgStatus') {
        console.log()
        if (user.member_type == "Dependent Member") {
            if (family.payment_status === 0) {
                return (
                    <PackageAlertComponent
                        severity='error'
                        title='Payment Due'
                        description='Your payment is on due. Request your primary member to pay the due amount.'
                        disclaimer={disclaimer}
                    />
                )
            }
            return (
                <PackageAlertComponent
                    severity='error'
                    title='Payment Due'
                    description="Primary member hasn't bought any packages."
                    disclaimer={disclaimer}
                />
            )
        }
        return (
            <PackageAlertComponent
                severity='error'
                title='Buy Package'
                description="You haven't bought any packages."
                link='/packages'
                btnLabel='Buy Package'
                disclaimer={disclaimer}
            />
        )
    }
    if (status === 'Not Booked' && type === 'pkgStatus') {
        if (user.member_type == 'Dependent Member') {
            return (
                <PackageAlertComponent
                    severity='error'
                    title='Package Payment Due'
                    description="Primary Member hasn't made payment."
                    disclaimer={disclaimer}
                />
            )
        }
        return (
            <PackageAlertComponent
                severity='error'
                title='Package Payment Due'
                description='Please make payment for your package before accessing the dashboard'
                link='/user/mypackages'
                btnLabel='Pay'
                state={{pay: true}}
                disclaimer={disclaimer}
            />
        )
    }
    if (globalFormStatus.isLoading) return
    if (data === null || status === "Not Booked") {
        return
    }
    if (globalFormStatus?.data?.message == "kyc form not submitted." || globalFormStatus?.data?.message == "kyc rejected.") {
        if (user.member_type === 'Primary Member') {
            return (
                <PackageAlertComponent
                    severity='error'
                    title='Fill up your global form to activate your package'
                    description='Your package will be activated after atleast two working days of global form submission'
                    link='/user/kyc'
                    btnLabel='Global Form'
                    disclaimer={disclaimer}
                />
            )
        }
        return (
            <PackageAlertComponent
                severity='error'
                title='Global Form'
                description="You haven't filled your global form. Fill your global form."
                link='/user/kyc'
                btnLabel='Global Form'
                disclaimer={disclaimer}
            />
        )
    }
    if (globalFormStatus?.data?.message == "kyc not verified.") {
        console.log()
        if (user.member_type === "Primary Member") {
            return (
                <PackageAlertComponent
                    severity='warning'
                    title='Global Form Verifying'
                    description='Your package will be activated after global form is verified'
                    disclaimer={disclaimer}
                />
            )
        }
        return (
            <PackageAlertComponent
                severity='warning'
                title='Global Form Verifying'
                description='Your global form is being verified'
                disclaimer={disclaimer}
            />
        )
    }
    if (status === "Pending") {
        return (
            <PackageAlertComponent
                severity='info'
                title='Package Status'
                description='Your package will be activated after first screening date'
                disclaimer={disclaimer}
            />
        )
    }
}

function PackageAlertComponent({severity, title, description, link, btnLabel, state = null, disclaimer}) {
    const navigate = useNavigate()
    return (
        <Alert severity={severity} sx={{'&>div:last-child': {width: '100%'}}}>
            <AlertTitle>{title}</AlertTitle>

            <Stack direction='row' justifyContent='space-between'>
                <Typography variant='subtitle1'>{description}</Typography>
                {
                    link &&
                    <Button variant='contained' onClick={() => navigate(link, {state: state})}>{btnLabel}</Button>
                }
            </Stack>

            {
                disclaimer && (
                    <span className="fw-light text-danger">
                        * {disclaimer}
                    </span>
                )
            }

        </Alert>
    )
}

export default PackageStatus
