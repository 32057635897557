import {CheckOutlined, DangerousOutlined, Download, PublicOutlined} from '@mui/icons-material'
import {Box, Button, Card, CardContent, CardHeader, CardMedia, Grid, Stack, Typography} from '@mui/material'
import {useQuery} from '@tanstack/react-query'
import React, {useContext, useEffect, useState} from 'react'
import {LoginStateContext} from '../../../../contexts/LoginStateContext'
import axios from '../../../api'

const data = {
    name: 'Mr Dora Explorer',
    education: 'master in computer science',
    gender: 'Male',
    number: '+9779860192019'
}

const labelPersonal = {
    salutation: 'Salutation',
    first_name: 'First Name',
    middle_name: 'Middle Name',
    gender: 'Gender',
    birth_date: 'Birth Date',
    country: 'Country',
    mobile_number: 'Mobile Number',
    email: 'Email',
    education: 'Education',
    identification_type: 'Identification Type',
    identification_no: 'Identification Number',
    citizenship_date: 'Citizenship Issued Date',
    citizenship_issue_district: 'Citizenship Issued District'
}

// const labelAddressPermanent = {
//     perm_province
// }

const labelOccupation = {
    work_status: 'Work Status',
    occupation: 'Occupation',
    designation: 'Designation',
    source_of_income: 'Source of Income',
    annual_income: 'Annual Income',
    pan_number: 'Pan Number',
    organization_name: 'Organization Name',
    organization_address: 'Organization Address',
    organization_number: 'Organization Contact'
}

const familyInfo = {
    father_full_name: 'Father Name',
    mother_full_name: 'Mother Name',
    grandfather_full_name: 'Grand Father Name',
    grandmother_full_name: 'Grand Mother Name',
    marital_status: 'Marital Status',
    husband_wife_full_name: 'Husband/Wife Name',
}

const transactionInfo = {
    account_branch: 'Account Branch',
    account_purpose: 'Purpose of Account',
    currency: 'Currency',
    max_amount_per_tansaction: 'Maximum Amount of Transaction',
    number_of_monthly_transaction: 'Number of Monthly Transaction',
    monthly_amount_of_transaction: 'Monthly Amount of Transaction',
    number_of_yearly_transaction: 'Number of Yearly Transaction',
    yearly_amount_of_transaction: 'Yearly Amount of Transaction'
}

const nomineeInfo = {
    nominee_name: 'Name',
    nominee_father_name: 'Father Name',
    nominee_grandfather_name: "Grand Father Name",
    nominee_relation: 'Relation',
    nominee_citizenship_number: 'Citizenship Number',
    nominee_citzenship_issued_place: 'Citizenship Issued District',
    nominee_citizenship_issued_date: 'Citizenship Issued Date',
    nominee_birthdate: 'Date of Birth',
    nominee_permanent_address: 'Permanent Address',
    nominee_current_address: 'Current Address',
    nominee_phone_number: 'Phone Number'
}

const beneficiaryInfo = {
    beneficiary_name: 'Benificiary Name',
    beneficiary_address: 'Benificiary Address',
    beneficiary_contact_number: 'Benificiary Contact Number',
    beneficiary_relation: 'Benificiary Relation',
}

const othersInfo = {
    are_you_nrn: 'Are You NRN?',
    us_citizen: 'Are you US Citizen?',
    us_residence: 'Are you US Resident?',
    criminal_offence: 'Have you been charged with criminal offense in Nepal or any other Country?',
    green_card: 'Did you hold Us Permanent resident card (Green card)?',
    account_in_other_banks: 'Do you have any account with other banks/Financial institutions?'
}

function GlobalFormView() {
    const loginContext = useContext(LoginStateContext)

    const {data, isLoading} = useQuery(
        ['globalform'],
        async () => (
            axios.get('api/view-global-form', {headers: {'Authorization': `Bearer ${loginContext.state.token}`}}).then(res => res.data)
        )
    )

    console.log()

    if (isLoading) {
        return
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Box sx={{
                            '& img': {
                                width: '100%',
                                aspectRatio: '1/1.2',
                                objectFit: 'cover',
                                borderRadius: '10px'
                            }
                        }}>
                            <img src={data.self_image_path} alt=""/>
                        </Box>
                    </Grid>
                    <Grid item xs={9} sx={{alignSelf: 'center'}}>
                        <Box display='flex' justifyContent='space-between'>
                            <h1 className='font-weight-bold'>{`${data.salutation} ${data.first_name} ${data.middle_name === null ? '' : data.middle_name} ${data.last_name}`}</h1>
                            <span class="badge badge-success px-5 align-self-start"><h5
                                className='m-0 p-0'>Verified</h5></span>
                        </Box>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    {/* <InfoItem label='Gender' value={data.gender}/> */}
                                    <FormInfoItem label='Gender' value={data.gender} xs={6}/>
                                    <FormInfoItem label='Country' value={data.country} xs={6}/>
                                    <FormInfoItem label='E-mail' value={data.email} xs={6}/>
                                    <FormInfoItem label='Mobile Number' value={data.mobile_number} xs={6}/>
                                    <FormInfoItem label='Nationality' value={data.nationality} xs={6}/>
                                    <FormInfoItem label='Education' value={data.education} xs={6}/>
                                    <FormInfoItem label='Date of Birth' value={data.birth_date} xs={6}/>
                                    <FormInfoItem label='Identification type' value={data.identification_type} xs={6}/>
                                    <FormInfoItem label='Identification Nubmer' value={data.identification_no} xs={6}/>
                                    <FormInfoItem label='Citizenship Issued District'
                                                  value={data.citizenship_issue_district} xs={6}/>
                                    <FormInfoItem label='Citizenship Issued Date' value={data.citizenship_date} xs={6}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <InfoCard title='Permanent Address' icon={PublicOutlined}>
                            <InfoItem label="Province" value="Bagmati Province"/>
                            <InfoItem label="District" value="Bhaktapur"/>
                            <InfoItem label="Municipality" value="Bhaktapur Municipality"/>
                            <InfoItem label="Ward Number" value="8"/>
                            <InfoItem label="Street" value="Araniko Highway, Bhaktapur"/>
                            <InfoItem label="House Number" value="86"/>
                        </InfoCard>
                    </Grid>
                    <Grid item xs={6}>
                        <InfoCard title='Temporary Address' icon={PublicOutlined}>
                            <InfoItem label="Province" value="Bagmati Province"/>
                            <InfoItem label="District" value="Bhaktapur"/>
                            <InfoItem label="Municipality" value="Bhaktapur Municipality"/>
                            <InfoItem label="Ward Number" value="8"/>
                            <InfoItem label="Street" value="Araniko Highway, Bhaktapur"/>
                            <InfoItem label="House Number" value="86"/>
                        </InfoCard>
                    </Grid>
                    <Grid item xs={6}>
                        <InfoCard title='Occupation Info' icon={PublicOutlined}>
                            <Grid container spacing={2}>
                                {
                                    Object.keys(labelOccupation).map((item, idx) => (
                                        <Grid item xs={6}>
                                            <InfoItem label={labelOccupation[item]} value={data[item] || 'Null'}/>
                                        </Grid>
                                    ))
                                }
                            </Grid>

                        </InfoCard>
                    </Grid>
                    <Grid item xs={6}>
                        <InfoCard title='Nominee Info' icon={PublicOutlined}>
                            <Grid container spacing={2}>
                                {
                                    Object.keys(nomineeInfo).map((item, idx) => (
                                        <Grid item xs={6} key={idx}>
                                            <InfoItem label={nomineeInfo[item]} value={data[item] || 'Null'}/>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </InfoCard>
                    </Grid>
                    <Grid item xs={3.5}>
                        <InfoCard title='Family Info' icon={PublicOutlined}>
                            {
                                Object.keys(familyInfo).map((item, idx) => (
                                    <InfoItem label={familyInfo[item]} value={data[item] || 'Null'}/>
                                ))
                            }
                        </InfoCard>
                    </Grid>
                    <Grid item xs={5}>
                        <InfoCard title='Transaction Info' icon={PublicOutlined}>
                            <Grid container spacing={2}>
                                {
                                    Object.keys(transactionInfo).map((item, idx) => (
                                        <Grid item xs={6} key={idx}>
                                            <InfoItem label={transactionInfo[item]} value={data[item] || 'Null'}/>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </InfoCard>
                    </Grid>
                    <Grid item xs={3.5}>
                        <InfoCard title='Beneficiary Info' icon={PublicOutlined}>
                            {
                                Object.keys(beneficiaryInfo).map((item, idx) => (
                                    <InfoItem label={beneficiaryInfo[item]} value={data[item] || 'Null'}/>
                                ))
                            }
                        </InfoCard>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} xl={6}>
                    <FormInfoCard title='Personal Info'>
                        <Grid container spacing={2}>
                            {
                                Object.keys(labelPersonal).map((item, idx) => (
                                    <FormInfoItem label={labelPersonal[item]} value={data[item] || 'Null'}/>
                                ))
                            }
                        </Grid>
                    </FormInfoCard>
                </Grid>
                <Grid item xs={12} xl={6}>
                    <FormInfoCard title='Occupation Info'>
                        <Grid container spacing={2}>
                            {
                                Object.keys(labelOccupation).map((item, idx) => (
                                    <FormInfoItem label={labelOccupation[item]} value={data[item] || 'Null'}/>
                                ))
                            }
                        </Grid>
                    </FormInfoCard>
                </Grid>
                <Grid item xs={12} xl={6}>
                    <FormInfoCard title='Family Info'>
                        <Grid container spacing={2}>
                            {
                                Object.keys(familyInfo).map((item, idx) => (
                                    <FormInfoItem key={idx} label={familyInfo[item]} value={data[item] || 'Null'}/>
                                ))
                            }
                        </Grid>
                    </FormInfoCard>
                </Grid>
                <Grid item xs={12} xl={6}>
                    <FormInfoCard title='Transaction Info'>
                        <Grid container spacing={2}>
                            {
                                Object.keys(transactionInfo).map((item, idx) => (
                                    <FormInfoItem label={transactionInfo[item]} value={data[item] || 'Null'}/>
                                ))
                            }
                        </Grid>
                    </FormInfoCard>
                </Grid>
                <Grid item xs={12} xl={6}>
                    <FormInfoCard title='Nominee Info'>
                        <Grid container spacing={2}>
                            {
                                Object.keys(nomineeInfo).map((item, idx) => (
                                    <FormInfoItem key={idx} label={nomineeInfo[item]} value={data[item] || 'Null'}/>
                                ))
                            }
                        </Grid>
                    </FormInfoCard>
                </Grid>
                <Grid item xs={12} xl={6}>
                    <FormInfoCard title='Nominee Details'>
                        <Grid container spacing={2}>
                            {
                                Object.keys(nomineeInfo).map((item, idx) => (
                                    <FormInfoItem key={idx} label={nomineeInfo[item]} value={data[item] || 'Null'}/>
                                ))
                            }
                        </Grid>
                    </FormInfoCard>
                </Grid>
                <Grid item xs={12} xl={6}>
                    <FormInfoCard title='Beneficiary Details'>
                        <Grid container spacing={2}>
                            {
                                Object.keys(beneficiaryInfo).map((item, idx) => (
                                    <FormInfoItem key={idx} label={beneficiaryInfo[item]} value={data[item] || 'Null'}/>
                                ))
                            }
                        </Grid>
                    </FormInfoCard>
                </Grid>
                <Grid item xs={12} xl={6}>
                    <FormInfoCard title="Others Info">
                        <Grid container spacing={2}>
                            {
                                Object.keys(othersInfo).map((item, idx) => (
                                    <FormInfoItem key={idx} label={othersInfo[item]}
                                                  value={data[item] === 0 ? <DangerousOutlined sx={{color: 'red'}}/> :
                                                      <CheckOutlined sx={{color: 'green'}}/>}/>
                                ))
                            }
                        </Grid>
                    </FormInfoCard>
                </Grid>
                <Grid item xs={12} xl={6}>
                    <FormInfoCard title="Uploaded Documents">
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Card sx={{height: '100%'}}>
                                    <CardMedia component='img' image={data.self_image_path}/>
                                    <CardContent sx={{textAlign: 'center'}}>
                                        <Button endIcon={<Download/>}>
                                            Applicant Image
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={4}>
                                <Card>
                                    <CardMedia component='img' image={data.citizenship_front_path}/>
                                    <CardContent sx={{textAlign: 'center'}}>
                                        <Button endIcon={<Download/>}>
                                            Citizenship Front
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={4}>
                                <Card>
                                    <CardMedia component='img' image={data.citizenship_back_path}/>
                                    <CardContent sx={{textAlign: 'center'}}>
                                        <Button endIcon={<Download/>}>
                                            Citizenship Back
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </FormInfoCard>
                </Grid>
                <Grid item xs={12} xl={6}>
                    <FormInfoCard title='View Files'>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <iframe src={data.nic_file_path} style={{height: '100%'}}/>
                            </Grid>
                            <Grid item xs={6}>
                                <iframe src={data.insurance_file_path}/>
                            </Grid>
                        </Grid>
                    </FormInfoCard>
                </Grid>
            </Grid>
        </Box>
    )
}

function FormInfoCard({title, children}) {
    return (
        <Card sx={{height: '100%'}}>
            <CardHeader title={title}/>
            <CardContent>
                {children}
            </CardContent>
        </Card>
    )
}

function FormInfoItem({label, value, xs, sm, md, lg, xl}) {
    return (
        <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
            <InfoItem label={label} value={value}/>
        </Grid>
    )
}

function InfoItem({label, value}) {
    return (
        <Box display='flex' alignItems='center' gap={2}>
            <Typography variant='subtitle1' color='text.secondary'>
                {label}:
            </Typography>
            <Typography sx={{overflowWrap: 'break-word'}} fontWeight={600}>{value}</Typography>
        </Box>
    )
}

function InfoCard({title, icon, children}) {
    const Component = icon
    return (
        <Card elevation={3} sx={{height: '100%'}}>
            <Box display='flex' alignItems='center' gap={1} p={2} bgcolor='#111111' color='white'>
                <Component sx={{fontSize: '40px'}}/>
                <Typography variant='h4' sx={{margin: '0 !important'}}>
                    {title}:
                </Typography>
            </Box>
            <Box p={2}>
                <Stack gap={2}>
                    {children}
                </Stack>
            </Box>
        </Card>
    )
}


// function useLocationDetails() {
//     const [province, setProvince] = useState([])
//     const [provinceLoading, setProvinceLoading] = useState(true)
//     const [district, setDistrict] = useState([])
//     const [districtLoading, setDistrictLoading] = useState(false)
//     const [municipality, setMunicipality] = useState([])
//     const [municipalityLoading, setMunicipalityLoading] = useState(false)
//     const [ward, setWard] = useState([])
//     const [wardLoading, setWardLoading] = useState(false)

//     useEffect(() => {
//         axios.get("api/fetchprovince")
//             .then(res => {
//                 setProvince(res.data)
//                 setProvinceLoading(false)
//             })
//             .catch(err => {
//                 setProvinceLoading(false)
//             })
//     }, [])

//     function fetchDistrict(province) {
//         console.log()
//         setDistrictLoading(true)
//         setMunicipality([])
//         setWard([])
//         axios.get("api/fetchdistrict?province_id=" + province)
//             .then(res => {
//                 setDistrict(res.data)
//                 setDistrictLoading(false)
//             })
//             .catch(err => {
//                 setDistrictLoading(false)
//             })
//     }

//     function fetchMunicipality(district) {
//         setMunicipalityLoading(true)
//         setWard([])
//         axios.get("api/fetchmun?district_id=" + district)
//             .then(res => {
//                 setMunicipality(res.data)
//                 setMunicipalityLoading(false)
//             })
//             .catch(err => {
//                 setMunicipalityLoading(false)
//             })
//     }

//     function fetchWard(municipality) {
//         setWardLoading(true)
//         axios.get("api/fetchward?municipality_id=" + municipality)
//             .then(res => {
//                 setWard(res.data)
//                 setWardLoading(false)
//             })
//             .catch(err => {
//                 setWardLoading(false)
//                 console.log()
//             })
//     }

//     function setAll(distr, muncip, war) {
//         setDistrict(distr)
//         setMunicipality(muncip)
//         setWard(war)
//     }

//     function getLocation() {
//         return {
//             province: province,
//             district: district,
//             municipality: municipality,
//             district: district,
//             ward: ward,
//         }
//     }

//     return {
//         getLocation,
//         fetchDistrict,
//         fetchMunicipality,
//         fetchWard,
//         setAll
//     }
// }


export default GlobalFormView
