import {
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    styled,
    Tab,
    TextField
} from '@mui/material'
import React, {useState} from 'react'
import {useEffect} from 'react';
import UploadFile from '../../../custom_componets/UploadFile';
import axios from "../../../api"
import {useQuery} from '@tanstack/react-query';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUpload} from '@fortawesome/free-solid-svg-icons';
import {useContext} from 'react';
import {LoginStateContext} from '../../../../contexts/LoginStateContext';
import swal from 'sweetalert';
import {useNavigate} from 'react-router-dom';
import {FileUpload} from '@mui/icons-material';
import {InnerTabs, OuterTabs} from '../../../custom_componets/CustomTab';

const Item = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

function Insurance() {
    const [tabValue, setTabValue] = useState(0)

    function handleTabChange(event, newValue) {
        setTabValue(newValue)
    }

    function handleFile(inputFile, name, setInputs) {
        var reader = new FileReader();
        reader.readAsDataURL(inputFile);
        console.log()
        var ext = inputFile.type.split('/')[1]
        reader.onload = () => {
            const strArr = reader.result.split('base64,')
            const base64Str = `data:image/${ext};base64,${strArr[1]}`
            setInputs(prev => ({...prev, [name]: base64Str}))
        }
        reader.onerror = error => {
            console.log();
        }
    }

    return (
        <Box>
            <Paper variant="outlined" square>
                <div className="insurance-form">
                    <Box component="div" sx={{p: 2, border: '1px'}}>
                        <h1 className='m-0 p-0' style={{fontWeight: '600'}}>Insurance Claim Form</h1>
                        <p className='text-muted'>Fill the fields below accurately and we will get back to you in short
                            time</p>
                    </Box>
                    <Box px={4} borderBottom={1.2} borderColor='divider'>
                        <InnerTabs value={tabValue} onChange={handleTabChange}>
                            <Tab label='Claim Normal Insurance'/>
                            <Tab label='Claim Death Insurance'/>
                        </InnerTabs>
                    </Box>
                    {
                        tabValue === 0 ?
                            <NormalInsurance handleFile={handleFile}/>
                            :
                            <DeathInsurance handleFile={handleFile}/>
                    }
                </div>
            </Paper>
        </Box>
    )
}

function NormalInsurance({handleFile}) {
    const [inputs, setInputs] = useState({
        package_insurance_id: '',
        claim_amount: ''
    })

    const navigate = useNavigate()

    const loginContext = useContext(LoginStateContext)

    const {isLoading, data} = useQuery(
        ['insurance'],
        async () => {
            return axios.get("/api/insurance", {headers: {'Authorization': `Bearer ${loginContext.state.token}`}}).then(res => res.data).catch(err => err)
        }
    )

    console.log("Insurance data is", data)

    function handleChange(e) {
        setInputs({...inputs, [e.target.name]: e.target.value})
    }

    function submitForm(e) {
        console.log()
        axios.post("/api/admin/insurance-claim/store", inputs, {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
            .then(res => {
                swal({
                    title: "Insurance Claim",
                    text: "Your Insurance claim is submitted!",
                    icon: "success",
                }).then((value) => {
                    if (value) {
                        navigate("/user/insurance/claim-settlement")
                    }
                });
            })
            .catch(err => {
                console.log(err.response.data.message.error[0])
                swal({
                    title: "Insurance Claim",
                    text: err.response.data.message.error[0],
                    icon: "error",
                })
            })
    }

    return (
        <Box component="form" sx={{p: 4}}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label" style={{zIndex: 0}}>Select Insurance Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            variant='filled'
                            id="demo-simple-select"
                            name='package_insurance_id'
                            value={inputs.package_insurance_id}
                            // label="Age"
                            onChange={handleChange}
                        >
                            {
                                isLoading ?
                                    <MenuItem>Loading....</MenuItem>
                                    :
                                    data.coverage.map((insuranceData) => (
                                        <MenuItem value={insuranceData.id}>{insuranceData.insurancetype.type}</MenuItem>
                                    ))
                            }

                        </Select>
                    </FormControl>

                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <TextField style={{zIndex: 0}} id="filled-basic" name="claim_amount" label="Claim Amount"
                                   variant="filled" type="number" onChange={handleChange} value={inputs.claim_amount}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                    <UploadFile
                        name="hand_written_letter"
                        label="Upload Hand Written Letter"
                        type={"pdf"}
                        accept={"application/pdf,application/vnd.ms-excel"}
                        setImageInput={(fileG) => {
                            handleFile(fileG[0], 'hand_written_letter', setInputs)
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <UploadFile
                        name="medical_report"
                        label="Upload Medical Report"
                        type={"pdf"}
                        accept={"application/pdf,application/vnd.ms-excel"}
                        setImageInput={(fileG) => {
                            handleFile(fileG[0], 'medical_report', setInputs)
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <UploadFile
                        name="invoice"
                        label="Upload Invoice"
                        type={"pdf"}
                        accept={"application/pdf,application/vnd.ms-excel"}
                        setImageInput={(fileG) => {
                            handleFile(fileG[0], 'invoice', setInputs)
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button variant="contained" startIcon={<FileUpload/>} fullWidth onClick={submitForm}>
                        Upload Form
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}

function DeathInsurance({handleFile}) {
    const [inputs, setInputs] = useState({
        user_id: '',
        package_insurance_id: '',
    })
    const navigate = useNavigate()
    const loginContext = useContext(LoginStateContext)
    const {isLoading, data} = useQuery(
        ['death_insurance'],
        async () => {
            return axios.get("/api/admin/insurance-claim/details", {headers: {'Authorization': `Bearer ${loginContext.state.token}`}}).then(res => res.data).catch(err => err)
        }
    )

    function handleChange(e) {
        setInputs({...inputs, [e.target.name]: e.target.value})
    }

    function submitForm(e) {
        console.log()
        axios.post("/api/admin/insurance-claim/death-claim-store", inputs, {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
            .then(res => {
                swal({
                    title: "Insurance Claim",
                    text: "Your Insurance claim is submitted!",
                    icon: "success",
                }).then((value) => {
                    if (value) {
                        navigate("/user/insurance/claim-settlement")
                    }
                });
            })
            .catch(err => {
                console.log(err.response.data.message.error[0])
                swal({
                    title: "Insurance Claim",
                    text: err.response.data.message.error[0],
                    icon: "error",
                })
            })
    }

    return (
        <Box component="form" sx={{p: 4}}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label" style={{zIndex: 0}}>Select Insurance Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            variant='filled'
                            id="demo-simple-select"
                            name='package_insurance_id'
                            value={inputs.package_insurance_id}
                            // label="Age"
                            onChange={handleChange}
                        >
                            {
                                isLoading ?
                                    <MenuItem>Loading....</MenuItem>
                                    :
                                    data.insurance_types.map((insuranceData) => (
                                        <MenuItem value={insuranceData.id}>{insuranceData.insurancetype.type}</MenuItem>
                                    ))
                            }

                        </Select>
                    </FormControl>

                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label" style={{zIndex: 0}}>Select Member</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            variant='filled'
                            id="demo-simple-select"
                            name='user_id'
                            value={inputs.user_id}
                            // label="Age"
                            onChange={handleChange}
                        >
                            {
                                isLoading ?
                                    <MenuItem>Loading....</MenuItem>
                                    :
                                    data.members.map((member) => (
                                        <MenuItem value={member.member_id}>{member.user.name}</MenuItem>
                                    ))
                            }

                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                    <UploadFile
                        name="hand_written_letter"
                        label="Upload Hand Written Letter"
                        type={"pdf"}
                        accept={"application/pdf,application/vnd.ms-excel"}
                        setImageInput={(fileG) => {
                            handleFile(fileG[0], 'hand_written_letter', setInputs)
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <UploadFile
                        name="medical_report"
                        label="Upload Medical Report"
                        type={"pdf"}
                        accept={"application/pdf,application/vnd.ms-excel"}
                        setImageInput={(fileG) => {
                            handleFile(fileG[0], 'medical_report', setInputs)
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <UploadFile
                        name="invoice"
                        label="Upload Invoice"
                        type={"pdf"}
                        accept={"application/pdf,application/vnd.ms-excel"}
                        setImageInput={(fileG) => {
                            handleFile(fileG[0], 'invoice', setInputs)
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button variant="contained" startIcon={<FileUpload/>} fullWidth onClick={submitForm}>
                        Upload Form
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Insurance
