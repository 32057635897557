import {useQuery} from '@tanstack/react-query';
import MUIDataTable from 'mui-datatables';
import React, {useContext, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom';
import axios from '../../../api.js';
import Loader from '../../Loader.js';
import Modal from 'react-bootstrap/Modal';
import {useForm} from 'react-hook-form';
import {LoginStateContext} from '../../../../contexts/LoginStateContext.js';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import {CancelOutlined} from '@mui/icons-material';
import AuthButton from '../../../custom_componets/AuthButton.jsx';
import CancelReasonModal from './CancelReasonModal.jsx';

const MyAppointments = () => {
    const loginContext = useContext(LoginStateContext)
    const appointmentsQuery = useQuery(
        ['appointments', loginContext.state.token],
        async () => {
            return axios.get('/api/admin/booking-review', {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                .then(res => res.data)
        }
    )

    if (appointmentsQuery.isLoading) {
        return <div className='d-flex justify-content-center'><Loader/></div>
    }
    const appointments = appointmentsQuery.data

    async function cancelAppointment(appointment) {
        try {
            let res = await axios.post(`/api/admin/booking-review/canclebyuser/${appointment}`, {cancel_reason: "Duplicate"}, {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
        } catch (e) {
            console.log()
        }
    }

    return (
        <AppointmentTable appointments={appointments} cancelAppointment={cancelAppointment}/>
    )
}

const AppointmentTable = ({appointments, cancelAppointment}) => {
    const loginContext = useContext(LoginStateContext)
    const navigate = useNavigate();
    const [open, setOpen] = useState(false)
    const [cancelReason, setCancelReason] = useState('')
    const [bookingSlug, setBookingSlug] = useState('');
    const [show, setShow] = useState(false);
    const [error, setError] = useState('');
    const {
        register,
        handleSubmit,
        resetField
    } = useForm({
        defaultValues: {
            password: "",
        }
    });

    const handleClose = () => {
        setShow(false);
        resetField("password");
        setError('');
    };
    const handleShow = (slug) => {
        setBookingSlug(slug);
        setShow(true);
    };

    const [testVar, setTestVar] = useState(true)

    const getOpenStatus = () => {
        return testVar
    }

    const awaitCancelReason = (compareVar) => {
        return new Promise((resolve, reject) => {
            let cancelInterval = setInterval(() => {
                // console.log())
                if (open !== compareVar) {
                    resolve({status: true})
                    clearInterval(cancelInterval)
                }
            }, 10)
        })
    }

    const [passwordType, setPasswordType] = useState('password');
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    async function handleClick(token, e, params) {
        // setOpen(true)
        // let res = await awaitCancelReason(open)

        await cancelAppointment(params.appointment)
    }

    const onSubmit = (data) => {
        data.slug = bookingSlug
        axios.post('/api/admin/booking-review/premedical-report', data, {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
            .then(res => {
                if (res.data.message) {
                    setError(res.data.message);
                } else {
                    setShow(false);
                    resetField("password");
                    navigate('/user/appointments/doctor/share/' + bookingSlug, {state: {reports: res.data}});
                }
            })
            .catch((err) => {
                console.log();
            })
    }

    const columns = [
        {
            name: "sn",
            label: "S.N."
        },
        {
            name: "doctorName",
            label: "Doctor Name",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "appointmentDate",
            label: "Appointment Date",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "appointmentTime",
            label: "Appointment Time",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "paymentStat",
            label: "Payment Status",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "appointmentStat",
            label: "Appointment Status",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "appointmentActions",
            label: "Actions",
        },
    ]

    const data = []

    for (let i = 0; i < appointments.length; i++) {
        let appointment = appointments[i]
        const dataObj = {}
        dataObj.sn = i + 1
        dataObj.doctorName = appointment.slot.bookings.doctor.user.name
        dataObj.appointmentDate = appointment.slot.bookings.date
        dataObj.appointmentTime = appointment.slot.slot
        dataObj.paymentStat = appointment.status === 1 ? <span className='text-success'>Completed</span> :
            <span className='text-danger'>Payment Due</span>
        dataObj.appointmentStat = appointment.booking_status
        dataObj.appointmentActions = (
            <div className="d-flex align-items-center">
                {(appointment.meeting === null || appointment.booking_status === "Completed" || appointment.booking_status === "Rejected" || appointment.booking_status === "Cancelled" || appointment.booking_status === "Not Scheduled" || appointment.booking_status === "Slot Unavailable" || appointment.booking_status === "Slot Expired") ?
                    ''
                    :
                    <>
                        <a href={appointment.meeting.join_url} target="_blank" className="btn btn-primary"
                           rel='noreferrer'>Join Meeting</a>
                        <button className='btn btn-primary d-flex ms-2' onClick={() => handleShow(appointment.slug)}>
                            <span className="material-symbols-outlined">
                                share
                            </span>
                        </button>
                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Confirm Password</Modal.Title>
                            </Modal.Header>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Modal.Body>
                                    <div className="form-group mb-3">
                                        <label htmlFor="">Enter your password</label>
                                        <div className="input-group flex-nowrap">
                                            <input type={passwordType} {...register("password")}
                                                   className="form-control"/>
                                            <span className="input-group-text" id="addon-wrapping">
                                                <button type="button" onClick={togglePassword} style={{
                                                    border: 'none',
                                                    backgroundColor: 'transparent',
                                                    float: 'right',
                                                    color: '#333'
                                                }} className="px-2">
                                                    {passwordType === "password" ?
                                                        <FontAwesomeIcon icon={faEyeSlash}/> :
                                                        <FontAwesomeIcon icon={faEye}/>}
                                                </button>
                                            </span>
                                        </div>
                                        <p className='text-danger'>{error}</p>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer className="dashboard-style">
                                    <button className='btn btn-secondary mr-2' type="button" onClick={handleClose}>
                                        Close
                                    </button>
                                    <button className='btn btn-primary' type="submit">
                                        Submit
                                    </button>
                                </Modal.Footer>
                            </form>
                        </Modal>
                    </>
                }
                {appointment.booking_status === "Slot Unavailable" || appointment.booking_status === "Slot Expired" ?
                    ''
                    :
                    <Link to={`/user/appointments/doctor/${appointment.slug}`} className="btn btn-primary d-flex ms-2">
                        <span className="material-symbols-outlined">
                            visibility
                        </span>
                    </Link>
                }
                {
                    appointment.booking_status === "Not Scheduled" &&
                    <AuthButton
                        component='div'
                        className='btn btn-danger ms-2'
                        callbackFn={handleClick}
                        parameters={{appointment: appointment.id}}
                    >
                        <CancelOutlined/>
                    </AuthButton>
                    // <div className="btn btn-danger ms-2" onClick={cancelAppointment}>

                    // </div>
                }
            </div>
        )
        data.push(dataObj)
    }

    const options = {
        filterType: 'checkbox',
        responsive: 'standard'
    };

    return (
        <>
            <MUIDataTable
                title={(<h2>Appointment List</h2>)}
                data={data}
                columns={columns}
                options={options}
            />
            <CancelReasonModal open={open} handleClose={() => setOpen(false)} setCancelReason={setCancelReason}/>
        </>
    )
}

export default MyAppointments
