import {Alert, AlertTitle, Box, InputBase, Pagination} from "@mui/material";
// import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import React, {useContext, useMemo, useRef} from "react";
import axios from "../../../api";
import {useQuery} from "@tanstack/react-query";
import {useChkLogin} from "../../../../middlewares/LoginMiddleware";
import {DateFormat} from "../../../DateFormat";
import {useState} from "react";
import {useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import swal from "sweetalert";
import FormModalContext from "../../../custom_componets/globalFormModal/FormModalContext";
import DeactivateModal from "./DeactivateModal";
import Loader from "../../Loader";
import {
    Call,
    CallOutlined,
    ConfirmationNumberOutlined,
    DateRangeOutlined,
    Done,
    GroupOutlined,
    KeyboardArrowDownOutlined,
    Mail,
    MailOutline,
    SchoolOutlined,
    Search,
} from "@mui/icons-material";
import {blue} from "@mui/material/colors";
import StudentUnpaid from "./StudentUnpaid";

function StudentLists() {
    const {token, data: userData} = useChkLogin();
    const {openFormModal} = useContext(FormModalContext);
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(1);
    const [allChk, setAllChk] = useState(false);
    const [sending, setSending] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    console.log("Search params are", searchParams.size);
    const [filters, setFilters] = useState({year: "", class: ""});
    let count = useRef(0);
    const {
        data: studentData,
        isLoading: studentLoading,
        isError: studetnError,
        refetch: studentRefetch,
    } = useQuery(["student-data", token], async () =>
        axios
            .get(`api/company-school-profile/student-details`, {
                params: {...Object.fromEntries(searchParams), page: page},
                headers: {Authorization: `Bearer ${token}`},
            })
            .then((res) => res.data)
            .catch((err) => err)
    );
    console.log("Student data", studentData);
    const {
        data: pkgData,
        isLoading: pkgLoading,
        isError: pkgError,
        refetch: pkgRefetch,
    } = useQuery(["mypackage", token], async () =>
        axios
            .get("/api/admin/userpackage", {
                headers: {Authorization: `Bearer ${token}`},
            })
            .then((res) => {
                console.log(res.data);
                return res.data;
            })
    );

    console.log("Package data", pkgData);

    useEffect(() => {
        studentRefetch();
    }, [searchParams, page]);

    if (studentLoading || pkgLoading) {
        return (
            <div className="d-flex p-4 justify-content-center align-items-center">
                <Loader/>
            </div>
        );
    }

    function handleCheck(e, val) {
        console.log("Handling check");
        let value = Number(val);
        setAllChk(false);
        if (selected.includes(value)) {
            let filtered = selected.filter((item) => item != value) || [];
            setSelected(filtered);
        } else {
            let selArr = selected;
            selArr.push(value);
            setSelected([...selArr]);
        }
    }

    function handleChkAll(e) {
        setAllChk(e.target.checked);
        let addSel = studentData.data.map((item) => item.member.user.id);
        if (e.target.checked) {
            let newArr = addSel.filter((item) =>
                selected.includes(item) ? false : true
            );
            console.log("New Array ", newArr);
            setSelected((prev) => [...prev, ...newArr]);
        } else {
            let newAddSel = selected.filter((item) => {
                return addSel.includes(item.id) ? true : false;
            });
            console.log(newAddSel);
            setSelected((prev) => newAddSel);
        }
    }

    function handleSubmit(e) {
        console.log("Selected students", selected);
    }

    function handlePageChange(e, page) {
        setAllChk(false);
        setPage(page);
    }

    function handleFilterChange(e) {
        setFilters((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    }

    function applyFilter() {
        setSearchParams({...Object.fromEntries(searchParams), ...filters});
    }

    function clearFilter() {
        setSearchParams({});
    }

    function deactivateStd() {
        let paramData = selected.join();
        if (paramData.length === 0) {
            return swal({
                title: "No Students",
                text:
                    "You haven't selected any students. Please select students to proceed",
                icon: "error",
            });
        }

        function deactivate(rejectReason) {
            axios
                .post(
                    `api/company-school-profile/deactivate-users?id=${paramData}`,
                    {
                        profile_id: userData.school_profile.id,
                        deactivate_reason: rejectReason,
                    },
                    {headers: {Authorization: `Bearer ${token}`}}
                )
                .then((res) => {
                    console.log(res.data, "Deactivated");
                    swal({
                        title: "Students Deactivation!",
                        text:
                            "The selected students deactivation request sent successfully. Waiting for GD Admin approval.",
                        icon: "success",
                        closeOnClickOutside: false,
                    }).then((value) => {
                        setAllChk(false);
                        setSelected([]);
                    });
                    setSearchParams({});
                    setPage(1);
                })
                .catch((err) => console.log(err));
        }

        openFormModal(<DeactivateModal deactivateFn={deactivate}/>);

        console.log("Selected id", selected);
    }

    function sendLoginCredentials() {
        if (sending) {
            return
        }
        console.log('Clkicked')
        setSending(true);
        axios
            .post(
                "/api/send-email-for-school-students",
                {},
                {headers: {Authorization: `Bearer ${token}`}}
            )
            .then((res) => {
                console.log('response', res)
                swal({
                    title: "Email Request Sent",
                    text:
                        "The request for email has been sent.",
                    icon: "success",
                    closeOnClickOutside: false,
                })
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setSending(false);
            });
    }

    return (
        <>
            <div className="card-header d-flex align-items-center justify-content-between">
                <div>
                    <span className="fs-4 fw-bold">Student Lists</span>
                    <div>
                        <span className="fs-6 fw-light">View Your Students</span>
                    </div>
                </div>
                <div>
                    {pkgData?.package?.package_status === "Activated" && (
                        <button onClick={sendLoginCredentials} className="btn btn-primary">Email Login
                            Credentials</button>
                    )}
                </div>
            </div>
            <div className="mb-4">
                <StudentUnpaid/>
            </div>
            <div className="row justify-content-end g-2 mb-1">
                {searchParams.size > 0 && (
                    <div className="col-2">
                        <div
                            className="h-100 badge badge-info d-flex align-items-center gap-2 justify-content-end ms-auto"
                            style={{width: "fit-content"}}
                        >
                            <div className="fw-light fs-6">Total Students:</div>
                            <div className="fs-6 fw-bold">{studentData.total}</div>
                        </div>
                    </div>
                )}
                {/* <div className="col-2">
                    <div className='d-flex align-items-center border border-2 rounded bg-white h-100 shadow-sm'>
                        <div className='p-1'>
                            <Search className='fs-5' />
                        </div>
                        <div className='flex-grow-1 px-2 py-1'>
                            <InputBase type='search' fullWidth className='fs-6' placeholder='Search for students' />
                        </div>
                    </div>
                </div> */}
                <div className="col-2">
                    <div
                        className="d-flex align-items-center border border-2 rounded justify-content-between h-100 bg-white shadow-sm">
                        <div className="p-1 px-2">
                            <InputBase
                                type="text"
                                name="class"
                                placeholder="Student Class"
                                value={filters.class}
                                onChange={handleFilterChange}
                                fullWidth
                            />
                        </div>
                        <div className="p-1">
                            <KeyboardArrowDownOutlined className="text-muted"/>
                        </div>
                    </div>
                </div>
                <div className="col-2">
                    <div
                        className="d-flex align-items-center border border-2 rounded justify-content-between h-100 bg-white shadow-sm">
                        <div className="p-1 px-2 flex-grow-1">
                            <InputBase
                                type="text"
                                name="year"
                                placeholder="Student Year"
                                value={filters.year}
                                onChange={handleFilterChange}
                                fullWidth
                            />
                        </div>
                        <div className="p-1">
                            <KeyboardArrowDownOutlined className="text-muted"/>
                        </div>
                    </div>
                </div>
                <div className="col-1">
                    <button className="btn btn-primary w-100 h-100" onClick={applyFilter}>
                        Apply Filter
                    </button>
                </div>
                <div className="col-1">
                    <button className="btn btn-danger w-100 h-100" onClick={clearFilter}>
                        Clear Filters
                    </button>
                </div>
            </div>

            <Box className="card-body">
                <table className="table">
                    <thead>
                    <Box component="tr" bgcolor={"#"}>
                        <th>
                            <input
                                type="checkbox"
                                style={{width: 16, height: 16}}
                                checked={allChk}
                                onClick={handleChkAll}
                            />
                        </th>
                        <th className="text-muted text-uppercase">S.N.</th>
                        <th>
                            <span className="text-muted text-uppercase">Student Name</span>
                        </th>
                        <th>
                            <span className="text-muted text-uppercase">Address</span>
                        </th>
                        <th>
                            <span className="text-muted text-uppercase">Date of Birth</span>
                        </th>
                        <th>
                <span className="text-muted text-uppercase">
                  Parent Contact
                </span>
                        </th>
                        <th>
                            <span className="text-muted text-uppercase">Class Detail</span>
                        </th>
                        <th>
                <span className="text-muted text-uppercase">
                  Student Status
                </span>
                        </th>
                    </Box>
                    </thead>
                    <tbody
                        style={{background: "#fff"}}
                        className="border-left border-right shadow-sm"
                    >
                    {studentData.data.map((item, idx) => (
                        <tr key={item.id}>
                            <td>
                                <input
                                    type="checkbox"
                                    value={item.id}
                                    style={{width: 16, height: 16}}
                                    onClick={(e) => handleCheck(e, item.member.user.id)}
                                    checked={selected.includes(Number(item.member.user.id))}
                                />
                            </td>
                            <td>{idx + 1}</td>
                            <td>
                                <div>
                                    <small className="fw-light">{item.member.gender}</small>
                                </div>
                                <div>
                    <span className="fs-6">
                      {item.member.user.first_name +
                          " " +
                          (item.member.user.middle_name || " ") +
                          " " +
                          item.member.user.last_name}
                    </span>
                                </div>
                                <div>
                    <span className="text-primary">
                      {item.member.user.user_name}
                    </span>
                                </div>
                            </td>
                            <td>
                                <span>{item.member.address}</span>
                            </td>
                            <td>
                                <span>{DateFormat(item.member.dob).getFullDateText()}</span>
                            </td>
                            <td>
                                <div className="d-flex gap-1 align-items-center">
                                    <MailOutline className="fs-6"/>
                                    <span>{item.parent_email}</span>
                                </div>
                                <div className="d-flex gap-1 align-items-center">
                                    <CallOutlined className="fs-6"/>
                                    <span>{item.parent_phone}</span>
                                </div>
                            </td>
                            <td>
                                <div className="d-flex gap-1 align-items-center">
                                    <DateRangeOutlined className="fs-6"/>
                                    <span>{item.year}</span>
                                </div>
                                <div className="d-flex gap-2">
                                    <div className="d-flex gap-1 align-items-center">
                                        <SchoolOutlined className="fs-6"/>
                                        <span>{item.class}</span>
                                    </div>
                                    <div className="d-flex gap-1 align-items-center">
                                        <GroupOutlined className="fs-6"/>
                                        <span>{item.section}</span>
                                    </div>
                                    <div className="d-flex gap-1 align-items-center">
                                        <ConfirmationNumberOutlined className="fs-6"/>
                                        <span>{item.roll}</span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                {pkgData.package === null ||
                                pkgData.package?.status === "Not Booked" ? (
                                    <div
                                        className="badge badge-info d-flex align-items-center gap-1"
                                        style={{width: "fit-content"}}
                                    >
                                        <Done sx={{fontSize: "14px"}}/>
                                        <span>Listed</span>
                                    </div>
                                ) : (
                                    <div
                                        className="badge badge-success d-flex align-items-center gap-1"
                                        style={{width: "fit-content"}}
                                    >
                                        <Done sx={{fontSize: "14px"}}/>
                                        <span>Activated</span>
                                    </div>
                                )}
                                {/* <div className="badge badge-success d-flex align-items-center gap-1" style={{ width: 'fit-content' }}>
                                            <Done sx={{ fontSize: '14px' }} />
                                            <span>Activated</span>
                                        </div> */}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <div className="mt-2 d-flex justify-content-between align-items-center">
                    <button className="btn btn-primary" onClick={deactivateStd}>
                        Deactivate
                    </button>
                    <Pagination
                        count={studentData.last_page}
                        size="large"
                        onChange={handlePageChange}
                    />
                </div>
            </Box>
        </>
    );
}

// function StudentLists() {
//     const loginObj = useChkLogin()
//     const { openFormModal } = useContext(FormModalContext)
//     const [data, setData] = useState(null)
//     const [isLoading, setLoading] = useState(true)
//     const [selected, setSelected] = useState([])
//     const [page, setPage] = useState(1)
//     const [allChk, setAllChk] = useState(false)
//     const [filterObj, setFilterObj] = useState({
//         year: null,
//         class: null,
//     })
//     const [filtered, setFiltered] = useState(false)
//     const [searchParams, setSearchParams] = useSearchParams()

//     const { data: pkg, isLoading: pkgLoading } = useQuery(
//         ['my-package', loginObj.token],
//         async () => (axios.get('/api/admin/userpackage', { headers: { "Authorization": `Bearer ${loginObj.token}` } }).then(res => { console.log(res.data); return res.data }))
//     )

//     function handlePageChange(e, page) {
//         setPage(page)
//     }

//     function fetchStudents() {
//         console.log(Object.fromEntries(searchParams))
//         let queryParam = Object.fromEntries(searchParams)
//         axios.get(`api/company-school-profile/student-details`, { params: { ...queryParam, page: page }, headers: { 'Authorization': `Bearer ${loginObj.token}` } })
//             .then(res => {
//                 console.log(res.data)
//                 setData(res.data)
//             })
//             .finally(() => setLoading(false))
//     }

//     function handleCheck(e) {
//         setAllChk(false)
//         if (selected.includes(Number(e.target.name))) {
//             let filtered = selected.filter(item => item != Number(e.target.name)) || []
//             setSelected(filtered)
//         }
//         else {
//             let selArr = selected
//             selArr.push(Number(e.target.name))
//             setSelected([...selArr])
//         }
//     }

//     useEffect(() => {
//         fetchStudents()
//         if (Object.keys(Object.fromEntries(searchParams)).length > 0) {
//             setFiltered(true)
//         } else {
//             setFiltered(false)
//         }
//     }, [page, searchParams])

//     if (isLoading || pkgLoading) {
//         return (
//             <div className="w-100 d-flex justify-content-center p-5"><Loader /></div>
//         )
//     }

//     function filterStudent() {
//         setSearchParams({ ...Object.fromEntries(searchParams), ...filterObj })
//     }

//     function clearFilter() {
//         setSearchParams({})
//     }

//     function handleFilter(e) {
//         setFilterObj(prev => ({
//             ...prev,
//             [e.target.name]: e.target.value
//         }))
//     }

//     function deactivateStd() {
//         let paramData = selected.join()
//         function deactivate(rejectReason) {

//             axios.post(`api/deactivate-users?id=${paramData}`, { profile_id: loginObj.data.school_profile.id, deactivate_reason:rejectReason }, { headers: { 'Authorization': `Bearer ${loginObj.token}` } })
//                 .then(res => {
//                     console.log(res.data, 'Deactivated')
//                     swal({
//                         title: "Students Deactivation!",
//                         text: "The selected students deactivation request sent successfully. Waiting for GD Admin approval.",
//                         icon: "success",
//                         closeOnClickOutside: false
//                     }).then(value => {
//                         setAllChk(false)
//                         setSelected([])
//                     })
//                     fetchStudents()
//                 })
//                 .catch(err => console.log(err))
//         }
//         openFormModal(<DeactivateModal deactivateFn={deactivate} />)

//         console.log('Selected id', selected)
//     }

//     function handleChkAll(e) {
//         setAllChk(e.target.checked)
//         if (e.target.checked) {
//             let newSel = data.data.map((item) => (
//                 item.member.user.is_verified == 1 && item.member.user.id
//             ))
//             setSelected(newSel)
//         }
//         else {
//             setSelected([])
//         }
//     }

//     return (
//         <>
//             <div className="row align-items-center">
//                 <div className="col-12 col-md-3 mb-3">
//                     <label className='fw-bold'>Student Class</label>
//                     <input name='class' className='form-control' type="text" placeholder='Input class' onChange={handleFilter} value={filterObj.class} />
//                 </div>
//                 <div className="col-12 col-md-3 mb-3">
//                     <label className='fw-bold'>Class Year</label>
//                     <input name='year' className='form-control' type="text" placeholder='Input Class Year' onChange={handleFilter} value={filterObj.year} />
//                 </div>
//                 <div className="col-md-5 mb-3" style={{ alignSelf: 'end' }}>
//                     <div className="d-flex align-items-center gap-2" >
//                         <button className="btn btn-primary" onClick={filterStudent}>
//                             Apply Filter
//                         </button>
//                         {
//                             Object.keys(Object.fromEntries(searchParams)).length > 0 &&
//                             <button className="btn btn-danger" onClick={clearFilter}>
//                                 Clear Filter
//                             </button>
//                         }
//                         {
//                             filtered && (
//                                 <span className="badge badge-info fs-6">
//                                     Total Filtered: {data.data.length}
//                                 </span>
//                             )
//                         }
//                     </div>

//                 </div>
//             </div>
//             {
//                 data.data.length === 0 ?
//                     <Alert severity='info' color='primary' sx={{ boxShadow: 1 }}>
//                         <AlertTitle>No Data</AlertTitle>
//                         <span className='fw-light fs-6'>No students found.</span>
//                     </Alert>
//                     :
//                     <div className="bg-white">
//                         <Table data={data} allChk={allChk} handleChkAll={handleChkAll} filtered={filtered} handleCheck={handleCheck} deactivateStd={deactivateStd} page={page} handlePageChange={handlePageChange} selected={selected} pkg={pkg} />
//                     </div>
//             }

//         </>
//     )
// }

export default StudentLists;

const Table = ({
                   data,
                   filtered,
                   allChk,
                   handleCheck,
                   deactivateStd,
                   page,
                   handleChkAll,
                   handlePageChange,
                   selected,
                   pkg,
               }) => {
    // Define the table instance
    let count = useRef(0);
    return (
        <>
            <table class="table table-striped table-bordered">
                <thead>
                <tr>
                    {/* <th scope="col" className='fs-6 fw-bold'>SN</th> */}
                    {filtered ? (
                        <th scope="col">
                            <input
                                type="checkbox"
                                onClick={handleChkAll}
                                checked={allChk}
                            />
                        </th>
                    ) : (
                        <th scope="col" className="fs-6 fw-bold">
                            S.N.
                        </th>
                    )}
                    <th scope="col" className="fs-6 fw-bold">
                        Full Name
                    </th>
                    <th scope="col" className="fs-6 fw-bold">
                        Gender
                    </th>
                    <th scope="col" className="fs-6 fw-bold">
                        Username
                    </th>
                    <th scope="col" className="fs-6 fw-bold">
                        Address
                    </th>
                    <th scope="col" className="fs-6 fw-bold">
                        Date of Birth
                    </th>
                    <th scope="col" className="fs-6 fw-bold">
                        Year
                    </th>
                    <th scope="col" className="fs-6 fw-bold">
                        Class
                    </th>
                    <th scope="col" className="fs-6 fw-bold">
                        Section
                    </th>
                    <th scope="col" className="fs-6 fw-bold">
                        Roll
                    </th>
                    <th scope="col" className="fs-6 fw-bold">
                        Parent Email
                    </th>
                    <th scope="col" className="fs-6 fw-bold">
                        Parent Phone
                    </th>
                    <th scope="col" className="fs-6 fw-bold">
                        Status
                    </th>
                </tr>
                </thead>
                <tbody>
                {// data.data.filter(students => students.)
                    data.data.map((item, idx) => (
                        <tr key={item.id}>
                            {console.log(
                                "item.member.user.name",
                                selected.includes(item.member.user.id)
                            )}
                            {filtered ? (
                                <td className="fs-6">
                                    <input
                                        type="checkbox"
                                        onClick={handleCheck}
                                        name={item.member.user.id}
                                        checked={selected.includes(Number(item.member.user.id))}
                                        disabled={item.member.user.is_verified == 0}
                                    />
                                </td>
                            ) : (
                                <td className="fs-6">
                                    {(function () {
                                        count.current = count.current + 1;
                                        return count.current;
                                    })()}
                                </td>
                            )}
                            {/* <th scope="row" className='fs-6 '>{idx + 1}</th> */}
                            <td className="fs-6 ">
                                {item.member.user.first_name +
                                    " " +
                                    (item.member.user.middle_name || " ") +
                                    " " +
                                    item.member.user.last_name}
                            </td>
                            <td className="fs-6 ">{item.member.gender}</td>
                            <td className="fs-6 ">{item.member.user.user_name}</td>
                            <td className="fs-6 ">{item.member.address}</td>
                            <td className="fs-6 ">
                                {DateFormat(item.member.dob).getFullDateText()}
                            </td>
                            <td className="fs-6 ">{item.year}</td>
                            <td className="fs-6 ">{item.class}</td>
                            <td className="fs-6 ">{item.section}</td>
                            <td className="fs-6 ">{item.roll}</td>
                            <td className="fs-6 ">{item.parent_email}</td>
                            <td className="fs-6 ">{item.parent_phone}</td>
                            <td className="fs-6 ">
                                {item.member.user.is_verified === 1 ? (
                                    pkg.package === null ||
                                    pkg.package?.status === "Not Booked" ? (
                                        <div className="badge badge-danger">Listed</div>
                                    ) : (
                                        <div className="badge badge-success">Activated</div>
                                    )
                                ) : (
                                    <div className="badge badge-danger">Deactivated</div>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="d-flex justify-content-end mt-4">
                <Pagination
                    count={data.last_page}
                    size="large"
                    onChange={handlePageChange}
                />
            </div>
            {filtered && (
                <button className="btn btn-danger mt-4" onClick={deactivateStd}>
                    Deactivate
                </button>
            )}
        </>
    );
};
