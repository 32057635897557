import "../../components/stores/WishListItem.css";
import axios from "../api.js";
import swal from "sweetalert";
import {addToLocalStorage} from "./LocalDatabaseQ";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashCan, faCartPlus} from "@fortawesome/free-solid-svg-icons";
// import { useNavigate } from 'react-router-dom'

const WishListItem = (props) => {
    const prod = props.prod.product
    // const navigate = useNavigate()
    let token = localStorage.getItem("token")

    const deleteAPI = async () => {
        return axios.delete(`api/admin/wishlist/wishlistDelete/${props.prod.id}`, {headers: {"Authorization": `Bearer ${token}`}})
            .then((res) => {
                props.setUpdated(!props.updated)
                return true;
            })
    }

    const deleteItem = () => {
        swal({
            title: "Are you sure?",
            text: "This product will be removed from your WishList",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deleteAPI().then((res) => {
                    props.setUpdated(!props.updated)
                    swal("Product Removed From Cart", {
                        icon: "success",
                    });
                })
            }
        });

    }

    const addToCart = () => {
        prod.cartCount = 1
        let res = addToLocalStorage("cart", prod)

        deleteAPI().then((r) => {
            if (res) {
                swal("Success", "Product Added To Cart", "success")
            } else {
                swal("Error", "Product Already Present In Cart", "error")
            }
        })
    }

    return (
        <div className="wish-item-container mb-1">
            <div className="row h-100">
                <div className="col-4 col-md-2 h-100">
                    <div className="img-div">
                        <img src={prod.image_path} alt={prod.productName}/>
                    </div>
                </div>

                <div className="col-4 col-md-6 d-flex align-self-center">
                    <div className="row w-100">
                        <div className="col-12 col-md-6 d-flex align-self-center">
                            <div className="prod-name">
                                <h5><span className="txt-highlight">{prod.productName}</span></h5>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 d-flex align-self-center">
                            <div className="prod-price">
                                <h5>Price Per Item: <span className="txt-highlight">Rs. {prod.sale_price}</span></h5>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="col-3 d-flex align-self-center">
                    <div className="prod-name">
                        <h5><span className="txt-highlight">{ prod.productName }</span></h5>
                    </div>
                </div>
                <div className="col-3 d-flex align-self-center">
                    <div className="prod-price">
                        <h5>Price Per Item:<span className="txt-highlight"> Rs. { prod.sale_price } </span></h5>
                    </div>
                </div> */}

                <div className="col-4 align-self-center">
                    <div className="row w-100">
                        <div className="col-12 col-md-6 d-flex align-self-center">
                            <div className="add-to-cart" onClick={addToCart}>
                                <button className="add-cart-btn"><FontAwesomeIcon icon={faCartPlus} size="l"/> Add To
                                    Cart
                                </button>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 d-flex align-self-center">
                            <div className="delete" onClick={deleteItem}>
                                <button className="delete-btn"><FontAwesomeIcon icon={faTrashCan} size="l"/>Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="col-2 col-md-2 d-flex align-self-center">
                    <div className="add-to-cart" onClick={addToCart}>
                        <button className="add-cart-btn"><FontAwesomeIcon icon={faCartPlus} size="l" /> Add To Cart</button>
                    </div>
                </div>
                <div className="col-2 col-md-2 d-flex align-self-center">
                    <div className="delete" onClick={deleteItem}>
                        <button className="delete-btn"><FontAwesomeIcon icon={faTrashCan} size="l" />Delete</button>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default WishListItem
