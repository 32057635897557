import {useEffect, useState} from "react";
import ErrorBoundary from "../../custom_componets/ErrorBoundry";
import KYCUpdateInfo from "./KYCUpdateInfo";
import {useLocation} from "react-router-dom";

const ContentWrapper = (props) => {
    const [error, setError] = useState(false)
    const location = useLocation()

    useEffect(() => {
        setError(false)
    }, [location])

    return (
        <div className="content-wrapper position-relative">
            {/* Inner content */}
            <KYCUpdateInfo/>
            <div className="content-inner">
                {/* Page header */}
                <div className="page-header page-header-light">
                    <div className="page-header-content header-elements-lg-inline">
                        <div className="page-title d-flex">
                            <h4>
                                <i className="icon-arrow-left52 mr-2"/>{" "}
                                <span className="font-weight-semibold">Home</span> - Dashboard
                            </h4>
                            <a
                                href="#"
                                className="header-elements-toggle text-body d-lg-none"
                            >
                                <i className="icon-more"/>
                            </a>
                        </div>
                    </div>
                    <div className="breadcrumb-line breadcrumb-line-light header-elements-lg-inline">
                        <div className="d-flex">
                            <div className="breadcrumb">
                                <a href="/users" className="breadcrumb-item">
                                    <i className="icon-home2 mr-2"/> Home
                                </a>
                                <span className="breadcrumb-item active">Dashboard</span>
                            </div>
                            <a
                                href="#"
                                className="header-elements-toggle text-body d-lg-none"
                            >
                                <i className="icon-more"/>
                            </a>
                        </div>

                    </div>
                </div>
                {/* /page header */}
                {/* Content area */}
                <div className="content">
                    {
                        error ? <h2>There was an error. Please Try Again.</h2>
                            :
                            <ErrorBoundary initialValue={error} updateState={setError} setError={setError}>
                                {props.children}
                            </ErrorBoundary>
                    }

                </div>
                {/* /content area */}
                {/* Footer */}
                <div className="navbar navbar-expand-lg navbar-light border-bottom-0 border-top">
                    <div className="text-center d-lg-none w-100">
                        <button
                            type="button"
                            className="navbar-toggler dropdown-toggle"
                            data-toggle="collapse"
                            data-target="#navbar-footer"
                        >
                            <i className="icon-unfold mr-2"/>
                            Footer
                        </button>
                    </div>
                    {/* <div className="navbar-collapse collapse" id="navbar-footer">
            <span className="navbar-text">
              Today Date: Thu Dec 08 2022 14:41:12 GMT+0545 (Nepal Time)
            </span>
            <ul className="navbar-nav ml-lg-auto">
              <span className="navbar-text">All Rights Reserved. © 2022 </span>
            </ul>
          </div> */}
                </div>
                {/* /footer */}
            </div>
            {/* /inner content */}
            <div className="btn-to-top">
                <button type="button" className="btn btn-dark btn-icon rounded-pill">
                    <i className="icon-arrow-up8"/>
                </button>
            </div>
        </div>
    );
};

export default ContentWrapper;
