import React, {useState} from 'react'
import NoData from '../../../custom_componets/NoData'
import {useChkLogin} from '../../../../middlewares/LoginMiddleware'
import axios from '../../../api'
import swal from 'sweetalert'

function ScreeningChange({pkg, refetch}) {
    console.log('Package', pkg)

    return (
        <div className="card h-100">
            <div className="card-body">
                <h3 className="mb-4 text-center" style={{fontWeight: '700'}}>
                    Reschedule Screening
                </h3>
                <ScreeningChangeBody pkg={pkg} refetch={refetch}/>
            </div>
        </div>
    )
}

function ScreeningChangeBody({pkg, refetch}) {
    const [data, setData] = useState({
        userpackage_id: pkg.id,
        screeningdate_id: pkg.dates[pkg.dates.length - 1]?.screening_id,
        date: null,
        time: null,
    })
    const [submitting, setSubmitting] = useState(false)
    const login = useChkLogin()

    function handleChange(e) {
        setData(prev => (
            {
                ...prev,
                [e.target.name]: e.target.value
            }
        ))
    }

    function handleSubmit(e) {
        e.preventDefault()
        if (submitting) return;
        setSubmitting(true)
        axios.post(`api/admin/request-screening-change`, data, {headers: {'Authorization': `Bearer ${login.token}`}})
            .then(res => {
                refetch()
                swal("Request sent", "Reschedule request sent successfully", "success")
            }).catch(err => {
            console.log('ERror handler', err)
        }).finally(() => setSubmitting(false))
    }

    if (pkg.package.schedule_flexibility !== 1) {
        return (
            <NoData title="Schedule change not available"
                    description='Doctor schedule change feature is not available on this package.'/>
        )
    }

    if (pkg.dates.length === 0 || pkg?.dates[pkg.dates.length - 1]?.screening?.screening === 'First Screening') {
        return (
            <NoData title='Schedule change not available' description='Cannot change schedule in first screening'/>
        )
    }

    if (pkg.requests.length >= pkg.package.schedule_times) {
        return (
            <NoData title='Schedule change not available'
                    description={`You have already changed screening schedule ${pkg.package.schedule_times} times. You can't change further.`}/>
        )
    }

    if (pkg.requests[pkg.requests.length - 1].screeningdate_id === pkg.dates[pkg.dates.length - 1].screening_id) {
        return (
            <NoData title="Schedule change not available"
                    description='You have already rescheduled for this screening.'/>
        )
    }

    if (pkg?.dates[pkg.dates.length - 1]?.status !== 'Doctor Visit') {
        return (
            <NoData title="Schedule change not available"
                    description='You can can change schedule once doctor visit date is added.'/>
        )
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <label className='form-label'>New screening date</label>
                <input type="date" name='date' className='form-control' onChange={handleChange}/>
            </div>
            <div className="mb-3">
                <label className="form-label">New screening time</label>
                <input type="time" name='time' className='form-control' onChange={handleChange}/>
            </div>
            <div>
                <input type="submit" value='Request' className='btn btn-primary' disabled={submitting}/>
            </div>
        </form>
    )
}

export default ScreeningChange
