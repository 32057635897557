import React from 'react'
import Esewa from '../../../images/payment/esewa.png'
// import Khalti from '../../../images/payment/khalti.png'
import ImePay from '../../../images/payment/imepay.png'
import ConnectIPS from '../../../images/payment/connectips.png'
import KhaltiImg from '../../../images/payment/khalti.png'
import "../../../components/pages/ChooseDate/BookingPayment.js"
import {useLocation, useNavigate} from 'react-router-dom'
import Khalti from '../khalti/Khalti'
import KhaltiDoctor from '../khalti/KhaltiDoctor'
import {useState} from 'react'
import {payKhaltiConfig, payWithKhalti} from '../../../features/payment/paymentService'
import {useChkLogin} from '../../../middlewares/LoginMiddleware'
import BookingProvider, {useBookingCtx} from './BookingPaymentContext.js'
import {doctorBookingVerification} from '../../../features/payment/verifications.js'
import swal from 'sweetalert'
import KhaltiPaymentComponent from '../../../features/khalti/components/KhaltiPaymentComponent.jsx'

const BookingPaymentContainer = () => {
    return (
        <BookingProvider>
            <BookingPayment/>
        </BookingProvider>
    )
}

const useBookingPaymentKhalti = () => {
    const {fee, id} = useBookingCtx()
    const {token: userToken, data: userData} = useChkLogin()

    const handlePayment = () => {
        return payWithKhalti(payKhaltiConfig(
            1000,
            {
                name: userData?.member?.name,
                email: userData?.member?.email,
                phone: userData?.member?.phone
            },
            "test01",
            "test",
            `http://localhost:3000/payment-complete/doctor_booking/${id}`
        ), userToken)
    }

    return handlePayment
}

const BookingPayment = () => {
    const {fee, id, redirectLink} = useBookingCtx()
    const {token} = useChkLogin()
    const navigate = useNavigate()
    const handlePaymentKhalti = useBookingPaymentKhalti()

    const handleKhaltiPayment = () => {
        handlePaymentKhalti().then(res => {
            window.open(res.data.payment_url, "_self");
        }).catch(err => console.log(err))
    }

    const verifyPayment = (payload) => {
        const {amount, mobile, pidx, purchase_order_id, purchase_order_name, transaction_id, txnId} = payload
        const reqBody = {
            pidx: pidx,
            amount: amount,
            id: id,
        }
        return doctorBookingVerification(reqBody, token)
    }

    const onSuccess = (response) => {
        if (response.data.success) {
            swal({
                title: response.data.success,
                icon: "success",
                closeOnClickOutside: false
            }).then((value) => {
                if (value) {
                    navigate(redirectLink)
                }
            });
        }
        if (response.data.error) {
            swal({
                title: response.data.error,
                icon: "warning",
                closeOnClickOutside: false
            }).then((value) => {
                if (value) {
                    navigate("/book-doctor/payment", {state: {fee: fee, id: id}})
                }
            });
        }
    }

    const onFailure = (error) => {
        swal({
            title: "Request Fail",
            icon: "warning",
            closeOnClickOutside: false
        });
    }

    return (
        <div className="container my-5 booking-payment ">
            <div className="card">
                {fee !== null &&
                    <div className="card-header">
                        <h5>Payment Amount: <span>Rs. {fee}</span></h5>
                    </div>
                }
                <div className="card-body py-0 px-0">
                    <div className="row align-items-center">
                        <div className="col-md-2 py-4 px-2 text-center">
                            Pay Via:
                        </div>
                        <div className="col-md-10 px-3">
                            <div className="row text-center">
                                {/* <div className="col-lg-3 col-md-6 my-3">
                                    <button type='submit'>
                                        <img src={Esewa} alt="" />
                                    </button>
                                </div> */}
                                <div className="col-lg-3 col-md-6 my-3">
                                    {/* <button onClick={handleKhaltiPayment}>
                                        <img src={KhaltiImg} alt="" />
                                    </button> */}
                                    {/* <KhaltiDoctor amount={fee} id={id} redirectLink='/booking/doctor'/> */}

                                    <KhaltiPaymentComponent
                                        config={{
                                            name: 'Ghargharmadoctor',
                                            email: 'ghargharmadoctor@gmail.com',
                                            phone: '9860291829',
                                            purchaseOrderId: '1231',
                                            purchaseOrderName: 'asdjklasd asda',
                                            amount: 1000 //calcRes.total !== 0 ? calcRes.total : calcRes.result
                                        }}
                                        onVerify={verifyPayment}
                                        onSuccess={onSuccess}
                                        onFailure={onFailure}
                                        render={
                                            (isLoading, isSubmitting, makePayment) => (
                                                <button onClick={makePayment}>
                                                    <img src={KhaltiImg} alt=""/>
                                                </button>
                                            )
                                        }
                                    />
                                </div>
                                {/* <div className="col-lg-3 col-md-6 my-3">
                                    <button type='submit'>
                                        <img src={ImePay} alt="" />
                                    </button>
                                </div>
                                <div className="col-lg-3 col-md-6 my-3">
                                    <button type='submit'>
                                        <img src={ConnectIPS} alt="" />
                                    </button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BookingPaymentContainer
