import React from "react";
import GoogleMapReact from "google-map-react";
import {useState, useEffect} from "react";
import axios from "../api.js";
import AmbulanceImg from '../../images/ambulance.png';

const AnyReactComponent = ({text}) => (
    <div>
        <img src={AmbulanceImg} alt="Ambulance" width='30px' height='100%'/>
    </div>
);

export default function Ambulance() {
    const [ambulance, setAmbulance] = useState([]);
    const handleApiLoaded = (map, maps) => {
        if (map) {
            map.setOptions({gestureHandling: 'greedy', mapTypeControl: true,});
        }
    };
    useEffect(() => {
        axios
            .get("/api/ambulance")
            .then((res) => setAmbulance(res.data))
            .catch((error) => {
                console.log();
            });
    }, []);

    const defaultProps = {
        center: {
            lat: 27.7172,
            lng: 85.324,
        },
        zoom: 12,
    };

    return (
        // Important! Always set the container height explicitly
        <div style={{height: "100vh", width: "100%"}}>
            <GoogleMapReact
                // bootstrapURLKeys={{ key: "" }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                yesIWantToUseGoogleMapApiInternals={true}
                onGoogleApiLoaded={({map, maps}) => handleApiLoaded(map, maps)}
            >
                {ambulance.map((amb) => (
                    <AnyReactComponent
                        key={amb.id}
                        lat={amb.latitude}
                        lng={amb.longitude}
                        text={"#"}
                    />
                ))}
            </GoogleMapReact>
        </div>
    );
}
