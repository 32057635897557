import React from 'react'
import NewsItemContainer from './components/NewsItemContainer'
import ItemDetails from './components/ItemDetails'
import {CircularProgress, Stack} from '@mui/material'
import {useBlogsHome} from './GDBlogProvider'
import {DateFormat} from '../../DateFormat'
import LatestBlogsSkeleton from './components/LatestBlogsSkeleton'

function LatestBlogs() {
    const {data, isLoading} = useBlogsHome()
    if (isLoading) return <LatestBlogsSkeleton/>
    return (
        <Stack gap={2}>
            {data.map((item, idx) => (
                <NewsItemContainer
                    mediaComponent={
                        <img src={item.image_path}/>
                    }
                >
                    <ItemDetails
                        title={item.title_en}
                        author={item?.user?.name || 'Ghargharmadoctor'}
                        category={item.menu.title_en}
                        // description={item.description_en}
                        time={DateFormat(item.created_at).getFullDateText()}
                        blog={item}
                    />
                </NewsItemContainer>
            ))}
        </Stack>
    )
}

export default LatestBlogs
