import React, {useContext, useState} from 'react'
import {GDVendorContext} from '../../gdvendors/GDVendorContext'
import {FilterItem, FilterTextLabel} from './FilterItem'
import ViewMore from '../../components/ViewMore'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {getVendorFilterURL} from '../../gdvendors/helperFunctions/getFilterURL'
import {useEffect} from 'react'
import {ProductFilterContext} from '../ProductFilterContext'

function ProductFilterCategory() {
    const {data, isLoading} = useContext(GDVendorContext).category
    const {vendor} = useContext(GDVendorContext)
    const {selectedCat, setSelectedCat} = useContext(ProductFilterContext)
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const [selectedCategory, setSelectedCategory] = useState(null)
    const queryParams = Object.fromEntries(searchParams)

    const handleClick = (category) => {
        setSelectedCat(category)
        navigate(getVendorFilterURL(vendor.slug, `filter?category_id=${category.id}`))
    }

    useEffect(() => {
        if (queryParams.category_id) {
            setSelectedCategory(queryParams.category_id)
        }
    }, [searchParams])

    if (isLoading) {
        return
    }
    if (data.length === 0) {
        return
    }
    return (
        <FilterItem filterTitle='Category'>
            <ViewMore data={data} limit={4}>
                {
                    (item, idx) => (
                        <FilterTextLabel key={idx} onClick={() => handleClick(item)}
                                         selected={item.id == selectedCat.id}>{item.name}</FilterTextLabel>
                    )
                }
            </ViewMore>
        </FilterItem>
    )
}

export default ProductFilterCategory
