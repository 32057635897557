import {Box} from '@mui/material'
import React, {useEffect, useRef} from 'react'
import {useChkLogin} from '../../../../../../middlewares/LoginMiddleware'
import axios from '../../../../../api'

function ChangeEmail() {
    const otpRef = useRef(null)
    const {token, data} = useChkLogin()
    const member = data.member

    console.log(data)

    useEffect(() => {
        axios.post('/api/update-email', {email: 'lawazu.sabin@gmail.com'}, {headers: {'Authorization': `Bearer ${token}`}})
            .then(res => {
                console.log("Request sent successfully", res)
            }).catch(err => {
            console.log("There was an error while seding the request", err)
        })
    }, [])

    const verifyEmailOTP = () => {
        // axios.get('')
    }

    const handlOTP = (e) => {
        if (e.target.value.length >= 5) {
            verifyEmailOTP(e.target.value)
        }
    }

    useEffect(() => {
        otpRef.current.setAttribute('size', otpRef.current.getAttribute('placeholder').length * 1.3)
    }, [])
    return (
        <Box
            width='100%'
            maxWidth='540px'
            position='absolute'
            top='50%'
            left='50%'
            sx={{transform: 'translate(-50%, -50%)'}}
        >
            <div className="card">
                <div className="card-header">
                    <div>
                        <span className="fs-5">Change Email Address</span>
                    </div>
                    <div>
                        <span className="fs-6 fw-light">Update your email address to stay protected.</span>
                    </div>
                </div>
                <div className="card-body">
                    <div className='d-flex gap-2 mb-4'>
                        <Box
                            borderRadius='50%'
                            color='#fff'
                            width={'26px'}
                            height='26px'
                            display='flex'
                            justifyContent='center'
                            alignItems='center'
                            className='bg-primary'
                        >
                            <span className="fs-6">1</span>
                        </Box>
                        <div>
                            <div className='mb-1'>
                                <span className='text-muted fs-6'>Enter the verification code sent to </span>
                                <span className='fs-6' style={{fontWeight: 550}}>{member.email}</span>
                            </div>
                            <div className='mb-3'>
                                <span className="text-primary fs-6 fw-light"
                                      role='button'>Resend Verficiation Code</span>
                            </div>
                            <div>
                                <input onChange={handlOTP} ref={otpRef} type="text" className='form-control'
                                       placeholder='9FG23H'
                                       style={{fontSize: '25px', letterSpacing: '10px', width: 'auto'}}/>
                            </div>
                        </div>
                    </div>

                    <div className='d-flex gap-2 mb-4'>
                        <Box
                            borderRadius='50%'
                            color='#fff'
                            width={'26px'}
                            height='26px'
                            display='flex'
                            justifyContent='center'
                            alignItems='center'
                            className='bg-primary'
                        >
                            <span className="fs-6">2</span>
                        </Box>
                        <div className='flex-grow-1'>
                            <div className='mb-3'>
                                <span className='text-muted fs-6'>Enter your new email address </span>
                                {/* <span className='fs-6' style={{ fontWeight: 550 }}>bomTamang@email.com</span> */}
                            </div>
                            <div className='w-100 mb-3'>
                                <input type="text" className='form-control w-100' placeholder='ramhari@gmail.com'
                                       style={{fontSize: '18px'}}/>
                            </div>
                            <div>
                                <button className="bg-primary px-4 py-1 border-0 text-white rounded-pill">
                                    Continue
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className='d-flex gap-2 mb-4'>
                        <Box
                            borderRadius='50%'
                            color='#fff'
                            width={'26px'}
                            height='26px'
                            display='flex'
                            justifyContent='center'
                            alignItems='center'
                            className='bg-primary'
                        >
                            <span className="fs-6">3</span>
                        </Box>
                        <div className='flex-grow-1'>
                            <div className='mb-3'>
                                <span className='text-muted fs-6'>Verify your new email address</span>
                                {/* <span className='fs-6' style={{ fontWeight: 550 }}>bomTamang@email.com</span> */}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </Box>
    )
}

export default ChangeEmail
