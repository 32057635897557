import {Box, Grid, Typography} from '@mui/material'
import React from 'react'
import InformationComponent from './InformationComponent'

function ProfileDetails() {
    return (
        <InformationComponent title='Profile Information'>
            <Box textAlign={'left'}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant='subtitle1' color={'text.disabled'}>Address</Typography>
                        <Typography variant='subtitle1' fontWeight={500}>Bagmati, Kathmandu, Baneshwor</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='subtitle1' color={'text.disabled'}>Phone number</Typography>
                        <Typography variant='subtitle1' fontWeight={500}>+977 9860291829</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='subtitle1' color={'text.disabled'}>Gender</Typography>
                        <Typography variant='subtitle1' fontWeight={500}>Male</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='subtitle1' color={'text.disabled'}>Date of birth</Typography>
                        <Typography variant='subtitle1' fontWeight={500}>Jan 1st, 1997</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='subtitle1' color={'text.disabled'}>Weight</Typography>
                        <Typography variant='subtitle1' fontWeight={500}>80 kg</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='subtitle1' color={'text.disabled'}>Height</Typography>
                        <Typography variant='subtitle1' fontWeight={500}>6' 3''</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='subtitle1' color={'text.disabled'}>Age</Typography>
                        <Typography variant='subtitle1' fontWeight={500}>26 years</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='subtitle1' color={'text.disabled'}>Blood group</Typography>
                        <Typography variant='subtitle1' fontWeight={500}>AB +</Typography>
                    </Grid>
                </Grid>
            </Box>
        </InformationComponent>
    )
}

export default ProfileDetails
