import axios from "../../api.js";
import React, {useEffect, useState} from "react";
import "../../../components/pages/ChooseDate/Booking.css";
import Footer from "../../Footer.js";
import {useSearchParams} from "react-router-dom";
import BookingCard from "./BookingCard.js";
import Loader from "../Loader.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRefresh} from "@fortawesome/free-solid-svg-icons";
import AddressAutoComp from "../../AddressAutoComp.js";
import Slider from '@mui/material/Slider';
import Pagination from "react-js-pagination";
import {Alert, AlertTitle, Box, Stack} from "@mui/material";
import {AutoCompleteInput} from "../../geocode/AutoComplete.jsx";
import SearchDropdown from "../../layouts/GDStore/components/SearchDropdown.jsx";
import {useQuery} from "@tanstack/react-query";
import {useChkLogin} from "../../../middlewares/LoginMiddleware.js";

const Booking = () => {
    const loginObj = useChkLogin()
    const [bookingsPaginate, setBookingsPaginate] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [hospitals, setHospital] = useState([]);
    const [loading, setLoading] = useState(true);
    const [minDate, setMinDate] = useState();
    const [maxDate, setMaxDate] = useState();
    const [search, setSearch] = useSearchParams();
    const currentParams = Object.fromEntries([...search]);
    const [coordinates, setCoordinates] = React.useState(null)
    const [defRadius, setDefRadius] = React.useState(5)
    const [filterParams, setFilterParams] = React.useState({})
    const [locationTxt, setLocationTxt] = React.useState('')
    const departmentSelect = React.useRef()
    const distanceSlider = React.useRef()
    let searchInputRef = React.useRef()
    //console.log().format('yyyy-MM-DD HH:mm'));
    const [open, setOpen] = useState(false) //state to control search dropdown
    const [show, setShow] = useState(null) //state to control what to show history or suggestion
    const [searchSuggestion, setSearchSuggestion] = useState([])

    const allBookings = bookingsPaginate.data || []

    const searchHistory = useQuery({
            queryKey: ['doctor-search-history'],
            queryFn: async () => (
                axios.get('/api/search-history?type=doctor', {headers: {'Authorization': `Bearer ${loginObj.token}`}})
                    .then(res => res.data)
            ),
            enabled: (!loginObj.isLoading && loginObj.isLoggedIn)
        }
    )

    useEffect(() => {
        if (departments.length > 0) {
            //console.log()
            if (currentParams.department != null) {
                departmentSelect.current.value = currentParams.department
            }
        }
    }, [departments, search])

    useEffect(() => {
        axios
            .get(`/api/admin/department`)
            .then((res) => {
                setDepartments(res.data);
            })
            .catch((err) => {
                console.log();
            });
    }, []);

    useEffect(() => {
        axios
            .get('/api/hospital')
            .then((res) => {
                setHospital(res.data);
            })
            .catch((err) => {
                console.log();
            });
    }, []);

    const handleItemClick = (keyword) => {
        setOpen(false)
        searchDoctor(keyword)
        // navigate(`/gd-store/filter?keyword=${keyword}`)
    }

    const searchDoctor = (value) => {
        setSearch({...currentParams, keyword: value});

        if (value === '') {
            delete currentParams.keyword;
            setSearch({...currentParams});

        }
    };

    const searchDepartment = e => {
        setFilterParams({...filterParams, department: e.target.value});
        if (e.target.value === '') {

            delete filterParams.department
            setFilterParams({...filterParams});
        }
    };

    const searchHospital = e => {
        // setSearch({ ...currentParams, hospital: e.target.value });
        setFilterParams({...filterParams, hospital: e.target.value});
        if (e.target.value === '') {
            // delete currentParams.hospital;
            // setSearch({ ...currentParams });

            delete filterParams.hospital;
            setFilterParams({...filterParams})
        }
    };

    const searchMinTime = (e) => {
        setMinDate(e);
        // setSearch({ ...currentParams, mintime: moment(e).format('yyyy-MM-DD HH:mm:00'), maxtime: moment(maxDate).format('yyyy-MM-DD HH:mm:00') });

        setFilterParams({
            ...filterParams,
            mintime: moment(e).format('yyyy-MM-DD HH:mm:00'),
            maxtime: moment(maxDate).format('yyyy-MM-DD HH:mm:00')
        });
    };

    const searchMaxTime = (e) => {
        setMaxDate(e);
        // setSearch({ ...currentParams, mintime: moment(minDate).format('yyyy-MM-DD HH:mm:00'), maxtime: moment(e).format('yyyy-MM-DD HH:mm:00') });

        setFilterParams({
            ...filterParams,
            mintime: moment(minDate).format('yyyy-MM-DD HH:mm:00'),
            maxtime: moment(e).format('yyyy-MM-DD HH:mm:00')
        });
    };

    const resetTime = () => {
        // delete currentParams.mintime;
        // delete currentParams.maxtime;

        setMinDate('');
        setMaxDate('');

        // setSearch({ ...currentParams });

        delete filterParams.mintime;
        delete filterParams.maxtime;
    }

    useEffect(() => {
        setLoading(true);
        let extendParam = {}
        if (loginObj.isLoggedIn) {
            extendParam["user_id"] = loginObj.data.member.id;
            extendParam['type'] = 'doctor'
        }
        if (Object.keys(currentParams).length > 0) {
            axios
                .get(`/api/booking/date`, {params: {...currentParams, ...extendParam}}, {headers: {'Authorization': `Bearer ${loginObj.token || null}`}})
                .then((res) => {
                    if (res.data !== "") {
                        console.log("data", res.data)
                        setBookingsPaginate(res.data);
                        setLoading(false);
                    }
                })
                .catch((err) => {
                    console.log();
                });
        } else {
            axios
                .get(`/api/booking/date`)
                .then((res) => {
                    console.log("Data", res.data)
                    setBookingsPaginate(res.data);
                    setLoading(false);
                })
                .catch((err) => {
                    console.log();
                });
        }
    }, [search]);

    useEffect(() => {
        function handleX() {
            delete currentParams.keyword;
            setSearch({...currentParams});
        }

        let element = searchInputRef.current

        element.addEventListener('search', handleX)

        return () => {
            console.log()
            element.removeEventListener('search', handleX)
        }
    }, [])

    useEffect(() => {
        axios.get('api/')
    }, [])

    function filterResults() {
        setSearch({...filterParams})
    }

    function clearFilters() {
        let selectInp = document.getElementsByTagName('select');
        for (let i = 0; i < selectInp.length; i++) {
            selectInp[i].selectedIndex = 0;
        }
        setLocationTxt('')
        resetTime()
        setFilterParams({})
        if ('page' in currentParams) {
            let pg = currentParams.page
            delete currentParams.page
            if (currentParams.length == 0) {
                setSearch({page: pg})
            }
        } else {
            setSearch({})
        }
    }

    async function getSearchSuggestions(e) {
        console.log()
        console.log()
        const res = await getSearchResults(e.target.value)
        if (res === undefined || res === null) return
        if (e.target.value.length === 0) {
            setShow(0)
            return
        }
        console.log()
        setShow(1)
        setSearchSuggestion(res)
    }

    function autoCompLocation(coordinates) {
        setLocationTxt(coordinates.properties.formatted)
        // setCoordinates(coordinates)
        let radius = defRadius
        if (defRadius == "" || defRadius == 0) {
            radius = 0.1
            setDefRadius(0.1)
        }
        setFilterParams({
            ...filterParams,
            latitude: coordinates.properties.lat,
            longitude: coordinates.properties.lon,
            distance: radius
        })
    }

    function valuetext(e, value) {
        setDefRadius(value)
        setRadiusFilter(value)
    }

    function setRadiusFilter(value) {
        if ('latitude' in filterParams) {
            setFilterParams({...filterParams, distance: value})
        }
    }

    function changePage(page) {
        // setFilterParams({page:})
        setSearch({...currentParams, page: page})
    }

    return (
        <>
            <div className="booking">
                <div className="heading">
                    <div className="container text-center">
                        <h3>Search Doctor, Make an Appointment</h3>
                        <p>
                            Discover the best doctors, clinic &amp; hospital nearest to you.
                        </p>
                    </div>
                </div>
                <div className="search">
                    <div className="container py-3">

                        <div className="row">
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="doctor-search">Search Doctor</label>
                                    <div className="input-group position-relative search-function">
                                        <input
                                            type="search"
                                            className="form-control"
                                            placeholder="Search Doctor"
                                            ref={searchInputRef}
                                            onChange={getSearchSuggestions}
                                            onClick={() => {
                                                setOpen(true)
                                                if (!searchInputRef.current.value) {
                                                    setShow(0)
                                                }
                                            }}
                                        />
                                        <div className="input-group-append">
                                            <button className="btn btn-outline-secondary" type="button"
                                                    onClick={(e) => {
                                                        searchDoctor(searchInputRef.current.value)
                                                        searchHistory.refetch()
                                                    }}>Search
                                            </button>
                                        </div>
                                        <SearchDropdown onClick={handleItemClick} refetchHistory={searchHistory.refetch}
                                                        show={show} open={open} close={() => {
                                            setOpen(false)
                                        }} searchResults={searchSuggestion} searchHistory={searchHistory.data || []}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="doctor-search">Location</label>
                                    <AutoCompleteInput
                                        setValue={setLocationTxt}
                                        onPlaceChange={autoCompLocation}
                                    >
                                        {
                                            (handleChange, inputRef) => (
                                                <input
                                                    ref={inputRef}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Location"
                                                    value={locationTxt}
                                                    onChange={handleChange}
                                                />
                                                // <input type="text" ref={inputRef} onChange={handleChange} placeholder='Search Place' />
                                            )
                                        }
                                    </AutoCompleteInput>
                                    {/* <AddressAutoComp
                    getLocation={autoCompLocation}
                    inputValue={locationTxt}
                    onChange={(address) => { setLocationTxt(address) }}
                  /> */}
                                </div>

                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="doctor-search">Find by Speciality</label>
                                    <select ref={departmentSelect} name="" id="" className="form-select"
                                            onChange={searchDepartment} value={filterParams.department}>
                                        <option value="">All Specialities</option>
                                        {departments.map((department) =>
                                            <option value={department.id}
                                                    key={department.id}>{department.department}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="doctor-search">Find by Hospital</label>
                                    <select name="" id="" className="form-select" onChange={searchHospital}
                                            value={filterParams.hospital}>
                                        <option value="" selected>All Hospitals</option>
                                        {hospitals.map((hospital) =>
                                            <option value={hospital.id} key={hospital.id}>{hospital.name}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="row g-1">
                                    <div className="form-group mt-3">
                                        <label htmlFor="">Date &amp; Time Filter: </label>
                                    </div>
                                    <div className="col-md-5">
                                        <div>
                                            <DatePicker
                                                selected={minDate}
                                                onChange={searchMinTime}
                                                timeInputLabel="Time:"
                                                dateFormat="yyyy-MM-dd HH:mm"
                                                showTimeInput
                                                minDate={new Date()}
                                                placeholderText="From Date"
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div>
                                            <DatePicker
                                                selected={maxDate}
                                                onChange={searchMaxTime}
                                                timeInputLabel="Time:"
                                                dateFormat="yyyy-MM-dd HH:mm"
                                                showTimeInput
                                                minDate={new Date()}
                                                placeholderText="To Date"
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <button className="btn btn-outline-primary ms-1" onClick={resetTime}>
                                            <FontAwesomeIcon
                                                icon={faRefresh}
                                                className="icon-content"
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group mt-3">
                                    <label htmlFor="doctor-search" className="me-4">Radius in Kilometer</label>
                                    <div className="row">
                                        <div className="slider-container col-9">
                                            {/* <Slider
                        aria-label="Small steps"
                        onChange={valuetext}
                        min={0.1}
                        max={5}
                        valueLabelDisplay="auto"
                        value={defRadius}
                      /> */}
                                            <Slider
                                                value={(typeof defRadius === 'number' || defRadius != 0) ? defRadius : 0.1}
                                                onChange={valuetext}
                                                min={0.1}
                                                max={5}
                                                aria-labelledby="input-slider"
                                            />
                                        </div>

                                        <input ref={distanceSlider} type="number" value={defRadius} className="col-3"/>
                                    </div>

                                </div>
                            </div>


                            <div className="col-md-6 d-flex align-items-end justify-content-end">
                                <div className="filter-btns">
                                    <button className="btn btn-outline-primary me-3" onClick={filterResults}>
                                        Apply Filter
                                    </button>
                                    <button className="btn btn-outline-danger" onClick={clearFilters}>
                                        Clear Filters
                                    </button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div className="container pt-5 pb-4">
                    {/* <div className='time-filter mb-4'>
            <h6 style={{ fontWeight: '600' }} className="">Date &amp; Time Filter: </h6>
            <div className="d-inline-block">
              <DatePicker
                selected={minDate}
                onChange={searchMinTime}
                timeInputLabel="Time:"
                dateFormat="yyyy-MM-dd HH:mm"
                showTimeInput
                minDate={new Date()}
                placeholderText="From Date"
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
            </div>
            <div className="d-inline-block">
              <DatePicker
                selected={maxDate}
                onChange={searchMaxTime}
                timeInputLabel="Time:"
                dateFormat="yyyy-MM-dd HH:mm"
                showTimeInput
                minDate={new Date()}
                placeholderText="To Date"
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
            </div>
            <button className="btn btn-outline-primary ms-1" onClick={resetTime}>
              <FontAwesomeIcon
                icon={faRefresh}
                className="icon-content"
              />
            </button>
          </div> */}
                    {loading || loginObj.isLoading ?
                        <div className="text-center">
                            <Loader/>
                        </div>
                        :
                        allBookings != null ?
                            <Stack gap={3} sx={{mb: 3}} className='page-loaded'>
                                {
                                    allBookings.length === 0 ?
                                        <Alert severity="error" sx={{boxShadow: 1}}>
                                            <AlertTitle>No Doctors</AlertTitle>
                                            <span className="fs-6 fw-light">There are currently no doctors available. Try again later.</span>
                                        </Alert>
                                        :
                                        allBookings.map((booking) =>
                                                ((!loginObj.isLoggedIn || (loginObj?.data?.member?.id !== booking.user.id)) && booking.fee !== null) && (
                                                    <BookingCard booking={booking} hospitals={hospitals}/>
                                                )
                                        )
                                }
                            </Stack>
                            :
                            ''
                    }

                    <Pagination
                        activePage={bookingsPaginate.current_page}
                        totalItemsCount={bookingsPaginate.total}
                        itemsCountPerPage={bookingsPaginate.per_page}
                        onChange={changePage}
                        itemClass="page-item"
                        linkClass="page-link"
                        firstPageText="First"
                        lastPageText="Last"
                    />
                </div>

            </div>
        </>
    );
};

async function getSearchResults(keyword) {
    console.log()
    const res = await axios.get(`/api/booking/date?keyword=${keyword}`)
    return res.data.data.map((item) => (
        item.user.name
    ))
}

export default Booking;
