import {useQuery} from '@tanstack/react-query'
import React from 'react'
import FitnessContainer from '../../../fitnessCenter/components/FitnessContainer'
import VendorCard from '../../components/VendorCard'
import CatalogSlider from './CatalogSlider'
import axios from '../../../../api'
// import {settings6items} from '../../sliderSettings/View6itemsOnlg'

const ExclusiveVendorSlider = {
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 4,
            }
        },
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 450,
            settings: {
                slidesToShow: 2,
            }
        }
    ]
}

function ExlusiveVendors({children}) {
    const {data, isLoading} = useQuery(
        ['exclusiveVendors'],
        async () => (
            axios.get('/api/exclusive-vendor').then(res => res.data).catch(err => err)
        )
    )
    if (isLoading) {
        return;
    }
    if (data.length === 0) {
        return;
    }
    return (
        <FitnessContainer title="GD Exclusive Vendors">
            <CatalogSlider numItems={6} settingProps={ExclusiveVendorSlider}>
                {
                    data.map((item, idx) => (
                        <div key={idx}>
                            <VendorCard data={item}/>
                        </div>
                    ))
                }
                {/* <div>
                    <VendorCard/>
                </div>
                <div>
                    <VendorCard/>
                </div>
                <div>
                    <VendorCard/>
                </div>
                <div>
                    <VendorCard/>
                </div>
                <div>
                    <VendorCard/>
                </div>
                <div>
                    <VendorCard/>
                </div>
                <div>
                    <VendorCard/>
                </div>  */}
            </CatalogSlider>
        </FitnessContainer>
    )
}

export default ExlusiveVendors
