import React from "react";
import Slider from "react-slick";
// import Headache from "../images/symptoms/headache.png"
// import Fever from "../images/symptoms/fever.png"
// import Weakness from "../images/symptoms/weakness.png"
// import DifficultyBreathing from "../images/symptoms/difficulty-breathing.png"
// import Dizziness from "../images/symptoms/dizzy.png"
// import SoreThroat from "../images/symptoms/sore-throat.png"
// import Appetite from "../images/symptoms/lack-of-appetite.png"
// import Asthma from "../images/symptoms/asthma.png"
// import Fatigue from "../images/symptoms/fatigue.png"
// import Chills from "../images/symptoms/chills.png"
import "./SymptomIdentify.css";
import axios from "./api"
import {useNavigate} from "react-router-dom";

const SymptomIdentify = () => {
    const [symptoms, setSymptoms] = React.useState([])
    const navigate = useNavigate()
    React.useEffect(() => {
        axios.get("api/admin/symptom")
            .then(res => {
                setSymptoms(res.data)
            })
            .catch(err => {
                console.log()
            })
    }, [])

    const settings = {
        dots: false,
        className: "center",
        centerMode: true,
        centerPadding: "60px",
        slidesToShow: 5,
        speed: 500,
        rows: 2,
        slidesPerRow: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    infinite: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]

    };

    return (
        <div className="symptoms-identify">
            <div className="container py-5">
                <div className="row">
                    <div className="col-md-8 heading">
                        <p>Identify and treat your symptoms Instantly</p>
                        <p>Choose Relevant Doctor as per the Symptoms</p>
                    </div>
                    <div className="col-md-4 text-end">
                        <a href="/symptoms" className="btn btn-outline-light speciality-btn">
                            View all Symptoms
                        </a>
                    </div>
                </div>
                <Slider {...settings} className="mt-5 pt-2">
                    {
                        symptoms.map((symptom) => {
                            return (
                                <div className="symptom-container transition-container pt-3" onClick={(e) => {
                                    navigate("/symptoms", {state: {initial: symptom.id}})
                                }}>
                                    <div className="symptom-icon icon-pop-transition">
                                        <img src={symptom.image_path} alt=""/>
                                    </div>
                                    <div className="text-center mt-2">
                                        <p className="mb-0 icon-text">{symptom.name}</p> <br/>
                                        <p className="mb-0">({symptom.name_nepali})</p>
                                    </div>
                                </div>
                            )
                        })
                    }


                </Slider>
            </div>

            {/* <div className=" mt-4 justify-content-between flex-wrap"> */}

            {/* </div> */}
        </div>
    );
};
export default SymptomIdentify;
// import React, { Component } from "react";
// import Slider from "react-slick";

// export default class MultipleRows extends Component {
//   render() {
//     const settings = {
//       dots: false,
//       className: "center",
//       centerMode: true,
//       infinite: true,
//       centerPadding: "60px",
//       slidesToShow: 3,
//       speed: 500,
//       rows: 2,
//       slidesPerRow: 2
//     };
//     return (
//       <div>
//         <h2>Multiple Rows</h2>
//         <Slider {...settings}>
//           <div>
//             <h3>1</h3>
//           </div>
//           <div>
//             <h3>2</h3>
//           </div>
//           <div>
//             <h3>3</h3>
//           </div>
//           <div>
//             <h3>4</h3>
//           </div>
//           <div>
//             <h3>5</h3>
//           </div>
//           <div>
//             <h3>6</h3>
//           </div>
//           <div>
//             <h3>7</h3>
//           </div>
//           <div>
//             <h3>8</h3>
//           </div>
//           <div>
//             <h3>9</h3>
//           </div>
//           <div>
//             <h3>10</h3>
//           </div>
//         </Slider>
//       </div>
//     );
//   }
// }
