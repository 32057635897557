import React, {useContext} from 'react';
import axios from '../../../api.js';
import Doctor from "../../../../images/dummy.jpg";
import {Link, useNavigate} from 'react-router-dom';
import {useQuery} from '@tanstack/react-query';
import {LoginStateContext} from '../../../../contexts/LoginStateContext.js';
import Loader from '../../Loader.js';
import {Box, Button, CircularProgress, IconButton, Modal, Typography} from '@mui/material';
import {Close, RemoveModerator} from '@mui/icons-material';
import {useState} from 'react';
import PhoneInput from 'react-phone-input-2';
import swal from 'sweetalert';

const Profile = () => {
    const loginContext = useContext(LoginStateContext)
    const userData = loginContext.state.data
    const navigate = useNavigate()
    const [openUpdateInfo, setOpenUpdateInfo] = useState(false)
    const [updatePhoneModal, setUpdatePhoneModal] = useState(false)
    const [phoneUpdateNum, setPhoneUpdateNum] = useState(userData.member.phone)

    function openModal(openFunction) {
        openFunction(true)
    }

    function closeModal(closeFunction) {
        closeFunction(false)
    }

    const packageData = useQuery(
        ['package'],
        async () => (
            axios.get('api/admin/userpackage', {headers: {'Authorization': `Bearer ${loginContext.state.token}`}}).then(res => res.data)
        )
    )

    const pg = packageData?.data?.package

    const userProfileQuery = useQuery(
        ["user-profile-data", userData.id],
        async () => {
            return axios.get('/api/admin/user-profile', {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                .then(res => res.data)
        },
    )

    const primaryProfileQuery = useQuery(
        ["family-data", userData.id],
        async () => {
            return axios.get('/api/admin/family', {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                .then(res => res.data)
        },
    )

    if (userProfileQuery.isLoading || primaryProfileQuery.isLoading) {
        return <div className='d-flex justify-content-center'><Loader/></div>
    }

    const user = userProfileQuery.data
    const primary = primaryProfileQuery.data

    function updateNumber() {
        const data = {
            phone: '+' + phoneUpdateNum,
            dob: userData.dob,
            gender: userData.gender,
            name: userData.member.name,
            email: userData.member.email
        }
        axios.post(`/api/admin/user-profile/update/${userData.id}`, data, {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
            .then(res => {
                console.log()
                closeModal(setUpdatePhoneModal)
                closeModal(setOpenUpdateInfo)
                swal({
                    title: "Phone updated successfully!",
                    icon: "success",
                }).then((value) => {
                    if (value) {
                        loginContext.refetch(loginContext.state.token)
                    }
                });
            })
            .catch((error) => {
                // if(error.response.data.message){
                //     setFamilyError(error.response.data.message);
                // }
                console.log()
            });
    }

    return (
        <div className='card'>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-4">
                        <div className="text-center">
                            {user.image_path != null ?
                                <img src={user.image_path} alt="" height="150px" width="150px"
                                     className="rounded-circle"/>
                                : <img src={Doctor} alt="" height="150px" width="150px" className="rounded-circle"/>}
                        </div>
                        <div className="text-center">
                            <h6 className="mb-0">{user.member.name}</h6>
                            <h6 className="text-primary"><i className="icon-user mr-1"></i> GD - {user.id}</h6>
                        </div>
                        <table className="table table-borderless mx-3 my-4">
                            <tbody>
                            <tr>
                                <th>Member Type</th>
                                <td>{user.member_type}</td>
                            </tr>
                            <tr>
                                <th>Family Name</th>
                                {user.member_type === null ? '' :
                                    (user.member_type === 'Primary Member' ?
                                            <td>{primary.family_name}</td>
                                            :
                                            primary.payment_status === 0 ?
                                                <td>{primary.familyname.family_name} <small className='text-danger'>(Pending.
                                                    Payment due)</small></td>
                                                :
                                                primary && (primary.approved !== 0 ?
                                                    <td>{primary.familyname.family_name} <span
                                                        className='text-success'>(Verified)</span></td>
                                                    :
                                                    <td>{primary.familyname.family_name} <span className='text-danger'>(Waiting for Verification)</span>
                                                    </td>)

                                        // !packageData.isLoading &&
                                        // <>
                                        //     {
                                        //         pg === null ?
                                        //             <td>{primary.familyname.family_name} <small className='text-danger'>(Pending. Payment due)</small></td>
                                        //             :
                                        //             primary && (primary.approved !== 0 ?
                                        //                 <td>{primary.familyname.family_name} <span className='text-success'>(Verified)</span></td>
                                        //                 :
                                        //                 <td>{primary.familyname.family_name} <span className='text-danger'>(Waiting for Verification)</span></td>)
                                        //     }
                                        // </>

                                    )
                                }
                            </tr>
                            <tr>
                                <th>Blood Group</th>
                                <td>{user.blood_group}</td>
                            </tr>
                            <tr>
                                <th>BMI</th>
                                <td>
                                    {user.weight && user.height &&
                                        (user.weight / user.height ** 2).toFixed(2)
                                    }
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-8">
                        <div className="text-right">
                            <Link to="/user/profile/edit" className="btn btn-primary px-4" state={{user: user}}><i
                                className="icon-pen mr-1"></i>Edit Profile</Link>
                        </div>
                        <div className="card my-3 p-3 shadow-1">
                            <h4 className="card-title" style={{borderBottom: '3px solid #007BFF'}}>My Profile</h4>
                            <table className="table table-borderless">
                                <tbody>
                                <tr>
                                    <th>Name</th>
                                    <td>{user.member.name}</td>
                                </tr>
                                <tr>
                                    <th>Email</th>
                                    <td>{user.member.email}</td>
                                </tr>
                                <tr>
                                    <th>Phone</th>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <span>{user.member.phone}</span>
                                            {
                                                user.member.phone_verified === 0 ? (
                                                    <Button onClick={() => navigate('/user/phone-verification')}>
                                                        <RemoveModerator sx={{color: 'error.main'}}/>
                                                    </Button>
                                                ) : (
                                                    <Button onClick={() => openModal(setOpenUpdateInfo)}>
                                                        Update Phone
                                                    </Button>
                                                )
                                            }
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Gender</th>
                                    <td>{user.gender}</td>
                                </tr>
                                <tr>
                                    <th>Address</th>
                                    <td>{user.address}</td>
                                </tr>
                                <tr>
                                    <th>Date Of Birth</th>
                                    <td>{user.dob}</td>
                                </tr>
                                <tr>
                                    <th>Age</th>
                                    <td>
                                        {user.dob ? new Date().getFullYear() - user.dob.slice(0, 4) : ''}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                open={openUpdateInfo}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    width='100%'
                    maxWidth={'400px'}
                    bgcolor='#fff'
                    border='none'
                    position='absolute'
                    top='50%'
                    left='50%'
                    boxShadow={4}
                    sx={{
                        transform: 'translate(-50%, -50%)'
                    }}

                >
                    <Box display='flex' alignItems='center' justifyContent='space-between' p={2}>
                        <h5><b>Update number</b></h5>
                        <IconButton onClick={() => {
                            closeModal(setOpenUpdateInfo);
                            closeModal(setUpdatePhoneModal)
                        }}>
                            <Close/>
                        </IconButton>
                    </Box>
                    {
                        !updatePhoneModal ? (
                            <>
                                <Box px={2}>
                                    <Box mt={1} mb={2}>
                                        <b>{phoneUpdateNum}</b>
                                    </Box>
                                    <Box>
                                        <Typography variant='subtitle1' textAlign={'left'}>
                                            Updating will change your phone number for some services. Are you sure you
                                            want to update your number.
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box p={2} display='flex' justifyContent='end' gap={1}>
                                    <Button color='error' onClick={() => closeModal(setOpenUpdateInfo)}>Cancel</Button>
                                    <Button onClick={() => {
                                        openModal(setUpdatePhoneModal)
                                    }}>Next</Button>
                                </Box>
                            </>
                        ) : (
                            <>
                                <Box px={2}>
                                    <Box mt={1} mb={2}>
                                        <Typography variant='subtitle1'>Enter the number you'd like to use</Typography>
                                    </Box>
                                    <PhoneInput
                                        country={'np'}
                                        onlyCountries={['np']}
                                        disableDropdown
                                        countryCodeEditable={false}
                                        inputProps={{
                                            name: 'phone',
                                            required: true,
                                        }}
                                        value={phoneUpdateNum}
                                        onChange={phone => setPhoneUpdateNum(phone)}
                                        inputStyle={{paddingLeft: '48px'}}
                                        required
                                    />
                                </Box>
                                <Box p={2} display='flex' justifyContent='end' gap={1}>
                                    <Button color='error' onClick={() => closeModal(setOpenUpdateInfo)}>Cancel</Button>
                                    <Button onClick={updateNumber}>Update Number</Button>
                                </Box>
                            </>
                        )
                    }

                </Box>
            </Modal>
        </div>
    )
}

export default Profile
