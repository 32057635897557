import React from 'react'
import {Link, useLocation} from 'react-router-dom'
import Headache from "../../../images/symptoms/headache.png"
import Fever from "../../../images/symptoms/fever.png"
import Weakness from "../../../images/symptoms/weakness.png"
import DifficultyBreathing from "../../../images/symptoms/difficulty-breathing.png"
import Dizziness from "../../../images/symptoms/dizzy.png"
import SoreThroat from "../../../images/symptoms/sore-throat.png"
import Appetite from "../../../images/symptoms/lack-of-appetite.png"
import Asthma from "../../../images/symptoms/asthma.png"
import Fatigue from "../../../images/symptoms/fatigue.png"
import Chills from "../../../images/symptoms/chills.png"
import "./Symptom.scss"
import Advertisement from '../../Advertisement'
import BecomeMember from '../../BecomeMember'
import HealthCenter from '../../HealthCenter'
import Badge from '@mui/material/Badge';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck} from '@fortawesome/free-solid-svg-icons'
import axios from "../../api"
import swal from 'sweetalert'

function Symptom() {
    const {state} = useLocation()
    const [symptomData, setSymptomData] = React.useState([])
    const [proceedDep, setProceedDep] = React.useState(false)
    const [symptoms, setSymptoms] = React.useState([])
    const [departments, setDepartments] = React.useState([])
    const [symSearch, setSymSearch] = React.useState([])

    React.useEffect(() => {
        if (state != null) {
            setSymptomData([state.initial])
        }
    }, [])

    React.useEffect(() => {
        axios.get("/api/admin/symptom")
            .then((res) => {
                setSymptoms(res.data)
                setSymSearch(res.data)
            })
            .catch((err) => {
                console.log()
            })
    }, [])

    const setProceed = (e) => {
        e.preventDefault()
        if (symptomData.length === 0) {
            swal("OOPS!", "Please Select One or More Symptoms before proceeding", "warning")
            return;
        }
        setProceedDep(true)
        console.log()
        getDepartments()
    }

    const setPostData = (e) => {
        let id = (e.target.getAttribute('data-value'))
        console.log()
        if (checkData(id)) {
            let filtered = symptomData.filter((sym) => {
                return sym != id
            })
            setSymptomData(filtered)
            return
        }

        if (symptomData.length >= 5) {
            swal("OOPS!", "You can only select 5 symptoms at most", "info")
            return;
        }

        setSymptomData([...symptomData, id])

    }

    const symptomArray = (str) => {
        // let str = str.replace(/"/ig, '')
        let arr = str.replace("[", '').replace("]", '').replaceAll("\\", '')
        arr = arr.split(",")

        // let arr = JSON.parse(str)
        console.log()
    }

    const getSymptomById = (id) => {
        let symptom = symptoms.filter(sym => {
            return sym.id == id
        })
        // console.log()
        return symptom[0]
    }


    const checkData = (id) => {
        if (symptomData.length == 0) return false

        for (let i = 0; i < symptomData.length; i++) {
            if (symptomData[i] == id) {
                return true
            }
        }
        return false
    }

    const getDepartments = () => {
        const formData = new FormData()
        console.log()
        formData.append('symptoms[]', JSON.stringify(symptomData));
        axios.post("/api/admin/department/departmentBySymptoms", formData)
            .then(res => {
                console.log()
                setDepartments(res.data)
            })
            .catch(err => {
                console.log()
            })
    }

    function searchSymptom(e) {
        const searchString = e.target.value.toLowerCase();
        const filtered = []
        for (var i = 0; i < symptoms.length; i++) {
            let searchStrArr = searchString
            let strFiltered = []
            for (var j = 0; j < symptoms[i].name.length; j++) {
                if (searchStrArr.length == 0) {
                    break;
                }
                const charStr = symptoms[i].name[j].toLowerCase();
                if (charStr == searchStrArr[0]) {
                    strFiltered.push(searchStrArr[0]);
                    searchStrArr = searchStrArr.slice(1);
                } else {
                    if (strFiltered.length > 0) {
                        strFiltered = []
                        searchStrArr = searchString
                    }
                }
            }
            if (strFiltered.length == searchString.length) {
                filtered.push(symptoms[i])
            }
        }
        setSymSearch(filtered)
    }


    return (
        <div className="symptom-page">
            <div className="inner-package text-center py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-7">
                            <div className="banner-detail">
                                <div className="banner-title">
                                    <h2 className='p-0 m-0'>Be Aware Of Your Health And Well Being. </h2>
                                    <h4 className="py-3 m-0">Consult Doctors of all Specialities</h4>
                                </div>

                                <div className="banner-btn">
                                    <Link to="" className="btn btn-primary">
                                        Consult Now
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="symptoms py-4">
                {!proceedDep ?
                    <div className="container sym-select">
                        <div className="row">
                            <div className="col-md-8 heading">
                                <p>Symptoms</p>
                                <p><b>Select 5 major symptoms</b></p>
                                <p>Identify and treat your symptoms Instantly</p>
                            </div>

                            <div className="col-md-4 search-symptoms d-flex align-items-center">
                                <div class="form-outline me-2">
                                    <input type="search" id="form1" class="form-control"
                                           placeholder="Search For Symptoms" aria-label="Search"
                                           onChange={searchSymptom}/>
                                </div>
                                <Link to="" className="btn btn-info" onClick={setProceed}>
                                    Proceed
                                </Link>
                            </div>

                            <div className="symptom-items row my-5">
                                {
                                    symSearch.map((symptom) => {
                                        return (
                                            <div className="symptom-container col-4 col-sm-3 col-md-2">

                                                <div className="symptom-icon">

                                                    <Badge badgeContent={checkData(symptom.id) ?
                                                        <FontAwesomeIcon icon={faCheck}/> : 0} color="primary"
                                                           className="d-flex align-items-center justify-content-center badge-container w-100">
                                                        <img src={symptom.image_path} alt="" data-value={symptom.id}
                                                             onClick={setPostData}/>
                                                    </Badge>
                                                </div>

                                                <div className="text-center mt-2">
                                                    <p className="mb-0 icon-text">{symptom.name}</p> <br/>
                                                    <p className="mb-0">({symptom.name_nepali})</p>
                                                </div>

                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div>
                    </div>

                    :
                    <div className="container sugg-depart pb-5">
                        <div className="row">
                            <div className="col-md-8 heading">
                                <p>Medical Departments Suggestion</p>
                                <p>
                                    <small className="text-danger">
                                        * This is machine generated response. Please refer actual doctor for precise
                                        advice.
                                    </small>
                                </p>
                            </div>
                        </div>
                        {
                            departments.map((department) => {
                                return (
                                    <div className="row dep-item mb-4">
                                        <div className="dep-item-card card">
                                            <div
                                                className="card-header d-flex justify-content-between align-items-center">
                                                <div className="dep-item-title">
                                                    {department.department}
                                                </div>
                                                <div className="dep-continue">
                                                    <Link to={"/booking/doctor?department=" + department.id}
                                                          className='btn btn-info'>
                                                        Continue
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="card-body dep-symptoms">
                                                <div className="row g-4">
                                                    {
                                                        // symptomArray(department.symptoms)
                                                        department.symptoms.map((sym) => {
                                                            return <div className="col-2">
                                                                <div
                                                                    className={"dep-symptoms-item " + (checkData(sym) && "selected")}>
                                                                    {getSymptomById(sym).name}
                                                                </div>
                                                            </div>
                                                        })
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {/* <div className="row dep-item">
                            <div className="dep-item-card card">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    <div className="dep-item-title">
                                        CARDIOLOGY
                                    </div>
                                    <div className="dep-continue">
                                        <Link to="" className='btn btn-info'>
                                            Continue
                                        </Link>
                                    </div>
                                </div>
                                <div className="card-body dep-symptoms">
                                    <div className="row g-4">
                                        <div className="col-2">
                                            <div className="dep-symptoms-item selected">
                                                Chest Pain
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="dep-symptoms-item">
                                                Chest Pain
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="dep-symptoms-item">
                                                Chest Pain
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="dep-symptoms-item">
                                                Chest Pain
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="dep-symptoms-item">
                                                Chest Pain
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="dep-symptoms-item">
                                                Chest Pain
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="dep-symptoms-item">
                                                Chest Pain
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="dep-symptoms-item">
                                                Chest Pain
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                }
            </div>
            <Advertisement/>
            <HealthCenter/>
            {/* <BecomeMember /> */}
        </div>
    )
}

Symptom.defaultProps = {
    initial: []
}

export default Symptom
