import React from 'react'
import PackageItem from './pages/packages/PackageItem'
import './CircularSlider.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft, faArrowRight} from '@fortawesome/free-solid-svg-icons';

function CircularSlider({children, className, settings}) {


    function previousSlide() {

    }

    function nextSlide() {

    }

    return (
        <div className="container">
            <div className={`circular-slider ${className}`}>
                <div className="slide-prev" onClick={previousSlide}>
                    <FontAwesomeIcon icon={faArrowLeft}/>
                </div>
                <div
                    className="circular-slider-item circular-slider-item-1 d-flex justify-content-center align-items-center">
                    <h1 className='text-white'>1</h1>
                </div>
                <div
                    className="circular-slider-item circular-slider-item-2 d-flex justify-content-center align-items-center">
                    <h1 className='text-white'>2</h1>
                </div>
                <div
                    className="circular-slider-item circular-slider-item-3 d-flex justify-content-center align-items-center">
                    <h1 className='text-white'>3</h1>
                </div>
                <div
                    className="circular-slider-item circular-slider-item-4 d-flex justify-content-center align-items-center">
                    <h1 className='text-white'>4</h1>
                </div>
                <div
                    className="circular-slider-item circular-slider-item-5 d-flex justify-content-center align-items-center">
                    <h1 className='text-white'>5</h1>
                </div>
                <div
                    className="circular-slider-item circular-slider-item-6 d-flex justify-content-center align-items-center">
                    <h1 className='text-white'>6</h1>
                </div>
                <div className="slide-next" onClick={nextSlide}>
                    <FontAwesomeIcon icon={faArrowRight}/>
                </div>
            </div>
        </div>
    )
}

export default CircularSlider
