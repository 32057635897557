import {Box, Grid} from '@mui/material'
import React, {useContext} from 'react'
import {GDVendorContext} from '../GDVendorContext'

function ThreeAdvert() {
    const {vendor} = useContext(GDVendorContext)
    const sxProp = {
        overFlow: 'hidden',
    }
    const imgStyle = {
        width: '100%', height: '100%', objectFit: 'cover'
    }
    if (vendor.is_exculsive !== 2) {
        return;
    }
    return (
        <Grid container sx={{widht: '100%', aspectRatio: '1/0.3'}} gap={1} flexWrap={'nowrap'}>
            <Grid item xs={7}>
                <GridItem height={'100%'}>
                    <img src="https://opatoday.com/wp-content/uploads/2022/09/Psychological-Health-and-Safety-3.png"
                         alt="" style={{width: '100%', height: '100%', objectFit: 'cover'}}/>
                </GridItem>
            </Grid>
            <Grid item xs={5}>
                <Grid container gap={1} sx={{height: '100%'}}>
                    <GridItem xs={12}>
                        <GridItem height={'100%'}>
                            <img
                                src="https://www.comalytics.com/wp-content/uploads/2018/01/online-advertising-for-e-commerce-featured-image.jpg"
                                alt="" {...imgStyle} />
                        </GridItem>
                    </GridItem>

                    <GridItem xs={12}>
                        <GridItem height={'100%'}>
                            <img
                                src="https://storage.googleapis.com/website-production/uploads/2019/06/ecommerce-promotions-online.png"
                                alt="" {...imgStyle} />
                        </GridItem>
                    </GridItem>
                </Grid>
            </Grid>
        </Grid>
    )
}

function GridItem({height, children}) {
    return (
        <Box sx={{width: '100%', height: height, position: 'relative'}}>
            <Box sx={{position: 'absolute', width: '100%', height: '100%', top: 0, left: 0}}>
                {children}
            </Box>
        </Box>
    )
}

export default ThreeAdvert

