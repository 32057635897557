import { alpha, styled } from "@mui/material/styles";
import { TextField } from "@mui/material";

const InputField = styled(TextField)(({ theme, color }) => ({
    "input:read-only": {
        backgroundColor: "#fafafa",
        "&:focus": {
            boxShadow: "none",
            borderColor: "#CCCCCC",
        },
    },
    "& .MuiInputBase-multiline": {
        padding: "0px",
    },
    "& .MuiInputBase-input": {
        borderRadius: "4px",
        position: "relative",
        backgroundColor: "white",
        fontSize: "0.875rem",
        width: "100%",
        padding: "12px 12px",
        transition: theme.transitions.create([
            "border-color",
            "background-color",
            "box-shadow",
        ]),
        "&:focus": {
            boxShadow:
                color === "secondary"
                    ? `${alpha(theme.palette.secondary.main, 0.2)} 0 0 0 0.2rem`
                    : `${alpha(theme.palette.primary.main, 0.2)} 0 0 0 0.2rem`,
            borderColor:
                color === "secondary"
                    ? theme.palette.secondary.main
                    : theme.palette.primary.main,
        },
    },
    "& .MuiOutlinedInput-notchedOutline": {
        display: "none",
    },
    ".css-i0mwh2-MuiFormHelperText-root": {
        margin: "0 0 0",
    },
}));

export default InputField;
