import React, {useEffect} from 'react'

import {App} from "./magnify"
import "./ImageMagnify.css"

function ImageMagnify({srcImage}) {
    useEffect(() => {
        App.init()
    }, [])

    return (
        <div className="react-image-magnify">
            <div className="image-magnifier">
                <div className="zoom-overlay">

                </div>
                <div className="image-container">
                    <div className="image-div">
                        {srcImage}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ImageMagnify
