import {Grid} from '@mui/material'
import React from 'react'
import FitnessCategoryCard from './FitnessCategoryCard'
import FitnessContainer from './FitnessContainer'
import axios from '../../../api'
import {useNavigate} from 'react-router-dom'
import {useQuery} from '@tanstack/react-query'

function FitnessTopCategories() {
    const {data, isLoading} = useQuery(
        ['fitnessCategory'],
        async () => (
            axios.get('/api/categories/vendortype?vendor_type_id=3').then(res => res.data).catch(err => err)
        )
    )
    const navigate = useNavigate()
    if (isLoading || data.length === 0) {
        return
    }
    console.log()
    return (
        <FitnessContainer title={'Top Categories'}>
            <Grid container>
                {data.map((cat) => (
                    <Grid item xs={6} md={4} lg={2}>
                        <FitnessCategoryCard img={cat.image_path} name={cat.name}
                                             onClick={() => navigate(`/gd-store/filter/${cat.vendor_type_id}/${cat.id}`)}/>
                    </Grid>
                ))}
            </Grid>
        </FitnessContainer>
    )
}

export default FitnessTopCategories
