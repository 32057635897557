import {useTheme} from '@emotion/react'
import {Box, useMediaQuery} from '@mui/material'
import React from 'react'
import Slider from 'react-slick'


function CatalogSlider({children, numItems = 5, settingProps}) {
    const theme = useTheme()
    const settings = {
        slidesToShow: numItems,
        slidesToScroll: 1,
        infinite: false,
        ...settingProps
    }
    console.log()
    return (
        <Box sx={{'& .custom-slick .slick-slider': {width: '100%'}}}>
            <div className="custom-slick">
                <Slider {...settings}>
                    {children}
                </Slider>
            </div>
        </Box>
    )
}

export default CatalogSlider
