import {Box, Card, CardHeader, Grid, Typography} from '@mui/material'
import React, {useContext} from 'react'
import LabProfileItem from './LabProfileItem'
import {LabTestContext} from './LabTestProvider'

function LabProfile({data}) {
    const {department} = useContext(LabTestContext)
    if (data.length === 0) {
        return
    }
    return (
        <Box>
            <Card variant='outlined'>
                <CardHeader
                    title={`${department.department} Lab Profiles`}
                    subheader='10 lab profiles found in Biochemistry'
                    titleTypographyProps={{
                        fontWeight: '550'
                    }}
                />
            </Card>

            <Grid container spacing={1} my={1}>
                {
                    data.map((item, idx) => (
                        <Grid item xs={4}>
                            <LabProfileItem data={item} key={idx}/>
                        </Grid>
                    ))
                }
                {/*
                <Grid item xs={4}>
                    <LabProfileItem />
                </Grid>
                <Grid item xs={4}>
                    <LabProfileItem />
                </Grid>
                <Grid item xs={4}>
                    <LabProfileItem />
                </Grid>
                <Grid item xs={4}>
                    <LabProfileItem />
                </Grid>
                <Grid item xs={4}>
                    <LabProfileItem />
                </Grid> */}
            </Grid>
        </Box>
    )
}

export default LabProfile
