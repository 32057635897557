import React from 'react'
import AdvertisementImg from '../images/mob.png'
import GooglePlay from '../images/google.png'
import AppStore from '../images/apple.png'
import Mobiles from '../images/mobile.png'

import '../components/Advertisement.css'
import {Box, Typography} from '@mui/material'

const Advertisement = () => {
    return (
        <div className='container advertisement my-3 py-4'>
            <div className="row ">
                <div className="col-lg-6 col-md-12 ">
                    <div className="img-container">
                        <a href='/'>
                            <img src={Mobiles} className="Ad-container" alt=""/>
                        </a>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 app-text">
                    <h2>Download the<br/> Ghargharma Doctor <br/>App</h2>
                    <p className='mt-3'>Access preventive health care services easily from the Ghargharma Doctor App.
                        Connect with top doctors from the comfort of your home. </p>
                    {/* <Box display='flex' gap={1}>
            <Typography variant='h3' color='#0D59A8' fontWeight={700}>COMING</Typography>
            <Typography variant='h3' color='#52C8F4' fontWeight={700}>SOON</Typography>
          </Box> */}
                    <div className="d-flex align-items-center mt-5">
                        <div className="google ">
                            <img src={GooglePlay} className='google-pay me-2' alt=""/>
                        </div>
                        <div className="apple">
                            <img src={AppStore} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        // {/* <div className='container advertisement my-3'>
        //     <div className="row advertisement-row">
        //     <div className="col-6  img-container">
        //     <img src={AdvertisementImg} className="Ad-container" alt=""/>

        //     </div>
        //     <div className="col-6 app-text">
        //        <h2>Download the<br/> GharGharma Doctor <br/>App</h2>
        //        <p >Access video consultation with Nepal's top doctors on the Ghargharma App.Connect with doctors online, available from the comfort of your home. </p>
        //        <div className="download">
        //        <div className="row">
        //        <div className="col-6">
        //        <img src={GooglePlay}  className='google-pay 'alt="" />
        //        </div>
        //        <div className="col-6">

        //        </div>

        //        </div>
        //        </div>
        //     </div>
        //     </div>
        // </div> */}
    )
}

export default Advertisement
