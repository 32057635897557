import {Grid} from '@mui/material'
import {useQuery} from '@tanstack/react-query'
import React, {useContext, useEffect, useState} from 'react'
import ProductCards from '../../../../stores/ProductCards'
import {ProductFilterContext} from '../ProductFilterContext'
import axios from '../../../../api'


function ProductFilterResults() {
    const {filters} = useContext(ProductFilterContext)
    const [queryString, setQueryString] = useState(new URLSearchParams(filters).toString())
    const [data, setData] = useState([])
    const [isLoading, setLoading] = useState(true)
    useEffect(() => {
        setLoading(true)
        axios.get("/api/products", {params: filters}).then(res => {
            setData(res.data)
            setLoading(false)
        })
    }, [filters])
    if (isLoading) {
        return
    }
    if (data.data.length === 0) {
        return
    }
    return (
        <Grid container sx={{width: '100%'}} spacing={2}>
            {
                data.data.map((item, idx) => (
                    <Grid item key={idx} sm={6} lg={3}>
                        <ProductCards data={item}/>
                    </Grid>
                ))
            }
        </Grid>
    )
}

export default ProductFilterResults
