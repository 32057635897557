import React, {useContext, useEffect, useState} from "react";
import "./Navbar.css";
import Logo from "../images/blue-logo.png";
// import Border from "../images/Rectangle 8.png"
import {Link} from "react-router-dom";
import axios from './api.js';
import {useNavigate} from "react-router-dom";
import swal from "sweetalert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowRightFromBracket,
    faBagShopping,
    faHandshake,
    faHouse,
    faRightToBracket,
    faUser
} from "@fortawesome/free-solid-svg-icons";
import {LoginStateContext} from '../contexts/LoginStateContext'
import {useRef} from "react";
import {useWebNav} from "./layouts/WebLayoutProvider";
import {Box, Button, CircularProgress} from "@mui/material";
import {useChkLogin} from "../middlewares/LoginMiddleware";
import {motion, useMotionValueEvent, useScroll} from "framer-motion";
import {CallEnd, CallOutlined, DirectionsOutlined, MailOutline, PhoneOutlined} from "@mui/icons-material";
import FormModalContext from "./custom_componets/globalFormModal/FormModalContext";
import InsuranceClaim from "./pages/insuranceClaim/InsuranceClaim";

const Navbar = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL
    const {openFormModal} = useContext(FormModalContext)
    const [name, setName] = useState();
    const [service, setService] = useState();
    const [teams, setTeams] = useState();
    const [mainService, setMainService] = React.useState([])
    let navigate = useNavigate();
    const loginContext = React.useContext(LoginStateContext)
    const [toggle, setToggle] = useState(false);
    const {navRef} = useWebNav()
    const {scrollYProgress, scrollY} = useScroll();
    const [scrolled, setScrolled] = useState(false)


    const pathname = window.location.pathname;
    const [about, setAbout] = useState({})

    useMotionValueEvent(scrollYProgress, "change", (latest) => {
        latest > 0 ? setScrolled(true) : setScrolled(false)
    })

    useEffect(() => {
        axios.get("api/about")
            .then(res => {
                setAbout(res.data[0])
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        axios.get('/api/health-service')
            .then(res => setService(res.data))
            .catch((error) => {
                console.log(error)
            })
    }, [])

    useEffect(() => {
        axios.get('/api/team-category')
            .then(res => setTeams(res.data))
            .catch((error) => {
                console.log(error)
            })
    }, [])

    useEffect(() => {
        axios.get("api/service")
            .then((res) => {
                setMainService(res.data)
            })
    }, [])

    const logout = () => {
        localStorage.clear();
        swal({
            title: "Logged out successfully!",
            icon: "success",
        }).then((value) => {
            if (value) {
                loginContext.logout()
            }
        });
    }

    return (
        <>
            <Box py={1} bgcolor='#0259A7' color='#fff'>
                <div className="container-lg">
                    <Box display='flex' gap={{xs: 1, lg: 5}} alignItems='center'>
                        <Box display='flex' gap={2} alignItems='center'>
                            <CallOutlined sx={{fontSize: '1.1rem'}}/>
                            <span>01-5917322</span>
                        </Box>
                        <Box display={{xs: 'none', sm: 'flex'}} gap={1} alignItems='center'>
                            <MailOutline sx={{fontSize: '1.1rem'}}/>
                            <span>info@ghargharmadoctor.com</span>
                        </Box>
                        <Box display={{xs: 'none', lg: 'flex'}} gap={1} alignItems='center'>
                            <span>Opening time:</span>
                            <span>9AM to 6PM</span>
                        </Box>
                        <Box ml='auto'>
                            <Box component='button' px={3} border={0} borderRadius={2} display='flex'
                                 alignItems='center' gap={1} color='#0259A7' fontWeight={700} onClick={() => {
                                window.open('https://maps.google.com/maps?ll=27.696597,85.353972&z=16&t=m&hl=en&gl=NP&mapclient=embed&cid=851041201172928665', '_blank')
                            }}>
                                <DirectionsOutlined sx={{fontSize: '1.1rem'}}/>
                                <span>Get directions</span>
                            </Box>
                        </Box>
                    </Box>
                </div>
            </Box>
            <div className="sticky-top">
                <div className="navigation bg-white" ref={navRef}>
                    <nav className="navbar navbar-expand-lg container py-0 px-sm-3">
                        <a className="navbar-brand py-0" href="/">
                            <motion.img
                                src={Logo}
                                className="logo my-1"
                                alt=""
                                animate={{
                                    height: scrolled ? '3.4rem' : '4rem'
                                }}
                                transition={{duration: 1}}
                            />
                            {/* <img src={Logo} className="logo my-1" alt="" /> */}
                        </a>
                        <button className="navbar-toggler" type="button" onClick={() => setToggle(!toggle)}>
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        {toggle === true &&
                            <div className=" navbar-collapse px-4">

                                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                    <li className={"nav-item me-3 " + (pathname === '/' ? "active" : "")}>
                                        <Link to="/" className="nav-link"
                                              onClick={() => setToggle(!toggle)}> Home </Link>
                                    </li>
                                    <li className={"nav-item me-3 " + (pathname === '/packages' ? "active" : "")}>
                                        <Link to="/packages" className="nav-link"
                                              onClick={() => setToggle(!toggle)}>Packages</Link>
                                    </li>
                                    <li className={"nav-item me-3 dropdown " + (pathname === '/booking' ? "active" : "")}>
                                        <a
                                            className="nav-link dropdown-toggle text-dark"
                                            href="/"
                                            id="navbarDropdown"
                                            role="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            Appointments
                                        </a>
                                        <ul
                                            className="dropdown-menu"
                                            aria-labelledby="navbarDropdown"
                                        >
                                            <li>
                                                <Link className="dropdown-item" to='/booking/doctor'
                                                      onClick={() => setToggle(!toggle)}>
                                                    Consultant Doctors
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to='/booking/nurse'
                                                      onClick={() => setToggle(!toggle)}>
                                                    Nurses
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className={"nav-item me-3 " + (pathname === '/gd-store' ? "active" : "")}>
                                        <Link to="/gd-store" className="nav-link" onClick={() => setToggle(!toggle)}>GD
                                            Store</Link>
                                    </li>
                                    <li className={"nav-item me-3 dropdown " + (pathname === '/service' ? "active" : "")}>
                                        <a
                                            className="nav-link dropdown-toggle "
                                            href="/"
                                            id="navbarDropdown"
                                            role="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            Services
                                        </a>
                                        <ul
                                            className="dropdown-menu"
                                            aria-labelledby="navbarDropdown"
                                        >
                                            <li>
                                                <Link className="dropdown-item" to={'/lab-tests'}
                                                      onClick={() => setToggle(!toggle)}>
                                                    Lab Services
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to={'/school-report'}
                                                      onClick={() => setToggle(!toggle)}>
                                                    Student Report
                                                </Link>
                                            </li>
                                            {
                                                mainService.map((serv, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <Link className="dropdown-item" to={`/service/${serv.slug}`}
                                                                  onClick={() => setToggle(!toggle)}>
                                                                {serv.service_title}
                                                            </Link>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </li>
                                    <li className={"nav-item me-3 " + (pathname === '/blog' ? 'active' : '')}>
                                        <Link to="/blog" className="nav-link"
                                              onClick={() => setToggle(!toggle)}>Blog</Link>
                                    </li>

                                    <li className={"nav-item me-3 dropdown " + (pathname === '/service' ? "active" : "")}>
                                        <a
                                            className="nav-link dropdown-toggle "
                                            href="/"
                                            id="navbarDropdown"
                                            role="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            About Us
                                        </a>
                                        <ul
                                            className="dropdown-menu"
                                            aria-labelledby="navbarDropdown"
                                        >
                                            <li>
                                                <Link className="dropdown-item" to={'/about'}
                                                      onClick={() => setToggle(!toggle)}>
                                                    About GD
                                                </Link>
                                            </li>
                                            {teams && teams.map((team, index) =>
                                                <li key={index}>
                                                    <Link className="dropdown-item" to={`/team/${team.slug}`}
                                                          onClick={() => setToggle(!toggle)}>
                                                        {team.category_name}
                                                    </Link>
                                                </li>
                                            )}
                                            <li>
                                                <Link className="dropdown-item" to={'/career'}
                                                      onClick={() => setToggle(!toggle)}>
                                                    Career Opportunity
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>

                                    {
                                        (!loginContext.state.loading) &&
                                        loginContext.state.loggedIn ?

                                            loginContext.state.data?.member?.roles.length >= 2 ?
                                                <li className={"nav-item me-3 "}>
                                                    <a target='_blank' href={`${process.env.REACT_APP_BASE_URL}/login`}
                                                       className="nav-link btn btn-outline-primary p-0 px-4 py-2"> Partner
                                                        Dashboard</a>
                                                </li>
                                                :
                                                <li className={"nav-item me-3 " + (pathname === '/become-partner' ? "active" : "")}>
                                                    <Link to="/become-partner"
                                                          className="nav-link btn btn-outline-primary p-0 px-4 py-2"
                                                          onClick={() => setToggle(!toggle)}>Become A Partner</Link>
                                                </li>
                                            :
                                            <li className={"nav-item me-3 " + (pathname === '/become-partner' ? "active" : "")}>
                                                <Link to="/become-partner"
                                                      className="nav-link btn btn-outline-primary p-0 px-4 py-2"
                                                      onClick={() => setToggle(!toggle)}>Become A Partner</Link>
                                            </li>
                                    }
                                    <li className="nav-item me-3 login-border">
                                    </li>
                                    <AuthNavItem/>
                                </ul>
                            </div>
                        }
                        {toggle === false &&
                            <div className="collapse navbar-collapse " id="navbarSupportedContent">
                                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                    <li className={"nav-item me-1 " + (pathname === '/' ? "active" : "")}>
                                        <Link to="/" className="nav-link"> Home </Link>
                                    </li>
                                    <li className={"nav-item me-1 " + (pathname === '/packages' ? "active" : "")}>
                                        <Link to="/packages" className="nav-link">Packages</Link>
                                    </li>
                                    <li className={"nav-item me-1 dropdown " + (pathname === '/booking' ? "active" : "")}>
                                        <a
                                            className="nav-link dropdown-toggle text-dark"
                                            href="/"
                                            id="navbarDropdown"
                                            role="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            Appointments
                                        </a>
                                        <ul
                                            className="dropdown-menu"
                                            aria-labelledby="navbarDropdown"
                                        >
                                            <li>
                                                <Link className="dropdown-item" to='/booking/doctor'>
                                                    Consultant Doctors
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to='/booking/nurse'>
                                                    Nurses
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className={"nav-item me-1 " + (pathname === '/gd-store' ? "active" : "")}>
                                        <Link to="/gd-store" className="nav-link">GD Store</Link>
                                    </li>
                                    <li className={"nav-item me-1 dropdown " + (pathname === '/service' ? "active" : "")}>
                                        <a
                                            className="nav-link dropdown-toggle "
                                            href="/"
                                            id="navbarDropdown"
                                            role="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            Services
                                        </a>
                                        <ul
                                            className="dropdown-menu"
                                            aria-labelledby="navbarDropdown"
                                        >
                                            <li>
                                                <Link className="dropdown-item" to={'/lab-tests'}>
                                                    Lab Services
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to={'/school-report'}>
                                                    Student Report
                                                </Link>
                                            </li>
                                            <li>
                                                <div role='button' className="dropdown-item" to={'/insurance-claim'}
                                                     onClick={() => {
                                                         openFormModal(<InsuranceClaim/>)
                                                     }}>
                                                    Claim Insurance
                                                </div>
                                            </li>
                                            {
                                                mainService.map((serv, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <Link className="dropdown-item"
                                                                  to={`/service/${serv.slug}`}>
                                                                {serv.service_title}
                                                            </Link>
                                                        </li>
                                                    )
                                                })
                                            }
                                            {/* <li className="lab-services">
                  <span className="dropdown-item">
                    Lab Services
                  </span>
                  <ul className="custom-dropdown">
                    {service && service.map((serv) =>
                      <li>
                        <Link className="dropdown-item" to={`/health-service/${serv.slug}`} state={{ service: serv }}>
                          {serv.service_name}
                        </Link>
                      </li>
                    )}

                  </ul>
                </li> */}

                                            {/* {service && service.map((serv) =>
                  <li>
                    <Link className="dropdown-item" to={`/health-service/${serv.slug}`} state={{ service: serv }}>
                      {serv.service_name}
                    </Link>
                  </li>
                )} */}
                                        </ul>
                                    </li>
                                    <li className={"nav-item me-1 " + (pathname === '/blog' ? 'active' : '')}>
                                        <Link to="/blog" className="nav-link">Blog</Link>
                                    </li>
                                    <li className={"nav-item me-1 dropdown " + (pathname === '/service' ? "active" : "")}>
                                        <a
                                            className="nav-link dropdown-toggle "
                                            href="/"
                                            id="navbarDropdown"
                                            role="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            About Us
                                        </a>
                                        <ul
                                            className="dropdown-menu"
                                            aria-labelledby="navbarDropdown"
                                        >
                                            <li>
                                                <Link className="dropdown-item" to={'/about'}>
                                                    About GD
                                                </Link>
                                            </li>
                                            {teams && teams.map((team, index) =>
                                                <li key={index}>
                                                    <Link className="dropdown-item" to={`/team/${team.slug}`}>
                                                        {team.category_name}
                                                    </Link>
                                                </li>
                                            )}
                                            <li>
                                                <Link className="dropdown-item" to={'/career'}>
                                                    Career Opportunity
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>

                                    {
                                        (!loginContext.state.loading) &&
                                        loginContext.state.loggedIn ?
                                            loginContext.state.data.school_profile === null && (
                                                <>
                                                    {
                                                        loginContext.state.data?.member?.roles.length >= 2 ?
                                                            <li className={"nav-item me-3 "}>
                                                                <a target='_blank' href={`${baseUrl}/login`}
                                                                   className="nav-link btn btn-outline-primary p-0 px-4 py-2"> Partner
                                                                    Dashboard</a>
                                                            </li>
                                                            :
                                                            <li className={"nav-item me-3 " + (pathname === '/become-partner' ? "active" : "")}>
                                                                <Link to="/become-partner"
                                                                      className="nav-link btn btn-outline-primary p-0 px-4 py-2">Become
                                                                    A Partner</Link>
                                                            </li>
                                                    }
                                                </>
                                            )
                                            :
                                            <li className={"nav-item me-3 " + (pathname === '/become-partner' ? "active" : "")}>
                                                <Link to="/become-partner"
                                                      className="nav-link btn btn-outline-primary p-0 px-4 py-2">Become
                                                    A Partner</Link>
                                            </li>
                                    }
                                    <li className="nav-item me-3 login-border">
                                    </li>
                                    <AuthNavItem/>
                                </ul>
                            </div>
                        }
                    </nav>
                </div>
                <Box position='relative' bgcolor='trasparent' sx={{
                    width: '100%',
                    minWidth: '100%',
                    '& > div': {
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        height: '5px',
                        background: '#0259A7',
                        transformOrigin: '0%',
                        width: '100%',
                        maxWidth: '99.5%'
                    }
                }}>
                    <motion.div
                        style={{scaleX: scrollYProgress}}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{once: true}}
                    />
                </Box>
            </div>
        </>
    );
};


function AuthNavItem() {
    const {isLoading, data, token, isLoggedIn, logout} = useChkLogin()
    const navigate = useNavigate()

    if (isLoading) {
        return (
            <CircularProgress size={'2rem'}/>
        )
    }
    return (
        <>
            {
                !isLoggedIn ? (
                    <>
                        <li className="nav-item me-3 d-flex nav-login">
                            <Link to="/login" className="align-self-center">
                                <FontAwesomeIcon icon={faUser} style={{color: '#b4b4b4'}} className="me-2"/>
                                Login
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/register" className="btn btn-primary login-btn p-0 px-4 py-2 me-0">
                                <FontAwesomeIcon icon={faRightToBracket} className="me-2"/>
                                Sign Up
                            </Link>
                        </li>
                    </>
                ) : (
                    <li className="nav-item dropdown  me-3">
                        <a
                            className="nav-link dropdown-toggle me-0 px-0"
                            href="/"
                            id="navbarDropdown"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <FontAwesomeIcon icon={faUser} style={{color: '#b4b4b4'}} className="me-2"/>
                            {data.member.name}
                        </a>
                        <ul
                            className="dropdown-menu"
                            aria-labelledby="navbarDropdown"
                        >
                            <li>
                                <Link
                                    to={`${data.school_profile == null || data.school_profile.types === 'corporate' ? '/user' : '/school'}`}
                                    className="dropdown-item">
                                    <FontAwesomeIcon icon={faHouse} className="me-2"/>
                                    My Dashboard
                                </Link>
                            </li>
                            <li>
                                {/* <Link to="/orders" className="dropdown-item">
                        My Orders
                      </Link> */}
                                <button className="dropdown-item" onClick={() => navigate("user/orders")}>
                                    <FontAwesomeIcon icon={faBagShopping} className="me-2"/>
                                    My Orders
                                </button>
                            </li>
                            <li>
                                <button className="dropdown-item" onClick={logout}>
                                    <FontAwesomeIcon icon={faArrowRightFromBracket} className="me-2"/>
                                    Logout
                                </button>
                            </li>
                        </ul>
                    </li>
                )
            }
        </>
    )
}

export default Navbar;
