import "./Orders.scss";
import React from "react";
import axios from "../api.js";
import loaderRolling from "../pages/Image/loader-rolling (1).gif";
import {useNavigate, Link} from 'react-router-dom'
import {Alert, AlertTitle} from "@mui/material";

const Orders = ({status, paymentStatus = null}) => {
    const [orders, setOrders] = React.useState([]);
    const [orderLoading, setOrderLoading] = React.useState(true);
    const token = document.cookie
        .split("; ")
        .find((row) => row.startsWith("token="))
        ?.split("=")[1];
    const navigate = useNavigate()
    // const filter = props.filter;

    React.useEffect(() => {
        let apiLink = "api/admin/order/orderStatus?status=" + status
        if (status == "all") {
            apiLink = "api/admin/order"
        }
        axios
            .get(apiLink, {headers: {Authorization: `Bearer ${token}`}})
            .then((res) => {
                let orderData = res.data
                if (paymentStatus == "unpaid") {
                    orderData = orderData.filter((stat) => stat.payment_status == paymentStatus)
                }
                console.log()
                setOrders(orderData);
                setOrderLoading(false);
            })
            .catch((err) => {
                console.log();
                setOrderLoading(false);
            });
    }, []);

    const renderOrders = () => {
        if (orderLoading) {
            return (
                <div className="text-center">
                    <img src={loaderRolling}/>
                </div>
            );
        }
        if (orders.length === 0) {
            return (
                <Alert severity="error" sx={{mt: 1}}>
                    <AlertTitle>No Orders</AlertTitle>
                    There are no orders
                </Alert>
            )
        } else {
            return orders.map((order) => {

                const orderStatus = {
                    ['unpaid']: (
                        <button className="btn btn-success"
                                onClick={() => navigate("/gd-store/checkout?progress=payment&orderNum=" + order.order_number)}>Pay</button>
                    ),
                    cancelled: (
                        <span className="text-muted"><b>Order Cancelled</b></span>
                    )
                }

                const status = {
                    ['new']: "Payment Pending",
                    ['cancel']: "Cancelled Order",
                }
                const orderD = new Date(order.created_at)
                return (
                    <div className="order-card mb-3" key={order.id}>
                        <div className="card">
                            <div className="card-header d-flex justify-content-between">
                                <div className="order-header-left">
                                    <div className="order-number">
                                        Order Number: #
                                        <Link to={`/user/order-details/${order.order_number}`}
                                              className="text-primary">{order.order_number}</Link>
                                    </div>
                                    <div className="order-date-info">
                                        <small>Order Date: {`${orderD}`}</small>
                                    </div>
                                </div>
                                <div className="order-header-right d-flex align-items-center">
                                    {
                                        order.status == "cancelled" ?
                                            orderStatus.cancelled
                                            :
                                            orderStatus[order.payment_status]
                                    }
                                    {/* {orderStatus[order.status]} */}
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="order-products-list">
                                    {order.products.map((product) => {
                                        return (
                                            <div className="order-product mb-3" key={product.id}>
                                                <div className="row w-100 h-100">
                                                    <div className="col-4 col-md-2 h-100 d-flex align-items-center">
                                                        <div
                                                            className="order-product-img h-100 d-flex justify-content-center">
                                                            <div className="img-div h-100">
                                                                <img
                                                                    src={product.image_path}
                                                                    alt="product image"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-3 align-items-center">
                                                        <div className="row w-100 h-100">
                                                            <div
                                                                className="col-12 col-md-9 d-flex align-items-center order-product-desc">
                                                                <strong>{product.productName}</strong>
                                                            </div>
                                                            <div
                                                                className="col-12 col-md-3 d-flex align-items-center order-product-qty">
                                                                Qty: {product.pivot.quantity}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-5 col-md-6 align-items-center">
                                                        <div className="row w-100 h-100">
                                                            <div
                                                                className="col-12 col-md-6 d-flex align-items-center justify-content-center  order-status">
                                                                {order.status}
                                                            </div>

                                                            <div
                                                                className="col-12 col-md-6 d-flex align-items-center justify-content-center order-deliver-date">
                                                                {
                                                                    (order.status != "cancel" && order.status != "delivered") && (
                                                                        <div className="btn btn-outline-success disabled">
                                                                            Delivered within 3 days
                                                                        </div>
                                                                    )
                                                                }

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                );

            });
        }
    };

    return (
        <div className="order-list">{renderOrders()}</div>
    );
};

export default Orders;
