import React, {useContext, useEffect, useRef} from "react";
import {createContext} from "react";
import PhoneInput from "react-phone-input-2";

export function InputField({
                               name,
                               type = "text",
                               id,
                               value,
                               label,
                               className,
                               onChange,
                               onClick,
                               error,
                               errorMsg,
                               required,
                               disabled,
                               appendContent,
                               inputProps,
                           }) {
    return (
        <div className="form-group">
            <label className="form-label">
                {label}
                <code>{required ? "*" : "(Optional)"}</code>
            </label>
            <div className="input-group">
                <input
                    type={type}
                    id={id}
                    className={`form-control ${className}`}
                    onClick={onClick}
                    name={name}
                    value={value}
                    onChange={onChange}
                    required={required}
                    disabled={disabled}
                />
                {!!appendContent && (
                    <div className="input-group-append"><span className="input-group-text">{appendContent}</span></div>
                )}
            </div>
            {error && <div className="text-danger">{errorMsg}</div>}
        </div>
    );
}

export function NepaliDateInput({
                                    label,
                                    name,
                                    id,
                                    value,
                                    className,
                                    onChange,
                                    error,
                                    errorMsg,
                                    required,
                                    disabled,
                                    max = null,
                                    inputProps,
                                }) {
    const dateInputRef = useRef();
    useEffect(() => {
        console.log();
        dateInputRef.current.nepaliDatePicker();
        console.log();
    }, []);
    return (
        <div className="form-group">
            <label className="form-label">
                {label}
                <code>{required ? "*" : "(Optional)"}</code>
            </label>
            <input
                ref={dateInputRef}
                type="date"
                id={id}
                className={`form-control`}
                name={name}
                value={value}
                onChange={onChange}
                required={required}
                disabled={disabled}
                max={max}
            />
            {error && <div className="text-danger">{errorMsg}</div>}
        </div>
    );
}

export function SelectInput({
                                name,
                                id,
                                value,
                                label,
                                className,
                                onChange,
                                error,
                                errorMsg,
                                required,
                                children,
                            }) {
    return (
        <div className="form-group">
            <label className="form-label">
                {label}
                <code>{required ? "*" : "Optional"}</code>
            </label>
            <select
                name={name}
                className={`form-select ${className}`}
                value={value}
                onChange={onChange}
                required={required}
            >
                <option value="" disabled>
                    --Select--
                </option>
                {children}
            </select>
            {error && <div className="alert alert-danger">{errorMsg}</div>}
        </div>
    );
}

export function SelectOption({name, id, value, className, children}) {
    return (
        <option id={id} value={value}>
            {children}
        </option>
    );
}

const RadioContext = createContext(null);

export function RadioInputContain({
                                      name,
                                      children,
                                      required,
                                      label,
                                      direction = "row",
                                  }) {
    return (
        <RadioContext.Provider value={{name: name, required: required}}>
            <div className="form-group">
                <label className="form-label">
                    <h5>{label}</h5>
                </label>
                <div className={`d-flex flex-${direction}`}>{children}</div>
            </div>
        </RadioContext.Provider>
    );
}

export function RadioInput({id, value, type, label, checked, onChange}) {
    const radioContext = useContext(RadioContext);
    return (
        <div className="form-check">
            <input
                className="form-check-input"
                type={type}
                value={value}
                name={radioContext.name}
                id={id}
                required={radioContext.required}
                checked={checked}
                onChange={(e) => {
                    console.log();
                    onChange(e);
                }}
            />
            <label className="form-check-label me-4" htmlFor={id}>
                <h6>{label}</h6>
            </label>
        </div>
    );
}

export function PhoneNumberInput({
                                     country,
                                     value,
                                     onChange,
                                     required,
                                     error,
                                     errorMsg,
                                 }) {
    return (
        <>
            <PhoneInput
                country={country}
                value={value}
                onChange={onChange}
                required
            />
            {error && <div className="text-danger">{errorMsg}</div>}
        </>
    );
}
