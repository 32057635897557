import {Box, Modal, Typography} from '@mui/material'
import React from 'react'

const ModalRender = ({open, handleClose, children}) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    }
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                {
                    children
                }
            </Box>
        </Modal>
    )
}

export default ModalRender
