import React from 'react'
import StoreHeader from "../../stores/layouts/StoreHeader";
import VendorHeader from "../../stores/vendor/VendorHeader"
import ShopPopularBrands from "../../stores/ShopPopularBrands"
import AdComponent from "../../stores/AdComponent"
import VendorProducts from "../../stores/vendor/VendorProducts"
import {useParams} from 'react-router-dom'
import {VendorPageContext} from "../../../contexts/VendorPageContext"
import {StoreContext} from "../../../contexts/StoreContext"
import axios from "../../api.js"

const VendorPage = ({vendor}) => {
    const params = useParams()
    const storeContext = React.useContext(StoreContext)
    const [vendorLoading, setVendorLoading] = React.useState(true)
    const [vendorCat, setVendorCat] = React.useState([])
    let adHeightCount = 0;

    React.useEffect(() => {
        axios.get("api/categories/vendortype?vendor_type_id=" + vendor.vendor_type)
            .then((res) => {
                setVendorCat(res.data)
            })
    }, [vendor])

    return (
        <>
            <VendorPageContext.Provider value={{vendor, vendorCat}}>
                <div className="container">
                    {/* <h3 className="mt-3">Vendor Name</h3> */}
                    {/* {<VendorHeader />} */}

                    {vendorCat.map((cat) => {
                        adHeightCount++
                        return (
                            <>
                                {(adHeightCount > 0 && adHeightCount % 2 == 0) ? <AdComponent
                                    adGif="https://static-01.daraz.com.np/other/shop/82ec9707c9be2db472e1820927adc9e0.png"/> : <></>}
                                <VendorProducts category={cat} vendor={vendor}/>
                            </>
                        )
                    })}
                    {<ShopPopularBrands/>}
                </div>

            </VendorPageContext.Provider>
        </>
    )
}

export default VendorPage
