const schoolRegistrationInitialState = {
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    owner_name: "",
    company_name: "",
    company_address: "",
    company_start_date: "",
    contact_number: "",
    pan_number: "",
    description: "",
    company_image: "",
    paper_work_pdf: "",
    latitude: 27.71,
    longitude: 85.324,
    is_verified: 0,
    role: 6,
    school_regd_file: "",
    types: "school",
};

export { schoolRegistrationInitialState}
