import {Link} from "react-router-dom";

const ParentSidebar = () => {
    return (
        <div className="sidebar sidebar-dark sidebar-main sidebar-expand-lg">
            <div className="sidebar-content">
                <div className="sidebar-section sidebar-user my-1 ml-3 align-self-center">
                    <button
                        type="button"
                        className="btn btn-outline-light-100 text-white border-transparent btn-icon rounded-pill btn-sm sidebar-mobile-main-toggle d-lg-none"
                    >
                        <i className="icon-cross2"/>
                    </button>
                </div>
                {(
                    <div className="sidebar-section">
                        <ul className="nav nav-sidebar">
                            <li className={'text-white py-3'}>
                                <a href="#"
                                   className={`nav-link`}>
                                    <i className="icon-calendar"></i> <span>Medical Report</span>
                                </a>
                                <ul className="nav nav-group-sub" data-submenu-title="Layouts">
                                    <li className="nav-item">
                                        <Link to="/parent" className="nav-link">
                                            <i className="icon-dash"></i>
                                            <span>External Medical Details</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/parent/vaccination-details"
                                              className={`nav-link `}>
                                            <i className="icon-dash"></i>
                                            <span>Vaccination</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ParentSidebar;
