import {Typography} from '@mui/material';
import React, {useState} from 'react'

function ViewMore({children, data, limit = 5}) {
    const [contentLimit, setContentLimit] = useState(limit)
    console.log()

    function viewMoreContent(e) {
        setContentLimit(prev => prev + limit)
    }

    function viewLess(e) {
        setContentLimit(prev => prev - limit)
    }

    return (
        <>
            {
                data.map((item, idx) => (
                    idx > contentLimit ?
                        <></>
                        :
                        idx === contentLimit ?
                            <Typography color='primary' variant='button' display='block' sx={{mt: 1, cursor: 'pointer'}}
                                        onClick={viewMoreContent}>View More</Typography>
                            :
                            children(item, idx)
                ))
            }
            {
                contentLimit > limit &&
                <Typography color='primary' variant='button' display='block' sx={{mt: 1, cursor: 'pointer'}}
                            onClick={viewLess}>View Less</Typography>
            }
        </>
    )
}

export default ViewMore
