import React, {useContext, useEffect, useState} from 'react'
import Chart from 'chart.js/auto'
import {CartesianGrid, LineChart, ReferenceLine, XAxis, YAxis, Tooltip, Legend} from 'recharts'
import {LoginStateContext} from '../../../../../contexts/LoginStateContext'
import axios from "../../../../api"
import {Line} from 'react-chartjs-2'

function DashboardCharts() {
    const loginContext = useContext(LoginStateContext)
    const [index, setIndex] = useState(0)

    const changeChart = (e) => {
        setIndex(e.target.value)
    }

    return (
        <div className="dashboard-charts h-100">
            <div className="card h-100">
                <div className="card-header">
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <select name="heart-rate" id="heart-rate" className='form-select' onChange={changeChart}>
                                <option value="0">Heart Rate</option>
                                <option value="1">Blood Pressure</option>
                                <option value="2">Steps Count</option>
                            </select>
                            {/* <span className="text-muted">
                                <select name="duration" id="duration" className="form-select mt-1">
                                    <option value="">1-7 December 2022</option>
                                </select>
                            </span> */}
                        </div>
                        {/* <button type="button" className="btn btn-primary-100 text-primary align-self-start">
                            <span className="text-indigo me-2 font-weight-bold">72 bmp</span> Average
                        </button> */}
                    </div>
                </div>

                <div className="card-body mt-3">
                    <div className="chart">
                        {
                            index == 0 &&
                            <HeartRateChart/>
                        }
                        {
                            index == 1 &&
                            <BPChart/>
                        }
                        {
                            index == 2 &&
                            <StepChart/>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardCharts

function HeartRateChart() {
    const {data, isLoading, err} = useHeartRate()

    if (isLoading) {
        return <h4>Loading....</h4>
    }
    // console.log()

    const data2 = {
        datasets: [{
            label: 'Heart Rate Chart',
            data: data,
            parsing: {
                xAxisKey: 'created_at',
                yAxisKey: 'heart_rate'
            },
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
        }]
    }
    return (
        <Line
            data={data2}
            options={{}}
        />
    )
}

function BPChart() {
    const {data, isLoading, error} = useBloodPressure()

    if (isLoading) {
        return <h4>Loading...</h4>
    }

    const data2 = {
        datasets: [{
            label: 'BP Upper',
            data: data,
            parsing: {
                xAxisKey: 'created_at',
                yAxisKey: 'upper'
            },
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
        }, {
            label: 'BP Lower',
            data: data,
            parsing: {
                xAxisKey: 'created_at',
                yAxisKey: 'lower'
            },
            fill: false,
            borderColor: 'rgb(192, 75, 192)',
            tension: 0.1
        }]
    }

    return (
        <Line
            data={data2}
            options={{}}
        />
    )

    return (
        <LineChart
            width={700}
            height={400}
            data={data}
            margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis dataKey="created_at"/>
            <YAxis domain={[60, 200]}/>
            <Tooltip/>
            <Legend/>
            <Line type="monotone" dataKey="upper" stroke="#8884d8" activeDot={{r: 8}}/>
            <Line type="monotone" dataKey="lower" stroke="#82ca9d"/>
        </LineChart>
    )
}

function StepChart() {
    const {isLoading, data, err} = useStepCount()
    if (isLoading) {
        return <h4>Loading...</h4>
    }
    return (
        <LineChart
            width={700}
            height={400}
            data={data}
            margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis dataKey="created_at"/>
            <YAxis domain={[60, 200]}/>
            <Tooltip/>
            {/* <Legend /> */}
            <Line type="monotone" dataKey="steps_count" stroke="#8884d8" activeDot={{r: 8}}/>
        </LineChart>
    )
}

function useStepCount() {
    const [data, setData] = useState([])
    const [err, setErr] = useState([])
    const [isLoading, setLoading] = useState(true)
    const loginContext = useContext(LoginStateContext)

    useEffect(() => {
        setLoading(true)
        axios.get("api/admin/user-profile/updateheartrate?steps_count=steps_count", {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
            .then(res => {
                const arr = res.data
                arr.map((item) => {
                    item.created_at = new Date(item.created_at).toLocaleTimeString(undefined, {
                        hour: '2-digit',
                        minute: '2-digit'
                    })
                })
                setData(arr)
                setLoading(false)
            })
            .catch(err => {
                setErr(err)
                setLoading(false)
            })
    }, [])

    return {data, err, isLoading}
}


function useHeartRate() {
    const [data, setData] = useState([])
    const [err, setErr] = useState([])
    const [isLoading, setLoading] = useState(true)
    const loginContext = useContext(LoginStateContext)

    useEffect(() => {
        setLoading(true)
        axios.get("api/admin/user-profile/updateheartrate?heart_rate=heart_rate", {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
            .then(res => {
                const arr = res.data
                arr.map((item) => {
                    item.created_at = new Date(item.created_at).toLocaleTimeString(undefined, {
                        hour: '2-digit',
                        minute: '2-digit'
                    })
                })
                setData(arr)
                setLoading(false)
            })
            .catch(err => {
                setErr(err)
                setLoading(false)
            })
    }, [])

    return {data, err, isLoading}
}

function useBloodPressure() {
    const [data, setData] = useState([])
    const [err, setErr] = useState([])
    const [isLoading, setLoading] = useState(true)
    const loginContext = useContext(LoginStateContext)

    useEffect(() => {
        setLoading(true)
        axios.get("api/admin/user-profile/updateheartrate?blood_pressure=blood_pressure", {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
            .then(res => {
                let arr = res.data
                arr.map((dat) => {
                    let range = dat.blood_pressure.split("/")
                    dat.upper = range[0]
                    dat.lower = range[1]
                    dat.created_at = new Date(dat.created_at).toLocaleTimeString(undefined, {
                        hour: '2-digit',
                        minute: '2-digit'
                    })
                })
                setData(arr)
                setLoading(false)
            })
            .catch(err => {
                setErr(err)
                setLoading(false)
            })
    }, [])

    return {data, err, isLoading}
}
