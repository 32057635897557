import React from 'react'
import GDBlogHeader from './GDBlogHeader'
import {Avatar, Box, CircularProgress, Grid, Stack, Typography} from '@mui/material'
import ContentContainer from './components/ContentContainer'
import {ShareOutlined} from '@mui/icons-material'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFacebookF, faInstagram, faTwitter} from '@fortawesome/free-brands-svg-icons'
import PopularPosts from './PopularPosts'
import {useBlogDetails, useBlogsHome} from './GDBlogProvider'
import NewsItemContainer from './components/NewsItemContainer'
import ItemDetails from './components/ItemDetails'
import {DateFormat} from '../../DateFormat'
import GDLogo from '../../../images/blue-logo.png'
import {useQuery} from '@tanstack/react-query'
import {useParams} from 'react-router-dom'
import axios from '../../api'
import BlogDetailSkeleton from './components/BlogDetailSkeleton'

function GDBlogDetails() {
    const relatedPosts = useBlogsHome()
    const params = useParams()
    // const {blogs} = useBlogDetails()
    const {data, isLoading} = useQuery(
        ['blog-detail', params.blog_slug],
        async () => (
            axios.get(`/api/show/${params.blog_slug}`).then(res => res.data).catch(err => err)
        )
    )
    const blogs = data
    return (
        <Box className="container" textAlign={'left'}>
            {/* <GDBlogHeader /> */}
            <Grid container spacing={{lg: 6, xs: 0}}>
                <Grid item xs={12} lg={7.5}>
                    {
                        isLoading ?
                            <BlogDetailSkeleton/>
                            :
                            <ContentContainer>
                                <Stack gap={6}>
                                    <Box
                                        width='100%'
                                        overflow={'hidden'}
                                        sx={{
                                            aspectRatio: '1.8/1',
                                            borderRadius: 1,
                                            '& img': {
                                                width: '100%',
                                                height: '100%'
                                            }
                                        }}
                                    >
                                        <img src={blogs.image_path} alt=""/>
                                    </Box>
                                    <Stack gap={2}>
                                        <Typography variant='h4' fontWeight={600}>
                                            {blogs.title_en}
                                        </Typography>
                                        <Box
                                            display='flex'
                                            alignItems='center'
                                            gap={1}
                                        >
                                            <Avatar
                                                src={blogs.user?.image_path || GDLogo}
                                            />
                                            <Stack>
                                                <Typography variant='subtitle1' sx={{'& span': {fontWeight: 600}}}>
                                                    <span>{blogs.user?.name || 'Admin'}</span> in <span>{blogs.menu.title_en}</span>
                                                </Typography>
                                                <Box
                                                    display='flex'
                                                    alignItems={'center'}
                                                    gap={1}
                                                >
                                                    <Typography variant='subtitle1' color='text.disabled'>
                                                        {DateFormat(blogs.created_at).getFullDateText()}
                                                    </Typography>
                                                    <Typography variant='subtitle1' fontWeight={800}
                                                                color='text.disabled'>
                                                        |
                                                    </Typography>
                                                    <Typography variant='subtitle1' color='text.disabled'>
                                                        {blogs.views} views
                                                    </Typography>
                                                </Box>
                                            </Stack>
                                        </Box>
                                        <Box
                                            display='flex'
                                            alignItems='center'
                                            gap={2}
                                        >
                                            <Box
                                                display='flex'
                                                alignItems='center'
                                                gap={1}
                                            >
                                                <ShareOutlined/>
                                                <Typography variant='subtitle1'>
                                                    200 Shares
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <FontAwesomeIcon icon={faFacebookF}/>
                                            </Box>
                                            <Box>
                                                <FontAwesomeIcon icon={faTwitter}/>
                                            </Box>
                                            <Box>
                                                <FontAwesomeIcon icon={faInstagram}/>
                                            </Box>
                                        </Box>
                                    </Stack>
                                    <Box
                                        sx={{
                                            '& *': {
                                                maxWidth: '98vW',
                                                margin: 'auto',
                                            }
                                        }}
                                    >
                                        <Typography variant='subtitle1'
                                                    dangerouslySetInnerHTML={{__html: blogs.description_en}}>

                                        </Typography>
                                    </Box>
                                </Stack>
                            </ContentContainer>
                    }
                </Grid>
                <Grid item xs={12} lg={4.5}>
                    <ContentContainer header='Popular Posts'>
                        <PopularPosts/>
                    </ContentContainer>
                    <ContentContainer header='Related Posts and Blogs'>
                        {
                            relatedPosts.isLoading ? <CircularProgress/> : (
                                <Stack gap={2}>
                                    {
                                        relatedPosts.data.map((item, idx) => (
                                            <NewsItemContainer
                                                mediaComponent={
                                                    <img src={item.image_path}/>
                                                }
                                            >
                                                <ItemDetails
                                                    title={item.title_en}
                                                    author={item?.user?.name || 'Ghargharmadoctor'}
                                                    category={item.menu.title_en}
                                                    time={DateFormat(item.created_at).getFullDateText()}
                                                    blog={item}
                                                />
                                            </NewsItemContainer>
                                        ))
                                    }
                                </Stack>
                            )
                        }

                    </ContentContainer>
                </Grid>
            </Grid>

        </Box>
    )
}

export default GDBlogDetails
