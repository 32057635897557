import {Box, Button, InputBase, Step, StepContent, StepLabel, Stepper} from '@mui/material'
// import { E } from 'chart.js/dist/chunks/helpers.core'
import React, {useContext, useEffect, useRef, useState} from 'react'
import {LoginStateContext} from '../../../../contexts/LoginStateContext.js';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import axios from '../../../api.js'
import {useNavigate} from 'react-router-dom';

const steps = [
    'Enter your phone number', 'Verify OTP'
]

function PhoneVerification() {
    const navigate = useNavigate()
    const loginContext = useContext(LoginStateContext)
    const userData = loginContext.state.data
    const [phoneVerifying, setPhoneVerifying] = useState(false)
    const [otpVerifying, setOtpVerifying] = useState(false)
    const [activeStep, setActiveStep] = useState(0)
    const [otp, setOtp] = useState()
    const [phoneNum, setPhoneNum] = useState(userData.member.phone)

    function nextStep() {
        console.log()
        setPhoneVerifying(true)
        axios.post('api/verify-phone', {phone: `+${phoneNum}`}, {headers: {'Authorization': `Bearer ${loginContext.state.token}`}})
            .then(res => {
                console.log(res.data)
                setActiveStep(prev => prev + 1)
            })
            .catch(err => console.log())
            .finally(() => setPhoneVerifying(false))
    }

    function prevStep() {
        setActiveStep(prev => prev - 1)
    }

    function handleChange(value) {
        console.log()
        setOtp(value)
    }

    function handleOTP() {
        setOtpVerifying(true)
        axios.post('api/verify-otp', {otp: otp}, {headers: {'Authorization': `Bearer ${loginContext.state.token}`}})
            .then(res => {
                console.log()
                navigate('/user/profile')

            }).catch(err => console.log()).finally(setOtpVerifying(false))
    }

    return (
        <Box>
            <Stepper activeStep={activeStep} orientation='vertical'>
                {
                    steps.map((step, index) => (
                        <Step key={step}>
                            <StepLabel>
                                {step}
                            </StepLabel>
                            <StepContent>
                                <Box width='100%' maxWidth={'400px'}>
                                    {
                                        activeStep === 0 && (
                                            <>
                                                <PhoneInput
                                                    country={'np'}
                                                    onlyCountries={['np']}
                                                    disableDropdown
                                                    countryCodeEditable={false}
                                                    inputProps={{
                                                        name: 'phone',
                                                        required: true,
                                                    }}
                                                    value={phoneNum}
                                                    onChange={phone => setPhoneNum(phone)}
                                                    inputStyle={{paddingLeft: '48px'}}
                                                    required
                                                />
                                                <Box mt={2}>
                                                    <Button variant='contained' onClick={nextStep}>
                                                        <span>Send OTP</span>
                                                    </Button>
                                                </Box>
                                            </>
                                        )
                                    }
                                    {
                                        activeStep === 1 && (
                                            <>
                                                <PhoneVerifyInput length={6} onChange={handleChange}/>
                                                <Box mt={2} display='flex' gap={3}>
                                                    <Button variant='contained' onClick={handleOTP}>
                                                        <span>Verify OTP</span>
                                                    </Button>
                                                    <Button onClick={prevStep}>
                                                        Change Phone Number
                                                    </Button>
                                                </Box>
                                            </>
                                        )
                                    }
                                </Box>
                            </StepContent>
                        </Step>
                    ))
                }
            </Stepper>
        </Box>
    )
}

function PhoneVerifyInput({
                              length, onChange = () => {
    }
                          }) {
    const [focused, setFocused] = useState(0)
    const [values, setValues] = useState([])
    let inputGroup = []
    console.log()


    function updateValues(newValues) {
        onChange(newValues.join(''))
        setValues(prev => (newValues))
    }

    function handleChange(e) {
        console.log()
        if (e.target.value === '') return
        setValues([...values, e.target.value])
    }

    function focusNext() {
        if (focused < length - 1) {
            setFocused(prev => prev + 1)
        }
    }

    function focusBack() {
        if (focused > 0) {
            setFocused(prev => prev - 1)
        }
    }

    function handleBack() {
        console.log()
        setFocused(prev => prev - 1)
    }

    useEffect(() => {
        console.log()
    }, [])

    for (var i = 0; i < length; i++) {
        inputGroup.push(
            <SingleInput focused={focused} index={i} next={focusNext} back={focusBack} val={values}
                         updateVal={updateValues}/>
        )
    }
    return (
        <div className="d-flex gap-4">
            {inputGroup}
        </div>
    )
}

function SingleInput({focused, val, index, back, next, updateVal}) {
    const [value, setValue] = useState(val[index])
    const inputRef = useRef(null)
    console.log()

    function onChange(e) {
        if (value) return
        val[index] = e.target.value
        updateVal(val)
        setValue(e.target.value)
        next()
    }

    function onBackSpace(e) {
        if (e.key != 'Backspace') return
        val[index] = ''
        updateVal(val)
        setValue('')
        if (value === '' || value === undefined || value === null) {
            back()
        }
    }

    function handleClick(e) {
        if (focused != index) {
            e.preventDefault()
        }
    }

    useEffect(() => {
        if (focused === index) {
            inputRef.current.focus()
            val[index] = ''
            updateVal(val)
            setValue('')
        } else {
            inputRef.current.blur()
        }
    }, [focused])

    return (
        <Box width='50px' height='50px' border={1} borderColor='divider' borderRadius={2} position='relative'>
            <InputBase inputRef={inputRef} type='text' value={value} onMouseDown={handleClick} onChange={onChange}
                       onKeyUp={onBackSpace} sx={{height: '100%', '& input': {textAlign: 'center'}}}/>
        </Box>
    )
}


export default PhoneVerification
