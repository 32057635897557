import {
    Alert,
    AlertTitle,
    Avatar,
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Rating,
    Select,
    Stack,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material'
import {useQuery} from '@tanstack/react-query'
import React from 'react'
import {useContext} from 'react'
import {GDVendorContext} from './GDVendorContext'
import axios from '../../../api'
import GDVendorReviews from './components/GDVendorReviews'
import GDVendorPostReview from './components/GDVendorPostReview'

function GDVendorProfile() {
    const matches = useMediaQuery('(max-width:780px)');

    const {vendor} = useContext(GDVendorContext)
    const reviews = useQuery(
        ['vendorReview', vendor.id],
        async () => (
            axios.get(`/api/vendor-review?vendor_id=${vendor.id}`).then(res => res.data)
        )
    )

    return (
        <Box className='container' sx={{textAlign: 'left'}}>
            <Grid container>
                <Grid item md={9}>
                    <Grid container sx={{borderTop: 1, borderColor: 'divider', py: 1}}>
                        <Grid item sm={12} md={4} sx={{borderRight: (matches) ? 0 : 1, borderColor: 'divider'}}>
                            <Box>
                                <Typography variant='h6'>Average Rating</Typography>
                                <Typography variant='h4'>{vendor.averageRating}/5</Typography>
                                <Rating value={vendor.averageRating} readOnly/>
                            </Box>
                            <GDVendorPostReview refetchReview={reviews.refetch}/>
                        </Grid>
                        <Grid item>
                            <Box sx={{pb: 1, borderBottom: 2, borderColor: 'divider'}}>
                                <Typography variant='h6'>Store Reviews</Typography>
                                <Typography variant='caption'>All reviews have been submitted by customers after
                                    purchasing products from the store</Typography>
                            </Box>
                            <Stack direction={'row'} justifyContent='space-between' mt={1} alignItems="center">
                                <Typography variant='subtitle1' fontWeight={500}>20 Reviews</Typography>
                                <FormControl sx={{m: 1, minWidth: 120}}>
                                    <InputLabel id="demo-simple-select-helper-label">Sort By</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        label="Sort By"
                                        size='small'
                                    >
                                        <MenuItem value={10}>Date</MenuItem>
                                        <MenuItem value={20}>Rating: High To Low</MenuItem>
                                        <MenuItem value={30}>Rating: Low To High</MenuItem>
                                    </Select>
                                </FormControl>
                            </Stack>
                            <GDVendorReviews reviews={reviews}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>

    )
}

export default GDVendorProfile
