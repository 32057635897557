import {Box, Stack, Typography} from '@mui/material'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {useBlogDetails} from '../GDBlogProvider'
import {removeTags} from '../../../../helperFunctions/removeHtmlTags'

function ItemDetails({title, description, category, author, time, slug = 'asdas', blog}) {
    const navigate = useNavigate()
    const {setBlogs} = useBlogDetails()
    const onClick = () => {
        setBlogs(blog)
        navigate(`/blog/${blog.id}`)
    }
    return (
        <Box width='100%' overflow='hidden'>
            <Stack
                gap={1.5}
                onClick={onClick}
                sx={{overflow: 'hidden'}}
            >
                <Box minHeight='48px' overflow='hidden'>
                    <Typography
                        variant='h6'
                        fontWeight={600}
                        lineHeight={1.2}
                        sx={{
                            display: '-webkit-box',
                            '-webkit-line-clamp': '2',
                            '-webkit-box-orient': 'vertical',
                            overflow: 'hidden'
                        }}
                    >
                        {title}
                    </Typography>
                </Box>
                {/* {
                    description && (
                        <Box overflow='hidden'>
                            <Typography
                                variant='subtitle1'
                                lineHeight={1.2}
                                sx={{
                                    display: '-webkit-box',
                                    '-webkit-line-clamp': '3',
                                    '-webkit-box-orient': 'vertical',
                                    overflow: 'hidden'
                                }}
                            >
                                {
                                    removeTags(description)
                                }
                            </Typography>
                        </Box>
                    )
                } */}

                <Box>
                    <Typography variant='subtitle1'
                                sx={{
                                    '& span': {
                                        fontWeight: 600
                                    }
                                }}
                    >
                        <span>{author}</span> in <span>{category}</span>
                    </Typography>
                    <Typography variant='subtitle1' color='text.secondary'>
                        {time}
                    </Typography>
                </Box>
            </Stack>
        </Box>

    )
}

export default ItemDetails
