import {Box, Card, Container, Tab, Tabs} from '@mui/material'
import React, {useState} from 'react'
import UnpaidItem from './components/UnpaidItem'
import UnpaidLists from './components/UnpaidLists'
import YourFitness from './components/YourFitness'

function UserFitnessPackages() {
    const [value, setValue] = useState(0)

    function handleChange(e, newVal) {
        setValue(newVal)
    }

    return (
        <Box sx={{width: '100%', typography: 'body1'}}>
            <Card variant='outlined' sx={{px: 2}}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={value} aria-label="basic tabs example" variant="scrollable" onChange={handleChange}>
                        <Tab label="Pending Payment"/>
                        <Tab label="Your Fitness Packages"/>
                    </Tabs>
                </Box>
                <TabContent value={value} index={0}>
                    <UnpaidLists/>
                </TabContent>
                <TabContent value={value} index={1}>
                    <YourFitness/>
                </TabContent>
            </Card>
        </Box>
    )
}

function TabContent({children, value, index}) {
    if (value !== index) {
        return
    }
    return (
        <Box>
            {children}
        </Box>
    )
}

export default UserFitnessPackages
