import {CircularProgress, Grid, Stack} from '@mui/material'
import React from 'react'
import NewsItemContainer from './components/NewsItemContainer'
import ItemDetails from './components/ItemDetails'
import {useBlogsHome} from './GDBlogProvider'
import {DateFormat} from '../../DateFormat'
import HomePostSkeleton from './components/HomePostSkeleton'

function HomePosts() {
    const {data, isLoading} = useBlogsHome()
    if (isLoading) {
        return <HomePostSkeleton/>
    }
    const dataHighlight = data[0]
    return (
        <Grid container spacing={4}>
            <Grid item xs={12} lg={data.length < 2 ? 12 : data.length < 5 ? 6 : 4}>
                <NewsItemContainer
                    direction='column'
                    mediaComponent={
                        <img src={dataHighlight.image_path}/>
                    }
                >
                    <ItemDetails
                        title={dataHighlight.title_en}
                        // description={dataHighlight.description_en}
                        author={dataHighlight?.user?.name || 'Ghargharmadoctor'}
                        category={dataHighlight.menu.title_en}
                        time={DateFormat(dataHighlight.created_at).getFullDateText()}
                        slug={dataHighlight.id}
                        blog={dataHighlight}
                    />
                </NewsItemContainer>
            </Grid>
            <Grid item xs={12} lg={data.length < 5 ? 6 : 4}>
                <Stack gap={2}>
                    {
                        (() => {
                            const renderList = []
                            for (let i = 1; i <= 3; i++) {
                                if (i >= data.length) {
                                    break;
                                }
                                ;

                                let newsItem = data[i]
                                renderList.push(
                                    <NewsItemContainer
                                        mediaComponent={
                                            <img src={newsItem.image_path}/>
                                        }
                                    >
                                        <ItemDetails
                                            title={newsItem.title_en}
                                            author={newsItem?.user?.name || 'Ghargharmadoctor'}
                                            category={newsItem.menu.title_en}
                                            // description={newsItem.description_en}
                                            time={DateFormat(newsItem.created_at).getFullDateText()}
                                            blog={newsItem}
                                        />
                                    </NewsItemContainer>
                                )
                            }
                            return renderList
                        })()
                    }
                </Stack>
            </Grid>
            {
                data.length > 3 && (

                    <Grid item xs={12} lg={4}>
                        <Stack gap={2}>
                            {
                                (() => {
                                    const renderList = []
                                    for (let i = 4; i <= 6; i++) {
                                        if (i >= data.length) {
                                            break;
                                        }
                                        ;

                                        let newsItem = data[i]
                                        renderList.push(
                                            <NewsItemContainer
                                                mediaComponent={
                                                    <img src={newsItem.image_path}/>
                                                }
                                            >
                                                <ItemDetails
                                                    title={newsItem.title_en}
                                                    author={newsItem?.user?.name || 'Ghargharmadoctor'}
                                                    category={newsItem.menu.title_en}
                                                    time={DateFormat(newsItem.created_at).getFullDateText()}
                                                    blog={newsItem}
                                                />
                                            </NewsItemContainer>
                                        )
                                    }
                                    return renderList
                                })()
                            }
                        </Stack>
                    </Grid>
                )
            }

        </Grid>
    )
}

export default HomePosts
