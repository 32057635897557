export function chatBotConfig(config, listenBot, typing, navigate) {
    let resOption = null;
    let checkBox = null;
    let awaitUser = false;
    let awaitedRes = null;
    let historyStack = [];
    let variables = {
        name: 'Guest User'
    }
    // if(auth.auth) {
    //     variables.name = auth.name
    // }
    function setCheckBox(chkBox) {
        checkBox = chkBox;
    }

    function back() {
        historyStack.pop()
        awaitUser = false
        awaitedRes = null
        if (historyStack.length === 0) {
            chat('End')
        } else {
            chat(historyStack[historyStack.length - 1])
        }
    }

    async function chat(title, checkBoxValues, username = "Guest User") {
        variables.name = username
        let timeout = 3000
        let response = Object.assign({}, config.filter((item) => item.title === title)[0])
        if (checkBoxValues) {
            setCheckBox(checkBoxValues)
            awaitUser = false
            response = awaitedRes
            response.awaitUser = false
        }
        if (typeof title === 'object' && title !== null) {
            response = Object.assign({}, title)
        }
        if (response === undefined || response === null) {
            return
        }
        if (response.pushStack) {
            historyStack.push(response)
        }
        if (response.awaitUser) {
            awaitedRes = response
            awaitUser = true
        }
        if (awaitUser) return

        if ('awaitUser' in response) {
            response.awaitUser = true
        }
        typing(true)

        if (response.request) {
            let data = await response.apiCall({checkValues: response.requestParams ? checkBox : null})
            if (data.length === 0) {
                let noDataTrigger = config.filter((item) => item.id === response.noDataTrigger)[0]
                chat(noDataTrigger)
                return
            }
            if (response.responseType === 'options') {
                resOption = data
            }
        }
        if (response.setOptions) {
            response.options_list = response.setOptions({prevOptions: resOption})
        }

        if (response.checkboxValues) {
            listenBot(setCheckBox)
        }

        if (response.message || response.options || response.checkbox) {
            if (response.message) {
                var placeholders = response.message.match(/\$(.*?)\$/g)
                if (placeholders) {
                    placeholders.forEach((placeholder) => {
                        var phText = placeholder.substring(1, placeholder.length - 1)

                        if (variables[phText]) {
                            response.message = response.message.replace(placeholder, variables[phText])
                        }
                    })
                }

            }
            setTimeout(() => {
                listenBot(response)
                typing(false)
            }, timeout)
            timeout += 1000
        }
        if (response.redirect) {
            setTimeout(() => {
                navigate(response.redirect_to)
            }, timeout)
        }
        if (response.trigger) {
            let triggered = config.filter((item) => item.id === response.trigger)[0]
            chat(triggered.title)
        }
    }

    return {botConfig: chat, back: back}


}
