import {createContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router';
import {clearToken, getToken} from "../../../utils/common/token.utils";
import {useQuery} from "@tanstack/react-query";
import ParentService from "../../../services/parent.service";
import Loader from "../../pages/Loader";


export const ProfileContext = createContext(null)

const Protected = (props) => {
    const navigate = useNavigate();
    const [authenticated, setAuthenticated] = useState(false);
    const token = getToken();
    const [profile, setProfile] = useState(undefined)

    /*
     * STATE AND PROPS
     * */
    const {children} = props;

    useQuery({
        queryKey: ['profile'],
        queryFn: () => ParentService.fetchParentProfile(),
        onSuccess: (data) => {
            if (data.success) {
                setAuthenticated(true);
                setProfile(data?.data)
            }
        },
        onError: (error) => {
            console.log(error)
            clearToken();
            setAuthenticated(false)
            navigate("/school-report");
        },
        enabled: Boolean(token),
        retry: 2,
    })

    useEffect(() => {
        if (!token) {
            navigate("/school-report");
            setAuthenticated(false)
        }
    }, [token]);

    const renderLoader = () => {
        return (
            <div style={{height: "100vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <Loader/>
            </div>
        )
    }

    return (
        <ProfileContext.Provider value={profile}>
            {authenticated ? children : renderLoader()}
        </ProfileContext.Provider>
    )
};

export default Protected;
