import axios from "../../api.js";
import React, {useContext, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import swal from "sweetalert";
import Loader from "../Loader.js";
import {LoginStateContext} from '../../../contexts/LoginStateContext'
import AuthButton from "../../custom_componets/AuthButton.jsx";
import {Box, Card, CardContent, CardHeader, CardMedia, Grid, Stack, Typography} from "@mui/material";
import {feetInchText} from "../../../helperFunctions/meterToFeetInch.js";


const PackageBooking = () => {
    const loginContext = useContext(LoginStateContext)
    const location = useLocation()
    const token = loginContext.state.token
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [interval, setInterval] = useState('');
    const [pkg, setPackage] = useState(null);
    const [packagefee, setPackageFee] = useState('');
    const [userpackage, setUserPackage] = useState('');
    const [submitting, setSubmitting] = useState(false);
    let navigate = useNavigate();
    const [calcRes, setCalcRes] = useState({
        registration: 0,
        monthly: 0,
        totalMonth: 0,
        discountTotal: 0,
        discount: 0,
        result: 0,
    })

    console.log("Package in package booking page", pkg)


    useEffect(() => {
        if (packagefee) {
            if (location.state?.payment_interval) {
                calcFeeCommon(location.state.payment_interval)
                setInterval(location.state.payment_interval)
            }
        }
    }, [location.state.payment_interval, packagefee])

    useEffect(() => {
        console.log("params is", params)
        axios
            .get(`/api/package?slug=${params.slug}`)
            .then((res) => {
                setPackage(res.data);
                console.log('response', res.data)
            })
            .catch((error) => {
                console.log();
            });
    }, []);

    useEffect(() => {
        axios
            .get("/api/admin/user-profile", {
                headers: {'Authorization': `Bearer ${token}`},
            })
            .then((res) => {
                setUser(res.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log();
            });
    }, []);

    //to get the package fee acoording to the family size
    useEffect(() => {
        if (pkg != null) {
            axios
                .get(`/api/admin/userpackage/calculate-amount/${pkg.id}`, {
                    headers: {'Authorization': `Bearer ${token}`}
                })
                .then((res) => {
                    setPackageFee(res.data.packagefee);
                })
                .catch((error) => {
                    console.log();
                });
        }
    }, [pkg]);

    useEffect(() => {
        axios
            .get('/api/admin/userpackage', {
                headers: {"Authorization": `Bearer ${token}`}
            })
            .then(res => {
                setUserPackage(res.data.package)
                setLoading(false);
            })
            .catch((error) => {
                console.log();
            });
    }, [token]);

    const {register, handleSubmit} = useForm({
        defaultValues: {
            payment_interval: location.state.payment_interval || null
        }
    });
    const [renewStatus, setRenewStatus] = useState();
    const [year, setYear] = useState();

    const onSubmit = (data) => {
        setSubmitting(true)
        data.package_id = pkg.id
        console.log(data)
        axios
            .post("/api/admin/userpackage/store", data, {
                headers: {'Authorization': `Bearer ${token}`},
            })
            .then((res) => {
                console.log(res)
                setSubmitting(false)
                if (res.data.message) {
                    swal({
                        title: res.data.message + "testing",
                        icon: "warning",
                    }).then((value) => {
                        if (value) {
                            navigate("/packages")
                        }
                    });
                } else {
                    swal({
                        title: "Package Booked!",
                        icon: "success",
                        button: "Proceed for Payment",
                        closeOnClickOutside: false
                    }).then((value) => {
                        if (value) {
                            navigate("/book-package/payment", {
                                state: {
                                    fee: calcRes.result,
                                    id: res.data.id,
                                    interval: interval,
                                    monthly_fee: calcRes.monthly * packagefee.family_size,
                                    packageType: pkg.id
                                }
                            })
                        }
                    });
                }
            })
            .catch((err) => {
                setSubmitting(false)
                console.log();
            });
    };

    const calcFeeCommon = (paymentInterval) => {
        const feeInterval = {
            Yearly: {
                month: 12,
                discount: 0.05
            },
            Half_Yearly: {
                month: 6,
                discount: 0.02
            },
            Quarterly: {
                month: 3,
                discount: 0.01
            },
            Monthly: {
                month: 1,
                discount: 0
            }

        }
        let totalmonth = 0;
        let discounttotal = 0;
        let res = 0;

        // if (userpackage !== null && userpackage.package_id === pkg.id && userpackage.familyfee.family_size + userpackage.additonal_members === packagefee.family_size) {
        //   if (userpackage.renew_status === 1) {
        //     totalmonth = packagefee.family_size * packagefee.two_monthly_fee * feeInterval[paymentInterval].month;
        //     discounttotal = totalmonth - totalmonth * feeInterval[paymentInterval].discount;
        //     res = packagefee.two_continuation_fee + discounttotal;
        //     setCalcRes({ registration: packagefee.two_continuation_fee, monthly: packagefee.two_monthly_fee, totalMonth: totalmonth, discountTotal: discounttotal, discount: feeInterval[paymentInterval].discount * 100, result: res })
        //     setRenewStatus(2)
        //     setYear(2)
        //   }
        //   if (userpackage.renew_status >= 2) {
        //     totalmonth = packagefee.family_size * packagefee.three_monthly_fee * feeInterval[paymentInterval].month;
        //     discounttotal = totalmonth - totalmonth * feeInterval[paymentInterval].discount;
        //     res = packagefee.three_continuation_fee + discounttotal;
        //     setCalcRes({ registration: packagefee.three_continuation_fee, monthly: packagefee.three_monthly_fee, totalMonth: totalmonth, discountTotal: discounttotal, discount: feeInterval[paymentInterval].discount * 100, result: res })
        //     setRenewStatus(3)
        //     setYear(userpackage.year + 1)
        //   }
        // } else {
        //   totalmonth = packagefee.family_size * packagefee.one_monthly_fee * feeInterval[paymentInterval].month;
        //   discounttotal = totalmonth - totalmonth * feeInterval[paymentInterval].discount;
        //   res = packagefee.one_registration_fee + discounttotal;
        //   setCalcRes({ registration: packagefee.one_registration_fee, monthly: packagefee.one_monthly_fee, totalMonth: totalmonth, discountTotal: discounttotal, discount: feeInterval[paymentInterval].discount * 100, result: res })
        //   setRenewStatus(1)
        //   setYear(1)
        // }
        totalmonth = packagefee.family_size * packagefee.one_monthly_fee * feeInterval[paymentInterval].month;
        discounttotal = totalmonth - totalmonth * feeInterval[paymentInterval].discount;
        res = packagefee.one_registration_fee + discounttotal;
        setCalcRes({
            registration: packagefee.one_registration_fee,
            monthly: packagefee.one_monthly_fee,
            totalMonth: totalmonth,
            discountTotal: discounttotal,
            discount: feeInterval[paymentInterval].discount * 100,
            result: res
        })
        setRenewStatus(1)
        setYear(1)
    }

    const calculateFee = (e) => {
        if (e.target.value === '') {
            setCalcRes({registration: 0, monthly: 0, totalMonth: 0, discountTotal: 0, discount: 0, result: 0})
        } else {
            setInterval(e.target.value)
            let paymentInterval = e.target.value;
            const feeInterval = {
                Yearly: {
                    month: 12,
                    discount: 0.05
                },
                Half_Yearly: {
                    month: 6,
                    discount: 0.02
                },
                Quarterly: {
                    month: 3,
                    discount: 0.01
                },
                Monthly: {
                    month: 1,
                    discount: 0
                }
            }
            let totalmonth = 0;
            let discounttotal = 0;
            let res = 0;

            // if (userpackage !== null && userpackage.package_id === pkg.id && userpackage.familyfee.family_size + userpackage.additonal_members === packagefee.family_size) {
            //   if (userpackage.renew_status === 1) {
            //     totalmonth = packagefee.family_size * packagefee.two_monthly_fee * feeInterval[paymentInterval].month;
            //     discounttotal = totalmonth - totalmonth * feeInterval[paymentInterval].discount;
            //     res = packagefee.two_continuation_fee + discounttotal;
            //     setCalcRes({ registration: packagefee.two_continuation_fee, monthly: packagefee.two_monthly_fee, totalMonth: totalmonth, discountTotal: discounttotal, discount: feeInterval[paymentInterval].discount * 100, result: res })
            //     setRenewStatus(2)
            //     setYear(2)
            //   }
            //   if (userpackage.renew_status >= 2) {
            //     totalmonth = packagefee.family_size * packagefee.three_monthly_fee * feeInterval[paymentInterval].month;
            //     discounttotal = totalmonth - totalmonth * feeInterval[paymentInterval].discount;
            //     res = packagefee.three_continuation_fee + discounttotal;
            //     setCalcRes({ registration: packagefee.three_continuation_fee, monthly: packagefee.three_monthly_fee, totalMonth: totalmonth, discountTotal: discounttotal, discount: feeInterval[paymentInterval].discount * 100, result: res })
            //     setRenewStatus(3)
            //     setYear(userpackage.year + 1)
            //   }
            // } else {
            //   totalmonth = packagefee.family_size * packagefee.one_monthly_fee * feeInterval[paymentInterval].month;
            //   discounttotal = totalmonth - totalmonth * feeInterval[paymentInterval].discount;
            //   res = packagefee.one_registration_fee + discounttotal;
            //   setCalcRes({ registration: packagefee.one_registration_fee, monthly: packagefee.one_monthly_fee, totalMonth: totalmonth, discountTotal: discounttotal, discount: feeInterval[paymentInterval].discount * 100, result: res })
            //   setRenewStatus(1)
            //   setYear(1)
            // }
            totalmonth = packagefee.family_size * packagefee.one_monthly_fee * feeInterval[paymentInterval].month;
            discounttotal = totalmonth - totalmonth * feeInterval[paymentInterval].discount;
            res = packagefee.one_registration_fee + discounttotal;
            setCalcRes({
                registration: packagefee.one_registration_fee,
                monthly: packagefee.one_monthly_fee,
                totalMonth: totalmonth,
                discountTotal: discounttotal,
                discount: feeInterval[paymentInterval].discount * 100,
                result: res
            })
            setRenewStatus(1)
            setYear(1)
        }
    }

    if (loading) {
        return (
            <div className="text-center">
                <Loader/>
            </div>
        )
    }

    return (
        <>

            <div className="container my-5">
                {
                    user &&
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {renewStatus !== undefined &&
                            <input type="hidden" {...register('renew_status')} value={renewStatus}/>
                        }
                        {renewStatus !== undefined &&
                            <input type="hidden" {...register('year')} value={year}/>
                        }
                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                                <Box sx={{display: 'flex'}} gap={3}>
                                    <CardMedia
                                        sx={{
                                            width: '100%',
                                            maxWidth: '300px',
                                            flexShrink: 0,
                                            flexGrow: 0,
                                            aspectRatio: '1/1'
                                        }}
                                        component="img"
                                        image={user.image_path}
                                        alt="Live from space album cover"
                                    />

                                    <Card variant="outlined">
                                        <CardHeader title={<Typography variant="h4">User Profile Details</Typography>}/>
                                        <CardContent sx={{flexGrow: 1}}>
                                            <Grid container spacing={2}>
                                                <UserProfileListItem label='Full Name' value={user.member.name}/>
                                                <UserProfileListItem label='Phone Number' value={user.member.phone}/>
                                                <UserProfileListItem label='Your Email' value={user.member.email}/>
                                                <UserProfileListItem label='Address' value={user.address}/>
                                                <UserProfileListItem label='Date of Birth' value={user.dob}/>
                                                <UserProfileListItem label='Age'
                                                                     value={user.dob ? new Date().getFullYear() - user.dob.slice(0, 4) : ''}/>
                                                <UserProfileListItem label='Gender' value={user.gender}/>
                                                <UserProfileListItem label='Blood Group' value={user.blood_group}/>
                                                <UserProfileListItem label='Weight' value={user.weight}/>
                                                <UserProfileListItem label='Height' value={feetInchText(user.height)}/>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Card variant='outlined'>
                                    <CardHeader title={<Typography variant="h4">Package Details</Typography>}/>
                                    <CardContent>
                                        <h2 style={{color: "#0259A7"}}> {pkg?.package_type}</h2>
                                        <div className="container my-4">
                                            <input type="hidden" {...register("package_id")} value={pkg?.id}/>
                                            <p className="fs-5">
                                                <b>Total Number of Visits: </b>
                                                <span>{pkg?.visits}</span>
                                            </p>

                                            <div className="my-4">
                                                <h5>
                                                    <b>Package Description</b>
                                                </h5>
                                                <p
                                                    dangerouslySetInnerHTML={{__html: `${pkg?.description}`}}
                                                    className="mb-0"
                                                ></p>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                            {
                                packagefee &&
                                <Grid item xs={12} md={6}>
                                    {userpackage !== null && userpackage.package_id === pkg?.id && userpackage.familyfee.family_size + userpackage.additonal_members === packagefee.family_size &&
                                        <div className="alert alert-success">
                                            Your current package will be renewed if you buy this package.
                                        </div>
                                    }
                                    <Card variant="outlined">
                                        <CardHeader title={<Typography variant="h4">Booking Details</Typography>}/>
                                        <CardContent>
                                            <Box mb={2}>
                                                <Typography variant="h4">
                                                    Your Total Family Size is <span
                                                    className="text-primary">{packagefee.family_size}</span>
                                                </Typography>
                                            </Box>
                                            <table className="table">
                                                <thead>
                                                <tr>
                                                    <th>Registration Fee/Family</th>
                                                    <td>Rs. {calcRes.registration}</td>
                                                </tr>
                                                <tr>
                                                    <th>Monthly Fee/Person</th>
                                                    <td>Rs. {calcRes.monthly}</td>
                                                </tr>
                                                <tr>
                                                    <th>Select Payment Interval</th>
                                                    <td>
                                                        <select
                                                            className='form-select' {...register("payment_interval")}
                                                            onChange={calculateFee} required>
                                                            <option value="">--Select--</option>
                                                            <option value="Yearly">Yearly</option>
                                                            <option value="Half_Yearly">Half Yearly</option>
                                                            <option value="Quarterly">Quarterly</option>
                                                            <option value="Monthly">Monthly</option>
                                                        </select>
                                                    </td>
                                                </tr>
                                                </thead>
                                            </table>
                                            <div className="text-end">
                                                <p><b>Total Monthly Fee:</b> Rs.{calcRes.totalMonth} </p>
                                                <p><b>Total Payable Monthly Fee After
                                                    Discount:</b> Rs.{calcRes.discountTotal} ({calcRes.discount}%
                                                    Discount) </p>
                                                <p><b>Total Payable Amount:</b> Rs. {calcRes.result}</p>
                                                <AuthButton component='button' loading={submitting} type={'submit'}
                                                            className={'btn btn-primary py-2 px-3'}>Book Package
                                                    Rs. {calcRes.result}/-</AuthButton>
                                                {/* <button type="submit" className='btn btn-primary py-2 px-3'> Book Package Rs. {calcRes.result}/-</button> */}
                                            </div>
                                        </CardContent>
                                    </Card>

                                </Grid>
                            }
                        </Grid>
                    </form>

                }

            </div>
        </>
    );
};

// function usePrevious(value) {
//   const ref = useRef()
//   useEffect(()=>{
//     ref.current = value
//   })
//   return ref.current
// }

function UserProfileListItem({label, value}) {
    return (
        <Grid item xs={6}>
            <Card variant='outlined' sx={{p: 1}}>
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="subtitle1" fontWeight={600}>
                            {label}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle1">
                            {value}
                        </Typography>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    )
}

export default PackageBooking;
