import {LockClockOutlined} from '@mui/icons-material'
import {Alert, AlertTitle, Avatar, Box} from '@mui/material'
import {useQuery} from '@tanstack/react-query'
import React from 'react'
import {useChkLogin} from '../../../../middlewares/LoginMiddleware'
import axios from '../../../api'
import ImgPlaceHolder from '../../../../images/receptionist.png'
import {DateFormat} from '../../../DateFormat'
import {getTimeDifference} from '../../../../helperFunctions/getTimeDifference'
import {useNavigate} from 'react-router-dom'

// delete this code and code a new one.


function ConsultationHistory() {
    const {token} = useChkLogin()
    const navigate = useNavigate()
    const {data, isLoading} = useQuery(
        ['consultation-history'],
        async () => (
            axios.get(`api/online-consultation-meeting`, {headers: {'Authorization': `Bearer ${token}`}})
                .then(res => res.data).catch(err => console.log(err))
        )
    )
    console.log("History", data)
    return (
        <div className="container">
            <div className="card">
                <div className="card-header">
                    <span className='fs-6 fw-bold'>Consultation History</span>
                    <br/>
                    <span className='fs-6 fw-light'>These are your several consultations</span>
                </div>
                <div className="card-body">
                    {
                        isLoading ? <h2>Loading..</h2>
                            :
                            data.length === 0 ?
                                <Alert severity='error'>
                                    <AlertTitle>No Consultation History Found.</AlertTitle>
                                </Alert>
                                :
                                <table className='table border-left border-right border-top'>
                                    <thead>
                                    <Box component='tr' bgcolor={'#'}>
                                        <th><span className='text-muted'>Doctor Name</span></th>
                                        <th><span className='text-muted'>Agenda</span></th>
                                        <th><span className="text-muted">Start Time</span></th>
                                        <th><span className='text-muted'>End Time</span></th>
                                        <th><span className='text-muted'>Consultation Time</span></th>
                                        <th><span className='text-muted'>Consultation Date</span></th>
                                        <th></th>
                                    </Box>
                                    </thead>
                                    <tbody>
                                    {
                                        data.map((item, idx) => (
                                            item.doctor !== null && (
                                                <tr key={item.id}>
                                                    <td>
                                                        <div className="d-flex align-items-center gap-2">
                                                            <Avatar src={item.doctor.image_path || ImgPlaceHolder}/>
                                                            <div>
                                                                <div>
                                                                    <span className='fs-6'
                                                                          style={{fontWeight: 550}}>{item.doctor.user.name}</span>
                                                                </div>
                                                                <div>
                                                                    <span
                                                                        className='fw-light'>{item.doctor.qualification || 'No qualification added'}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span className="fs-6 text-muted">{item.agenda}</span>
                                                    </td>
                                                    <td>
                                                        <span
                                                            className="fs-6 text-muted">{item.start_time.split(' ')[1]}</span>
                                                    </td>
                                                    <td>
                                                        <span
                                                            className="fs-6 text-muted">{item.end_time.split(' ')[1]}</span>
                                                    </td>
                                                    <td>
                                                        <div
                                                            className="d-flex align-items-center gap-1 badge badge-success text-white"
                                                            style={{width: 'fit-content'}}>
                                                            <LockClockOutlined className='fs-6'/>
                                                            <span
                                                                className="fs-6 ">{getTimeDifference(item.start_time, item.end_time)}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span
                                                            className="fs-6 text-muted">{DateFormat(item.start_time).getFullDateText()}</span>
                                                    </td>
                                                    <td>
                                                        <button className="btn btn-primary"
                                                                onClick={() => navigate(`/user/consultation-history/${item.id}`)}>View
                                                            Details
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        ))
                                    }

                                    {/* <tr>
                                            <td>
                                                <div className="d-flex align-items-center gap-2">
                                                    <Avatar src='https://mui.com/static/images/avatar/1.jpg' />
                                                    <div>
                                                        <div>
                                                            <span className='fs-6' style={{ fontWeight: 550 }}>Adam Messy</span>
                                                        </div>
                                                        <div>
                                                            <span className='fw-light'>PHD, Orthopedics</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <span className="fs-6 text-muted">Package Health Treatment</span>
                                            </td>
                                            <td>
                                                <span className="fs-6 text-muted">13:00</span>
                                            </td>
                                            <td>
                                                <span className="fs-6 text-muted">15:00</span>
                                            </td>
                                            <td>
                                                <div className="d-flex align-items-center gap-1 badge badge-warning text-white" style={{ width: 'fit-content' }}>
                                                    <LockClockOutlined className='fs-6' />
                                                    <span className="fs-6 ">30 minutes</span>
                                                </div>
                                            </td>
                                            <td>
                                                <span className="fs-6 text-muted">June 3, 2023</span>
                                            </td>
                                            <td>
                                                <button className="btn btn-primary">View Details</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="d-flex align-items-center gap-2">
                                                    <Avatar src='https://mui.com/static/images/avatar/1.jpg' />
                                                    <div>
                                                        <div>
                                                            <span className='fs-6' style={{ fontWeight: 550 }}>Adam Messy</span>
                                                        </div>
                                                        <div>
                                                            <span className='fw-light'>PHD, Orthopedics</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <span className="fs-6 text-muted">Package Health Treatment</span>
                                            </td>
                                            <td>
                                                <span className="fs-6 text-muted">13:00</span>
                                            </td>
                                            <td>
                                                <span className="fs-6 text-muted">15:00</span>
                                            </td>
                                            <td>
                                                <div className="d-flex align-items-center gap-1 badge badge-info text-white" style={{ width: 'fit-content' }}>
                                                    <LockClockOutlined className='fs-6' />
                                                    <span className="fs-6 ">1 hour</span>
                                                </div>
                                            </td>
                                            <td>
                                                <span className="fs-6 text-muted">June 3, 2023</span>
                                            </td>
                                            <td>
                                                <button className="btn btn-primary">View Details</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="d-flex align-items-center gap-2">
                                                    <Avatar src='https://mui.com/static/images/avatar/1.jpg' />
                                                    <div>
                                                        <div>
                                                            <span className='fs-6' style={{ fontWeight: 550 }}>Adam Messy</span>
                                                        </div>
                                                        <div>
                                                            <span className='fw-light'>PHD, Orthopedics</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <span className="fs-6 text-muted">Package Health Treatment</span>
                                            </td>
                                            <td>
                                                <span className="fs-6 text-muted">13:00</span>
                                            </td>
                                            <td>
                                                <span className="fs-6 text-muted">15:00</span>
                                            </td>
                                            <td>
                                                <div className="d-flex align-items-center gap-1 badge badge-secondary text-white" style={{ width: 'fit-content' }}>
                                                    <LockClockOutlined className='fs-6' />
                                                    <span className="fs-6 ">2 hours</span>
                                                </div>
                                            </td>
                                            <td>
                                                <span className="fs-6 text-muted">June 3, 2023</span>
                                            </td>
                                            <td>
                                                <button className="btn btn-primary">View Details</button>
                                            </td>
                                        </tr> */}
                                    </tbody>
                                </table>
                    }

                </div>
            </div>
        </div>
    )
}

export default ConsultationHistory
