import {faEye} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useQuery} from '@tanstack/react-query';
import MUIDataTable from 'mui-datatables';
import React from 'react'
import {Link} from 'react-router-dom';
import axios from '../../../api.js';
import Loader from '../../Loader.js';
import {useChkLogin} from '../../../../middlewares/LoginMiddleware.js';

const MyNurseAppointment = () => {
    const {token} = useChkLogin()
    const appointmentsQuery = useQuery(
        ['nurse-appointments'],
        async () => {
            return axios.get('/api/admin/nurses/bookings', {headers: {"Authorization": `Bearer ${token}`}})
                .then(res => res.data)
        }
    )

    if (appointmentsQuery.isLoading) {
        return <div className='d-flex justify-content-center'><Loader/></div>
    }
    const appointments = appointmentsQuery.data

    return (
        // <div className='card'>
        //     <div className="card-body">
        //         <table className='table'>
        //             <thead>
        //                 <tr>
        //                     <th>S.N.</th>
        //                     <th>Nurse Name</th>
        //                     <th>Appointment Date</th>
        //                     <th>Shift</th>
        //                     <th>Payment Status</th>
        //                     <th>Appointment Status</th>
        //                     <th>Action</th>
        //                 </tr>
        //             </thead>
        //             <tbody>
        //                 {appointments.map((appointment, index) =>
        //                     <tr key={index}>
        //                         <td>{index + 1}</td>
        //                         <td>{appointment.shift.nurse.user.name}</td>
        //                         <td>{appointment.shift.date}</td>
        //                         <td>{appointment.shift.shift}</td>
        //                         <td>{appointment.status === 1 ? <span className='text-success'>Completed</span> : <span className='text-danger'>Payment Due</span>}</td>
        //                         <td>{appointment.booking_status}</td>
        //                         <td>
        //                             <Link to={`/user/appointments/nurse/${appointment.slug}`} className='btn btn-primary'>
        //                                 <FontAwesomeIcon icon={faEye} />
        //                             </Link>
        //                         </td>
        //                     </tr>
        //                 )}
        //             </tbody>
        //         </table>
        //     </div>
        // </div>
        <NurseAppointmentTable appointments={appointments}/>
    )
}

const NurseAppointmentTable = ({appointments}) => {
    const columns = [
        {
            name: "sn",
            label: "S.N."
        },
        {
            name: "nurseName",
            label: "Nurse Name",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "appointmentDate",
            label: "Appointment Date",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "shift",
            label: "Shift",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "paymentStat",
            label: "Payment Status",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "appointmentStat",
            label: "Appointment Status",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "appointmentActions",
            label: "Actions",
        },
    ]

    let data = []

    for (let i = 0; i < appointments.length; i++) {
        let appointment = appointments[i]
        const dataObj = {}
        dataObj.sn = i + 1
        dataObj.nurseName = appointment.shift.nurse.user.name
        dataObj.appointmentDate = appointment.shift.date
        dataObj.shift = appointment.shift.shift
        dataObj.paymentStat = appointment.status === 1 ? <span className='text-success'>Completed</span> :
            <span className='text-danger'>Payment Due</span>
        dataObj.appointmentStat = appointment.booking_status
        dataObj.appointmentActions = (
            <Link to={`/user/appointments/nurse/${appointment.slug}`} className='btn btn-primary'>
                <FontAwesomeIcon icon={faEye}/>
            </Link>
        )
        data.push(dataObj)
    }

    const options = {
        filterType: 'checkbox',
        responsive: 'standard'
    };

    return (
        <MUIDataTable
            title={(<h2>Appointment List</h2>)}
            data={data}
            columns={columns}
            options={options}
        />
    )

}

export default MyNurseAppointment
