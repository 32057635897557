import {useQuery} from '@tanstack/react-query'
import React, {useState} from 'react'
import {useEffect} from 'react'
import {useContext} from 'react'
import {LoginStateContext} from '../../../contexts/LoginStateContext'
import axios from '../../api'

function useFamily(mainpackage) {
    const loginContext = useContext(LoginStateContext)
    const token = loginContext.state.token || null
    const [data, setData] = useState(null), [isLoading, setLoading] = useState(true)
    useEffect(() => {
        if (!loginContext.state.loading && loginContext.state.loggedIn && mainpackage != null) {
            axios.get(`api/admin/userpackage/calculate-amount/${mainpackage.id}`, {headers: {'Authorization': `Bearer ${token}`}})
                .then(res => {
                    setData(res.data)
                    console.log()
                    setLoading(false)
                    console.log()
                })
        }
    }, [loginContext.state.loading, mainpackage])

    if (loginContext.state.loading) {
        return {data: null, isLoading: loginContext.state.loading}
    }

    if (!loginContext.state.loggedIn) {
        return {
            data: null,
            isLoading: isLoading
        }
    }

    return {data, isLoading}
}

export default useFamily
