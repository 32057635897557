import {useQuery} from '@tanstack/react-query'
import React, {createContext} from 'react'
import {useContext} from 'react'
import axios from '../api'

const WebFooterContext = createContext(null)

function WebFooterProvider({children}) {
    const services = useQuery(
        ['our-services'],
        async () => (
            axios.get("api/service")
                .then((res) => res.data)
        )
    )
    const footerAbout = useQuery(
        ['about-gd'],
        async () => (
            axios.get('api/about')
                .then(res => res.data)
        )
    )
    return (
        <WebFooterContext.Provider
            value={{
                services,
                footerAbout
            }}
        >
            {children}
        </WebFooterContext.Provider>
    )
}

export function useFooterServices() {
    const {services} = useContext(WebFooterContext)
    return {...services}
}

export function useFooterAbout() {
    const {footerAbout} = useContext(WebFooterContext)
    return {...footerAbout}
}

export default WebFooterProvider
