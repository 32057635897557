import {Cancel, Close, Done, Pending} from '@mui/icons-material'
import {Box, IconButton} from '@mui/material'
import React from 'react'
import {DateFormat} from '../../../DateFormat'

function StudentRequests({handleClose, data}) {
    return (
        <Box
            position='absolute'
            top='50%'
            left='50%'
            sx={{
                transform: 'translate(-50%, -50%)'
            }}
            maxHeight='80vH'
        >
            <Box className="card" minWidth={600}>
                <div className="card-header">
                    <div className="d-flex align-items-center justify-content-between">
                        <span className="fs-5">Deactivate Requests - Students</span>
                        <IconButton type='error' onClick={handleClose}>
                            <Close/>
                        </IconButton>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row mb-2">
                        <div className="col-3">
                            <span className="fs-6 fw-light">
                                Request Date:
                            </span>
                        </div>
                        <div className="col-4">
                            <span className='fs-6'>{DateFormat(data.created_at).getFullDateText()}</span>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-3">
                            <span className="fs-6 fw-light">
                                Total Students:
                            </span>
                        </div>
                        <div className="col-4">
                            <span className="fs-6">
                                {data.students.length} Students
                            </span>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-3">
                            <span className="fs-6 fw-light">
                                Request Status:
                            </span>
                        </div>
                        <div className="col-4">
                            {
                                data.status === 0 ?
                                    <div className="d-flex align-items-center gap-1 badge bg-info"
                                         style={{width: 'fit-content'}}>
                                        <Pending className='fs-6'/>
                                        <span className="">Pending</span>
                                    </div>
                                    :
                                    data.status === 1 ?
                                        <div className="d-flex align-items-center gap-1 badge bg-success"
                                             style={{width: 'fit-content'}}>
                                            <Done className='fs-6'/>
                                            <span className="">Approved</span>
                                        </div>
                                        :
                                        data.status === 2 ?
                                            <div className="d-flex align-items-center gap-1 badge bg-danger"
                                                 style={{width: 'fit-content'}}>
                                                <Cancel className='fs-6'/>
                                                <span className="">Rejected</span>
                                            </div>
                                            :
                                            <div className="d-flex align-items-center gap-1 badge bg-warning"
                                                 style={{width: 'fit-content'}}>
                                                <Cancel className='fs-6'/>
                                                <span className="">Cancelled</span>
                                            </div>
                            }
                        </div>
                    </div>
                    {
                        data.status === 2 && (
                            <div className="row mb-5">
                                <div className="col-3">
                                    <span className="fs-6 fw-light">
                                        Reject Reason:
                                    </span>
                                </div>
                                <div className="col-4">
                                    <span className="fs-6" dangerouslySetInnerHTML={{__html: data?.reject_reason}}/>
                                </div>
                            </div>
                        )
                    }
                    <table className="table border-left border-right border-top">
                        <thead>
                        <Box component='tr' bgcolor={'#'}>
                            <th><span className="fw-light">S.N.</span></th>
                            <th><span className="fw-light">Student Name</span></th>
                            <th><span className="fw-light">Student Username</span></th>
                            {/* <th><span className="fw-light">Address</span></th> */}
                        </Box>
                        </thead>
                        <tbody>
                        {
                            data.students.map((item, idx) => {
                                let stdDetails = item.user
                                return (
                                    <tr key={item.id}>
                                        <td>
                                            <span className='text-muted'>{idx + 1}</span>
                                        </td>
                                        <td>
                                            <span className='text-muted'>{stdDetails.name}</span>
                                        </td>
                                        <td>
                                            <span className='text-primary'>{stdDetails.user_name}</span>
                                        </td>
                                        {/* <td>
                                                <span className='text-muted'>Baneshwor, Kathmandu, Nepal</span>
                                            </td> */}
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </Box>
        </Box>
    )
}

export default StudentRequests
