import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { createContext } from "react";
import swal from "sweetalert";

const GDCartContext = createContext(null)

export const GDCartProvider = ({ children }) => {
    let cartName = 'gdCart'
    const [cart, setCart] = useState([]);

    useEffect(() => {
        if (localStorage.getItem('gdCart') || localStorage.getItem('gdCart') != undefined) {
            setCart(JSON.parse(localStorage.getItem(cartName)))
        }
        else {
            let emptyCart = []
            localStorage.setItem(cartName, JSON.stringify(emptyCart))
            setCart(emptyCart)
        }
    }, [])

    function getCartTotal() {
        const total = 0;
        cart.forEach((data, idx) => {
            total += data.sale_price - data.discountPercent / 100 * data.sale_price
        })
        return total;
    }

    function getCartCount() {
        return cart.length;
    }

    function findCartItem(id) {
        let index = null;
        let res = []
        cart.every((item,idx)=>{
            if(item.id == id) {
                index = idx
                res.push(item)
                return false
            }
            return true
        })

        return {
            data: res,
            index: index
        }
    }

    function hasStock(item, qty) {
        let found = findCartItem(item.id).data[0] || item
        let cartCount = found.cartCount || 0
        if((cartCount + qty)>item.stock) {
            return false
        }
        return true
    }

    function addToCart(item, qty) {
        let found = findCartItem(item.id)
        if (found.data.length > 0) {
            return updateCart(found, qty)
        }
        if(!hasStock(item, qty)) {
            swal("Cart Invalid", "Cart amount exceeds available stock")
            return false
        }
        item.cartCount = qty
        let newCart = [...cart, item]
        setCart(newCart)
        localStorage.setItem(cartName, JSON.stringify(newCart))
        return true
    }

    function updateCart(data, qty) {
        const modified = data.data[0]
        if(!hasStock(modified,qty)) {
            swal("Cart Invalid", "Cart amount exceeds available stock")
            return
        }
        modified.cartCount = Number(modified.cartCount) + Number(qty)
        // alert(modified.cartCount)
        cart[data.index] = modified
        setCart(cart)
        localStorage.setItem(cartName, JSON.stringify(cart))
        return true
    }

    function updateCartQuantity(data, qty) {
       let found = findCartItem(data.id)
       let oldItem = found.data[0]
       if(data.stock < qty) {
        return
       }
       oldItem.cartCount = qty
       let newCart = cart
       newCart[found.index] = oldItem
       localStorage.setItem(cartName, JSON.stringify(cart))
       setCart(JSON.parse(localStorage.getItem(cartName)))
    }

    function deleteCart(id) {
        let newCart = cart.filter((item)=>item.id!=id)
        console.log()
        localStorage.setItem(cartName, JSON.stringify(newCart))
        setCart(newCart)
    }

    function freshCart() {
        setCart([])
        localStorage.setItem(cartName, JSON.stringify([]))
    }

    return (
        <GDCartContext.Provider value={{cart, getCartTotal, getCartCount, addToCart, updateCart, findCartItem, hasStock, updateCartQuantity, deleteCart, freshCart}} >
            {children}
        </GDCartContext.Provider>
    )
}
export const useCart = () => {
    const {addToCart, getCartTotal, cart, getCartCount, hasStock, updateCartQuantity, deleteCart, freshCart} = useContext(GDCartContext)
    return {
        addToCart, getCartTotal, cart, getCartCount, hasStock, updateCartQuantity, deleteCart, freshCart
    }
}