import React, {useEffect, useRef, useState} from 'react'
import {useLocation, useNavigate} from 'react-router';
// import KhaltiPackage from '../../khalti/KhaltiPackage.js';
import KhaltiImg from '../../../../images/payment/khalti.png'
import {payKhaltiConfig, payWithKhalti} from '../../../../features/payment/paymentService.js';
import {useChkLogin} from '../../../../middlewares/LoginMiddleware.js';
import KhaltiPaymentComponent from '../../../../features/khalti/components/KhaltiPaymentComponent.jsx';
import {packageBookingVerification} from '../../../../features/payment/verifications.js';
import swal from 'sweetalert';

const PackagePayment = ({
                            user,
                            pkg,
                            prepay,
                            months,
                            calcRes,
                            interval,
                            calculateFee,
                            additionalPayment,
                            setAdditionalPayment,
                            packagefee
                        }) => {
    const {token, data: userData} = useChkLogin()
    const location = useLocation()
    const navigate = useNavigate()

    const verifyPayment = (payload) => {
        const {amount, mobile, pidx, purchase_order_id, purchase_order_name, transaction_id, txnId} = payload
        const reqBody = {
            pidx: pidx,
            amount: amount,
            id: pkg.id,
            payment_interval: interval,
            prepay_status: prepay === true ? 1 : 0,
            monthly_amount: calcRes.totalMonth
        }
        return packageBookingVerification(reqBody, token)
    }

    const onSuccess = (response) => {
        if (response.data.success) {
            swal({
                title: response.data.success,
                icon: "success",
                closeOnClickOutside: false
            }).then((value) => {
                if (value) {
                    navigate(window.location.pathname)
                }
            });
        }
        if (response.data.error) {
            swal({
                title: response.data.error,
                icon: "warning",
                closeOnClickOutside: false
            }).then((value) => {
                if (value) {
                    navigate("/book-package/payment", {state: {fee: calculateFee, id: pkg.id}})
                }
            });
        }
    }

    const onFailure = (error) => {
        swal({
            title: "Request Fail",
            icon: "warning",
            closeOnClickOutside: false
        });
    }

    useEffect(() => {
        if (location.state?.pay) {
            setTimeout(() => {
                document.getElementById('scrollToElem').scrollIntoView()
            }, 100)
        }
    }, [location])
    return (
        <>
            <h3 style={{fontWeight: '700'}} id={'scrollToElem'}>Payment Status
                {pkg.status === 0 || prepay === true || pkg.grace_period === 1 ?
                    <div id="pay">
                        {pkg.status === 0 &&
                            <span className='badge badge-danger ms-2' style={{fontSize: '14px'}}>Payment Due</span>
                        }
                        {prepay === true &&
                            <span className='badge badge-success ms-2' style={{fontSize: '14px'}}>Completed</span>
                        }
                        {pkg.grace_period === 1 &&
                            <span className='badge badge-warning ms-2' style={{fontSize: '14px'}}>Extended</span>
                        }
                        {(user.member_type === 'Primary Member' || user.member_type == null) &&
                            <div className='card my-2'>
                                <div className="card-body py-2">
                                    {pkg.status === 0 || pkg.grace_period === 1 ?
                                        <h4 style={{fontWeight: '400', borderBottom: '2px solid #063b9d'}}>Subscription
                                            Payment</h4> : ''
                                    }
                                    {prepay === true &&
                                        <h4 style={{fontWeight: '400', borderBottom: '2px solid #063b9d'}}>Subscription
                                            Prepayment</h4>
                                    }
                                    <table className="table" style={{fontSize: '16px'}}>
                                        <tbody>
                                        <tr>
                                            <th>Total Family Size</th>
                                            <td>{packagefee?.family_size}</td>
                                        </tr>
                                        {pkg.payment.length === 0 &&
                                            <tr>
                                                {pkg.renew_status >= 2 ?
                                                    <th>Continuation Fee/Family</th>
                                                    :
                                                    <th>Registration Fee/Family</th>
                                                }
                                                <td>Rs. {packagefee?.one_registration_fee}</td>
                                                {/* {pkg.renew_status === 1 && <td>Rs. {pkg.familyfee.one_registration_fee}</td>}
                                                {pkg.renew_status === 2 && <td>Rs. {pkg.familyfee.two_continuation_fee}</td>}
                                                {pkg.renew_status === 3 && <td>Rs. {pkg.familyfee.three_continuation_fee}</td>} */}

                                            </tr>
                                        }
                                        <tr>
                                            <th>Monthly Fee/Person</th>
                                            <td>Rs. {packagefee?.one_monthly_fee}</td>
                                            {/* {pkg.renew_status === 1 && <td>Rs. {pkg.familyfee.one_monthly_fee}</td>}
                                                {pkg.renew_status === 2 && <td>Rs. {pkg.familyfee.two_monthly_fee}</td>}
                                                {pkg.renew_status === 3 && <td>Rs. {pkg.familyfee.three_monthly_fee}</td>} */}
                                        </tr>
                                        <tr>
                                            <th>Select Payment Interval</th>
                                            <td>
                                                <select className='form-select' name="payment_interval"
                                                        onChange={calculateFee} required>
                                                    <option value="">--Select--</option>
                                                    {months === 0 ? <option value="Yearly">Yearly</option> : ''}
                                                    {months >= 0 && months < 7 ?
                                                        <option value="Half_Yearly">Half Yearly</option> : ''}
                                                    {months >= 0 && months < 10 ?
                                                        <option value="Quarterly">Quarterly</option> : ''}
                                                    {months >= 0 ? <option value="Monthly">Monthly</option> : ''}
                                                </select>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <div className="text-end my-2" style={{fontSize: '16px'}}>
                                        <p style={{fontWeight: '400'}}><b>Total Monthly Fee:</b> Rs.{calcRes.totalMonth}
                                        </p>
                                        <p style={{fontWeight: '400'}}><b>Total Payable Monthly Fee After
                                            Discount:</b> Rs.{calcRes.discountTotal} ({calcRes.discount}% Discount) </p>
                                        <p style={{fontWeight: '400'}}><b>Total Payable Amount:</b> Rs. {calcRes.result}
                                        </p>
                                    </div>
                                    {pkg.additonal_members > 0 && additionalPayment &&
                                        <>
                                            <h4 style={{
                                                fontWeight: '400',
                                                borderBottom: '2px solid #063b9d'
                                            }}>Additional Member Payment</h4>
                                            <table className="table" style={{fontSize: '16px'}}>
                                                <tbody>
                                                <tr>
                                                    <th>Total Additional Family Members</th>
                                                    <td>{pkg.additonal_members}</td>
                                                </tr>
                                                <tr>
                                                    <th>Daily Fee</th>
                                                    <td>Rs. {additionalPayment.daily_fee} </td>
                                                </tr>
                                                <tr>
                                                    <th>Total Payment Days</th>
                                                    <td>{additionalPayment.payment_days} days</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            <div className="text-end my-2" style={{fontSize: '16px'}}>
                                                <p style={{fontWeight: '400'}}><b>Total
                                                    Amount:</b> Rs. {additionalPayment.total_payment}</p>
                                            </div>
                                        </>
                                    }
                                    {calcRes.result !== 0 ?
                                        <table className='table' style={{fontSize: '16px'}}>
                                            <thead>
                                            <tr>
                                                <td>Total Amount:
                                                    Rs. {calcRes.total !== 0 ? calcRes.total : calcRes.result}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Pay Via:
                                                    {/* <KhaltiPackage monthly_amount={calcRes.totalMonth} amount={calcRes.total !== 0 ? calcRes.total : calcRes.result} id={pkg.id} interval={interval} prepay={prepay === true ? 1 : 0} redirectLink={window.location.pathname} setAdditionalPayment={setAdditionalPayment} /> */}

                                                    <KhaltiPaymentComponent
                                                        config={{
                                                            name: 'Sabin Lawaju',
                                                            email: 'lawazu.sabin@gmail.com',
                                                            phone: '9860335814',
                                                            purchaseOrderId: '1231',
                                                            purchaseOrderName: 'asdjklasd asda',
                                                            amount: 1000 //calcRes.total !== 0 ? calcRes.total : calcRes.result
                                                        }}
                                                        onVerify={verifyPayment}
                                                        onSuccess={onSuccess}
                                                        onFailure={onFailure}
                                                        render={
                                                            (isLoading, isSubmitting, makePayment) => (
                                                                <button onClick={makePayment}>
                                                                    <img src={KhaltiImg} alt=""/>
                                                                </button>
                                                            )
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                            </thead>
                                        </table>
                                        :
                                        ''
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    :
                    <span className='badge badge-success ms-2' style={{fontSize: '14px'}}>Completed</span>
                }
            </h3>
        </>
    )
}

export default PackagePayment
