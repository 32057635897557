import React from "react";
import {useEffect} from "react";
import {useState} from "react";
import {useForm} from "react-hook-form";
import {useLocation, useNavigate} from "react-router-dom";
import swal from "sweetalert";
import axios from "../../api.js";
import Loader from "../Loader.js";

const ServiceBooking = ({service}) => {
    const [loading, setLoading] = useState(true);
    // const location = useLocation();
    // const service = location.state.service;
    const token = localStorage.getItem("token");
    const [user, setUser] = useState(null);
    let navigate = useNavigate();

    useEffect(() => {
        axios
            .get("/api/admin/user-profile", {
                headers: {Authorization: `Bearer ${token}`},
            })
            .then((res) => {
                // console.log();
                setUser(res.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log();
            });
    }, [token]);

    const {register, handleSubmit} = useForm();

    const onSubmit = (data) => {
        axios
            .post("/api/admin/book-lab-test", data, {
                headers: {Authorization: `Bearer ${token}`},
            })
            .then((res) => {
                //console.log();
                swal({
                    title: "Lab Test Booked!",
                    icon: "success",
                    button: "Proceed for Payment",
                }).then((value) => {
                    if (value) {
                        navigate("/book-lab-test/payment", {state: {fee: service.price, id: res.data.id}})
                    }
                });
            })
            .catch((err) => {
                console.log();
            });
    };

    return (
        <>
            {loading ? (
                <div className="text-center">
                    <Loader/>
                </div>
            ) : (
                <div className="container my-5">
                    {user && (
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <h3 style={{color: "#0259A7"}}>
                                {" "}
                                {service.service_name} Booking Details
                            </h3>
                            <input
                                type="hidden"
                                {...register("service_id")}
                                value={service.id}
                            />
                            <div className="row my-5 g-3">
                                <div className="form-group">
                                    <label htmlFor="">Date</label>
                                    <div className="col-md-5">
                                        <input
                                            type="date"
                                            {...register("date")}
                                            className="form-control" required
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Time</label>
                                    <div className="col-md-5">
                                        <input
                                            type="time"
                                            {...register("time")}
                                            className="form-control" required
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Amount</label>
                                    <div className="col-md-5">
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">
                          Rs.
                        </span>
                                            </div>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={service.price}
                                                readOnly
                                                aria-describedby="basic-addon1"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button type="submit" className="btn btn-primary py-2 px-3">
                                Book Service
                            </button>
                        </form>
                    )}
                </div>
            )}
        </>
    );
};

export default ServiceBooking;
