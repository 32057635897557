/**
 * Adds comma to the number
 * @param number
 * @param format
 * @returns number with comma added
 */
const addCommasToNumber = (number, format = "en-IN") => {
    if (Number.isNaN(number)) {
        console.error('Input is not a valid number');
        return '';
    }
    return number.toLocaleString(format);
};

/**
 * REMOVES EMPTY OR UNDEFINED KEYS FROM AN OBJECT
 * @param params
 * @returns
 */
const filterParams = (params) => {
    Object.keys(params).forEach((key) => {
        if (
            params[key] === undefined ||
            params[key] === "" ||
            params[key]?.length === 0
        ) {
            delete params[key];
        }
    });
    return params;
};


const filterFormData = (formData) => {
    const newFormData = formData;
    const entries = formData.entries();
    let currentEntry = entries.next();

    while (!currentEntry.done) {
        const [key, value] = currentEntry.value;
        if (value !== undefined && value !== null && value !== '') {
            formData.set(key, value);
        } else if (value === '') {
            formData.delete(key);
        }
        currentEntry = entries.next();
    }
    return newFormData;
}

export {filterParams, filterFormData, addCommasToNumber}
