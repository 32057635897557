import React from 'react'
import {Box} from "@mui/material"
import './TermsandCondition.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleArrowUp} from "@fortawesome/free-solid-svg-icons";

export default function TermsandConditions() {
    let mybutton = document.getElementById("myBtn");
    window.onscroll = function () {
        scrollFunction()
    };

    function scrollFunction() {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            mybutton.style.display = "block";
        } else {
            mybutton.style.display = "none";
        }

    }

    function topFunction() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    return (
        <div>
            <Box className="termsandcondition">
                {/* <img src={Page404} alt="" /> */}
                <h1>Conditions of Use </h1>
                <h3>Last updated: September 14, 2022 </h3>

                Welcome to Amazon.com. Amazon.com Services LLC and/or its affiliates ("Amazon") provide website features
                and other products and services to you when you visit or shop at Amazon.com, use Amazon products or
                services, use Amazon applications for mobile, or use software provided by Amazon in connection with any
                of the foregoing (collectively, "Amazon Services"). By using the Amazon Services, you agree, on behalf
                of yourself and all members of your household and others who use any Service under your account, to the
                following conditions.
                <h2>Please read these conditions carefully.</h2>

                We offer a wide range of Amazon Services, and sometimes additional terms may apply. When you use an
                Amazon Service (for example, Your Profile, Gift Cards, Amazon Video, Your Media Library, Amazon devices,
                or Amazon applications) you also will be subject to the guidelines, terms and agreements applicable to
                that Amazon Service ("Service Terms"). If these Conditions of Use are inconsistent with the Service
                Terms, those Service Terms will control.

                <h2>PRIVACY</h2>
                Please review our Privacy Notice, which also governs your use of Amazon Services, to understand our
                practices.

                <h2>ELECTRONIC COMMUNICATIONS </h2>
                When you use Amazon Services, or send e-mails, text messages, and other communications from your desktop
                or mobile device to us, you may be communicating with us electronically. You consent to receive
                communications from us electronically, such as e-mails, texts, mobile push notices, or notices and
                messages on this site or through the other Amazon Services, such as our Message Center, and you can
                retain copies of these communications for your records. You agree that all agreements, notices,
                disclosures, and other communications that we provide to you electronically satisfy any legal
                requirement that such communications be in writing.

                <h2>COPYRIGHT</h2>
                All content included in or made available through any Amazon Service, such as text, graphics, logos,
                button icons, images, audio clips, digital downloads, data compilations, and software is the property of
                Amazon or its content suppliers and protected by United States and international copyright laws. The
                compilation of all content included in or made available through any Amazon Service is the exclusive
                property of Amazon and protected by U.S. and international copyright laws.

                <h2>TRADEMARKS </h2>
                Click here to see a non-exhaustive list of Amazon trademarks. In addition, graphics, logos, page
                headers, button icons, scripts, and service names included in or made available through any Amazon
                Service are trademarks or trade dress of Amazon in the U.S. and other countries. Amazon's trademarks and
                trade dress may not be used in connection with any product or service that is not Amazon's, in any
                manner that is likely to cause confusion among customers, or in any manner that disparages or discredits
                Amazon. All other trademarks not owned by Amazon that appear in any Amazon Service are the property of
                their respective owners, who may or may not be affiliated with, connected to, or sponsored by Amazon.

                <h2>LICENSE AND ACCESS</h2>
                Subject to your compliance with these Conditions of Use and any Service Terms, and your payment of any
                applicable fees, Amazon or its content providers grant you a limited, non-exclusive, non-transferable,
                non-sublicensable license to access and make personal and non-commercial use of the Amazon Services.
                This license does not include any resale or commercial use of any Amazon Service, or its contents; any
                collection and use of any product listings, descriptions, or prices; any derivative use of any Amazon
                Service or its contents; any downloading, copying, or other use of account information for the benefit
                of any third party; or any use of data mining, robots, or similar data gathering and extraction tools.
                All rights not expressly granted to you in these Conditions of Use or any Service Terms are reserved and
                retained by Amazon or its licensors, suppliers, publishers, rightsholders, or other content providers.
                No Amazon Service, nor any part of any Amazon Service, may be reproduced, duplicated, copied, sold,
                resold, visited, or otherwise exploited for any commercial purpose without express written consent of
                Amazon. You may not frame or utilize framing techniques to enclose any trademark, logo, or other
                proprietary information (including images, text, page layout, or form) of Amazon without express written
                consent. You may not use any meta tags or any other "hidden text" utilizing Amazon's name or trademarks
                without the express written consent of Amazon. You may not misuse the Amazon Services. You may use the
                Amazon Services only as permitted by law. The licenses granted by Amazon terminate if you do not comply
                with these Conditions of Use or any Service Terms.

                <h2>YOUR ACCOUNT </h2>
                You may need your own Amazon account to use certain Amazon Services, and you may be required to be
                logged in to the account and have a valid payment method associated with it. If there is a problem
                charging your selected payment method, we may charge any other valid payment method associated with your
                account. Visit Your Payments to manage your payment options. You are responsible for maintaining the
                confidentiality of your account and password and for restricting access to your account, and you agree
                to accept responsibility for all activities that occur under your account or password. Amazon does sell
                products for children, but it sells them to adults, who can purchase with a credit card or other
                permitted payment method. If you are under 18, you may use the Amazon Services only with involvement of
                a parent or guardian. Parents and guardians may create profiles for teenagers in their Amazon Household.
                Alcohol listings on Amazon are intended for adults. You must be at least 21 years of age to purchase
                alcohol, or use any site functionality related to alcohol. Amazon reserves the right to refuse service,
                terminate accounts, terminate your rights to use Amazon Services, remove or edit content, or cancel
                orders in its sole discretion.

                <h2>REVIEWS, COMMENTS, COMMUNICATIONS, AND OTHER CONTENT</h2>
                You may post reviews, comments, photos, videos, and other content; send e-cards and other
                communications; and submit suggestions, ideas, comments, questions, or other information, so long as the
                content is not illegal, obscene, threatening, defamatory, invasive of privacy, infringing of
                intellectual property rights (including publicity rights), or otherwise injurious to third parties or
                objectionable, and does not consist of or contain software viruses, political campaigning, commercial
                solicitation, chain letters, mass mailings, or any form of "spam" or unsolicited commercial electronic
                messages. You may not use a false e-mail address, impersonate any person or entity, or otherwise mislead
                as to the origin of a card or other content. Amazon reserves the right (but not the obligation) to
                remove or edit such content, but does not regularly review posted content.
                If you do post content or submit material, and unless we indicate otherwise, you grant Amazon a
                nonexclusive, royalty-free, perpetual, irrevocable, and fully sublicensable right to use, reproduce,
                modify, adapt, publish, perform, translate, create derivative works from, distribute, and display such
                content throughout the world in any media. You grant Amazon and sublicensees the right to use the name
                that you submit in connection with such content, if they choose. You represent and warrant that you own
                or otherwise control all of the rights to the content that you post; that the content is accurate; that
                use of the content you supply does not violate this policy and will not cause injury to any person or
                entity; and that you will indemnify Amazon for all claims resulting from content you supply. Amazon has
                the right but not the obligation to monitor and edit or remove any activity or content. Amazon takes no
                responsibility and assumes no liability for any content posted by you or any third party.

                <h2> INTELLECTUAL PROPERTY COMPLAINTS </h2>
                Amazon respects the intellectual property of others. If you believe that your intellectual property
                rights are being infringed, please follow our Notice and Procedure for Making Claims of Copyright
                Infringement.

                <h2>RISK OF LOSS </h2>
                All purchases of physical items from Amazon are made pursuant to a shipment contract. This means that
                the risk of loss and title for such items pass to you upon our delivery to the carrier.

                RETURNS, REFUNDS AND TITLE
                Amazon does not take title to returned items until the item arrives at our fulfillment center. At our
                discretion, a refund may be issued without requiring a return. In this situation, Amazon does not take
                title to the refunded item. For more information about our returns and refunds, please see our Returns
                Center.

                PRODUCT DESCRIPTIONS
                Amazon attempts to be as accurate as possible. However, Amazon does not warrant that product
                descriptions or other content of any Amazon Service is accurate, complete, reliable, current, or
                error-free. If a product offered by Amazon itself is not as described, your sole remedy is to return it
                in unused condition.

                <h2>PRICING </h2>
                "List Price" means the suggested retail price of a product as provided by a manufacturer, supplier, or
                seller. We regularly check List Prices against prices recently found on Amazon and other retailers.
                Certain products may have a "Was Price" displayed, which is determined using recent price history of the
                product on Amazon.
                With respect to items sold by Amazon, we cannot confirm the price of an item until you order. Despite
                our best efforts, a small number of the items in our catalog may be mispriced. If the correct price of
                an item sold by Amazon is higher than our stated price, we will, at our discretion, either contact you
                for instructions before shipping or cancel your order and notify you of such cancellation. Other
                merchants may follow different policies in the event of a mispriced item.

                We generally do not charge your credit card until after your order has entered the shipping process or,
                for digital products, until we make the digital product available to you.

                <h2>APP PERMISSIONS </h2>
                When you use apps created by Amazon, such as the Amazon App or Kindle App, you may grant certain
                permissions to us for your device. Most mobile devices provide you with information about these
                permissions. To learn more about these permissions, click here.

                SANCTIONS AND EXPORT POLICY
                You may not use any Amazon Service if you are the subject of U.S. sanctions or of sanctions consistent
                with U.S. law imposed by the governments of the country where you are using Amazon Services. You must
                comply with all U.S. or other export and re-export restrictions that may apply to goods, software
                (including Amazon Software), technology, and services.

                <h2>OTHER BUSINESSES </h2>
                Parties other than Amazon operate stores, provide services or software, or sell product lines through
                the Amazon Services. In addition, we provide links to the sites of affiliated companies and certain
                other businesses. If you purchase any of the products or services offered by these businesses or
                individuals, you are purchasing directly from those third parties, not from Amazon. We are not
                responsible for examining or evaluating, and we do not warrant, the offerings of any of these businesses
                or individuals (including the content of their Web sites). Amazon does not assume any responsibility or
                liability for the actions, product, and content of all these and any other third parties. You should
                carefully review their privacy statements and other conditions of use.

                <h2>DISCLAIMER OF WARRANTIES OR LIMITATION OF LIABILITY </h2>
                THE AMAZON SERVICES AND ALL INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) AND OTHER
                SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE AMAZON SERVICES ARE PROVIDED BY
                AMAZON ON AN "AS IS" AND "AS AVAILABLE" BASIS, UNLESS OTHERWISE SPECIFIED IN WRITING. AMAZON MAKES NO
                REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE AMAZON
                SERVICES, OR THE INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR OTHER SERVICES
                INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE AMAZON SERVICES, UNLESS OTHERWISE SPECIFIED
                IN WRITING. YOU EXPRESSLY AGREE THAT YOUR USE OF THE AMAZON SERVICES IS AT YOUR SOLE RISK.

                TO THE FULL EXTENT PERMISSIBLE BY LAW, AMAZON DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING,
                BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. AMAZON
                DOES NOT WARRANT THAT THE AMAZON SERVICES, INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING
                SOFTWARE) OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE AMAZON SERVICES,
                AMAZON'S SERVERS OR ELECTRONIC COMMUNICATIONS SENT FROM AMAZON ARE FREE OF VIRUSES OR OTHER HARMFUL
                COMPONENTS. TO THE FULL EXTENT PERMISSIBLE BY LAW, AMAZON WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND
                ARISING FROM THE USE OF ANY AMAZON SERVICE, OR FROM ANY INFORMATION, CONTENT, MATERIALS, PRODUCTS
                (INCLUDING SOFTWARE) OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH ANY AMAZON
                SERVICE, INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL
                DAMAGES, UNLESS OTHERWISE SPECIFIED IN WRITING.

                <h2>DISPUTES </h2>
                Any dispute or claim relating in any way to your use of any Amazon Service will be adjudicated in the
                state or Federal courts in King County, Washington, and you consent to exclusive jurisdiction and venue
                in these courts. We each waive any right to a jury trial.

                <h2>APPLICABLE LAW </h2>
                By using any Amazon Service, you agree that applicable federal law, and the laws of the state of
                Washington, without regard to principles of conflict of laws, will govern these Conditions of Use and
                any dispute of any sort that might arise between you and Amazon.

                SITE POLICIES, MODIFICATION, AND SEVERABILITY
                Please review our other policies, such as our pricing policy, posted on this site. These policies also
                govern your use of Amazon Services. We reserve the right to make changes to our site, policies, Service
                Terms, and these Conditions of Use at any time. If any of these conditions shall be deemed invalid,
                void, or for any reason unenforceable, that condition shall be deemed severable and shall not affect the
                validity and enforceability of any remaining condition.

                <h2>OUR ADDRESS </h2>
                Amazon.com, Inc.
                P.O. Box 81226
                Seattle, WA 98108-1226
                ADDITIONAL AMAZON SOFTWARE TERMS
                The following terms (“Software Terms”) apply to any software (including any updates or upgrades to the
                software) and any related documentation we make available to you in connection with Amazon Services (the
                "Amazon Software").
                <h5>1.Use of the Amazon Software.</h5> You may use Amazon Software solely for purposes of enabling you
                to use the Amazon Services as provided by Amazon, and as permitted by these Conditions of Use and any
                Service Terms. You may not incorporate any portion of the Amazon Software into other programs or compile
                any portion of it in combination with other programs, or otherwise copy (except to exercise rights
                granted in this section), modify, create derivative works of, distribute, assign any rights to, or
                license the Amazon Software in whole or in part. All software used in any Amazon Service is the property
                of Amazon or its software suppliers and is protected by United States and international copyright laws.
                <h5>2.Use of Third Party Services.</h5> When you use the Amazon Software, you may also be using the
                services of one or more third parties, such as a wireless carrier or a mobile software provider. Your
                use of these third party services may be subject to the separate policies, terms of use, and fees of
                these third parties.
                <h5>3.No Reverse Engineering.</h5> You may not reverse engineer, decompile or disassemble, tamper with,
                or bypass any security associated with the Amazon Software, whether in whole or in part.
                <h5>4.Updates.</h5> We may offer automatic or manual updates to the Amazon Software at any time and
                without notice to you.
                <h5>5.Government End Users.</h5> If you are a U.S. Government end user, we are licensing the Amazon
                Software to you as a "Commercial Item" as that term is defined in the U.S. Code of Federal Regulations
                (see 48 C.F.R. § 2.101), and the rights we grant you to the Amazon Software are the same as the rights
                we grant to all others under these Conditions of Use.
                <h5>6.Conflicts. </h5> In the event of any conflict between these Conditions of Use and any other Amazon
                or third-party terms applicable to any portion of Amazon Software, such as open-source license terms,
                such other terms will control as to that portion of the Amazon Software and to the extent of the
                conflict.
                <h2> HOW TO SERVE A SUBPOENA OR OTHER LEGAL PROCESS </h2>
                Amazon accepts service of subpoenas or other legal process only through Amazon's national registered
                agent, Corporation Service Company (CSC). Subpoenas or other legal process may be served by sending them
                to CSC at the following address:

                Amazon.com, Inc.
                Corporation Service Company
                300 Deschutes Way SW, Suite 208 MC-CSC1
                Tumwater, WA 98501
                Attn: Legal Department – Legal Process
                Please note also that providing detailed and accurate information at the outset will facilitate
                efficient processing of your request. That information will include, for example, e-mail and/or credit
                card number used to make purchases for retail purchase information; the name, e-mail, and physical
                address of a seller for seller information; device serial number for Amazon devices; and IP address and
                complete time stamps.

                001
                <div className="last"></div>
                <button onClick={topFunction} id="myBtn"><FontAwesomeIcon icon={faCircleArrowUp}/></button>


            </Box>


        </div>
    )
}
