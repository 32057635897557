import React from "react";
import {useChkLogin} from "../../../../middlewares/LoginMiddleware";
import SchoolProfileForm from "../../schoolProfileForm/SchoolProfileForm";

function EditSchoolProfile() {
    const {token, data} = useChkLogin();
    let compProfile = data?.school_profile;
    // useEffect(()=> {
    //     axios.get('https://cors-anywhere.herokuapp.com/https://demo.ghargharmadoctor.com/storage/images/call-doctor-concept-doctors-answer-patient-questions-phone_1150-50289-removebg-preview_1680432004.png').then(res=>console.log("Image fetch response", res))
    // },[])
    let state = {
        owner_name: null,
        company_name: null,
        company_address: null,
        company_start_date: null,
        contact_number: null,
        pan_number: null,
        description: null,
        company_image: null,
        paper_work_pdf: null,
        types: "school",
    };
    // if(data?.school_profile.status!='rejected') {
    //     state = {
    //         owner_name: compProfile.owner_name,
    //         company_name: compProfile.company_name,
    //         company_address: compProfile.company_address,
    //         company_start_date: compProfile.company_start_date,
    //         contact_number: compProfile.contact_number,
    //         pan_number: compProfile.pan_number,
    //         description: compProfile.description,
    //         company_image: compProfile.company_image_path,
    //         paper_work_pdf: compProfile.paper_work_pdf_path,
    //         types: 'school'
    //     }
    // }
    state = {
        owner_name: compProfile.owner_name,
        company_name: compProfile.company_name,
        company_address: compProfile.company_address,
        company_start_date: compProfile.company_start_date,
        contact_number: compProfile.contact_number,
        pan_number: compProfile.pan_number,
        description: compProfile.description,
        company_image: compProfile.company_image_path,
        paper_work_pdf: compProfile.paper_work_pdf_path,
        types: "school",
    };
    return (
        <SchoolProfileForm
            state={state}
            type="edit"
            apiLink={`api/company-school-profile/${compProfile.id}`}
            navigateTo={"/school"}
        />
    );
}

export default EditSchoolProfile;
