import React, {useContext, useState} from 'react'
import GDLogo from './GDLogo'
import Logo from "../../../../images/blue-logo.png"
import SearchBar from './SearchBar'
import "./Header.scss"
import {Link, useLocation, useNavigate} from 'react-router-dom'
import axios from '../../../api'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronDown} from '@fortawesome/free-solid-svg-icons'
import {LoginStateContext} from '../../../../contexts/LoginStateContext'
import {
    Avatar,
    Badge,
    Box,
    Button,
    ButtonBase,
    Card,
    CardMedia,
    ClickAwayListener,
    Grid,
    Grow,
    IconButton,
    ListItemIcon,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Stack,
    useMediaQuery
} from '@mui/material'
import {GDStoreContext} from '../../../../contexts/GDStoreContext'
import swal from 'sweetalert'
import {
    DashboardOutlined,
    FavoriteBorderOutlined,
    KeyboardArrowDownOutlined,
    LockOutlined,
    LoginOutlined,
    ManageAccountsOutlined,
    MenuOutlined,
    PowerSettingsNew,
    ShoppingBagOutlined,
    ShoppingCartOutlined
} from '@mui/icons-material'
import {useRef} from 'react'
import {useCart} from '../../../../contexts/provider/GDCartProvider'

function VendorCategory({vendor_type}) {
    const [category, setCategory] = React.useState([])
    const loginContext = useContext(LoginStateContext)


    React.useEffect(() => {
        axios.get("api/categories/vendortype?vendor_type_id=" + vendor_type)
            .then(res => {
                setCategory(res.data)
            })
    }, [])

    if (category.length == 0) {
        return
    }

    return (
        <div className="sub-header-inner" id={"inner-item-" + vendor_type}>
            <div className="sub-header-inner-item-wrap">
                <div className="container">
                    <div className="py-4">
                        <div className="sub-header-inner-item">
                            <ul>
                                {
                                    category.map((cat) => {
                                        return (
                                            <li><Link to={`/gd-store/filter/${vendor_type}/${cat.id}`}
                                                      className="text-decoration-none text-dark">{cat.name}</Link></li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

function Header() {
    const [vendorType, setVendorType] = React.useState([])
    const loginContext = useContext(LoginStateContext)
    const storeContext = useContext(GDStoreContext)
    const location = useLocation()
    const gdCart = useCart()
    const mobileScreen = useMediaQuery('(max-width:600px)')

    const navigate = useNavigate()

    const loginRef = useRef(null)
    const [open, setOpen] = useState(false)

    React.useEffect(() => {
        axios.get("api/vendor-type").then((res) => {
            setVendorType(res.data);
        });

        if (loginContext.state.loggedIn) {
            axios.get("api/admin/wishlist", {
                headers: {Authorization: `Bearer ${loginContext.state.token}`},
            })
                .then(res => {
                    storeContext.setWishlist(res.data)
                })
        }
    }, []);

    React.useEffect(() => {
        let links = document.getElementsByClassName('sub-header-item-link')
        let subHeadCover = document.getElementsByClassName('sub-header-cover')[0]

        function removeActive(elem) {
            console.log()
            var subHeaderWrapper = document.getElementsByClassName('sub-header-inner-wrapper')[0]
            subHeaderWrapper.classList.remove('active')
            for (var i = 0; i < links.length; i++) {
                links[i].classList.remove('active')
            }
        }

        function linkClickListen(e) {
            var target = this.getAttribute('data-target')
            var elem = document.querySelector(`${target}`)
            if (elem === null) {
                console.log()
                return;
            }
            var subHeaderWrapper = document.getElementsByClassName('sub-header-inner-wrapper')[0]
            subHeaderWrapper.classList.add('active')

            let hasActive = this.classList.contains('active')

            if (this.classList.contains('active')) {
                subHeaderWrapper.classList.remove('active')
            }

            var innerHeaders = document.getElementsByClassName('sub-header-inner');
            var headerLinks = document.getElementsByClassName('sub-header-item-link')
            for (let i = 0; i < headerLinks.length; i++) {
                headerLinks[i].classList.remove('active');
            }
            for (let i = 0; i < innerHeaders.length; i++) {
                innerHeaders[i].classList.remove('active')
            }

            elem.classList.add('active')
            if (!hasActive) {
                this.classList.add('active')
            }
        }

        for (let i = 0; i < links.length; i++) {
            links[i].addEventListener('click', linkClickListen)
        }

        subHeadCover.addEventListener('click', removeActive)

        removeActive()

        return () => {
            for (let i = 0; i < links.length; i++) {
                links[i].removeEventListener('click', linkClickListen)
            }
        }
    }, [vendorType, location])

    const logout = () => {
        localStorage.clear();
        swal({
            title: "Logged out successfully!",
            icon: "success",
        }).then((value) => {
            if (value) {
                // console.log()
                // navigate("/",{ replace: true });
                loginContext.logout()
            }
        });
    }

    function handleClose(e) {
        if (loginRef.current && loginRef.current.contains(e.target)) {
            return;
        }
        setOpen(false)
    }

    function handleToggle() {
        setOpen((prev) => !prev)
    }

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    return (
        <div className="header">
            <div className="container">
                <div className="row w-100 align-items-center py-1 g-2">
                    <div className="col-2 order-lg-12">
                        <Link to="/" style={{display: "inline-block"}}><GDLogo/></Link>
                    </div>
                    <div className="col-10 col-lg-4 order-lg-1 justify-self-end">
                        <div className="header-items d-flex align-items-center justify-content-end">
                            <Badge badgeContent={gdCart.getCartCount()} color="primary">
                                <Link to="cart"
                                      className="header-item d-flex flex-column justify-content-center align-items-center text-decoration-none">
                                    <ShoppingCartOutlined sx={{color: 'black', fontSize: '2rem'}}/>
                                    <span className='text-muted'>Cart</span>
                                </Link>
                            </Badge>

                            <Badge badgeContent={storeContext.wishlist.length} color="secondary">
                                <Link to="wishlist"
                                      className="header-item d-flex flex-column justify-content-center align-items-center text-decoration-none">
                                    <FavoriteBorderOutlined sx={{color: 'black', fontSize: '2rem'}}/>
                                    <span className='text-muted'>Wishlist</span>
                                </Link>
                            </Badge>

                            <Link to="/user/orders"
                                  className="header-item d-flex flex-column justify-content-center align-items-center text-decoration-none">
                                <ShoppingBagOutlined sx={{color: 'black', fontSize: '2rem'}}/>
                                <span className='text-muted'>Orders</span>
                            </Link>

                            {
                                loginContext.state.loading ?
                                    <span>Checking...</span>
                                    :
                                    <>
                                        {
                                            !loginContext.state.loggedIn ?
                                                <Link to="/login"
                                                      className="header-item d-flex flex-column justify-content-center align-items-center text-decoration-none text-dark algin-self-end">
                                                    <LoginOutlined sx={{color: 'black', fontSize: '2rem'}}/>
                                                    <span className='text-muted'>Login</span>
                                                </Link>
                                                :
                                                <>
                                                    <div
                                                        className="header-item d-flex flex-column justify-content-center text-decoration-none text-dark">
                                                        <div>
                                                            <Avatar src={loginContext.state.data.image_path} sx={{
                                                                width: '2rem',
                                                                height: '2rem',
                                                                marginLeft: '.4rem'
                                                            }}/>
                                                        </div>
                                                        <div className="d-flex align-items-center" ref={loginRef}
                                                             onClick={handleToggle}>
                                                            <span
                                                                className='text-muted'>{loginContext.state.data.member.name}</span>
                                                            <KeyboardArrowDownOutlined/>
                                                        </div>
                                                    </div>
                                                    <Popper
                                                        open={open}
                                                        anchorEl={loginRef.current}
                                                        role={undefined}
                                                        placement="bottom-start"
                                                        transition
                                                        disablePortal
                                                        sx={{zIndex: '99'}}
                                                    >
                                                        {({TransitionProps, placement}) => (
                                                            <Grow
                                                                {...TransitionProps}
                                                                style={{
                                                                    transformOrigin:
                                                                        placement === 'bottom-start' ? 'left top' : 'left bottom',
                                                                }}
                                                            >
                                                                <Paper>
                                                                    <ClickAwayListener onClickAway={handleClose}>
                                                                        <MenuList
                                                                            autoFocusItem={open}
                                                                            id="composition-menu"
                                                                            aria-labelledby="composition-button"
                                                                            onKeyDown={handleListKeyDown}
                                                                        >
                                                                            <MenuItem onClick={e => {
                                                                                handleClose(e);
                                                                                navigate('/user/profile/edit')
                                                                            }}>
                                                                                <ListItemIcon>
                                                                                    <ManageAccountsOutlined
                                                                                        fontSize="small"/>
                                                                                </ListItemIcon>
                                                                                Edit Profile
                                                                            </MenuItem>
                                                                            <MenuItem onClick={e => {
                                                                                handleClose(e);
                                                                                navigate('/user/')
                                                                            }}>
                                                                                <ListItemIcon>
                                                                                    <DashboardOutlined
                                                                                        fontSize="small"/>
                                                                                </ListItemIcon>
                                                                                My Dashboard
                                                                            </MenuItem>
                                                                            <MenuItem onClick={(e) => {
                                                                                handleClose(e);
                                                                                logout()
                                                                            }}>
                                                                                <ListItemIcon>
                                                                                    <PowerSettingsNew fontSize="small"/>
                                                                                </ListItemIcon>
                                                                                Logout
                                                                            </MenuItem>
                                                                        </MenuList>
                                                                    </ClickAwayListener>
                                                                </Paper>
                                                            </Grow>
                                                        )}
                                                    </Popper>
                                                </>
                                        }
                                    </>
                            }
                        </div>
                    </div>
                    <div className="col-lg-6 order-lg-6">
                        <SearchBar/>
                    </div>
                </div>
            </div>

            <div className="sub-header-container">
                <div className="container main-sub-header">
                    <div className="sub-header-wrapper">
                        <div className="sub-header">
                            <div className="sub-header-item">
                                <Link to="/gd-store" className='text-decoration-none text-dark'>Store Home</Link>
                            </div>
                            {
                                vendorType.map((menu) =>
                                    menu.vendor_type === "Fitness Center" ?
                                        <></>
                                        :
                                        <div className="sub-header-item">
                                            <div className="sub-header-item-link"
                                                 data-target={"#inner-item-" + menu.id}>
                                                <span
                                                    className='text-decoration-none text-dark'>{menu.vendor_type}</span>
                                                <FontAwesomeIcon icon={faChevronDown} size="sm"
                                                                 className='text-muted ms-2'/>
                                            </div>
                                        </div>
                                )
                            }
                            <div className="sub-header-item">
                                <Link to="/gd-store/fitness-center" className='text-decoration-none text-dark'>Fitness
                                    Center</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="sub-header-inner-wrapper">
                    {
                        vendorType.map((typ) => {
                            if (typ.vendor_type === "Fitness Center") return
                            return (
                                <VendorCategory vendor_type={typ.id}/>
                            )
                        })
                    }
                    <div className="sub-header-cover"></div>
                </div>
            </div>
        </div>
    )

    // return (
    //     <Box className='container' py={0.3} p={0} sx={{position:'sticky', top:-5, zIndex:99, bgcolor:'white'}}>
    //         <Grid container alignItems={'center'} justifyContent='space-between' spacing={1}>
    //             <Grid item xs={1.4}>
    //                 <IconButton>
    //                     <MenuOutlined fontSize='large'/>
    //                 </IconButton>
    //             </Grid>
    //             <Grid item xs={1.4} sm={1} md={0.5}>
    //                 <Card sx={{ width: '100%' }} elevation={0}>
    //                     <CardMedia component='img' image={Logo} />
    //                 </Card>
    //             </Grid>
    //             <Grid item xs={6}>
    //                 <SearchBar />
    //             </Grid>
    //             <Grid item xs={2.5}>
    //                 <Stack direction={'row'} alignItems='center' sx={{ width: '100%', justifyContent: 'space-between' }}>
    //                     {/* <IconButton>
    //                         <ShoppingBagOutlined fontSize='large' fontColor='text.primary' />
    //                     </IconButton>
    //                     <IconButton>
    //                         <FavoriteBorderOutlined fontSize='large' />
    //                     </IconButton>

    //                     <IconButton>
    //                         <Badge badgeContent={gdCart.getCartCount()} color="primary">
    //                             <ShoppingCartOutlined fontSize='large' />
    //                         </Badge>
    //                     </IconButton> */}


    //                     <Button endIcon={<KeyboardArrowDownOutlined />}>
    //                         <Avatar src='https://www.w3schools.com/howto/img_avatar.png' />
    //                     </Button>
    //                 </Stack>
    //             </Grid>
    //         </Grid>
    //     </Box>
    // )
}

export default Header
