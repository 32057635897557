import {faCheckSquare, faMinus, faPlus, faShareNodes} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Rating, Skeleton} from '@mui/material'
import axios from '../api'
import React from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import swal from 'sweetalert'
import "./ProductDetails.scss"
import ProductQuanChanger from './ProductQuanChanger'
import ImageMagnify from '../custom_componets/ImageMagnify'

function ProductDetails({product, productLoading, addToCart}) {
    const navigate = useNavigate()
    const [cartCount, setCartCount] = React.useState(0);
    const [stateChanged, setStateChanged] = React.useState(false)
    const [outOfStock, setOutOfStock] = React.useState(false)
    const location = useLocation()

    React.useEffect(() => {
        setCartCount(0)
        setOutOfStock(false)
    }, [location])

    React.useEffect(() => {
        if (productLoading) {
            return
        }
        const cart = JSON.parse(localStorage.getItem('cart'))
        const cartItem = cart.filter((i) => {
            return i.id == product.id
        })

        const count = cartItem.length > 0 ? cartItem[0].cartCount : 0

        if (product.stock == 0 || count >= product.stock) {
            setOutOfStock(true)
        }
    }, [productLoading])

    React.useEffect(() => {
        const cart = JSON.parse(localStorage.getItem('cart'))
        const cartItem = cart.filter((i) => {
            return i.id == product.id
        })
        axios.get("/api/products?slug=" + product.slug)
            .then(res => {
                console.log()
                if (res.data.data[0].stock == 0 || cartCount >= res.data.data[0].stock || cartItem[0].cartCount >= res.data.data[0].stock) {
                    console.log()
                    setOutOfStock(true)

                } else {
                    setOutOfStock(false)
                }

            })

    }, [cartCount])

    const buyProduct = () => {
        const cart = JSON.parse(localStorage.getItem('cart'));
        let filtered = []

        if (cart.length > 0) {
            filtered = cart.filter((c) => {
                return c.id == product.id
            })
        }
        if (filtered.length == 0) {
            addToCart()
        }
        navigate("/cart")
    }

    const setCountCart = (action) => {

        if (action == "add") {
            if (!outOfStock)
                setCartCount(cartCount + 1)
        }
        if (action == "remove") {
            if (cartCount > 0) {
                setCartCount(cartCount - 1)
            }
        }

    }

    const addCart = () => {
        let cart = JSON.parse(localStorage.getItem('cart'))
        let cartFiltered = []
        cartFiltered = cart.filter((c) => {
            return c.id == product.id
        })
        if (cartFiltered.length > 0) {
            cartFiltered[0].cartCount = cartFiltered[0].cartCount + cartCount;
            let arr = cart.filter((c) => {
                return c.id != product.id
            })
            arr.push(cartFiltered[0])
            localStorage.setItem('cart', JSON.stringify(arr))
            swal("Success", "Product Added To Cart", "success");
        } else {
            let carti = product;
            carti.cartCount = cartCount
            let arr = cart.filter((c) => {
                return c.id != product.id
            })
            arr.push(carti)
            localStorage.setItem('cart', JSON.stringify(arr))
            swal("Success", "Product Added To Cart", "success");
        }
        // storeContext.setCartCount(storeContext.cartCount + 1)


    }

    React.useEffect(() => {
        let filtered = []
        const cart = JSON.parse(localStorage.getItem('cart'));

        if (cart.length > 0) {
            filtered = cart.filter((c) => {
                return c.id == product.id
            })
            if (filtered.length > 0) {
                setCartCount(filtered[0].cartCount)
            }

        }
    }, [stateChanged])
    return (
        <div className="product-details">
            <div className="row g-4">
                <div className="col-12 col-md-5">
                    <div className="product-images">
                        {
                            (productLoading) ? <Skeleton variant="rectangular"
                                                         style={{height: "240px", width: "240px", margin: "auto"}}/> :
                                <div className="product-main-img">
                                    <ImageMagnify srcImage={<img src={product.image_path} alt=""/>}/>

                                </div>
                        }
                        <div className="prouct-slide-img">

                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-7">
                    {
                        productLoading ?
                            <Skeleton variant="text" sx={{fontSize: '1.4rem'}} animation="wave"/> :

                            <div className="product-name d-flex justify-content-between align-items-center">
                                <h2 className="me-3">{product.productName}</h2>
                                <FontAwesomeIcon icon={faShareNodes} size="xl"/>
                            </div>
                    }
                    {
                        productLoading ?
                            <Skeleton variant="text" sx={{fontSize: '1.4rem'}} animation="wave"/> :

                            <div className="product-price d-flex justify-content-between align-items-center">
                                <div className="price d-flex align-items-center">
                                    {product.discountPercent != null && <s>RS. {product.sale_price}</s>}
                                    <h2>RS. {(product.discountPercent == null) ? product.sale_price : product.sale_price - ((product.discountPercent / 100) * product.sale_price)}</h2>
                                    {/* <h2 className='ms-4'>RS. {product.sale_price - ((product.discountPercent / 100) * product.sale_price)}</h2> */}
                                </div>
                                <div className="discount text-primary text-center d-flex align-items-center">
                                    {
                                        product.discountPercent != null &&
                                        <span>{`Upto ${product.discountPercent}% discount`}</span>
                                    }
                                </div>
                            </div>
                    }
                    {
                        productLoading ?
                            <Skeleton variant="text" sx={{fontSize: '1.4rem'}} animation="wave"/> :

                            <div className="product-rating d-flex justify-content-between align-items-center my-3">

                                <div className="product-authenticity">
                                    <FontAwesomeIcon icon={faCheckSquare} size="lg" className='me-2'/>
                                    100% Authentic
                                </div>
                                <div className="rating">
                                    <Rating name="read-only" value={product.averageRating} readOnly/>
                                </div>
                            </div>
                    }
                    <div className="product-add-details pt-3">
                        <div className="row g-2 pb-4">
                            <div className="col-4 col-md-3">
                                {
                                    productLoading ?
                                        <Skeleton variant="text" sx={{fontSize: '1.4rem'}} animation="wave"/> :

                                        <div className="left-label">
                                            <span>Manufacturer</span>
                                        </div>
                                }
                            </div>
                            <div className="col-8">
                                {
                                    productLoading ?
                                        <Skeleton variant="text" sx={{fontSize: '1.4rem'}} animation="wave"/> :

                                        <div className="product-manufacturer right-info">
                                            <span>ABC MANUFACTURER PVT LTD.</span>
                                        </div>
                                }
                            </div>
                        </div>

                        <div className="row g-2 pb-4">
                            <div className="col-4 col-md-3">
                                {
                                    productLoading ?
                                        <Skeleton variant="text" sx={{fontSize: '1.4rem'}} animation="wave"/> :

                                        <div className="left-label">
                                            <span>Vendor</span>
                                        </div>
                                }
                            </div>
                            <div className="col-8">
                                {
                                    productLoading ?
                                        <Skeleton variant="text" sx={{fontSize: '1.4rem'}} animation="wave"/> :

                                        <div className="product-manufacturer right-info">
                                            <span>Pharmacy Vendor ABCD</span>
                                        </div>
                                }
                            </div>
                        </div>

                        <div className="row g-2 pb-4">
                            <div className="col-4 col-md-3">
                                {
                                    productLoading ?
                                        <Skeleton variant="text" sx={{fontSize: '1.4rem'}} animation="wave"/> :

                                        <div className="left-label">
                                            <span>Quantity</span>
                                        </div>
                                }
                            </div>
                            <div className="col-8">
                                {
                                    productLoading ?
                                        <Skeleton variant="text" sx={{fontSize: '1.4rem'}} animation="wave"/> :

                                        <div className="product-quantity d-flex">
                                            <div className="prod-quan-stepper">
                                                <div className="item-count d-flex ">
                                                    <button
                                                        className="minus"
                                                        onClick={() => setCountCart("remove")}
                                                    >
                                                        <FontAwesomeIcon icon={faMinus} size="lg"/>
                                                    </button>
                                                    <input
                                                        type="text"
                                                        value={cartCount}
                                                        className="text-center"
                                                        min="1"
                                                    />
                                                    <button
                                                        className="plus"
                                                        onClick={() => setCountCart("add")}
                                                    >
                                                        <FontAwesomeIcon icon={faPlus} size="lg"/>
                                                    </button>
                                                </div>
                                            </div>
                                            {
                                                outOfStock &&
                                                <div className="alert alert-danger text-muted">
                                                    Out of Stock
                                                </div>
                                            }

                                        </div>
                                }
                            </div>
                        </div>
                    </div>

                    {
                        productLoading ?
                            <Skeleton variant="text" sx={{fontSize: '1.4rem'}} animation="wave"/> :
                            <>
                                {
                                    product.stock > 0 ?
                                        <div className="product-details-btn mt-3 d-flex">

                                            <div className="product-buy me-2">
                                                <button className="btn btn-outline-info px-5 buy-btn"
                                                        onClick={buyProduct}>
                                                    Buy Now
                                                </button>
                                            </div>
                                            <div className="product-add-cart">
                                                <button className="btn btn-info px-5 cart-btn" onClick={addCart}>
                                                    Add To Cart
                                                </button>
                                            </div>
                                        </div>
                                        :

                                        <div className="product-details-btn mt-3 d-flex">

                                            <div className="product-buy me-2">
                                                <button className="btn btn-outline-info px-5 buy-btn" disabled
                                                        onClick={buyProduct}>
                                                    Buy Now
                                                </button>
                                            </div>
                                            <div className="product-add-cart">
                                                <button className="btn btn-info px-5 cart-btn" disabled
                                                        onClick={addCart}>
                                                    Add To Cart
                                                </button>
                                            </div>
                                        </div>

                                }
                            </>


                    }

                </div>
            </div>
        </div>
    )
}

export default ProductDetails
