import React from 'react'
import SkeletonNewsItem from './SkeletonNewsItem'
import {Stack} from '@mui/material'

function LatestBlogsSkeleton() {

    return (
        <Stack gap={2}>
            {[...Array(5).keys()].map((item) => (
                <SkeletonNewsItem/>
            ))}
        </Stack>
    )
}

export default LatestBlogsSkeleton
