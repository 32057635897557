import {useQuery} from '@tanstack/react-query'
import React, {useContext} from 'react'
import {LoginStateContext} from '../../../../contexts/LoginStateContext'
import axios from '../../../api.js';
import Loader from '../../Loader';
import ConnectIPS from "../../../../images/payment/connectips.png"
import Esewa from "../../../../images/payment/esewa.png"
import IMEPay from "../../../../images/payment/imepay.png"
import KhaltiAdditional from '../../khalti/KhaltiAdditional';
import {useLocation, useParams} from 'react-router-dom';
import {Alert, AlertTitle} from '@mui/material';

const FamilyPayment = () => {
    const loginContext = useContext(LoginStateContext)
    const location = useLocation()
    const data = location.state
    const userData = loginContext.state.data
    const params = useParams();
    const familyId = params.id;
    const redirectLink = data.redirectLink || '/user/family'
    let apiLink = data.type === 0 ? `/api/admin/userpackage/calculate-initial-payment?family_count=${data.selected.length}` : `api/company-school-profile/reactivate-payment-calulation`
    const calculatefamilypayment = useQuery(
        ['family-payment-calculation', userData.id],
        async () => {
            return axios.get(apiLink, {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                .then(res => res.data)
        }
    )
    if (calculatefamilypayment.isLoading) {
        return <div className='d-flex justify-content-center'><Loader/></div>
    }
    const payment = calculatefamilypayment.data;

    return data?.selected ?
        (
            <div>
                <div className="alert alert-danger">
                    You must pay this member package payment in order to complete your family member joining process and
                    to join package.
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="card h-100">
                            <div className="card-body">
                                <h5 style={{fontWeight: 600}} className="mb-3">Subscription Payment</h5>
                                <table className="table">
                                    <tbody>
                                    <tr>
                                        <th><b>Total Additional Family Member</b></th>
                                        <td>{data.selected.length}</td>
                                    </tr>
                                    {
                                        data.type === 0 && (
                                            <>
                                                <tr>
                                                    <th><b>Enrollment fee</b></th>
                                                    <td>Rs. {payment.enrollment_fee}</td>
                                                </tr>
                                                <tr>
                                                    <th><b>Discount ({payment.discount}%):</b></th>
                                                    <td>Rs. {payment.discount_amount}</td>
                                                </tr>
                                            </>
                                        )
                                    }

                                    {/* <tr>
                                            <th><b>Daily fee</b></th>
                                            <td>Rs. {payment.daily_fee}</td>
                                        </tr> */}
                                    <tr>
                                        <th><b>Remaining Payment Days</b></th>
                                        <td>{payment.payment_days} days</td>
                                    </tr>
                                    </tbody>

                                </table>
                                <div className="text-end mt-2">
                                    <h6><b>Total Amount:</b><br/> Rs. {payment.total_payment}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card h-100">
                            <div className="card-body">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h5 style={{fontWeight: 600}} className="mb-3">Payment Details</h5>
                                    <h5><b>Total Amount:</b> Rs. {payment.total_payment}</h5>
                                </div>
                                <h6 style={{fontWeight: 600}} className='mt-4 mb-3'>Pay Via:</h6>
                                <div className="d-flex justify-content-between flex-wrap align-items-center">
                                    <div className='my-2'>
                                        <KhaltiAdditional familyId={data.selected} payment={payment}
                                                          redirectLink={redirectLink} type={data.type}/>
                                    </div>
                                    <div className='my-2'>
                                        <img src={Esewa} alt="Esewa"/>
                                    </div>
                                    <div className='my-2'>
                                        <img src={IMEPay} alt="IMEPay"/>
                                    </div>
                                    <div className='my-2'>
                                        <img src={ConnectIPS} alt="ConnectIPS"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        ) : (
            <Alert severity='error'>
                <span>You haven't selected any member. Please select family and try again.</span>
                <a href="/user/family">Go to Family</a>
            </Alert>
        )
}

export default FamilyPayment
