import {Box} from '@mui/material';
import React from 'react'
import {Link} from 'react-router-dom';

const NurseShift = (props) => {
    const nurse = props.nurse;
    //console.log();
    const token = props.token;
    return (
        <div className='card position-relative w-100 ' style={{
            borderRadius: '0',
            overflow: 'auto',
            minHeight: '100%',
            maxHeight: '300px',
            boxSizing: 'border-box'
        }}>
            <div className="card-body">

                <Box position='absolute' whiteSpace='nowrap' width='97%' boxSizing='border-box'>
                    <table className="table text-center">
                        <thead style={{borderBottom: '4px solid #C6DFF6'}}>
                        <tr>
                            <th>Date</th>
                            <th>Available Shift</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {nurse.shifts.length !== 0 ?
                            nurse.shifts.map((shift) =>
                                <tr>
                                    <td>{shift.date}</td>
                                    <td>{shift.shift}</td>
                                    <td>
                                        {token != null ?
                                            <Link className="btn btn-primary py-1" to="/book-nurse"
                                                  state={{nurse: nurse, shift: shift}}>Book</Link>
                                            :
                                            <Link className="btn btn-primary py-1" to="/login">Book</Link>
                                        }
                                    </td>
                                </tr>
                            )
                            :
                            <tr>
                                <td colSpan="3">No Shift Available</td>
                            </tr>
                        }
                        </tbody>
                    </table>
                </Box>
                <table>

                </table>
            </div>
        </div>
    )
}

export default NurseShift
