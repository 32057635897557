import { Box, CircularProgress, IconButton, Modal } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useKhaltiCtx } from "../context/KhaltiContext";

const style = {
  width: "100vW",
  height: "96vH",
  overflow: "auto",
  position: "relative",
  marginTop: "auto",
  borderRadius: "20px 20px 0 0",
  background: "white",
};

const iframeStyle = {
  width: "100%",
  height: "100%",
};

const KhaltiModal = () => {
  const {
    khaltiSrc,
    khaltiOpen,
    isLoading,
    isSubmitting,
    setKhaltiOpen,
    khaltiConfig,
    setSubmitting,
  } = useKhaltiCtx();

  const iframeRef = useRef(null);

  const handleIframeLoad = async (e) => {
    const iframeWindow = iframeRef.current.contentWindow
    try {
      const iframeURL = iframeWindow.location.origin
      const iframeSearch = new URLSearchParams(iframeWindow.location.search)
      const searchObj = Object.fromEntries(iframeSearch)
      if(iframeURL === window.location.origin) {
        setSubmitting(true)
        let verifyFn = khaltiConfig.onVerify(searchObj)
        try {
          const res = await verifyFn()
          khaltiConfig.onSuccess(res)
        }
        catch (err) {
          khaltiConfig.onFailure(err)
          throw err
        }
        finally {
          setKhaltiOpen(false)
          setSubmitting(false)
        }

        return
      }
    }
    catch(e) {
      //handle Error
      console.log("Iframe error", e)
    }
  }

  return (
    <Modal
      open={khaltiOpen}
      //   onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: "flex", flexDirection: "column" }}
    >
      <Box sx={style}>
        {isLoading || isSubmitting ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "grid",
              placeItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          !isSubmitting && <iframe
            src={khaltiSrc}
            style={iframeStyle}
            ref={iframeRef}
            onLoad={handleIframeLoad}
          ></iframe>
        )}

        <div
          style={{
            position: "absolute",
            top: "10px",
            right: "40px",
          }}
        >
          <IconButton onClick={() => setKhaltiOpen(false)}>
            <CloseIcon />
          </IconButton>
        </div>
      </Box>
    </Modal>
  );
};

export default KhaltiModal;
