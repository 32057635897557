import {Search} from '@mui/icons-material'
import {Box, Button, InputBase} from '@mui/material'
import React, {useRef} from 'react'
import {useNavigate} from 'react-router-dom'

function BlogSearchInput() {
    const searchRef = useRef(null)
    const navigate = useNavigate()

    function handleSearch() {
        navigate(`/blog/filter?search=${searchRef.current.value}`)
    }

    return (
        <Box
            display='flex'
            justifyContent={'space-between'}
            gap={1}
            border={1}
            borderColor={'divider'}
            borderRadius={4}
            overflow={'hidden'}
            flexGrow={1}
            boxShadow={'rgba(149, 157, 165, 0.2) 0px 8px 24px'}
        >
            <Box px={2} py={0.5} flexGrow={1}>
                <InputBase type='text' placeholder='Search For Blogs' inputRef={searchRef} fullWidth/>
            </Box>
            <Button variant='contained' onClick={handleSearch}>
                <Search/>
            </Button>
        </Box>
    )
}

export default BlogSearchInput
