import {InventoryOutlined} from '@mui/icons-material'
import {CardMedia, FormControl, Grid, InputLabel, MenuItem, NativeSelect, Select} from '@mui/material'
import {Box} from '@mui/material'
import {Stack} from '@mui/material'
import {Button} from '@mui/material'
import {Typography} from '@mui/material'
import {Avatar} from '@mui/material'
import {CardContent} from '@mui/material'
import {Card} from '@mui/material'
import React, {useState} from 'react'
import BookFitnessModal from '../gdvendors/components/BookFitnessModal'


function getFirstNotNullPricing(data) {
    if (data.one_month) return 'one_month'
    if (data.three_month) return 'three_month'
    if (data.six_month) return 'six_month'
    if (data.one_year) return 'one_year'
}

function FitnessItem({data}) {
    const [selected, setSelected] = useState(getFirstNotNullPricing(data))
    const [price, setPrice] = useState(data[selected])
    const [open, setOpen] = useState(false)

    function handleChange(e) {
        e.preventDefault()
        setSelected(e.target.value)
        setPrice(data[e.target.value])
    }

    return (
        <Card sx={{maxWidth: '350px'}}>
            <CardMedia
                component='img'
                src='https://cdn.shopify.com/s/files/1/0029/7828/3564/files/DSC05416_dark_2000x.jpg?v=1613760603'
                sx={{aspectRatio: '1.2/1'}}
            />
            <CardContent sx={{position: 'relative', p: 0}}>
                <Box sx={{position: 'absolute', width: '100%', top: '-40px'}}>
                    <Stack alignItems='center' sx={{width: '100%'}}>
                        <Avatar
                            src='https://cms-assets.tutsplus.com/cdn-cgi/image/width=630/uploads/users/151/posts/32516/image/Placeit2.jpg'
                            sx={{width: '90px', height: '90px'}}/>
                        <Typography variant="h6">Power House Fitness</Typography>
                    </Stack>
                </Box>
                <Box sx={{width: '100%', height: '85px', borderBottom: '1px solid', borderColor: 'divider'}}>
                </Box>
                <Box mt={2} textAlign='center'>
                    <Typography variant='h6' fontWeight={400}>{data.fitnesstype.fitness_name}</Typography>

                    {/* <Typography variant='subtitle1' fontSize='14px' color='text.secondary'>Dedicated Trainer</Typography>
                    <Typography variant='subtitle1' fontSize='14px' color='text.secondary'>4 Days A Week</Typography>
                    <Typography variant='subtitle1' fontSize='14px' color='text.secondary'>Morning or Evening Membership</Typography> */}

                    <Grid container p={2}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-select-small">Duration</InputLabel>
                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    defaultValue={selected}
                                    label='Duration'
                                    size='small'
                                    onChange={handleChange}
                                >
                                    {
                                        data.one_month && <MenuItem value='one_month'>One Month</MenuItem>
                                    }
                                    {
                                        data.three_month && <MenuItem value='three_month'>Three Months</MenuItem>
                                    }
                                    {
                                        data.six_month && <MenuItem value='six_month'>Six Months</MenuItem>
                                    }
                                    {
                                        data.one_year && <MenuItem value='one_year'>One Year</MenuItem>
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant='h5' fontWeight={400}>NPR {price}</Typography>
                        </Grid>
                    </Grid>

                    <Stack direction={'row'} mt={2} justifyContent='space-around' px={2}>
                        {/* <Typography variant='h5' fontWeight={400}>NPR 1200/M</Typography> */}
                        <Button fullWidth variant='contained' startIcon={<InventoryOutlined/>}
                                onClick={() => setOpen(true)}>
                            Book Now
                        </Button>
                    </Stack>
                </Box>
            </CardContent>

            <BookFitnessModal open={open} handleClose={() => {
                setOpen(false)
            }} fitnesstype={data.fitnesstype.fitness_name} durations={selected} price={price} isLoading={false}
                              vendorName={'Prashanna GC'}
                              logo={"https://previews.123rf.com/images/bogadeva1983/bogadeva19831507/bogadeva1983150700010/42552108-fitness-club-logo-or-emblem-with-woman-and-man-silhouettes-woman-and-man-holds-dumbbells-isolated-on.jpg"}
                              fitnesstypeid={data.fitnesstype.id} vendorId={data.vendor_id}/>
        </Card>
    )
}

export default FitnessItem
