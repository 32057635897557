import {Carousel} from "react-bootstrap";
import Slider from "react-slick";
import {Link} from "react-router-dom";

const StoreCarousel = () => {
    const carouselStyle = {
        aspectRatio: "3.36/1",
        marginTop: "1em",
        overflow: "hidden",
        backgroundColor: "#F9F6EE",
    };

    const imgStyle = {
        height: "100%",
        width: "100%",
        objectFit: "cover",
    };

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 6000,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <Slider {...sliderSettings} style={carouselStyle}>
            <div className="item w-100" style={{zIndex: "1001"}}>
                <img
                    src="https://www.nepmeds.com.np/storage/images/slider/images/everteen-banner-for-nepal-1170x350-desktop-png-20210329102901-nmbq0C.png"
                    alt=""
                    className="slider"
                    style={imgStyle}
                />
            </div>

            <div className="item w-100" style={{zIndex: "1001"}}>
                <img
                    src="https://www.nepmeds.com.np/storage/images/slider/images/c-section-banner-20200311153900-cGVSel.png"
                    alt=""
                    className="slider"
                    style={imgStyle}
                />
            </div>
        </Slider>
    );

    return (
        <Carousel style={carouselStyle}>
            <Carousel.Item className="carousel-inner-custom">
                <img
                    style={imgStyle}
                    className="d-block w-100"
                    src="https://post.healthline.com/wp-content/uploads/2021/06/944264-The-10-Best-Home-Gym-Equipment-Items-to-Own-1200x628-Facebook.jpg"
                    alt="First slide"
                />
                <Carousel.Caption>
                    <h3>First slide label</h3>
                    <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src="https://pharmaceutical-journal.com/wp-content/uploads/2021/01/herbal-medicines-ss-18-scaled.jpg"
                    alt="Second slide"
                />

                <Carousel.Caption>
                    <h3>Second slide label</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    );
};

export default StoreCarousel;
