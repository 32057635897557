import "./Checkout.css";
// import BreadCrumbs from "../../BreadCrumbs";
import Footer from "../../Footer";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ProgressTracking from "../../stores/checkout/ProgressTracking";
import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import swal from "sweetalert";
import axios from "../../api.js";
import StoreHeader from "../../stores/layouts/StoreHeader";
import {StoreContext} from "../../../contexts/StoreContext";
import {Card, CardContent, CardHeader, Typography} from "@mui/material";

const Checkout = () => {
    // const products = JSON.parse(localStorage.getItem("cart"));
    const [progress, setProgress] = useState('fill-details')
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams()
    const currentParams = Object.fromEntries(searchParams)

    const storeContext = React.useContext(StoreContext);

    const token = localStorage.getItem("token");

    useEffect(() => {
        if (Object.keys(currentParams).length == 0) {
            setSearchParams({progress: "fill-details"})
        }
    }, [])


    // return (
    //   <>
    //     <div className="container pt-2">
    //       <div className="checkout-container mb-5 mt-2">
    //         <div className="card">
    //           <div className="card-header checkout-card-header">
    //             <h3>Checkout</h3>
    //           </div>
    //           <div className="card-body">
    //             <ProgressTracking progress={progress} setProgress={setProgress}/>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </>
    // );
    return (
        <div className="container pt-2">
            <div className="checkout-container mb-5 mt-2">
                <ProgressTracking progress={progress} setProgress={setProgress}/>
            </div>
        </div>
    )
};

export default Checkout;
