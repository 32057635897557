import {useQuery} from '@tanstack/react-query'
import axios from '../../../api'
import React from 'react'
import Slider from 'react-slick'
import FitnessContainer from './FitnessContainer'
import FitnessVendorCard from './FitnessVendorCard'
import {useNavigate} from 'react-router-dom'

const fitnessCenterSlider = {
    responsive: [
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 3,

            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 1150,
            settings: {
                slidesToShow: 4,
            }
        },
        {
            breakpoint: 320,
            settings: {
                slidesToShow: 1,
            }
        }
    ]
}

function FitnessCenters() {
    const {data, isLoading} = useQuery(
        ['fitnessCenters'],
        async () => axios.get("/api/vendor-details?vendor_type=3").then(res => res.data)
    )
    const navigate = useNavigate()
    const settings = {
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: false,
        dots: true,
        ...fitnessCenterSlider,
    }

    if (isLoading || data.length === 0) {
        return
    }

    return (
        <FitnessContainer title={'Browse Fitness Center'}>
            <div className="custom-slick">
                <Slider {...settings}>
                    {
                        data.map((vendor) => (
                            <div className='pt-2 ps-2'>
                                <FitnessVendorCard
                                    logo={vendor.image_path}
                                    name={vendor.user.name}
                                    email={vendor.user.email}
                                    number={vendor.user.phone}
                                    onClick={() => navigate(`/gd-store/vendor/${vendor.slug}`)}
                                />
                            </div>
                        ))
                    }
                    <div className='pt-2 ps-2'>
                        <FitnessVendorCard/>
                    </div>
                    <div className='pt-2 ps-2'>
                        <FitnessVendorCard/>
                    </div>
                    <div className='pt-2 ps-2'>
                        <FitnessVendorCard/>
                    </div>
                    <div className='pt-2 ps-2'>
                        <FitnessVendorCard/>
                    </div>
                    <div className='pt-2 ps-2'>
                        <FitnessVendorCard/>
                    </div>
                </Slider>
            </div>
        </FitnessContainer>
    )
}

export default FitnessCenters
