import React from "react";
import Slider from "react-slick";
import {Rating} from "@mui/material";
import axios from "../api.js";
import "./ShopVendor.scss"
import {Link, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleArrowRight} from "@fortawesome/free-solid-svg-icons";

const ShopVendorList = () => {
    const [vendors, setVendors] = React.useState([])
    const navigate = useNavigate()

    React.useEffect(() => {
        axios.get("api/vendor-details?vendor_type=2")
            .then((res) => {
                setVendors(res.data)
            })
            .catch((err) => {
                console.log()
            })
    }, [])

    const renderVendorList = (vendor) => {
        return (
            <div className="flex-wrap-items">
                <div className="card vendor-card" onClick={() => {
                    navigate(`/gd-store/vendor/${vendor.slug}`)
                }}>
                    <div className="card-header">
                        <div className="img-div">
                            <img src={vendor.image_path} alt="vendor"/>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="vendor-card-desc">
                            <div className="vendor-card-name d-flex justify-content-center">
                                <h5>{vendor.user.name}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    return (
        <div className="section-vendors mt-4 mb-5">
            <div className="topic-head d-flex justify-content-between mb-4">
                <h2>Ghargharmadoctor Vendors</h2>
                <div className="topic-head-all">
                    <Link to="" className="text-decoration-none">
                        <span>See All</span>
                        <FontAwesomeIcon icon={faCircleArrowRight} size="xl"/>
                    </Link>
                </div>
            </div>
            <div className="vendor-list custom-slick">
                <div className="d-flex flex-wrap">
                    {vendors.map((vendor) => {
                        return renderVendorList(vendor)
                    })}
                </div>
            </div>
        </div>
    );
};

export default ShopVendorList;
