import { createContext, useContext, useState } from "react";

const KhaltiContext = createContext(null)

const KhatliProvider = ({children, config}) => {
    const [khaltiConfig, setKhaltiConfig] = useState(config)
    const [isLoading, setLoading] = useState(false)
    const [isSubmitting, setSubmitting] = useState(false)

    const [error, setError] = useState()

    const [khaltiSrc, setKhaltiSrc] = useState(null)
    const [khaltiOpen, setKhaltiOpen] = useState(false)

    return (
        <KhaltiContext.Provider value={{isLoading, isSubmitting, khaltiSrc, khaltiOpen, error, khaltiConfig, setLoading, setSubmitting, setKhaltiSrc, setKhaltiOpen, setError}}>
            { children }
        </KhaltiContext.Provider>
    )
}

export default KhatliProvider

export const useKhaltiCtx = () => useContext(KhaltiContext)