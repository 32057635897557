import {Avatar, Box, Button, Modal, Stack, Typography} from '@mui/material'
import React, {useState} from 'react'
import KhaltiFitness from '../../khalti/KhaltiFitness';
import KhaltiOrder from '../../khalti/KhaltiOrder';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

function FitnessPayment({open, handleClose, data}) {
    const [verifying, setVerifying] = useState(false)
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Stack direction={'row'} alignItems='center' my={1}>
                    <Typography variant='subtitle1' color='text.secondary' sx={{mr: 1}}>Fitness Package: </Typography>
                    <Typography variant='h6'>Gym And Cardio</Typography>
                </Stack>
                <Stack direction={'row'} alignItems='center' my={1}>
                    <Typography variant='subtitle1' color='text.secondary' sx={{mr: 1}}>Total: </Typography>
                    <Typography variant='h6'>NPR {data.total_amount}</Typography>
                </Stack>
                <Stack direction={'row'} alignItems='center' my={1}>
                    <Typography variant='subtitle1' color='text.secondary' sx={{mr: 1}}>Sold By: </Typography>
                    <Stack direction='row' alignItems='center'>
                        <Avatar
                            alt="Vendor"
                            src={"https://img.freepik.com/premium-vector/fitness-logo_25327-145.jpg?w=2000"}
                            sx={{width: 40, height: 40}}
                        />
                        <Typography variant='subtitle1'>Vendor Name</Typography>
                    </Stack>
                </Stack>
                <Stack direction={'row'} alignItems='center' my={1}>
                    <Typography variant='subtitle1' color='text.secondary' sx={{mr: 1}}>Duration: </Typography>
                    <Typography variant='subtitle1'>1 Month</Typography>
                </Stack>
                <Stack direction='row' alignItems='center' flexWrap='wrap' my={1}>
                    <Typography variant='subtitle1' color='text.secondary'>Pay With:</Typography>
                    <KhaltiFitness packageId={data.id} package_amount={10} identity={data.slug}
                                   setVerifying={setVerifying}/>
                </Stack>
                <Stack direction='row' justifyContent='end'>
                    <Button variant='outlined' sx={{my: 1}} color='error' onClick={handleClose}>
                        Cancel
                    </Button>
                </Stack>
            </Box>
        </Modal>
    )
}

export default FitnessPayment
