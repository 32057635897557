import React, { useState } from 'react'
import KhatliProvider, { useKhaltiCtx } from '../context/KhaltiContext'
import axios from '../../../components/api'
import KhaltiModal from './KhaltiModal'

const KhaltiRenderComponent = ({render}) => {
    const { isLoading, isSubmitting, khaltiConfig, setError, setLoading, setKhaltiSrc, setKhaltiOpen } = useKhaltiCtx()

    const makePayment = async () => {
        setLoading(true)
        setKhaltiOpen(true)
        //on khalti button click handle this logic.
        try {
            const res = await axios.post(
                "/api/admin/payment/initiate",
                khaltiConfig.config
            )
            setKhaltiSrc(res.data.payment_url)
        }
        catch (e) {
            setKhaltiOpen(false)
            setError(e)
        }
        finally {
            setLoading(false)
        }
    }

    return render(isLoading, isSubmitting, makePayment)
}

const KhaltiPaymentComponent = ({config, onVerify, onSuccess, onFailure, render}) => {
    const khaltiConfig = {
        config: {
            return_url: `${window.location.origin}/verify-khalti-payment`,
            website_url: "https://testreact.ghargharmadoctor.com",
            amount: config.amount,
            purchase_order_id: config.purchaseOrderId,
            purchase_order_name: config.purchaseOrderName,
            customer_info: {
                name: config.name,
                email: config.email,
                phone: config.phone,
            }
        },
        onVerify: onVerify,
        onSuccess: onSuccess,
        onFailure: onFailure
    }
    
  return (
    <KhatliProvider config={khaltiConfig}>
        <KhaltiRenderComponent render={render} />
        <KhaltiModal />
    </KhatliProvider>
  )
}

export default KhaltiPaymentComponent