import {useQuery} from '@tanstack/react-query';
import {param} from 'jquery';
import React, {useContext} from 'react'
import {useParams} from 'react-router-dom'
import {LoginStateContext} from '../../../../contexts/LoginStateContext';
import axios from "../../../api"
import Loader from '../../Loader'
import ClaimStatus from './components/ClaimStatus';

const ClaimSettlementDetails = () => {
    const params = useParams();
    const loginContext = useContext(LoginStateContext)
    const claimSettlementDetailsQuery = useQuery(
        ['claim-settlement-details-data', params.slug],
        async () => {
            return axios.get(`/api/admin/insurance-claim?slug=${params.slug}`, {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                .then(res => res.data)
        }
    )
    if (claimSettlementDetailsQuery.isLoading) {
        return <div className='d-flex justify-content-center'><Loader/></div>
    }

    let claimObj = claimSettlementDetailsQuery.data
    const claimSettlementDetails = claimObj.claims;

    return (
        <div className="card">
            <div className="card-body">
                <ClaimStatus data={claimSettlementDetails}/>
                <h4 style={{borderBottom: '2px solid #063b9d'}}>1. Insurance Details</h4>
                <table className="table my-3">
                    <tr>
                        <th style={{fontWeight: '600'}}>Insurance Type</th>
                        <td>{claimSettlementDetails.insurance.insurancetype.type}</td>
                    </tr>
                    <tr>
                        <th style={{fontWeight: '600'}}>Insurance Claim Amount</th>
                        <td>Rs. {claimSettlementDetails.claim_amount}</td>
                    </tr>
                    <tr>
                        <th style={{fontWeight: '600'}}>Company Name</th>
                        <td>{claimObj.company_name}</td>
                    </tr>
                    <tr>
                        <th style={{fontWeight: '600'}}>Insurance Claim Status</th>
                        <td><span className='badge badge-secondary'>{claimSettlementDetails.insurance_status}</span>
                        </td>
                    </tr>
                    {
                        claimSettlementDetails.setelled_date ? (
                            <tr>
                                <th style={{fontWeight: 600}}>Claim Settled Date</th>
                                <td><span>{claimSettlementDetails.setelled_date}</span></td>
                            </tr>
                        ) : claimSettlementDetails.rejected_date ? (
                            <tr>
                                <th style={{fontWeight: 600}}>Claim Settled Date</th>
                                <td><span>{claimSettlementDetails.rejected_date}</span></td>
                            </tr>
                        ) : claimSettlementDetails.approved_date && (
                            <tr>
                                <th style={{fontWeight: 600}}>Claim Settled Date</th>
                                <td><span>{claimSettlementDetails.approved_date}</span></td>
                            </tr>
                        )
                    }
                </table>
                <h4 style={{borderBottom: '2px solid #063b9d'}}>2. Hand Written Letter</h4>
                <div className="my-4">
                    <p>Click here to view your hand written letter: <a
                        href={claimSettlementDetails.hand_written_letter_path}
                        target="_blank" rel="noreferrer">{claimSettlementDetails.hand_written_letter_path}</a></p>
                    <iframe src={claimSettlementDetails.hand_written_letter_path} height="600" width="600"
                            title="hand_written_letter"></iframe>
                </div>
                <h4 style={{borderBottom: '2px solid #063b9d'}}>3. Medical Report</h4>
                <div className="my-4">
                    <p>Click here to view your medical report: <a href={claimSettlementDetails.medical_report_path}
                                                                  target="_blank"
                                                                  rel="noreferrer">{claimSettlementDetails.medical_report_path}</a>
                    </p>
                    <iframe src={claimSettlementDetails.medical_report_path} height="600" width="600"
                            title="medical_report"></iframe>
                </div>
                <h4 style={{borderBottom: '2px solid #063b9d'}}>4. Invoice</h4>
                <div className="my-4">
                    <p>Click here to view your invoice: <a href={claimSettlementDetails.invoice_path}
                                                           target="_blank"
                                                           rel="noreferrer">{claimSettlementDetails.invoice_path}</a>
                    </p>
                    <iframe src={claimSettlementDetails.invoice_path} height="600" width="600" title="invoice"></iframe>
                </div>
            </div>
        </div>
    )
}

export default ClaimSettlementDetails
