import {Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, Typography} from '@mui/material'
import React from 'react'

function BookingDetails() {
    return (
        <Box p={4} position='sticky' top={80}>
            <Typography variant='h4' fontWeight={500}>
                Booking Details
            </Typography>
            <Box mt={2}>
                <Stack gap={2}>
                    <Box width='100%' textAlign='center' border={1.5} borderRadius={2} borderColor={'primary.main'}
                         p={1}>
                        <Typography variant='h5'>Family Size: 1</Typography>
                    </Box>
                    <Box display='flex' textAlign={'left'}>
                        <Box pr={2} flexGrow={1} display={'flex'} flexDirection='column'>
                            <Typography variant='subtitle1' color='text.disabled' lineHeight={1.3}>Registration
                                fee/family</Typography>
                            <Typography variant='h6' sx={{mt: 'auto'}}>Rs. 17000</Typography>
                        </Box>
                        <Box px={2} flexGrow={1} borderLeft={1} borderRight={1} borderColor='divider' display={'flex'}
                             flexDirection='column'>
                            <Typography variant='subtitle1' color='text.disabled' lineHeight={1.3}>Monthly
                                fee/person</Typography>
                            <Typography variant='h6' sx={{mt: 'auto'}}>Rs. 1500</Typography>
                        </Box>
                        <Box pl={2} flexGrow={1}>
                            <Typography variant='subtitle1' color='text.disabled' lineHeight={1.3}>Select Payment
                                Interval</Typography>
                            <FormControl variant='standard' fullWidth sx={{mt: 'auto'}}>
                                <InputLabel id="demo-simple-select-label">Age</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    // value={age}
                                    label="Age"
                                    // onChange={handleChange}
                                >
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                    <Box width='100%' textAlign='center' border={1} borderColor='#52C8F4' p={1} borderRadius={2}>
                        <Typography variant='h5'>
                            NPR. 15,000
                        </Typography>
                    </Box>
                    <Box>
                        <Button variant='contained' fullWidth>
                            <Typography variant='h5'>
                                Buy package
                            </Typography>
                        </Button>
                    </Box>
                </Stack>
            </Box>
        </Box>
    )
}

export default BookingDetails
