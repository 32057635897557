import {Box, CircularProgress, LinearProgress} from '@mui/material'
import {Typography} from '@mui/material'
import {Modal} from '@mui/material'
import React, {useState} from 'react'
import UploadFile from '../../custom_componets/UploadFile'
import {IconButton} from '@mui/material'
import {Close} from '@mui/icons-material'
import {useQuery} from '@tanstack/react-query'
import axios from '../../api'
import swal from 'sweetalert'

function InsuranceClaim({handleClose}) {
    const [inputs, setInputs] = useState({
        insurance_type_id: null,
        name: null,
        phone: null,
        claiming_name: null,
        claiming_phone: null,
    })
    const [submitting, setSubmitting] = useState(false)
    const {isLoading, data} = useQuery(
        ['death_insurance'],
        async () => {
            return axios.get("/api/insurance/type").then(res => res.data).catch(err => err)
        }
    )

    function submitForm(e) {
        e.preventDefault()
        console.log("Insurance inputs", inputs)
        setSubmitting(true)
        axios.post('api/insurance-claim/death-store', inputs)
            .then(res => {
                handleClose()
                swal({
                    title: "Success!",
                    text: "Insurance claim created successfully!",
                    icon: "success",
                })
            })
            .catch(err => swal({
                title: 'Error',
                text: err.response.data.message?.error[0] || 'There was an error',
                icon: 'error',
            }))
            .finally(() => setSubmitting(false))
    }

    function handleInputs(e) {
        setInputs((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    function handleFile(inputFile, name, setInputs) {
        var reader = new FileReader();
        reader.readAsDataURL(inputFile);
        console.log()
        var ext = inputFile.type.split('/')[1]
        reader.onload = () => {
            const strArr = reader.result.split('base64,')
            const base64Str = `data:image/${ext};base64,${strArr[1]}`
            setInputs(prev => ({...prev, [name]: base64Str}))
        }
        reader.onerror = error => {
            console.log();
        }
    }

    return (
        <>
            <Box
                width='100%'
                maxWidth='600px'
                position='absolute'
                top='50%'
                left='50%'
                sx={{transform: 'translate(-50%, -50%)'}}
            >
                {submitting && <LinearProgress sx={{height: '8px', position: 'relative', zIndex: 99}}/>}
                <div className="card w-100">
                    <div className="card-header d-flex justify-content-between align-items-center">
            <span className='fs-5'>
              Death Insurance Claim
            </span>
                        <IconButton>
                            <Close sx={{color: 'error.light'}}/>
                        </IconButton>
                    </div>
                    <div className="card-body" style={{maxHeight: '650px', overflow: 'auto'}}>
                        <Box position='relative'>
                            <form onSubmit={submitForm}>
                                <div className="row g-3">
                                    <div className="col-6">
                                        <label className='mb-1 fw-light'>Policy Holder Name<code>*</code></label>
                                        <input type="text" className="form-control" placeholder='Policy holder name'
                                               required name='name' value={inputs.name} onChange={handleInputs}/>
                                    </div>
                                    <div className="col-6">
                                        <label className='mb-1 fw-light'>Policy Holder Phone<code>*</code></label>
                                        <input type="text" className="form-control" placeholder='Policy holder phone'
                                               required name='phone' value={inputs.phone} onChange={handleInputs}/>
                                    </div>
                                    <div className="col-12">
                                        <label className='mb-1 fw-light'>Insurance Type<code>*</code></label>
                                        <select className="form-select form-control" aria-label="Default select example"
                                                required name='insurance_type_id' value={inputs.insurance_type_id}
                                                onChange={handleInputs}>
                                            {
                                                isLoading ? <option>Loading...</option> : (
                                                    <>
                                                        <option disabled>Select Insurance Type</option>
                                                        {data.map((item, idx) => (
                                                            <option value={item.id} key={item.id}>
                                                                {item.type}
                                                            </option>
                                                        ))}
                                                    </>
                                                )
                                            }
                                        </select>
                                    </div>
                                    <div className="col-6">
                                        <label className='mb-1 fw-light'>Name<code>*</code></label>
                                        <input type="text" className="form-control" placeholder='Your name' required
                                               name='claiming_name' value={inputs.claiming_name}
                                               onChange={handleInputs}/>
                                    </div>
                                    <div className="col-6">
                                        <label className='mb-1 fw-light'>Phone<code>*</code></label>
                                        <input type="number" className="form-control" placeholder='Your phone' required
                                               name='claiming_phone' value={inputs.claiming_phone}
                                               onChange={handleInputs}/>
                                    </div>
                                    <div className="col-6">
                                        <label className='mb-1 fw-light'>Relationship Certificate<code>*</code></label>
                                        <UploadFile
                                            name="relationship_certificate"
                                            label="Relationship Certificate"
                                            type={"pdf"}
                                            accept={"application/pdf,application/vnd.ms-excel"}
                                            required
                                            setImageInput={(fileG) => {
                                                handleFile(fileG[0], 'relationship_certificate', setInputs)
                                            }}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <label className='mb-1 fw-light'>Invoice<code>*</code></label>
                                        <UploadFile
                                            name="invoice"
                                            label="Upload Invoice"
                                            type={"pdf"}
                                            accept={"application/pdf,application/vnd.ms-excel"}
                                            required
                                            setImageInput={(fileG) => {
                                                handleFile(fileG[0], 'invoice', setInputs)
                                            }}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <label className='mb-1 fw-light'>Death Certificate<code>*</code></label>
                                        <UploadFile
                                            name="death_certificate"
                                            label="Upload Death Certificate"
                                            type={"pdf"}
                                            accept={"application/pdf,application/vnd.ms-excel"}
                                            required
                                            setImageInput={(fileG) => {
                                                handleFile(fileG[0], 'death_certificate', setInputs)
                                            }}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <div className="d-flex justify-content-end">
                                            <button type="submit" className="btn btn-primary">
                                                Proceed
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            {submitting &&
                                <Box
                                    position='fixed'
                                    top={0}
                                    left={0}
                                    bgcolor='white'
                                    width='100%'
                                    height='100%'
                                    // opacity={0.2}
                                    sx={{opacity: 0.5}}
                                >
                                    {/* <CircularProgress sx={{position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)'}} /> */}
                                </Box>
                            }
                        </Box>

                    </div>
                </div>
            </Box>
        </>
    )
}

export default InsuranceClaim
