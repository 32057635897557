import {FmdGoodOutlined, Search} from '@mui/icons-material'
import {Box, CircularProgress, InputBase, Paper} from '@mui/material'
import React, {useEffect, useRef, useState} from 'react'
import L from 'leaflet'
import {MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents} from 'react-leaflet'
import axios from '../api'
import MapMarker from '../../images/mapmarker.png'

function getLeafIcon(size) {
    return new L.Icon({
        iconUrl: MapMarker,
        iconRetinaUrl: MapMarker,
        iconSize: [size, size + 15],
        shadowSize: [size, size]
    })
}

function LeafletMap({
                        height, aspectRatio, autoComplete = false, getLatLng = () => {
    }, initPos = {lat: 27.71, lng: 85.3240}
                    }) {
    const [position, setPosition] = useState(initPos)
    let containerRef = useRef(null)

    function handleMapClick(e) {
        // setPosition(e.latlng)
        handleAutoComp(e.latlng)
        console.log("Map clicked", e.latlng)
    }

    function handleAutoComp(latlng) {
        setPosition(latlng)
        getLatLng(latlng)
    }

    // useEffect(()=> {
    //     console.log("Setting new position", newPosition)
    //     setPosition(newPosition)
    // }, [newPosition])

    return (
        <Box sx={{'& > div:first-child': {height: height, aspectRatio: aspectRatio}}} position='relative'>
            <MapContainer center={position} zoom={13} scrollWheelZoom={false} height={'200px'}>
                <LocationClicked onClick={handleMapClick}/>
                <ChangeView center={position} zoom={20}/>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={position} icon={getLeafIcon(30)}>
                    <Popup>
                        A pretty CSS3 popup. <br/> Easily customizable.
                    </Popup>
                </Marker>
            </MapContainer>
            {
                autoComplete && (
                    <Box sx={{position: 'absolute', zIndex: 999, top: 10, left: 100, width: '40%', margin: 'auto'}}>
                        <LeafletAutoComplete autoCompRes={handleAutoComp}/>
                    </Box>
                )
            }
        </Box>
    )
}

function LeafletAutoComplete({autoCompRes}) {
    const [latlng, setLatlng] = useState(null)
    const [searched, setSearched] = useState(null)
    const [addressTxt, setAddressTxt] = useState('')
    const [searching, setSearching] = useState(false)
    const [showAddress, setShowAddress] = useState(false)

    function searchLocation(e) {
        setAddressTxt(e.target.value)
        e.target.value.length >= 3 ? setShowAddress(true) : setShowAddress(false)
        setSearching(true)
        axios.get(`https://api.geoapify.com/v1/geocode/autocomplete?text=${e.target.value}&filter=countrycode:np&apiKey=68725a46c50b4690bf4c6d41efde7963`)
            .then(res => {
                setSearched(res.data.features)
            }).catch(err => console.log(err)).finally(() => setSearching(false))
    }

    function locationClicked(properties) {
        return () => {
            let res = {
                lat: properties.lat,
                lng: properties.lon
            }
            setShowAddress(false)
            setLatlng(res)
            setAddressTxt(properties.formatted)
            autoCompRes(res)
        }
    }

    return (
        <Box>
            <Paper sx={{position: 'relative'}}>
                <Box
                    display='flex'
                    alignItems='center'
                    gap={1}
                >
                    <Box sx={{ml: 4, py: 1, flexGrow: 1}}>
                        <InputBase type='text' placeholder='Search Maps' fullWidth onChange={searchLocation}
                                   value={addressTxt}/>
                    </Box>
                    <Box px={2} py={1} role='button'>
                        {
                            searching ? (
                                <CircularProgress size={24}/>
                            ) : (
                                <Search className='fs-3'/>
                            )
                        }

                    </Box>
                </Box>
                {
                    showAddress && (
                        <Box width='100%' borderTop={1} borderColor='divider'>
                            <Paper sx={{py: 1}}>
                                {
                                    searched?.map((item, idx) => (
                                        <div className="px-4 py-2 d-flex align-items-center gap-2" role='button'
                                             onClick={locationClicked(item?.properties)}>
                                            <FmdGoodOutlined className='fs-6'/>
                                            <span className="fs-6">{item?.properties?.formatted}</span>
                                        </div>
                                    ))
                                }
                            </Paper>
                        </Box>
                    )
                }

            </Paper>

        </Box>
    )
}

function LocationClicked({onClick}) {
    const map = useMapEvents({
        click(e) {
            onClick(e)
        }
    })
}

function ChangeView({center, zoom}) {
    const map = useMap()
    map.setView(center, zoom)
    return null;
}

export default LeafletMap
