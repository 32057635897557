import React from 'react'
import "./OrderVerifyingUI.scss"
import LoadingSpinner from '../../LoadingSpinner'
import Loader from '../../pages/Loader'

function OrderVerifyingUI() {
    return (
        <div className="order-verifying">
            <div className="container">
                <div className='text-center'>
                    <Loader/>
                    <br/>
                    <h4 className='text-light'>Verifying Your Payment. Please Wait!</h4>
                </div>
            </div>
        </div>
    )
}

export default OrderVerifyingUI
