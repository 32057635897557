import {PlayArrowOutlined} from '@mui/icons-material'
import {Grid, Button, TextField} from '@mui/material'
import React, {useState} from 'react'
import {useSearchParams} from 'react-router-dom'
import {FilterItem} from './FilterItem'

function ProductFilterPrice() {
    const [priceRange, setPriceRange] = useState({
        min: null,
        max: null
    })
    const [searchParams, setSearchParams] = useSearchParams()

    function handleChange(e) {
        setPriceRange({
            ...priceRange,
            [e.target.name]: e.target.value
        })
    }

    function handleClick(e) {
        const objParams = Object.fromEntries(searchParams)
        setSearchParams({
            ...objParams,
            min_price: priceRange.min,
            max_price: priceRange.max
        })
    }

    return (
        <FilterItem filterTitle={'Price'}>
            <Grid container alignItems={'center'} justifyContent={'space-evenly'}>
                <Grid item xs={4}>
                    <TextField label="Min" type='number' size='small' value={priceRange.min} name='min'
                               onChange={handleChange}/>
                </Grid>
                <Grid item xs={1} className="d-flex justify-content-center">
                    -
                </Grid>
                <Grid item xs={4}>
                    <TextField label='Max' type='number' value={priceRange.max} size='small' name='max'
                               onChange={handleChange}/>
                </Grid>
                <Grid item xs={3}>
                    <Button sx={{display: 'block', height: '100%'}} variant='contained' onClick={handleClick}>
                        <PlayArrowOutlined/>
                    </Button>
                </Grid>
            </Grid>
        </FilterItem>
    )
}

export default ProductFilterPrice
