import React, {useState} from "react";
import {useChkLogin} from "../../../../middlewares/LoginMiddleware";
import {DateFormat} from "../../../DateFormat";
import {useQuery} from "@tanstack/react-query";
import axios from "../../../api";
import {Alert, AlertTitle, Box, CircularProgress} from "@mui/material";
import {Settings, Upload} from "@mui/icons-material";
import {Link, useNavigate} from "react-router-dom";
import CsvUpload from "../../schoolDashboard/csvupload/CsvUpload";
import {GoogleMap, Marker} from "@react-google-maps/api";
import StudentUnpaid from "../studentLists/StudentUnpaid";
import PdfIcon from "../../../../images/pdfIcon.png";


function CorporateProfile() {
    const {token, data} = useChkLogin();
    const [openCsv, setOpenCsv] = useState(false);
    const navigate = useNavigate();
    const csvStatus = useQuery(["csvstatus"], async () =>
        axios
            .get("api/admin/import/status", {
                headers: {Authorization: `Bearer ${token}`},
            })
            .then((res) => res.data)
    );
    const mypackage = useQuery(["package-details-data", data.id], async () => {
        return axios
            .get("/api/admin/userpackage", {
                headers: {Authorization: `Bearer ${token}`},
            })
            .then((res) => res.data);
    });
    const refetchAll = () => {
        csvStatus.refetch();
        mypackage.refetch();
    };
    let companyInfo = data.school_profile;

    if (companyInfo === null) {
        return;
    }

    return (
        <div>
            <div className="mb-4">
                <StudentUnpaid/>
            </div>
            {companyInfo.types == "school" && (
                <>
                    {companyInfo.status == "pending" ? (
                        <Alert severity="warning" sx={{mb: 2}}>
                            <AlertTitle>
                                Your school profile is being verified by GD Admin.
                            </AlertTitle>
                        </Alert>
                    ) : companyInfo.status == "verified" ? (
                        !csvStatus.isLoading &&
                        !mypackage.isLoading &&
                        companyInfo.types == "school" && (
                            <>
                                {Object.keys(csvStatus.data).length === 0 ? (
                                    <Alert severity="warning" sx={{mb: 2}}>
                                        <AlertTitle>
                                            Please upload the csv file to continue.
                                        </AlertTitle>
                                    </Alert>
                                ) : csvStatus.data.status == 0 ? (
                                    <Alert severity="warning" sx={{mb: 2}}>
                                        <AlertTitle>
                                            Your CSV file has been uploaded successfully and send for
                                            verification.
                                        </AlertTitle>
                                    </Alert>
                                ) : csvStatus.data.status == 2 ? (
                                    <Alert severity="error" sx={{mb: 2, boxShadow: 1}}>
                                        <AlertTitle>CSV Rejected.</AlertTitle>
                                        <span className="fs-6 fw-light">
                      Your CSV file has been rejected. Please reupload your csv.
                    </span>
                                        <div className="d-flex gap-2 mt-1">
                                            <span className="fs-6 fw-light">Reason:</span>
                                            <span className="fs-6 fw-bold">
                        {csvStatus.data?.reject_reason}
                      </span>
                                        </div>
                                    </Alert>
                                ) : (
                                    mypackage.data.package == null && (
                                        <Alert
                                            severity="info"
                                            sx={{border: 1, borderColor: "divider", mb: 2}}
                                        >
                                            <AlertTitle>Buy Package</AlertTitle>
                                            <span className="fs-6 fw-light">
                        You haven't bought any packages. Buy package now.
                      </span>{" "}
                                            <br/>
                                            <button
                                                className="btn btn-primary mt-2"
                                                onClick={() =>
                                                    navigate(
                                                        "/single-package/package-School-Membership-jxd"
                                                    )
                                                }
                                            >
                                                Buy Package
                                            </button>
                                        </Alert>
                                    )
                                )}
                            </>
                        )
                    ) : (
                        <Alert severity="error" sx={{mb: 2}}>
                            <AlertTitle>Your school profile was rejected.</AlertTitle>
                            <div>
                                <span className="fs-6 fw-light">Reject Reason:</span>
                                <span className="fs-6 fw-bold">
                  {companyInfo.reject_reason}
                </span>
                            </div>
                        </Alert>
                    )}
                </>
            )}
            <div className="mb-3 d-flex align-items-center gap-4">
                <h3 className="mb-0 font-weight-semibold">Corporate Profile</h3>
                <button
                    className={`btn ${
                        companyInfo.status == "pending"
                            ? "btn-warning"
                            : companyInfo.status == "verified"
                                ? "btn-success"
                                : "btn-danger"
                    }`}
                    disabled
                >
                    <span className="text-capitalize">{companyInfo.status}</span>
                </button>
                <div className="d-flex ml-auto gap-4">
                    {data.school_profile.types === "school" && (
                        <>
                            {companyInfo.status == "verified" && (
                                <>
                                    {csvStatus.isLoading ? (
                                        <button className="btn btn-primary">
                                            <CircularProgress size={16}/>
                                        </button>
                                    ) : (
                                        <>
                                            {csvStatus.data?.status == 0 ? (
                                                <></>
                                            ) : (
                                                <button
                                                    className="btn btn-primary d-flex gap-2 align-items-center"
                                                    onClick={() => setOpenCsv(true)}
                                                >
                                                    <Upload sx={{fontSize: "14px"}}/>
                                                    Add Students
                                                </button>
                                            )
                                                // csvStatus.data?.status == 1 ?
                                                //     <button className='btn btn-primary d-flex gap-2 align-items-center' disabled>
                                                //         CSV verified
                                                //     </button>
                                                //     :
                                                //     <button className='btn btn-primary d-flex gap-2 align-items-center' onClick={() => setOpenCsv(true)}>
                                                //         <Upload sx={{ fontSize: '14px' }} />
                                                //         Upload CSV
                                                //     </button>
                                            }
                                        </>
                                    )}
                                </>

                                // <button className='btn btn-primary d-flex gap-2 align-items-center' onClick={() => setOpenCsv(true)}>
                                //     <Upload sx={{ fontSize: '14px' }} />
                                //     Upload CSV
                                // </button>
                            )}

                            <CsvUpload
                                open={openCsv}
                                handleClose={() => setOpenCsv(false)}
                                refetch={refetchAll}
                            />
                        </>
                    )}
                    {companyInfo.status !== "verified" &&
                        (companyInfo.status === "rejected" ? (
                            <Link
                                to="../edit-corporate-profile"
                                className="btn btn-primary d-flex gap-2 align-items-center"
                            >
                                <Settings sx={{fontSize: "14px"}}/>
                                Reapply
                            </Link>
                        ) : (
                            <Link
                                to="../edit-corporate-profile"
                                className="btn btn-primary d-flex gap-2 align-items-center"
                            >
                                <Settings sx={{fontSize: "14px"}}/>
                                Edit{" "}
                                {data.school_profile.types === "school"
                                    ? "School"
                                    : "Corporate"}{" "}
                                Profile
                            </Link>
                        ))}
                </div>
            </div>
            <div className="row">
                <div className="col-lg-8">
                    <div className="card">
                        <div className="card-body border-bottom border-primary">
                            <div className="row">
                                <div className="col-3">
                                    <div className="company-img w-100">
                                        <img
                                            src={companyInfo.company_image_path}
                                            alt=""
                                            className="w-100 ratio ratio-1x1"
                                            style={{aspectRatio: "1/1"}}
                                        />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="mb-2">
                    <span className="fs-3 fw-bold">
                      {companyInfo.company_name || "Company name"}
                    </span>
                                    </div>
                                    <div className="mb-2">
                                        <span>{companyInfo.company_address}</span>
                                    </div>
                                    <div className="mb-2">
                                        <span className="fw-normal">{companyInfo.user_name}</span>
                                    </div>
                                    <div classNameName="mb-2">
                    <span classNameName="fw-normal">
                      {companyInfo.company_start_date}
                    </span>
                                    </div>
                                </div>
                                <div className="col-5">
                                    <div
                                        className="pb-2 mb-2 border-bottom border-primary"
                                        style={{width: "fit-content"}}
                                    >
                    <span className="fs-5 fw-bold text-primary">
                      Description
                    </span>
                                    </div>
                                    <div>{companyInfo.description}</div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-7">
                                    <div
                                        className="pb-2 mb-2 border-bottom border-primary"
                                        style={{width: "fit-content"}}
                                    >
                    <span className="fs-5 fw-bold text-primary">
                      Information
                    </span>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-6">Address:</div>
                                        <div className="col-6">{companyInfo.company_address}</div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-6">Owner:</div>
                                        <div className="col-6">{companyInfo.owner_name}</div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-6">Address:</div>
                                        <div className="col-6">{companyInfo.company_address}</div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-6">PAN No:</div>
                                        <div className="col-6">{companyInfo.pan_number}</div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-6">Contact Number:</div>
                                        <div className="col-6">+{companyInfo.contact_number}</div>
                                    </div>
                                    {/* <div className="row mb-2">
                                        <div className="col-6">
                                            Email Address:
                                        </div>
                                        <div className="col-6">
                                            admin@gmail.com
                                        </div>
                                    </div> */}
                                    <div className="row mb-2">
                                        <div className="col-6">Company Start Date:</div>
                                        <div className="col-6">
                                            {DateFormat(
                                                companyInfo.company_start_date
                                            ).getFullDateText()}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    {/* <div className="pb-2 border-bottom border-primary" style={{ width: 'fit-content' }}>
                                        <span className="fs-5 fw-bold text-primary">
                                            List of Members
                                        </span>
                                    </div> */}
                                    <Box height="300px" sx={{overflowY: "auto"}}>
                                        <ListOfMembers/>
                                    </Box>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="card mb-3">
                        <div className="card-header bg-primary text-white">
                            <h6 className="card-title">Uploaded Documents</h6>
                        </div>
                        <div className="card-body">
                            <div className="d-flex gap-4 items-center">
                                <a
                                    href={companyInfo.paper_work_pdf_path}
                                    target="_blank"
                                    style={{all: "unset", display: "block", cursor: "pointer"}}
                                >
                                    <div className="d-flex flex-column align-items-center">
                                        <img
                                            src={PdfIcon}
                                            alt="company paper work pdf"
                                            style={{
                                                width: "80px",
                                            }}
                                        />
                                        <a
                                            href={companyInfo.paper_work_pdf_path}
                                            target="_blank"
                                            style={{fontWeight: 600}}
                                        >
                                            Company Paperwork
                                        </a>
                                    </div>
                                </a>

                                <a
                                    href={companyInfo.school_regd_file_path}
                                    target="_blank"
                                    style={{all: "unset", display: "block", cursor: "pointer"}}
                                >
                                    <div className="d-flex flex-column align-items-center">
                                        <img
                                            src={PdfIcon}
                                            alt="School Registration File"
                                            style={{
                                                width: "80px",
                                            }}
                                        />
                                        <a
                                            href={companyInfo.school_regd_file_path}
                                            target="_blank"
                                            style={{fontWeight: 600}}
                                        >
                                            Registration File
                                        </a>
                                    </div>
                                </a>
                            </div>
                        </div>
                        {/* <div>
                            <iframe src={companyInfo.paper_work_pdf_path} frameborder="0" width='100%' height='320px'></iframe>
                        </div> */}
                    </div>
                    <div className="card">
                        <div className="location-map position-relative">
                            <GoogleMap
                                mapContainerStyle={{width: 427, height: 210}}
                                center={{
                                    lat: Number(companyInfo.latitude),
                                    lng: Number(companyInfo.longitude),
                                }}
                                zoom={15}
                            >
                                <Marker
                                    position={{
                                        lat: Number(companyInfo.latitude),
                                        lng: Number(companyInfo.longitude),
                                    }}
                                    draggable={false}
                                />
                            </GoogleMap>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function ListOfMembers(show = 5) {
    let loginObj = useChkLogin();
    const {data, isLoading} = useQuery(["family", loginObj.data.id], async () =>
        axios
            .get("api/admin/addfamily", {
                headers: {Authorization: `Bearer ${loginObj.token}`},
            })
            .then((res) => res.data)
    );
    if (isLoading) {
        return;
    }
    if (data.length === 0) {
        return (
            <Alert severity="warning" sx={{mt: 1}}>
                <AlertTitle>No members added yet.</AlertTitle>
            </Alert>
        );
    }

    function getList() {
        let list = [];
        data.forEach((item, idx) => {
            if (idx > show) {
                return;
            }
            list.push(
                <div
                    className="row w-100 py-2 g-2 align-items-center border-bottom border-primary"
                    key={item.id}
                >
                    <div className="col-2">
                        <div className="w-100 p-1 bg-success text-center rounded">
              <span className="fs-5 text-white">
                {item.member.user.name.charAt(0)}
              </span>
                        </div>
                    </div>
                    <div className="col-10">
                        <div className="mb-1">
                            <span className="fs-6">{item.member.user.name}</span>
                        </div>
                        <div className="text-muted">
                            {item.family_relation != null && (
                                <>
                                    <span>{item.family_relation}</span>
                                    <span> | </span>
                                </>
                            )}

                            <span>{item.member.member_type}</span>
                        </div>
                    </div>
                </div>
            );
        });
        return (
            <>
                <div
                    className="pb-2 border-bottom border-primary d-flex gap-4 align-items-center"
                    style={{width: "fit-content"}}
                >
                    <span className="fs-5 fw-bold text-primary">List of Students</span>
                    <span
                        className="fs-6 badge badge-primary"
                        style={{fontWeight: 550}}
                    >
            Total: {data.length}
          </span>
                </div>
                {list}
            </>
        );
    }

    return <>{getList()}</>;
}

export default CorporateProfile;
