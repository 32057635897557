import React, {useEffect, useState} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import axios from "../../api"
import swal from 'sweetalert';
import "./ServiceDetail.scss"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleChevronRight} from '@fortawesome/free-solid-svg-icons';
import ServiceBooking from './ServiceBooking';
import BookService from './BookService';

function ServiceDetail() {
    const location = useLocation();
    const [service, setService] = useState(null);
    const token = localStorage.getItem('token');
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    //console.log();
    const params = useParams()

    useEffect(() => {
        if (location.state != null) {
            setService(location.state.service)
            return
        }
        axios.get("api/health-service")
            .then((res) => {
                setService(res.data.filter(serv => serv.slug == params.slug)[0])
            })
    }, [])

    useEffect(() => {
        axios
            .get("/api/admin/user-profile", {
                headers: {Authorization: `Bearer ${token}`},
            })
            .then((res) => {
                // console.log();
                setUser(res.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log();
            });
    }, [token]);

    const checkUser = () => {
        if (user.gender == null && user.dob == null) {
            swal({
                title: "User Profile",
                text: "Please update your profile!",
                icon: "warning",
            }).then((value) => {
                if (value) {
                    navigate("/user/profile")
                }
            });
        } else {
            navigate("/book-service", {state: {service: service}})
        }
    }

    if (service == null) {
        return (
            <div className="container">
                <div className="alert alert-danger mt-4">
                    There are no Services
                </div>
            </div>

        )
    }

    return (
        <div className="service-detail-page py-5">
            <div className="container">
                <div className="row">
                    <div className="col-8">
                        <div className="service-details">
                            <div className="row g-5">
                                <div className="col-5">
                                    <div className="service-image">
                                        <img
                                            src="https://media.istockphoto.com/id/1299928264/vector/liver-healthcare-hepatitis-world-day-concept-of-liver-disease-cirrhosis-cancer-awareness.jpg?s=612x612&w=0&k=20&c=VdqdggA7wXIszK0e9hTDeXcInKV3ijPNDsxUFcGOg4o="
                                            alt=""/>
                                    </div>
                                </div>
                                <div className="col-7">
                                    <div className="service-description">
                                        <div className="service-title">
                                            <span>{service.service_name}</span>
                                        </div>
                                        <div className="provided-by">
                                            <span className='provided-by-name'>Provided By : GHARGHARMA DOCTOR</span>
                                            <br/>
                                            {/* <div className="d-flex align-items-center">
                                                <span class="material-symbols-outlined">
                                                    schedule
                                                </span>
                                                <span className="text-muted ms-2">October 21, 2022</span>
                                            </div> */}
                                        </div>
                                        <div className="service-price">
                                            <span>NPR. {service.price}</span>
                                        </div>
                                        <div className="service-description">
                                            <b>Description: </b>
                                            <p dangerouslySetInnerHTML={{__html: `${service.description}`}}></p>
                                            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eum a nulla
                                                minus cum magnam inventore delectus repellendus repudiandae veritatis
                                                rerum dolores, ut, laudantium alias earum error temporibus tempora
                                                cumque in modi facere velit. Rem dolor deserunt ullam expedita
                                                perspiciatis id!</p>
                                        </div>
                                        <div className="included-tests">
                                            <b>Included Tests:</b>

                                            <ul className="list-unstyled">
                                                {service.tests.map((test) =>
                                                    <li className="mb-2" key={test.id}>
                                                        <FontAwesomeIcon
                                                            icon={faCircleChevronRight}
                                                            className="me-2 icon-content" style={{color: '#0259A7'}}
                                                        />
                                                        <span className="silver-text">{test.tests}</span>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="book-service p-4 pb-5">
                            <BookService service={service}/>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceDetail
