import React from 'react'
import {Link} from 'react-router-dom';
import axios from "../../api";

const SpecialityCard = (props) => {
    const department = props.department;
    const [depCount, setDepCount] = React.useState(0)
    React.useEffect(() => {
        axios.get("api/countDepartment?department=" + department.id)
            .then((res) => {
                setDepCount(res.data.count)
            })
    })
    return (
        <div key={department.id} className='transition-container'>
            <div className='speciality-icon icon-pop-transition'>
                <img src={department.image_path} alt=""/>
            </div>
            <div className='text-center mt-2'>
                <p className='mb-0 department-name'>{department.department}</p>
                <p className='mb-0' style={{fontWeight: 500}}>({department.department_nepali})</p>
                <p className="mb-2 department-doctor mb-3">{depCount == null ? 0 : depCount} Doctors</p>
                <Link to={`/booking/doctor/?department=${department.id}`} className='consult-btn'>Consult Now</Link>
            </div>
        </div>
    )
}

export default SpecialityCard
