import axios from "../api.js";
import {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import "./StoreNavVendor.scss";

const parentPath = "/store";

const VendorTypeList = ({vendor, children}) => {
    return (
        // ${depType.id}

        <li className="nav-item me-4" key={vendor.id}>
            {vendor.vendor_type}
            {children}
        </li>
    );
};

const VendorCategoryList = ({vendor}) => {
    const [categories, setCategories] = useState([]);
    // const navigate = useNavigate();
    useEffect(() => {
        axios
            .get("api/categories/vendortype?vendor_type_id=" + vendor.id)
            .then((res) => {
                setCategories(res.data);
            })
            .catch((err) => console.log());
    }, [vendor.id]);
    if (categories.length === 0) {
        return <></>;
    }
    return (
        <div className="drop-down-category">
            <ul className="row w-100">
                {categories.map((category) => {
                    return (
                        <li className="col-6 my-2 text-center">
                            <Link to={`${parentPath}/${vendor.id}/${category.id}`}>
                                {category.name}
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

const StoreNavVendor = () => {
    const [departmentTypes, setDepartmentTypes] = useState([]);

    useEffect(() => {
        axios.get("api/vendor-type").then((res) => {
            setDepartmentTypes(res.data);
        });
    }, []);

    return (
        <div className="nav-bar-vendors">
            <div className="card">
                <div className="card-header w-100">
                    <div className="container">
                        <ul className="navbar-nav d-flex flex-row">
                            {departmentTypes.map((depType) => {
                                return (
                                    // ${depType.id}

                                    // <Link to={`/store/`}>
                                    //   <li className="nav-item me-4" key={depType.id}>
                                    //     {depType.vendor_type}
                                    //   </li>
                                    // </Link>
                                    <VendorTypeList vendor={depType}>
                                        <VendorCategoryList vendor={depType}/>
                                    </VendorTypeList>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StoreNavVendor;
