import {Box, InputBase} from "@mui/material"
import {useRef} from "react"
import {useEffect} from "react"
import {useState} from "react"

export function PhoneVerifyInput({
                                     length, onChange = () => {
    }
                                 }) {
    const [focused, setFocused] = useState(0)
    const [values, setValues] = useState([])
    let inputGroup = []

    // console.log()


    function updateValues(newValues, nextStep) {
        onChange(newValues.join(''), nextStep === length)
        setValues(prev => (newValues))
    }

    function handleChange(e) {
        // console.log()
        if (e.target.value === '') return
        setValues([...values, e.target.value])
    }

    function focusNext() {
        if (focused < length - 1) {
            setFocused(prev => prev + 1)
        }
    }

    function focusBack() {
        if (focused > 0) {
            setFocused(prev => prev - 1)
        }
    }

    // function handleBack() {
    //   console.log()
    //   setFocused(prev => prev-1)
    // }

    // useEffect(() => {
    //   console.log()
    // }, [])

    for (var i = 0; i < length; i++) {
        inputGroup.push(
            <SingleInput focused={focused} index={i} next={focusNext} back={focusBack} val={values}
                         updateVal={updateValues}/>
        )
    }
    return (
        <div className="d-flex gap-4">
            {inputGroup}
        </div>
    )
}

function SingleInput({focused, val, index, back, next, updateVal}) {
    const [value, setValue] = useState(val[index])
    const inputRef = useRef(null)

    // console.log()

    function onChange(e) {
        if (value) return
        val[index] = e.target.value
        updateVal(val, focused + 1)
        setValue(e.target.value)
        next()
    }

    function onBackSpace(e) {
        if (e.key != 'Backspace') return
        val[index] = ''
        updateVal(val)
        setValue('')
        if (value === '' || value === undefined || value === null) {
            back()
        }
    }

    function handleClick(e) {
        if (focused != index) {
            e.preventDefault()
        }
    }

    useEffect(() => {
        if (focused === index) {
            inputRef.current.focus()
            val[index] = ''
            updateVal(val)
            setValue('')
        } else {
            inputRef.current.blur()
        }
    }, [focused])

    return (
        <Box flexGrow={1} border={1} borderColor='divider' borderRadius={2} position='relative'
             boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' sx={{aspectRatio: '1/1'}}>
            <InputBase inputRef={inputRef} type='text' value={value} onMouseDown={handleClick} onChange={onChange}
                       onKeyUp={onBackSpace}
                       sx={{height: '100%', '& input': {textAlign: 'center', fontSize: '2.3rem'}}}/>
        </Box>
    )
}
