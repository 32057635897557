import React from 'react'
import KhaltiCheckout from "khalti-checkout-web";
import KhaltiLogo from '../../../images/payment/khalti.png';
import axios from "../../api.js";
import swal from "sweetalert";
import {useNavigate} from 'react-router-dom';
import {Button} from '@mui/material';

function KhaltiFitness({packageId, identity, package_amount, setVerifying, title, icon = null}) {
    const usertoken = localStorage.getItem('token');
    const amount = package_amount * 100;
    // const id = props.id;
    // const title = props.title;
    let navigate = useNavigate();
    const publicKey = process.env.REACT_APP_KhaltiPublicTestKey
    let config = {
        // replace this key with yours
        "publicKey": publicKey,
        "productIdentity": identity,
        "productName": "Ghargharma Doctor",
        "productUrl": "https://react.ghargharmadoctor.com/",
        "eventHandler": {
            onSuccess(payload) {
                // console.log();
                // hit merchant api for initiating verfication
                axios
                    .post('/api/fitness/fitness-payment', {
                        token: payload.token,
                        total_amount: payload.amount,
                        id: packageId
                    }, {headers: {"Authorization": `Bearer ${usertoken}`}})
                    .then((res) => {
                        // console.log();
                        if (res.data.success) {
                            setVerifying(false)
                            swal({
                                title: res.data.success,
                                icon: "success",
                            }).then((value) => {
                                if (value) {
                                    navigate("/user/orders")
                                }
                            });
                        }
                        if (res.data.error) {
                            setVerifying(false)
                            swal({
                                title: res.data.error,
                                icon: "warning",
                            }).then((value) => {
                                if (value) {
                                    navigate("/user/orders")
                                }
                            });
                        }
                    })

            },
            // onError handler is optional
            onError(error) {
                // handle errors
                setVerifying(false)
                console.log();
            },
            onClose() {
                setVerifying(false)
                // console.log();
            }
        },
        "paymentPreference": ["KHALTI"],
    };
    let checkout = new KhaltiCheckout(config);

    const checkoutPayment = () => {
        setVerifying(true)
        checkout.show({amount: amount})
    }

    return (
        <div>
            <Button onClick={() => checkout.show({amount: amount})}>
                {
                    icon !== null ?
                        icon
                        :
                        <img src={KhaltiLogo} alt=""/>
                }
            </Button>
        </div>
    )
}

export default KhaltiFitness
