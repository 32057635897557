import {Box, Button} from '@mui/material'
import React, {useContext, useEffect, useState} from 'react'
import PhoneInput from 'react-phone-input-2'
import {InputField, RadioInput, RadioInputContain} from '../../../../custom_componets/FormComponents'
import {useGlobalFormData} from '../GlobalFormProvider'
import {LoginStateContext} from '../../../../../contexts/LoginStateContext'
import axios from '../../../../api'

function generateInitial(initialObj, allFormData) {
    const stateObj = {}
    initialObj.forEach((obj) => {
        stateObj[obj] = allFormData[obj] || ""
    })
    return stateObj
}

function DeclareAndService({handleBack, handleNext, allFormData, setAllFormData, chkOptions, setChkOptions}) {
    const {globalForm, refetchGlobalForm} = useGlobalFormData()
    const [inputs, setInputs] = useState(generateInitial([
        'nominee_name',
        'nominee_father_name',
        'nominee_grandfather_name',
        'nominee_relation',
        'nominee_citizenship_issued_place',
        'nominee_citizenship_number',
        'nominee_birthdate',
        'nominee_permanent_address',
        'nominee_current_address',
        'nominee_phone_number',
        'beneficiary_name',
        'beneficiary_address',
        'beneficiary_contact_number',
        'beneficiary_relation',
        'are_you_nrn',
        'us_citizen',
        'us_residence',
        'criminal_offence',
        'green_card',
        'account_in_other_banks',
        'service_form'
    ], globalForm))
    const [errors, setErrors] = useState([])
    const [nomineeChk, setNomineeChk] = useState(false)
    const [beneficiaryChk, setBeneficiaryChk] = useState(false)
    const token = useContext(LoginStateContext).state.token

    useEffect(() => {
        let options = {
            are_you_nrn: inputs.are_you_nrn || 0,
            us_citizen: inputs.us_citizen || 0,
            us_residence: inputs.us_residence || 0,
            criminal_offence: inputs.criminal_offence || 0,
            green_card: inputs.green_card || 0,
            account_in_other_banks: inputs.account_in_other_banks || 0,
            service_form: inputs.service_form || 0
        }
        console.log()
        setInputs({
            ...inputs,
            ...options
        })
    }, [])

    function toggleChecked(e, func) {
        setChkOptions(prev => (
                {
                    ...prev,
                    [e.target.name]: e.target.checked
                }
            )
        )
        func(e.target.checked)
    }

    function handleSubmit(e) {
        e.preventDefault()
        // setAllFormData({ ...allFormData, ...inputs })
        axios.post(`api/kyc/declaration-and-services/${globalForm.id}`, inputs, {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                console.log()
                handleNext()
                refetchGlobalForm()
            })
    }

    function handleChange(e) {
        setInputs({...inputs, [e.target.name]: e.target.value})
    }

    return (
        <React.Fragment>
            <form className='form-horizontal' onSubmit={handleSubmit}>
                <div className="card">
                    <div className="card-header">
                        <h3 className="m-0 p-0">Nominee Details</h3>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-4">
                                <div className="form-check">
                                    <input type="checkbox" name="nomineeChk" checked={chkOptions.nomineeChk}
                                           className="form-check-input" id="toggleNominee" onChange={(e) => {
                                        toggleChecked(e, setNomineeChk)
                                    }}/>
                                    <label className='form-check-label' htmlFor="toggleNominee"><h5>Add Nominee</h5>
                                    </label>
                                </div>
                            </div>
                        </div>
                        {
                            chkOptions.nomineeChk &&
                            <div className="row">
                                <div className="col-md-4">
                                    <InputField
                                        label="Nominee Name"
                                        name="nominee_name"
                                        value={inputs.nominee_name}
                                        error={errors?.nominee_name}
                                        errorMsg={errors?.nominee_name ? errors.nominee_name.message : null}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="col-md-4">
                                    <InputField
                                        label="Nominee Father Name"
                                        name="nominee_father_name"
                                        value={inputs.nominee_father_name}
                                        error={errors?.nominee_father_name}
                                        errorMsg={errors?.nominee_father_name ? errors.nominee_father_name.message : null}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="col-md-4">
                                    <InputField
                                        label="Nominee Grandfather Name"
                                        name="nominee_grandfather_name"
                                        value={inputs.nominee_grandfather_name}
                                        error={errors?.nominee_grandfather_name}
                                        errorMsg={errors?.nominee_grandfather_name ? errors.nominee_grandfather_name.message : null}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="col-md-4">
                                    <InputField
                                        label="Nominee Relation"
                                        name="nominee_relation"
                                        value={inputs.nominee_relation}
                                        error={errors?.nominee_relation}
                                        errorMsg={errors?.nominee_relation ? errors.nominee_relation.message : null}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="col-md-4">
                                    <InputField
                                        label="Nominee Citizenship Number"
                                        name="nominee_citizenship_number"
                                        value={inputs.nominee_citizenship_number}
                                        error={errors?.nominee_citizenship_number}
                                        errorMsg={errors?.nominee_citizenship_number ? errors.nominee_citizenship_number.message : null}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="col-md-4">
                                    <InputField
                                        label="Nominee Citizenship Issued Place"
                                        name="nominee_citizenship_issued_place"
                                        value={inputs.nominee_citizenship_issued_place}
                                        error={errors?.nominee_citizenship_issued_place}
                                        errorMsg={errors?.nominee_citizenship_issued_place ? errors.nominee_citizenship_issued_place.message : null}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="col-md-4">
                                    <InputField
                                        label="Nominee Citizenship Issued Date"
                                        type="date"
                                        name="nominee_citizenship_issued_date"
                                        value={inputs.nominee_citizenship_issued_date}
                                        error={errors?.nominee_citizenship_issued_date}
                                        errorMsg={errors?.nominee_citizenship_issued_date ? errors.nominee_citizenship_issued_date.message : null}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="col-md-4">
                                    <InputField
                                        label="Nominee Birth Date"
                                        name="nominee_birth_date"
                                        type="date"
                                        value={inputs.nominee_birth_date}
                                        error={errors?.nominee_birth_date}
                                        errorMsg={errors?.nominee_birth_date ? errors.nominee_birth_date.message : null}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="col-md-4">
                                    <InputField
                                        label="Nominee Permanent Address"
                                        name="nominee_permanent_address"
                                        value={inputs.nominee_permanent_address}
                                        error={errors?.nominee_permanent_address}
                                        errorMsg={errors?.nominee_permanent_address ? errors.nominee_permanent_address.message : null}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="col-md-4">
                                    <InputField
                                        label="Nominee Current Address"
                                        name="nominee_current_address"
                                        value={inputs.nominee_current_address}
                                        error={errors?.nominee_current_address}
                                        errorMsg={errors?.nominee_current_address ? errors.nominee_current_address.message : null}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="col-md-4">
                                    <label className='form-label'>Nominee Phone Number</label>
                                    <PhoneInput
                                        country={'np'}
                                        onlyCountries={['np']}
                                        disableDropdown
                                        countryCodeEditable={false}
                                        inputProps={{
                                            name: 'phone',
                                            required: true,
                                            autoFocus: true,
                                        }}
                                        value={inputs.nominee_phone_number}
                                        onChange={phone => setInputs({...inputs, nominee_phone_number: phone})}
                                        inputStyle={{paddingLeft: '48px'}}
                                        required
                                    />
                                </div>
                            </div>

                        }

                    </div>
                </div>

                <div className="card">
                    <div className="card-header">
                        <h3 className="m-0 p-0">Benificiary Details</h3>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-4">
                                <div className="form-check">
                                    <input type="checkbox" name='beneficiaryChk' checked={chkOptions.beneficiaryChk}
                                           className="form-check-input" id="toggleBeneficiary" onChange={(e) => {
                                        toggleChecked(e, setBeneficiaryChk)
                                    }}/>
                                    <label className='form-check-label' htmlFor="toggleBeneficiary"><h5>Add
                                        Beneficiary</h5></label>
                                </div>
                            </div>
                        </div>
                        {
                            chkOptions.beneficiaryChk &&
                            <div className="row">
                                <div className="col-md-4">
                                    <InputField
                                        label="Beneficiary Name"
                                        name="beneficiary_name"
                                        value={inputs.beneficiary_name}
                                        error={errors?.beneficiary_name}
                                        errorMsg={errors?.beneficiary_name ? errors.beneficiary_name.message : null}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="col-md-4">
                                    <InputField
                                        label="Beneficiary Address"
                                        name="beneficiary_address"
                                        value={inputs.beneficiary_address}
                                        error={errors?.beneficiary_address}
                                        errorMsg={errors?.beneficiary_address ? errors.beneficiary_address : null}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="col-md-4">
                                    <InputField
                                        label="Beneficiary Contact Number"
                                        type='number'
                                        name="beneficiary_contact_number"
                                        value={inputs.beneficiary_contact_number}
                                        error={errors?.beneficiary_contact_number}
                                        errorMsg={errors?.beneficiary_contact_number ? errors.beneficiary_contact_number.message : null}
                                        onChange={handleChange}
                                        required
                                    />
                                    <label className='form-label'>Beneficiary Contact Number</label>
                                    <PhoneInput
                                        country={'np'}
                                        onlyCountries={['np']}
                                        disableDropdown
                                        countryCodeEditable={false}
                                        inputProps={{
                                            name: 'phone',
                                            required: true,
                                        }}
                                        value={inputs.beneficiary_contact_number}
                                        onChange={phone => setInputs({...inputs, beneficiary_contact_number: phone})}
                                        inputStyle={{paddingLeft: '48px'}}
                                        required
                                    />
                                </div>
                                <div className="col-md-4">
                                    <InputField
                                        label="Beneficiary Relation"
                                        name="beneficiary_relation"
                                        value={inputs.beneficiary_relation}
                                        error={errors?.beneficiary_relation}
                                        errorMsg={errors?.beneficiary_relation ? errors.beneficiary_relation.message : null}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>

                        }

                    </div>
                </div>

                <div className="card">
                    <div className="card-header">
                        <h3 className="m-0 p-0">Declaration</h3>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-4">
                                <RadioInputContain name="are_you_nrn" label={"Are You NRN?"}>
                                    <RadioInput
                                        label="Yes"
                                        id="are_you_nrn_yes"
                                        value={1}
                                        checked={inputs.are_you_nrn == 1}
                                        type="radio"
                                        onChange={handleChange}
                                    />
                                    <RadioInput
                                        label="No"
                                        id="are_you_nrn_no"
                                        checked={inputs.are_you_nrn == 0}
                                        value={0}
                                        type="radio"
                                        onChange={handleChange}
                                    />
                                </RadioInputContain>
                            </div>
                            <div className="col-4">
                                <RadioInputContain name="us_citizen" label={"Are You A Foreigner?"}>
                                    <RadioInput
                                        label="Yes"
                                        id="us_citizen_yes"
                                        value={1}
                                        type="radio"
                                        checked={inputs.us_citizen == 1}
                                        onChange={handleChange}
                                    />
                                    <RadioInput
                                        label="No"
                                        id="us_citizen_no"
                                        value={0}
                                        checked={inputs.us_citizen == 0}
                                        type="radio"
                                        onChange={handleChange}
                                    />
                                </RadioInputContain>
                            </div>
                            <div className="col-4">
                                <RadioInputContain name="us_residence" label={"Are You A Foreign Residence?"}>
                                    <RadioInput
                                        label="Yes"
                                        id="us_residence_yes"
                                        value={1}
                                        checked={inputs.us_residence == 1}
                                        type="radio"
                                        onChange={handleChange}
                                    />
                                    <RadioInput
                                        label="No"
                                        id="us_residence_no"
                                        value={0}
                                        checked={inputs.us_residence == 0}
                                        type="radio"
                                        onChange={handleChange}
                                    />
                                </RadioInputContain>
                            </div>
                            <div className="col-4">
                                <RadioInputContain name="criminal_offence"
                                                   label={"Have you been charged with criminal offense in Nepal or any other Country?"}>
                                    <RadioInput
                                        label="Yes"
                                        id="criminal_offence_yes"
                                        value={1}
                                        checked={inputs.criminal_offence == 1}
                                        type="radio"
                                        onChange={handleChange}
                                    />
                                    <RadioInput
                                        label="No"
                                        id="criminal_offence_no"
                                        value={0}
                                        checked={inputs.criminal_offence == 0}
                                        type="radio"
                                        onChange={handleChange}
                                    />
                                </RadioInputContain>
                            </div>
                            <div className="col-4">
                                <RadioInputContain name="green_card" label={"Did you hold Green Card of any country?"}>
                                    <RadioInput
                                        label="Yes"
                                        id="green_card_yes"
                                        value={1}
                                        checked={inputs.green_card == 1}
                                        type="radio"
                                        onChange={handleChange}
                                    />
                                    <RadioInput
                                        label="No"
                                        id="green_card_no"
                                        checked={inputs.green_card == 0}
                                        value={0}
                                        type="radio"
                                        onChange={handleChange}
                                    />
                                </RadioInputContain>
                            </div>
                            <div className="col-4">
                                <RadioInputContain name="account_in_other_banks"
                                                   label={"Do you have any account with other banks/Financial institution?"}>
                                    <RadioInput
                                        label="Yes"
                                        id="account_in_other_banks_yes"
                                        value={1}
                                        checked={inputs.account_in_other_banks == 1}
                                        type="radio"
                                        onChange={handleChange}
                                    />
                                    <RadioInput
                                        label="No"
                                        id="account_in_other_banks_no"
                                        value={0}
                                        checked={inputs.account_in_other_banks == 0}
                                        type="radio"
                                        onChange={handleChange}
                                    />
                                </RadioInputContain>
                            </div>
                        </div>
                    </div>
                </div>

                <React.Fragment>
                    <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                        <Button
                            color="inherit"
                            onClick={handleBack}
                            sx={{mr: 1}}
                        >
                            Back
                        </Button>
                        <Box sx={{flex: '1 1 auto'}}/>
                        <button type="submit" className="btn btn-primary" onClick={(e) => {
                            console.log()
                            // handleNext()
                        }}>
                            Next
                        </button>
                    </Box>
                </React.Fragment>
            </form>
        </React.Fragment>
    )
}

export default DeclareAndService
