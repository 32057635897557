import React, {useContext} from "react";
import Logo from "../../../images/white-logo.png";
import {Link, useNavigate} from "react-router-dom";
import {ProfileContext} from "../../hoc/protected";
import {clearToken} from "../../../utils/common/token.utils";
import {useMutation} from "@tanstack/react-query";
import ParentService from "../../../services/parent.service";
import {toast} from "react-hot-toast";

const DashboardNav = () => {
    const profile = useContext(ProfileContext)
    const navigate = useNavigate();

    const {mutate:logout, isLoading} = useMutation({
        mutationFn: ParentService.parentLogout,
        onSuccess: (response) => {
            if (response?.success) {
                clearToken()
                navigate('/school-report');
                toast.success("Log out successful.")
            }
        },
        onError: () => {
            toast.error("Something went wrong.")
        }
    })

    return (
        <div
            className="navbar navbar-expand-lg navbar-dark navbar-static d-flex align-items-center justify-content-between ">
            <ul
                className="collapse navbar-collapse"
                id="navbar-mobile"
            >
                <Link to="/">
                    <img src={Logo} alt="Logo" width="70px" className="mr-2"/>
                    <span className="text-white" style={{fontSize: '25px'}}>GHARGHARMA DOCTOR</span>
                </Link>
            </ul>
            <div className="d-flex gap-3 align-items-center">
                <div>
                    {profile?.first_name}
                </div>
                <button type="button" className="btn btn-secondary btn-sm" onClick={logout}><i
                    className="icon-switch2"/> {isLoading ? "Logging out..." : "Logout"} </button>
            </div>
        </div>
    );
};

export default DashboardNav;
