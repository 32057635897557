import {Card, CardMedia} from '@mui/material'
import React, {useContext} from 'react'
import CatalogSlider from '../../gdstorehome/components/CatalogSlider'
import {GDVendorContext} from '../GDVendorContext'

const responsiveslidersetting = {
    responsive: [
        {
            breakpoint: 1300,
            settings: {
                slidesToShow: 4,

            }
        },
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 550,
            settings: {
                slidesToShow: 2,
            }
        }
    ]
}

function VendorBrands() {
    const {vendor, brands} = useContext(GDVendorContext)

    if (vendor.is_exculsive !== 2) {
        return
    }
    if (brands.isLoading) {
        return
    }
    if (brands.data.length === 0) {
        return
    }
    return (
        <Card sx={{px: 2}}>
            <CatalogSlider numItems={7} settingProps={responsiveslidersetting}>
                {
                    brands.data.map((brand) => (
                        <div>
                            <VendorBrandCard img={brand.image_path}/>
                        </div>
                    ))
                }
            </CatalogSlider>
        </Card>
    )
}

function VendorBrandCard({img = 'https://static-01.daraz.com.np/other/shop/656f8982964d847bd04573b461f71a23.png_300x300q75.jpg_.webp'}) {
    return (
        <Card sx={{width: '100%', cursor: 'pointer', "&:hover": {boxShadow: 4}}}>
            <CardMedia
                component='img'
                image={img}
                alt="Apple"
                sx={{
                    width: '100%',
                    aspectRatio: '1/1',
                }}
            />
        </Card>
    )
}

export default VendorBrands
