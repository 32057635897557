import {Box, CircularProgress, Stack, Typography} from '@mui/material'
import React from 'react'
import ItemDetails from './components/ItemDetails'
import {useBlogsPopular} from './GDBlogProvider'
import {DateFormat} from '../../DateFormat'
import SkeletonNewsItem from './components/SkeletonNewsItem'

function PopularPosts() {
    let {data, isLoading} = useBlogsPopular()
    // if (isLoading) return <CircularProgress />
    data = !isLoading ? data.slice(0, 4) : null
    return (
        <Stack gap={2}>
            {
                isLoading ? (
                    [...Array(5).keys()].map((item) => (
                        <SkeletonNewsItem/>
                    ))
                ) : (
                    data.map((item, idx) => (
                        <Box display='flex' gap={1}>
                            <Box>
                                <Typography variant='h2' color='text.disabled' lineHeight={0.7}>
                                    0{idx + 1}
                                </Typography>
                            </Box>
                            <ItemDetails
                                title={item.title_en}
                                author={item?.user?.name || 'Ghargharmadoctor'}
                                category={item.menu.title_en}
                                time={DateFormat(item.created_at).getFullDateText()}
                                blog={item}
                            />
                        </Box>
                    ))
                )

            }
            {/* <Box display='flex' gap={1}>
                <Box>
                    <Typography variant='h2' color='text.disabled' lineHeight={0.7}>
                        01
                    </Typography>
                </Box>
                <Box>
                    <ItemDetails
                        title='News Needs to Meet Its Audiences Where They Are'
                        author='Dave Rogers'
                        category='Heart Health'
                        time='Jun 14, 2023'
                    />
                </Box>
            </Box>
            <Box display='flex' gap={1}>
                <Box>
                    <Typography variant='h2' color='text.disabled' lineHeight={0.7}>
                        02
                    </Typography>
                </Box>
                <Box>
                    <ItemDetails
                        title='News Needs to Meet Its Audiences Where They Are'
                        author='Dave Rogers'
                        category='Heart Health'
                        time='Jun 14, 2023'
                    />
                </Box>
            </Box>
            <Box display='flex' gap={1}>
                <Box>
                    <Typography variant='h2' color='text.disabled' lineHeight={0.7}>
                        03
                    </Typography>
                </Box>
                <Box>
                    <ItemDetails
                        title='News Needs to Meet Its Audiences Where They Are'
                        author='Dave Rogers'
                        category='Heart Health'
                        time='Jun 14, 2023'
                    />
                </Box>
            </Box>
            <Box display='flex' gap={1}>
                <Box>
                    <Typography variant='h2' color='text.disabled' lineHeight={0.7}>
                        04
                    </Typography>
                </Box>
                <Box>
                    <ItemDetails
                        title='News Needs to Meet Its Audiences Where They Are'
                        author='Dave Rogers'
                        category='Heart Health'
                        time='Jun 14, 2023'
                    />
                </Box>
            </Box>
            <Box display='flex' gap={1}>
                <Box>
                    <Typography variant='h2' color='text.disabled' lineHeight={0.7}>
                        05
                    </Typography>
                </Box>
                <Box>
                    <ItemDetails
                        title='News Needs to Meet Its Audiences Where They Are'
                        author='Dave Rogers'
                        category='Heart Health'
                        time='Jun 14, 2023'
                    />
                </Box>
            </Box> */}
        </Stack>
    )
}

export default PopularPosts
