import React, {useContext, useState} from 'react'
import {Box, Grid, Rating, Skeleton, Tab, Tabs, useMediaQuery} from "@mui/material";
import {Link, useNavigate, useParams} from "react-router-dom";
import axios from "../../../api";
import "./ProductPage.css";
import {createContext} from 'react';
import {useQuery} from '@tanstack/react-query';
import ProductDetail from './components/ProductDetail';
import TabPanel from '../../../TabPanel';
import ProdLoadVerify from './components/ProdLoadVerify';
import ProductReview from '../../../stores/ProductReview';
import ProductDesc from './components/ProductDesc';
import HealthCenter from '../../../HealthCenter';
import ProductDetailTabs from './components/ProductDetailTabs';
import Recommended, {RecommendedLaptop, RecommendedTablet} from './components/Recommended';
import RelatedProducts from './components/RelatedProducts';

const ProductContext = createContext(null)

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function ProductDetPage() {
    const params = useParams()
    const {data, isLoading, refetch} = useQuery(
        ['product_detail', params.slug],
        async () => {
            return axios.get("api/products?slug=" + params.slug)
                .then(res => res.data.data[0]).catch(err => err)
        }
    )
    return (
        <ProductContext.Provider value={{product: data, isLoading: isLoading, prodRefetch: refetch}}>
            <div className="container-lg prod-page-container pt-5">
                <div className="prod-bas-detail">
                    <ProductDetail/>
                </div>

                <Grid container my={5}>
                    <Grid item xs={12} md={9} pr={3}>
                        <ProductDetailTabs/>
                        <RelatedProducts product={data}/>
                        <RecommendedTablet/>
                        <ProdLoadVerify>
                            <ProductReview/>
                        </ProdLoadVerify>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <RecommendedLaptop/>
                    </Grid>
                </Grid>

                <div className="mt-5">

                </div>
            </div>
        </ProductContext.Provider>
    );

}

// const ProductDetailTabs = () => {
//     const productCont = useContext(ProductContext)
//     const [tabValue, setTabValue] = useState(0)
//     const tabItems = {
//         how_to_use: "How To Use",
//         precaution: "Precaution",
//         side_effect: "Side Effects",
//     }

//     function changeTab(e, newValue) {
//         setTabValue(newValue)
//     }

//     if (productCont.isLoading) {
//         return
//     }
//     let index = 1;
//     let tabIndex = 1;
//     return (
//         <>
//             <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
//                 <Tabs value={tabValue} onChange={changeTab} aria-label="basic tabs example">
//                     <Tab label="Product Description" {...a11yProps(0)} />
//                     {
//                         Object.keys(tabItems).map((key) => {
//                             return (
//                                 productCont.product[key] !== null &&
//                                 <Tab label={tabItems[key]} {...a11yProps(index++)} />
//                             )
//                         })
//                     }
//                     {/* {
//                         productCont.product.how_to_use !== null &&
//                         <Tab label="How To Use" {...a11yProps(1)} />
//                     }
//                     {
//                         productCont.product.precaution !== null &&
//                         <Tab label="Precaution" {...a11yProps(2)} />
//                     }
//                     {
//                         productCont.product.side_effect !== null &&
//                         <Tab label="Side Effects" {...a11yProps(3)} />
//                     } */}
//                 </Tabs>
//             </Box>
//             <TabPanel value={tabValue} index={0}>
//                 <ProductDesc description={productCont.product.description} />
//             </TabPanel>
//             {
//                 Object.keys(tabItems).map((key) => {
//                     return (
//                         productCont.product[key] !== null &&
//                         <TabPanel value={tabValue} index={tabIndex++}>
//                             <ProductDesc description={productCont.product[key]} />
//                         </TabPanel>
//                     )
//                 })
//             }
//             {/* {
//                 productCont.product.how_to_use !== null &&
//                 <TabPanel value={tabValue} index={1}>
//                     <ProductDesc description={productCont.product.how_to_use} />
//                 </TabPanel>
//             }
//             {
//                 productCont.product.precaution !== null &&
//                 <TabPanel value={tabValue} index={2}>
//                     <ProductDesc description={productCont.product.precaution} />
//                 </TabPanel>
//             }

//             {
//                 productCont.product.side_effect !== null &&
//                 <TabPanel value={tabValue} index={3}>
//                     <ProductDesc description={productCont.product.side_effect} />
//                 </TabPanel>
//             } */}

//         </>
//     )
// }

export default ProductDetPage
export {ProductContext}
