import {useEffect} from "react";
import axios from "../../api";
import {useState} from "react";
import {getCookieToken} from "../../../helperFunctions/cookiesHelper";

function useRefreshToken(loginContext) {
    const [refreshed, setRefreshed] = useState(true)
    const [refreshing, setRefreshing] = useState(true)
    const [token, setToken] = useState(getCookieToken() || null)

    useEffect(() => {
        let timeout = setInterval(refreshToken, 40000)

        return () => {
            clearInterval(timeout)
        }
    }, [])


    async function refreshToken() {
        const tokenFromLocalStorage = localStorage.getItem('token_id')

        if (token && tokenFromLocalStorage !== "undefined") {

            try {
                const response = await axios.post('/api/check-token', {token_id: tokenFromLocalStorage}, {headers: {'Authorization': `Bearer ${token}`}})
                if (response.message !== 'Token is still valid') {
                    localStorage.setItem('token_id', '')
                    loginContext.expireToken()
                }
            } catch (e) {
                console.log(e)
            }

            setRefreshing(true)
            const cookieValue = document.cookie
                .split(";")
                .find((row) => row.startsWith("token="))
                ?.split("=")[1];
            if (!cookieValue || cookieValue == '') return
            axios.get('/api/revoke-token', {headers: {'Authorization': `Bearer ${cookieValue}`}}).then(res => {
                setToken(res.data.refresh_token)
                document.cookie = 'token=' + res.data.refresh_token + ';path=/;'
            })
                .catch(err => console.log(err))
                .finally(
                    () => {
                        setRefreshed(prev => !prev)
                        setRefreshing(false)
                    }
                )
        }

        return {refreshing, refreshed, token}
    }
}

export {useRefreshToken}
