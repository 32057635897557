import {Button, CircularProgress, Typography} from '@mui/material'
import React, {useContext, useState} from 'react'
import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {LoginStateContext} from '../../contexts/LoginStateContext'

function AuthButton({
                        variant = 'contained',
                        children,
                        callbackFn,
                        parameters = {},
                        disabled = false,
                        loading = false,
                        auth = false,
                        authVerfify,
                        label = null,
                        component,
                        type,
                        className,
                        style,
                        sx,
                        labelColor,
                        preventPropagation,
                        startIcon,
                        loaderColor = 'white'
                    }) {
    const [clicked, setClicked] = useState(false)
    const navigate = useNavigate()

    async function handleClick(e) {
        let token = ''
        if (preventPropagation) {
            e.preventDefault()
            if (e && e.stopPropagation) e.stopPropagation();
        }
        if (clicked || loading) return
        setClicked(true)
        let nextStep = true;
        if (auth) {
            try {
                const res = await authVerfify()
                token = res.state.token
                console.log()
            } catch (err) {
                nextStep = false
                navigate("/login", {state: {redirect_pg: window.location.pathname}})
            }
        }
        if (!nextStep) return
        try {
            await callbackFn(token, e, parameters)
        } catch (err) {
            console.log()
        }
        setClicked(false)
    }

    function btnStyle(elem) {
        // let disabled = (disabled || (type==='submit' && loading))
        if (disabled || (type === 'submit' && loading)) {
            disabled = true;
        }
        let btnProps = {
            className: className,
            type: (type === "submit" && loading) ? '' : type,
            onClick: handleClick,
            style: style,
            disabled: disabled
        }
        switch (component) {
            case 'div':
                return (
                    <div {...btnProps}>
                        {elem}
                    </div>
                )
            case 'button':
                return (
                    <button {...btnProps}>
                        {elem}
                    </button>
                )
            default:
                return (
                    <Button {...btnProps} variant={variant} sx={sx} startIcon={startIcon}>
                        {elem}
                    </Button>
                )
        }
    }

    return btnStyle(
        <Typography variant='button' sx={{color: labelColor}}>
            {
                clicked || loading ?
                    <CircularProgress size={"1rem"} sx={{color: loaderColor}}/>
                    :
                    <>
                        {
                            (label === null) ?
                                children
                                :
                                label
                        }
                    </>
            }
        </Typography>
    )

}

export default AuthButton

AuthButton.defaultProps = {
    callbackFn: () => {
    }
}
