import React from "react";
import KhaltiCheckout from "khalti-checkout-web";
import KhaltiLogo from "../../../images/payment/khalti.png";
import axios from "../../api.js";
import swal from "sweetalert";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import OrderVerifyingUI from "../../stores/checkout/OrderVerifyingUI";
import {useChkLogin} from "../../../middlewares/LoginMiddleware";

const KhaltiPackageAdditional = ({
                                     familyId,
                                     additionalPayment,
                                     interval,
                                     packageamount,
                                     userpackageId,
                                     totalamount,
                                     redirectLink,
                                 }) => {
    const [verifying, setVerifying] = useState();
    const {token} = useChkLogin()
    const usertoken = token;
    let navigate = useNavigate();
    const totalAmt = totalamount * 100;
    const publicKey = process.env.REACT_APP_KhaltiPublicTestKey;
    let config = {
        // replace this key with yours
        publicKey: publicKey,
        productIdentity: "1234567890",
        productName: "Ghargharma Doctor",
        productUrl: "https://react.ghargharmadoctor.com/",
        eventHandler: {
            onSuccess(payload) {
                //console.log();
                // hit merchant api for initiating verfication
                setVerifying(true);
                axios
                    .post(
                        "/api/admin/userpackage/joint-payment",
                        {
                            token: payload.token,
                            amount: payload.amount,
                            family_id: familyId,
                            payment_interval: interval,
                            id: userpackageId,
                            packageamount: packageamount,
                            additionalamount: additionalPayment.total_additonal_payment,
                            newadditionalamount: additionalPayment.total_newadditonal_payment,
                            payment_days: additionalPayment.payment_days
                        },
                        {headers: {Authorization: `Bearer ${usertoken}`}}
                    )
                    .then((res) => {
                        setVerifying(false);
                        //console.log();
                        if (res.data.success) {
                            swal({
                                title: res.data.success,
                                icon: "success",
                            }).then((value) => {
                                if (value) {
                                    navigate(redirectLink)
                                }
                            });
                        }
                        if (res.data.error) {
                            swal({
                                title: res.data.error,
                                icon: "warning",
                            }).then((value) => {
                                if (value) {
                                    navigate(`/user/family/payment/${familyId}`)
                                }
                            });
                        }
                    });
            },
            // onError handler is optional
            onError(error) {
                // handle errors
                console.log();
            },
            onClose() {
                // console.log();
            },
        },
        paymentPreference: ["KHALTI"],
    };
    let checkout = new KhaltiCheckout(config);

    return (
        <div>
            <button
                onClick={() => checkout.show({amount: 1000})}
                // onClick={() => checkout.show({ amount: totalAmt })}
                className="border-0"
            >
                <img src={KhaltiLogo} alt=""/>
            </button>
            {verifying && <OrderVerifyingUI/>}
        </div>
    );
};

export default KhaltiPackageAdditional;
