import React from 'react'
import ContentWrapper from "./ContentWrapper"
import {Outlet} from 'react-router-dom'
import "../../../assets/css/dashboard.scss"
import {DatatableColumnVisibility} from '../../../assets/js/DatabaseTableColvis'
import {DashboardLayoutProvider} from './DashboardLayoutProvider'
import ParentSidebar from "./ParentDashboardSidebar";
import Protected from "../../hoc/protected";
import ParentDashboardNav from "./ParentDashboardNav";

const DashboardLayout = () => {

    React.useEffect(() => {
        DatatableColumnVisibility.init();
    }, [])

    return (
        <Protected>
            <DashboardLayoutProvider>
                <div className="dashboard-style">
                    <ParentDashboardNav/>
                    <div className="page-content custom-scrollbars">
                        <ParentSidebar/>
                        <ContentWrapper>
                            <Outlet/>
                        </ContentWrapper>
                    </div>
                </div>
            </DashboardLayoutProvider>
        </Protected>
    )
}

export default DashboardLayout
