import http from "../utils/http/http.utils";
import setFormData from "../utils/http/formdata.utils";

class ParentService {
    /**
     * Function to handle parent login
     * @param {Object} credentials - The credentials for login
     * @returns {Promise} The promise object representing the login request
     */
    static parentLogin(credentials) {
        try {
            return http().post('api/login/parent-login', credentials)
        } catch (e) {
            console.log(e)
        }
    }

    static async parentLogout() {
        try {
            return http().post('api/parent/logout')
        } catch (e) {
            console.log(e)
        }
    }


    /**
     * FETCH PROFILE
     * */
    static async fetchParentProfile() {
        try {
            const response = await http().get('api/parent/profile');
            return response.data;
        } catch (e) {
            console.log(e)
        }
    }

    /**
     * Function to fetch parent's children list
     * @returns {Promise} The promise object representing the fetch request
     */

    static async fetchChildren() {
        try {
            const response = await http().get('api/parent/childrens');
            return response.data?.data;
        } catch (e) {
            console.log(e)
        }
    }

    /**
     * Function to fetch medical history
     * @returns {Promise} The promise object representing the fetch request
     */
    static fetchMedicalHistory() {
        try {
            return http().get('api/parent/childrens/medical-history')
        } catch (e) {
            console.log(e)
        }
    }
    static fetchMedicalHistoryDetail(memberId, id) {
        try {
            return http().get(`api/parent/childrens/${memberId}/medical-history/${id}`)
        } catch (e) {
            console.log(e)
        }
    }

    static fetchMedicalHistoryChildren(id) {
        try {
          return http().get(`api/parent/childrens/${id}/medical-history`)
        } catch (e) {
            console.log(e)
        }
    }

    /**
     * Uploads a medical document.
     *
     * @param id
     * @param {Object} data - The data for the medical document.
     * @return {Promise} A promise that resolves to the result of the upload.
     */
    static async uploadMedicalDocument(id, data) {
        try {
            return http().post(`api/parent/childrens/${id}/medical-history`, data)
        } catch (e) {
            console.log(e)
        }
    }

    static fetUserPackage() {
        try {
            return {
                "package": {
                    "id": 2,
                    "slug": "package-booking-Ew1",
                    "familyname_id": 4,
                    "member_id": null,
                    "package_id": 2,
                    "family_id": 4,
                    "status": 1,
                    "package_status": "Activated",
                    "renew_status": 1,
                    "booked_date": "2023-12-25",
                    "expiry_date": "2024-12-24",
                    "grace_period": 0,
                    "year": 1,
                    "activated_date": "2023-12-25",
                    "additonal_members": 0,
                    "created_at": "2023-12-25T10:06:00.000000Z",
                    "updated_at": "2023-12-25T10:42:41.000000Z",
                    "familyname": {
                        "id": 4,
                        "primary_member_id": 77,
                        "family_name": "Shrestha77",
                        "online_consultation": 5,
                        "created_at": "2023-12-25T09:34:05.000000Z",
                        "updated_at": "2024-03-01T05:57:28.000000Z",
                        "primary": {
                            "id": 77,
                            "member_id": 90,
                            "gd_id": "GD-77",
                            "gender": "Male",
                            "address": "Balu",
                            "image": "65894af3315df.png",
                            "image_path": "https:\/\/test.ghargharmadoctor.com\/storage\/images\/65894af3315df.png",
                            "file": null,
                            "file_path": null,
                            "dob": "2000-10-15",
                            "blood_group": "A+",
                            "country": "\ud83c\uddf3\ud83c\uddf5    Nepal",
                            "weight": "55",
                            "height": "1.879598985016548",
                            "slug": "member-Bishal--Shrestha-Dki",
                            "member_type": "Primary Member",
                            "bp": null,
                            "bp_updated_date": null,
                            "heart_rate": null,
                            "steps_count": null,
                            "height_feet": "6",
                            "height_inch": "2",
                            "created_at": "2023-12-25T09:23:53.000000Z",
                            "updated_at": "2024-02-19T15:21:56.000000Z",
                            "member": {
                                "id": 90,
                                "global_form_id": 3,
                                "user_name": null,
                                "first_name": "Bishal",
                                "middle_name": null,
                                "last_name": "Shrestha",
                                "name": "Bishal  Shrestha",
                                "phone": "+9779843636790",
                                "is_verified": 1,
                                "email": "babastha19@gmail.com",
                                "email_verified_at": null,
                                "referrer_id": null,
                                "is_enabled": 0,
                                "phone_verified": 0,
                                "subrole": null,
                                "is_school": 1,
                                "created_at": "2023-12-25T09:23:53.000000Z",
                                "updated_at": "2023-12-25T10:15:55.000000Z",
                                "referral_link": "https:\/\/test.ghargharmadoctor.com\/register?ref=90"
                            }
                        }
                    },
                    "package": {
                        "id": 2,
                        "slug": "package-Silver-Membership-MsX",
                        "type": 1,
                        "package_type": "Silver Membership",
                        "description": "<ul>\r\n<li style=\"text-align: justify;\">Pathological Screening<\/li>\r\n<li style=\"text-align: justify;\">Medical Check-up<\/li>\r\n<li style=\"text-align: justify;\">Physical Fitness Plan<\/li>\r\n<li style=\"text-align: justify;\">Nutrition Advice &amp; Diet Plan<\/li>\r\n<li style=\"text-align: justify;\">Free use of GD Application<\/li>\r\n<li style=\"text-align: justify;\">Free Doctor Consultation booking.<\/li>\r\n<li style=\"text-align: justify;\">Free Ambulance Services for hospitalization.<\/li>\r\n<li style=\"text-align: justify;\">Hospital booking on payment.<\/li>\r\n<\/ul>",
                        "visits": 2,
                        "registration_fee": "8000-24000",
                        "monthly_fee": "700-1650",
                        "screening": 1,
                        "checkup": 1,
                        "ambulance": 1,
                        "insurance": 1,
                        "insurance_amount": 500000,
                        "order": 2,
                        "schedule_flexibility": 0,
                        "schedule_times": null,
                        "online_consultation": 6,
                        "tests": 44,
                        "seo_keyword": null,
                        "seo_description": null,
                        "created_at": "2023-06-09T06:15:08.000000Z",
                        "updated_at": "2024-03-19T09:03:54.000000Z",
                        "fees": [
                            {
                                "id": 3,
                                "package_id": 2,
                                "family_size": 1,
                                "one_registration_fee": 8000,
                                "one_monthly_fee": 1500,
                                "created_at": "2023-06-26T06:06:02.000000Z",
                                "updated_at": "2023-06-26T06:06:02.000000Z"
                            },
                            {
                                "id": 4,
                                "package_id": 2,
                                "family_size": 2,
                                "one_registration_fee": 9000,
                                "one_monthly_fee": 1125,
                                "created_at": "2023-06-26T06:06:02.000000Z",
                                "updated_at": "2023-06-26T06:06:02.000000Z"
                            },
                            {
                                "id": 5966,
                                "package_id": 2,
                                "family_size": 3,
                                "one_registration_fee": 10000,
                                "one_monthly_fee": 825,
                                "created_at": "2023-06-26T06:34:58.000000Z",
                                "updated_at": "2023-06-26T06:34:58.000000Z"
                            },
                            {
                                "id": 5967,
                                "package_id": 2,
                                "family_size": 4,
                                "one_registration_fee": 12000,
                                "one_monthly_fee": 775,
                                "created_at": "2023-06-26T06:34:58.000000Z",
                                "updated_at": "2023-06-26T06:34:58.000000Z"
                            },
                            {
                                "id": 5968,
                                "package_id": 2,
                                "family_size": 5,
                                "one_registration_fee": 15000,
                                "one_monthly_fee": 750,
                                "created_at": "2023-06-26T06:34:58.000000Z",
                                "updated_at": "2023-06-26T06:34:58.000000Z"
                            },
                            {
                                "id": 5969,
                                "package_id": 2,
                                "family_size": 6,
                                "one_registration_fee": 18000,
                                "one_monthly_fee": 750,
                                "created_at": "2023-06-26T06:34:58.000000Z",
                                "updated_at": "2023-06-26T06:34:58.000000Z"
                            },
                            {
                                "id": 5970,
                                "package_id": 2,
                                "family_size": 7,
                                "one_registration_fee": 21000,
                                "one_monthly_fee": 750,
                                "created_at": "2023-06-26T06:34:58.000000Z",
                                "updated_at": "2023-06-26T06:34:58.000000Z"
                            },
                            {
                                "id": 5971,
                                "package_id": 2,
                                "family_size": 8,
                                "one_registration_fee": 24000,
                                "one_monthly_fee": 750,
                                "created_at": "2023-06-26T06:34:58.000000Z",
                                "updated_at": "2023-06-26T06:34:58.000000Z"
                            }
                        ]
                    },
                    "familyfee": {
                        "id": 4,
                        "package_id": 2,
                        "family_size": 2,
                        "one_registration_fee": 9000,
                        "one_monthly_fee": 1125,
                        "created_at": "2023-06-26T06:06:02.000000Z",
                        "updated_at": "2023-06-26T06:06:02.000000Z"
                    },
                    "dates": [
                        {
                            "id": 1,
                            "userpackage_id": 2,
                            "screening_id": 1,
                            "screening_date": "2023-12-25",
                            "screening_time": "1:45 AM",
                            "labreport_date": null,
                            "doctorvisit_date": null,
                            "doctorreport_date": null,
                            "status": "Lab In Progress",
                            "consultation_type": null,
                            "reschedule_status": 0,
                            "additional_screening_status": 1,
                            "additional_screening_date": null,
                            "additional_screening_time": null,
                            "doctorvisit_time": null,
                            "is_verified": 1,
                            "created_at": "2023-12-25T10:55:51.000000Z",
                            "updated_at": "2023-12-31T05:58:40.000000Z",
                            "screening": {
                                "id": 1,
                                "screening": "First Screening",
                                "color": "#A3E88B",
                                "created_at": null,
                                "updated_at": null
                            },
                            "employees": [
                                {
                                    "id": 1,
                                    "screeningdate_id": 1,
                                    "employee_id": 10,
                                    "type": "Lab Nurse",
                                    "online_status": 0,
                                    "created_at": "2023-12-25T10:55:51.000000Z",
                                    "updated_at": "2023-12-25T10:55:51.000000Z",
                                    "employee": {
                                        "id": 10,
                                        "employee_code": "15",
                                        "slug": "employee--tT2",
                                        "gd_id": "GD-14",
                                        "employee_id": 14,
                                        "head_employee_id": 2,
                                        "gender": "Female",
                                        "address": "Boudha",
                                        "nmc_no": null,
                                        "nnc_no": "29781",
                                        "department": null,
                                        "sub_role_id": 7,
                                        "salutation": null,
                                        "qualification": "Bachelor in Nursing",
                                        "year_practiced": "8",
                                        "specialization": null,
                                        "hospital": null,
                                        "image": "Nurse_1702533173.jpeg",
                                        "image_path": "https:\/\/test.ghargharmadoctor.com\/storage\/images\/Nurse_1702533173.jpeg",
                                        "file": "Employee Contract Paper_1702533173.pdf",
                                        "file_path": "https:\/\/test.ghargharmadoctor.com\/storage\/images\/Employee Contract Paper_1702533173.pdf",
                                        "is_user": null,
                                        "status": 1,
                                        "percentage": null,
                                        "created_at": "2023-12-14T05:52:53.000000Z",
                                        "updated_at": "2024-02-17T07:09:29.000000Z",
                                        "nda_file": "Employee Non Disclosure Agreement_1702533173.pdf",
                                        "nda_file_path": "https:\/\/test.ghargharmadoctor.com\/storage\/images\/Employee Non Disclosure Agreement_1702533173.pdf",
                                        "signature": "rak sing_1704269780.jpg",
                                        "signature_path": "https:\/\/test.ghargharmadoctor.com\/storage\/images\/rak sing_1704269780.jpg",
                                        "user": {
                                            "id": 14,
                                            "global_form_id": null,
                                            "user_name": null,
                                            "first_name": "Rakshya",
                                            "middle_name": null,
                                            "last_name": "Sahu",
                                            "name": "Rakshya  Sahu",
                                            "phone": "9767485056",
                                            "is_verified": 1,
                                            "email": "nurse.team1@ghargharmadoctor.com",
                                            "email_verified_at": null,
                                            "referrer_id": null,
                                            "is_enabled": 0,
                                            "phone_verified": 0,
                                            "subrole": 7,
                                            "is_school": 1,
                                            "created_at": "2023-12-14T05:52:53.000000Z",
                                            "updated_at": "2024-02-17T07:09:29.000000Z",
                                            "referral_link": "https:\/\/test.ghargharmadoctor.com\/register?ref=14"
                                        }
                                    }
                                }
                            ],
                            "reports": [
                                {
                                    "id": 1,
                                    "member_id": 77,
                                    "screeningdate_id": 1,
                                    "collected_by": 10,
                                    "lab_id": null,
                                    "verified_by": null,
                                    "sample_no": "77-2023-289",
                                    "sample_date": "2023-12-25 16:50:03",
                                    "deposited_date": "2024-01-01 13:30:19",
                                    "report_date": null,
                                    "consultation_report_date": null,
                                    "skip_reason": null,
                                    "sample_info": "<p>sample collected<\/p>",
                                    "status": "Sample Deposited",
                                    "created_at": "2023-12-25T10:55:51.000000Z",
                                    "updated_at": "2024-01-01T07:45:19.000000Z",
                                    "homeskip": null,
                                    "online": null,
                                    "advice": [],
                                    "nosample": null,
                                    "additionalnosample": null
                                }
                            ],
                            "online": []
                        }
                    ],
                    "payment": [
                        {
                            "id": 2,
                            "userpackage_id": 2,
                            "payment_interval": "Yearly",
                            "payment_method": "Khalti",
                            "payment_date": "2023-12-25 00:00:00",
                            "expiry_date": "2024-12-24",
                            "monthly_amount": 27000,
                            "amount": 10,
                            "grace_days": null,
                            "prepay_status": 0,
                            "prepay_days": null,
                            "paidmember_id": 77,
                            "created_at": "2023-12-25T10:11:39.000000Z",
                            "updated_at": "2023-12-25T10:42:41.000000Z"
                        }
                    ],
                    "requests": []
                },
                "month_count": 12,
                "remainingDays": 279,
                "completedDays": 87,
                "prepay": false,
                "extended_date": null,
                "expire": false
            }
            // return http().get('/api/admin/userpackage')
        } catch (e) {
            console.log(e)
        }
    }

}

export default ParentService
