import React from 'react'
import NewsItemContainer from './NewsItemContainer'
import {Box, Skeleton} from '@mui/material'

function SkeletonNewsItem() {
    return (
        <NewsItemContainer
            mediaComponent={
                <Skeleton variant="rectangular" width='100%' height='100%'/>
            }
        >
            <Box width='100%'>
                <Skeleton width='100%' height='30px'/>
                <Skeleton width='60%' height='30px'/>
                <Skeleton width='20%' height='24px'/>
            </Box>
        </NewsItemContainer>
    )
}

export default SkeletonNewsItem
