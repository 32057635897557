
/**
 * CREATES FORMDATA FROM ANY OBJECT
 * @param values
 * @returns
 */
 const setFormData = (values) => {
    const fd = new FormData();

    /** FORM DATA IN-CASE OF ARRAY */
    const setFdArray = (array, oldKey) => {
        array.forEach((item) => {
            if (!(item instanceof File) && typeof item === "object") {
                setFdObject(item, `${item}[]`);
            } else if (item instanceof Array) {
                setFdArray(item, "[]");
            } else {
                fd.append(`${oldKey}[]`, item || "");
            }
        });
    };

    /** FORM DATA IN-CASE OF OBJECT */
    const setFdObject = (newValues, oldKey) => {
        if (newValues) {
            Object.keys(newValues).forEach((key) => {
                if (
                    !(newValues[key] instanceof File) &&
                    typeof newValues[key] === "object"
                ) {
                    setFdObject(newValues[key], `${oldKey}[${key}]`);
                } else if (newValues[key] instanceof Array) {
                    setFdArray(newValues[key], `${oldKey}[${key}]`);
                } else {
                    fd.append(`${oldKey}[${key}]`, newValues[key] || "");
                }
            });
        }
    };

    if (values) {
        Object.keys(values).forEach((key) => {
            if (!(values[key] instanceof File) && typeof values[key] === "object") {
                setFdObject(values[key], key);
            } else if (values[key] instanceof Array) {
                setFdArray(values[key], key);
            } else {
                fd.append(
                    key,
                    values[key] || values[key] !== undefined ? values[key] : ""
                );
            }
        });
    }

    return fd;
};

export default setFormData
