import {VisibilityOffOutlined, VisibilityOutlined} from "@mui/icons-material";
import {Box, IconButton, InputBase} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";

function FormInputBase({children}) {
    const ref = useRef(null);
    const [focus, setFocus] = useState(false);
//   useEffect(() => {
//     ref.current.onfocus = () => {
//       setFocus(true);
//     };
//     ref.current.onblur = () => {
//       setFocus(false);
//     };
//   }, []);
    return (
        // <Box
        //     display='flex'
        //     alignItems='center'
        //     height='50px'
        //     border={'0.906977px solid rgba(102, 102, 102, 0.35)'}
        //     boxShadow={focus && '0 4px #C9DFFF, 0 -4px #C9DFFF, 4px 0 #C9DFFF, -4px 0 #C9DFFF'}
        //     borderRadius='10.8837px'
        //     boxSizing='content-box'
        // >
        //     {children(ref)}
        // </Box>

        <>{children(ref)}</>
    );
}

export function FormInputPassword({children}) {
    const [show, setShow] = useState(false);
    return (
        <FormInputBase>
            {(ref) => (
                <>
                    <div class="input-group">
                        {children(ref, show)}
                        <div class="input-group-append" style={{marginBottom: '15px'}}>
              <span class="input-group-text h-100" id="basic-addon2">
                  {show ? (
                      <VisibilityOutlined onClick={() => setShow(false)}/>
                  ) : (
                      <VisibilityOffOutlined onClick={() => setShow(true)}/>
                  )}
              </span>
                        </div>
                    </div>
                </>
            )}
        </FormInputBase>
    );
}

export default FormInputBase;
