import {MenuItem, Select} from "@mui/material";
import React, {useState} from "react";
import {useQuery} from "@tanstack/react-query";
import ParentService from "../../../../services/parent.service";

const ParentChildrenSelection = (props) => {
    const {handleChange} = props

    const [value, setValue] = useState(undefined)

    /*
   * CHILDREN FETCHING
   * */
    const { data} = useQuery({
            queryKey: ['parent-children'],
            queryFn: () => ParentService.fetchChildren(),
        }
    )

   const formattedChildrenOptions = data?.map((item) => {
        return {
            label: `${item?.first_name} ${item?.last_name}`,
            value: item?.id
        }
    })


    const onChange = (e) => {
        const value = e.target.value
        setValue(value)
        handleChange(value)
    }


    return (
        <div className="d-flex  pb-4">
            <Select
                color="primary"
                value={value}
                disableUnderline
                displayEmpty
                renderValue={value !== "" ? undefined : () => <div style={{color:"#B1B1B1"}}>Select a field</div>}
                classes="custom-select"
                sx={{
                    background: "#fff",
                    borderRadius: '6px',
                    height: 44,
                    paddingLeft: '12px',
                    marginTop: 0,
                    width: 200,
                }}
                onChange={(e, data) => onChange(e)}
            >
                {
                    formattedChildrenOptions?.map((item) => (
                        <MenuItem key={item.value} color="primary" value={item.value}>
                            {item?.label}
                        </MenuItem>
                    ))
                }
            </Select>
        </div>
    )
}


export default ParentChildrenSelection
