import { useContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import axios from "../components/api"
import { LoginStateContext } from "../contexts/LoginStateContext"

const useKycStatus = (token) => {
    const location = useLocation()
    const [data, setData] = useState()
    const [error, setError] = useState()
    const loginContext = useContext(LoginStateContext)
    const [isLoading, setLoading] = useState(true)
    useEffect(() => {
        if (!loginContext.state.loading) {
            if(!loginContext.state.loggedIn) {
                setError("Not Authenticated")
            }
            setLoading(true)
            axios.get('/api/kycstatus', { headers: { "Authorization": `Bearer ${loginContext.state.token}` } })
                .then(res => {
                    setLoading(false)
                    setData(res.data)
                }).catch(err => {
                    setLoading(false)
                })
        }
    }, [loginContext.state.loading, location])
    return { data, error, isLoading }
}

export default useKycStatus