import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    Divider,
    Grid,
    Stack,
    Tab,
    Typography
} from '@mui/material'
import React, {useContext} from 'react'
import {useState} from 'react';
import {AntTabs, ButtonTabs, ButtonTabsCentered, InnerTabs, OuterTabs} from '../../../custom_componets/CustomTab'
import PdfIcon from '../../../../images/pdfIcon.png'
import PathologyReportProvider, {useScreeningReport} from './PathologyReportProvider';
import {DateFormat} from '../../../DateFormat';
import {LoginStateContext} from '../../../../contexts/LoginStateContext';
import {ExpandMoreOutlined} from '@mui/icons-material';
import {useEffect} from 'react';
import ChartComponent from '../pathologyreport/ChartComponent';
import ConsultationReport from '../consultationReport/ConsultationReport';
import {getDateStatus} from '../packages/DateStatus';
import NoData from '../../../custom_componets/NoData';

function PathologyReport() {
    return (
        <PathologyReportProvider>
            <PathologyReportContent/>
        </PathologyReportProvider>
    )
}

function PathologyReportContent() {
    const [value, setValue] = useState(0)
    const loginContext = useContext(LoginStateContext)
    const {screeningReport} = useScreeningReport()
    let report = screeningReport[value]
    const collectedBy = screeningReport[value].collectedby
    const labReport = screeningReport[value].labreports
    console.log()
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [labtest, setLabtest] = useState(null)
    // useEffect(() => {
    //     let labTests = {}
    //     for (let i = 0; i < value + 1; i++) {
    //         let labreports = screeningReport[i].labreports
    //         for (let j = 0; j < labreports.lenght; j++) {
    //             if (labreports[j].labtest === null) {
    //                 continue
    //             }
    //             if (!labreports[j].labtest.tests in labTests) {
    //                 labTests[labreports[j].labtest.tests] = []

    //             }
    //             labTests[labreports[j].labtest.tests].push(labreports[j].value)
    //         }
    //     }
    //     setLabtest(labTests)
    //     console.log()
    // }, [])
    function viewPdf() {
        window.open(report.pdf.report_path, '_blank')
    }

    useEffect(() => {
        let labTests = {}
        for (let i = 0; i < screeningReport.length; i++) {
            let labreports = screeningReport[i].labreports
            console.log()
            for (let j = 0; j < labreports.length; j++) {
                if (labreports[j].labtest === null) {
                    continue
                }
                let testName = labreports[j].labtest.tests
                console.log()
                if (!(testName in labTests)) {
                    console.log()
                    labTests[testName] = {
                        metadatas: {
                            code: labreports[j].labtest.code,
                            min_range: labreports[j].min_range,
                            max_range: labreports[j].max_range,
                            // amber_min_range: labreports[j].amber_min_range,
                            // amber_max_range: labreports[j].amber_max_range,
                            // red_min_range: labreports[j].red_min_range,
                            // red_max_range: labreports[j].red_max_range

                            // red_min_range: 0,
                            // amber_min_range: 1,
                            // min_range: 2,
                            // max_range: 3,
                            // amber_max_range: 4,
                            // red_max_range: 5
                        },
                        data: []
                    }

                }
                console.log()
                labTests[testName].data.push(
                    {
                        value: labreports[j].value,
                        date: screeningReport[i].screeningdate.screening_date,
                        screening: screeningReport[i].screeningdate.screening.screening
                    })
            }
        }
        // console.log()
        setLabtest(labTests)
        // console.log()
    }, [value])

    return (
        <Box display='flex' flexDirection={'column'}>
            <OuterTabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                // scrollButtons
                allowScrollButtonsMobile
                aria-label="scrollable force tabs example"
            >
                {
                    screeningReport.map((item, idx) => (
                        <Tab label={item.screeningdate.screening.screening}/>
                    ))

                }
            </OuterTabs>
            <Card variant='outlined' sx={{py: 1}}>
                <ButtonTabs
                    value={0}
                    variant="scrollable"
                    // scrollButtons
                    allowScrollButtonsMobile
                    aria-label="scrollable force tabs example"
                >
                    <Tab label="Lab Report"/>
                    <Tab label="Consultation Report"/>
                </ButtonTabs>
                <Divider className='divider' sx={{border: '1px solid !important', m: '0 !important'}}/>
                {/* <ConsultationReport /> */}

                <ScreeningTabContent report={report} labtest={labtest}/>
            </Card>
        </Box>
    )
}

function ScreeningTabContent({report, labtest}) {
    const loginContext = useContext(LoginStateContext)
    const collectedBy = report.collectedby
    const labReport = report.labreports

    function viewPdf() {
        window.open(report.pdf.report_path, '_blank')
    }

    // if (report.status !== "Report Verified") {
    //     return (
    //         <Alert severity='error'>
    //             <AlertTitle>Report Not Yet Published.</AlertTitle>
    //         </Alert>
    //     )
    // }
    console.log("Report status is", report.status)
    // if (getDateStatus(report.status) < 5) {
    //     return (<NoData title='No Data' description='No lab report published yet.' />)
    // }
    return (
        <>
            <Box
                p={1}
                position='relative'
                minHeight='88px'
                display='flex'
                gap={3}
            >
                <Box position='absolute' top={0} left={0} width='100%' height='100%'
                     sx={{background: 'linear-gradient(90deg, rgba(15,66,161,0.8) 3%, rgba(82,200,244,0) 100%)'}}/>
                <Box display='flex' gap={3} position='relative' sx={{zIndex: 2}}>
                    <Box position='relative' width='10%' display='flex' alignItems='center'>
                        <Box width='100%' sx={{'& img': {width: '100%'}}}>
                            <img src={PdfIcon}/>
                        </Box>
                    </Box>
                    <Box flexGrow={1}>
                        <Box display='flex' justifyContent='end'>
                            {
                                report.pdf && (
                                    <Button variant='contained' onClick={viewPdf}>View PDF</Button>
                                )
                            }
                        </Box>
                        <Grid container>
                            <ReportGridItem label="Sample Number" value={report.sample_no}/>
                            <ReportGridItem label="Sample Date" value={report.sample_date}/>
                            <ReportGridItem label="Sample Deposit Date" value={report.depositied_date}/>
                            <ReportGridItem label="Report Date" value={report.report_date}/>
                            <ReportGridItem label="Patient ID" value={report.member_id}/>
                            <ReportGridItem label="Patient Name" value={loginContext.state.data.member.name}/>
                            <ReportGridItem label="D.O.B" value={loginContext.state.data.dob}/>
                            <ReportGridItem label="Sex" value={loginContext.state.data.gender}/>
                            <ReportGridItem label="Screening Date" value={report.screeningdate.screening_date}/>
                            <ReportGridItem label="Generated By" value={collectedBy?.user?.name}/>
                            <ReportGridItem label="Generated On"
                                            value={DateFormat(collectedBy.created_at).getFullDateText()}/>
                            <ReportGridItem label="Collected By" value={collectedBy.user.name}/>
                        </Grid>
                    </Box>
                </Box>
            </Box>

            <Stack gap={2}>
                <Typography variant='h4'>VISUAL REPRESENTATIONS:</Typography>
                <Grid container spacing={2}>
                    {
                        labtest != null &&
                        Object.keys(labtest).map((item, idx) => (
                            <Grid item xs={6}>
                                <MedicalReportAccordion key={idx} data={labtest[item]} testName={item}/>
                            </Grid>
                        ))
                        // report.labreports.map((item, idx) => (
                        //     item.labtest !== null &&
                        //     <Grid item xs={4}>
                        //         <MedicalReportAccordion data={item} labtest={labtest[item?.labtest?.tests]} />
                        //     </Grid>
                        // ))
                    }
                </Grid>
            </Stack>
        </>
    )
}

function ReportGridItem({label, value,}) {
    return (
        <Grid item xs={6}>
            <Grid container>
                <Grid item xs={5}>
                    <Typography variant='subtitle1' sx={{fontWeight: '700 !important'}}>{label}:</Typography>
                </Grid>
                <Grid item xs={7}>
                    <Typography variant='subtitle1'>{value}</Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

function MedicalReportAccordion({data, labtest, testName}) {
    // let graphData = []

    // for(let i=0; i<labtest.length; i++) {
    //     graphData.push({
    //         date:''
    //     })
    // }
    // if (data.labtest === null) {
    //     return
    // }
    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreOutlined/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>{testName} report</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Stack gap={1}>
                    <Box>
                        <Box display='flex' gap={1} alignItems='center'>
                            <Typography varaint='subtitle1' sx={{fontWeight: '500 !important'}}>Minimum
                                Range:</Typography>
                            <Typography varaint='subtitle1'>{data.metadatas.min_range}</Typography>
                        </Box>
                        <Box display='flex' gap={1} alignItems='center'>
                            <Typography varaint='subtitle1' sx={{fontWeight: '500 !important'}}>Maximum
                                Range:</Typography>
                            <Typography varaint='subtitle1'>{data.metadatas.max_range}</Typography>
                        </Box>
                        <Box display='flex' gap={1} alignItems='center'>
                            <Typography varaint='subtitle1' sx={{fontWeight: '500 !important'}}>Actual
                                Range:</Typography>
                            <Typography varaint='subtitle1'>{data.data[data.data.length - 1].value}</Typography>
                        </Box>
                    </Box>
                    <Box sx={{'& canvas': {width: '100% !important'}}}>
                        <ChartComponent data={data.data} metadata={data.metadatas} label={testName}/>
                    </Box>
                </Stack>
            </AccordionDetails>
        </Accordion>
    )
}

export default PathologyReport
