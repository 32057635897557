import {useQuery} from '@tanstack/react-query'
import React, {useState} from 'react'
import {useContext} from 'react'
import {useEffect} from 'react'
import {useRef} from 'react'
import {useNavigate} from 'react-router-dom'
import {LoginStateContext} from '../../../../contexts/LoginStateContext'
import axios from "../../../api"
import {GoogleMap, Autocomplete, Marker} from '@react-google-maps/api'
import HorizontalLinearStepper from './HorizontalLinearStepper'
import {Box, Button, Step, StepLabel, Stepper, Typography} from '@mui/material'
import Personal from './components/Personal'
import AddressDetails from './components/AddressDetails'
import ProfessionalDetails from './components/ProfessionalDetails'
import DeclareAndService from './components/DeclareAndService'
import DocumentUpload from './components/DocumentUpload'
import useKycStatus from '../../../../customHooks/useKycStatus'
import KycVerification from './components/KycVerification'
import {GlobalFormProvider, useGlobalFormData} from './GlobalFormProvider'
import GlobalForm from './GlobalForm'
import Loader from '../../Loader'
import {Call} from '@mui/icons-material'

const stepperIndex = {
    'personal-information': 0,
    'address-information': 1,
    'professional-information': 2,
    'declaration-and-services': 3,
    'upload-document': 4
}
const steps = ['Personal Information', 'Address Information', 'Professional Information', 'Declaration And Services', 'Upload Documents', 'KYC Verification'];

function UserKyc() {
    const loginContext = useContext(LoginStateContext)
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [submitForm, setSubmitForm] = React.useState(false)
    const [allFormData, setAllFormData] = React.useState({})
    const [waitingFormData, setWaitingFormData] = React.useState(true)
    const stepperRef = useRef(null)
    const {globalForm} = useGlobalFormData()

    const [errors, setErrors] = React.useState({})

    const [chkOptions, setChkOptions] = useState({
        nomineeChk: false,
        beneficiarChk: false,
    })

    const kycStatus = useKycStatus(loginContext.state.token)

    const navigate = useNavigate()

    useEffect(() => {
        if (globalForm.form_status) {
            setActiveStep(stepperIndex[globalForm.form_status] + 1)
        }
    }, [])

    useEffect(() => {
        stepperRef.current && stepperRef.current.scrollIntoView()

    }, [activeStep])

    useEffect(() => {
        if (!kycStatus.isLoading) {
            if (kycStatus?.data?.kyc) {
                setAllFormData(kycStatus.data.kyc)
            }
            setWaitingFormData(false)
        }
    }, [kycStatus.isLoading])

    const handleNext = () => {
        //
        if (activeStep === steps.length - 1) {
            // handleSubmit()
        } else {
            let newSkipped = skipped;

            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setSkipped(newSkipped);
        }

    };

    const handleBack = (inputs = {}) => {
        // setAllFormData(prev => ({
        //     ...prev,
        //     ...inputs
        // }))

        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    if (kycStatus.isLoading) {
        return <div className='d-flex justify-content-center'><Loader/></div>
    }

    if (kycStatus.data.message !== "kyc form not submitted." && kycStatus.data.message !== "kyc rejected.") {
        return (
            <div className="row">
                <div className="col-12 text-center text-bold">
                    <h1><b>Global Form Already Submitted</b></h1>
                </div>
            </div>
        )
    }

    return (
        // <GlobalForm/>

        <Box sx={{width: '100%'}}>
            <div className="mb-3">
                <a href="tel:+977015917322" className="btn btn-primary d-flex gap-2 align-items-center"
                   style={{width: 'fit-content'}}>
                    <Call className='fs-6 fw-light'/>
                    <span>
                        Get GD Assistance
                    </span>
                </a>
            </div>
            <Stepper activeStep={activeStep} ref={stepperRef} className="py-2 pb-4">
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <React.Fragment>
                    <Typography sx={{mt: 2, mb: 1}}>
                        All steps completed - you&apos;re finished
                    </Typography>
                    <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                        <Box sx={{flex: '1 1 auto'}}/>
                        <Button onClick={handleReset}>Reset</Button>
                    </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {
                        (!waitingFormData) &&
                        <>
                            {
                                activeStep === 0 &&
                                <Personal handleBack={handleBack} handleNext={handleNext} allFormData={allFormData}
                                          setAllFormData={setAllFormData} errors={errors} setErrors={setErrors}/>
                            }
                            {
                                activeStep === 1 &&
                                <AddressDetails handleBack={handleBack} handleNext={handleNext}
                                                allFormData={allFormData} setAllFormData={setAllFormData}
                                                errors={errors} setErrors={setErrors}/>
                            }
                            {
                                activeStep === 2 &&
                                <ProfessionalDetails handleBack={handleBack} handleNext={handleNext}
                                                     allFormData={allFormData} setAllFormData={setAllFormData}
                                                     errors={errors} setErrors={setErrors}/>
                            }
                            {
                                activeStep === 3 &&
                                <DeclareAndService handleBack={handleBack} handleNext={handleNext}
                                                   allFormData={allFormData} setAllFormData={setAllFormData}
                                                   errors={errors} setErrors={setErrors} chkOptions={chkOptions}
                                                   setChkOptions={setChkOptions}/>
                            }
                            {
                                activeStep === 4 &&
                                <DocumentUpload handleBack={handleBack} handleNext={handleNext}
                                                allFormData={allFormData} setAllFormData={setAllFormData}
                                                errors={errors} setErrors={setErrors} status={kycStatus.data.message}/>
                            }
                            {
                                activeStep === 5 &&
                                <KycVerification handleBack={handleBack} handleNext={handleNext}
                                                 allFormData={allFormData} setAllFormData={setAllFormData}
                                                 errors={errors} setErrors={setErrors}/>
                            }
                        </>
                    }
                </React.Fragment>
            )}
        </Box>

    )

}

export default UserKyc

// function Backup() {
//     return (
//         <div className="card">
//             <form className="form-horizontal" onSubmit={handleSubmit}>
//                 <div className="card-header">
//                     <h3>Personal Information</h3>
//                 </div>
//                 <div className="card-body">
//                     <div className="row">
//                         <div className="col-lg-4">
//                             <div className="form-group">
//                                 <label className="form-label">First Name<code>*</code></label>
//                                 <input type="text" className="form-control" name="first_name" value={inputs.first_name} onChange={handleChange} />
//                                 {
//                                     ('first_name' in error) &&
//                                     <div className="alert alert-danger">
//                                         {error.first_name}
//                                     </div>
//                                 }
//                             </div>
//                         </div>
//                         <div className="col-lg-4">
//                             <div className="form-group">
//                                 <label className="form-label">Middle Name<code>(Optional)</code></label>
//                                 <input type="text" className="form-control" name="middle_name" value={inputs.middle_name} onChange={handleChange} />
//                                 {
//                                     ('middle_name' in error) &&
//                                     <div className="alert alert-danger">
//                                         {error.middle_name}
//                                     </div>
//                                 }
//                             </div>
//                         </div>
//                         <div className="col-lg-4">
//                             <div className="form-group">
//                                 <label className="form-label">Last Name<code>*</code></label>
//                                 <input type="text" className="form-control" name="last_name" value={inputs.last_name} onChange={handleChange} />
//                                 {
//                                     ('last_name' in error) &&
//                                     <div className="alert alert-danger">
//                                         {error.last_name}
//                                     </div>
//                                 }
//                             </div>
//                         </div>
//                         <div className="col-lg-4">
//                             <div className="form-group">
//                                 <label className="form-label">Gender<code>*</code></label>
//                                 <select name="gender" className="form-control" value={inputs.gender} onChange={handleChange}>
//                                     <option value disabled selected>--select--</option>
//                                     <option value="Male">Male</option>
//                                     <option value="Female">Female</option>
//                                     <option value="Other">Other</option>
//                                 </select>
//                                 {
//                                     ('gender' in error) &&
//                                     <div className="alert alert-danger">
//                                         {error.gender}
//                                     </div>
//                                 }
//                             </div>
//                         </div>
//                         <div className="col-lg-4">
//                             <div className="form-group">
//                                 <label className="form-label">Birth Date<code>*</code></label>
//                                 <input type="date" className="form-control" name="birth_date" value={inputs.birth_date} onChange={handleChange} />
//                                 {
//                                     ('birth_date' in error) &&
//                                     <div className="alert alert-danger">
//                                         {error.birth_date}
//                                     </div>
//                                 }
//                             </div>
//                         </div>
//                         <div className="col-lg-4">
//                             <div className="form-group">
//                                 <label className="form-label">Citizenship Date<code>*</code></label>
//                                 <input type="date" className="form-control" name="citizenship_date" value={inputs.citizenship_date} onChange={handleChange} />
//                                 {
//                                     ('citizenship_date' in error) &&
//                                     <div className="alert alert-danger">
//                                         {error.citizenship_date}
//                                     </div>
//                                 }
//                             </div>
//                         </div>
//                         <div className="col-lg-4">
//                             <div className="form-group">
//                                 <label className="form-label">Citizenship No.<code>*</code></label>
//                                 <input type="text" className="form-control" name="citizenship_no" value={inputs.citizenship_no} onChange={handleChange} />
//                                 {
//                                     ('citizenship_no' in error) &&
//                                     <div className="alert alert-danger">
//                                         {error.citizenship_no}
//                                     </div>
//                                 }
//                             </div>
//                         </div>
//                         <div className="col-lg-4">
//                             <div className="form-group">
//                                 <label className="form-label">Citizenship Issued District<code>*</code></label>
//                                 <input type="text" className="form-control" name="citizenship_issue_district" value={inputs.citizenship_issue_district} onChange={handleChange} />
//                                 {
//                                     ('citizenship_issue_district' in error) &&
//                                     <div className="alert alert-danger">
//                                         {error.citizenship_issue_district}
//                                     </div>
//                                 }
//                             </div>
//                         </div>
//                         <div className="col-lg-4">
//                             <div className="form-group">
//                                 <label className="form-label">Nationality<code>*</code></label>
//                                 <select name="nationality" className="form-control" value={inputs.nationality} onChange={handleChange}>
//                                     <option value disable selected>--select--</option>
//                                     <option value="Nepali">Nepali</option>
//                                     <option value="Indian">Indian</option>
//                                     <option value="Chinese">Chinese</option>
//                                 </select>
//                                 {
//                                     ('nationality' in error) &&
//                                     <div className="alert alert-danger">
//                                         {error.nationality}
//                                     </div>
//                                 }
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 {/* <div className="card">
//                     <div className="card-header">
//                         <h3>Contact Information</h3>
//                     </div>
//                     <div className="card-body">
//                         <div className="row">
//                             <div className="col-lg-4">
//                                 <div className="form-group">
//                                     <label className="form-label">Mobile Number<code>*</code></label>
//                                     <input type="number" className="form-control" name="mobile_number" value={inputs.mobile_number} onChange={handleChange} />
//                                     {
//                                         ('mobile_number' in error) &&
//                                         <div className="alert alert-danger">
//                                             {error.mobile_number}
//                                         </div>
//                                     }
//                                 </div>
//                             </div>
//                             <div className="col-lg-4">
//                                 <div className="form-group">
//                                     <label className="form-label">Email<code>*</code></label>
//                                     <input type="email" className="form-control" name="email" value={inputs.email} onChange={handleChange} />
//                                     {
//                                         ('email' in error) &&
//                                         <div className="alert alert-danger">
//                                             {error.email}
//                                         </div>
//                                     }
//                                 </div>
//                             </div>
//                             <div className="col-lg-4">
//                                 <div className="form-group">
//                                     <label className="form-label">Country<code>*</code></label>
//                                     <select name="country" className="form-control" value={inputs.country} onChange={handleChange} >
//                                         <option value disable selected>--select--</option>
//                                         <option value="Nepali">Nepal</option>
//                                         <option value="Indian">India</option>
//                                         <option value="Chinese">China</option>
//                                     </select>
//                                     {
//                                         ('country' in error) &&
//                                         <div className="alert alert-danger">
//                                             {error.country}
//                                         </div>
//                                     }
//                                 </div>
//                             </div>
//                             <div className="col-md-3">
//                                 <div className="form-group">
//                                     <label htmlFor>Province <code>*</code></label>
//                                     <select className="custom-select" name="province_id" id="temp_province" onChange={(e) => { handleChange(e, handleProvince) }}>
//                                         <option value disabled selected>Choose Province</option>
//                                         {
//                                             province.map((item) => {
//                                                 return <option value={item.id} key={item.id}>{item.english_name}</option>
//                                             })
//                                         }
//                                     </select>
//                                     {
//                                         ('province_id' in error) &&
//                                         <div className="alert alert-danger">
//                                             {error.province_id}
//                                         </div>
//                                     }
//                                 </div>
//                             </div>
//                             <div className="col-md-3">
//                                 <div className="form-group">
//                                     <label htmlFor>District <code>*</code></label>
//                                     <select className="custom-select" name="district_id" id="temp_district" onChange={(e) => { handleChange(e, handleDistrict) }}>
//                                         <option value disabled selected>Choose District</option>
//                                         {
//                                             district.map((item) => {
//                                                 return <option value={item.id} key={item.id}>{item.english_name}</option>
//                                             })
//                                         }
//                                     </select>
//                                     {
//                                         ('district_id' in error) &&
//                                         <div className="alert alert-danger">
//                                             {error.district_id}
//                                         </div>
//                                     }
//                                 </div>
//                             </div>
//                             <div className="col-md-3">
//                                 <div className="form-group">
//                                     <label htmlFor>VDC/Municipality <code>*</code></label>
//                                     <select className="custom-select" name="municipality_id" id="temp_municipality" onChange={(e) => { handleChange(e, handleMunic) }}>
//                                         <option value disabled selected>Choose VDC/Municipality</option>
//                                         {
//                                             municipality.map((item) => {
//                                                 return <option value={item.id} key={item.id}>{item.english_name}</option>
//                                             })
//                                         }
//                                     </select>
//                                     {
//                                         ('municipality_id' in error) &&
//                                         <div className="alert alert-danger">
//                                             {error.municipality_id}
//                                         </div>
//                                     }
//                                 </div>
//                             </div>
//                             <div className="col-md-3">
//                                 <div className="form-group">
//                                     <label htmlFor>Ward No <code>*</code></label>
//                                     <select className="custom-select" name="ward_id" id="temp_ward" onChange={handleChange}>
//                                         <option value disabled selected>Choose Ward</option>
//                                         {
//                                             ward.map((item) => {
//                                                 return <option value={item.id} key={item.id}>{item.ward_name}</option>
//                                             })
//                                         }
//                                     </select>
//                                     {
//                                         ('ward_id' in error) &&
//                                         <div className="alert alert-danger">
//                                             {error.ward_id}
//                                         </div>
//                                     }
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="card">
//                     <div className="card-header">
//                         <h3>Other Information</h3>
//                     </div>
//                     <div className="card-body">
//                         <div className="row">
//                             <div className="col-lg-4">
//                                 <div className="form-group">
//                                     <label className="form-label">Father Full Name<code>*</code></label>
//                                     <input type="text" className="form-control" name="father_full_name" value={inputs.father_full_name} onChange={handleChange} />
//                                     {
//                                         ('father_full_name' in error) &&
//                                         <div className="alert alert-danger">
//                                             {error.father_full_name}
//                                         </div>
//                                     }
//                                 </div>
//                             </div>
//                             <div className="col-lg-4">
//                                 <div className="form-group">
//                                     <label className="form-label">Mother Full Name<code>*</code></label>
//                                     <input type="text" className="form-control" name="mother_full_name" value={inputs.mother_full_name} onChange={handleChange} />
//                                     {
//                                         ('mother_full_name' in error) &&
//                                         <div className="alert alert-danger">
//                                             {error.mother_full_name}
//                                         </div>
//                                     }
//                                 </div>
//                             </div>
//                             <div className="col-lg-4">
//                                 <div className="form-group">
//                                     <label className="form-label">Grandfather Full Name<code>*</code></label>
//                                     <input type="text" className="form-control" name="grandfather_full_name" value={inputs.grandfather_full_name} onChange={handleChange} />
//                                     {
//                                         ('grandfather_full_name' in error) &&
//                                         <div className="alert alert-danger">
//                                             {error.grandfather_full_name}
//                                         </div>
//                                     }
//                                 </div>
//                             </div>
//                             <div className="col-lg-4">
//                                 <div className="form-group">
//                                     <label className="form-label">Husband / Wife Fullname<code>(Optional)</code></label>
//                                     <input type="text" className="form-control" name="husband_wife_full_name" value={inputs.husband_wife_full_name} onChange={handleChange} />
//                                     {
//                                         ('husband_wife_full_name' in error) &&
//                                         <div className="alert alert-danger">
//                                             {error.husband_wife_full_name}
//                                         </div>
//                                     }
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div> */}
//                 <div className="card-footer">
//                     <button type="submit" className="btn btn-primary">Save</button>
//                 </div>
//             </form>
//         </div>
//     )
// }


// function MapInput({ setLatLong = () => { } }) {
//     const [latLng, setLatLng] = useState({
//         lat: null,
//         lng: null
//     })

//     const [center, setCenter] = useState({
//         lat: 27.68322,
//         lng: 85.33582
//     })

//     const [autocomplete, setAutocomplete] = useState(null)

//     function clickedMap(e) {
//
//         setLatAndLng(e.latLng.lat(), e.latLng.lng())
//     }

//     function setLatAndLng(lat, lng) {
//         setLatLng({ ...latLng, lat: lat, lng: lng })
//         setCenter({ ...latLng, lat: lat, lng: lng })
//         setLatLong(lat, lng)
//     }
//     return (
//         <>
//             <h4>Place the marker on your location</h4>

//             <GoogleMap
//                 mapContainerStyle={{ width: "60%", aspectRatio: "1.5/1" }}
//                 center={center}
//                 zoom={15}
//                 onClick={clickedMap}
//             >
//                 <Autocomplete
//                     restrictions={{ country: 'np' }}
//                     types={'cities'}
//                     onLoad={(autocomp) => {
//                         setAutocomplete(autocomp)
//                     }}
//                     onPlaceChanged={() => {
//                         if (autocomplete !== null) {
//
//                             let selLocation = autocomplete.getPlace().geometry.location;
//                             setLatAndLng(selLocation.lat(), selLocation.lng())
//                         }
//                     }}
//                 >
//                     <input
//                         type="text"
//                         placeholder="Enter Your Location"
//                         style={{
//                             boxSizing: `border-box`,
//                             border: `1px solid transparent`,
//                             width: `50%`,
//                             height: `40px`,
//                             padding: `0 12px`,
//                             borderRadius: `3px`,
//                             boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
//                             fontSize: `18px`,
//                             outline: `none`,
//                             textOverflow: `ellipses`,
//                             position: "absolute",
//                             top: "1%",
//                             left: "25%",

//                         }}
//                     />
//                 </Autocomplete>
//                 <Marker
//                     position={latLng}
//                 />
//             </GoogleMap>
//         </>
//     )
// }

