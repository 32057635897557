import React, {useContext} from 'react'
import {useQuery} from '@tanstack/react-query'
import axios from '../../../api'
import {LoginStateContext} from '../../../../contexts/LoginStateContext'
import NotificationList from './NotificationList'

function NewsNotifcation() {
    const loginContext = useContext(LoginStateContext)
    const {data, isLoading} = useQuery(
        ['news_notifiations'],
        async () => {
            return axios.get("api/admin/booking-notification?type=News", {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                .then(res => res.data)
                .catch(err => err)
        }
    )

    if (isLoading) {
        return <h4>Loading...</h4>
    }

    console.log()

    return (
        <NotificationList data={data}/>
    )
}

export default NewsNotifcation
