import {Alert, AlertTitle} from '@mui/material';
import React, {useContext, useState} from 'react'
import {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import swal from 'sweetalert';
import {LoginStateContext} from '../../../../contexts/LoginStateContext';
import axios from '../../../api.js';

const ShareReport = () => {
    const loginContext = useContext(LoginStateContext)
    const [reports, setReports] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const {
        register,
        handleSubmit,
    } = useForm();

    useEffect(() => {
        if (location.state == null) {
            navigate('/user/appointments/doctor');
        } else {
            setReports(location.state.reports);
        }
    }, [])

    const onSubmit = (data) => {
        axios.post(`/api/admin/booking-review/forward-report/${params.slug}`, data, {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
            .then(res => {
                if (res.data.success) {
                    swal({
                        title: res.data.success,
                        icon: "success",
                    }).then((value) => {
                        if (value) {
                            navigate('/user/appointments/doctor');
                        }
                    });
                }
            })
            .catch((err) => {
                console.log();
            })
    }

    return (
        <>
            {reports.length !== 0 ?
                <>
                    <h5 style={{fontWeight: '600'}} className="mb-4">Please select the medical reports you want to
                        forward the doctor.</h5>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            {
                                reports.map((file) => (
                                    <div className='col-md-4' key={file.id}>
                                        <div className="form-check">
                                            <input className="form-check-input"
                                                   type="checkbox" {...register("report_id")} value={file.id}/>
                                            <embed src={`${file.report_path}#view=FitH`}
                                                   style={{width: "100%", aspectRatio: "1/1.3"}}/>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <button className="btn btn-primary d-flex" type='submit'>
                            <span className="material-symbols-outlined me-2">
                                share
                            </span>
                            Send your report
                        </button>
                    </form>
                </>
                :
                <Alert severity="error">
                    <AlertTitle>No Medical Reports</AlertTitle>
                    You haven't uploaded any medical records yet!
                </Alert>
            }
        </>
    )
}

export default ShareReport
