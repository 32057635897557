import React from 'react'
import {GoogleMap, Marker} from '@react-google-maps/api'
import {useQuery} from '@tanstack/react-query'
import axios from "../api"
import {useParams} from 'react-router-dom'
import {useEffect} from 'react'
import {useRef} from 'react'

function EventDetailPage() {
    const {isLoading, data} = useQuery(
        ['homeEvents'],
        async () => {
            return axios.get("api/event-expo")
                .then(res => res.data).catch(err => err)
        }
    )
    const params = useParams()
    const eventDesc = useRef(null)
    const eventAndExpo = data?.filter((eve) => eve.slug == params.slug)[0]

    useEffect(() => {
        if (!isLoading) {
            eventDesc.current.innerHTML = eventAndExpo.description
        }
    }, [isLoading])

    if (isLoading) {
        return <h4>Loading...</h4>
    }

    return (
        <div className="event-detail-pg">
            <div className="container">
                <div className="row py-5">
                    <div className="col-lg-8 pe-5">
                        <div className="upcoming-evt">
                            <h4 className='text-danger text-center'>
                                UPCOMING EVENT 2022
                            </h4>
                        </div>
                        <div className="event-title text-center">
                            <h1>{eventAndExpo.title}</h1>
                        </div>
                        <div className="img-container w-100 overflow-hidden ratio ratio-16x9">
                            <img
                                src="https://demo.ghargharmadoctor.com/storage/images/5e105bdd12124100012a1309_cnbless-wrist-blood-pressure-monitor_415_1672637041.jpeg"
                                alt="" className='w-100' style={{objectFit: "contain"}}/>
                        </div>
                        <div className="event-desc" ref={eventDesc}>
                            <p>
                                The two-day symposium themed Promoting Innovation in Local Governance for Inclusive
                                Economic Development of Nepal held on October 13-14 in Kathmandu brought together more
                                than 300 stakeholders from the government, the private sector, civil society, and
                                international scholars and development agencies.
                            </p>
                            <p>
                                On the first day, 11 municipalities from six provinces of Nepal shared their public
                                innovations, key lessons and scale-up opportunities and on the second day, national
                                stakeholders deliberated on ways to institutionalise the learning from the first day and
                                scale up policy impact.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="card">
                            <div className="card-title-event card-header">
                                <h4 className="text-center">EVENT SCHEDULE</h4>
                            </div>
                            <div className="card-body text-center">
                                <h3><b>{eventAndExpo.start_date}</b> to <b>{eventAndExpo.end_date}s</b></h3>
                            </div>
                            <div className="px-2 w-100 mb-2">
                                <div className="btn btn-primary w-100 py-2 rounded-pill">
                                    <h5 className='m-0 p-0'>GET TICKET NOW</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card mt-4">
                            <div className="card-title-event card-header">
                                <h4 className='text-center'>EVENT LOCATION</h4>
                            </div>
                            <div className="card-body">
                                <div className="property-map d-flex justify-content-center">
                                    <MapInput lat={eventAndExpo.latitude} lng={eventAndExpo.longitude}/>
                                </div>
                                <div className="text-center">
                                    <h4 className='p-0 m-0 mt-4'>{eventAndExpo.address}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function MapInput({lat, lng}) {
    return (
        <>
            <GoogleMap
                mapContainerStyle={{width: "100%", aspectRatio: "1.3/1"}}
                center={{lat: Number(lat), lng: Number(lng)}}
                zoom={15}
            >
                <Marker position={{lat: Number(lat), lng: Number(lng)}}/>
            </GoogleMap>
        </>
    )
}

export default EventDetailPage
