import React from "react";
import {useEffect} from "react";
import {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {Link, useLocation, useNavigate} from "react-router-dom";
import swal from "sweetalert";
import axios from "../../api.js";
import Loader from "../Loader.js";

const BookService = ({service}) => {
    const [loading, setLoading] = useState(true);
    // const location = useLocation();
    // const service = location.state.service;
    const token = localStorage.getItem("token");
    const [user, setUser] = useState(null);
    let navigate = useNavigate();
    const [total, setTotal] = useState(0);

    useEffect(() => {
        axios
            .get("/api/admin/user-profile", {
                headers: {Authorization: `Bearer ${token}`},
            })
            .then((res) => {
                // console.log();
                setUser(res.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log();
                setLoading(false);
            });
    }, [token]);

    const {register, handleSubmit, watch, setValue} = useForm();
    const testId = watch('test_id')
    useEffect(() => {
        if (testId) {
            let amt = 0
            testId.forEach(id => {
                service.tests.filter(test => test.id == id).map((tst) =>
                    amt += parseInt(tst.price)
                )
            });
            setTotal(amt)
            setValue('price', amt.toString())
        }
    }, [testId])

    const onSubmit = (data) => {
        axios
            .post("/api/admin/book-service", data, {
                headers: {Authorization: `Bearer ${token}`},
            })
            .then((res) => {
                //console.log();
                swal({
                    title: "Lab Test Booked!",
                    icon: "success",
                    button: "Proceed for Payment",
                }).then((value) => {
                    if (value) {
                        navigate("/book-lab-test/payment", {state: {fee: data.price, id: res.data.id}})
                    }
                });
            })
            .catch((err) => {
                console.log();
                setLoading(false)
            });
    };

    if (!loading && user == null) {
        return (
            <>
                <label className="mb-4">
                    <b>Login or Signup with Ghargharma Doctor to Book Services now.</b>
                </label>

                <div className="d-flex justify-content-center align-items-center w-100">

                    <div>
                        <Link to="/login" className="btn btn-primary mb-4">
                            Login - If you already have an account
                        </Link>
                        <br/>
                        <Link to="/register" className="btn btn-primary">
                            Signup - If you don't have an account
                        </Link>
                    </div>

                </div>
            </>


        )

    }

    return (
        <>
            {loading ? (
                <div className="text-center">
                    <Loader/>
                </div>
            ) : (
                <div className="container">
                    {user && (
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <h5 style={{color: "#0259A7"}}>
                                <b>
                                    {" "}
                                    {service.service_name} Booking Details
                                </b>
                            </h5>
                            <input
                                type="hidden"
                                {...register("service_id")}
                                value={service.id}
                            />
                            <div className="row g-3">
                                <div className="form-group">
                                    <label htmlFor=""><b>Date</b></label>
                                    <div className="col-12 mt-2">
                                        <input
                                            type="date"
                                            {...register("date")}
                                            className="form-control" required
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor=""><b>Time</b></label>
                                    <div className="col-12 mt-1">
                                        <input
                                            type="time"
                                            {...register("time")}
                                            className="form-control" required
                                        />
                                    </div>
                                </div>
                                {service.price == null &&
                                    <div className="form-group">
                                        <label htmlFor=""><b>Select Tests</b></label>
                                        <div className="row mt-2">
                                            {service.tests.map((test) =>
                                                <div className="col-md-6">
                                                    <div className="form-check">
                                                        <input className="form-check-input"
                                                               type="checkbox" {...register('test_id')}
                                                               value={test.id}/>
                                                        <label className="form-check-label">
                                                            {test.tests} - Rs. {test.price}
                                                        </label>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                }
                                {service.price != null ?
                                    <div className="form-group">
                                        <label htmlFor=""><b>Total Amount</b></label>
                                        <div className="col-12 mt-2">
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                <span className="input-group-text" id="basic-addon1">
                                                    Rs.
                                                </span>
                                                </div>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    {...register('price')}
                                                    value={service.price}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="form-group">
                                        <label htmlFor=""><b>Total Amount</b></label>
                                        <div className="col-12 mt-2">
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                <span className="input-group-text" id="basic-addon1">
                                                    Rs.
                                                </span>
                                                </div>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={total}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>

                            <button type="submit" className="btn btn-primary py-2 px-3">
                                Book Service
                            </button>
                        </form>
                    )}
                </div>
            )}
        </>
    );
};

export default BookService
