import { doctorBookingVerification, packageBookingVerification } from "./verifications"

export const paymentTypes = {
    'doctor_booking': doctorBookingVerification,
    'package_booking': packageBookingVerification,
}

export const getPaymentType = (param) => {
    if(param in paymentTypes) {
        return paymentTypes[param]
    }

    throw new Error("Payment method does not exist!");
}

