import {KeyboardArrowDownOutlined} from "@mui/icons-material"
import {Box, Collapse, Grid, Typography} from "@mui/material"
import {useState} from "react"

function InformationComponent({children, title}) {
    const [expanded, setExpanded] = useState(true)
    return (
        <div>
            <Box display='flex' justifyContent='space-between' alignItems='center' sx={{cursor: 'pointer'}}
                 onClick={() => setExpanded(prev => !prev)}>
                <Typography variant='h4' fontWeight={500}>{title}</Typography>
                <KeyboardArrowDownOutlined sx={{fontSize: 30}}/>
            </Box>
            <Collapse in={expanded} sx={{mt: 2}}>
                {
                    children
                }
            </Collapse>
        </div>
    )
}

export default InformationComponent
