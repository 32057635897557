import React, {useEffect, useState} from 'react'
import {Controller, useForm} from 'react-hook-form';
import {useLocation, useNavigate} from 'react-router-dom';
import axios from '../../../api.js';
import 'react-phone-number-input/style.css'
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input'
import "./EditProfile.css";
import {parsePhoneNumber, getCountryCallingCode} from 'react-phone-number-input'
import swal from 'sweetalert';
import {useQuery} from '@tanstack/react-query';
import {useContext} from 'react';
import {LoginStateContext} from '../../../../contexts/LoginStateContext.js';
import {FileUploader} from 'react-drag-drop-files';
import {Box, CircularProgress, InputBase} from '@mui/material';
import {meterToFeetInch} from '../../../../helperFunctions/meterToFeetInch.js';

const imageTypes = ["JPG", "PNG", "GIF", "PDF"];
const fileTypes = ["PDF"];
const EditProfile = () => {
    const [submitting, setSubmitting] = useState(false)
    const navigate = useNavigate();
    const location = useLocation()
    const loginContext = useContext(LoginStateContext)
    const userData = loginContext.state.data
    const [user, setUser] = useState(userData);
    const today = new Date();
    const formattedToday = today.toISOString().split('T')[0]
    useEffect(() => {
        axios
            .get("api/admin/user-profile", {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
            .then((res) => {
                setUser(prev => ({
                    ...prev, ...res.data
                }));
                console.log(res.data)
            })
            .catch((err) => {

            });
    }, [loginContext.state.token]);

    const phoneNumber = parsePhoneNumber(user.member.phone);
    const preFormValues = {
        name: user.member.name,
        email: user.member.email,
        phone: user.member.phone,
        gender: user.gender,
        address: user.address,
        dob: user.dob,
        blood_group: user.blood_group,
        weight: user.weight,
        height: user.height,
        height_feet: user.height_feet,
        height_inch: user.height_inch,
        image_path: user.image_path
    }

    const {
        register,
        handleSubmit,
        formState: {errors},
        control
    } = useForm({
        defaultValues: preFormValues
    });
    const [image, setImage] = useState(null);
    const [imagePath, setImagePath] = useState(user.image_path);
    const [file, setFile] = useState(user.file);
    const [filePath, setFilePath] = useState(user.file_path);

    const restrictNegative = (e) => {
        if (!((e.keyCode > 95 && e.keyCode < 106)
            || (e.keyCode > 47 && e.keyCode < 58)
            || e.keyCode == 8)) {
            e.preventDefault()
        }
        return true;
    }

    const handleImage = (image) => {
        //
        var reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = () => {
            setImage(reader.result)
            //
        }
        reader.onerror = error => {

        }
        //   setImage(image);
        setImagePath(URL.createObjectURL(image))
    };
    const handleFile = (file) => {
        setFile(file);
        setFilePath(URL.createObjectURL(file))
    };

    const sendCompState = () => {
        if (location.state.redirect_to && location.state.componentState) {
            navigate(location.state.redirect_to, {state: {componentState: location.state.componentState}})
        }
    }

    const onSubmit = (data) => {
        const fd = new FormData();
        data.height = (Number(data.height_feet) * 12 + Number(data.height_inch)) / 39.3701;
        //

        for (var key in data) {
            fd.append(key, data[key]);
        }
        if (image !== null) {
            fd.append('image', image);
        }

        // fd.append('file',file);
        //
        setSubmitting(true)
        axios.post(`/api/admin/user-profile/update/${user.id}`, fd, {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
            .then(res => {
                swal({
                    title: "Profile Updated successfully!",
                    icon: "success",
                }).then((value) => {
                    if (value) {
                        loginContext.refetch(loginContext.state.token)
                        if (location.state?.redirect_to) {
                            //
                            navigate(location.state.redirect_to, {state: {componentState: location.state?.componentState}})
                        } else {
                            navigate("/user");
                        }
                    }
                }).finally(() => {
                    setSubmitting(false)
                });
            })
            .catch((error) => {
                // if(error.response.data.message){
                //     setFamilyError(error.response.data.message);
                // }

            });
    }

    const renderImage = (img) => {
        if (img == null || img.length == 0) {
            //
            if (user?.image_path) {
                return (
                    <div className="imgUpload">
                        <img src={user.image_path} alt="upload img"/>
                    </div>
                )
            }
            return
        }
        return (
            <div className="imgUpload">
                <img src={img} alt="upload img"/>
            </div>
        )
    }

    const renderFile = (file) => {
        if (file == null || file.length == 0) {
            return
        }
        return (
            <div className="viewFileUpload">
                <iframe src={file} width="100%" title="KYC Form"/>
                Review PDF <a href={file} target="_blank" rel="noreferrer">Click here</a>
            </div>

        )
    }

    return (
        <div className='card editProfile'>
            {/* <FileUploader types={imageTypes}/>   */}
            <form onSubmit={handleSubmit(onSubmit)} className="form">
                <div className="card-body">
                    <div className="row g-3">
                        <div className="col-md-4">
                            <label className="form-label">Full Name</label>
                            <input
                                type="text"
                                className="form-control input-field"
                                required
                                {...register("name")}
                            />
                            {errors.name?.type === "required" && (
                                <p>Please enter your name.</p>
                            )}
                        </div>
                        {/* <div className="col-md-4">
                            <label className="form-label">Email</label>
                            <input
                                type="email"
                                className="form-control input-field"
                                required
                                {...register("email", {
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "Please enter a valid email.",
                                },
                                })}
                            />
                            {errors.email && (
                                <p>{errors.email.message}</p>
                            )}
                        </div> */}
                        {/* <div className="col-md-4">
                            <label className="form-label">Phone</label>
                            <Controller
                            name="phone"
                            control={control}
                            required
                            rules={{
                                required:"Please enter a valid phone.",
                                validate: (value) => isValidPhoneNumber(value)
                            }}
                            render={({ field:{onChange,value} }) => (
                                <PhoneInput
                                value={value}
                                onChange={onChange}
                                country={phoneNumber.country}
                                id="phone-input1"
                                international
                                countryCallingCodeEditable={false}
                                addInternationalOption={false}
                                />
                            )}
                            />
                            {errors.phone && (
                                <p>Please enter a valid phone.</p>
                            )}
                        </div> */}
                        <div className="col-md-4">
                            <label className="form-label">Gender</label>
                            <select required {...register("gender")} className="form-select input-field">
                                <option value="">--Select--</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Others">Others</option>
                            </select>
                            {errors.gender?.type === "required" && (
                                <p>Please select your gender.</p>
                            )}
                        </div>
                        <div className="col-md-4">
                            <label className="form-label">Address</label>
                            <input
                                type="text"
                                className="form-control input-field"
                                required
                                {...register("address")}
                            />
                            {errors.address?.type === "required" && (
                                <p>Please enter your address.</p>
                            )}
                        </div>
                        <div className="col-md-4">
                            <label className="form-label">Date of Birth</label>
                            <input
                                type="date"
                                className="form-control input-field"
                                required
                                max={formattedToday}
                                {...register("dob")}
                            />
                            {errors.dob?.type === "required" && (
                                <p>Please enter your dob.</p>
                            )}
                        </div>
                        <div className="col-md-4">
                            <label className="form-label">Blood Group</label>
                            <select required {...register("blood_group")} className="form-select input-field">
                                <option value="">--Select--</option>
                                <option value="A+">A+</option>
                                <option value="A-">A-</option>
                                <option value="B+">B+</option>
                                <option value="B-">B-</option>
                                <option value="O+">O+</option>
                                <option value="O-">O-</option>
                                <option value="AB+">AB+</option>
                                <option value="AB-">AB-</option>
                            </select>
                            {errors.blood_group?.type === "required" && (
                                <p>Please select your blood group.</p>
                            )}
                        </div>
                        <div className="col-md-4">
                            <label className="form-label">Weight</label>
                            <div className="input-group mb-2 w-100">
                                <div className="input-group-prepend w-100">
                                    <input
                                        type="number"
                                        className="form-control input-field w-100"
                                        min="0"
                                        required
                                        onKeyDown={restrictNegative}
                                        {...register("weight")}
                                        placeholder="Weight in kg"
                                    />
                                    <div className="input-group-text">KG</div>
                                </div>
                            </div>
                            {errors.weight?.type === "required" && (
                                <p>Please enter your weight.</p>
                            )}
                        </div>
                        <div className="col-md-4">
                            <label className="form-label">Height</label>
                            <Box display='flex' alignItems='center'>
                                <div className="input-group mb-2">
                                    <input type="number" min="0" max="10" onKeyDown={restrictNegative}
                                           className="form-control" id="inlineFormInputGroup" placeholder="Feet"
                                           required {...register('height_feet')} />
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Feet</div>
                                    </div>
                                </div>
                                <div className="input-group mb-2">
                                    <input type="number" min="0" max="11" onKeyDown={restrictNegative}
                                           className="form-control" id="inlineFormInputGroup" placeholder="Inches"
                                           required {...register('height_inch')} />
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Inches</div>
                                    </div>
                                </div>
                            </Box>
                            {errors.height?.type === "required" && (
                                <p>Please enter your height.</p>
                            )}
                        </div>
                        <div className="col-md-4">
                            <label className="form-label">
                                Upload your photo
                            </label>
                            <div className='image-input'>
                                {/* <FileUploader handleChange={handleImage} name="image" required /> */}
                                <FileUploader name="image" types={imageTypes} hoverTitle="Drop here"
                                              handleChange={handleImage} required/>
                                {image ? <>{renderImage(imagePath)}</> : ''}
                            </div>
                        </div>

                    </div>
                </div>
                <div className="card-footer">
                    <button type="submit" disabled={submitting} className="btn btn-primary">
                        {
                            submitting ? <CircularProgress size={20}/> : 'Update'
                        }
                    </button>
                </div>
            </form>
        </div>
    )
}

export default EditProfile
