import React from 'react'
import {useState} from 'react'
import {useContext} from 'react'
import {NavPillContext} from './NavPillContext'

let index = -1;

function NavPillTab({value, children, onChange}) {
    index = -1;
    return (
        <NavPillContext.Provider value={{value: value, onChange: onChange}}>
            <ul className="nav nav-pills nav-justified mb-3 p-1 register-tab" id="pills-tab" role="tablist">
                {children}
            </ul>
        </NavPillContext.Provider>
    )
}

function NavPillItem({label}) {
    const {value, onChange} = useContext(NavPillContext)
    const [idx, setIdx] = useState(++index)

    function handleClick(e, idx) {
        onChange(e, idx)
        console.log()
    }

    return (
        <li className="nav-item" role="presentation">
            <button className={`nav-link ${value == idx && 'active'}`} id="pills-user-tab" type="button" role="tab"
                    aria-controls="pills-user" aria-selected="true"
                    onClick={(e) => handleClick(e, idx)}>{label}</button>
        </li>
    )
}

export {NavPillTab, NavPillItem}
