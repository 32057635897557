import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import React from "react";
import InputFieldLabel from "../../form/InputFieldLabel";

const SelectReactHookForm = (props) => {
    const {label, name, options, error, form, required = false, placeholder} = props
    const {setValue, watch, register} = form

    const handleChange = (e) => {
        setValue(name, e.target.value)
    };

    const value = watch(name)

    return (
        <FormControl  variant="standard" fullWidth color="primary">
            <InputFieldLabel required={required}>
                {label}
            </InputFieldLabel>
            <Select
                {...register(name)}
                color="primary"
                value={value}
                error={Boolean(error)}
                disableUnderline
                displayEmpty
                renderValue={value !== "" ? undefined : () => <div style={{color:"#B1B1B1"}}>Select a field</div>}
                classes="custom-select"
                sx={{
                    background: "#fff",
                    borderRadius: '6px',
                    height: 44,
                    paddingLeft: '12px',
                    marginTop: 0,
                }}
                onChange={(e, data) => handleChange(e)}
            >
                {
                    options.map((item) => (
                        <MenuItem key={item.value} color="primary" value={item.value}>
                            {item?.label}
                        </MenuItem>
                    ))
                }
            </Select>
            {
                Boolean(error) &&
                <p className="text-danger">
                    {error}
                </p>
            }
        </FormControl>
    )
}

export default SelectReactHookForm
