import {faBagShopping} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React from 'react'
import {Link} from 'react-router-dom'

function ServiceItemCard({labservice}) {
    return (
        <div className="service-item-card">
            <div className="service-item-img">
                <img src={labservice.image_path} alt=""/>
            </div>
            <div className="service-details mt-2">
                <div className="service-name">
                    <span><b>{labservice.service_name}</b> </span>
                </div>
                <div className="service-price">
                    NPR. {labservice.price}
                </div>
                <Link to={`/health-service/${labservice.slug}`}
                      className="btn btn-success text-decoration-none text-white">
                    <FontAwesomeIcon icon={faBagShopping} className="me-3"/>
                    Buy Now
                </Link>
                {/* <div className="btn btn-success">
                    <FontAwesomeIcon icon={faBagShopping} className="me-3" />
                    Buy Now
                </div> */}
            </div>
        </div>
    )
}

export default ServiceItemCard
