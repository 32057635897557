import {faCalendar, faCaretRight, faClock} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React from 'react'
import {useEffect} from 'react'
import {useState} from 'react'
import {useForm} from 'react-hook-form'
import {useLocation, useNavigate} from 'react-router-dom'
import swal from 'sweetalert'
import HomeIcon from '../../../images/home-icon.png'
import axios from "../../api.js";
import Loader from '../Loader'
import {useChkLogin} from '../../../middlewares/LoginMiddleware'

const NurseForm = () => {
    const [loading, setLoading] = useState(true);
    const {token, isLoading} = useChkLogin();
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const nurse = location.state.nurse;
    const shift = location.state.shift;

    useEffect(() => {
        axios.get('/api/admin/user-profile', {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                console.log();
                setUser(res.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log()
            });
    }, [token])

    const {
        register,
        handleSubmit,
    } = useForm();

    const onSubmit = (data) => {
        axios.post('/api/admin/nurses/book', data, {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                console.log();
                swal({
                    title: "Nurse Appointment Booked!",
                    icon: "success",
                    button: "Proceed for Payment",
                }).then((value) => {
                    if (value) {
                        navigate("/book-nurse/payment", {state: {fee: nurse.fee, id: res.data[0].id}})
                    }
                });
            })
            .catch((err) => {
                console.log();
            })
    }

    return (
        <>
            {loading ?
                <div className="text-center">
                    <Loader/>
                </div>
                :
                <div className='booking-form'>
                    <div className="container py-5">
                        <div className="card">
                            {user &&
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="d-flex flex-wrap">
                                        <div className="col-md-5 doctor-profile flex-fill">
                                            <div className="card-header d-flex align-items-center py-3 booking-heading">
                                                <img src={HomeIcon} alt=""/>
                                                <span className='ms-3'>Nurse Booking Details</span>
                                            </div>
                                            <div className="card-body px-0 pt-0">
                                                <div
                                                    className="d-flex py-4 flex-wrap justify-content-center profile-description">
                                                    <img src={nurse.image_path} alt="" className="doctor-image"/>
                                                    <div className='ms-1'>
                                                        <h5>{nurse.user.name}</h5>
                                                        <ul className="list-unstyled mb-4">
                                                            <li><FontAwesomeIcon icon={faCaretRight} className="me-2"/>Nurse
                                                                Type: {nurse.nurse_type}</li>
                                                            <li><FontAwesomeIcon icon={faCaretRight}
                                                                                 className="me-2"/>Qualification: {nurse.qualification}
                                                            </li>
                                                            <li><FontAwesomeIcon icon={faCaretRight}
                                                                                 className="me-2"/>Experience: {nurse.year_practiced} years
                                                            </li>
                                                            <li><FontAwesomeIcon icon={faCaretRight} className="me-2"/>Booking
                                                                Fee: Rs. {nurse.fee}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className='px-3 py-3 profile-description'>
                                                    <div className="d-flex justify-content-between">
                                                        <div className="d-flex">
                                                            <FontAwesomeIcon icon={faCalendar}
                                                                             style={{color: '#B0D0ED'}}
                                                                             className="mt-1"/>
                                                            <p className='ms-2 mb-0'> On <span
                                                                style={{fontWeight: '600'}}>{shift.date}</span></p>
                                                        </div>
                                                        <div className="d-flex">
                                                            <FontAwesomeIcon icon={faClock} style={{color: '#B0D0ED'}}
                                                                             className="mt-1"/>
                                                            <p className='ms-2 mb-0'> Shift: <span
                                                                style={{fontWeight: '600'}}>{shift.shift}</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-7 flex-fill patient-details">
                                            <div className="card-header border-0 py-3">
                                                <h4>Patient Information</h4>
                                            </div>
                                            <div className="card-body pt-0 px-1 form">
                                                <input type="hidden" {...register("nurseshift_id")} value={shift.id}/>
                                                <div className="row g-2">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <label className="form-label">Full Name</label>
                                                                <p>{user.member.name}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <label className="form-label">Phone Number</label>
                                                                <p>{user.member.phone}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <label className="form-label">Your Email</label>
                                                                <p>{user.member.email}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <label className="form-label">Address</label>
                                                                <p>{user.address}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <label className="form-label">Date of Birth</label>
                                                                <p>{user.dob}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <label className="form-label">Age</label>
                                                                <p>{user.dob ? new Date().getFullYear() - user.dob.slice(0, 4) : ''}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <label className="form-label">Gender</label>
                                                                <p>{user.gender}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <label className="form-label">Blood Group</label>
                                                                <p>{user.blood_group}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <label className="form-label">Weight</label>
                                                                <p>{user.weight}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <label className="form-label">Height</label>
                                                                <p>{user.height}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 border-0">
                                                        <label className="form-label mb-2">Your Health Condition/ Your
                                                            Message</label>
                                                        <textarea className='form-control mb-2'
                                                                  rows="5" {...register("messages")} required
                                                                  placeholder='Write about your health condition or any other message .....'>

                                                </textarea>
                                                    </div>
                                                    <div className="col-md-12 text-end border-0">
                                                        <button type="submit" className='btn btn-primary py-2 px-3'>Book
                                                            Nurse Appointment
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default NurseForm
