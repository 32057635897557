import {CallOutlined, Close} from '@mui/icons-material'
import {Avatar, Box, IconButton, Tooltip} from '@mui/material'
import React from 'react'
import Mascot from '../../images/mascot.png'

function ChatHeader({closeWindow}) {
    return (
        <Box px={2} py={1} bgcolor={'#0259A7'} color='white' width='100%' display='flex' justifyContent='space-between'
             alignItems='center'>
            <Box display='flex' alignItems={'center'} gap={1}>
                <Avatar src={Mascot} sx={{'& img': {objectFit: 'contain'}}}/>
                <span>Doctor Saab</span>
            </Box>
            <Box display='flex' gap={2} alignItems='center'>
                <Box>

                </Box>
                {/* <span>
                4/5 call
            </span>
            <IconButton>
                <CallOutlined sx={{color:'white'}}/>
            </IconButton> */}
                <IconButton onClick={closeWindow}>
                    <Close sx={{color: 'white'}}/>
                </IconButton>
            </Box>
        </Box>
    )
}

export default ChatHeader
