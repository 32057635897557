import {Alert, AlertTitle, Box} from '@mui/material'
import React from 'react'
import {useState} from 'react'
import {useContext} from 'react'
import {LoginStateContext} from '../../../../../contexts/LoginStateContext'
import UnpaidItem from './UnpaidItem'
import axios from "../../../../api"
import {useQuery} from '@tanstack/react-query'
import {useEffect} from 'react'
import PaidItem from './PaidItem'

function YourFitness() {
    const loginContext = useContext(LoginStateContext)
    const [open, setOpen] = useState(false)
    const {data, isLoading} = useQuery(
        ['myFitness'],
        async () => axios.get("/api/fitness/fitness-booking", {headers: {"Authorization": `Bearer ${loginContext.state.token}`}}).then(res => res.data)
    )
    const [membershipPackage, setMembershipPackage] = useState([])

    useEffect(() => {
        if (data !== null) {
            const filtered = data.filter((item) => item.payment_status === 'paid')
            setMembershipPackage(filtered)
        }
    }, [data])


    if (isLoading) {
        return
    }

    if (data.length === 0) {
        return (
            <Alert severity="error">
                <AlertTitle>No Data Found</AlertTitle>
                We couldn't find any fitness packages
            </Alert>
        )
    }

    return (
        <Box>
            {
                membershipPackage.map((item, idx) => (
                    <PaidItem key={idx} data={item}/>
                ))
            }
        </Box>
    )
}

export default YourFitness
