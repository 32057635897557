import {useQuery} from '@tanstack/react-query';
import React, {useContext, useState} from 'react'
import {useParams} from 'react-router-dom';
import {LoginStateContext} from '../../../../../contexts/LoginStateContext';
import axios from "../../../../api"
import Loader from '../../../Loader';
// import { Document, Page,pdfjs } from 'react-pdf';
// pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ViewReport = () => {
    const loginContext = useContext(LoginStateContext)
    const params = useParams();
    // const [numPages, setNumPages] = useState(null);
    // const [pageNumber, setPageNumber] = useState(1);
    const viewMedicalReportQuery = useQuery(
        ["view-medical-report-pdf", params.id],
        async () => {
            return axios.get(`/api/admin/medical-report/pdf?id=${params.id}`, {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                .then(res => res.data)
        },
    )
    if (viewMedicalReportQuery.isLoading) {
        return <div className='d-flex justify-content-center'><Loader/></div>
    }
    const report = viewMedicalReportQuery.data

    // function onDocumentLoadSuccess({ numPages }) {
    //     setNumPages(numPages);
    // }
    return (
        <>
            {report &&
                <div>
                    <iframe src={report.report_path} width="100%" height="1500px" title={report.report}></iframe>

                    {/* <Document file={report.report_path} onLoadSuccess={onDocumentLoadSuccess} onLoadError={console.error}>
                    {Array.from(new Array(numPages), (el, index) => (
                        <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                    ))}
                </Document> */}
                    {/* <p>
                    Page {pageNumber} of {numPages}
                </p> */}
                </div>
            }
        </>
    )
}

export default ViewReport
