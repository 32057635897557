import CategoryProducts from "../../stores/CategoryProducts";
import StoreNavVendor from "../../stores/StoreNavVendor";
import StoreVendorList from "../../stores/StoreVendorList";
import StoreFilterBar from "../../stores/StoreFilterBar";
import Footer from "../../Footer";
import StoreHeader from "../../stores/layouts/StoreHeader";
import {StoreContext} from "../../../contexts/StoreContext";
import React from "react";
import {useParams, useSearchParams} from "react-router-dom";
import axios from "../../api.js";
import Loader from "../Loader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleXmark, faFilter} from "@fortawesome/free-solid-svg-icons";
import {useChkLogin} from "../../../middlewares/LoginMiddleware";

export const ACTIONS = {
    FETCH_SUCCESS: "FETCH_SUCCESS",
    FETCH_FAILURE: "FETCH_FAILURE",
    REFETCH: "REFETCH",
};

const initialState = {
    data: [],
    loading: true,
    message: "",
};

const reducer = (product, action) => {
    switch (action.type) {
        case ACTIONS.FETCH_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                message: "Product Fetched Successfully",
            };
        case ACTIONS.FETCH_FAILURE:
            return {
                data: [],
                loading: false,
                message: "Error while fetching product",
            };
        case ACTIONS.REFETCH:
            return {
                data: [],
                loading: true,
                message: "",
            };
        default:
            return product;
    }
};

const ActiveFilters = ({filters, setSearchParams}) => {
    if (Object.keys(filters).length == 0) {
        return;
    }


    const filterTagStyle = {
        backgroundColor: "white",
        border: "1px solid #CED4DA",
        borderRadius: "10px",
        padding: "0 20px",
    };

    const deleteFilter = (key) => {
        if (key == "min_price" || key == "max_price") {
            delete filters.min_price;
            delete filters.max_price;
        } else {
            delete filters[key];
        }
        setSearchParams(filters);
    };

    return (
        <div className="active-filters d-flex align-items-center mt-4">
            <span className="active-filters-title me-3">Current Filters:</span>
            {Object.keys(filters).map((key) => {
                return key != "sort" ? (
                    <div
                        className="filter-tag d-flex align-items-center g-2 me-2"
                        style={filterTagStyle}
                    >
            <span className="filter-tag-title me-2">
              {`${key}: ${filters[key]}`}
            </span>
                        <span
                            className="filter-tag-cross align-self-center"
                            onClick={() => {
                                deleteFilter(key);
                            }}
                        >
              <FontAwesomeIcon icon={faCircleXmark} size="sm"/>
            </span>
                    </div>
                ) : (
                    <></>
                );
            })}
        </div>
    );
};

const Store = () => {
    const {isLoading, data, token, isLoggedIn} = useChkLogin()
    const storeContext = React.useContext(StoreContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const [prod, dispatch] = React.useReducer(reducer, initialState);
    const [title, setTitle] = React.useState("");
    const currentParams = Object.fromEntries([...searchParams]);
    const [toggleClicked, setToggleClicked] = React.useState(false);
    const param = useParams();

    const loadingRoller = (
        <div className="text-center">
            <Loader/>
        </div>
    );

    // React.useEffect(() => {
    //   storeContext.setBreadCrumbs([
    //     ["/", "Home"],
    //     ["current_page", "Store"],
    //   ]);
    // }, []);

    React.useEffect(() => {
        dispatch({type: ACTIONS.REFETCH});
        if (!isLoading) {
            var urlParam = {};
            // urlParam['vendor_type'] = param.vendorType
            if (param.category != null && param.category !== "product_all") {
                urlParam["category_id"] = param.category;
            }
            if (param.brand != null) {
                urlParam["brand"] = param.brand
            }
            if (isLoggedIn) {
                urlParam["user_id"] = data.member.id;
                urlParam['type'] = 'product'
            }

            if (Object.keys(currentParams).length > 0) {
                urlParam = {...urlParam, ...currentParams};
            }
            axios
                .get("api/products", {params: {...urlParam}}, {headers: {'Authorization': `Bearer ${token}`}})
                .then((res) => {
                    dispatch({type: ACTIONS.FETCH_SUCCESS, payload: res.data});
                })
                .catch((err) => {
                    dispatch({type: ACTIONS.FETCH_FAILURE});
                });

            param.brand = null;
            param.type = null;
        }
    }, [searchParams, param, isLoading]);

    const chkToggleClick = () => {
        setToggleClicked(!toggleClicked)
    }

    return (
        <>
            <div className="container category-product">
                <div className="category-prod-container">
                    {/* <StoreNavVendor /> */}
                    {param.vendorType != null && <StoreVendorList vendorType={param.vendorType}/>}

                    {/* <h4>{getCurrentCat()}</h4> */}
                    <div className="row mt-4">
                        <div className="col-lg-3">
                            <StoreFilterBar
                                setSearchParams={setSearchParams}
                                loadingRoller={loadingRoller}
                                currentParams={currentParams}
                                dispatch={dispatch}
                                setTitle={setTitle}
                                toggleClicked={toggleClicked}
                                chkToggleClick={chkToggleClick}
                            />
                        </div>
                        <div className="col-lg-9 center-container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div
                                        className="prod-top-bar row d-flex justify-content-between align-items-center me-2">
                                        <div
                                            className="col-12 col-lg-6 d-flex justify-content-between align-items-center">
                                            <div className="prod-top-bar-title">
                                                <h4 className="font-weight-light">{title}</h4>
                                                <p>
                          <span>
                            <b>{!prod.data.data ? "0" : prod.data.from}</b>
                          </span>
                                                    -
                                                    <span>
                            <b>{!prod.data.data ? "0" : prod.data.to}</b>
                          </span>
                                                    {" of over "}
                                                    <span>
                            <b>{!prod.data.data ? "0" : prod.data.total}</b>
                          </span>
                                                    {" results for "}{" "}
                                                    <span className="text-capitalize">
                            <b>{title.toLowerCase()}</b>
                          </span>
                                                </p>
                                            </div>
                                            <div className="filter-toggle-mobile d-flex align-items-center"
                                                 onClick={chkToggleClick}>
                                                <FontAwesomeIcon icon={faFilter}/>
                                                <span className="ms-2">Filters</span>
                                            </div>
                                        </div>

                                        <div className="col-12 col-lg-6 d-flex justify-content-center">
                                            <div className="prod-top-bar-sort d-flex align-items-center">
                                                <label className="me-4">Sort By:</label>
                                                <select
                                                    class="form-select"
                                                    aria-label="Select Sort By Option"
                                                    style={{width: "auto"}}
                                                    onChange={(e) => {
                                                        setSearchParams({
                                                            ...currentParams,
                                                            [e.target.options[e.target.selectedIndex].dataset.name]: e.target.value,
                                                        });
                                                    }}
                                                >
                                                    <option value="">Best Match</option>
                                                    <option data-name="latest" value="asc">Latest Product</option>
                                                    <option data-name="sort" value="asc">Price Low To High</option>
                                                    <option data-name="sort" value="desc">Price High To Low</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <hr style={{marginTop: "0"}}/>

                                    <ActiveFilters
                                        filters={currentParams}
                                        setSearchParams={setSearchParams}
                                    />
                                    <CategoryProducts products={prod} dispatch={dispatch}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Store;
