import React, {useEffect} from "react";
import {useMutation} from "@tanstack/react-query";
import VaccinationService from "../../../../../services/vaccination.service";
import UploadFile from "../../../../custom_componets/UploadFile";
import {FileUpload} from "@mui/icons-material";
import {Button, CircularProgress} from "@mui/material";
import DatePickerReactHookForm from "../../../../date-picker/date-picker-react-hook-form";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {VaccinationDocumentValidationSchema} from "./vaccinationDocumentValidationSchema";
import {grey} from "@mui/material/colors";

const defaultValues = {
    vaccination_date: "",
    vaccination_document: "",
};

function Index() {
    const {mutate:uploadMutation, isLoading:isUploading, error} = useMutation({
        mutationFn: VaccinationService.uploadVaccinationDocument
    })

    const {control,setValue, clearErrors,  register,handleSubmit,  formState: {errors}} = useForm({resolver: yupResolver(VaccinationDocumentValidationSchema), defaultValues});

    const onSubmit = (data) =>  uploadMutation(data)

    /*
    * creating a field on mounting.
    * */
    useEffect(() => {
        control.register("vaccination_document")
    }, [])

    console.log(errors)

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="card p-4" style={{background: grey["200"], borderRadius: 8}}>
            <h1>Upload vaccination document</h1>
            <div className="d-grid gap-4">
                <DatePickerReactHookForm label="Vaccination Date"
                                         required={true}
                                         control={control}
                                         name={`vaccination_date`}
                                         error={errors?.vaccination_date && errors.vaccination_date.message}
                />
                <div className="grid gap-1">
                    <label>Document</label>
                    <UploadFile
                        type={"pdf"}
                        accept={"application/pdf,application/vnd.ms-excel"}
                        aspectRatio="3/1"
                        setImageInput={files => {
                            if(files[0]) {
                                setValue("vaccination_document", files[0])
                                clearErrors("vaccination_document")
                            }
                        }}
                        error={errors?.vaccination_document && errors.vaccination_document.message}
                    />
                </div>
                <div>
                    <Button variant="contained" startIcon={<FileUpload/>} fullWidth type='submit'>
                        { isUploading ?  <CircularProgress size={16} sx={{color: 'white'}}/> :
                                <span> Upload Medical History </span>
                        }
                    </Button>
                </div>
            </div>
        </form>
    )
}

export default Index
