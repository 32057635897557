import {Box} from '@mui/material'
import React, {useState} from 'react'

function DeactivateModal({deactivateFn, handleClose}) {
    const [reason, setReason] = useState()
    return (
        <Box
            width='100%'
            maxWidth='400px'
            position='absolute'
            top='50%'
            left='50%'
            sx={{transform: 'translate(-50%, -50%)'}}
        >
            <div className="card">
                <div className="card-header">
                    <div><span className="fs-6">Deactivate Reason</span></div>
                    {/* <div><span className="fw-light">Enter deactivate reason.</span></div> */}
                </div>
                <div className="card-body">
                    <div className='form-group mb-2'>
                        <label htmlFor="">Deactivate Reason</label>
                        <textarea value={reason} onChange={(e) => setReason(e.target.value)} className='form-control'/>
                        {/* <input type="text" value={reason} onChange={(e)=>setReason(e.target.value)} /> */}
                    </div>
                    <div className="form-group">
                        <button className='btn btn-primary' onClick={() => {
                            deactivateFn(reason);
                            handleClose()
                        }}>
                            Proceed
                        </button>
                    </div>
                </div>
            </div>
        </Box>
    )
}

export default DeactivateModal
