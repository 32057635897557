import {Box} from "@mui/material";
import React, {useState} from "react";
import {useContext} from "react";
import {useEffect} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Link} from "react-router-dom";
import swal from "sweetalert";
import {LoginStateContext} from "../../../contexts/LoginStateContext";
import NoSlot from "../../../images/Calendar.svg";
import axios from "../../api"
import {useChkLogin} from "../../../middlewares/LoginMiddleware";

const DateCalendar = (props) => {
    const [date, setDate] = useState(props.date);
    const handleChange = (date) => {
        setDate(date);
    };
    const booking = props.booking;
    //console.log();
    const filtered = booking.bookings.filter((slotDate) => date.toISOString().slice(0, 10) === slotDate.date)


    return (
        <div className="row flex-wrap date-picker g-0 h-100">
            <Box className={`col-lg-${props.colSize[0]} flex-fill`}
                 sx={{'& > div': {height: '100%', '& > div': {height: '100%'}}}}>
                <DatePicker
                    selected={date}
                    onChange={(date) => handleChange(date)}
                    inline
                    minDate={new Date()}
                />
            </Box>

            <div className={`col-lg-${props.colSize[1]} flex-fill time-slot`}>
                <div className="position-relative h-100" style={{minHeight: "220px"}}>
                    <div className="scroll-wrapper position-absolute top-0 start-0 w-100 h-100 overflow-scroll">
                        <div className="card border-0">
                            <div className="card-header text-center">
                                <h6>Available Time Slots</h6>
                                <h6 className="mb-0">Choose time to book appointment</h6>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="text-center">
                                            <h6 className="mb-0">In Video</h6>
                                        </div>
                                        <TimeSlots filtered={filtered} filteredType={"In Video"} booking={booking}/>
                                    </div>
                                    <div className="col-6">
                                        <div className="text-center">
                                            <h6 className="mb-0">In Person</h6>
                                        </div>
                                        <TimeSlots filtered={filtered} filteredType={"In Person"} booking={booking}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

DateCalendar.defaultProps = {
    colSize: [5, 7]
}

function TimeSlots({filtered, filteredType, booking}) {
    const {loading, data} = useBookedSlots(booking.id)
    const {token} = useChkLogin()
    // let token = localStorage.getItem('token')

    if (loading) {
        return
    }
    return (
        <>
            {filtered.length > 0 ? filtered.map((bookingDate) =>
                    bookingDate.slots.map((slot) =>
                        slot.service_type == filteredType
                        &&
                        <>
                            {token !== null ?
                                <>
                                    {
                                        data.includes(slot.id) ?
                                            <div className="btn btn-info" style={{margin: "1%"}} key={slot.id}
                                                 onClick={(e) => {
                                                     swal({
                                                         title: "Already Booked",
                                                         text: "You already booked at this time",
                                                         icon: "warning",
                                                     })
                                                 }}>{slot.slot}</div>
                                            :
                                            <Link
                                                to="/book-doctor"
                                                state={{
                                                    doctor_id: booking.id,
                                                    slug: booking.slug,
                                                    doctor_name: booking.user.name,
                                                    image: booking.image_path,
                                                    salutation: booking.salutation,
                                                    department: booking.departments.department,
                                                    experience: booking.year_practiced,
                                                    date: bookingDate.date,
                                                    slot_id: slot.id,
                                                    service_type: slot.service_type,
                                                    timeSlot: slot.slot,
                                                    fee: booking.fee
                                                }}
                                                className="btn btn-info"
                                                style={{margin: "1%"}}
                                                key={slot.id}
                                            >
                                                {slot.slot}
                                            </Link>
                                    }

                                </>
                                :
                                <Link
                                    to="/login"
                                    className="btn btn-info"
                                    style={{margin: "1%"}}
                                    key={slot.id}
                                >
                                    {slot.slot}
                                </Link>}
                        </>
                    )
                ) :
                <div className="text-center">
                    <img src={NoSlot} alt="" width='150px'/>
                </div>
            }
        </>
    )
}

function useBookedSlots(doctor_id) {
    const loginContext = useContext(LoginStateContext)
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!loginContext.state.loading) {
            if (loginContext.state.loggedIn) {
                axios.get("api/admin/booking-review", {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                    .then((res) => {
                        const filtered = res.data.filter((item) => item.doctor_id == doctor_id)
                        const slots = []
                        filtered.forEach((booking) => {
                            slots.push(booking.slot.id)
                        })
                        setData(slots)
                        setLoading(false)
                    })
                    .catch(err => {
                        setLoading(false)
                    })
            }
            setLoading(false)
            setData([])
        }

    }, [loginContext.state.loading])

    return {data, loading}
}

export default DateCalendar;
