import {Alert, AlertTitle} from '@mui/material'
import {useQuery} from '@tanstack/react-query'
import React from 'react'
import {useContext} from 'react'
import {createContext} from 'react'
import {LoginStateContext} from '../../../../contexts/LoginStateContext'
import axios from '../../../api'

const PathologyReportContext = createContext(null)

function PathologyReportProvider({children}) {
    const loginContext = useContext(LoginStateContext)
    const mypackage = useQuery(
        ['mypackage'],
        async () => (
            axios.get('/api/admin/userpackage', {headers: {'Authorization': `Bearer ${loginContext.state.token}`}}).then(res => res.data).catch(err => console.log())
        )
    )
    const packageid = mypackage.data?.package?.id
    console.log()
    // const pathologyReport = useQuery(
    //   ['screeningReport'],
    //   async()=>(
    //       axios.get(`/api/admin/medical-report/${packageid}`, {headers:{'Authorization':`Bearer ${loginContext.state.token}`}}).then(res=>res.data).catch(err=>console.log())
    //   )
    // )

    const pathologyReport = useQuery({
        queryKey: ['screeningReport', packageid],
        queryFn: async () => (
            axios.get(`/api/admin/medical-report`, {headers: {'Authorization': `Bearer ${loginContext.state.token}`}}).then(res => res.data).catch(err => console.log())
        ),
        // The query will not execute until the userId exists
        enabled: !!packageid,
    })
    if (mypackage.data?.package === null) {
        return (
            <Alert severity='error'>
                <AlertTitle>You haven't booked any packages.</AlertTitle>
            </Alert>
        )
    }
    if (pathologyReport.isLoading) {
        return <h2>Loading {packageid}...</h2>
    }
    if (pathologyReport.isError) {
        return (
            <Alert severity='error'>
                <AlertTitle>There was an error</AlertTitle>
            </Alert>
        )
    }
    console.log(pathologyReport.data)
    const screeningReport = pathologyReport.data.reports
    if (screeningReport.length === 0) {
        return <Alert severity='error'>
            <AlertTitle>No Screening Report Added Yet.</AlertTitle>
        </Alert>
    }
    return (
        <PathologyReportContext.Provider value={{screeningReport}}>
            <div>
                {children}
            </div>
        </PathologyReportContext.Provider>
    )
}

export function useScreeningReport() {
    let {screeningReport} = useContext(PathologyReportContext)

    return {screeningReport}
}

export default PathologyReportProvider
