import http from "../utils/http/http.utils";
import setFormData from "../utils/http/formdata.utils";
import {filterFormData} from "../utils/common/common.utils";

class SchoolPaymentVerificationService {

    /*
    * payment verification method for school
    * */
    static submitPayment(data) {
        const formData = setFormData(data)

        console.log('hello')
        try {
            return http().post('api/company-school-profile/payment', filterFormData(formData))
        } catch (e) {
            console.log(e)
        }
    }

    /*
    * fetch information related to payment
    * */
    static fetchPaymentInformation() {
        try {
            return http().get('api/admin/userpackage/calculate-initial-payment')
        } catch (e) {
            console.log(e)
        }
    }


    /*
   * fetch payment history details
   * */
    static fetchPaymentHistory() {
        try {
            return http().get('/payment-history')
        } catch (e) {
            console.log(e)
        }
    }


}

export default SchoolPaymentVerificationService
