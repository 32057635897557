// import axios from './api'
import React from 'react'
import {AutoCompleteInput} from './geocode/AutoComplete';
// import Autocomplete from "react-google-autocomplete"
// import Slider from 'react-slick';

function loadAsyncScript(src) {
    return new Promise(resolve => {
        const script = document.createElement("script");
        Object.assign(script, {
            type: "text/javascript",
            async: true,
            src
        })
        script.addEventListener("load", () => resolve(script))
        document.head.appendChild(script)
    })
}

function AddressAutoComp({getLocation, inputValue, onChange}) {
    const searchInput = React.useRef(null)


    const [locationData, setLocationData] = React.useState(null)
    const [locationStr, setLocationStr] = React.useState('')

    // console.log())

    const googleMap = "https://maps.googleapis.com/maps/api/place/autocomplete/json"

    const mapKey = process.env.REACT_APP_GoogleMapKey
    const initMap = (mapUrl) => {
        if (window.google) {
            return Promise.resolve()
        }
        const src = `${mapUrl}?key=${mapKey}&libraries=places`
        return loadAsyncScript(src)
    }


    React.useEffect(() => {
        initMap(googleMap).then(() => {
            if (!searchInput.current) return;

            const autocomplete = new window.google.maps.places.Autocomplete(searchInput.current);
            autocomplete.setComponentRestrictions({'country': ['NP']})
            autocomplete.setFields(["address_component", "geometry"])


            autocomplete.addListener("place_changed", () => {
                const location = autocomplete.getPlace();
                setLocationStr(searchInput.current.value)
                setLocationData(location)
                console.log()
            })
        })
    }, [])

    React.useEffect(() => {
        if (locationData != null && getLocation != null) {
            getLocation({
                latitude: locationData.geometry.location.lat(),
                longitude: locationData.geometry.location.lng(),
                locationStr: locationStr
            })
        }
    }, [locationData])

    function handleChange(e) {
        onChange(searchInput.current.value)
    }

    return (

        <input
            ref={searchInput}
            type="text"
            className="form-control"
            placeholder="Location"
            value={inputValue}
            onChange={handleChange}
        />


    )
}

// function AddressAutoComp({ getLocation, inputValue, onChange }) {
//     return (
//         <AutoCompleteInput>
//             {
//                 (handleChange, value, inputRef) => (
//                     <input
//                         ref={searchInput}
//                         type="text"
//                         className="form-control"
//                         placeholder="Location"
//                         value={inputValue}
//                         onChange={handleChange}
//                     />
//                     // <input type="text" ref={inputRef} onChange={handleChange} placeholder='Search Place' />
//                 )
//             }
//         </AutoCompleteInput>
//     )
// }

export default AddressAutoComp
