import {faFilter} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React from 'react'
import ServiceItemCard from './components/ServiceItemCard'
import axios from '../../api';
import "./ServiceFilter.scss"

function ServiceFilter() {
    const [labServices, setLabServices] = React.useState([]);

    React.useEffect(() => {
        axios.get("api/health-service")
            .then((res) => {
                setLabServices(res.data)
            })
    }, [])

    return (
        <div className='service-filter-page'>
            <div className="container py-5">
                <div className="row g-4">
                    {/* <div className="col-3">
                        <div className="filter-bar h-100">
                            <div className="filter-titlte mb-4">
                                <FontAwesomeIcon icon={faFilter} className="me-3" /><b>Filters</b>
                            </div>
                            <div className="filter-section">
                                <div className="filter-title">
                                    <b>Categories</b>
                                </div>
                                <div className="form-check filter-items">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        <span className="filter-item-name">Organ Function Tests</span>
                                    </label>
                                </div>
                                <div className="form-check filter-items">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        <span className="filter-item-name">Screening Tests</span>
                                    </label>
                                </div>
                                <div className="form-check filter-items">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        <span className="filter-item-name">Infectious Disease Tests</span>
                                    </label>
                                </div>
                                <div className="form-check filter-items">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        <span className="filter-item-name">Tumor and Cancer Marker Tests</span>
                                    </label>
                                </div>
                                <div className="form-check filter-items">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        <span className="filter-item-name">Nutrient and Vitamin level Tests</span>
                                    </label>
                                </div>
                                <div className="form-check filter-items">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        <span className="filter-item-name">Hormone level Tests</span>
                                    </label>
                                </div>
                                <div className="form-check filter-items">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        <span className="filter-item-name">Cholestrol level Tests</span>
                                    </label>
                                </div>
                            </div>

                            <div className="filter-section">
                                <div className="filter-title">
                                    <b>Price Range</b>
                                </div>
                                <div className="filter-items">
                                    <div className="min d-flex align-items-center">
                                        <span className='col-3'>Min</span>
                                        <div className="col-4 form-group">
                                            <input type="text" className='form-control' />
                                        </div>
                                    </div>
                                </div>
                                <div className="filter-items">
                                    <div className="min d-flex align-items-center">
                                        <span className='col-3'>Max</span>
                                        <div className="col-4 form-group">
                                            <input type="text" className='form-control' />
                                        </div>
                                    </div>
                                </div>
                                <div className="filter-items d-flex align-items-center mt-3">
                                    <div className="btn btn-outline-danger me-3">
                                        Reset
                                    </div>
                                    <div className="btn btn-primary">
                                        Apply
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="col-12">
                        <div className="service-results-container row align-items-center mb-4">
                            <div className="results-terms col-md-7">
                                <h5><b>Pathological Tests</b></h5>
                                <p>
                                    1-10 of <b>20 Tests</b> found for <b>Pathological Tests</b>
                                </p>
                            </div>
                            <div className="results-sort col-md-3 ms-auto">
                                <select className="form-select" aria-label="Sort Tests">
                                    <option value="1">Sort By Relevence</option>
                                    <option value="2">Sort By Date</option>
                                    {/* <option value="3">Three</option> */}
                                </select>
                            </div>
                        </div>
                        <div className="row g-4">
                            {
                                labServices.map((services) => {
                                    return (
                                        <div className="col-3">
                                            <ServiceItemCard labservice={services}/>
                                        </div>
                                    )
                                })
                            }
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceFilter
