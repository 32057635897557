import {Avatar, Box, FormControlLabel, Grid, Stack, Typography} from '@mui/material'
import React, {useContext} from 'react'
import {LoginStateContext} from '../../../../contexts/LoginStateContext'
import {deepPurple} from '@mui/material/colors'
import {Edit} from '@mui/icons-material'
import IOSSwitch from '../../../custom_componets/IOSSwitch'
import MyProfile from './myprofile/MyProfile'
import {Outlet} from 'react-router-dom'
import AccountSide from './AccountSide'

function AccountSetting() {
    const loginContext = useContext(LoginStateContext)
    const userData = loginContext.state.data
    return (
        <div className="container">
            <div className="card">
                <div className="row">
                    <div className="col-3 border border-bottom-0 border-top-0 border-start-0 border-end-1">
                        {/* <div className="p-4">
                            <Box className='mb-1 p-2' bgcolor='primary.main' width='fit-content' borderRadius={10} color='white' >
                                <span className='fw-light fs-6'>My Profile</span>
                            </Box>
                            <div className='mb-1 p-2'>
                                <span className='fw-light fs-6'>Security Setting</span>
                            </div>
                            <div className="mb-1 p-2">
                                <span className="fw-light fs-6">Payment Methods</span>
                            </div>
                            <div className="mb-1 p-2">
                                <span className="fw-light fs-6">Notification Setting</span>
                            </div>
                            <div className="mb-1 p-2">
                                <span className="fw-light fs-6">Billing/Delivery Address</span>
                            </div>
                        </div> */}
                        <AccountSide/>
                    </div>

                    <div className="col-9">
                        <Outlet/>
                    </div>

                    {/* <MyProfile /> */}
                </div>
            </div>
        </div>
    )
}

export default AccountSetting
