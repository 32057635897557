import React from "react";
import StoreHeader from "../stores/layouts/StoreHeader";
import StoreNavVendor from "../stores/StoreNavVendor";
import {StoreContext} from "../../contexts/StoreContext";
import {Outlet, useLocation} from "react-router-dom";
import BreadCrumbs from "../BreadCrumbs";
import Footer from "../Footer";

const StoreLayout = () => {
    const [cartCount, setCartCount] = React.useState(null);
    const [wishlistCount, setWishlistCount] = React.useState(null);

    const [breadLinks, setBreadLinks] = React.useState({current_page: ""});

    console.log();

    const setBreadCrumbs = (crumbs) => {
        let getCrumb = {};

        crumbs.map((crumb) => {
            getCrumb[crumb[0]] = crumb[1];
        });
        setBreadLinks(getCrumb);
    };

    return (
        <StoreContext.Provider
            value={{
                cartCount,
                wishlistCount,
                breadLinks,
                setCartCount,
                setWishlistCount,
                setBreadCrumbs,
            }}
        >
            <StoreHeader/>
            <div className="container-lg">
                <StoreNavVendor/>
                {/* <BreadCrumbs links={breadLinks} /> */}
            </div>


            <Outlet/>
        </StoreContext.Provider>
    );
};

export default StoreLayout;
