import React, {useEffect, useState} from 'react'
import {useContext} from 'react'
import {useSearchParams} from 'react-router-dom'
import {ProductFilterContext} from './ProductFilterContext'
import axios from '../../../api'

function ProductFIlterProvider({children}) {
    const [filters, setFilters] = useState({})
    const [searchParams, setSearchParams] = useSearchParams()
    const [selectedCat, setSelectedCat] = useState({})
    const [selectedBrands, setSelectedBrands] = useState({})
    const productData = useFilteredProducts(filters)

    useEffect(() => {
        const objectParams = Object.fromEntries(searchParams)
        console.log()
        if (objectParams.pg) {
            delete objectParams.pg
        }
        setFilters(prev => ({
            ...prev,
            ...objectParams
        }))
    }, [searchParams])

    return (
        <ProductFilterContext.Provider value={{
            filters: filters,
            setFilters: setFilters,
            selectedCat: selectedCat,
            setSelectedCat: setSelectedCat,
            selectedBrands: selectedBrands,
            setSelectedBrands: setSelectedBrands,
            productData: productData.data,
            productLoading: productData.isLoading
        }}
        >
            {children}
        </ProductFilterContext.Provider>
    )
}

export function useFilteredProducts(filters) {
    const [isError, setError] = useState(false)
    const [data, setData] = useState()
    const [isLoading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        axios.get("/api/products", {params: filters}).then(res => {
            setData(res.data)
        }).catch(err => {
            setError(true)
        }).finally(() => setLoading(false))
    }, [filters])

    return {data, isLoading, isError}
}

export function useFetchedProducts() {
    const {productData, setProductData, productLoading} = useContext(ProductFilterContext)
    const [searchParams, setSearchParams] = useSearchParams()
    const products = productData?.data
    const currentPage = productData?.current_page
    const totalItems = productData?.total
    const itemsPerPage = productData?.per_page

    const changePage = (page) => {
        const objectParams = Object.fromEntries(searchParams)
        setSearchParams({...objectParams, page: page})
    }

    const fetchProductData = () => {

    }

    return {products, totalItems, currentPage, itemsPerPage, changePage, productLoading}
}

export default ProductFIlterProvider
