import {ReadMoreOutlined} from '@mui/icons-material'
import {Box, Button, Grid, Stack, Typography, useMediaQuery} from '@mui/material'
import {useQuery} from '@tanstack/react-query'
import React, {useContext} from 'react'
import {LoginStateContext} from '../../../../../contexts/LoginStateContext'
import SliderStyle2 from '../../components/SliderStyle2'
import {GDVendorContext} from '../GDVendorContext'
import axios from '../../../../api'

function PromotionSlider() {
    const {vendor} = useContext(GDVendorContext)
    const {data, isLoading, isError} = useQuery(
        ['vendor-slider', vendor.id],
        async () => {
            return axios.get('api/vendor-slider?vendor_id=' + vendor.id)
                .then(res => res.data)
        }
    )
    if (isLoading || isError) {
        return
    }
    if (data.length === 0) {
        return
    }
    if (vendor.is_exculsive !== 2) {
        return
    }
    return (
        <SliderStyle2>
            {
                data.map((item, idx) => (
                    <div key={idx}>
                        <PromotionItem data={item}/>
                    </div>
                ))
            }
        </SliderStyle2>
    )
}

function PromotionItem({data}) {
    // const matches = useMediaQuery('(max-width:760px)');
    // const remove= {
    //     display: matches ? 'none' : 'block'
    // }
    return (
        <Box sx={{textAlign: 'start', overflow: 'hidden', boxShadow: 3}} bgcolor='#F8F8FF' borderRadius={'10px'}>
            <Grid container height={'100%'}>
                <Grid item xs={12}>
                    <Box width='100%' height="100%">
                        <img src={data.image_path} alt="" style={{aspectRatio: 3 / 1, width: '100%'}}/>
                    </Box>
                </Grid>

                {/* <Grid item md={6}>
                    <Stack justifyContent='center' sx={{ width: '100%', height: '100%' }} px={4} >
                        <Typography variant='h5' fontWeight={450} lineHeight={1}>
                            {data.banner_title}
                        </Typography>
                        <Typography variant='h6' lineHeight={1} fontWeight={400} sx={{ color: 'text.secondary', mt: 1 }} >
                            {data.banner_body}
                        </Typography>
                        <Button size='large' variant="contained" sx={{ alignSelf: 'start', mt: 1 }} endIcon={<ReadMoreOutlined />}>
                            View More
                        </Button>
                    </Stack>
                </Grid> */}
            </Grid>
        </Box>
    )
}

export default PromotionSlider
