import StoreHeader from "../../stores/layouts/StoreHeader";
import StoreNavVendor from "../../stores/StoreNavVendor";
import Orders from "../../stores/Orders";
import OrderNav from "../../stores/OrderNav";
import {StoreContext} from "../../../contexts/StoreContext"
import {useEffect, useContext} from 'react'

const OrderPage = () => {
    let storeContext = useContext(StoreContext)
    useEffect(() => {
        // storeContext.setBreadCrumbs([
        //   ['/', "Home"], ["/store", "Store"], ['current_page', "Orders"]
        // ])
    }, [])
    return (
        <>
            <div>
                {/* <StoreNavVendor /> */}
                <h3 className="mt-3">My Orders</h3>
                <OrderNav/>
            </div>
        </>
    );
};

export default OrderPage;
