import React, {createContext, useCallback, useContext, useState} from 'react'
import {useEffect} from 'react'

const DashboardLayoutContext = createContext(null)

function DashboardLayoutProvider({children}) {
    const [breadCrumbs, setBreadCrumbs] = useState(null)
    return (
        <DashboardLayoutContext.Provider value={{
            breadCrumbs, setBreadCrumbs
        }}>
            {children}
        </DashboardLayoutContext.Provider>
    )
}

function useBreadCrumbs(breadLinks) {
    const {breadCrumbs, setBreadCrumbs} = useContext(DashboardLayoutContext)

    useEffect(() => {
        setBreadCrumbs(breadLinks)
    }, [])

    return breadCrumbs
}

export {DashboardLayoutProvider, useBreadCrumbs}
