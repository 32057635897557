import {Box} from '@mui/material'
import React from 'react'
import ChatHeader from './ChatHeader'
import ChatBody from './ChatBody'

function ChatOpen({closeWindow, openChatModal}) {
    return (
        <Box
            sx={{
                maxWidth: '400px',
                width: '100vW',
                height: '500px',
                border: 1,
                borderColor: 'divider',
                borderRadius: 2,
                overflow: 'hidden',
                boxShadow: 2
            }}
            display='flex'
            flexDirection="column"
        >
            <ChatHeader closeWindow={closeWindow}/>
            <ChatBody openChatModal={openChatModal}/>
        </Box>
    )
}

export default ChatOpen
