import {ArticleOutlined, WorkOffOutlined} from '@mui/icons-material'
import {Box, Typography} from '@mui/material'
import React from 'react'

function NoData({title, description}) {
    return (
        <Box className="container">
            <Box py={10} display='flex' flexDirection={'column'} alignItems={'center'} gap={1} justifyContent='center'
                 textAlign={'center'}>
                <Box textAlign='center'>
                    <Typography variant='h2' textAlign={'center'} fontWeight='400'>
                        <ArticleOutlined sx={{fontSize: '70px'}}/>
                    </Typography>
                </Box>
                <Box>
                    <Typography variant='h4'>{title}</Typography>
                    <Typography variant='subtitle1' color='text.secondary'>{description}</Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default NoData
