import {faCheck, faXmark} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Box, InputLabel, NativeSelect, Slide} from '@mui/material'
import {useQuery} from '@tanstack/react-query'
import axios from '../../api'
import React, {useEffect, useRef} from 'react'
import {useState} from 'react'
import {Link} from 'react-router-dom'

function ComparePg() {
    const [packageType, setPackageType] = useState(1)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
    }, [packageType])

    return (
        <div className="pg-comp-comp">
            <h1 className='text-center m-0 p-0 mt-5'>Ghargharma doctor packages</h1>
            <div className="text-center m-0"><span className='fs-5 fw-lighter text-muted'>Simple Pricing, No Hidden Fees, Advanced Features</span>
            </div>
            <div className="mt-4">
                <div
                    className='d-flex align-items-center justify-content-center shadow m-auto rounded-3 overflow-hidden'
                    style={{width: 'fit-content'}}>
                    <div className={`px-4 py-2 ${packageType == 1 ? 'bg-primary text-white' : ''}`} role='button'
                         onClick={() => setPackageType(1)}>
                        <span className='fs-5'>Family Packages</span>
                    </div>
                    <div className={`px-4 py-2 ${packageType == 2 ? 'bg-primary text-white' : ''}`} role='button'
                         onClick={() => setPackageType(2)}>
                        <span className='fs-5'>Corporate Packages</span>
                    </div>
                </div>
            </div>
            <div className="mt-4 mx-auto text-center">
                <small className='text-muted'>Select Packages from dropdown to compare.</small>
            </div>
            <div>
                <div className="py-5 pg-compare-chart justify-content-center">
                    <div>
                        {/* <ComparePgHeader type={packageType}/> */}
                        <ComparePgHead type={packageType}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

function ComparePgHead({type}) {
    const {data, isLoading, refetch} = useQuery(
        ['packages'],
        async () => (
            axios.get("api/package").then(res => res.data)
        )
    )
    const compareDiv = useRef(null)

    const [allPackages, setAllPackages] = useState([])
    const [selectedPackages, setSelectedPackages] = useState([])
    const [showStickyHead, setShowStickyHead] = useState(false)

    console.log("Sticy head value is", showStickyHead)

    useEffect(() => {
        if (!isLoading) {
            let pkgs = []
            if (type === 1) {
                pkgs = data.filter(item => item.type === 1 || item.type === 2)
            } else {
                pkgs = data.filter(item => item.type === 3)
            }
            setAllPackages(pkgs)
            setSelectedPackages([pkgs[0], pkgs[1]])
        }
    }, [isLoading, data, type])

    useEffect(() => {
        function scrollFn(e) {
            // console.log("Scrolling", compareDiv.current.getBoundingClientRect())
            let rect = compareDiv.current.getBoundingClientRect()
            if (rect.y < 18) {
                // console.log("Sticky head true")
                setShowStickyHead(true)
            } else {
                // console.log("Sticky head false")
                setShowStickyHead(false)
            }
        }

        window.addEventListener('scroll', scrollFn)
        return () => {
            window.removeEventListener('scroll', scrollFn)
        }
    }, [])

    function handleChange(e) {
        let newPkg = [...selectedPackages]
        // console.log(newPkg, Number(e.target.name), e.target.value)
        newPkg[Number(e.target.name)] = allPackages.filter(item => item.id === Number(e.target.value))[0]
        // console.log('New Package', newPkg)
        setSelectedPackages(newPkg)
    }

    if (isLoading) {
        return <h5>Loading....</h5>
    }

    return (
        <>
            {
                showStickyHead && (
                    <Slide direction='down' in={true} mountOnEnter unmounonExit>
                        <Box
                            position='fixed'
                            width='100%'
                            top={64}
                            left={0}
                            bgcolor={'#FAFAFA'}
                            boxShadow={1}
                            zIndex={999}
                        >
                            <div className="container">
                                <div className="row g-0">
                                    <div className="col-4">

                                    </div>
                                    {
                                        selectedPackages.map((item, idx) => (
                                            <div className="col-4">
                                                <div
                                                    className={`w-100 border-start border-top ${idx === selectedPackages.length - 1 && 'border-right'} bg-white`}>
                                                    <div className="py-4 d-flex flex-column align-items-center">
                                                        <div className='mb-2'>
                                                            <span className='fs-4'>{item.package_type}</span>
                                                        </div>
                                                        <div className="btn btn-outline-primary">
                                                            Get Started
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                    {/* <div className="col-4">
                                        <div className='w-100 border-start border-top bg-white'>
                                            <div className="p-3 d-flex flex-column align-items-center">
                                                <div className='mb-2'>
                                                    <span className='fs-5'>Silver Membership</span>
                                                </div>
                                                <div className="btn btn-outline-primary">
                                                    Get Started
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className='w-100 border-start border-top border-end bg-white'>
                                            <div className="p-3 d-flex flex-column align-items-center">
                                                <div className='mb-2'>
                                                    <span className='fs-5'>VIP Membership</span>
                                                </div>
                                                <div className="btn btn-outline-primary">
                                                    Get Started
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </Box>
                    </Slide>
                )
            }
            <div className='container' ref={compareDiv}>
                <ComparePgHeadUi selectedPackages={selectedPackages} allPackages={allPackages}
                                 handleChange={handleChange}/>
                <ComparePgLists selectedPgs={selectedPackages}/>
            </div>
        </>
    )
}

function ComparePgHeadUi({selectedPackages, allPackages, handleChange}) {
    console.log('Selected Packages', selectedPackages)
    return (
        <>
            <div className="compare-chart-head align-items-center">
                <div className="row bg-light py-2">
                    <div className="col-4">
                        <span className='text-primary'>Data</span>
                    </div>
                    {
                        selectedPackages.map((item, idx) => (
                            <div className="col-4">
                                <NativeSelect
                                    defaultValue={item.id}
                                    inputProps={{
                                        name: idx
                                    }}
                                    fullWidth
                                    className='text-center'
                                    onChange={handleChange}
                                    sx={{textAlign: 'center'}}
                                >
                                    {
                                        allPackages.map((pkg, idx) => (
                                            <>
                                                {
                                                    (!selectedPackages.includes(pkg) || pkg.id === item.id)
                                                    &&
                                                    <option key={idx} value={pkg.id}>{pkg.package_type}</option>
                                                }
                                            </>
                                        ))
                                    }
                                </NativeSelect>
                            </div>
                        ))
                    }
                </div>
            </div>
            {/* <ComparePgLists selectedPgs={selectPackages} /> */}
        </>
    )
}

// function ComparePgHeader({type}) {
//     const [selectPackages, setSelectPackages] = useState([])
//     const [index, setIndex] = useState(0)
//     const { data, isLoading, refetch } = useQuery(
//         ['packages'],
//         async () => {
//             return axios.get("api/package")
//                 .then(res => {
//                     let data = res.data.filter((item) => item.type === type)
//                     setSelectPackages([data[0], data[1]])
//                     return data;
//                 }).catch(err => err)
//         }
//     )

//     useEffect(()=>{
//         refetch()
//     }, [type])

//     function changeSelPackage(e, index) {
//         const selPg = data.filter((val) => {
//             return val.id == e.target.value
//         })[0]

//         selectPackages[index] = selPg
//         setSelectPackages((prev) => selectPackages)
//         setIndex((prev) => prev + 1)
//     }

//     if (isLoading) {
//         return <h5>Loading....</h5>
//     }

//     return (
//         <>
//             <div className="compare-chart-head align-items-center">
//                 <div className="row bg-light py-2">
//                     <div className="col-4">
//                         <span className='text-primary'>Data</span>
//                     </div>
//                     <div className="col-4">
//                         <NativeSelect
//                             defaultValue={selectPackages[0].id}
//                             inputProps={{
//                                 name: "age",
//                                 id: "uncontrolled-native",
//                             }}
//                             onChange={(e) => { changeSelPackage(e, 0) }}
//                             fullWidth
//                             className="text-center"
//                         >
//                             {
//                                 data.map((pg) => {
//                                     return (
//                                         <>
//                                             {
//                                                 (!selectPackages.includes(pg) || pg.id === selectPackages[0].id) &&
//                                                 <option value={pg.id}>{pg.package_type}</option>
//                                             }
//                                         </>
//                                     )
//                                 })
//                             }
//                         </NativeSelect>
//                     </div>
//                     <div className="col-4">
//                         <NativeSelect
//                             defaultValue={selectPackages[1].id}
//                             inputProps={{
//                                 name: "age",
//                                 id: "uncontrolled-native",
//                             }}
//                             onChange={(e) => { changeSelPackage(e, 1) }}
//                             className="text-center"
//                             fullWidth
//                         >
//                             {
//                                 data.map((pg) => {
//                                     return (
//                                         <>
//                                             {
//                                                 (!selectPackages.includes(pg) || pg.id === selectPackages[1].id) &&
//                                                 <option value={pg.id} key={pg.id}>{pg.package_type}</option>
//                                             }
//                                         </>
//                                     )
//                                 })
//                             }
//                         </NativeSelect>
//                     </div>
//                 </div>
//             </div>
//             <ComparePgLists selectedPgs={selectPackages} />
//         </>
//     )
// }

function ComparePgLists({selectedPgs}) {

    return (
        <Box className="compare-chart-body"
             sx={{'& .compare-chart-item > div:not(:first-child)': {textAlign: 'center'}}}>
            <div className="compare-chart-item border-bottom row py-3">
                <div className="col-4">
                    <span className='fw-bolder'>Number of Home Visit</span>
                </div>
                {
                    selectedPgs.map((selPg) => {
                        return (
                            <div className="col-4">
                                <span>{selPg.visits} Home Visit</span>
                            </div>
                        )
                    })
                }
            </div>
            <div className="compare-chart-item border-bottom row py-3">
                <div className="col-4">
                    <span className='fw-bolder'>Online Consultation</span>
                </div>
                {
                    selectedPgs.map((selPg) => {
                        return (
                            <div className="col-4">
                                <span>{selPg.online_consultation}</span>
                            </div>
                        )
                    })
                }
            </div>
            <div className="compare-chart-item border-bottom row py-3">
                <div className="col-4">
                    <span className='fw-bolder'>Number of Lab Tests</span>
                </div>
                {
                    selectedPgs.map((selPg) => {
                        return (
                            <div className="col-4">
                                <span>{selPg.tests || 0}</span>
                            </div>
                        )
                    })
                }
            </div>
            <div className="compare-chart-item border-bottom row py-3">
                <div className="col-4">
                    <span className='fw-bolder'>Registration Fee</span>
                </div>
                {
                    selectedPgs.map((selPg) => {
                        return (
                            <div className="col-4">
                                <span>NPR. {selPg.registration_fee}</span>
                            </div>
                        )
                    })
                }
            </div>
            <div className="compare-chart-item border-bottom row py-3">
                <div className="col-4">
                    <span className='fw-bolder'>Monthly Fee</span>
                </div>
                {
                    selectedPgs.map((selPg) => {
                        return (
                            <div className="col-4">
                                <span>NPR. {selPg.monthly_fee} per Month</span>
                            </div>
                        )
                    })
                }
            </div>
            <div className="compare-chart-item border-bottom row py-3">
                <div className="col-4">
                    <span className='fw-bolder'>Screening</span>
                </div>

                {
                    selectedPgs.map((selPg) => (
                        selPg.screening === 1 ? (
                            <div className="col-4">
                                <span>
                                    <FontAwesomeIcon icon={faCheck} className="text-success" size='lg'/>
                                </span>
                            </div>

                        ) : (
                            <div className="col-4">
                                <span>
                                    <FontAwesomeIcon icon={faXmark} className="text-danger" size='lg'/>
                                </span>
                            </div>
                        )
                    ))
                }

            </div>
            <div className="compare-chart-item border-bottom row py-3">
                <div className="col-4">
                    <span className='fw-bolder'>Checkup</span>
                </div>

                {
                    selectedPgs.map((selPg) => (
                        selPg.checkup === 1 ? (
                            <div className="col-4">
                                <span>
                                    <FontAwesomeIcon icon={faCheck} className="text-success" size='lg'/>
                                </span>
                            </div>

                        ) : (
                            <div className="col-4">
                                <span>
                                    <FontAwesomeIcon icon={faXmark} className="text-danger" size='lg'/>
                                </span>
                            </div>
                        )
                    ))
                }
            </div>
            <div className="compare-chart-item border-bottom row py-3">
                <div className="col-4">
                    <span className='fw-bolder'>Ambulance</span>
                </div>
                {
                    selectedPgs.map((selPg) => (
                        selPg.ambulance === 1 ? (
                            <div className="col-4">
                                <span>
                                    <FontAwesomeIcon icon={faCheck} className="text-success" size='lg'/>
                                </span>
                            </div>

                        ) : (
                            <div className="col-4">
                                <span>
                                    <FontAwesomeIcon icon={faXmark} className="text-danger" size='lg'/>
                                </span>
                            </div>
                        )
                    ))
                }
            </div>
            <div className="compare-chart-item border-bottom row py-3">
                <div className="col-4">
                    <span className='fw-bolder'>Insurance</span>
                </div>
                {
                    selectedPgs.map((selPg) => (
                        selPg.insurance === 1 ? (
                            <div className="col-4">
                                <span>
                                    <FontAwesomeIcon icon={faCheck} className="text-success" size='lg'/>
                                </span>
                            </div>

                        ) : (
                            <div className="col-4">
                                <span>
                                    <FontAwesomeIcon icon={faXmark} className="text-danger" size='lg'/>
                                </span>
                            </div>
                        )
                    ))
                }
            </div>
            <div className="compare-chart-item border-bottom row py-3">
                <div className="col-4">
                    <span className='fw-bolder'>Insurance Amount</span>
                </div>
                {
                    selectedPgs.map((selPg) => (
                        <div className="col-4">
                            <span>NPR. {selPg.insurance_amount}</span>
                        </div>
                    ))
                }
            </div>
            <div className="compare-chart-item border-bottom row py-3">
                <div className="col-4">
                    <span className='fw-bolder'>Schedule Flexibility</span>
                </div>
                {
                    selectedPgs.map((selPg) => (
                        selPg.schedule_flexibility === 1 ? (
                            <div className="col-4">
                                <span>
                                    <FontAwesomeIcon icon={faCheck} className="text-success" size='lg'/>
                                </span>
                            </div>

                        ) : (
                            <div className="col-4">
                                <span>
                                    <FontAwesomeIcon icon={faXmark} className="text-danger" size='lg'/>
                                </span>
                            </div>
                        )
                    ))
                }
            </div>
            {/* <div className="compare-chart-item border-bottom row py-3">
                <div className="col-4">
                    <span className='fw-bolder'>Vaccination Types</span>
                </div>
                <div className="col-4">
                    <span><FontAwesomeIcon icon={faXmark} className="text-primary" size='lg' /></span>
                </div>
                <div className="col-4">
                    <span>2 Types </span>
                </div>
            </div> */}
            <div className="compare-chart-item border-bottom row py-3">
                <div className="col-4">
                </div>
                {
                    selectedPgs.map((selPg) => {
                        return (
                            <div className="col-4">
                                <Link to={`/single-package/${selPg.slug}`} className='btn btn-outline-primary'>Buy
                                    Package</Link>
                            </div>
                        )
                    })
                }
            </div>
        </Box>
    )
}

export default ComparePg
