import React, {useState} from 'react'
import {useChkLogin} from '../../../middlewares/LoginMiddleware'
import Loader from '../Loader'
import {Backdrop, Box, CircularProgress} from '@mui/material'
import UploadFile from '../../custom_componets/UploadFile'
import {Autocomplete, GoogleMap, Marker} from '@react-google-maps/api'
import PhoneInput from 'react-phone-input-2'
import {InputField, NepaliDateInput} from '../../custom_componets/FormComponents'
import axios from '../../api'
import swal from 'sweetalert'
import {useNavigate} from 'react-router-dom'

function CompanyProfileForm({
                                state = {
                                    owner_name: '',
                                    company_name: '',
                                    company_address: '',
                                    company_start_date: '',
                                    contact_number: '',
                                    pan_number: '',
                                    description: '',
                                    company_image: '',
                                    paper_work_pdf: '',
                                    latitude: '',
                                    longitude: '',
                                    types: 'corporate'
                                }, apiLink = `/api/company-school-profile`, navigateTo = '/packages'
                            }) {
    console.log(state)
    const {token, isLoading, data} = useChkLogin()
    const navigate = useNavigate()
    const [submitting, setSubmitting] = useState(false)
    const [inputs, setInputs] = useState(state)
    const [latLng, setLatLng] = useState()

    function handleChange(e) {
        setInputs(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    function handleLatLng(lat, lng) {
        setInputs(prev => ({...prev, latitude: lat, longitude: lng}))
        setLatLng([lat, lng])
    }

    if (isLoading) {
        return (
            <Box p={10} display='flex' justifyContent='center'>
                <Loader/>
            </Box>
        )
    }

    function handleFile(inputFile, name) {
        var reader = new FileReader();
        reader.readAsDataURL(inputFile);
        console.log()
        var ext = inputFile.type.split('/')[1]
        reader.onload = () => {
            const strArr = reader.result.split('base64,')
            const base64Str = `data:image/${ext};base64,${strArr[1]}`
            setInputs({...inputs, [name]: base64Str})
        }
        reader.onerror = error => {
            console.log();
        }
    }

    async function handleSubmit(e) {
        e.preventDefault()
        setSubmitting(true)
        let result = await axios.get(`https://api.geoapify.com/v1/geocode/reverse?apiKey=68725a46c50b4690bf4c6d41efde7963&lat=${latLng[0]}&lon=${latLng[1]}`)
        // .then(result => {
        //     let location = result.data.features[0].properties
        //     console.log(result.data)
        //     setAddress(`${location.address_line1}, ${location.city}`)
        // })
        let location = result.data.features[0].properties
        inputs.company_address = `${location.address_line1} ${location.city}`
        axios.post(apiLink, inputs, {headers: {'Authorization': `Bearer ${token}`}})
            .then(res => {
                console.log(res)
                swal(
                    {
                        title: "Success",
                        text: 'Your form is submitted successfully',
                        icon: "success",
                        closeOnClickOutside: false
                    }
                ).then((confirm) => {
                    if (confirm) {
                        navigate(navigateTo)
                    }
                })
            })
            .catch(err => console.log(err))
            .finally(() => setSubmitting(false))
    }

    return (
        <div className="container my-5">
            <form className='form-horizontal' onSubmit={handleSubmit}>
                <h3 className="m-0 p-0 mb-2">Company Information</h3>
                <div className="card">
                    <div className="card-body">
                        <div className="row g-3">
                            <div className="col-md-6">
                                <InputField
                                    label="Owner name"
                                    name="owner_name"
                                    value={inputs.owner_name}
                                    // error={errors?.first_name}
                                    // errorMsg={errors?.first_name ? errors.first_name.message : null}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-md-6">
                                <InputField
                                    label="Company name"
                                    name="company_name"
                                    value={inputs.company_name}
                                    onChange={handleChange}
                                    // value={inputs.middle_name}
                                    // error={errors?.middle_name}
                                    // errorMsg={errors?.middle_name ? errors.middle_name.message : null}
                                    // onChange={handleChange}
                                    required
                                />
                            </div>
                            {/* <div className="col-md-4">
                                <InputField
                                    label="Company address"
                                    name="company_address"
                                    value={inputs.company_address}
                                    onChange={handleChange}
                                    required
                                />
                            </div> */}
                            <div className="col-md-4">
                                <NepaliDateInput
                                    label="Company start date"
                                    name="company_start_date"
                                    value={inputs.company_start_date}
                                    onChange={handleChange}
                                    // value={inputs.birth_date}
                                    // error={errors?.birth_date}
                                    // errorMsg={errors?.birth_date ? errors.birth_date.message : null}
                                    // onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className='form-label'>Contact number</label>
                                    <PhoneInput
                                        country={'np'}
                                        onlyCountries={['np']}
                                        disableDropdown
                                        countryCodeEditable={false}
                                        inputProps={{
                                            name: 'contact_number',
                                            required: true,
                                        }}
                                        value={inputs.contact_number}
                                        onChange={phone => setInputs({...inputs, contact_number: phone})}
                                        inputStyle={{paddingLeft: '48px', width: '100%'}}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <InputField
                                    label="PAN number"
                                    type='number'
                                    name="pan_number"
                                    value={inputs.pan_number}
                                    // error={errors?.last_name}
                                    // errorMsg={errors?.last_name ? errors.last_name.message : null}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Company description<code>*</code></label>
                                    <textarea className="form-control mt-2" rows="4" name='description'
                                              onChange={handleChange} value={inputs.description}></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h3 className="mt-4 mb-2 p-0">Document Upload</h3>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-4">
                                <UploadFile
                                    name="company_image"
                                    type={"img"}
                                    label="Company image"
                                    value={[inputs.company_image]}
                                    // setImageInput={(imgFile) => {
                                    //     setInputs({ ...inputs, self_image: imgFile[0] })
                                    // }}
                                    setImageInput={(fileG) => {
                                        handleFile(fileG[0], 'company_image')
                                    }}
                                    required
                                />
                            </div>

                            <div className="col-md-4">
                                <UploadFile
                                    name="paper_work_pdf"
                                    type={"pdf"}
                                    accept={"application/pdf,application/vnd.ms-excel"}
                                    label="Paper work pdf"
                                    value={[inputs.paper_work_pdf]}
                                    setImageInput={(fileG) => {
                                        handleFile(fileG[0], 'paper_work_pdf')
                                    }}
                                    required
                                />
                            </div>

                            <div className="col-md-4">
                                <UploadFile
                                    // name="company_registration"
                                    // type="pdf"
                                    type={"pdf"}
                                    accept={"application/pdf,application/vnd.ms-excel"}
                                    label="Company registration file"
                                    // value={inputs.pan}
                                    // setImageInput={(fileG) => {
                                    //     setInputs(prev=>({...prev, compan:fileG}))
                                    // }}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <h3 className='mt-4 mb-2'>Company Address</h3>
                <div className="card">
                    <div className="card-body">
                        <div className="row g-4">
                            {/* <div className="col-lg-6">
                                <InputField
                                    label="Latitude"
                                    name="latitude"
                                    value={inputs.latitude}
                                    error={errors?.latitude}
                                    errorMsg={errors?.latitude ? errors.latitude.message : null}
                                    required
                                    disabled
                                />
                            </div> */}
                            {/* <div className="col-lg-6">
                                <InputField
                                    label="Longitude"
                                    name="longitude"
                                    value={inputs.longitude}
                                    error={errors?.longitude}
                                    errorMsg={errors?.longitude ? errors.longitude.message : null}
                                    required
                                    disabled
                                />
                            </div> */}
                            <div className="col-lg-12">
                                {/* <div id="map" /> */}
                                <MapInput
                                    setLatLong={handleLatLng}
                                    lat={inputs.latitude}
                                    lng={inputs.longitude}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-4">
                    <button className="btn btn-primary" type='submit'>
                        Submit form
                    </button>
                </div>
            </form>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={submitting}
                // onClick={handleClose}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
        </div>
    )
}

function MapInput({
                      setLatLong = () => {
                      }, lat = null, lng = null
                  }) {
    const [latLng, setLatLng] = useState({
        lat: (lat === null || lat === "") ? null : Number(lat),
        lng: (lng === null || lng == "") ? null : Number(lng)
    })

    const [center, setCenter] = useState({
        lat: (lat === null || lat === "") ? 27.68322 : Number(lat),
        lng: (lng === null || lng == "") ? 85.33582 : Number(lng)
    })

    const [autocomplete, setAutocomplete] = useState(null)

    function clickedMap(e) {
        console.log(e)
        // console.log(), e.latLng.lng())
        setLatAndLng(e.latLng.lat(), e.latLng.lng())
    }

    function setLatAndLng(lat, lng) {
        setLatLng({...latLng, lat: lat, lng: lng})
        setCenter({...latLng, lat: lat, lng: lng})
        setLatLong(lat, lng)
    }

    return (
        <>
            {/* <h4>Place the marker on your location</h4> */}

            <GoogleMap
                mapContainerStyle={{width: "100%", aspectRatio: "3/1"}}
                center={center}
                zoom={15}
                onClick={clickedMap}
            >
                {/* <Autocomplete
                    restrictions={{ country: 'np' }}
                    types={'cities'}
                    onLoad={(autocomp) => {
                        setAutocomplete(autocomp)
                    }}
                    onPlaceChanged={() => {
                        if (autocomplete !== null) {
                            // console.log())
                            let selLocation = autocomplete.getPlace().geometry.location;
                            setLatAndLng(selLocation.lat(), selLocation.lng())
                        }
                    }}
                >
                    <input
                        type="text"
                        placeholder="Enter Your Location"
                        style={{
                            boxSizing: `border-box`,
                            border: `1px solid transparent`,
                            width: `50%`,
                            height: `40px`,
                            padding: `0 12px`,
                            borderRadius: `3px`,
                            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                            fontSize: `18px`,
                            outline: `none`,
                            textOverflow: `ellipses`,
                            position: "absolute",
                            top: "1%",
                            left: "25%",

                        }}
                    />
                </Autocomplete> */}
                <Marker
                    position={latLng}
                    draggable={true}
                    onDragEnd={clickedMap}
                />
            </GoogleMap>
        </>
    )
}

export default CompanyProfileForm
