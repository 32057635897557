import React, {useContext, useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import {useForm} from 'react-hook-form';
import axios from '../../../api.js';
import swal from 'sweetalert';
import {useNavigate} from 'react-router-dom';
import {LoginStateContext} from '../../../../contexts/LoginStateContext.js';
import {useQuery} from '@tanstack/react-query';

const FamilyMembers = ({user, token, family, leave, leavefamilyQuery, familyQuery, userpackage}) => {
    const loginContext = useContext(LoginStateContext)
    const [show3, setShow3] = useState(false);
    const [show5, setShow5] = useState(false);
    const [show6, setShow6] = useState(false);
    const [famId, setFamId] = useState();
    const [relationId, setRelationId] = useState(null);
    const navigate = useNavigate();
    const [selected, setSelected] = useState([])
    const [allChk, setAllChk] = useState(false)
    //leave request

    const packageData = useQuery(
        ['package'],
        async () => (
            axios.get('api/admin/userpackage', {headers: {'Authorization': `Bearer ${loginContext.state.token}`}}).then(res => res.data)
        )
    )

    const pg = packageData?.data?.package

    const {
        register: register3,
        handleSubmit: handleSubmit3,
        resetField: resetField3,
    } = useForm({
        defaultValues: {
            leave_reason: ""
        }
    });
    const handleClose3 = () => {
        setShow3(false);
        resetField3("leave_reason");
    };
    const handleShow3 = () => setShow3(true);
    const onSubmit3 = (data) => {
        axios.post('/api/admin/family/leave-request', data, {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.success) {
                    setShow3(false);
                    resetField3("leave_reason");
                    swal({
                        title: res.data.success,
                        icon: "success",
                    }).then((value) => {
                        if (value) {
                            leavefamilyQuery.refetch();
                        }
                    });
                }
            })
            .catch((err) => {

                console.log();
            })
    }

    //add family realtion
    const {
        register: register5,
        handleSubmit: handleSubmit5,
        resetField: resetField5,
    } = useForm({
        defaultValues: {
            family_relation: ""
        }
    });
    const handleClose5 = () => {
        setShow5(false)
        resetField5("family_relation")
    };
    const handleShow5 = (id) => {
        setShow5(true)
        setRelationId(id)
    }
    const onSubmit5 = (data) => {
        axios.post(`/api/admin/family/add-relation/${relationId}`, data, {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.success) {
                    setShow5(false);
                    resetField5("family_relation");
                    swal({
                        title: res.data.success,
                        icon: "success",
                    }).then((value) => {
                        if (value) {
                            familyQuery.refetch();
                        }
                    });
                }
            })
            .catch((err) => {
                console.log();
            })
    }

    //remove family member
    const {
        register: register6,
        handleSubmit: handleSubmit6,
        resetField: resetField6,
    } = useForm({
        defaultValues: {
            remove_reason: ""
        }
    });
    const handleClose6 = () => {
        setShow6(false);
        resetField6("remove_reason");
    };
    const handleShow6 = (e, id) => {
        setShow6(true)
        setFamId(id)
    }

    const onSubmit6 = (data) => {
        axios.post(`/api/admin/family/remove-request/${famId}`, data, {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.success) {
                    setShow6(false);
                    resetField6("remove_reason");
                    swal({
                        title: res.data.success,
                        icon: "success",
                    }).then((value) => {
                        if (value) {
                            familyQuery.refetch();
                        }
                    });
                }
            })
            .catch((err) => {
                console.log();
            })
    }

    const handleMemberPayment = () => {
        // if (userpackage != null && userpackage.package_status === 'Deactivated') {
        //     navigate(`/user/family-and-package/payment/${id}`)
        // } else {
        //     navigate(`/user/family/payment/${id}`)
        // }
        if (selected.length === 0) {
            return
        }
        navigate('/user/family/payment', {state: {selected: selected, type: 0}})
    }

    function handleCheck(e, val) {
        let value = Number(val)
        setAllChk(false)
        if (selected.includes(value)) {
            let filtered = selected.filter(item => item != value) || []
            setSelected(filtered)
        } else {
            let selArr = selected
            selArr.push(value)
            setSelected([...selArr])
        }
    }

    function handleChkAll(e) {
        setAllChk(e.target.checked)
        let chkData = family.filter((item) => item.approved === 1 && item.payment_status === 0)
        let addSel = chkData.map((item) => item.id)
        if (e.target.checked) {
            let newArr = addSel.filter(item => selected.includes(item) ? false : true)
            setSelected(prev => ([...prev, ...newArr]))
        } else {
            let newAddSel = selected.filter(item => {
                return addSel.includes(item.id) ? true : false
            })
            setSelected(prev => newAddSel)
        }
    }

    return (
        <div>

            <table className="table table-bordered">
                <thead>
                {user.member_type === 'Primary Member' ?
                    <tr>
                        <th>Family Member Name</th>
                        <th>Phone No.</th>
                        <th>Family Relation</th>
                        <th>Actions</th>
                    </tr>
                    :
                    <tr>
                        <th>Family Name</th>
                        <th>Actions</th>
                        <th>Remarks</th>
                    </tr>
                }
                </thead>
                <tbody>
                {user.member_type === 'Primary Member' ?
                    <>
                        <tr>
                            <td>{user.member.name}</td>
                            <td>{user.member.phone}</td>
                            <td>Self</td>
                            <td></td>
                        </tr>
                        {
                            family.filter(f => f.approved === 1).map((fam) =>
                                fam.payment_status !== 0 &&
                                <tr key={fam.id}>
                                    <td>{fam.member.user.name}</td>
                                    <td>{fam.member.user.phone}</td>
                                    {fam.family_relation !== null ?
                                        <td>{fam.family_relation}</td>
                                        :
                                        <td>
                                            <button className="btn btn-primary btn-sm"
                                                    onClick={() => handleShow5(fam.id)}>Add Family Relation
                                            </button>
                                            <Modal show={show5} onHide={handleClose5}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Add Family Relation</Modal.Title>
                                                </Modal.Header>
                                                <form onSubmit={handleSubmit5(onSubmit5)}>
                                                    <Modal.Body>
                                                        <div className="form-group">
                                                            <label htmlFor="">Family Relation</label>
                                                            <input type="text" {...register5("family_relation")}
                                                                   className="form-control" required/>
                                                        </div>
                                                    </Modal.Body>
                                                    <Modal.Footer className="dashboard-style">
                                                        <button className='btn btn-secondary mr-2'
                                                                onClick={handleClose5}>
                                                            Close
                                                        </button>
                                                        <button className='btn btn-primary' type="submit">
                                                            Save Changes
                                                        </button>
                                                    </Modal.Footer>
                                                </form>
                                            </Modal>
                                        </td>
                                    }
                                    <td>
                                        {fam.payment_status === 0 ?
                                            <>
                                                <span className='badge badge-warning mr-2'>Payment Due</span>
                                                <button className="btn btn-danger btn-sm mr-2"
                                                        onClick={() => handleMemberPayment(fam.id)}>Pay
                                                </button>
                                            </>
                                            :
                                            ''
                                        }
                                        {
                                            fam.remove.length === 0 || fam.remove[0].status !== 0 ?
                                                <>
                                                    <button className="btn btn-danger btn-sm"
                                                            onClick={(e) => handleShow6(e, fam.id)}>Remove
                                                    </button>
                                                    <Modal show={show6} onHide={handleClose6}>
                                                        <Modal.Header closeButton>
                                                            <Modal.Title>Remove Family Member</Modal.Title>
                                                        </Modal.Header>
                                                        <form onSubmit={handleSubmit6(onSubmit6)}>
                                                            <Modal.Body>
                                                                <div className="form-group">
                                                                    <label htmlFor="">Remove Reason</label>
                                                                    <textarea
                                                                        type="text" {...register6("remove_reason")}
                                                                        className="form-control" required></textarea>
                                                                </div>
                                                            </Modal.Body>
                                                            <Modal.Footer className="dashboard-style">
                                                                <button className='btn btn-secondary mr-2'
                                                                        onClick={handleClose6}>
                                                                    Close
                                                                </button>
                                                                <button className='btn btn-primary' type="submit">
                                                                    Save Changes
                                                                </button>
                                                            </Modal.Footer>
                                                        </form>
                                                    </Modal>
                                                </>
                                                :
                                                fam.remove[0].status === 0 &&
                                                <span className="badge badge-secondary">(Remove request) Waiting for admin approval</span>
                                        }
                                    </td>
                                </tr>
                            )
                        }
                    </>
                    :
                    family.filter(f => f.approved === 1).map((fam) =>
                        <tr key={fam.id}>
                            <td>{fam.familyname.family_name}</td>
                            <td>
                                {
                                    fam.payment_status === 0 ?
                                        <span className='badge badge-secondary'>Pending. Payment Due</span>
                                        :
                                        ''
                                }
                                {
                                    Object.keys(leave).length === 0 || leave.status === 2 || leave.status === 4 ?
                                        <button className='ml-3 btn btn-danger' onClick={handleShow3}>Leave
                                            Family</button>
                                        :
                                        ''
                                }
                                <Modal show={show3} onHide={handleClose3}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Leave Family Request</Modal.Title>
                                    </Modal.Header>
                                    <form onSubmit={handleSubmit3(onSubmit3)}>
                                        <Modal.Body>
                                            <div className="form-group">
                                                <label htmlFor="">Leave Reason</label>
                                                <textarea type="text" {...register3("leave_reason")}
                                                          className="form-control" required></textarea>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer className="dashboard-style">
                                            <button className='btn btn-secondary mr-2' onClick={handleClose3}>
                                                Close
                                            </button>
                                            <button className='btn btn-primary' type="submit">
                                                Save Changes
                                            </button>
                                        </Modal.Footer>
                                    </form>
                                </Modal>
                            </td>
                            <td>
                                {leave.status === 0 || leave.status === 1 ?
                                    <span
                                        className="badge badge-secondary">Leave Request (Waiting for approval)</span> : ''}
                                {leave.status === 2 || leave.status === 4 ?
                                    <>
                                        <span className="badge badge-danger">Leave Request Rejected</span>
                                        <p className='text-danger mb-0'><b>Reject Reason:</b> {leave.reject_reason}</p>
                                    </>
                                    :
                                    ''
                                }
                            </td>
                        </tr>
                    )
                }
                </tbody>
            </table>
            {
                family.filter(item => item.approved === 1 && item.payment_status === 0)?.length > 0 && (
                    <div className='mt-4'>
                        <div className="card-header">
                            <div><span className="fs-5">Payment Due Members</span></div>
                            <div><span
                                className="fw-light">These members have due payment. Pay to add them to your family.</span>
                            </div>
                        </div>
                        <div className="card-body">
                            <table className="table table-bordered">
                                <thead>
                                {user.member_type === 'Primary Member' ?
                                    <tr>
                                        <th><input type="checkbox" onClick={handleChkAll} checked={allChk}/></th>
                                        <th>Family Member Name</th>
                                        <th>Phone No.</th>
                                        <th>Family Relation</th>
                                        <th>Actions</th>
                                    </tr>
                                    :
                                    <tr>
                                        <th>Family Name</th>
                                        <th>Actions</th>
                                        <th>Remarks</th>
                                    </tr>
                                }
                                </thead>
                                <tbody>
                                {user.member_type === 'Primary Member' ?
                                    <>
                                        {
                                            family.filter(f => f.approved === 1).map((fam) =>
                                                fam.payment_status === 0 &&
                                                <tr key={fam.id}>
                                                    <td><input type="checkbox" onClick={(e) => handleCheck(e, fam.id)}
                                                               checked={selected.includes(fam.id)}/></td>
                                                    <td>{fam.member.user.name}</td>
                                                    <td>{fam.member.user.phone}</td>
                                                    {fam.family_relation !== null ?
                                                        <td>{fam.family_relation}</td>
                                                        :
                                                        <td>
                                                            <button className="btn btn-primary btn-sm"
                                                                    onClick={() => handleShow5(fam.id)}>Add Family Relation
                                                            </button>
                                                            <Modal show={show5} onHide={handleClose5}>
                                                                <Modal.Header closeButton>
                                                                    <Modal.Title>Add Family Relation</Modal.Title>
                                                                </Modal.Header>
                                                                <form onSubmit={handleSubmit5(onSubmit5)}>
                                                                    <Modal.Body>
                                                                        <div className="form-group">
                                                                            <label htmlFor="">Family Relation</label>
                                                                            <input
                                                                                type="text" {...register5("family_relation")}
                                                                                className="form-control" required/>
                                                                        </div>
                                                                    </Modal.Body>
                                                                    <Modal.Footer className="dashboard-style">
                                                                        <button className='btn btn-secondary mr-2'
                                                                                onClick={handleClose5}>
                                                                            Close
                                                                        </button>
                                                                        <button className='btn btn-primary' type="submit">
                                                                            Save Changes
                                                                        </button>
                                                                    </Modal.Footer>
                                                                </form>
                                                            </Modal>
                                                        </td>
                                                    }
                                                    <td>
                                                        {fam.payment_status === 0 ?
                                                            <>
                                                                {/* <span className='badge badge-warning mr-2'>Payment Due</span> */}
                                                                {/* <button className="btn btn-danger btn-sm mr-2" onClick={() => handleMemberPayment(fam.id)}>Pay</button> */}
                                                            </>
                                                            :
                                                            ''
                                                        }
                                                        {
                                                            fam.remove.length === 0 || fam.remove[0].status !== 0 ?
                                                                <>
                                                                    <button className="btn btn-danger btn-sm"
                                                                            onClick={(e) => handleShow6(e, fam.id)}>Remove
                                                                    </button>
                                                                    <Modal show={show6} onHide={handleClose6}>
                                                                        <Modal.Header closeButton>
                                                                            <Modal.Title>Remove Family Member</Modal.Title>
                                                                        </Modal.Header>
                                                                        <form onSubmit={handleSubmit6(onSubmit6)}>
                                                                            <Modal.Body>
                                                                                <div className="form-group">
                                                                                    <label htmlFor="">Remove Reason</label>
                                                                                    <textarea
                                                                                        type="text" {...register6("remove_reason")}
                                                                                        className="form-control"
                                                                                        required></textarea>
                                                                                </div>
                                                                            </Modal.Body>
                                                                            <Modal.Footer className="dashboard-style">
                                                                                <button className='btn btn-secondary mr-2'
                                                                                        onClick={handleClose6}>
                                                                                    Close
                                                                                </button>
                                                                                <button className='btn btn-primary'
                                                                                        type="submit">
                                                                                    Save Changes
                                                                                </button>
                                                                            </Modal.Footer>
                                                                        </form>
                                                                    </Modal>
                                                                </>
                                                                :
                                                                fam.remove[0].status === 0 &&
                                                                <span className="badge badge-secondary">(Remove request) Waiting for admin approval</span>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </>
                                    :
                                    family.filter(f => f.approved === 1).map((fam) =>
                                        <tr key={fam.id}>
                                            <td>{fam.familyname.family_name}</td>
                                            <td>
                                                {
                                                    fam.payment_status === 0 ?
                                                        <span className='badge badge-secondary'>Pending. Payment Due</span>
                                                        :
                                                        ''
                                                }
                                                {
                                                    Object.keys(leave).length === 0 || leave.status === 2 || leave.status === 4 ?
                                                        <button className='ml-3 btn btn-danger' onClick={handleShow3}>Leave
                                                            Family</button>
                                                        :
                                                        ''
                                                }
                                                <Modal show={show3} onHide={handleClose3}>
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>Leave Family Request</Modal.Title>
                                                    </Modal.Header>
                                                    <form onSubmit={handleSubmit3(onSubmit3)}>
                                                        <Modal.Body>
                                                            <div className="form-group">
                                                                <label htmlFor="">Leave Reason</label>
                                                                <textarea type="text" {...register3("leave_reason")}
                                                                          className="form-control" required></textarea>
                                                            </div>
                                                        </Modal.Body>
                                                        <Modal.Footer className="dashboard-style">
                                                            <button className='btn btn-secondary mr-2'
                                                                    onClick={handleClose3}>
                                                                Close
                                                            </button>
                                                            <button className='btn btn-primary' type="submit">
                                                                Save Changes
                                                            </button>
                                                        </Modal.Footer>
                                                    </form>
                                                </Modal>
                                            </td>
                                            <td>
                                                {leave.status === 0 || leave.status === 1 ?
                                                    <span className="badge badge-secondary">Leave Request (Waiting for approval)</span> : ''}
                                                {leave.status === 2 || leave.status === 4 ?
                                                    <>
                                                        <span className="badge badge-danger">Leave Request Rejected</span>
                                                        <p className='text-danger mb-0'><b>Reject
                                                            Reason:</b> {leave.reject_reason}</p>
                                                    </>
                                                    :
                                                    ''
                                                }
                                            </td>
                                        </tr>
                                    )
                                }
                                </tbody>

                            </table>
                            <div className="mt-3">
                                <div className="btn btn-primary" onClick={handleMemberPayment}>
                                    Make Due Payment
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }


        </div>
    )
}

export default FamilyMembers
