import {ArrowBackIosOutlined, ArrowForwardIosOutlined} from '@mui/icons-material';
import {Box, IconButton} from '@mui/material';
import React from 'react'
import {useState} from 'react';
import Slider from 'react-slick';

function SliderOne({numItems, settingOverride = {}, sx = {}, children, gap = 0, py = 0, px = 0}) {
    const [muiPrevIcon, setMuiPrevIcon] = useState();
    const [muiNextIcon, setMuiNextIcon] = useState();

    const settings = {
        dots: true,
        infinite: false,
        speed: 600,
        autoplaySpeed: 6000,
        slidesToShow: numItems,
        nextArrow: <NextArrow/>,
        prevArrow: <PrevArrow/>,
        ...settingOverride
    }
    const sliderSx = {
        '& .slick-track': {
            marginLeft: '0 !important',
            display: 'flex',
            gap: gap,
        },
        '& .slick-list': {
            py: py
        },
        '& .slick-slider': {
            width: '100% !important'
        },
        '& .slick-slide': {
            height: 'auto !important',
            '& > div': {
                height: '100% !important'
            }
        }

    }
    return (
        <Box
            sx={{...sx, ...sliderSx}}>
            <Slider {...settings}>
                {children}
            </Slider>
        </Box>
    )
}


function PrevArrow(props) {
    const {className, style, onClick} = props
    const sx = {
        // display:'block',
        position: 'absolute',
        top: '50%',
        left: '0',
        transform: 'translate(0, -50%)',
        zIndex: 1,
        padding: 0
    }
    return (
        <IconButton sx={sx} onClick={onClick} size='large'>
            <ArrowBackIosOutlined fontSize='large'/>
        </IconButton>
    )
}

function NextArrow(props) {
    const {className, style, onClick} = props
    const sx = {
        // display:'block',
        position: 'absolute',
        top: '50%',
        right: '0',
        transform: 'translate(0, -50%)',
        padding: 0,
    }
    return (
        <IconButton sx={sx} onClick={onClick} size='large'>
            <ArrowForwardIosOutlined fontSize='large'/>
        </IconButton>
    )
}

export default SliderOne
