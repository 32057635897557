import {Box, Card, CardContent, Grid, Typography} from '@mui/material'
import React, {useRef} from 'react'
import {useEffect} from 'react'

function SearchResults({open, searchResults, handleClose}) {
    const resultBoxRef = useRef(null)
    const display = open ? 'block' : 'none'

    useEffect(() => {
        const clickAway = (e) => {
            if (e.target !== resultBoxRef.current) {
                handleClose()
            }
        }
        window.addEventListener('click', clickAway)

        return () => {
            window.removeEventListener('click', clickAway)
        }
    }, [])
    return (
        <Box sx={{
            position: 'absolute',
            width: '100%',
            top: '60px',
            left: 0,
            background: 'white',
            zIndex: 99,
            display: display
        }} ref={resultBoxRef}>
            <Card sx={{background: 'white'}}>
                <CardContent>
                    {
                        searchResults.map((res) => (
                            <Box sx={{'&:hover': {opacity: 0.8}, cursor: 'pointer'}} mb={2}>
                                <Grid container gap={2} alignItems='center' justifyContent='space-between'>
                                    <Grid item xs={1}>
                                        <img src={res.image_path} alt=""
                                             style={{width: '100%', aspectRatio: '1/1', objectFit: 'cover'}}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant='subtitle1'>
                                            {res.productName}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant='title' sx={{fontWeight: '550'}}>
                                            NPR {res.sale_price}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        ))
                    }
                </CardContent>
            </Card>
        </Box>
    )
}

export default SearchResults
