import {Box, Button, InputBase} from '@mui/material'
import React from 'react'
import BlogMenus from './BlogMenus'
import {Search} from '@mui/icons-material'
import BlogSearchInput from './components/BlogSearchInput'

function GDBlogHeader() {
    return (
        <Box
            display={'flex'}
            alignItems='center'
            justifyContent='space-between'
            pt={4}
            flexWrap='wrap'
            gap={4}
            position='relative'
        >
            <BlogSearchInput/>
            <Box
                overflow={'auto'}
            >
                <BlogMenus/>
            </Box>
        </Box>
    )
}

export default GDBlogHeader
