import {Rating} from '@mui/material'
import React from 'react'
import {useContext} from 'react'
import {useState} from 'react'
import {useEffect} from 'react'
import axios from '../../../api'
import {LoginStateContext} from '../../../../contexts/LoginStateContext'
import swal from 'sweetalert'

function AppointmentReview({doctor_id, appointment_id}) {
    const loginContext = useContext(LoginStateContext)
    const [allowReview, setAllowReview] = useState(false)
    const [inputs, setInputs] = useState({
        rating: 1,
        comment: '',
        doctor_id: doctor_id,
        appointment_id: appointment_id
    })
    // console.log()
    useEffect(() => {
        axios.get("api/doctorReview?doctor_id=" + doctor_id)
            .then(res => {
                // console.log()
                const data = res.data.filter(val => val.appointment_id == appointment_id)
                if (data.length == 0) {
                    setAllowReview(true)
                } else {
                    setAllowReview(false)
                }
            })
            .catch(err => console.log())
    }, [])

    function postReview() {
        axios.post("/api/doctorReview", inputs, {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
            .then(res => {
                swal("Success", "Review Posted", "success");
                setAllowReview(false)
            })
            .catch(err => {
                swal("Error", "There was an error while posting review", "error");
            })
    }

    if (!allowReview) {
        return <></>
    }

    return (
        <div className="card">
            <div className="review-input">
                <div className="review-item mt-3">
                    <div className="review-top d-flex align-items-center mb-1">
                        <div className="review-user-image me-2">
                            <img
                                src={"https://images.unsplash.com/photo-1615789591457-74a63395c990?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8YmFieSUyMGNhdHxlbnwwfHwwfHw%3D&w=1000&q=80"}
                                alt=""/>
                        </div>
                        <div className="review-middle">
                            <div className="review-name">
                                <span className="label-txt">
                                    {loginContext.state.data.member.name}
                                </span>
                            </div>
                            <div className="review-rating">
                                <Rating name="size-large" value={inputs.rating}
                                        onChange={(event, newValue) => {
                                            setInputs({...inputs, rating: newValue});
                                        }}/>
                            </div>
                        </div>
                        <div className="ml-auto">
                            <button className="btn btn-success" onClick={postReview}>
                                Post Review
                            </button>
                        </div>
                    </div>
                    <div class="input-group">
                        <textarea class="form-control" aria-label="With textarea"
                                  placeholder='Write Your Review For This Doctor' required onChange={(e) => {
                            setInputs({...inputs, comment: e.target.value})
                        }}></textarea>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppointmentReview
