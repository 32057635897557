import {faMinus, faPlus} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React, {useState} from 'react'
import swal from 'sweetalert'
import axios from "../api.js"

function ProductQuanChanger({count, product, setStateChanged}) {
    const [cartCount, setCartCount] = useState(count)

    const cartItem = JSON.parse(localStorage.getItem('cart'))

    const cart_item = cartItem.filter((item) => {
        return item.id == product.id
    })

    // cartItem.map((item)=>{
    //     if(item.id == product.id) {
    //         item.cartCount = cartCount
    //     }
    // })

    // localStorage.setItem('cart', JSON.stringify(cartItem))

    const changeProdCount = (count) => {
        axios.get("api/products?slug=" + product.slug)
            .then(res => {
                if (res.data.data[0].stock == 0) {
                    swal({
                        title: "Product Out Of Stock",
                        text: "This product is out of Stock and it will be removed from your cart",
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                    })
                    const cart = JSON.parse(localStorage.getItem('cart'))
                    const newCart = cart.filter((item) => {
                        return item.id != product.id
                    })
                    localStorage.setItem('cart', JSON.stringify(newCart))
                    setStateChanged((prev) => !prev)
                    return
                }

                if (count <= 0 || count > res.data.data[0].stock) {
                    return
                }

                setCartCount(count)
                cartItem.map((item) => {
                    if (item.id == product.id) {
                        item.cartCount = count
                    }
                })
                localStorage.setItem('cart', JSON.stringify(cartItem))
                setStateChanged((prev) => !prev)

            })
    }

    return (
        <div className="item-count d-flex ">
            <button
                className="minus"
                onClick={() => changeProdCount(cartCount - 1)}
            >
                <FontAwesomeIcon icon={faMinus} size="xl"/>
            </button>
            <input
                type="text"
                value={(cartCount > 0) ? cartCount : count}
                className="text-center"
                min="1"
            />
            <button
                className="plus"
                onClick={() => changeProdCount(cartCount + 1)}
            >
                <FontAwesomeIcon icon={faPlus} size="xl"/>
            </button>
        </div>
    )
}

ProductQuanChanger.defaultProps = {
    setStateChanged: () => {
    }
}

export default ProductQuanChanger
