import {FormControlLabel} from '@mui/material'
import React from 'react'
import IOSSwitch from '../../../../custom_componets/IOSSwitch'
import {Edit} from '@mui/icons-material'
import {useChkLogin} from '../../../../../middlewares/LoginMiddleware'
import FormModalContext from '../../../../custom_componets/globalFormModal/FormModalContext'
import {useContext} from 'react'
import ChangeEmail from '../myprofile/changeEmail/ChangeEmail'

function SecuritySetting() {
    const {token, data} = useChkLogin()
    const {openFormModal} = useContext(FormModalContext)
    console.log("Login data is", data)
    const memberDetails = data.member
    const emailVerified = memberDetails.is_verified === 1
    const phoneVerified = memberDetails.phone_verified === 1

    function getVerificationStatus(status) {
        if (status) {
            return (
                <span className="text-success">
                    Verified
                </span>
            )
        } else {
            return (
                <span className="text-danger">
                    Unverified
                </span>
            )
        }
    }

    return (
        <div>
            <div className="p-4">
                <div className="mb-4">
                    <span className="fw-bold fs-4">Security Setting</span>
                </div>
                <div className="py-3 border-bottom">
                    <div className="row">
                        <div className="col-6">
                            <div>
                                <span className='fs-6' style={{fontWeight: 600}}>
                                    Email Address
                                </span>
                            </div>
                            <div className="mt-1">
                                <span
                                    className="fw-light text-muted">The email address associated with your account.</span>
                            </div>
                        </div>
                        <div className="col-4 text-right">
                            <div>
                                <span className="fs-6">
                                    {memberDetails.email}
                                    {/* alex.morrison5@gmail.com */}
                                </span>
                            </div>
                            <div className="mt-1">
                                {getVerificationStatus(emailVerified)}
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="d-flex w-100 justify-content-end">
                                <button className='btn btn-primary' style={{borderRadius: 20}}
                                        onClick={() => openFormModal(<ChangeEmail/>)}>
                                    <div className="d-flex gap-2 align-items-center">
                                        <span className="fs-6">Edit</span>
                                        <Edit className='fs-6'/>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-3 border-bottom">
                    <div className="row">
                        <div className="col-6">
                            <div>
                                <span className='fs-6' style={{fontWeight: 600}}>
                                    Mobile Number
                                </span>
                            </div>
                            <div className="mt-1">
                                <span
                                    className="fw-light text-muted">The mobile number associated with your account.</span>
                            </div>
                        </div>
                        <div className="col-4 text-right">
                            <div>
                                <span className="fs-6">
                                    {memberDetails.phone}
                                </span>
                            </div>
                            <div className="mt-1">
                                {getVerificationStatus(phoneVerified)}
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="d-flex w-100 justify-content-end">
                                <button className='btn btn-primary' style={{borderRadius: 20}}>
                                    <div className="d-flex gap-2 align-items-center">
                                        <span className="fs-6">Edit</span>
                                        <Edit className='fs-6'/>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-3 border-bottom">
                    <div className="row">
                        <div className="col-6">
                            <div>
                                <span className='fs-6' style={{fontWeight: 600}}>
                                    Password
                                </span>
                            </div>
                            <div className="mt-1">
                                <span
                                    className="fw-light text-muted">Set a unique password to protect your account.</span>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="d-flex w-100 justify-content-end">
                                <button className='btn btn-outline-dark' style={{borderRadius: 20}}>
                                    <div className="d-flex gap-2 align-items-center">
                                        <span className="fs-6">Change Password</span>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-3 border-bottom">
                    <div className="row align-items-center">
                        <div className="col-9">
                            <div>
                                <span className='fs-6' style={{fontWeight: 600}}>
                                    2-Factor Authentication
                                </span>
                            </div>
                            <div className="mt-1">
                                <span className="fw-light text-muted">Make your account extra secure with 2-Factor Authentication. You need to enter OTP along with your password.</span>
                            </div>
                        </div>

                        <div className="col-3">
                            <div className="d-flex w-100 justify-content-end">
                                <FormControlLabel
                                    control={<IOSSwitch sx={{m: 1}} defaultChecked/>}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default SecuritySetting
