import React from 'react'
import InfoModal from '../infoModal'
import {Typography} from '@mui/material'

const LabModal = () => {
    return (
        <Typography>
            This is the lab modal content.
        </Typography>
    )
}

const LabInfo = () => {
    return (
        <InfoModal
            title="Click to view insurance informations."
            modal={<LabModal/>}
        />
    )
}

export default LabInfo
