import {ExpandMoreOutlined} from '@mui/icons-material'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Backdrop,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Grid,
    Stack,
    TextField,
    Typography
} from '@mui/material'
import {DateTimePicker, DesktopDatePicker, LocalizationProvider, TimePicker} from '@mui/x-date-pickers'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import React from 'react'
import {useContext} from 'react'
import {useEffect} from 'react'
import {useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {LoginStateContext} from '../../../contexts/LoginStateContext'
import axios from '../../api'
import PaymentModal from './PaymentModal'

function getInitialState(type, data) {
    if (type === 'profile') {
        return {
            labprofile_id: data.id
        }
    } else {
        return {
            labtest_id: data.id
        }
    }
}

function LabTestDetail() {
    const [expanded, setExpanded] = useState(true)
    const location = useLocation()
    const navigate = useNavigate()
    const loginContext = useContext(LoginStateContext)
    const [successData, setSuccessData] = useState(null)
    const [open, setOpen] = useState(false)
    const [verifying, setVerifying] = useState(false)
    let data = null
    if (location.state.data) {
        data = location.state.data
    }
    const [postData, setPostData] = useState({
        ...getInitialState(location.state.type, data),
        date: new Date(),
        time: new Date().getTime(),
        price: data.price
    })

    function handleClose() {
        setOpen(false)
    }

    function bookpackage() {
        console.log()
        postData.date = dayjs(postData.date).format('YYYY-MM-DD')
        postData.time = dayjs(postData.time).format("HH:MM")
        console.log()
        setVerifying(true)
        axios.post('/api/admin/lab-test/book-test', postData, {headers: {'Authorization': `Bearer ${loginContext.state.token}`}})
            .then(res => {
                console.log()
                setSuccessData(res.data)
                setOpen(true)
                setVerifying(false)
            })
            .catch(err => {
                navigate('/login')
                setVerifying(false)
            })
    }


    if (data === null) {
        return
    }
    return (
        <Box className='container' py={2}>
            <Typography variant='h5' fontWeight={600}>Test Details</Typography>
            <Grid container spacing={2} py={1}>
                <Grid item xs={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Card variant='outlined'>
                                <CardContent>
                                    <Stack direction='row'>
                                        <Avatar src={"https://assets.pharmeasy.in/web-assets/dist/51ffd21a.svg"}
                                                width={10} height={10}/>
                                        <Box ml={2}>
                                            <Typography variant='h5' fontWeight={600}>
                                                {
                                                    location.state.type === 'profile' ?
                                                        data.profile_name
                                                        :
                                                        data.tests
                                                }
                                            </Typography>
                                            <Typography variant='h4' my={2}>
                                                NPR {data.price}
                                            </Typography>
                                            <Stack direction={'row'} alignItems={'center'}>
                                                <Avatar
                                                    src='https://react.ghargharmadoctor.com/static/media/blue-logo.e8a3b3f1000e4d7812ed.png'
                                                    sx={{width: 40, height: 40}}/>
                                                <Typography variant='subtitle1' ml={1}>Provided By Ghargharmadoctor
                                                    Lab</Typography>
                                            </Stack>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <Stack spacing={3} direction='row' my={2}>
                                                    <DesktopDatePicker
                                                        label="Date for testing"
                                                        value={postData.date}
                                                        onChange={(value) => setPostData(prev => ({
                                                            ...prev,
                                                            date: value
                                                        }))}
                                                        renderInput={(params) => <TextField {...params} />}
                                                        minDate={new Date()}
                                                    />
                                                    <TimePicker
                                                        label="Time for testing"
                                                        value={postData.time}
                                                        onChange={value => setPostData(prev => ({
                                                            ...prev,
                                                            time: value
                                                        }))}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                                </Stack>
                                            </LocalizationProvider>
                                            <Button variant='outlined' sx={{mt: 2}} onClick={bookpackage}>
                                                Book Package
                                            </Button>
                                        </Box>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>
                        {
                            location.state.type === 'profile'
                            &&
                            <Grid item xs={12}>
                                <Box>
                                    <Accordion elevation={0} sx={{border: '1px solid', borderColor: 'divider'}}
                                               expanded={expanded}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreOutlined color='blue'/>}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            onClick={() => setExpanded(prev => !prev)}
                                            sx={{bgcolor: '#F7F7F7'}}
                                        >
                                            <Typography variant='h6' color={'primary'}>Included Tests in package
                                                ({data.labtest.length})</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{borderTop: 1, borderColor: 'divider'}}>
                                            <ul>
                                                {
                                                    data.labtest.map((test, idx) => (
                                                        <Box component='li' py={0.5} color='text.secondary' key={idx}>
                                                            <Typography variant='subtitle1'>
                                                                {test.tests}
                                                            </Typography>
                                                        </Box>
                                                    ))
                                                }
                                            </ul>

                                        </AccordionDetails>
                                    </Accordion>
                                </Box>
                            </Grid>
                        }

                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Card variant='outlined'>
                        <CardHeader title="More Packages and Tests" subheader="Book more packages"/>
                    </Card>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {
                successData &&
                <PaymentModal open={open} handleClose={handleClose} data={successData} setVerifying={setVerifying}/>
            }

            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={verifying}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>

        </Box>
    )
}

export default LabTestDetail
