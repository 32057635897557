import React from 'react'
import NewsItemContainer from './components/NewsItemContainer'
import ItemDetails from './components/ItemDetails'
import {Box, Button, Grid, IconButton, InputBase, Stack, Typography} from '@mui/material'
import ContentContainer from './components/ContentContainer'
import {Search} from '@mui/icons-material'
import BlogMenus from './BlogMenus'
import GDBlogProvider from './GDBlogProvider'
import HomePosts from './HomePosts'
import LatestBlogs from './LatestBlogs'
import PopularPosts from './PopularPosts'
import GDBlogHeader from './GDBlogHeader'


function GDBlogs() {
    return (
        <Box className='container'>
            {/* <GDBlogHeader/> */}

            <ContentContainer header='Trending Posts'>
                <HomePosts/>
            </ContentContainer>

            <Grid container spacing={2}>
                <Grid item xs={12} lg={8}>
                    <ContentContainer header='Recent Blogs and Posts'>
                        <LatestBlogs/>
                    </ContentContainer>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <ContentContainer header='Popular Posts'>
                        <PopularPosts/>
                    </ContentContainer>
                </Grid>
            </Grid>
        </Box>
    )
}

export default GDBlogs
