import {RateReview, RateReviewOutlined, VideoCallOutlined} from '@mui/icons-material'
import {Alert, AlertTitle, Avatar, Box, Button, Fade, Grow, Rating, Stack, TextField, Typography} from '@mui/material'
import {useQuery} from '@tanstack/react-query'
import React, {useContext} from 'react'
import {useNavigate} from 'react-router-dom'
import AuthButton from '../../../custom_componets/AuthButton'
import axios from '../../../api';
import {LoginStateContext} from '../../../../contexts/LoginStateContext'
import {useState} from 'react'
import {useRef} from 'react'
import {getDateStatus} from './DateStatus'

function ScreeningTeam({team = []}) {
    if (team.length === 0) {
        return (
            <Alert severity='error'>
                <AlertTitle>No Team Added Yet</AlertTitle>
            </Alert>
        )
    }
    return (
        <Box minHeight='200px'>
            <table className="table table-responsive-sm">
                <col style={{width: '45%'}}/>
                <col style={{width: '30%'}}/>
                <col style={{width: '25%'}}/>
                <thead>
                <tr>
                    <th className='text-muted'>Employee</th>
                    <th className='text-muted'>Employee Type</th>
                    <th className='text-muted'>Contact Number</th>
                </tr>
                </thead>
                <tbody>
                {
                    team.map((item, idx) => (
                        <tr key={idx}>
                            <td>
                                <Box display='flex' alignItems='center' gap={1}>
                                    <Avatar
                                        src='https://media.istockphoto.com/id/1309328823/photo/headshot-portrait-of-smiling-male-employee-in-office.jpg?s=612x612&w=0&k=20&c=kPvoBm6qCYzQXMAn9JUtqLREXe9-PlZyMl9i-ibaVuY='/>
                                    <span className='font-weight-bold'>{item.employee.user.name}</span>
                                </Box>
                            </td>
                            <td><span className='font-weight-bold'>{item.type}</span></td>
                            <td><span className='font-weight-bold'>{item.employee.user.phone}</span></td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </Box>
    )
}

export function ScreeningLabTeam({screeningDate}) {
    if (screeningDate.length === 0) {
        return
    }
    let date = screeningDate[screeningDate.length - 1]
    let team = date.employees.filter((item) => item.type === "Lab Technician" || item.type === "Lab Nurse")
    console.log()
    return (
        <ScreeningTeam team={team}/>
    )
}

export function ScreeningDoctorTeam({screeningDate,}) {
    const [open, setOpen] = useState(false)
    const [reviewData, setReviewData] = useState({})
    if (screeningDate.length === 0) {
        return
    }
    let date = screeningDate[screeningDate.length - 1]
    let team = date.employees.filter((item) => item.type !== "Lab Technician" && item.type !== "Lab Nurse")

    function handleClose() {
        setOpen(false)
    }

    function handleReviewBtn(id) {
        return function () {
            setOpen(true)
            setReviewData({
                employee_id: id,
                date: date
            })
        }
    }

    if (team.length === 0) {
        return (
            <Alert severity='error'>
                <AlertTitle>No Team Added Yet</AlertTitle>
            </Alert>
        )
    }

    return (
        <Box>
            {/* <ScreeningTeam team={team} /> */}
            <Box minHeight='200px'>
                <table className="table table-responsive-sm">
                    <thead>
                    <tr>
                        <th className='text-muted'>Employee</th>
                        <th className='text-muted'>Employee Type</th>
                        <th className='text-muted'>Contact Number</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        team.map((item, idx) => (
                            <tr key={idx}>
                                <td>
                                    <Box display='flex' alignItems='center' gap={1}>
                                        <Avatar
                                            src='https://media.istockphoto.com/id/1309328823/photo/headshot-portrait-of-smiling-male-employee-in-office.jpg?s=612x612&w=0&k=20&c=kPvoBm6qCYzQXMAn9JUtqLREXe9-PlZyMl9i-ibaVuY='/>
                                        <span className='font-weight-bold'>{item.employee.user.name}</span>
                                    </Box>
                                </td>
                                <td><span className='font-weight-bold'>{item.type}</span></td>
                                <td><span className='font-weight-bold'>{item.employee.user.phone}</span></td>
                                <td>
                                    {
                                        getDateStatus(date.status) > 6 &&
                                        <Button onClick={handleReviewBtn(item.employee.id)}
                                                startIcon={<RateReviewOutlined/>}>Post Review</Button>
                                    }
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </Box>
            {
                date.status === 'Doctor Visit' && (
                    <VideoConsultation online={date.online}/>
                )
            }
            <ScreeningReview open={open} handleClose={handleClose} reviewData={reviewData}/>
        </Box>
    )
}

function VideoConsultation({online}) {
    const navigate = useNavigate()
    if (online.length === 0) {
        return
    }
    let videoConsult = online[0]

    function joinMeeting() {
        window.open(videoConsult.join_url, '_blank')
        // navigate(videoConsult.join_url)
    }

    return (
        <Box display='flex' gap={1} alignItems='center'>
            <Box>
                <b>Meeting Time:</b> {videoConsult.start_time}
            </Box>
            <Box>
                <Button variant='contained' endIcon={<VideoCallOutlined/>} onClick={joinMeeting}>
                    Join Zoom Meeting
                </Button>
            </Box>
        </Box>
    )
}

function ScreeningReview({open, handleClose, reviewData}) {
    const loginContext = useContext(LoginStateContext)
    const commentRef = useRef(null)

    function postReview() {
        // console.log()
        const data = {
            employee_id: reviewData.employee_id,
            screeningdate_id: reviewData.date.id,
            comment: commentRef.current.value
        }
        axios.post('api/admin/screening/review', data, {headers: {'Authorization': `Bearer ${loginContext.state.token}`}}).then(res => handleClose())
    }

    if (!open) {
        return
    }
    return (
        <Box>
            <Box position='absolute' width='100%' height='100%' top={0} left={0} display='flex' justifyContent='center'
                 alignItems={'center'} zIndex={1}>
                <Grow in={open}>
                    <Box mt={2} p={2} width='80%' bgcolor={'white'} boxShadow={3}>
                        <Stack direction={'row'} alignItems='center'>
                            <Avatar
                                src={loginContext.state.data.image_path}
                                sx={{width: '70px', height: '70px', mr: 1}}
                            />
                            <Box>
                                <Typography variant="subtitle1" fontWeight={450}>
                                    {loginContext.state.data.member.name}
                                </Typography>
                                <Typography lineHeight={1.1} variant="subtitle1" color="text.secondary">
                                    Reviews are public and are editable
                                </Typography>
                            </Box>
                        </Stack>
                        <Box my={1}>
                            <TextField
                                inputRef={commentRef}
                                variant='filled'
                                id="outlined-textarea"
                                label="Your Experience"
                                placeholder="Your Experience with the store"
                                fullWidth
                                multiline
                                // onChange={(e) => setReviewData(prev => ({ ...prev, comment: e.target.value }))}
                                // disabled
                            />
                        </Box>
                        <Box display='flex' alignItems={'center'} gap={1}>
                            <Button variant='contained' color='error' onClick={handleClose}>Cancel</Button>
                            <Button variant='contained' onClick={postReview}>Post Review</Button>
                        </Box>
                    </Box>
                </Grow>
            </Box>
            <Box position='absolute' top={0} left={0} width='100%' height='100%' bgcolor='black' sx={{opacity: 0.5}}/>
        </Box>
    )
}

export default ScreeningTeam
