import {useEffect, useState,} from "react";
import axios from "./api.js";
import "./VendorRegister.css";
import {Link} from "react-router-dom"

const VendorRegister = () => {

    useEffect(() => {
    });

    const initialValues = {
        name: "",
        email: "",
        country: "",
        phone: "",
        vendor_type: "",
        address: "",
        password: "",
        image: "",
        file: "",
        role: "5",
        is_verified: "0"

    };
    const [formValues, setFormValues] = useState(initialValues);
    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormValues({...formValues, [name]: value});
    };
    const vendorRegister = (e) => {
        e.preventDefault();
        // console.log();
        axios
            .post("/api/register", formValues, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((res) => {
                console.log();
            })
            .catch((err) => {
                console.log();
            });
    };
    return (
        <div classNameName="container">
            <div className="card my-5 mx-5">
                <h5 className="card-header">Vendor Register</h5>
                <div className="card-body">
                    <form
                        onSubmit={vendorRegister}
                        method="post"
                        className="row g-3 needs-validation"
                    >
                        <div className="col-md-12">
                            <label for="validationCustom01" className="form-label">
                                Full Name
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name="name"
                                placeholder="Full Name"
                                value={formValues.name}
                                required
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-md-12">
                            <label for="validationCustom02" className="form-label">
                                Email Address
                            </label>
                            <input
                                type="email"
                                className="form-control"
                                placeholder="email"
                                name="email"
                                value={formValues.email}
                                required
                                onChange={handleChange}
                            />
                            <div className="valid-feedback">Looks good!</div>
                        </div>
                        <div className="col-md-9">
                            <label for="validationCustom02" className="form-label">
                                Phone Number
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                name="phone"
                                value={formValues.phone}
                                required
                                onChange={handleChange}
                            />
                        </div>
                        {/* <div className="col-md-3">
              <label for="validationCustom02" className="form-label">
                Phone Number
              </label>
              <input
                type="number"
                className="form-control"
                name="phone"
                value={formValues.phone}
                required
                onChange={handleChange}

              />
            </div> */}
                        <div class="col-md-6 position-relative">
                            <label for="validationTooltip04" class="form-label">
                                Type
                            </label>
                            <select class="form-select" name="vendor_type"
                                    value={formValues.vendor_type}
                                    required
                                    onChange={handleChange}>
                                <option value="" selected disabled
                                >
                                    --Select--
                                </option>
                                <option value="1">Gym</option>
                                <option value="2">Yoga</option>
                                <option value="3">Pharmacy</option>
                            </select>
                            <div class="invalid-tooltip">Please select a valid state.</div>
                        </div>
                        <div className="col-md-6">
                            <label for="validationCustom03" className="form-label">
                                Address
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name="address"
                                value={formValues.address}
                                required
                                onChange={handleChange}
                            />
                            <div className="invalid-feedback">
                                Please provide a valid Address .
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label for="validationCustom05" className="form-label">
                                Password
                            </label>
                            <input
                                type="password"
                                className="form-control"
                                name="password"
                                value={formValues.password}
                                required
                                onChange={handleChange}
                            />
                            <div classNameName="invalid-feedback">
                                Please provide a valid Password.
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label for="validationCustom05" className="form-label">
                                Confirm Password
                            </label>
                            <input
                                type="password"
                                className="form-control"
                                name="Conform_password"
                                value={formValues.Conform_password}
                                required
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-md-6">
                            <label for="validationCustomUsername" className="form-label">
                                Upload your Logo
                            </label>
                            <input
                                type="file"
                                className="form-control"
                                aria-label="file example"
                                placeholder="No Logo choose"
                                name="image"
                                value={formValues.image}
                                required
                                onChange={handleChange}
                            />
                            <div className="invalid-feedback"> Please choose a Logo.</div>
                        </div>
                        <div className="col-md-6">
                            <label for="validationCustomUsername" className="form-label">
                                Upload File
                            </label>
                            <input
                                type="file"
                                className="form-control"
                                aria-label="file example"
                                name="file"
                                value={formValues.file}
                                required
                                onChange={handleChange}

                            />
                            <div className="invalid-feedback"> Please choose a File</div>
                        </div>

                        <div className="col-12">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""

                                />
                                <label className="form-check-label" for="invalidCheck">
                                    Agree to terms of use and Privacy Policy
                                </label>
                                <div className="invalid-feedback">
                                    You must agree before submitting.
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <button className="btn btn-primary" type="submit">
                                Sign Up
                            </button>
                            Or
                            Already have an account?<Link to="/login"/>Login<Link/>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default VendorRegister;

