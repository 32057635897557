import http from "../utils/http/http.utils";
import setFormData from "../utils/http/formdata.utils";

class VaccinationService {
    /**
     * Uploads a vaccination document.
     *
     * @param {Object} data - The data for the vaccination document.
     * @return {Promise} A promise that resolves to the result of the upload.
     */
    static async uploadVaccinationDocument(data) {
        const formData = setFormData(data)
        try {
            return http().post('api/parent/add-vaccination', formData)
        } catch (e) {
            console.log(e)
        }
    }
}

export default VaccinationService
