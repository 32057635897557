import React, {useContext} from 'react'
import ImgPlaceHolder from '../../../../images/receptionist.png'
import {Avatar, IconButton} from '@mui/material'
import PdfIcon from '../../../../images/pdf.png'
import {Visibility, Work} from '@mui/icons-material'
import {useQuery} from '@tanstack/react-query'
import {useSearchParams} from 'react-router-dom'
import NoDataUi from '../../../../images/nodata.jpg'
import {DateFormat} from '../../../DateFormat'
import FormModalContext from '../../../custom_componets/globalFormModal/FormModalContext'
import MedicalHistoryPDF from './MedicalHistoryPDF'
import Loader from '../../Loader'
import ParentService from "../../../../services/parent.service";
import Warning from "../../../error/warning";

function MedicalHistoryDetail() {
    const {openFormModal} = useContext(FormModalContext)
    const [params] = useSearchParams();

    const {data, isLoading} = useQuery({
        queryKey: ['medical-history-detail'],
        queryFn: () => ParentService.fetchMedicalHistoryDetail(params.get('member_id'), params.get('id')),
        enable: Boolean(params.get('member_id'))
    })


    if (isLoading) {
        return (
            <div className="d-flex justify-content-center p-4">
                <Loader/>
            </div>
        )
    }


    let doctor = data.doctor
    let showStatus = !(doctor === null && data.reject_reason != null)

    return (
        <div className="container">
            <Warning>
                * Please refer your original uploaded document in case of confusion or doubt.
            </Warning>
            <div className="row g-1 justify-content-center">
                {
                    (showStatus) && (
                        <div className="col-7">
                            <div className="card mb-2">
                                {
                                    data.doctor === null ?
                                        <div className="card-body" style={{width: '100%', aspectRatio: 2 / 1}}>
                                            <div
                                                className="w-100 h-100 d-flex justify-content-center align-items-center flex-column">
                                                <div>
                                                    <img src={NoDataUi} alt="No Data" style={{height: '140px'}}/>
                                                </div>

                                                <div>
                                                    <span className='fs-5 fw-light'>Doctor Not Assigned Yet.</span>
                                                </div>

                                            </div>
                                        </div>
                                        :
                                        <div className="card-body">
                                            <div className="row border-bottom pb-3">
                                                <div className="col-7">
                                                    <div className="d-flex align-items-center gap-3">
                                                          <Avatar src={doctor?.image_path || ImgPlaceHolder}/>
                                                        <div>
                                                            <div className="mb-1">
                                                                <span className="fw-bold fs-6">
                                                                    {doctor?.user.name}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-5 d-flex justify-content-end align-items-center">
                                                    <div className="btn btn-primary d-flex align-items-center gap-2">
                                                        <span className="fs-6">Give Review</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row py-3 g-3">
                                                <div className="col-6">
                                                    <div className='mb-1'>
                                                        <span className="fs-6 fw-light">
                                                            Address
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className="fs-6">
                                                            {doctor?.address || 'No Data'}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className='mb-1'>
                                                        <span className="fs-6 fw-light">
                                                            Qualification
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className="fs-6">
                                                            {doctor?.qualification || 'No Data'}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className='mb-1'>
                                                        <span className="fs-6 fw-light">
                                                            NMC Number
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className="fs-6">
                                                            {doctor?.nmc_no || 'No Data'}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className='mb-1'>
                                                        <span className="fs-6 fw-light">
                                                            Specialization
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className="fs-6">
                                                            {doctor?.specializtion || 'No Data'}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className='mb-1'>
                                                        <span className="fs-6 fw-light">
                                                            Experience
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className="fs-6">
                                                            {doctor?.year_practiced || 'No Data'}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className='mb-1'>
                                                        <span className="fs-6 fw-light">
                                                            Gender
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className="fs-6">
                                                            {doctor?.gender || 'No Data'}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                }

                            </div>
                            <div className="card mb-2">
                                <div>
                                    <div className="card-header">
                                        <span className="fw-bold fs-6">
                                            Observations
                                        </span>
                                        <br/>
                                        <span className='fw-light'>Your medical history based on the consultation</span>
                                    </div>
                                    <div className="card-body">
                                        {
                                            data.observation == null ?
                                                <span className="fs-6">No observation added yet.</span>
                                                :
                                                <p className="fs-6">
                                                    {data.observation}
                                                </p>
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="card mb-2">
                                <div>
                                    <div className="card-header">
                                        <span className="fw-bold fs-6">
                                            Findings
                                        </span>
                                        <br/>
                                        <span className='fw-light '>Your findings based on medical history</span>
                                    </div>
                                    <div className="card-body">
                                        {
                                            data.finding === null ?
                                                <span className="fs-6">No findings added yet.</span>
                                                :
                                                <p className="fs-6">
                                                    {data.finding}
                                                </p>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-2">
                                <div>
                                    <div className="card-header">
                                        <span className="fw-bold fs-6">
                                            Medication
                                        </span>
                                        <br/>
                                        <span className='fw-light'>Your medication based on your medical history</span>
                                    </div>
                                    <div className="card-body">
                                        {
                                            data.medication === null ?
                                                <span className="fs-6">No medications added yet.</span>
                                                :
                                                <p className="fs-6">
                                                    {data.medication}
                                                </p>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }


                <div className={showStatus ? 'col-5' : 'col-12'}>
                    <div className="row">
                        <div className={showStatus ? 'col-12' : 'col-6'}>
                            <div className='card mb-2'>
                                <div className="card-header">
                                    <span className="fw-bold fs-6">
                                        Medical History Status
                                    </span>
                                    <br/>
                                    <span className='fw-light'>Medical History Status details</span>
                                </div>
                                <div className="card-body border-bottom">
                                    <div className='mb-2 fs-6'>
                                        <span className='fw-light me-2'>Status: </span>
                                        {
                                            data.status === 0 || null ?
                                                <span className='badge badge-warning fs-6'>Pending</span>
                                                :
                                                data.status === 2 ?
                                                    <span className="badge badge-danger fs-6">Rejected</span>
                                                    :
                                                    <span className="badge badge-success fs-6">Approved</span>
                                        }
                                    </div>
                                    {
                                        data.status === 2 && (
                                            <>
                                                <div className="fs-6">
                                                    <span className="fw-light me-2">Reject Reason:</span>
                                                    <span className='text-danger'>{data.reject_reason}</span>
                                                </div>
                                                <div className="mb-2">
                                                    <span className="text-danger">* Please reupload your documents</span>
                                                </div>
                                            </>

                                        )
                                    }
                                    <div className="mb-2 d-flex gap-4 fs-6">
                                        <div>
                                            <span
                                                className='fw-light me-2'>Uploaded Date: </span><span>{DateFormat(data.created_at).getFullDateText()}</span>
                                        </div>
                                    </div>
                                </div>
                                {
                                    showStatus && (
                                        <div className="card-body">
                                            <div className="row g-2">
                                                <div className='col-6'>
                                                    <div className='mb-1'>
                                                        <span className="fw-light me-2">Doctor Name</span>
                                                    </div>
                                                    <div>
                                                        <span>{data.doctor_name ? `${data.doctor_name}` : 'No Doctor Added'}</span>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="mb-1">
                                                        <span className="fw-light me-2">Doctor NMC</span>
                                                    </div>
                                                    <div>
                                                        <span>
                                                            {data.doctor_nmc ? data.doctor_nmc : 'No Doctor Added'}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="mb-1">
                                                        <span className="fw-light me-2">Checked At</span>
                                                    </div>
                                                    <div>
                                                        <span>{data.hospital ? data.hospital : 'No Hospital Added'}</span>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="mb-1">
                                                        <span className="fw-light me-2">Checked Date</span>
                                                    </div>
                                                    <div>
                                                        <span>{data.check_date ? DateFormat(data.check_date).getFullDateText() : 'Check Date Not Added'}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }

                                {
                                    (data.department || data.is_other) && (
                                        <div className="card-body">
                                            <div className="mb-2">
                                                <span className="fw-light fs-6">
                                                    Suggested Department
                                                </span>
                                            </div>
                                            <div>
                                                <div className='fs-6 badge badge-info d-flex align-items-center gap-2'
                                                     style={{width: 'fit-content'}}>
                                                    <Work className='fs-6'/>
                                                    <span>
                                                        {
                                                            data.department ? data.department.department : data.is_other
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }

                            </div>
                        </div>
                        <div className={showStatus ? 'col-12' : 'col-6'}>
                            <div className='card mb-2'>
                                <div className="card-header">
                                    <span className="fw-bold fs-6">
                                        Medical Reports (PDF)
                                    </span>
                                    <br/>
                                    <span className='fw-light'>Your medical history reports you uploaded.</span>
                                </div>
                                <div className="card-body">
                                    {
                                        data?.medical_report?.map((item, idx) => (
                                            <div className="d-flex gap-2 align-items-center mb-3" key={item.id}>
                                                <div>
                                                    <img src={PdfIcon} alt="" style={{height: '40px'}}/>
                                                </div>
                                                <div className='flex-1'>
                                                    <div>
                                                        <span className="fs-6">
                                                            Medical History <span style={{fontWeight: 550}}
                                                                                  className='text-uppercase'>#{item.report.split('.')[0]}</span>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className="fw-light">
                                                            {DateFormat(item.created_at).getFullDateText()}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <IconButton onClick={() => openFormModal(<MedicalHistoryPDF
                                                        pdf={item?.report_path}/>)}>
                                                        <Visibility/>
                                                    </IconButton>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}


export default MedicalHistoryDetail
