import axios from '../../api'


export async function chkConditions(token, mainPackage) {

    try {
        let res = await axios.get("/api/admin/user-profile", {
            headers: {Authorization: `Bearer ${token}`},
        })
        // return {
        //     isChk: true,
        //     title: 'Success!',
        //     message: 'You can proceed.',
        //     status: 4,
        //     profile: res.data
        // };
        if (res.data.school_profile != null && res.data.school_profile.types == 'school' && mainPackage.id != 10) {
            if (res.data.school_profile.status === 'pending') {
                return {
                    isChk: false,
                    title: 'Package Booking!',
                    // message: 'You have a school profile and thus cannot buy another package',
                    message: 'You have submitted form for School Package Profile and thus cannot buy another package.',
                    status: 10
                }
            } else if (res.data.school_profile.status === 'verified') {
                return {
                    isChk: false,
                    title: 'Package Booking!',
                    message: 'Your School Package Profile has been created and thus cannot buy another package.'
                }
            } else {
                return {
                    isChk: false,
                    title: 'School Package',
                    message: 'Your submitted form for School Package Profile has been rejected. Please refill up the form.',
                    navigate: '/school/edit-corporate-profile',
                    status: 17
                }
            }

        }
        // if (res.data.school_profile != null) {
        //     return {
        //         isChk: true,
        //         title: 'Success!',
        //         message: 'You can proceed.',
        //         status: 4
        //     };
        // }
        if ((res.data.gender == null || res.data.dob == null)) {
            return {
                isChk: false,
                title: 'User Profile!',
                message: 'Please update your profile',
                navigate: '/user/profile/edit',
                status: 0
            }
        }
        if (res.data.member_type === 'Dependent Member') {
            return {
                isChk: false,
                title: 'Package Booking!',
                message: 'Dependent member cannot buy package',
                status: 1
            }
        }

        if (mainPackage.type === 3 && res.data.school_profile?.status === 'pending') {
            return {
                isChk: false,
                title: 'Info',
                message: 'Please wait until your company profile gets verified.',
                status: 7
            }
        }
        // if((mainPackage.type===1 || mainPackage.type===2) && res.data.school_profile!==null) {
        if (res.data.school_profile !== null && res.data.school_profile.status === 'verified') {
            if (!(mainPackage.type == 3 || mainPackage.type == 4)) {
                return {
                    isChk: false,
                    title: 'Package Booking!',
                    message: 'Since you have a company profile, you can only buy coroporate package.',
                    status: 9,
                }
            }
        }
        let res2 = await axios.get('/api/admin/userpackage', {headers: {"Authorization": `Bearer ${token}`}})



        if (res2.data.package != null) {
            if (res2.data.package.package_status === 'Not Booked') {
                if (mainPackage.type === 4) {
                    return {
                        isChk: false,
                        title: 'Package Booking!',
                        message: 'You have already booked a package',
                        status: 11
                    }
                } else {
                    return {
                        isChk: false,
                        title: 'Package Booking!',
                        message: 'You must discard previous package to buy this package.',
                        status: 3
                    }
                }

            } else {
                // (res2.data.package.package_status === 'Activated' ||)
                if (res2.data.package.package_status != "Expired") {
                    return {
                        isChk: false,
                        title: 'Package Booking!',
                        message: 'You have already booked a package.',
                        status: 8
                    }
                }
            }
        }

        if (mainPackage.type === 4) {
            if (res.data.school_profile === null) {
                return {
                    isChk: false,
                    title: 'School Package',
                    message: 'You are trying to buy school package. You need to fill some information to buy school package.',
                    navigate: '/school-profile',
                    status: 15
                }
            }
            if (res.data.school_profile.status === 'pending') {
                return {
                    isChk: false,
                    title: 'School Package',
                    message: 'You have submitted form for School Package Profile and is being verified by GD Admin.',
                    status: 16
                }
            }
            if (res.data.school_profile.status === 'rejected') {
                return {
                    isChk: false,
                    title: 'School Package',
                    message: 'Your submitted form for School Package Profile has been rejected. Please refill up the form.',
                    navigate: '/school/edit-corporate-profile',
                    status: 17
                }
            }
        }

        console.log(mainPackage.type)
        if (mainPackage.type === 3 && res.data.school_profile == null) {
            return {
                isChk: false,
                title: 'Info',
                message: 'In order to buy a corporate package you need to fill up your company profile.',
                navigate: '/company-profile-form',
                status: 6
            }
        }

        let res1 = await axios.get(`/api/admin/userpackage/calculate-amount/${mainPackage.id}`, {headers: {Authorization: `Bearer ${token}`}})
        if (res1.data.message !== 'success') {
            return {
                isChk: false,
                title: 'Package Booking!',
                message: res1.data.message,
                status: 2
            }
        }
        return {
            isChk: true,
            title: 'Success!',
            message: 'You can proceed.',
            status: 4,
            profile: res.data
        };
    } catch (error) {
        console.log(error)
        return {
            isChk: false,
            title: 'OOps!',
            message: 'Somethin Went Wrong',
            status: 5
        }
    }
}
