import React, {memo} from 'react';
import {useNavigate} from "react-router-dom";
import classNames from "classnames";
import {CircularProgress} from "@mui/material";

const FormAction = ({cancelPath, cancelText, submitText, handleSubmit, isLoading, spinnerColor, classes}) => {
    const navigate = useNavigate();

    const handleCancel = () => {
        if (cancelPath) {
            navigate(cancelPath)
        } else {
            navigate(-1)
        }
    };

    return (
        <div className={classNames("d-flex justify-content-end gap-3", classes)}>
            <button
                onClick={handleCancel} className="btn btn-danger">
                {cancelText || 'Cancel'}
            </button>
            <button className="btn d-flex align-items-center gap-2 btn-primary me-3">
                {isLoading && <CircularProgress size={18} color={spinnerColor}/>
                }
                {
                    isLoading ? 'Loading' : submitText || 'Submit'
                }
            </button>
        </div>
    );
};

export default memo(FormAction);
