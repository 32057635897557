import {Outlet, useLocation} from 'react-router-dom'
import Navbar from "../Navbar";
import Footer from "../Footer";
import FooterBottom from "../FooterBottom"
import Bot from '../chatbot/Bot';
import {WebLayoutProvider} from './WebLayoutProvider';
import WebFooter from './WebFooter';
import GDChatbot from '../customChatBot/GDChatbot';


const WebLayout = () => {
    const location = useLocation()

    return (
        <WebLayoutProvider>
            <div className="weblayout-style">
                <Navbar/>
                <Outlet/>
                {(location.pathname != "/login" && location.pathname != "/register" && location.pathname != "/become-partner" && location.pathname != "/forgot-password") ?
                    <WebFooter/> : <></>}
                {/* {(location.pathname=="/") ? <FooterBottom /> : <></>} */}
                <GDChatbot/>
            </div>
        </WebLayoutProvider>
    )
}

export default WebLayout
