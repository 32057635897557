import {Card, Grid} from '@mui/material'
import React, {useContext} from 'react'
import FitnessContainer from '../../fitnessCenter/components/FitnessContainer'
import FitnessProdCard from '../../fitnessCenter/components/FitnessProdCard'
import ProductGrid from '../components/ProductGrid'
import CatalogSlider from '../gdstorehome/components/CatalogSlider'
import FitnessPricingPlans from '../gdstorehome/components/FitnessPricingPlans'
import LabTestCatalog from '../gdstorehome/components/LabTestCatalog'
import PaymentCoupons from './advertisements/PaymentCoupons'
import ThreeAdvert from './advertisements/ThreeAdvert'
import BestSellingProducts from './components/BestSellingProducts'
import JustForYou from './components/JustForYou'
import VendorBrands from './components/VendorBrands'
import PromotionSlider from './customize_components/PromotionSlider'
import {GDVendorContext} from './GDVendorContext'

function GdVendorHome() {
    const {vendor} = useContext(GDVendorContext)
    return (
        <div className="container">
            <VendorBrands/>
            <Grid container>
                <Grid item xs={12}>
                    <PromotionSlider/>
                </Grid>
            </Grid>
            {/* <PaymentCoupons /> */}
            <CardCustom>
                <BestSellingProducts/>
            </CardCustom>
            <ThreeAdvert/>
            {
                vendor.vendor_type == 3 &&
                <FitnessPricingPlans vendor={vendor.id}/>
            }
            <CardCustom>
                <JustForYou/>
            </CardCustom>
        </div>
    )
}

function CardCustom({children}) {
    return (
        <Card sx={{my: 2}}>
            {children}
        </Card>
    )
}

export default GdVendorHome
