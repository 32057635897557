import {Typography} from '@mui/material'
import React from 'react'
import InfoModal from '../infoModal'

const InsuranceModal = () => {
    return (
        <>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Text in a modal
            </Typography>
            <Typography id="modal-modal-description" sx={{mt: 2}}>
                Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
            </Typography>
        </>
    )
}

const InsuranceInfo = () => {
    return (
        <InfoModal
            title="Click to view insurance informations."
            modal={<InsuranceModal/>}
        />
    )
}

export default InsuranceInfo
