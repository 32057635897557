import {Box} from '@mui/material'
import React from 'react'
import {DateFormat} from '../../../DateFormat'
import {Approval, Cancel, Close, HealingOutlined, Pending} from '@mui/icons-material'

function BecomePrimaryReqView({handleClose, data}) {
    return (
        <Box
            top='50%'
            left='50%'
            position='absolute'
            maxWidth='500px'
            width='100%'
            textAlign='left'
            sx={{
                transform: 'translate(-50%,-50%)'
            }}
        >
            <div className="card">
                <div className="card-header">
                    <span className="fs-5">Request detail</span>
                </div>
                <div className="card-body">
                    <div className="row g-2">
                        <div className="col-4">
                            <div>
                                <span className="fs-6 fw-light">
                                    Request Date
                                </span>
                            </div>
                            <div>
                                <span className="fs-6">
                                    {DateFormat(data.created_at).getFullDateText()}
                                </span>
                            </div>
                        </div>

                        <div className="col-4">
                            <div>
                                <span className="fs-6 fw-light">
                                    Family Relation
                                </span>
                            </div>
                            <div>
                                <span className="fs-6">
                                    {data.family_relation}
                                </span>
                            </div>
                        </div>

                        <div className="col-4">
                            <div>
                                <span className="fs-6 fw-light">
                                    Change Reason
                                </span>
                            </div>
                            <div>
                                <span className="fs-6">
                                    {data.change_reason}
                                </span>
                            </div>
                        </div>

                        <div className="col-4">
                            <div>
                                <span className="fs-6 fw-light">
                                    Death Case
                                </span>
                            </div>
                            <div>
                                {
                                    data.death_case === 0 ?
                                        <div className="badge bg-danger d-flex align-items-center gap-1"
                                             style={{width: 'fit-content'}}>
                                            <Close className='fs-6'/>
                                            <span className="fs-6 fw-light">
                                                False
                                            </span>
                                        </div>
                                        :
                                        <div className="badge bg-primary d-flex align-items-center gap-1"
                                             style={{width: 'fit-content'}}>
                                            <Approval className='fs-6'/>
                                            <span className="fs-6 fw-light">
                                                True
                                            </span>
                                        </div>
                                }

                            </div>
                        </div>

                        <div className="col-4">
                            <div>
                                <span className="fs-6 fw-light">
                                    Request Status
                                </span>
                            </div>
                            <div>
                                {
                                    data?.status == 0 ?
                                        <div className="badge bg-warning d-flex align-items-center gap-1"
                                             style={{width: 'fit-content'}}>
                                            <Pending className='fs-6'/>
                                            <span className='fs-6 fw-light'>Pending</span>
                                        </div>
                                        :
                                        data?.status === 1 ?
                                            <div className="badge bg-success d-flex align-items-center gap-1"
                                                 style={{width: 'fit-content'}}>
                                                <HealingOutlined className='fs-6'/>
                                                <span className='fs-6 fw-light'>Approved</span>
                                            </div>
                                            :
                                            <div className="badge bg-danger d-flex align-items-center gap-1"
                                                 style={{width: 'fit-content'}}>
                                                <Cancel className='fs-6'/>
                                                <span className='fs-6 fw-light'>Rejected</span>
                                            </div>
                                }
                            </div>

                        </div>

                        {
                            data?.status === 2 && (
                                <div className="col-12">
                                    <div><span className="fs-6 fw-light">Reject Reason</span></div>
                                    <div><span className="fs-6 text-danger fw-light">{data.reject_reason}</span></div>
                                </div>
                            )
                        }
                    </div>
                </div>
                {
                    data.death_case === 1 && (
                        <div className="card-body border-top">
                            <div className="fs-6 fw-light">
                                Death Certificate
                            </div>
                            <div className='w-100'>
                                <img src={data.death_certificate_path} alt="" style={{width: '100%'}}/>
                            </div>
                        </div>
                    )
                }

            </div>
        </Box>
    )
}

export default BecomePrimaryReqView
