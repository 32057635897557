import React, {useState, useEffect} from "react";
import axios from "../api.js";
import Pagination from "react-js-pagination";
import "../../components/stores/CategoryProduct.scss";
import {useNavigate, useSearchParams, useParams} from "react-router-dom";
import ProductCards from "./ProductCards.js";
import {getFromLocalStorage} from "../stores/LocalDatabaseQ";
import swal from "sweetalert";
import StoreFilterBar from "./StoreFilterBar";
import StoreVendorList from "./StoreVendorList";
import StoreNavVendor from "./StoreNavVendor";
import {StoreContext} from "../../contexts/StoreContext";
import Loader from "../pages/Loader";
import {ResizeListener} from "../custom_componets/RespTxtDiv.js";
import ProductSkeleton from "./ProductSkeleton.js";

const CategoryProducts = ({products, dispatch}) => {
    const [userId, setUserId] = useState(null);
    const [pageNum, setPageNum] = useState(1);

    const [cartCount, setCartCount] = useState(null);
    const [wishCount, setWishCount] = useState(null);
    const [wishlist, setWishlist] = useState([]);

    const [priceRange, setPriceRange] = useState([200, 1000]);
    const [searchKey, setSearchKey] = useState(null);

    const [searchParams, setSearchParams] = useSearchParams();
    const currentParams = Object.fromEntries([...searchParams]);

    const [filterToggleStatus, setFilterToggleStatus] =
        useState("collapse-filter");

    const storeContext = React.useContext(StoreContext);

    const navigate = useNavigate();

    const token = localStorage.getItem("token");

    let params = useParams();

    useEffect(() => {
        if (token !== null) {
            axios
                .get("/api/admin/user-profile", {
                    headers: {Authorization: `Bearer ${token}`},
                })
                .then((res) => setUserId(res.data.member.id))
                .catch((error) => {
                    console.log();
                });
        }
    }, [token]);

    const addToWishList = (productId) => {
        const data = {
            user_id: userId,
            product_id: productId,
        };

        let err = false;

        wishlist.map((wish) => {
            if (wish.product_id == productId) {
                swal(
                    "Already In The WishList",
                    "Product Already In Your Wishlist",
                    "error"
                );
                err = true;
                return;
            }
        });
        if (err) {
            return;
        }

        axios
            .post("api/admin/wishlist/addToWishlist", data, {
                headers: {Authorization: `Bearer ${token}`},
            })
            .then((res) => {
                swal("Added To Wishlist", "Product Added To WishList", "success");
                storeContext.setWishlistCount(wishCount + 1);
            })
            .catch((err) => console.log());
    };

    const changePage = (pgNum) => {
        setPageNum(pgNum);
        setSearchParams({...currentParams, page: pgNum});
    };

    const navigateToCart = () => {
        navigate("/cart");
    };

    const loadingRoller = () => {
        return (
            <div className="text-center">
                <Loader/>
            </div>
        );
    };

    const renderProductList = () => {
        if (products.loading) {
            // return loadingRoller();
            let productSkeleton = []
            for (let i = 0; i < 8; i++) {
                productSkeleton.push(
                    <div className="col-xl-3 col-md-4 col-6">
                        <ProductSkeleton/>
                    </div>
                )
            }
            return productSkeleton
        }
        let cardNum = 0;
        return (
            <>
                {products.data.data.map((product) => {
                    return (
                        <div className="col-xl-3 col-md-4 col-6">
                            <ProductCards
                                data={product}
                                setCartCount={setCartCount}
                                addToWishList={addToWishList}
                                setWishCount={setWishCount}
                                cardNum={cardNum++}
                                pageNum={pageNum}
                            />
                        </div>
                    );
                })}
            </>
        );
    };

    const getCurrentCat = () => {
        console.log();
    };

    const navigateToWishList = () => {
        navigate("/wishlist");
    };

    return (
        <>
            <div className="prod-grid-container">
                <div className="row g-4 mt-1">{renderProductList()}</div>
            </div>
            <div className="react-pagination mt-3 mb-5">
                <Pagination
                    activePage={products.current_page}
                    totalItemsCount={products.total}
                    itemsCountPerPage={products.per_page}
                    onChange={changePage}
                    itemClass="page-item"
                    linkClass="page-link"
                    firstPageText="First"
                    lastPageText="Last"
                />
            </div>
        </>
    );
};

export default CategoryProducts;
