import {DeleteForeverOutlined} from '@mui/icons-material'
import {Box, Card, CardMedia, Grid, IconButton, Stack, Typography} from '@mui/material'
import React from 'react'
import {useEffect} from 'react'
import {useRef} from 'react'
import swal from 'sweetalert'
import {useCart} from '../../../../contexts/provider/GDCartProvider'
import QuantityStepper from '../../stores/ProductPage/components/QuantityStepper'

function CartItem({item}) {
    const gdCart = useCart()
    const qtyRef = useRef(null)
    let totalWithoutDiscount = Number(item.sale_price) * Number(item.cartCount);
    let totalWithDiscount = totalWithoutDiscount - item.discountPercent / 100 * totalWithoutDiscount;

    function inputListener(value) {
        gdCart.updateCartQuantity(item, value)
    }

    function removeFromCart() {
        swal(
            {
                title: 'Are You Sure',
                text: 'Do you want to delete this item',
                icon: 'info',
            }
        ).then((data) => {
            if (data) {
                gdCart.deleteCart(item.id)
            }
        })
        // gdCart.deleteCart(item.id)
    }

    return (
        <Card variant='outlined'
              sx={{border: 0, borderBottom: 1, borderColor: 'divider', textAlign: 'left', lineHeight: 1.1, py: 1.5}}>
            <Grid container>
                <Grid item xs={3.5} md={2} p={2}>
                    <CardMedia
                        component='img'
                        image={item.image_path}
                        sx={{width: '100%', aspectRatio: '1/1', objectFit: 'contain'}}
                    />
                </Grid>
                <Grid item xs={8.5} md={10}>
                    <Stack direction={'row'} justifyContent='space-between' alignItems={'center'}>
                        <Typography variant='inherit' fontWeight={500}>
                            {item.productName}
                        </Typography>
                        <IconButton onClick={removeFromCart}>
                            <DeleteForeverOutlined color='error'/>
                        </IconButton>
                    </Stack>
                    <Typography my={1} variant='subtitle1' color='text.secondary'>{item.cartCount} {item.unit} X
                        रू {item.sale_price}</Typography>
                    <Stack direction={'row'} flexWrap='wrap' justifyContent='space-between' alignItems={'center'}>
                        <Box minWidth='250px'>
                            <Stack direction={'row'} alignItems='center'>
                                <Typography variant='subtitle1' color='text.secondary'
                                            fontWeight={550}>MRP <s>रू {totalWithoutDiscount}</s></Typography>
                                <Typography ml={2} variant='h6' fontWeight={600} color="primary">{item.discountPercent}%
                                    Off</Typography>
                            </Stack>
                            <Typography variant='h5' fontWeight={550}>रू {totalWithDiscount}</Typography>
                        </Box>
                        <div>
                            <QuantityStepper product={item.productName} qtyRef={qtyRef} inputListener={inputListener}
                                             defaultValue={item.cartCount} upperBound={item.stock}/>
                        </div>
                    </Stack>
                </Grid>
            </Grid>
        </Card>
    )
}

export default CartItem
