import * as yup from "yup";
import {FIELD_REQUIRED} from "../../../../../utils/constant/common";
import { CHEQUE_ONLY, COLLECTED, DEPOSITED, ONLINE_TRANSFER} from "../../../../../utils/constant/payment";

const PaymentValidationSchema = yup.object().shape({
    payment_type: yup.string().required(FIELD_REQUIRED),
    online_transfer: yup.array().when("payment_type", {
        is: ONLINE_TRANSFER,
        then: () => yup.array().of(yup.object().shape({
            transaction_source: yup.string().required(FIELD_REQUIRED),
            transaction_date: yup.string().required(FIELD_REQUIRED),
            amount_transferred: yup.string().required(FIELD_REQUIRED),
            transaction_receipt: yup.string().required(FIELD_REQUIRED),
        })),
        otherwise: schema => schema.optional(),
    }),
    cheques: yup.array().when("payment_type", {
        is: CHEQUE_ONLY,
        then: () => yup.array().of(yup.object().shape({
            cheque_number: yup.string().required(FIELD_REQUIRED),
            cheque_issue_date: yup.string().required(FIELD_REQUIRED),
            cheque_amount: yup.string().required(FIELD_REQUIRED),
            cheque_issue_bank: yup.string().required(FIELD_REQUIRED),

            cheque_status: yup.string().required(FIELD_REQUIRED),

            /*
            * if cheque status type is DEPOSITED
            * */
            depositor_name: yup.string().when('cheque_status', {
                is: DEPOSITED,
                then: () => yup.string().required(FIELD_REQUIRED),
                otherwise: schema => schema.optional(),
            }),
            deposited_date: yup.string().when('cheque_status', {
                is: DEPOSITED,
                then: () => yup.string().required(FIELD_REQUIRED),
                otherwise: schema => schema.optional(),
            }),
            deposited_voucher_scan: yup.string().when('cheque_status', {
                is: DEPOSITED,
                then: () => yup.string().required(FIELD_REQUIRED),
                otherwise: schema => schema.optional(),
            }),

           /*
           * else if  cheque status type is COLLECTED
           * */

            collect_on: yup.string().when('cheque_status', {
                is: COLLECTED,
                then: () => yup.string().required(FIELD_REQUIRED),
                otherwise: schema => schema.optional(),
            }),
        })),
        otherwise: schema => schema.optional(),
    }),
});

export {PaymentValidationSchema};
