import React, {useState} from 'react'
import axios from '../api.js';
import {Link, useNavigate, useLocation} from "react-router-dom";
import Logo from '../../images/white-logo.png';
import Background from '../../images/bg.png';
import '../../components/auth/Login.css'
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import swal from "sweetalert";
import {LogInContext} from "../../contexts/LogInContext"
import AuthButton from '../custom_componets/AuthButton.jsx';

function ResetPassword() {
    const location = useLocation();
    const urlParams = Object.fromEntries(new URLSearchParams(location.search))
    const [token, setToken] = useState();
    const [email, setEmail] = useState(urlParams.email && urlParams.email);
    const [password, setPassword] = useState();
    const [password_confirmation, setPwdConfirm] = useState();
    const [code, setCode] = useState();
    const [errorMsg, setErrorMsg] = useState();
    const [codeErrorMsg, setCodeErrorMsg] = useState();
    const [pswErrorMsg, setPswErrorMsg] = useState();
    const [passwordType, setPasswordType] = useState('password');
    const [submitting, setSubmitting] = useState(false)
    let navigate = useNavigate();

    const handlesubmit = async e => {
        e.preventDefault();
        setSubmitting(true)
        axios.post("api/change-password", {
            email: email,
            code: code,
            password: password,
            password_confirmation: password_confirmation
        })
            .then(res => {
                console.log()
                setSubmitting(false)
                swal({
                    title: "Password Reset successful!",
                    icon: "success",
                }).then((value) => {
                    if (value) {
                        navigate("/login")
                    }
                });
                if (res.data.errors) {
                    setPswErrorMsg(res.data.errors.password);
                    setCodeErrorMsg(res.data.errors.code);
                } else {
                    swal({
                        title: "Password Reset successful!",
                        icon: "success",
                    }).then((value) => {
                        if (value) {
                            navigate("/login")
                        }
                    });
                }
            })
            .catch((err) => {
                setSubmitting(false)
                setErrorMsg('Invalid Credentials');
                window.setTimeout(() => {
                    setErrorMsg(null);
                })
            })
    }

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    return (
        <div className="container-fluid login auth">
            <div className="row">
                <div className="col-lg-6 image-side px-0">
                    <img src={Background} alt="" className='bg-image'/>
                    <div className="image-overlay"></div>
                    <div className="text-center bg-logo mx-auto">
                        <img src={Logo} alt=""/>
                    </div>
                </div>
                <div className="col-lg-6 offset-lg-6 login-form">
                    <div className="card border-0">
                        <div>
                            <h3 className="card-title">Reset your password</h3>
                            <p>Please enter your new password for your account.</p>
                        </div>
                        <div className="card-body px-0">
                            {errorMsg &&
                                <div className="alert alert-danger alert-dismissible fade show">
                                    {errorMsg}
                                </div>
                            }
                            <form action="" method="post" onSubmit={handlesubmit}>
                                <div className="form-group">
                                    <label htmlFor="" className='form-label'>Email</label> <br/>
                                    <input type="email" name="email" className='form-control input-field' value={email}
                                           required onChange={e => setEmail(e.target.value)}
                                           placeholder="Please enter your email"/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="" className='form-label'>OTP Code</label> <br/>
                                    <input type="text" name="email" className='form-control input-field' required
                                           onChange={e => setCode(e.target.value)}
                                           placeholder="Please enter your OTP Code - (example: 450192)"/>
                                    {codeErrorMsg &&
                                        <div className="alert alert-danger alert-dismissible fade show py-1">
                                            {codeErrorMsg}
                                        </div>
                                    }
                                </div>
                                <div className="form-group">
                                    <label htmlFor="" className='form-label'>New Password</label>
                                    <button type="button" onClick={togglePassword} style={{
                                        border: 'none',
                                        backgroundColor: 'transparent',
                                        float: 'right',
                                        color: '#333'
                                    }}>
                                        {passwordType === "password" ? <FontAwesomeIcon icon={faEyeSlash}/> :
                                            <FontAwesomeIcon icon={faEye}/>}
                                    </button>
                                    <br/>
                                    <input type={passwordType} name="password" className='form-control input-field'
                                           required onChange={e => setPassword(e.target.value)}
                                           placeholder="Please input new password"/>
                                    {pswErrorMsg &&
                                        <div className="alert alert-danger alert-dismissible fade show py-1">
                                            {pswErrorMsg}
                                        </div>
                                    }
                                </div>
                                <div className="form-group">
                                    <label htmlFor="" className='form-label'>Confirm Password</label>
                                    <button type="button" onClick={togglePassword} style={{
                                        border: 'none',
                                        backgroundColor: 'transparent',
                                        float: 'right',
                                        color: '#333'
                                    }}>
                                        {passwordType === "password" ? <FontAwesomeIcon icon={faEyeSlash}/> :
                                            <FontAwesomeIcon icon={faEye}/>}
                                    </button>
                                    <br/>
                                    <input type={passwordType} name="password" className='form-control input-field'
                                           required onChange={e => setPwdConfirm(e.target.value)}
                                           placeholder="Please confirm your new password"/>
                                </div>
                                <div className='d-grid'>
                                    {/* <button type='submit' className='btn btn-primary login-btn'>Reset Password</button> */}
                                    <AuthButton
                                        type='submit'
                                        component='button'
                                        className='btn btn-primary login-btn'
                                        label='Reset Password'
                                        loading={submitting}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword
