import {ShoppingCartCheckout} from '@mui/icons-material'
import {Button, Card, CardContent, CardHeader, Stack, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import swal from 'sweetalert'

function CartSummary({data}) {
    const [summary, setSummary] = useState({
        total: 0,
        discountAmt: 0,
        deliveryCharge: 0,
        totalPayable: 0
    })
    const navigate = useNavigate()
    useEffect(() => {
        let total = 0, discountAmt = 0, deliveryCharge = 0, totalPayable = 0
        data.forEach((item, idx) => {
            total += Number(item.sale_price) * Number(item.cartCount)
            discountAmt += Number(item.cartCount) * Number(item.discountPercent / 100) * Number(item.sale_price)
        })
        setSummary({
            total: total,
            discountAmt: discountAmt,
            totalPayable: total - discountAmt
        })
    }, [data])
    return (
        <Card elevation={2}>
            <CardHeader title={'Cart Summary'}/>
            <CardContent>
                <Stack direction='row' justifyContent="space-between" alignItems='center'>
                    <Typography variant="subtitle1" color='text.secondary'>Total Price</Typography>
                    <Typography variant="subtitle1">NPR. {summary.total}</Typography>
                </Stack>
                <Stack direction='row' justifyContent="space-between" alignItems='center'>
                    <Typography variant='subtitle1' color='text.secondary'>Discount on Total Price</Typography>
                    <Typography variant="subtitle1">NPR. {summary.discountAmt}</Typography>
                </Stack>
                <Stack direction='row' justifyContent="space-between" alignItems='center' my={1}>
                    <Typography variant='subtitle1' color='text.secondary'>Delivery Charge</Typography>
                    <Typography variant="subtitle1">Calculated on Checkout</Typography>
                </Stack>
                <Stack direction='row' justifyContent="space-between" alignItems='center' pt={1}
                       sx={{borderTop: 1, borderColor: 'divider'}}>
                    <Typography variant='subtitle1' color='text.secondary'>Total Payable Amount</Typography>
                    <Typography variant="h5">NPR. {summary.totalPayable}</Typography>
                </Stack>
                <Button variant='contained' sx={{width: '100%', mt: 2}} onClick={() => {
                    if (summary.totalPayable > 500) {
                        navigate('/gd-store/checkout')
                        return
                    }
                    swal(
                        'Checkout',
                        'You must purchase items of more than NPR 500 to checkout',
                        'error'
                    )
                }}>
                    Proceed To Checkout
                </Button>
            </CardContent>
        </Card>
    )
}

export default CartSummary
