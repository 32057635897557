import {AttachMoney, ContactMail, RateReview, SettingsInputComponent} from '@mui/icons-material';
import {Stack, Step, StepConnector, stepConnectorClasses, StepLabel, Stepper, styled} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react'

const ColorlibConnector = styled(StepConnector)(({theme}) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({theme, ownerState}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor: '#52C8F4'
    }),
    ...(ownerState.completed && {
        backgroundColor: '#0D59A8'
    }),
}));

function ColorlibStepIcon(props) {
    const {active, completed, className} = props;

    const icons = {
        1: <ContactMail/>,
        2: <AttachMoney/>,
        3: <RateReview/>,
    };

    return (
        <ColorlibStepIconRoot ownerState={{completed, active}} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

const steps = [
    'Address Details',
    'Payment',
    'Confirmation',
];

function CustomStepper({children}) {
    return (
        <Stack sx={{width: '100%'}} spacing={4}>
            <Stepper atlternativeLabel activeStep={1} connector={<ColorlibConnector/>}>
                {
                    steps.map((step) => (
                        <Step key={step}>
                            <StepLabel StepIconComponent={ColorlibStepIcon}>{step}</StepLabel>
                        </Step>
                    ))
                }
            </Stepper>
        </Stack>
    )
}

export default CustomStepper
