import {useQuery} from '@tanstack/react-query'
import React, {useContext} from 'react'
import FitnessContainer from '../../../fitnessCenter/components/FitnessContainer'
import ProductGrid from '../../components/ProductGrid'
import {GDVendorContext} from '../GDVendorContext'
import axios from '../../../../api'


function JustForYou() {
    const {vendor} = useContext(GDVendorContext)
    const {data, isLoading} = useQuery(
        ['bestSelling', vendor.id],
        async () => (
            axios.get('/api/products?vendor_id=' + vendor.id).then(res => res.data).catch(err => err)
        )
    )
    if (isLoading) {
        return
    }
    if (data.data.length === 0) {
        return
    }
    return (
        <FitnessContainer title="Just For You">
            <ProductGrid data={data.data} xsCount={6} smCount={4} lgCount={2.4}/>
        </FitnessContainer>
    )
}

export default JustForYou
