import React from 'react'
import BookingDetails from './BookingDetails'
import PaymentDetails from './PaymentDetails'

const TestBooking = ({labDetails, userData}) => {
    return (
        <>
            <div className="card border-0">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-6">
                            <h3 style={{fontWeight: '700'}} className="title_color">{labDetails.labtest.tests}</h3>
                            <p className='mb-0'><span
                                style={{fontWeight: '600'}}>Lab Department :</span> {labDetails.labtest.labdepartment.department}
                            </p>
                            {labDetails.labtest.profile_id !== null ? <p className='mb-0'><span
                                style={{fontWeight: '600'}}>Lab Profile :</span> {labDetails.labtest.labprofile.profile_name}
                            </p> : ''}
                            <p className='mb-0'><span
                                style={{fontWeight: '600'}}>Code :</span> {labDetails.labtest.code}</p>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <BookingDetails labDetails={labDetails}/>
                        <PaymentDetails labDetails={labDetails}/>
                    </div>
                </div>
            </div>
            {labDetails !== null && labDetails.reports.length !== 0 &&
                <>
                    <hr className='line mt-3'/>
                    <div className="card">
                        <div className="card-header">
                            <h3 className="title_color card-title" style={{fontWeight: '700'}}>
                                Test Report
                            </h3>
                        </div>
                        <div className="card-body">
                            <div className='border p-3'>
                                <div className="d-flex justify-content-between mb-3">
                                    <div>
                                        <p><span className='fontStyle'>Name : </span>{userData.member.name}</p>
                                        <p><span
                                            className='fontStyle'>Gender : </span>{userData.gender ? userData.gender : ''}
                                        </p>
                                        <p><span
                                            className='fontStyle'>Age : </span> {userData.dob ? new Date().getFullYear() - userData.dob.slice(0, 4) : ''}
                                        </p>
                                    </div>
                                    <div>
                                        <p><span className='fontStyle'>Sample No. : </span>{labDetails.sample_no}</p>
                                        <p><span className='fontStyle'>Sample Date : </span>{labDetails.sample_date}</p>
                                        <p><span className='fontStyle'>Reporting Date : </span>{labDetails.report_date}
                                        </p>
                                    </div>
                                </div>
                                {labDetails.labtest.test_result_type === "Range" &&
                                    <table className="table table-borderless border text-center">
                                        <thead className=''>
                                        <tr>
                                            <th className='fontStyle' width="25%">Test</th>
                                            <th className='fontStyle' width="25%">Result</th>
                                            <th className='fontStyle' width="25%">Units</th>
                                            <th className='fontStyle' width="25%">Reference Range</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td colSpan="4"
                                                className='fontStyle'>{labDetails.labtest.labdepartment.department} Report
                                            </td>
                                        </tr>
                                        {labDetails.reports.map((report) =>
                                            <tr key={report.id}>
                                                <td className='fontStyle'>{labDetails.labtest.tests}</td>
                                                <td>{report.value}</td>
                                                <td>{labDetails.labtest.unit}</td>
                                                <td>{report.min_range} - {report.max_range}</td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                }
                                {labDetails.labtest.test_result_type === "Value" &&
                                    <>
                                        <h6 className='fontStyle'>Test Name: {labDetails.labtest.tests}</h6>
                                        <table className="table table-borderless border text-center">
                                            <thead className=''>
                                            <tr>
                                                <th className='fontStyle' width="50%">Test Particulars</th>
                                                <th className='fontStyle' width="50%">Result</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td colSpan="4"
                                                    className='fontStyle'>{labDetails.labtest.labdepartment.department} Report
                                                </td>
                                            </tr>
                                            {labDetails.reports.map((report) =>
                                                <tr key={report.id}>
                                                    <td className='fontStyle'>{report.labvalue.result_name}</td>
                                                    <td>{report.result}</td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                    </>
                                }
                                {labDetails.labtest.test_result_type === "Image" &&
                                    <div className="row">
                                        <h6 className='fontStyle'>Test Name: {labDetails.labtest.tests}</h6>
                                        <div className="col-md-6">
                                            {labDetails.reports.map((report) =>
                                                <div key={report.id}>
                                                    <iframe src={report.report_path} width="100%" height="400px"
                                                            title={labDetails.labtest.tests}></iframe>
                                                    View Full Test Report <a href={report.report_path} target="_blank"
                                                                             rel="noreferrer">Click here</a>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                }
                                {labDetails.labtest.test_result_type === "Value and Image" &&
                                    <>
                                        <h6 className='fontStyle'>Test Name: {labDetails.labtest.tests}</h6>
                                        <table className="table table-borderless border text-center">
                                            <thead className=''>
                                            <tr>
                                                <th className='fontStyle' width="50%">Test Particulars</th>
                                                <th className='fontStyle' width="50%">Result</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td colSpan="4"
                                                    className='fontStyle'>{labDetails.labtest.labdepartment.department} Report
                                                </td>
                                            </tr>
                                            {labDetails.reports.filter(val => val.report === null).map((report) =>
                                                <tr key={report.id}>
                                                    <td className='fontStyle'>{report.labvalue.result_name}</td>
                                                    <td>{report.result}</td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                        <div className="row mt-3">
                                            <div className="col-md-6">
                                                {labDetails.reports.filter(val => val.report !== null).map((report) =>
                                                    <div key={report.id}>
                                                        <iframe src={report.report_path} width="100%" height="400px"
                                                                title={labDetails.labtest.tests}></iframe>
                                                        View Full Test Report <a href={report.report_path}
                                                                                 target="_blank" rel="noreferrer">Click
                                                        here</a>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className='mt-3'>
                                    Processed By:
                                    <h6 className='fontStyle mb-0'>
                                        {labDetails.lab.user.name}
                                    </h6>
                                    <span className='fontStyle'>{labDetails.lab.subrole.subrole}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }

        </>
    )
}

export default TestBooking
