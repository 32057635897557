import {VideoCallOutlined} from '@mui/icons-material';
import {Box, Tooltip} from '@mui/material'
import React, {createContext, useContext, useEffect, useRef, useState} from 'react'

let maxHeightTop = 0;
let maxHeightBottom = 0;
let activeNode = null;

let active_color = '#42A5F5'

const ActiveColorContext = createContext()

export function UserTimeline({children}) {
    maxHeightTop = 0;
    maxHeightBottom = 0;
    const upperRef = useRef()
    const bottomRef = useRef()
    const [activeColor, setActiveColor] = useState(active_color)

    useEffect(() => {
        upperRef.current.style.height = `${maxHeightTop}px`
        bottomRef.current.style.height = `${maxHeightBottom}px`
        // console.log()
        // activeNode.scrollIntoView()
    }, [])

    return (
        <ActiveColorContext.Provider value={{activeColor, setActiveColor}}>
            <Box overflow='auto' width='100%'>
                <Box className='node-upper' ref={upperRef}/>
                <Box display='flex' alignItems='center' flexWrap='no-wrap'>
                    {children}
                </Box>
                <Box className='node-lower' ref={bottomRef}/>
            </Box>
        </ActiveColorContext.Provider>
    )
}

export function TimeNode({
                             active = false,
                             pending = false,
                             children,
                             radius = 50,
                             icon = null,
                             tipTitle = '',
                             error = false,
                             skipped = false,
                             skipReason = null
                         }) {
    const {setActiveColor} = useContext(ActiveColorContext)
    // console.log('Skipped')
    useEffect(() => {
        if (error) {
            setActiveColor('#ff3333')
        }
    }, [])
    return (
        <Box position='relative' maxWidth='200px' minWidth='150px' display='flex' alignItems={'center'}>
            <NodeConnector active={active} pending={pending} skipReason={skipReason}/>
            <Node active={active} pending={pending} radius={radius} icon={icon} tipTitle={tipTitle}
                  skipReason={skipReason}>
                {children}
            </Node>
        </Box>
    )
}

function Node({active, pending, radius, icon, children, tipTitle, skipReason = null}) {
    const Component = icon
    const activeRef = useRef()
    const {activeColor} = useContext(ActiveColorContext)
    useEffect(() => {
        if (!active) {
            activeNode = activeRef.current
        }
    })
    // console.log() * radius)
    return (
        <Tooltip title={active ? tipTitle : ''}>
            <Box
                ref={activeRef}
                width={radius + 'px'}
                height={radius + 'px'}
                flexGrow={0}
                flexShrink={0}
                borderRadius='50%'
                bgcolor={active ? activeColor : pending ? '#FF9800' : skipReason == null ? '#D7D7D7' : 'error.main'}
                display='flex'
                justifyContent='center'
                alignItems='center'
                sx={{cursor: 'pointer'}}
            >
                {icon && <Component sx={{color: 'white', fontSize: `${(4 * radius) / 5}px`}}/>}
                {/* <VideoCallOutlined sx={{color:'white', fontSize:'40px'}}/> */}
                {children}
            </Box>
        </Tooltip>
    )
}

function NodeConnector({active, pending, skipReason}) {
    const {activeColor} = useContext(ActiveColorContext)
    return (
        <Box
            flexGrow={1}
            flexShrink={0}
            height={5}
            bgcolor={active ? activeColor : pending ? '#FF9800' : skipReason == null ? '#D7D7D7' : 'error.main'}
        />
    )
}

export function NodeContent({children}) {
    const contentRef = useRef()
    useEffect(() => {
        if (contentRef.current.clientHeight > maxHeightBottom) {
            maxHeightBottom = contentRef.current.clientHeight
            // console.log()
        }
    }, [])
    return (
        <Box
            position='absolute'
            top='100%'
            width='100%'
            ref={contentRef}
        >
            {children}
        </Box>
    )
}

export function NodeAlternateContent({children}) {
    const contentRef = useRef()
    useEffect(() => {
        if (contentRef.current.clientHeight > maxHeightTop) {
            maxHeightTop = contentRef.current.clientHeight
        }
    }, [])
    return (
        <Box
            position='absolute'
            bottom='100%'
            width='100%'
            ref={contentRef}
        >
            {children}
        </Box>
    )
}
