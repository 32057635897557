import {faCircle} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React, {useContext, useEffect} from 'react'
import axios from "../../../api.js";
import {LoginStateContext} from '../../../../contexts/LoginStateContext'


function NotificationList({data, refetch}) {
    const loginContext = useContext(LoginStateContext)

    if (data.length == 0) {
        return (
            <div className="alert alert-danger mt-2">
                <p>There are no notifications.</p>
            </div>
        )

    }


    function handleClick(id, type) {

        axios.get("api/admin/notification/" + type + "/" + id, {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})

            .then(res => {
                refetch();
            })
            .catch(err => err)


    }

    return (
        <>
            {
                data.map((item) => {
                    return (<div className="notifications-list">
                        <div className="row" type='submit' onClick={(e) => {
                            e.preventDefault();
                            handleClick(item.id, item.type)
                        }}>
                            <div className="col-1">
                                <div className="notification-img">
                                    <img src={item.image} alt="profile pic"/>
                                </div>
                            </div>
                            <div className="col-10">
                                <div className="notification-content ms-4">
                                    <p>{item.title} </p>


                                    <p>{item.body}</p>
                                    <p>
                                        <span className="text-muted">
                                            24 Dec, 2020 at 16:15
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div className="col-1">
                                {item.watched === "unseen" ?
                                    <FontAwesomeIcon icon={faCircle} size="xs" className='text-primary'/> : ""}
                            </div>
                        </div>
                    </div>)
                })
            }
        </>
    )
}

export default NotificationList
