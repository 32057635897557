import {useQuery} from '@tanstack/react-query'
import React, {useState} from 'react'
import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import axios from '../../../api'
import LabProfile from './LabProfile'
import LabTests from './LabTests'

function LabTestContent({department, children}) {
    const {data, isLoading, refetch} = useQuery(
        ['testsLab'],
        async () => (
            axios.get(`/api/lab-test/tests/${department}`).then(res => res.data)
        )
    )
    useEffect(() => {
        refetch()
    }, [department])
    if (isLoading) {
        return
    }
    console.log()
    return (
        <>
            <LabProfile data={data.labprofiles}/>
            <LabTests data={data.labtests}/>
        </>
    )
}

export default LabTestContent
