import React, {useState} from 'react'
import axios from '../../../api.js';
import swal from 'sweetalert';
import Modal from 'react-bootstrap/Modal';
import {useForm} from 'react-hook-form';

const LeaveRequests = ({leave, token, leavefamilyQuery, familyQuery}) => {
    const [show4, setShow4] = useState(false);
    const {
        register: register4,
        handleSubmit: handleSubmit4,
        resetField: resetField4,
    } = useForm({
        defaultValues: {
            reject_reason: ""
        }
    });
    const handleClose4 = () => {
        setShow4(false);
        resetField4("reject_reason");
    };
    const handleShow4 = () => setShow4(true);

    const onSubmit4 = (data) => {
        axios.post(`/api/admin/family/leave-request/reject/${data.id}`, data, {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.success) {
                    setShow4(false);
                    resetField4("reject_reason");
                    swal({
                        title: res.data.success,
                        icon: "success",
                    }).then((value) => {
                        if (value) {
                            leavefamilyQuery.refetch();
                            familyQuery.refetch();
                        }
                    });
                }
            })
            .catch((err) => {
                console.log();
            })
    }
    const acceptLeaveRequest = (id) => {
        if (token !== null) {
            axios.post('/api/admin/family/leave-request/approve', {id: id}, {headers: {"Authorization": `Bearer ${token}`}})
                .then(res => {
                    swal({
                        title: res.data.success,
                        icon: "success",
                    }).then((value) => {
                        if (value) {
                            leavefamilyQuery.refetch();
                            familyQuery.refetch();
                        }
                    });
                })
                .catch((error) => {
                    console.log()
                });
        }
    }

    return (
        <table className="table table-bordered">
            <thead>
            <tr>
                <th>Family Member Name</th>
                <th>Phone No.</th>
                <th>Leave Reason</th>
                <th>Action/Remarks</th>
            </tr>
            </thead>
            <tbody>
            {leave.length !== 0 &&
                leave.map((item) =>
                    <tr key={item.id}>
                        <td>{item.member.user.name}</td>
                        <td>{item.member.user.phone}</td>
                        <td>{item.leave_reason}</td>
                        <td>
                            {item.status === 0 &&
                                <>
                                    <button className="btn btn-success btn-sm mr-2"
                                            onClick={() => acceptLeaveRequest(item.id)}>Accept
                                    </button>
                                    <button className="btn btn-danger btn-sm" onClick={handleShow4}>Reject</button>
                                    <Modal show={show4} onHide={handleClose4}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Reject Leave Family Request</Modal.Title>
                                        </Modal.Header>
                                        <form onSubmit={handleSubmit4(onSubmit4)}>
                                            <Modal.Body>
                                                <input type="hidden" {...register4("id")} value={item.id}/>
                                                <div className="form-group">
                                                    <label htmlFor="">Reject Reason</label>
                                                    <textarea type="text" {...register4("reject_reason")}
                                                              className="form-control" required></textarea>
                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer className="dashboard-style">
                                                <button className='btn btn-secondary mr-2' onClick={handleClose4}>
                                                    Close
                                                </button>
                                                <button className='btn btn-primary' type="submit">
                                                    Save Changes
                                                </button>
                                            </Modal.Footer>
                                        </form>
                                    </Modal>
                                </>
                            }
                            {item.status === 1 &&
                                <span className='badge badge-success'>Approved (Please wait for admin approval.)</span>
                            }
                            {item.status === 3 &&
                                <span className='badge badge-success'>Approved</span>
                            }
                            {item.status === 2 || item.status === 4 ?
                                <>
                                    <span className='badge badge-danger'>Rejected</span>
                                    <p className='text-danger mb-0'><b>Reject Reason:</b> {item.reject_reason}</p>
                                </>
                                :
                                ''
                            }
                        </td>
                    </tr>
                )
            }
            </tbody>
        </table>
    )
}

export default LeaveRequests
