import {Box, Card, CardContent, CardHeader, Step, StepLabel, Stepper} from '@mui/material';
import React from 'react'
import {useParams} from 'react-router-dom';
import CustomStepper from './CustomStepper';


const steps = [
    'Address Details',
    'Payment',
    'Confirmation',
];

function CheckoutPage() {
    const params = useParams()

    return (
        <Box className="container" my={1}>
            <Card elevation={0}>
                <CardHeader title="Checkout"/>
                <CustomStepper>

                </CustomStepper>
            </Card>
        </Box>
    )
}

export default CheckoutPage
