import axios from '../../api.js';
import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form';
import {useLocation, useNavigate} from 'react-router-dom';
import '../../../components/pages/ChooseDate/BookingForm.css'
import HomeIcon from '../../../images/home-icon.png'
import Doctor from '../../../images/doctor.png'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faCaretRight, faClock} from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import Loader from '../Loader.js';
import {feetInchText} from '../../../helperFunctions/meterToFeetInch.js';
import {useChkLogin} from '../../../middlewares/LoginMiddleware.js';

const BookingForm = () => {
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const doctor_id = location.state.doctor_id;
    const slug = location.state.slug;
    const image = location.state.image;
    const doctor_name = location.state.doctor_name;
    const salutation = location.state.salutation;
    const department = location.state.department;
    const experience = location.state.experience;
    const date = location.state.date;
    const slot_id = location.state.slot_id;
    const slot = location.state.timeSlot;
    const fee = location.state.fee;
    const service_type = location.state.service_type;
    const {token} = useChkLogin()
    const [user, setUser] = useState(null);
    let navigate = useNavigate();

    console.log("I was here")

    useEffect(() => {
        axios.get('/api/admin/user-profile', {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                //console.log();
                setUser(res.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log()
            });
    }, [token])

    const {
        register,
        handleSubmit,
    } = useForm();


    const onSubmit = (data) => {
        console.log()
        axios.post('/api/admin/booking-review/review', data, {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                swal({
                    title: "Appointment Booked!",
                    icon: "success",
                    button: "Proceed for Payment",
                }).then((value) => {
                    if (value) {
                        navigate("/book-doctor/payment", {state: {fee: fee, id: res.data[0].id}})
                    }
                });
            })
            .catch((err) => {
                console.log();
            })
    }

    const changeDate = (doctor_id, slug) => {
        navigate(`/doctor/${slug}`, {state: {id: doctor_id}});
    }

    return (
        <>
            {loading ?
                <div className="text-center">
                    <Loader/>
                </div>
                :
                <div className="booking-form">
                    <div className="container py-5">
                        <div className="card">
                            {user &&
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="d-flex flex-wrap">
                                        <div className="col-md-5 doctor-profile flex-fill">
                                            <div className="card-header d-flex align-items-center py-3 booking-heading">
                                                <img src={HomeIcon} alt=""/>
                                                <span className='ms-3'>Online Consultation</span>
                                            </div>
                                            <div className="card-body px-0 pt-0">
                                                <div
                                                    className="d-flex py-4 flex-wrap justify-content-center profile-description">
                                                    <img src={image} alt="" className="doctor-image"/>
                                                    <div className='ms-4'>
                                                        <h5>{salutation} {doctor_name}</h5>
                                                        <ul className="list-unstyled mb-4 ms-3">
                                                            <li><FontAwesomeIcon icon={faCaretRight}
                                                                                 className="me-2"/>{department}</li>
                                                            <li><FontAwesomeIcon icon={faCaretRight}
                                                                                 className="me-2"/>Experience: {experience} years
                                                            </li>
                                                            <li><FontAwesomeIcon icon={faCaretRight} className="me-2"/>Consultation
                                                                Fee: Rs.{fee}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className='px-3 py-3 profile-description'>
                                                    <div className="d-flex justify-content-between">
                                                        <div className="d-flex">
                                                            <FontAwesomeIcon icon={faCalendar}
                                                                             style={{color: '#B0D0ED'}}
                                                                             className="mt-1"/>
                                                            <p className='ms-2'>On <span
                                                                style={{fontWeight: '600'}}>{date}</span></p>
                                                        </div>
                                                        <div className="d-flex">
                                                            <FontAwesomeIcon icon={faClock} style={{color: '#B0D0ED'}}
                                                                             className="mt-1"/>
                                                            <p className='ms-2'>At <span
                                                                style={{fontWeight: '600'}}>{slot} </span></p>
                                                        </div>
                                                    </div>
                                                    <div className="d-grid">
                                                        <button type="button" className='btn btn-primary py-2'
                                                                onClick={() => changeDate(doctor_id, slug)}>Change
                                                            Date &amp; Time
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-7 flex-fill patient-details">
                                            <div className="card-header border-0 py-3">
                                                <h4>Patient Information</h4>
                                            </div>
                                            <div className="card-body pt-0 px-1 form">
                                                <div className="row g-2">
                                                    <input type="hidden" {...register("doctor_id")} value={doctor_id}/>
                                                    <input type="hidden" {...register("booking_id")} value={slot_id}/>
                                                    <input type="hidden" {...register("doctor_service_type")}
                                                           value={service_type}/>
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <label className="form-label">Full Name</label>
                                                                <p>{user.member.name}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <label className="form-label">Phone Number</label>
                                                                <p>{user.member.phone}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <label className="form-label">Your Email</label>
                                                                <p>{user.member.email}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <label className="form-label">Address</label>
                                                                <p>{user.address}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <label className="form-label">Date of Birth</label>
                                                                <p>{user.dob}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <label className="form-label">Age</label>
                                                                <p>{user.dob ? new Date().getFullYear() - user.dob.slice(0, 4) : ''}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <label className="form-label">Gender</label>
                                                                <p>{user.gender}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <label className="form-label">Blood Group</label>
                                                                <p>{user.blood_group}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <label className="form-label">Weight</label>
                                                                <p>{user.weight} kg</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <label className="form-label">Height</label>
                                                                <p>{feetInchText(user.height)}</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 border-0">
                                                        <label className="form-label mb-2">Your Health Condition/ Your
                                                            Message</label>
                                                        <textarea className='form-control mb-2'
                                                                  rows="5" {...register("messages")} required
                                                                  placeholder='Write about your health condition or any other message .....'>

                                                    </textarea>
                                                    </div>
                                                    <div className="col-md-12 text-end border-0">
                                                        <button type="submit" className='btn btn-primary py-2 px-3'>Book
                                                            Appointment
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default BookingForm
