import React, {useContext} from 'react'
import {ProductContext} from '../ProductDetPage'

function ProdLoadVerify({children}) {
    const productCont = useContext(ProductContext)

    if (productCont.isLoading) {
        return <h4>Loading...</h4>
    }
    return (
        <>
            {children}
        </>
    )
}

export default ProdLoadVerify
