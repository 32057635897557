import {Box, CircularProgress, Skeleton, Typography} from '@mui/material'
import React from 'react'
import {useBlogMenu} from './GDBlogProvider'
import {useNavigate} from 'react-router-dom'

function BlogMenus() {
    const {data, isLoading} = useBlogMenu()

    if (isLoading) {
        return (
            <Box
                display='flex'
                alignItems='center'
                // justifyContent={'center'}
                flexWrap={'nowrap'}
                gap={1}
                sx={{
                    '& > div': {
                        borderRadius: 1,
                        cursor: 'pointer',
                        flexShrink: 0
                    }
                }}
            >
                {
                    [...Array(5).keys()].map((item) => (
                        <Skeleton variant="rectangular" width='100px' height='40px' key={item}/>
                    ))
                }
            </Box>
        )
    }
    return (
        <Box
            display='flex'
            alignItems='center'
            // justifyContent={'center'}
            flexWrap={'nowrap'}
            gap={1}
            sx={{
                '& > div': {
                    borderRadius: 1,
                    cursor: 'pointer',
                    flexShrink: 0
                }
            }}
        >
            <BlogMenuItem label='Home' link='/blog'/>
            {
                data.map((item, idx) => (
                    <BlogMenuItem label={item.title_en} link={`/blog/menu/${item.id}`}/>
                ))
            }
        </Box>
    )
}

function BlogMenuItem({label, link}) {
    const navigate = useNavigate()
    const location = window.location

    const active = location.pathname === link

    let style = {
        bgcolor: '#f3fafe',
        boxShadow: 'inset -5px -5px 9px rgba(255,255,255,0.45), inset 5px 5px 9px rgba(94,104,121,0.3)',
        px: 2,
        py: 0.5
    }

    if (active) {
        style = {
            ...style,
            bgcolor: 'primary.main',
            boxShadow: '-10px -10px 15px rgba(255,255,255,0.5),10px 10px 15px rgba(70,70,70,0.12)',
            color: '#fff'
        }
    }

    function handleClick() {
        navigate(link)
    }

    return (
        <Box {...style} onClick={handleClick}>
            <Typography variant='subtitle1'>
                {label}
            </Typography>
        </Box>
    )
}

export default BlogMenus
