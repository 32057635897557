import React from 'react'

const VerifyKhaltiPayment = () => {
    const search = window.location.search 

    const searchParam = new URLSearchParams(search)

    const searchObj = Object.fromEntries(searchParam)

    console.log('Search Obj', searchObj)
  return (
    <div>VerifyKhaltiPayment</div>
  )
}

export default VerifyKhaltiPayment