import React from 'react'
import FitnessContainer from '../../../fitnessCenter/components/FitnessContainer'
import FitnessProdCard from '../../../fitnessCenter/components/FitnessProdCard'
import {settings5items} from '../../sliderSettings/View5itemsOnlg'
import CatalogSlider from './CatalogSlider'

function FastSelling() {
    return (
        <FitnessContainer title={"Fast Selling Products"}>
            <CatalogSlider settingProps={settings5items}>
                <div>
                    <FitnessProdCard/>
                </div>
                <div>
                    <FitnessProdCard/>
                </div>
                <div>
                    <FitnessProdCard/>
                </div>
                <div>
                    <FitnessProdCard/>
                </div>
                <div>
                    <FitnessProdCard/>
                </div>
                <div>
                    <FitnessProdCard/>
                </div>
            </CatalogSlider>
        </FitnessContainer>
    )
}

export default FastSelling
