import {VideoCallOutlined} from '@mui/icons-material'
import {Box} from '@mui/material'
import React, {useState} from 'react'
import {NodeAlternateContent, NodeContent, TimeNode, UserTimeline} from '../../dashboardHome/components/UserTimeline'
import {DateFormat} from '../../../../DateFormat'

function ClaimStatus({data}) {
    const [active, setActive] = useState(0)
    console.log()

    function getStatus(status = [], index) {
        if (index <= active) {
            return {
                active: true
            }
        }
        if (status.includes(data.insurance_status)) {
            setActive(index)
        }
        return false;
    }

    return (
        <UserTimeline>
            <TimeNode icon={VideoCallOutlined} radius='20' active>
                <NodeContent>
                    <Box textAlign='center'>
                        <h5>
                            In Progress
                        </h5>
                    </Box>
                </NodeContent>
            </TimeNode>

            <TimeNode radius='20' {...getStatus(['Approved'], 1)}>
                <NodeContent>
                    <Box textAlign='center'>
                        <h5>
                            Claim Approved
                        </h5>
                    </Box>
                </NodeContent>
            </TimeNode>
            <TimeNode radius='20'
                      tipTitle={active === 2 ? data.insurance_status : ''} {...getStatus(['Processing(Insurance Company)', 'Processing(Claim Department)', 'Processing(Finance Department)', 'Processing(Forward to GD)'], 2)}>
                <NodeContent>
                    <Box textAlign='center'>
                        <h5>
                            Processing
                        </h5>
                    </Box>
                </NodeContent>
            </TimeNode>

            <TimeNode radius='20' {...getStatus(['Claim Settelled'], 3)}>
                <NodeContent>
                    <Box textAlign='center'>
                        <h5>
                            Claim Settled
                        </h5>
                    </Box>
                </NodeContent>
            </TimeNode>

            <TimeNode radius='20' error {...getStatus(['Rejected'], 4)}>
                <NodeContent>
                    <Box textAlign='center'>
                        <h5>
                            Claim Rejected
                        </h5>
                    </Box>
                </NodeContent>
            </TimeNode>
        </UserTimeline>
    )
}

// function PackageTimeNode({ data }) {
//     return (
//         <TimeNode active>
//             <NodeContent>
//                 <Box textAlign='center'>
//                     <div>
//                         <b>July 1st, 2022</b>
//                     </div>
//                     <h5>
//                         First Screening Start
//                     </h5>
//                 </Box>
//             </NodeContent>
//         </TimeNode>
//     )
// }

export default ClaimStatus
