import React, {useState, useEffect} from "react";
import axios from '../../api.js';
import Logo from "../../../images/white-logo.png";
import swal from "sweetalert";
import {LogInContext} from '../../../contexts/LogInContext'
import {Link, NavLink} from "react-router-dom";
import {LoginStateContext} from "../../../contexts/LoginStateContext.js";
import {HandshakeOutlined, NotificationsActiveOutlined} from "@mui/icons-material";
import {Badge} from "@mui/material";

const DashboardNav = () => {
    const [user, setUser] = useState();
    const loginContext = React.useContext(LoginStateContext)

    // const token = localStorage.getItem('token');
    useEffect(() => {
        if (!loginContext.state.loading) {
            if (loginContext.state.token !== null) {
                axios.get('/api/admin/user-profile', {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                    .then(res => setUser(res.data.member.name))
                    .catch((error) => {
                        console.log()
                    });
            }
        }

    }, [loginContext.state.loading])

    const logout = () => {
        localStorage.clear();
        loginContext.logout()
    }

    return (
        <div className="navbar navbar-expand-lg navbar-dark navbar-static d-flex justify-content-between ">
            <ul className="d-flex flex-1 d-lg-none">
                <button
                    className="navbar-toggler sidebar-mobile-main-toggle"
                    type="button"
                >
                    <i className="icon-paragraph-justify3"/>
                </button>
            </ul>
            <ul
                className="collapse navbar-collapse order-2 order-lg-1"
                id="navbar-mobile"
            >
                <Link to="/">
                    <img src={Logo} alt="Logo" width="70px" className="mr-2"/>
                    <span className="text-white" style={{fontSize: '25px'}}>GHARGHARMA DOCTOR</span>
                </Link>
            </ul>
            <ul className="navbar-nav flex-row order-1 order-lg-2 flex-1 flex-lg-0 justify-content-end align-items-center">
                <li className="nav-item nav-item-dropdown-lg dropdown">
                    <div className="dropdown-menu dropdown-menu-right dropdown-content wmin-lg-350">
                        <div className="dropdown-content-body dropdown-scrollable">
                            <ul className="media-list">
                                <li className="media">
                                    <div className="mr-3 position-relative">
                                        <img
                                            src="../global_assets/images/demo/users/face10.jpg"
                                            width={36}
                                            height={36}
                                            className="rounded-circle"
                                            alt=""
                                        />
                                    </div>
                                    <div className="media-body">
                                        <div className="media-title">
                                            <a href="#">
                        <span className="font-weight-semibold">
                          James Alexander
                        </span>
                                                <span className="text-muted float-right font-size-sm">
                          04:58
                        </span>
                                            </a>
                                        </div>
                                        <span className="text-muted">
                      who knows, maybe that would be the best thing for me...
                    </span>
                                    </div>
                                </li>
                                <li className="media">
                                    <div className="mr-3 position-relative">
                                        <img
                                            src="../global_assets/images/demo/users/face3.jpg"
                                            width={36}
                                            height={36}
                                            className="rounded-circle"
                                            alt=""
                                        />
                                    </div>
                                    <div className="media-body">
                                        <div className="media-title">
                                            <a href="#">
                        <span className="font-weight-semibold">
                          Margo Baker
                        </span>
                                                <span className="text-muted float-right font-size-sm">
                          12:16
                        </span>
                                            </a>
                                        </div>
                                        <span className="text-muted">
                      That was something he was unable to do because...
                    </span>
                                    </div>
                                </li>
                                <li className="media">
                                    <div className="mr-3">
                                        <img
                                            src="../global_assets/images/demo/users/face24.jpg"
                                            width={36}
                                            height={36}
                                            className="rounded-circle"
                                            alt=""
                                        />
                                    </div>
                                    <div className="media-body">
                                        <div className="media-title">
                                            <a href="#">
                        <span className="font-weight-semibold">
                          Jeremy Victorino
                        </span>
                                                <span className="text-muted float-right font-size-sm">
                          22:48
                        </span>
                                            </a>
                                        </div>
                                        <span className="text-muted">
                      But that would be extremely strained and suspicious...
                    </span>
                                    </div>
                                </li>
                                <li className="media">
                                    <div className="mr-3">
                                        <img
                                            src="../global_assets/images/demo/users/face4.jpg"
                                            width={36}
                                            height={36}
                                            className="rounded-circle"
                                            alt=""
                                        />
                                    </div>
                                    <div className="media-body">
                                        <div className="media-title">
                                            <a href="#">
                        <span className="font-weight-semibold">
                          Beatrix Diaz
                        </span>
                                                <span className="text-muted float-right font-size-sm">
                          Tue
                        </span>
                                            </a>
                                        </div>
                                        <span className="text-muted">
                      What a strenuous career it is that I've chosen...
                    </span>
                                    </div>
                                </li>
                                <li className="media">
                                    <div className="mr-3">
                                        <img
                                            src="../global_assets/images/demo/users/face25.jpg"
                                            width={36}
                                            height={36}
                                            className="rounded-circle"
                                            alt=""
                                        />
                                    </div>
                                    <div className="media-body">
                                        <div className="media-title">
                                            <a href="#">
                        <span className="font-weight-semibold">
                          Richard Vango
                        </span>
                                                <span className="text-muted float-right font-size-sm">
                          Mon
                        </span>
                                            </a>
                                        </div>
                                        <span className="text-muted">
                      Other travelling salesmen live a life of luxury...
                    </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="dropdown-content-footer justify-content-center p-0">
                            <a
                                href="#"
                                className="btn btn-light btn-block border-0 rounded-top-0"
                                data-popup="tooltip"
                                title="Load more"
                            >
                                <i className="icon-menu7"/>
                            </a>
                        </div>
                    </div>
                </li>
                <li className="nav-item nav-item-dropdown-lg dropdown">
                    <NavLink to="/user/notifications" className="navbar-nav-link navbar-nav-link-toggler">
                        {/* <i className="icon-bubbles4" /> */}
                        <Badge badgeContent={0} color='warning'>
                            <NotificationsActiveOutlined/>
                        </Badge>

                        {/* <span className="badge badge-warning badge-pill ml-auto ml-lg-0"> */}
                        {/* 10 */}
                        {/* </span> */}
                    </NavLink>
                    <div className="dropdown-menu dropdown-menu-right dropdown-content wmin-lg-350">
                        <div className="dropdown-content-header">
                            <span className="font-weight-semibold">Messages</span>
                            <a href="#" className="text-body"><i className="icon-compose"/></a>
                        </div>
                        <div className="dropdown-content-body dropdown-scrollable">
                            <ul className="media-list">
                                <li className="media">
                                    <div className="mr-3 position-relative">
                                        <img src="../global_assets/images/demo/users/face10.jpg" width={36} height={36}
                                             className="rounded-circle" alt=""/>
                                    </div>
                                    <div className="media-body">
                                        <div className="media-title">
                                            <a href>
                        <span className="font-weight-semibold">
                        </span>
                                                <span className="text-muted float-right font-size-sm"/>
                                            </a>
                                        </div>
                                        <span className="text-muted"/>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="dropdown-content-footer justify-content-center p-0">
                            <a href="/" className="btn btn-light btn-block border-0 rounded-top-0" data-popup="tooltip"
                               title="Load more"><i className="icon-menu7"/></a>
                        </div>
                    </div>
                </li>
                <li className="nav-item nav-item-dropdown-lg dropdown dropdown-user h-100">
                    <a
                        href="#"
                        className="navbar-nav-link navbar-nav-link-toggler dropdown-toggle d-inline-flex align-items-center h-100"
                        data-bs-toggle="dropdown"
                    >
                        <img
                            src="../global_assets/images/demo/users/face11.jpg"
                            className="rounded-pill mr-lg-2"
                            height={34}
                            alt=""
                        />
                        <span className="d-none d-lg-inline-block">{user}</span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right">
                        <NavLink to="/user/profile" className='dropdown-item'>
                            <i className="icon-user-plus"/> My profile
                        </NavLink>
                        {/* <a href="#" className="dropdown-item">

            </a> */}

                        <div className="dropdown-divider"/>
                        {
                            loginContext.state.data.school_profile === null && (
                                <>
                                    {
                                        loginContext.state.data?.member?.roles.length >= 2 ?
                                            <a target='_blank' href="https://demo.ghargharmadoctor.com"
                                               className="dropdown-item">
                                                <HandshakeOutlined fontSize="small" sx={{mr: 1.2}}/> Partner Dashboard
                                            </a>
                                            :
                                            <NavLink to="/become-partner" className="dropdown-item">
                                                <HandshakeOutlined fontSize="small" sx={{mr: 1.2}}/> Become A Partner
                                            </NavLink>
                                    }
                                </>
                            )
                        }

                        <NavLink to="/user/change-password" className="dropdown-item">
                            <i className="icon-cog5"/> Change Password
                        </NavLink>
                        <button className="dropdown-item" onClick={logout}>
                            <i className="icon-switch2"/> Logout
                        </button>
                    </div>
                </li>
            </ul>
        </div>
    );
};

export default DashboardNav;
