import {addCommasToNumber} from "../../../../../utils/common/common.utils";
import React from "react";
import {useQuery} from "@tanstack/react-query";
import SchoolPaymentVerificationService from "../../../../../services/school-payment-verification.service";

const PaymentInformation = () => {
    const {loading, data} = useQuery({
        queryKey: ['school-payment-information'],
        queryFn: SchoolPaymentVerificationService.fetchPaymentInformation,
    })


    return (
        <div className="d-grid gap-1">
            <p className="mb-0" style={{fontWeight: 600, color: "#555"}}><span className="text-black  font-weight-bold">Total Payable Amount :</span> NRS. {addCommasToNumber(435959)}</p>
            <p className="mb-0" style={{fontWeight: 600, color: "#555"}}><span className="text-black  font-weight-bold">Academic Year :</span> 2081/82 BS</p>
            <p style={{fontWeight: 600, color: "#555"}}><span className="text-black  font-weight-bold">Total Number of Students :</span> {addCommasToNumber(322)}</p>
        </div>
    )
}

export default PaymentInformation
