import axios from '../../components/api'

const payWithKhalti = async (config, token) => {
    try {
        const response = await axios.post(
            "/api/admin/payment/initiate",
            config,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        )
        return response
    }
    catch (err) {
        console.log(err)
    }
}

const payKhaltiConfig = (amount, user, orderId, orderName, returnUrl) => {
    return {
        return_url: returnUrl,
        website_url: "https://testreact.ghargharmadoctor.com",
        amount: amount,
        purchase_order_id: orderId,
        purchase_order_name: orderName,
        customer_info: {
            name: user.name,
            email: user.email,
            phone: user.phone,
        }
    }
}

export { payWithKhalti, payKhaltiConfig }