import {faCircleChevronRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useQuery} from '@tanstack/react-query';
import React, {useContext, useEffect, useRef} from 'react'
import {LoginStateContext} from '../../../../contexts/LoginStateContext'
import {useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import swal from 'sweetalert';
import axios from '../../../api.js';
import Loader from '../../Loader.js';
import './MyPackageDetails.css'
import {Doughnut} from 'react-chartjs-2';
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import PackageAlert from './PackageAlert';
import PackagePayment from './PackagePayment';
import {Alert, AlertTitle, Avatar, Box, Button, Card, Stack, Typography} from '@mui/material';
import PackageStatus from '../packageStatuses/PackageStatus';
import ScreeningTeam, {ScreeningDoctorTeam, ScreeningLabTeam} from './ScreeningTeam';
import ScreeningChange from './ScreeningChange';
import DOMPurify from 'dompurify';
import {DateFormat} from '../../../DateFormat';
import {FmdBad, VideoCall} from '@mui/icons-material';

ChartJS.register(ArcElement, Tooltip, Legend);

const MyPackageDetail = () => {
    const loginContext = useContext(LoginStateContext)
    const userData = loginContext.state.data
    const [interval, setInterval] = useState('');
    const [additionalPayment, setAdditionalPayment] = useState();
    const location = useLocation()
    const paymentElement = useRef(null)
    // const [detail,setDetail] = useState('');
    // const [regular,setRegular] = useState('');
    // const [followupCount,setFollowupCount] = useState('');
    const navigate = useNavigate();
    const [calcRes, setCalcRes] = useState({
        // registration: 0,
        // monthly: 0,
        totalMonth: 0,
        discountTotal: 0,
        discount: 0,
        result: 0,
        total: 0,
    })

    const userProfileDataQuery = useQuery(
        ["user-profile-data", userData.id],
        async () => {
            return axios.get('/api/admin/user-profile', {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                .then(res => res.data)
        },
    )


    const mypackage = useQuery(
        ['package-details-data', userData.id],
        async () => {
            return axios.get('/api/admin/userpackage', {headers: {"Authorization": `Bearer ${loginContext.state.token}`}})
                .then(res => res.data)
        }
    )

    const {data: calcAmtData, isLoading: calcAmtLoading} = useQuery({
        queryKey: ['calc-amount', userData.id],
        queryFn: async () => {
            return axios.get(`/api/admin/userpackage/calculate-amount/${mypackage?.data?.package?.package_id}`, {headers: {"Authorization": `Bearer ${loginContext.state.token}`}}).then(res => res.data)
        },
        enabled: !!mypackage.data
    })

    useEffect(() => {
        if (location.state?.pay) {
            // alert("Scrolling into view")
            console.log()
            console.log()
            // paymentElement.current.scrollIntoView()
        }
    }, [])

    if (mypackage.isLoading || userProfileDataQuery.isLoading || calcAmtLoading) {
        return <div className='d-flex justify-content-center'><Loader/></div>
    }

    const user = userProfileDataQuery.data;
    const pkg = mypackage.data.package;

    console.log()

    const months = mypackage.data.month_count;
    const remainingDays = mypackage.data.remainingDays;
    const completedDays = mypackage.data.completedDays;
    const prepay = mypackage.data.prepay;
    const extended_date = mypackage.data.extended_date;
    const expire = mypackage.data.expire;


    let userPkgReport = pkg?.dates.length > 0 ? (pkg?.dates[pkg.dates.length - 1]?.reports[0] || null) : null
    let homeskipped = userPkgReport?.homeskip
    let onlineConsult = userPkgReport?.online

    //    console.log('Data', mypackage.data)
    const data = {
        labels: [
            'Remaining',
            'Completed',
        ],
        datasets: [{
            label: 'Days',
            data: [remainingDays, completedDays],
            backgroundColor: [
                'rgb(54, 162, 235)',
                'rgba(255, 99, 132, 0.2)',
            ],
            hoverOffset: 4,
            weight: 1
        }]
    }


    const calculateFee = (e) => {
        if (e.target.value === '') {
            setCalcRes({registration: 0, monthly: 0, totalMonth: 0, discountTotal: 0, discount: 0, result: 0})
        } else {
            setInterval(e.target.value)
            const feeInterval = {
                Yearly: {
                    month: 12,
                    discount: 0.05
                },
                Half_Yearly: {
                    month: 6,
                    discount: 0.02
                },
                Quarterly: {
                    month: 3,
                    discount: 0.01
                },
                Monthly: {
                    month: 1,
                    discount: 0
                }
            }
            let paymentInterval = e.target.value;
            let totalmonth = 0;
            let discounttotal = 0;
            let res = 0;
            // if (pkg.renew_status === 2) {
            //     totalmonth = pkg.familyfee.family_size * pkg.familyfee.two_monthly_fee * feeInterval[paymentInterval].month;
            //     discounttotal = totalmonth - totalmonth * feeInterval[paymentInterval].discount;
            //     if (pkg.payment.length === 0) {
            //         res = pkg.familyfee.two_continuation_fee + discounttotal;
            //     } else {
            //         res = discounttotal;
            //     }
            //     if (pkg.additonal_members > 0) {
            //         axios.post(`/api/admin/userpackage/calculate-additional-payment/${pkg.id}`, { payment_interval: paymentInterval }, { headers: { Authorization: `Bearer ${loginContext.state.token}` } })
            //             .then((response) => {
            //                 setAdditionalPayment(response.data)
            //                 setCalcRes({ totalMonth: totalmonth, discountTotal: discounttotal, discount: feeInterval[paymentInterval].discount * 100, result: res, total: res + response.data.total_payment })
            //             })
            //     } else {
            //         setCalcRes({ totalMonth: totalmonth, discountTotal: discounttotal, discount: feeInterval[paymentInterval].discount * 100, result: res, total: 0 })
            //     }
            // }
            // else if (pkg.renew_status === 3) {
            //     totalmonth = pkg.familyfee.family_size * pkg.familyfee.three_monthly_fee * feeInterval[paymentInterval].month;
            //     discounttotal = totalmonth - totalmonth * feeInterval[paymentInterval].discount;
            //     if (pkg.payment.length === 0) {
            //         res = pkg.familyfee.three_continuation_fee + discounttotal;
            //     } else {
            //         res = discounttotal;
            //     }
            //     if (pkg.additonal_members > 0) {
            //         axios.post(`/api/admin/userpackage/calculate-additional-payment/${pkg.id}`, { payment_interval: paymentInterval }, { headers: { Authorization: `Bearer ${loginContext.state.token}` } })
            //             .then((response) => {
            //                 setAdditionalPayment(response.data)
            //                 setCalcRes({ totalMonth: totalmonth, discountTotal: discounttotal, discount: feeInterval[paymentInterval].discount * 100, result: res, total: res + response.data.total_payment })
            //             })
            //     } else {
            //         setCalcRes({ totalMonth: totalmonth, discountTotal: discounttotal, discount: feeInterval[paymentInterval].discount * 100, result: res, total: 0 })
            //     }
            // }
            // else {
            totalmonth = pkg.familyfee.family_size * pkg.familyfee.one_monthly_fee * feeInterval[paymentInterval].month;
            discounttotal = totalmonth - totalmonth * feeInterval[paymentInterval].discount;
            if (pkg.payment.length === 0) {
                res = pkg.familyfee.one_registration_fee + discounttotal;
            } else {
                res = discounttotal;
            }
            if (pkg.additonal_members > 0) {
                axios.post(`/api/admin/userpackage/calculate-additional-payment/${pkg.id}`, {payment_interval: paymentInterval}, {headers: {Authorization: `Bearer ${loginContext.state.token}`}})
                    .then((response) => {
                        setAdditionalPayment(response.data)
                        setCalcRes({
                            totalMonth: totalmonth,
                            discountTotal: discounttotal,
                            discount: feeInterval[paymentInterval].discount * 100,
                            result: res,
                            total: res + response.data.total_payment
                        })
                    })
            } else {
                setCalcRes({
                    totalMonth: totalmonth,
                    discountTotal: discounttotal,
                    discount: feeInterval[paymentInterval].discount * 100,
                    result: res,
                    total: 0
                })
            }
            // }
        }
    }

    const checkFamilySize = () => {
        axios.get(`/api/admin/userpackage/check-family-size/${pkg.id}`, {headers: {Authorization: `Bearer ${loginContext.state.token}`}})
            .then((res) => {
                if (res.data.message === 'success') {
                    navigate(`/${pkg.package.slug}/book-package`, {state: {payment_interval: 'Yearly'}})
                } else {
                    swal({
                        title: 'Renew Package',
                        text: res.data.message,
                        icon: "warning",
                    }).then((value) => {
                        if (value) {
                            navigate('/packages')
                        }
                    });
                }
            })

    }
    // const checkFamilySize = () => {
    //     axios.get(`/api/admin/userpackage/calculate-amount/${pkg.package_id}`, {headers: { Authorization: `Bearer ${loginContext.state.token}` }})
    //     .then((res) => {
    //         if(pkg.family_id === res.data.packagefee.id){
    //             navigate(`/${pkg.package.slug}/book-package`)
    //         }else{
    //             swal({
    //                 title: 'Renew Package',
    //                 text:'You cannot renew the package due to additional family members. Please buy package.',
    //                 icon: "warning",
    //             }).then((value) => {
    //                 if(value){
    //                    navigate('/packages')
    //                 }
    //             });
    //         }
    //     })
    // }

    return (
        <>
            <PackageAlert user={user} pkg={pkg} prepay={prepay} extended_date={extended_date} expire={expire}
                          checkFamilySize={checkFamilySize} paymentRef={paymentElement}/>
            {pkg !== null ?
                <>
                    <div className="row mypackages">
                        <div className="col-md-4">
                            <div className="card h-100">
                                {pkg.grace_period === 0 &&
                                    <p style={{color: '#787878', fontSize: '12px'}} className='text-center mb-0'><b>Remaining
                                        Days</b></p>}
                                {pkg.grace_period === 1 && <p style={{color: '#787878', fontSize: '12px'}}
                                                              className='text-center text-danger mb-0'><b>Extended
                                    Days</b></p>}
                                {pkg.grace_period === 2 && pkg.package_status === 'Deactivated' &&
                                    <p style={{color: '#787878', fontSize: '12px'}}
                                       className='text-center text-danger mb-0'><b>Deactivated</b></p>}
                                {pkg.package_status === 'Expired' && <p style={{color: '#787878', fontSize: '12px'}}
                                                                        className='text-center text-danger mb-0'>
                                    <b>Expired</b></p>}
                                <div className="d-flex justify-content-center"
                                     style={{width: '100%', height: '250px', position: 'relative'}}>
                                    <Doughnut data={data} options={{
                                        responsive: true,
                                        maintainAspectRatio: true,
                                        cutout: '70%',
                                    }}/>

                                    <div style={{
                                        position: 'absolute',
                                        width: '100%',
                                        top: '60%',
                                        left: 'auto',
                                        right: 'auto',
                                        textAlign: 'center',
                                        marginTop: '-28px',
                                        lineHeight: '20px'
                                    }}>
                                        <span style={{fontSize: '30px', fontWeight: '600'}}>{remainingDays} days</span>
                                    </div>
                                </div>
                                {
                                    (pkg.dates.length === 0 && pkg.package_status === 'Booked')
                                    &&
                                    <Typography variant='subtitle1' color='error'>* Your days count will start after
                                        first screening date</Typography>
                                }
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card h-100 px-2 py-1">
                                <p style={{color: '#787878', fontSize: '12px'}}
                                   className='text-center mb-3'>Subscription Package</p>
                                <div className="package-item-circle mx-auto" style={{position: 'relative'}}>
                                    <div style={{
                                        position: 'absolute',
                                        width: '100%',
                                        top: '20%',
                                        left: 'auto',
                                        right: 'auto',
                                        textAlign: 'center'
                                    }}>
                                        <span style={{
                                            fontWeight: '600',
                                            fontSize: '25px'
                                        }}>{pkg.package.package_type}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card h-100 px-2 py-1">
                                <p style={{color: '#787878', fontSize: '12px'}} className='text-center mb-3'>Family
                                    Size</p>
                                <div className="package-item-circle mx-auto" style={{position: 'relative'}}>
                                    {pkg.additonal_members > 0 ?
                                        <div style={{
                                            position: 'absolute',
                                            width: '100%',
                                            top: '20%',
                                            left: 'auto',
                                            right: 'auto',
                                            textAlign: 'center'
                                        }}>
                                            <span style={{
                                                fontWeight: '600',
                                                fontSize: '35px'
                                            }}>{calcAmtData.packagefee.family_size}</span><br/>
                                            <span style={{
                                                fontWeight: '600',
                                                fontSize: '12px'
                                            }}>{pkg.additonal_members} Additional Members</span>
                                        </div>
                                        :
                                        <div style={{
                                            position: 'absolute',
                                            width: '100%',
                                            top: '30%',
                                            left: 'auto',
                                            right: 'auto',
                                            textAlign: 'center'
                                        }}>
                                            <span style={{
                                                fontWeight: '600',
                                                fontSize: '35px'
                                            }}>{calcAmtData.packagefee.family_size}</span><br/>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2 mb-3">
                        <div className={pkg.dates.length !== 0 ? `col-md-6` : `col-md-12`}>
                            <div className="card h-100">
                                <div className="card-body">
                                    <h3 style={{fontWeight: '700', textAlign: 'center'}} className='mb-4'>Booking
                                        Details</h3>
                                    <table className="table table-responsive-sm">
                                        <tbody>
                                        <tr>
                                            <th style={{fontWeight: '700'}}>Subscription Package</th>
                                            <td>{pkg.package.package_type}</td>
                                        </tr>
                                        <tr>
                                            <th style={{fontWeight: '700'}}>Family Size</th>
                                            <td>{calcAmtData.packagefee.family_size} {pkg.additonal_members > 0 ? <> ({pkg.additonal_members} Additional
                                                Members)</> : ''}</td>
                                        </tr>
                                        <tr>
                                            <th style={{fontWeight: '700'}}>Booked Date</th>
                                            <td>{pkg.booked_date}</td>
                                        </tr>
                                        <tr>
                                            <th style={{fontWeight: '700'}}>Expiry Date</th>
                                            <td>{pkg.expiry_date}</td>
                                        </tr>
                                        <tr>
                                            <th style={{fontWeight: '700'}}>Subscription Status</th>
                                            <td>{pkg.package_status}</td>
                                        </tr>
                                        <tr>
                                            <th style={{fontWeight: '700'}}>Payment Status</th>
                                            {pkg.status === 1 ? <td className='text-success'>Paid</td> :
                                                <td className='text-danger'>Payment Due</td>}
                                        </tr>
                                        <tr>
                                            {pkg.renew_status >= 2 &&
                                                <th style={{fontWeight: '700'}}>Renew Status/Renew Year</th>
                                            }
                                            {pkg.renew_status >= 2 &&
                                                <td>Renewed / Year {pkg.year} </td>
                                            }
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {pkg.dates.length !== 0 &&
                            <div className="col-md-6">
                                <div className="card h-100">
                                    <div className="card-body">
                                        <h3 style={{fontWeight: '700', textAlign: 'center'}} className='mb-4'>Screening
                                            Details</h3>
                                        <table className="table table-responsive-sm">
                                            <tbody>
                                            <tr>
                                                <th style={{fontWeight: '700'}}>Screening</th>
                                                <th style={{fontWeight: '700'}}>Screening Date</th>
                                                <th style={{fontWeight: '700'}}>Screening Status</th>
                                            </tr>
                                            {pkg.dates.map((date) =>
                                                <tr key={date.id}>
                                                    <td>{date.screening.screening}</td>
                                                    <td>{date.screening_date}</td>
                                                    {date.status === 'Pending' ?
                                                        <td className='text-danger'>{date.status}</td>
                                                        :
                                                        <td className='text-success'>{date.status}</td>
                                                    }

                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                        <div className="mt-4">
                                            {
                                                pkg.dates.length != 0 && pkg.dates[pkg.dates.length - 1].reports[0]?.nosample != null && (
                                                    <>
                                                        <Alert severity='info' color='warning'>
                                                            <AlertTitle>
                                                                Sample not collected
                                                            </AlertTitle>

                                                            <div className='fs-6 fw-light'>
                                                                <span
                                                                    className='text-muted'>Sample not collected reason: </span>
                                                                <span
                                                                    className='fs-6'>{pkg.dates[pkg.dates.length - 1].reports[0].nosample.reason}</span>
                                                            </div>

                                                            {
                                                                pkg.dates[pkg.dates.length - 1].additional_screening_date != null && (
                                                                    <div className='mt-1 fs-6 fw-light'>
                                                                        <span
                                                                            className='text-muted'>New Sample Collection Date: </span><span
                                                                        className='fs-6'>{DateFormat(pkg.dates[pkg.dates.length - 1].additional_screening_date).getFullDateText()} {pkg.dates[pkg.dates.length - 1].additional_screening_time}.</span>
                                                                    </div>
                                                                )
                                                            }
                                                        </Alert>
                                                        {
                                                            pkg.dates[pkg.dates.length - 1].reports[0].additionalnosample != null && (
                                                                <Alert severity='info' sx={{mt: 2}}>
                                                                    <AlertTitle>
                                                                        Additional sample not collected
                                                                    </AlertTitle>

                                                                    <div className='fs-6 fw-light'>
                                                                        <span className='text-muted'>Sample not collected reason: </span>
                                                                        <span
                                                                            className='fs-6'>{pkg.dates[pkg.dates.length - 1].reports[0].additionalnosample.reason}</span>
                                                                    </div>

                                                                    {/* {
                                                                        pkg.dates[pkg.dates.length - 1].additional_screening_date != null && (
                                                                            <div className='mt-1 fs-6 fw-light'>
                                                                                <span className='text-muted'>New Sample Collection Date: </span><span className='fs-6'>{DateFormat(pkg.dates[pkg.dates.length - 1].additional_screening_date).getFullDateText()} {pkg.dates[pkg.dates.length - 1].additional_screening_time}.</span>
                                                                            </div>
                                                                        )
                                                                    } */}
                                                                </Alert>
                                                            )
                                                        }
                                                    </>

                                                )
                                            }

                                        </div>
                                        {
                                            pkg.dates.length != 0 && pkg.dates[pkg.dates.length - 1].reports[0]?.homeskip != null && (
                                                <div className="mt-4">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <div className='fw-bold d-flex gap-1 align-items-center mb-1'>
                                                                <FmdBad className='fs-6' sx={{color: 'error.main'}}/>
                                                                <span className='fs-6'>Your homevisit has been skipped because of your absence.</span>
                                                            </div>
                                                            <div><Box component='span'
                                                                      color='primary.main'>Reason:</Box><span
                                                                className="fs-6 fw-light text-error ms-2">{pkg.dates[pkg.dates.length - 1].reports[0].homeskip.reason}</span>
                                                            </div>
                                                        </div>
                                                        <div className="card-header">
                                                            <span className='fs-6 fw-bold'>Join online consultation</span>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="d-flex gap-3">
                                                                <Avatar alt="Remy Sharp"
                                                                        src="https://mui.com/static/images/avatar/1.jpg"/>
                                                                <div>
                                                                    <div><span
                                                                        className="fs-6">Dr. {onlineConsult.doctor.user.name}</span>
                                                                    </div>
                                                                    <div><span
                                                                        className="fw-light fs-6">{onlineConsult.doctor.user.email}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="mt-2">
                                                                <div className="row">
                                                                    <div className="col-4">
                                                                        <div><span
                                                                            className="fw-light fs-6">Phone Number</span>
                                                                        </div>
                                                                        <div><span
                                                                            className="fs-6">+977 {onlineConsult.doctor.user.phone}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <div><span
                                                                            className="fw-light fs-6">Join Date</span></div>
                                                                        <div><span
                                                                            className="fs-6 badge badge-primary">{DateFormat(onlineConsult.start_time).getFullDateText()}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <div><span
                                                                            className="fw-light fs-6">Join Time</span></div>
                                                                        <div><span
                                                                            className="fs-6 badge badge-warning">{onlineConsult.start_time.split(' ')[1]}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="mt-3">
                                                                <div className="row g-2">
                                                                    <div className="col-8">
                                                                        <span className='fw-light fs-6'>
                                                                            Join the meeting at the given date and time for online consultation.
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <div>
                                                                            <button
                                                                                className="btn btn-primary d-flex align-items-center gap-2"
                                                                                onClick={() => window.open(onlineConsult.join_url, '_blank')}>
                                                                                <VideoCall className='fs-6'/>
                                                                                Join Meeting
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <table className='table border'>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <div className="d-flex align-items-center gap-2">
                                                                                <Avatar alt="Remy Sharp" src="https://mui.com/static/images/avatar/1.jpg" />
                                                                                <div>
                                                                                    <div><span className="text-muted fs-6">Dr. Shyam Shrestha</span></div>
                                                                                    <div><span className="fw-light fs-6">Surgeon</span></div>
                                                                                    <div><span className="text-muted fs-6">+977 9860192817</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <span className="text-muted fs-6">July 1st, 2023</span><br />
                                                                            <span className="fs-6 badge badge-primary text-white">15:00</span>
                                                                        </td>
                                                                        <td>
                                                                            <button className="btn btn-primary">
                                                                                Join Meeting
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table> */}

                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                    {
                        pkg.dates.length > 0 && (
                            <div className="row mt-2 mb-3">
                                <div className="col-6">
                                    <div className="card h-100">
                                        <div className="card-body">
                                            <h3 style={{fontWeight: '700', textAlign: 'center'}} className='mb-4'>Diagnostic
                                                and Lab Team</h3>
                                            <ScreeningLabTeam screeningDate={pkg.dates}/>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <Box className="card h-100" position='relative'>
                                        <div className="card-body">
                                            <h3 style={{fontWeight: '700', textAlign: 'center'}} className='mb-4'>Doctor and
                                                Advisors Team</h3>
                                            <ScreeningDoctorTeam screeningDate={pkg.dates}/>
                                        </div>
                                    </Box>
                                </div>
                            </div>
                        )
                    }
                    <div className="row mt-2 mb-3">
                        <div className="col-md-6">
                            <div className="card h-100">
                                <div className="card-body">
                                    <PackagePayment user={user} pkg={pkg} prepay={prepay} months={months}
                                                    calcRes={calcRes} interval={interval} calculateFee={calculateFee}
                                                    additionalPayment={additionalPayment}
                                                    setAdditionalPayment={setAdditionalPayment}
                                                    packagefee={calcAmtData?.packagefee}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <ScreeningChange pkg={pkg} refetch={mypackage.refetch}/>
                        </div>
                    </div>

                    <div className="card mt-2">
                        <div className="card-body">
                            <div className="row">
                                {pkg.package_status !== 'Not Booked' &&
                                    <div className="col-md-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <h3 style={{fontWeight: '700'}}>Subscription Payment History</h3>
                                                <table className="table table-responsive">
                                                    <thead>
                                                    <tr>
                                                        <th style={{fontWeight: '700'}}>Payment Interval</th>
                                                        {/* <th style={{fontWeight:'700'}}>Payment Date</th> */}
                                                        <th style={{fontWeight: '700'}}>Expiry Date</th>
                                                        <th style={{fontWeight: '700'}}>Paid Amount</th>
                                                        <th style={{fontWeight: '700'}}>Extended Days</th>
                                                        <th style={{fontWeight: '700'}}>Prepaid Days</th>
                                                        <th style={{fontWeight: '700'}}>Payment Status</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {pkg.payment.map((payment) =>
                                                        <tr key={payment.id}>
                                                            <td>{payment.payment_interval}</td>
                                                            {/* <td>{payment.payment_date}</td> */}
                                                            <td>{payment.expiry_date !== null ? payment.expiry_date :
                                                                <span
                                                                    className='text-danger'>Package not activated yet.</span>}</td>
                                                            <td>NPR. {payment.amount}</td>
                                                            <td>{payment.grace_days !== null ? payment.grace_days : 0} days</td>
                                                            <td>{payment.prepay_days !== null ? payment.prepay_days : 0} days</td>
                                                            <td>{payment.expiry_date !== null ? payment.expiry_date > new Date().toISOString().slice(0, 10) ?
                                                                    <span className='text-success'>Paid</span> :
                                                                    <span className='text-danger'>Expired</span> :
                                                                <span className='text-success'>Paid</span>}</td>
                                                        </tr>
                                                    )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </>
                :
                <PackageStatus data={pkg}/>
            }
        </>
    )
}

export default MyPackageDetail
