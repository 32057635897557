const chkItemLocalStorage = (toStoreName, item) => {
    let localData = getFromLocalStorage(toStoreName)
    let res = false
    localData.map((data) => {
        if (data.id == item.id) {
            res = true
            return
        }
    })
    return res
}

const addToLocalStorage = (toStoreName, toStoreData) => {
    console.log()
    let res = false
    if (getFromLocalStorage(toStoreName).length == 0) {
        localStorage.setItem(toStoreName, JSON.stringify([toStoreData]))
        res = true
    } else {
        if (chkItemLocalStorage(toStoreName, toStoreData) == false) {

            let data = JSON.parse(localStorage.getItem(toStoreName))
            data.push(toStoreData)
            localStorage.setItem(toStoreName, JSON.stringify(data))
            res = true
        }
    }
    return res
}

const removeFromLocal = (storeName, keyName, id) => {
    let localData = getFromLocalStorage(storeName)
    if (localData.length > 0) {
        let remData = localData.filter(data => data[keyName] != id)
        localStorage.setItem(storeName, JSON.stringify(remData))
    }
}

const removeAllFromLocal = (toRemoveName) => {
    localStorage.removeItem(toRemoveName)
}

const getFromLocalStorage = (toStoreName) => {
    return JSON.parse(localStorage.getItem(toStoreName) || '[]')
}

const getById = (toStoreName, keyName, id) => {
    let localData = getFromLocalStorage(toStoreName)

    localData.filter(data => data[keyName] == id)
    console.log()
    return localData
}


export {addToLocalStorage, getFromLocalStorage, removeFromLocal, removeAllFromLocal}
