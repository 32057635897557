import {Box, Button} from '@mui/material'
import React, {useContext, useState} from 'react'
import {InputField, SelectInput, SelectOption} from '../../../../custom_componets/FormComponents'
import generateInitialState from '../helpers/generateInitialState'
import {useGlobalFormData} from '../GlobalFormProvider'
import {LoginStateContext} from '../../../../../contexts/LoginStateContext'
import axios from '../../../../api'


function ProfessionalDetails({handleBack, handleNext, allFormData, setAllFormData}) {
    const {globalForm, refetchGlobalForm} = useGlobalFormData()
    // console.log()
    const [inputs, setInputs] = useState(generateInitialState([
        'max_amount_per_tansaction',
        'number_of_monthly_transaction',
        'monthly_amount_of_transaction',
        'number_of_yearly_transaction',
        'yearly_amount_of_transaction',
        'education',
        'identification_type',
        'identification_no',
        'citizenship_date',
        'citizenship_issue_district',
        'father_full_name',
        'mother_full_name',
        'grandfather_full_name',
        'grandmother_full_name',
        'husband_wife_full_name',
        'marital_status'
    ], globalForm))

    const token = useContext(LoginStateContext).state.token

    console.log()

    const [errors, setErrors] = useState([])

    function handleSubmit(e) {
        e.preventDefault()
        // setAllFormData({ ...allFormData, ...inputs })
        axios.post(`api/kyc/professional-information/${globalForm.id}`, inputs, {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                console.log()
                handleNext()
                refetchGlobalForm()
            })
    }

    function handleChange(e) {
        setInputs({...inputs, [e.target.name]: e.target.value})
    }

    return (
        <React.Fragment>
            <form className='form-horizontal' onSubmit={handleSubmit}>
                <div className="card">
                    <div className="card-header">
                        <h3 className="m-0 p-0">Transaction Details <small>(For Banking Purposes)</small></h3>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-4">
                                <InputField
                                    label="Max Amount Per Transaction"
                                    name="max_amount_per_tansaction"
                                    type='number'
                                    min='1'
                                    value={inputs.max_amount_per_tansaction}
                                    error={errors?.max_amount_per_tansaction}
                                    errorMsg={errors?.max_amount_per_tansaction ? errors.max_amount_per_tansaction.message : null}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField
                                    label="Number of Monthly Transaction"
                                    type="number"
                                    name="number_of_monthly_transaction"
                                    value={inputs.number_of_monthly_transaction}
                                    error={errors?.number_of_monthly_transaction}
                                    errorMsg={errors?.number_of_monthly_transaction ? errors.number_of_monthly_transaction.message : null}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField
                                    label="Monthly Amount of Transaction"
                                    type="number"
                                    name="monthly_amount_of_transaction"
                                    value={inputs.monthly_amount_of_transaction}
                                    error={errors?.monthly_amount_of_transaction}
                                    errorMsg={errors?.monthly_amount_of_transaction ? errors.monthly_amount_of_transaction.message : null}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField
                                    label="Number of Yearly Transaction"
                                    type='number'
                                    name="number_of_yearly_transaction"
                                    value={inputs.number_of_yearly_transaction}
                                    error={errors?.number_of_yearly_transaction}
                                    errorMsg={errors?.number_of_yearly_transaction ? errors.number_of_yearly_transaction.message : null}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField
                                    label="Yearly Amount of Transaction"
                                    type="number"
                                    name="yearly_amount_of_transaction"
                                    value={inputs.yearly_amount_of_transaction}
                                    error={errors?.yearly_amount_of_transaction}
                                    errorMsg={errors?.yearly_amount_of_transaction ? errors.yearly_amount_of_transaction.message : null}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-header">
                        <h3 className="m-0 p-0">
                            Education Details
                        </h3>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-4">
                                <InputField
                                    label="Education"
                                    name="education"
                                    value={inputs.education}
                                    error={errors?.education}
                                    errorMsg={errors?.education ? errors.education.message : null}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-header">
                        <h3 className="m-0 p-0">
                            Identification Details
                        </h3>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-4">
                                <SelectInput
                                    label="Identification Type"
                                    name="identification_type"
                                    value={inputs.identification_type}
                                    error={errors?.identification_type}
                                    errorMsg={errors?.identification_type ? errors.identification_type.message : null}
                                    onChange={handleChange}
                                    required
                                >
                                    <SelectOption value='Citizenship'>Citizenship</SelectOption>
                                    <SelectOption value='Passport'>Passport</SelectOption>
                                    <SelectOption value='Driver License'>Driver License</SelectOption>
                                    <SelectOption value='Non-Resident Nepali Identity Card'>Non-Resident Nepali Identity
                                        Card</SelectOption>
                                    <SelectOption value='National Identity Card'>National Identity Card</SelectOption>
                                    <SelectOption value='Voter Card'>Voter Card</SelectOption>
                                    <SelectOption value='Other'>Other</SelectOption>
                                </SelectInput>
                            </div>
                            <div className="col-4">
                                <InputField
                                    label="Identification Number"
                                    name="identification_no"
                                    value={inputs.identification_no}
                                    error={errors?.identification_no}
                                    errorMsg={errors?.identification_no ? errors.identification_no.message : null}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-4">
                                <InputField
                                    label="Citizenship Issued Date"
                                    type='date'
                                    name="citizenship_date"
                                    value={inputs.citizenship_date}
                                    error={errors?.citizenship_date}
                                    errorMsg={errors?.citizenship_date ? errors.citizenship_date.message : null}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-4">
                                <InputField
                                    label="Citizenship Issued District"
                                    name="citizenship_issue_district"
                                    value={inputs.citizenship_issue_district}
                                    error={errors?.citizenship_issue_district}
                                    errorMsg={errors?.citizenship_issue_district ? errors.citizenship_issue_district.message : null}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-header">
                        <h3 className="m-0 p-0">
                            Family Details
                        </h3>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-4">
                                <InputField
                                    label="Father Full name"
                                    name="father_full_name"
                                    value={inputs.father_full_name}
                                    error={errors?.father_full_name}
                                    errorMsg={errors?.father_full_name ? errors.father_full_name.message : null}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-4">
                                <InputField
                                    label="Mother Full Name"
                                    name="mother_full_name"
                                    value={inputs.mother_full_name}
                                    error={errors?.mother_full_name}
                                    errorMsg={errors?.mother_full_name ? errors.mother_full_name.message : null}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-4">
                                <InputField
                                    label="Grandfather Full Name"
                                    name="grandfather_full_name"
                                    value={inputs.grandfather_full_name}
                                    error={errors?.grandfather_full_name}
                                    errorMsg={errors?.grandfather_full_name ? errors.grandfather_full_name.message : null}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-4">
                                <InputField
                                    label="Grand Mother Full Name"
                                    name="grandmother_full_name"
                                    value={inputs.grandmother_full_name}
                                    error={errors?.grandmother_full_name}
                                    errorMsg={errors?.grandmother_full_name ? errors.grandmother_full_name.message : null}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-4">
                                <InputField
                                    label="Husband/Wife Full Name"
                                    name="husband_wife_full_name"
                                    value={inputs.husband_wife_full_name}
                                    error={errors?.husband_wife_full_name}
                                    errorMsg={errors?.ghusband_wife_full_name ? errors.husband_wife_full_name.message : null}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-4">
                                <SelectInput
                                    label="Marital Status"
                                    name="marital_status"
                                    value={inputs.marital_status}
                                    error={errors?.marital_status}
                                    errorMsg={errors?.marital_status ? errors.marital_status.message : null}
                                    onChange={handleChange}
                                    required
                                >
                                    <SelectOption value={"single"}>Single</SelectOption>
                                    <SelectOption value={"married"}>Married</SelectOption>
                                    <SelectOption value={"divorced"}>Divorced</SelectOption>
                                    <SelectOption value={"widowed"}>Widowed</SelectOption>
                                </SelectInput>
                            </div>
                        </div>
                    </div>
                </div>


                <React.Fragment>
                    <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                        <Button
                            color="inherit"
                            onClick={handleBack}
                            sx={{mr: 1}}
                        >
                            Back
                        </Button>
                        <Box sx={{flex: '1 1 auto'}}/>
                        <button type="submit" className="btn btn-primary" onClick={(e) => {
                            console.log()
                            // handleNext()
                        }}>
                            Next
                        </button>
                    </Box>
                </React.Fragment>
            </form>
        </React.Fragment>
    )
}

export default ProfessionalDetails
