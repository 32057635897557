import {CalendarMonthOutlined, LocalHospitalOutlined, Search} from '@mui/icons-material'
import {Avatar, Box, CircularProgress, InputBase} from '@mui/material'
import React, {useContext, useState} from 'react'
import {Link} from 'react-router-dom'
import {useQuery} from '@tanstack/react-query'
import DocImg from '../../../../images/receptionist.png'
import FormModalContext from '../../../custom_componets/globalFormModal/FormModalContext'
import PdfIcon from '../../../../images/pdf.png'
import MedicalHistoryPDF from './MedicalHistoryPDF'
import NoDataUi from '../../../../images/nodata.jpg'
import ParentService from "../../../../services/parent.service";
import {DateFormat} from "../../../DateFormat";
import ParentChildrenSelection from "./ParentChildrenSelection";
import {toast} from "react-hot-toast";

function Index() {
    const {openFormModal} = useContext(FormModalContext)
    const [historyData, setHistoryData] = useState([])
    const [childrenId, setChildrenId] = useState(undefined);

    /*
   * MEDICAL HISTORY FETCHING BY CHILDREN
   * */
    const{data, isLoading} = useQuery({
            queryKey: ['children-medical-history', childrenId],
            queryFn: () => childrenId ?  ParentService.fetchMedicalHistoryChildren(childrenId) : undefined,
            onSuccess: (data) => {
                setHistoryData(data?.data)
            },
            onError: (err) => {
                toast.error("Something went wrong.")
            },
            enable: Boolean(childrenId),
        }
    )

    /*
    * PACKAGES FETCHING
    * */
    const {data: pkgData, isLoading: packageLoading, isError: packageError, refetch: refetchPackages} = useQuery({
            queryKey: ['my-packages'],
            queryFn: () => ParentService.fetUserPackage(),
        }
    )

    const ifUserPackageIsNull = pkgData?.package === null
    const ifUserHasBookedPackage = pkgData?.package?.package_status !== 'Not Booked'

    let isMember = ifUserPackageIsNull ||  ifUserHasBookedPackage

    const handleOnSearch = (e) => {
        console.log(data)
        // const data = handleSearch(e);
        // setHistoryData(data)
    }

    const renderHistoryDataUI = () => {
        if (historyData && historyData?.length) {
            return (
                <>
                    {historyData?.map((item, idx) => (
                        item.medical_report.length > 0 &&
                        <tr key={item.id}>
                            <td>
                                                                            <span className="text-muted fs-6">
                                                                                {idx + 1}
                                                                            </span>
                            </td>
                            <td>
                                <div className="d-flex gap-2 align-items-center"
                                     role='button' onClick={() => openFormModal(
                                    <MedicalHistoryPDF
                                        pdf={item.medical_report[0].report_path}/>)}>
                                    <div>
                                        <img src={PdfIcon} alt=""
                                             style={{height: '40px'}}/>
                                    </div>
                                    <div>
                                                                                    <span className='fs-6'
                                                                                          style={{fontWeight: '550'}}>
                                                                                        #{item.medical_report[0].report.split('.')[0]}
                                                                                    </span>
                                        <br/>
                                        <span className="fs-6 fw-light">
                                                                                        {DateFormat(item.created_at).getFullDateText()}
                                                                                    </span>
                                    </div>

                                </div>
                            </td>
                            <td>
                                {
                                    item.doctor === null ?
                                        <span className="text-muted fs-6">Doctor Not Assigned</span>
                                        :
                                        <div
                                            className="d-flex align-items-center gap-2">
                                            <Avatar
                                                src={item.doctor.image_path || DocImg}/>
                                            <div>
                                                <div>
                                                                                            <span className='fs-6'
                                                                                                  style={{fontWeight: 550}}>Dr. {item.doctor.user.name}</span>
                                                </div>
                                                {
                                                    item.doctor.nmc_no && (
                                                        <div>
                                                                                                    <span
                                                                                                        className='badge badge-info'>{item.doctor.nmc_no}</span>
                                                        </div>
                                                    )
                                                }

                                            </div>
                                        </div>
                                }
                            </td>
                            <td>
                                <div>
                                                                            <span
                                                                                className="fs-6 me-1">{item.doctor_name || '-----'}</span>
                                    <small
                                        className="badge badge-success text-white">{item.doctor_nmc}</small>
                                </div>
                                {
                                    item.hospital && (
                                        <div
                                            className='d-flex align-items-center gap-1'>
                                            <LocalHospitalOutlined
                                                className='fs-6'/>
                                            <span
                                                className="fw-light fs-6">{item.hospital}</span>
                                        </div>
                                    )
                                }
                                {
                                    item.check_date && (
                                        <div
                                            className="d-flex align-items-center gap-1">
                                            <CalendarMonthOutlined
                                                className='fs-6'/>
                                            <span
                                                className="fw-light text-muted fs-6">{DateFormat(item.check_date).getFullDateText()}</span>
                                        </div>
                                    )
                                }

                            </td>
                            <td>
                                                                            <span className="fs-6 text-muted">{
                                                                                item.department ? (
                                                                                    <>{item.department.department}</>
                                                                                ) : item.is_other ? (
                                                                                    <>{item.is_other}</>
                                                                                ) : (
                                                                                    <>---</>
                                                                                )
                                                                            }</span>
                            </td>
                            <td>
                                {
                                    item.status === 2 ?
                                        <span
                                            className="badge badge-danger">Rejected</span>
                                        :
                                        item.status === 1 ?
                                            <span
                                                className="badge badge-success">Approved</span>
                                            :
                                            <span
                                                className="badge badge-warning">Pending</span>
                                }
                            </td>
                            <td>
                                <div className="d-flex justify-content-end">
                                    <Link
                                        to={`/parent/medical-history-detail?member_id=${item.member_id}&id=${item.id}`}
                                        className="btn btn-primary">
                                        View Report
                                    </Link>
                                </div>
                            </td>
                        </tr>
                    ))}
                </>
            )
        } else {
            return (
                <tr>
                    <td colSpan={7}>
                        {
                            isLoading ? renderLoader() : (
                                <div
                                    className="w-100 d-flex justify-content-center">
                                    <span className="fs-6">No Data Found.</span>
                                </div>
                            )
                        }
                    </td>
                </tr>
            )
        }

    }

    const handleChildrenChange = (childrenID) => {
        setChildrenId(childrenID)
    }

    const renderLoader = () => {
        return (
            <div className="d-flex gap-3 justify-content-center align-items-center py-5">
                    <CircularProgress /> Loading...
            </div>
        )
    }

    return (
        <Box>
            <div>
                <ParentChildrenSelection handleChange={handleChildrenChange}/>
                <div className="card">
                    <div className="card-header d-flex flex-column gap-4">
                        <div>
                            <div><span className='fs-5'>External Medical History</span></div>
                            <span className='text-muted'>These are your external medical history.</span>
                        </div>

                    </div>
                    <div className="card-body">

                        <Box className='d-flex  align-items-start justify-content-between mb-3'>
                            {childrenId ? (
                                <Link to={`/parent/add-medical-history/${childrenId}`} className="btn btn-primary">
                                    Add Medical History
                                </Link>
                            ) : (
                                <button className="btn btn-primary" disabled>
                                    Add Medical History
                                </button>
                            )}
                            {
                                isMember && (
                                    <Box className='d-flex gap-1 align-items-center rounded mb-2 shadow-sm'
                                         border={1} borderColor='divider' width={'250px'}>
                                        <div className="p-1">
                                            <Search/>
                                        </div>
                                        <Box className='flex-grow-1 p-1'>
                                            <InputBase type='search' placeholder='Search For Medical History'
                                                       fullWidth onChange={handleOnSearch}/>
                                        </Box>
                                    </Box>
                                )
                            }
                        </Box>
                        {
                            data?.length === 0 ?
                                <div
                                    className="w-100 d-flex justify-content-center align-items-center flex-column"
                                    style={{aspectRatio: '2.8/1'}}>
                                    <div style={{height: '60%'}}>
                                        <img src={NoDataUi} alt="No Data" className='h-100'/>
                                    </div>

                                    <div>
                                                    <span
                                                        className='fs-4  fw-light'>No Medical History Added Yet.</span>
                                    </div>

                                </div>
                                :
                                isMember ? (
                                    <table className='table border-left border-right border-top'>
                                        <thead>
                                        <Box component='tr' bgcolor={'#'}>
                                            <th><span className="text-muted">S.N.</span></th>
                                            <th><span className="text-muted">Medical PDF</span></th>
                                            <th><span className="text-muted">Translated By</span></th>
                                            <th><span className='text-muted'>Checked By</span></th>
                                            <th><span className="text-muted">Department</span></th>
                                            <th><span className="text-muted">Status</span></th>
                                            <th></th>
                                        </Box>
                                        </thead>
                                        <tbody>
                                        {renderHistoryDataUI()}
                                        </tbody>
                                    </table>
                                ) : (
                                    <div className="row mt-4">
                                        {
                                            data.map((item, idx) => (
                                                item.medical_report.length > 0 &&
                                                <div className="col-3" key={item.id}>
                                                    <div className="card shadow">
                                                        <div className="card-body">
                                                            <div
                                                                className="d-flex gap-2 align-items-center">
                                                                <div>
                                                                    <img src={PdfIcon} alt=""
                                                                         style={{height: '60px'}}/>
                                                                </div>
                                                                <div>
                                                                                <span className="fw-light">
                                                                                    Medical History
                                                                                </span>
                                                                    <br/>
                                                                    <span className='fs-6'>
                                                                                    #{item.medical_report[0].report.split('.')[0]}
                                                                                </span>
                                                                    <br/>
                                                                    <span className="fs-6 fw-light">
                                                                                    {DateFormat(item.medical_report[0].created_at).getFullDateText()}
                                                                                </span>
                                                                </div>
                                                                <div className='ms-auto'>
                                                                    <button
                                                                        className="btn btn-outline-primary"
                                                                        onClick={() => openFormModal(
                                                                            <MedicalHistoryPDF
                                                                                pdf={item.medical_report[0].report_path}/>)}>
                                                                        View
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                )
                        }
                    </div>
                </div>
            </div>
        </Box>
    )
}

export default Index
