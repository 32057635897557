import {useState, useEffect} from "react";
import axios from "../api.js";
import {useNavigate} from "react-router-dom";
import Slider from "react-slick";
import "./StoreVendorList.scss";

const StoreVendorCard = (props) => {
    const vendor = props.vendor;
    const navigate = useNavigate()
    return (
        <div className="vendor-card card" onClick={() => {
            navigate(`/gd-store/seller-store/${vendor.slug}`)
        }}>
            <div className="vendor-card-body card-body">
                <div className="img-div vendor-img">
                    <img src={vendor.image_path} alt={vendor.user.name}/>
                </div>
            </div>
            {/* <div className="vendor-card-body card-body">
        <div className="vendor-name d-flex justify-content-center">
          <h5>{vendor.user.name}</h5>
        </div>
      </div> */}
        </div>
    );
};

const StoreVendorList = ({vendorType}) => {
    const [vendors, setVendors] = useState([]);

    // const params = useParams();

    const sliderSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 3,
                    infinite: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    useEffect(() => {
        const getParam = new URLSearchParams([["vendor_type", vendorType]]);
        axios
            .get("api/vendor-details", {params: getParam})
            .then((res) => {
                setVendors(res.data);
            })
            .catch((err) => {
                console.log();
            });
    }, [vendorType]);

    return (
        <div className="vendor-list mt-3">
            <h4>{"Popular Vendors >"}</h4>
            <hr/>
            <div className="custom-slick">
                <Slider {...sliderSettings}>
                    {vendors.map((vendor) => {
                        return <StoreVendorCard vendor={vendor}/>;
                    })}
                </Slider>
            </div>
            <hr/>
        </div>
    );
};

export default StoreVendorList;
