import {RateReview, Reviews, Send} from '@mui/icons-material'
import {Avatar, Rating} from '@mui/material'
import React from 'react'
import {useChkLogin} from '../../../../middlewares/LoginMiddleware'
import {useParams} from 'react-router-dom'
import {useQuery} from '@tanstack/react-query'
import axios from '../../../api'
import ImgPlaceHolder from '../../../../images/receptionist.png'
import {DateFormat} from '../../../DateFormat'
import {getTimeDifference} from '../../../../helperFunctions/getTimeDifference'

function ConsultationHistoryDetails() {
    const {token} = useChkLogin()
    const params = useParams()
    const {data, isLoading} = useQuery(
        ['consultation-history-item', token],
        async () => (axios.get(`api/online-consultation-meeting?id=${params.id}`, {headers: {'Authorization': `Bearer ${token}`}})).then(res => res.data)
    )
    if (isLoading) {
        return
    }
    console.log("Doctor Data ", data)
    return (
        <div className="container">
            <div className="row">
                <div className="col-7">
                    <div className="card mb-2">
                        <div className="card-body">
                            <div className="row border-bottom pb-3">
                                <div className="col-7">
                                    <div className="d-flex align-items-center gap-3">
                                        <Avatar src={data.doctor.image_path || ImgPlaceHolder}/>
                                        <div>
                                            <div className="mb-1">
                                                <span className="fw-bold fs-6">
                                                    {data.doctor.user.name}
                                                </span>
                                            </div>
                                            <div>
                                                <span className="fs-6 fw-light">
                                                    {data.doctor.address}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-5 d-flex justify-content-end align-items-center">
                                    <div className="btn btn-primary d-flex align-items-center gap-2">
                                        <RateReview className='fs-6'/>
                                        <span className="fs-6">Give Review</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row py-3 g-3">
                                <div className="col-6">
                                    <div className='mb-1'>
                                        <span className="fs-6 fw-light">
                                            Address
                                        </span>
                                    </div>
                                    <div>
                                        <span className="fs-6">
                                            {data.doctor.address || 'No Data'}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className='mb-1'>
                                        <span className="fs-6 fw-light">
                                            Qualification
                                        </span>
                                    </div>
                                    <div>
                                        <span className="fs-6">
                                            {data.doctor.qualification || 'No Data'}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className='mb-1'>
                                        <span className="fs-6 fw-light">
                                            NMC Number
                                        </span>
                                    </div>
                                    <div>
                                        <span className="fs-6">
                                            {data.doctor.nmc_no || 'No Data'}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className='mb-1'>
                                        <span className="fs-6 fw-light">
                                            Specialization
                                        </span>
                                    </div>
                                    <div>
                                        <span className="fs-6">
                                            {data.doctor.specializtion || 'No Data'}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className='mb-1'>
                                        <span className="fs-6 fw-light">
                                            Experience
                                        </span>
                                    </div>
                                    <div>
                                        <span className="fs-6">
                                            {data.doctor.year_practiced || 'No Data'}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className='mb-1'>
                                        <span className="fs-6 fw-light">
                                            Gender
                                        </span>
                                    </div>
                                    <div>
                                        <span className="fs-6">
                                            {data.doctor.gender || 'No Data'}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div>
                            <div className="card-header">
                                <span className="fw-bold fs-6">
                                    Medical History
                                </span>
                                <br/>
                                <span className='fw-light fs-6'>Your medical history based on the consultation</span>
                            </div>
                            <div className="card-body">
                                <p className="fs-6">
                                    {data.history}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <span className="fs-6 fw-bold">
                                Patient Feedback
                            </span>
                            <br/>
                            <span className="fs-6 fw-light">
                                Give feedback about your experience in this online consultation. Give your honest review. You are not obligated to review.
                            </span>
                        </div>
                        <div className="card-body">
                            <div className='mb-2'>
                                <div className='mb-1'>
                                    <span className="fs-6">
                                        Your rating
                                    </span>
                                </div>
                                <div>
                                    <Rating name="size-large" defaultValue={2} size="large"/>
                                </div>
                            </div>
                            <div className='mb-4'>
                                <div className='mb-1'>
                                    <span className="fs-6">
                                        Your review
                                    </span>
                                </div>
                                <div>
                                    <textarea className='form-control' id="" cols="30" rows="4"></textarea>
                                </div>
                            </div>
                            <div>
                                <div className="btn btn-primary d-flex align-items-center gap-2"
                                     style={{width: 'fit-content'}}>
                                    <Send className='fs-6'/>
                                    <span className="fs-7">Submit Feedback</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-5">
                    <div className='card mb-2'>
                        <div className="card-header">
                            <span className="fw-bold fs-6">
                                Consultation Time
                            </span>
                            <br/>
                            <span className='fw-light fs-6'>Your Consultation Time Details</span>
                        </div>
                        <div className="card-body">
                            <div className='mb-2 fs-6'>
                                <span
                                    className='fw-light me-2'>Consultation Date: </span><span>{DateFormat(data.start_time).getFullDateText()}</span>
                            </div>
                            <div className="mb-2 d-flex gap-4 fs-6">
                                <div>
                                    <span
                                        className='fw-light me-2'>Start Time: </span><span>{data.start_time.split(' ')[1]}</span>
                                </div>
                                <div>
                                    <span
                                        className='fw-light me-2'>End Time: </span><span>{data.end_time.split(' ')[1]}</span>
                                </div>
                            </div>
                            <div className="fs-6 mb-2">
                                <span className='fw-light me-2'>Duration: </span>
                                <span
                                    className="badge badge-warning fs-6">{getTimeDifference(data.start_time, data.end_time)}</span>
                            </div>
                            <div className="fs-6 mt-4">
                                <span className="fw-light me-2">Agenda:</span>
                                <span className='fw-bold fs-6'>{data.agenda}</span>
                            </div>
                        </div>
                    </div>
                    <div className='card mb-2'>
                        <div className="card-header">
                            <span className="fw-bold fs-6">
                                Examination
                            </span>
                            <br/>
                            <span className='fw-light fs-6'>Your examination based on the consultation</span>
                        </div>
                        <div className="card-body">
                            <p className="fs-6">
                                {data.examination}
                            </p>
                        </div>
                    </div>
                    <div className='card mb-2'>
                        <div className="card-header">
                            <span className="fw-bold fs-6">
                                Treatment
                            </span>
                            <br/>
                            <span className='fw-light fs-6'>Your treatment based on the consultation</span>
                        </div>
                        <div className="card-body">
                            <p className="fs-6">
                                {data.treatment}
                            </p>
                        </div>
                    </div>
                    <div className='card'>
                        <div className="card-header">
                            <span className="fw-bold fs-6">
                                Progress
                            </span>
                            <br/>
                            <span className='fw-light fs-6'>Your progress based on the consultation</span>
                        </div>
                        <div className="card-body">
                            <p className="fs-6">
                                {data.progress}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ConsultationHistoryDetails
