import {faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    IconButton,
    InputBase,
    Popover,
    Popper,
    Stack,
    TextField,
    Typography
} from '@mui/material'
import React, {useEffect, useRef, useState} from 'react'
import './SearchBar.scss'
import SearchResults from './SearchResults'
import axios from '../../../api'
import {Search} from '@mui/icons-material'
import SearchDropdown from './SearchDropdown'
import {useChkLogin} from '../../../../middlewares/LoginMiddleware'
import {useNavigate} from 'react-router-dom'

function SearchBar() {
    const loginObj = useChkLogin()
    const [open, setOpen] = useState(false)
    const [searchResults, setSearchResults] = useState([])
    const [show, setShow] = useState(null)
    const inputRef = useRef(null)
    const [searchHistory, setSearchHistory] = useState([])
    const navigate = useNavigate()

    const handleItemClick = (keyword) => {
        setOpen(false)
        navigate(`/gd-store/filter?keyword=${keyword}`)
    }

    const fetchHistory = () => {
        axios.get('/api/search-history?type=product', {headers: {'Authorization': `Bearer ${loginObj.token}`}})
            .then(res => {
                setSearchHistory(res.data)
            })
    }

    useEffect(() => {
        if (!loginObj.isLoading && loginObj.isLoggedIn) {
            fetchHistory()
        }
    }, [loginObj.isLoading, loginObj.isLoggedIn])


    function handleClose() {
        setOpen(false)
    }

    async function handleChange(e) {
        const res = await getSearchResults(e.target.value)
        if (res === undefined || res === null) return
        if (e.target.value.length === 0) {
            setShow(0)
            return
        }
        setShow(1)
        setSearchResults(res)
    }

    function handleClick(e) {
        setOpen(true)
        if (!inputRef.current.value) {
            setShow(0)
        }
    }

    async function searchProducts(keyword) {
        if (loginObj.isLoading) {
            return
        }
        navigate(`/gd-store/filter?keyword=${keyword}`)
    }

    const style = {
        borderRadius: "40px",
        overflow: "hidden",
        background: "#F5F5F5",
        height: "2.5rem"
    }

    const inputStyle = {
        border: "none",
        background: "inherit",
    }

    const btnStyle = {
        background: "inherit !important"
    }
    return (
        <div className="search-bar position-relative search-function">
            <Box
                display='flex'
                gap={2}
                border={1}
                borderColor={'divider'}
                borderRadius='2rem'
                overflow='hidden'
                bgcolor={'#F5F5F5'}
                pl={2}
            >
                <Search sx={{alignSelf: 'center'}}/>
                <InputBase
                    type='search'
                    placeholder='Search for products'
                    onChange={handleChange}
                    onClick={handleClick}
                    disabled={loginObj.isLoading}
                    onKeyUp={(e) => {
                        if (e.code === 'Enter') {
                            searchProducts(inputRef.current.value)
                            setOpen(false)
                        }
                    }}
                    inputRef={inputRef}
                    sx={{
                        flexGrow: 1,
                        py: 1
                    }}
                />
                <div>
                    <Button variant='contained' sx={{height: '100%'}}
                            onClick={(e) => searchProducts(inputRef.current.value)} disabled={loginObj.isLoading}>
                        Search
                    </Button>
                </div>

            </Box>
            <SearchDropdown dropRef={inputRef} onClick={handleItemClick} refetchHistory={fetchHistory} show={show}
                            open={open} close={handleClose} searchResults={searchResults}
                            searchHistory={searchHistory}/>
        </div>
    )
}

async function getSearchResults(keyword) {
    try {
        const res = await axios.get(`/api/products?keyword=${keyword}`)
        return res.data.data.map((item) => (
            item.productName
        ))
    } catch (e) {
        console.log()
    }

}

export default SearchBar
