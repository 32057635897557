//This middleware restricts the route from being accessible.

import React, { useContext, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { LoginStateContext } from '../contexts/LoginStateContext'
import Loader from '../components/pages/Loader'

function RestrictMiddleware() {
  const login = useContext(LoginStateContext)

  const loadingScreenStyle = {
    height:"80vH"
  }

  if (login.state.loading) {
    // return <h2>Loading...</h2>
    return (
      <div
        className="loading-screen text-center"
        style={loadingScreenStyle}
      >
        <div className="h-100 d-flex align-items-center justify-content-center">
          <div className="center-div text-center">
            <Loader />
            <h4>Verifying. Please Wait...</h4>
          </div>
        </div>
      </div>
    )
  }

  if (login.state.loggedIn === true) {
    return <NavigateBack replace={true} />
  }

  return (
    <Outlet />
  )
}

function NavigateBack({ replace }) {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(-1, { replace: replace })
  }, [])

  return
}

export default RestrictMiddleware