import React from 'react'
import Slider from "react-slick";
import {Box, Rating, Stack} from "@mui/material";
import "./ShopByLatestProd.scss";
import axios from "../api.js";
import ProductCards from './ProductCards';
import {ResizeListener} from '../custom_componets/RespTxtDiv';
import ProductSkeleton from './ProductSkeleton';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleArrowRight} from '@fortawesome/free-solid-svg-icons';
import FitnessContainer from '../pages/fitnessCenter/components/FitnessContainer';

const ShopByLatestProd = () => {
    const [latestProds, setLatestProds] = React.useState([])
    const [loading, setLoading] = React.useState(true)

    React.useEffect(() => {
        axios.get("api/products?latest=desc")
            .then((res) => {
                setLatestProds(res.data.data)
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
            })
    }, [])


    const sliderSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 1,
                },
            },
        ],
    };

    const getProdCard = () => {
        if (loading) {
            let skeleton = []
            for (let i = 0; i < 6; i++) {
                skeleton.push(
                    <ProductSkeleton/>
                )
            }
            return skeleton
        }

        let cardNum = 0;
        return latestProds.map((prod) => {
            return (
                <div>
                    <Stack direction='row' justifyContent='center'>
                        <ProductCards
                            data={prod}
                            cardNum={cardNum++}
                            // pageNum={pageNum}
                        />
                    </Stack>
                </div>
            )
        })
    };

    return (
        // <div className="latest-products mb-5">
        //   <div className="topic-head d-flex justify-content-between mb-4">
        //     <h2>Latest Products</h2>
        //     <div className="topic-head-all">
        //       <Link to="" className="text-decoration-none">
        //         <span>See All</span>
        //         <FontAwesomeIcon icon={faCircleArrowRight} size="xl" />
        //       </Link>
        //     </div>
        //   </div>
        //   <div className="latest-products-list mt-4 custom-slick">
        //     <Slider {...sliderSettings}>
        //       {getProdCard()}
        //     </Slider>
        //   </div>


        // </div>
        <FitnessContainer title='Latest Products'>
            <Slider {...sliderSettings}>
                {getProdCard()}
            </Slider>
        </FitnessContainer>

    );
};

export default ShopByLatestProd;
