import {KeyboardArrowRightOutlined} from '@mui/icons-material'
import {Avatar, Button, Card, Stack, Typography} from '@mui/material'
import React from 'react'
import {useNavigate} from 'react-router-dom'

function LabProfileItem({data}) {
    const navigate = useNavigate()

    function handleClick() {
        navigate('/lab-tests/item', {state: {data: data, type: 'profile'}})
    }

    return (
        <Card sx={{p: 2, minWidth: '200px'}}>
            <Avatar src={"https://assets.pharmeasy.in/web-assets/dist/51ffd21a.svg"} width={10} height={10}/>
            <Typography variant='h6' fontWeight='550' lineHeight={1.2} mt={1}>{data.profile_name} profile</Typography>
            <Typography variant='subtitle1' color="text.secondary">Includes {data.labtest.length} Tests</Typography>
            <Stack direction={'row'} justifyContent='space-between'>
                <Typography variant='h6' fontWeight={550}>NPR {data.price}</Typography>
                <Button endIcon={<KeyboardArrowRightOutlined/>} onClick={handleClick}>Book Now</Button>
            </Stack>
        </Card>
    )
}

export default LabProfileItem
