import React from "react";
import {useQuery} from "@tanstack/react-query";
import SchoolPaymentVerificationService from "../../../../../services/school-payment-verification.service";
import {grey} from "@mui/material/colors";

const PaymentHistory = () => {

    const {loading, data} = useQuery({
        queryKey: ['school-payment-history'],
        queryFn: SchoolPaymentVerificationService.fetchPaymentHistory
    })

    const history = [
        {
            "invoice_no": "INV001",
            "transaction_date": "2024-03-01",
            "address": "123 Main St, Anytown, USA",
            "service_name": "School package",
            "total_payment": "100.00",
            "payment_type": "cash",
            "invoice_url": "https://example.com/invoice/INV001"
        },
        {
            "invoice_no": "INV002",
            "transaction_date": "2024-03-08",
            "address": "456 Elm St, Anycity, USA",
            "service_name": "School package",
            "total_payment": "150.00",
            "payment_type": "cheque",
            "invoice_url": "https://example.com/invoice/INV002"
        },
        {
            "invoice_no": "INV003",
            "transaction_date": "2024-03-14",
            "address": "789 Oak St, Anysuburb, USA",
            "service_name": "School package",
            "total_payment": "200.00",
            "payment_type": "cash",
            "invoice_url": "https://example.com/invoice/INV003"
        }
    ]

    return (
        <div className="d-grid gap-2 mb-4">
            <div>
                <h4 className="fs-4 fw-bolder lh-sm">
                    Payment History
                </h4>
            </div>
            <div className="gap-y-4 row" style={{
                gap: "20px 0"
            }}>
                {
                    history.map((paymentItem,index) => {
                        return (
                            <div  key={index} className="col-6">
                                <div className="bg-white shadow-sm p-3 " style={{borderRadius: 6}}>
                                    <h4 className="fs-6 fw-bolder lh-sm">
                                          NRS. {paymentItem.total_payment} /
                                            {paymentItem.payment_type}
                                    </h4>
                                    <div className="d-flex gap-5">
                                        <div className="d-flex gap-1 align-items-center">
                                            <span className="opacity-50">Invoice No.</span> <span>{paymentItem.invoice_no}</span>
                                        </div>
                                        <div className="d-flex gap-1 align-items-center">
                                            <span className="opacity-50">Payment Date.</span> <span>{paymentItem.transaction_date}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default PaymentHistory
