import React from 'react'
import BannerNAdvertisement from './components/BannerNAdvertisement'
import ExlusiveVendors from './components/ExlusiveVendors'
import FastSelling from './components/FastSelling'
import FitnessPricingPlans from './components/FitnessPricingPlans'
import GDPharmacyCatalog from './components/GDPharmacyCatalog'
import LabTestCatalog from './components/LabTestCatalog'
import LatestProducts from './components/LatestProducts'
import LatestViewed from './components/LatestViewed'
import MostViewed from './components/MostViewed'

function StoreHomeGD() {
    return (
        <React.Fragment>
            <BannerNAdvertisement/>
            <div className="container-lg">
                <GDPharmacyCatalog/>
                <LatestViewed/>
                <LabTestCatalog/>
                <LatestProducts/>
                <MostViewed/>
                <ExlusiveVendors/>
                {/* <FastSelling/> */}
                <FitnessPricingPlans/>
            </div>
        </React.Fragment>
    )
}

export default StoreHomeGD
