import {CardMedia} from '@mui/material'
import {Box} from '@mui/material'
import {Avatar} from '@mui/material'
import {Typography} from '@mui/material'
import {Stack} from '@mui/material'
import {CardContent} from '@mui/material'
import {Card} from '@mui/material'
import React from 'react'
import {useNavigate} from 'react-router-dom'


function VendorCard({data}) {
    const navigate = useNavigate();
    return (
        <Card sx={{maxWidth: '190px', position: 'relative', cursor: 'pointer'}}
              onClick={() => navigate(`/storetest/seller-store/${data.slug}`)}>
            <CardMedia
                component="img"
                image={data.image_path}
                sx={{aspectRatio: '1/1.1'}}
            />
            <Box sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                aspectRatio: '1/1.1',
                opacity: '0.2',
                background: 'linear-gradient(180deg, rgba(255,255,255,1) 72%, rgba(47,47,47,1) 89%)'
            }}/>

            <CardContent sx={{position: 'relative', width: '100%', height: '70px', p: 0, m: 0}}>
                <Box sx={{position: 'absolute', width: '100%', height: '80%', top: '-40px', left: 0}}>
                    <Stack width={'100%'} alignItems='center'>
                        <Card sx={{width: '30%', mb: 1}} elevation={3}>
                            <CardMedia
                                component='img'
                                src={data.image_path}
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    aspectRatio: '1/1',
                                }}
                            />
                        </Card>
                        <Typography variant='subtitle1'>{data.store_name}</Typography>
                    </Stack>
                </Box>
            </CardContent>
        </Card>
    )
}

export default VendorCard
